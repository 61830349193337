import { Configuration, OpenAIApi } from 'openai';

const configuration = new Configuration({
	apiKey: process.env.REACT_APP_GPT_SECRET_KEY,
});

const openai = new OpenAIApi(configuration);

// const model = 'gpt-3.5-turbo'; // powers chat gpt, $0.002/k tokens
const model = 'gpt-3.5-turbo';

export const getDetailedText = async (text) => {
	try {
		if (!typeof text === 'string' || !text.trim()) throw new Error('Invalid Text');

		const response = await openai.createChatCompletion({
			model: model,
			temperature: 0,
			// top_p: 1,
			max_tokens: 2048,
			messages: [
				{
					role: 'user',
					content: `${queryWhiteboard}${text}`,
				},
			],
		});

		return response?.data?.choices?.[0]?.message?.content;
	} catch (err) {
		console.log(err);
		return '';
	}
};

export const getEntitiesFromText = async (text) => {
	try {
		if (!typeof text === 'string' || !text.trim()) throw new Error('Invalid Text');

		const response = await openai.createChatCompletion({
			model: model,
			temperature: 0,
			// top_p: 1,
			max_tokens: 2048,
			messages: [
				{
					role: 'user',
					content: `${query}${text}`,
				},
			],
		});

		const txt = response?.data?.choices?.[0]?.message?.content;
		if (txt.indexOf('{') !== -1) return JSON.parse(txt.substring(txt.indexOf('{')));
		else return '';
	} catch (err) {
		console.log(err);
		return '';
	}
};

const queryInvestigation = `Write description about this investigation done on a patient:\n`;
const queryInvestigation2 = `Write description about the following investigations done on a patient:\n`;
export const getInvestigationDescription = async (investigationName, invData) => {
	try {
		if (investigationName) {
			if (!typeof investigationName === 'string' || !investigationName.trim()) return;

			var q2 = '';
			if (invData && Object.keys(invData).length > 0)
				q2 = `Investigation Data (dates and values): ${JSON.stringify(invData)}`;

			const response = await openai.createChatCompletion({
				model: model,
				temperature: 0,
				// top_p: 1,
				max_tokens: 2048,
				messages: [
					{
						role: 'user',
						content: `${queryInvestigation}Investigation Name:${investigationName}\n${q2}`,
					},
				],
			});

			return response?.data?.choices?.[0]?.message?.content;
		} else {
			var q2 = '';
			if (invData && Object.keys(invData).length > 0)
				q2 = `Investigation Data (dates and values): ${JSON.stringify(invData)}`;

			const response = await openai.createChatCompletion({
				model: model,
				temperature: 0,
				// top_p: 1,
				max_tokens: 2048,
				messages: [
					{
						role: 'user',
						content: `${queryInvestigation2}\n${q2}`,
					},
				],
			});

			return response?.data?.choices?.[0]?.message?.content;
		}
	} catch (err) {
		console.log(err);
		return '';
	}
};

const query = `classify vitals(weight as int, height as int, pulse as int, spo2 as int, bpSystolic as int, bpDiastolic as int), complaints as array of string, history(past (personal as string, menstrual as string, pregnancy as string, allergy as string, surgical as string, hospitalization as string), social as string, drug as string, family as string), symptoms as array of string, investigations(name as string, value as int, min as int, max as int) as array, examinations as array of string, diagnoses(name as string, icdCode as string) as array, medicinesPrescribed(name as string, dosage as string, frequency as string, timing as string, duration as string) as array, testsRequired as array of string, specialInstructions as array of string and follow up as string of the patient from the following text as a json object, leave string value empty if not available, find icd codes from https://www.icd10data.com/ICD10CM/Codes :\n`;

// const queryWhiteboard = `Write detailed clinical notes for headings Vitals, Past Drug History, Past Medical History, Family History, Social History, Investigation History, Current Diagnosis, Tests Requested, Medicines Suggested and Follow up appointment:\n`;

const queryWhiteboard = `Write detailed clinical notes for the following text:\n`;

const queryVitals =
	'classify vitals(weight as int, height as int, pulse as int, spo2 as int, bpSystolic as int, bpDiastolic as int) as a json object, leave value empty if not available, from the following text:\n';

const queryMedicines =
	'classify medicinesPrescribed(name as string, dosage as string, frequency as string, timing as string, duration as string) as json object of arrays from following text:\n';

export const getVitals = async (text) => {
	try {
		if (!typeof text === 'string' || !text.trim()) throw new Error('Invalid Text');

		const response = await openai.createChatCompletion({
			model: model,
			temperature: 0,
			// top_p: 1,
			max_tokens: 2048,
			messages: [
				{
					role: 'user',
					content: `${queryVitals}${text}`,
				},
			],
		});

		const txt = response?.data?.choices?.[0]?.message?.content;
		if (txt.indexOf('{') !== -1) return JSON.parse(txt.substring(txt.indexOf('{')));
		else return '';
	} catch (err) {
		console.log(err);
		return '';
	}
};

export const getMedicines = async (text) => {
	try {
		if (!typeof text === 'string' || !text.trim()) throw new Error('Invalid Text');

		const response = await openai.createChatCompletion({
			model: model,
			temperature: 0,
			// top_p: 1,
			max_tokens: 2048,
			messages: [
				{
					role: 'user',
					content: `${queryMedicines}${text}`,
				},
			],
		});

		const txt = response?.data?.choices?.[0]?.message?.content;
		if (txt.indexOf('{') !== -1) return JSON.parse(txt.substring(txt.indexOf('{')));
		else return '';
	} catch (err) {
		console.log(err);
		return '';
	}
};

const clinicalNotesQuery = 'Write detailed clinical notes based on following data:\n';
export const getClinicalNotes = async (text) => {
	try {
		if (!typeof text === 'string' || !text.trim()) throw new Error('Invalid Text');

		const response = await openai.createChatCompletion({
			model: model,
			temperature: 0,
			// top_p: 1,
			max_tokens: 2048,
			messages: [
				{
					role: 'user',
					content: `${clinicalNotesQuery}${text}`,
				},
			],
		});

		return response?.data?.choices?.[0]?.message?.content;
	} catch (err) {
		console.log(err);
		return '';
	}
};
