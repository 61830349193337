import {
	SET_OPD_STATUSES,
	DELETE_OPD_STATUS,
	SET_CHIEF_COMPLAINTS,
	DELETE_CHIEF_COMPLAINT,
	SET_EXAMINATIONS,
	DELETE_EXAMINATION,
	SET_DIAGNOSES,
	DELETE_DIAGNOSIS,
	SET_COMMUNICATION_GROUPS,
	DELETE_COMMUNICATION_GROUP,
	SET_REFERRAL_TYPES,
	DELETE_REFERRAL_TYPE,
	SET_EXTERNAL_REFERRERS,
	DELETE_EXTERNAL_REFERRER,
	SET_NOTES,
	SET_TREATMENTS,
	DELETE_TREATMENT,
	SET_PRESCRIPTION_TEMPLATES,
	DELETE_PRESCRIPTION_TEMPLATE,
	DELETE_NOTE,
	SET_INVESTIGATIONS,
	DELETE_INVESTIGATION,
	SET_ORAL_EXAMINATIONS,
	DELETE_ORAL_EXAMINATION,
	SET_IPD_ROOMS,
	SET_VISITING_DOC,
	SET_INVESTIGATION_RATELIST,
	SET_IPD_OTHERS,
	SET_IPD_WARDS,
	SET_INVESTIGATION_TEMPLATES,
	SET_BILLPREFIX,
	SET_NURSING_LIST,
	SET_SYMPTOMS,
	SET_ROS_CATEGORIES,
	SET_TREATMENTS_TAGS,
	CREATE_TREATMENTS_TAGS,
	SET_PATIENT_OCCUPATION,
	CREATE_CONSENT,
	DELETE_CONSENT,
	SET_CONSENTS_lIST,
	SET_MEDICINE_ROWS,
	DELETE_MEDICINE_ROW,
	SET_SPECIAL_INSTRUCTION_TEMPLATES,
	DELETE_SPECIAL_INSTRUCTION_TEMPLATE,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { store } from '../store/configureStore';
import { toggleCreateLoading, toggleTableLoading } from './componentActions';

export const getOpdStatuses = (id) => async (dispatch) => {
	console.log('running getOpdStatuses');
	const vendorId = store?.getState?.()?.auth?.user?.Vendor_detail?.vendorId;

	try {
		const res = await axios.get('/opd-status', { params: { vendorId: id || vendorId } });
		if (res.data.success) {
			dispatch({
				type: SET_OPD_STATUSES,
				payload: res.data.opdStatuses,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOpdStatusForDoctor = async (doctorId) => {
	console.log('running getOpdStatuses');
	const vendorId = store?.getState?.()?.auth?.user?.Vendor_detail?.vendorId;

	try {
		const res = await axios.get('/opd-status', { params: { vendorId: doctorId } });
		if (res.data.success) {
			return res.data.opdStatuses;
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOpdStatus = (newStatus, id) => async (dispatch) => {
	try {
		const res = await axios.post('/opd-status', newStatus);
		if (res.data.success) {
			showNotification('Success', 'The status is created successfully', 'success');
			dispatch(getOpdStatuses(id));
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateOpdStatus = (updateBody, id) => async (dispatch) => {
	try {
		const res = await axios.patch('/opd-status', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The status is updated successfully', 'success');
			dispatch(getOpdStatuses(id));
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteOpdStatus = (statusId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/opd-status/${statusId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_OPD_STATUS,
				payload: statusId,
			});
			showNotification('Success', 'The status has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getChiefComplaints = () => async (dispatch) => {
	console.log('running getChiefComplaints');
	try {
		const res = await axios.get('/chief-complaint/gp');
		if (res.data.success) {
			dispatch({
				type: SET_CHIEF_COMPLAINTS,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createChiefComplaintNow = async (complaint) => {
	try {
		const res = await axios.post('/chief-complaint/gp', complaint);
		if (res.data.success) {
			return res.data.data;
		}
		return null;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const createChiefComplaint = (newComplaint) => async (dispatch) => {
	try {
		const res = await axios.post('/chief-complaint/gp', newComplaint);
		if (res.data.success) {
			showNotification('Success', 'The chief complaint is created successfully', 'success');
			dispatch(getChiefComplaints());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateChiefComplaint = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.put('/chief-complaint/gp', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The chief complaint is updated successfully', 'success');
			dispatch(getChiefComplaints());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteChiefComplaint = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/chief-complaint/gp/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_CHIEF_COMPLAINT,
				payload: id,
			});
			showNotification(
				'Success',
				'The chief complaint has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getExaminations = () => async (dispatch) => {
	console.log('running getExaminations');
	try {
		const res = await axios.get('/doctor/prescription/prescription-examination-list');
		if (res.data.success) {
			dispatch({
				type: SET_EXAMINATIONS,
				payload: res.data.data?.list,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createExamination = (newExamination) => async (dispatch) => {
	try {
		const res = await axios.post(
			'/doctor/prescription/prescription-examination-create',
			newExamination,
		);
		if (res.data.success) {
			showNotification('Success', 'The examination is created successfully', 'success');
			dispatch(getExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateExamination = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/doctor/examination', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The examination is updated successfully', 'success');
			dispatch(getExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteExamination = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/examination/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_EXAMINATION,
				payload: id,
			});
			showNotification('Success', 'The examination has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getDiagnoses = () => async (dispatch) => {
	console.log('running getDiagnoses');
	try {
		const res = await axios.get('/doctor/diagnosis');
		if (res.data.success) {
			dispatch({
				type: SET_DIAGNOSES,
				payload: res.data.diagnoses,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createDiagnosis = (newDiagnosis) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/diagnosis', newDiagnosis);
		if (res.data.success) {
			showNotification('Success', 'The diagnosis is created successfully', 'success');
			dispatch(getDiagnoses());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateDiagnosis = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/doctor/diagnosis', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The diagnosis is updated successfully', 'success');
			dispatch(getDiagnoses());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteDiagnosis = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/diagnosis/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_DIAGNOSIS,
				payload: id,
			});
			showNotification('Success', 'The diagnosis has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getCommunicationGroups = () => async (dispatch) => {
	console.log('running getCommunicationGroups');
	try {
		const res = await axios.get('/communication');
		if (res.data.success) {
			dispatch({
				type: SET_COMMUNICATION_GROUPS,
				payload: res.data.communicationGroups,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createCommunicationGroup = (newCommGroup) => async (dispatch) => {
	try {
		const res = await axios.post('/communication', newCommGroup);
		if (res.data.success) {
			showNotification(
				'Success',
				'The communication group is created successfully',
				'success',
			);
			dispatch(getCommunicationGroups());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateCommunicationGroup = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/communication', updateBody);
		if (res.data.success) {
			showNotification(
				'Success',
				'The communication group is updated successfully',
				'success',
			);
			dispatch(getCommunicationGroups());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteCommunicationGroup = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/communication/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_COMMUNICATION_GROUP,
				payload: id,
			});
			showNotification('Success', 'The status has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getReferralTypes = () => async (dispatch) => {
	console.log('running getReferralTypes');
	try {
		const res = await axios.get('/referral-type');
		if (res.data.success) {
			dispatch({
				type: SET_REFERRAL_TYPES,
				payload: res.data.referralTypes,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createReferralType = (newRefType) => async (dispatch) => {
	try {
		const res = await axios.post('/referral-type', newRefType);
		if (res.data.success) {
			showNotification('Success', 'The referral type is created successfully', 'success');
			dispatch(getReferralTypes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateReferralType = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/referral-type', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The referral type is updated successfully', 'success');
			dispatch(getReferralTypes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteReferralType = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/referral-type/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_REFERRAL_TYPE,
				payload: id,
			});
			showNotification(
				'Success',
				'The referral type has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getExternalReferrers = () => async (dispatch) => {
	console.log('running getExternalReferrers');
	try {
		const res = await axios.get('/externalreferrer');
		if (res.data.success) {
			dispatch({
				type: SET_EXTERNAL_REFERRERS,
				payload: res.data.externalReferrers,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createExternalReferrer = (newExtRef) => async (dispatch) => {
	try {
		const res = await axios.post('/externalreferrer', newExtRef);
		if (res.data.success) {
			showNotification('Success', 'The external referrer is created successfully', 'success');
			dispatch(getExternalReferrers());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateExternalReferrer = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/externalreferrer', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The external referrer is updated successfully', 'success');
			dispatch(getExternalReferrers());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteExternalReferrer = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/externalreferrer/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_EXTERNAL_REFERRER,
				payload: id,
			});
			showNotification(
				'Success',
				'The external referrer has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getTreatments = () => async (dispatch) => {
	console.log('running getTreatments');
	try {
		const res = await axios.get('/treatment?type=GP');
		if (res.data.success) {
			dispatch({
				type: SET_TREATMENTS,
				payload: res.data.treatments,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTreatmentNow = async (newTreatment) => {
	try {
		const res = await axios.post('/treatment', { ...newTreatment, type: 'GP' });
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTreatment = (newTreatment) => async (dispatch) => {
	try {
		const res = await axios.post('/treatment', { ...newTreatment, type: 'GP' });
		if (res.data.success) {
			showNotification('Success', 'The treatment is created successfully', 'success');
			dispatch(getTreatments());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateTreatment = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/treatment', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The treatment is updated successfully', 'success');
			dispatch(getTreatments());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteTreatment = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/treatment/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_TREATMENT,
				payload: id,
			});
			showNotification('Success', 'The treatment has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getTreatmentTags = () => async (dispatch) => {
	try {
		const res = await axios.get('/treatment/tag?type=GP');
		if (res.data.success) {
			dispatch({
				type: SET_TREATMENTS_TAGS,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTreatmentTag = async (name) => {
	try {
		const res = await axios.post('/treatment/tag?type=GP', { name, type: 'GP' });
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPrescriptionTemplates = () => async (dispatch) => {
	console.log('running getPrescriptionTemplates');
	try {
		const res = await axios.get('/prescription-template');
		if (res.data.success) {
			dispatch({
				type: SET_PRESCRIPTION_TEMPLATES,
				payload: res.data.prescriptionTemplates,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createPrescriptionTemplate = (newPrescTemp) => async (dispatch) => {
	try {
		const res = await axios.post('/prescription-template', newPrescTemp);
		if (res.data.success) {
			dispatch(getPrescriptionTemplates());
			showNotification(
				'Success',
				'The Prescription Template is created successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePrescriptionTemplate = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/prescription-template', updateBody);
		if (res.data.success) {
			dispatch(getPrescriptionTemplates());
			showNotification(
				'Success',
				'The Prescription Template is updated successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePrescriptionTemplate = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/prescription-template/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PRESCRIPTION_TEMPLATE,
				payload: id,
			});
			showNotification(
				'Success',
				'The Prescription Template has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePrescriptionTemplateList = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/prescription-template/list/${id}`);
		if (res.data.success) {
			dispatch(getPrescriptionTemplates());
			showNotification(
				'Success',
				'The Prescription Template has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getNotesByType = (type) => async (dispatch) => {
	try {
		const res = await axios.get(`/notes`, { params: { type } });
		if (res.data.success) {
			dispatch({
				type: SET_NOTES,
				payload: res.data.notes,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getNotes = () => async (dispatch) => {
	console.log('running getNotes');
	try {
		const res = await axios.get('/notes');
		if (res.data.success) {
			dispatch({
				type: SET_NOTES,
				payload: res.data.notes,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createNote = (newNote) => async (dispatch) => {
	try {
		const res = await axios.post('/notes', newNote);
		if (res.data.success) {
			showNotification('Success', 'The note is created successfully', 'success');
			dispatch(getNotes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateNote = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/notes', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The note is updated successfully', 'success');
			dispatch(getNotes());
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOccupations = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/patient/occupation`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_OCCUPATION,
				payload: res.data.occupations,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteNote = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/notes/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_NOTE,
				payload: id,
			});
			showNotification('Success', 'The note has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getInvestigations = () => async (dispatch) => {
	console.log('running getInvestigations');
	try {
		const res = await axios.get('/investigation?type=GP');
		if (res.data.success) {
			dispatch({
				type: SET_INVESTIGATIONS,
				payload: res.data.investigations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getInvestigationsNow = async () => {
	console.log('running getInvestigationsNow');
	try {
		const res = await axios.get('/investigation?type=GP');
		if (res.data.success) {
			return res.data.investigations;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getIPDInvestigationsNow = async () => {
	console.log('running getInvestigationsNow');
	try {
		const res = await axios.get('/investigation/investigation-by-type/IPD');
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const createInvestigation = (newInvestigation) => async (dispatch) => {
	try {
		const res = await axios.post('/investigation', newInvestigation);
		if (res.data.success) {
			// showNotification('Success', 'The investigation is created successfully', 'success');
			dispatch(getInvestigations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const createInvestigationNow = async (newInvestigation) => {
	try {
		const res = await axios.post('/investigation', newInvestigation);
		if (res.data.success) {
			// showNotification('Success', 'The investigation is created successfully', 'success');
			const newInvestigations = await getInvestigationsNow();
			return newInvestigations?.find((i) => i.name === newInvestigation.name);
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const updateInvestigation = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/investigation', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The investigation is updated successfully', 'success');
			dispatch(getInvestigations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteInvestigation = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/investigation/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_INVESTIGATION,
				payload: id,
			});
			showNotification(
				'Success',
				'The investigation has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteInvestigationUnit = (invId, unitId) => async (dispatch) => {
	try {
		const res = await axios.delete(
			`/investigation/unit?investigationId=${invId}&investigationUnitId=${unitId}`,
		);
		if (res.data.success) {
			dispatch(getInvestigations());
			// showNotification(
			// 	'Success',
			// 	'The investigation unit has been deleted successfully',
			// 	'success',
			// );
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOralExaminations = () => async (dispatch) => {
	console.log('running getOralExaminations');
	try {
		const res = await axios.get('/oral-examination');
		if (res.data.success) {
			dispatch({
				type: SET_ORAL_EXAMINATIONS,
				payload: res.data.oralExaminations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOralExamination = (newExam) => async (dispatch) => {
	try {
		const res = await axios.post('/oral-examination', newExam);
		if (res.data.success) {
			showNotification('Success', 'The examination is created successfully', 'success');
			dispatch(getOralExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateOralExamination = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/oral-examination', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The examination is updated successfully', 'success');
			dispatch(getOralExaminations());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteOralExamination = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/oral-examination/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_ORAL_EXAMINATION,
				payload: id,
			});
			showNotification('Success', 'The examination has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getInvestigationRateList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/investigation/investigation-by-type/IPD`);
		if (res.data.success) {
			dispatch({
				type: SET_INVESTIGATION_RATELIST,
				payload: res.data.data?.filter((x) => !x?.isAdmin),
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createInvestigationRateList = async (data, dispatch) => {
	try {
		const res = await axios.post(`/investigation`, data);
		if (res.data.success) {
			showNotification('Success', 'The investigation created successfully', 'success');
			// dispatch(getInvestigationRateList());
			const newInvestigations = await getIPDInvestigationsNow();
			dispatch({
				type: SET_INVESTIGATION_RATELIST,
				payload: newInvestigations?.filter((x) => !x?.isAdmin),
			});
			return newInvestigations?.filter((x) => !x?.isAdmin)?.find((i) => i.name === data.name);
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateInvestigationRateList = (data) => async (dispatch) => {
	try {
		const res = await axios.patch(`/investigation`, data);
		if (res.data.success) {
			showNotification('Success', 'The investigation updated successfully', 'success');
			dispatch(getInvestigationRateList());
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteInvestigationRateList = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/investigation/delete-by-organisation/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The investigation deleted successfully', 'success');
			dispatch(getInvestigationRateList());
		}
	} catch (err) {
		console.log(err);
	}
};

export const getIpdOther = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipdother`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_OTHERS,
				payload: res.data.ipds,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createIpdOther = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/ipdother`, data);
		if (res.data.success) {
			showNotification('Success', 'The ipd created successfully', 'success');
			dispatch(getIpdOther());
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateIpdOther = (data) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/ipdother`, data);
		if (res.data.success) {
			showNotification('Success', 'The ipd updated successfully', 'success');
			dispatch(getIpdOther());
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteIpdOther = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/ipdother/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The ipd deleted successfully', 'success');
			dispatch(getIpdOther());
		}
	} catch (err) {
		console.log(err);
	}
};

export const getVisitingDoc = () => async (dispatch) => {
	console.log('running getVisitingDoc');
	try {
		const res = await axios.get('/visiting-doctors');
		if (res.data.success) {
			dispatch({
				type: SET_VISITING_DOC,
				payload: res.data.visitingDoctors,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createVisitingDoc = (data) => async (dispatch) => {
	try {
		const res = await axios.post('/visiting-doctors', data);
		if (res.data.success) {
			showNotification('Success', 'The doctor is created successfully', 'success');
			dispatch(getVisitingDoc());
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateVisitingDoc = (data) => async (dispatch) => {
	try {
		const res = await axios.patch('/visiting-doctors', data);
		if (res.data.success) {
			showNotification('Success', 'The doctor is updated successfully', 'success');
			dispatch(getVisitingDoc());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteVisitingDoc = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/visiting-doctors/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The doctor has been deleted successfully', 'success');
			dispatch(getVisitingDoc());
		}
	} catch (err) {
		console.log(err);
	}
};

export const getInvestigationTemplates = () => async (dispatch) => {
	try {
		const res = await axios.get(`/investigation-template`);
		if (res.data.success) {
			dispatch({
				type: SET_INVESTIGATION_TEMPLATES,
				payload: res.data.investigationTemplates,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addInvestigationTemplate = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/investigation-template`, data);
		if (res.data.success) {
			dispatch(getInvestigationTemplates());
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateInvestigationTemplate = (data) => async (dispatch) => {
	try {
		const res = await axios.patch(`/investigation-template`, data);
		if (res.data.success) {
			dispatch(getInvestigationTemplates());
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteInvestigationTemplate = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/investigation-template/${id}`);
		if (res.data.success) {
			dispatch(getInvestigationTemplates());
		}
	} catch (error) {
		console.error(error);
	}
};

export const getBillPrefix = () => async (dispatch) => {
	try {
		const res = await axios.get(`/bill-settings/get-bill-prefix`);
		if (res.data.success) {
			dispatch({
				type: SET_BILLPREFIX,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const updatePrefixBill = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/bill-settings/add-bill-prefix`, data);
		if (res.data.success) {
			dispatch(getBillPrefix());
			showNotification('Success', 'Prefix updated successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const getNursingList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd-nursing`);
		if (res.data.success) {
			dispatch({
				type: SET_NURSING_LIST,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addNursingItem = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/ipd-nursing`, data);
		if (res.data.success) {
			dispatch(getNursingList());
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateNursingItem = (data) => async (dispatch) => {
	try {
		const res = await axios.put(`/doctor/ipd-nursing`, data);
		if (res.data.success) {
			dispatch(getNursingList());
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteNursingItem = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/ipd-nursing/${id}`);
		if (res.data.success) {
			dispatch(getNursingList());
		}
	} catch (error) {
		console.error(error);
	}
};

export const getSymptoms = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ros/symptoms`);
		if (res.data.success) {
			dispatch({
				type: SET_SYMPTOMS,
				payload: res.data.rosSymptoms,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createSystomNow = async (data) => {
	try {
		const res = await axios.post(`/doctor/ros/symptoms`, data);
		if (res.data.success) {
			return res.data.rosSymptom;
		}
		return null;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const getRosCategories = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ros`);
		if (res.data.success) {
			dispatch({
				type: SET_ROS_CATEGORIES,
				payload: res.data.rosCategories,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createRosTemplate = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/ros/template`, data);
		if (res.data.success) {
			dispatch(getRosCategories());
		}
	} catch (error) {
		console.error(error);
	}
};

export const getConsentForms = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/consent`);
		if (res.data.success) {
			dispatch({
				type: SET_CONSENTS_lIST,
				payload: res?.data?.consents,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleTableLoading(false));
	}
};

export const deleteRosCategory = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/ros/category/${id}`);
		if (res.data.success) {
			dispatch(getRosCategories());
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteRosSymptom = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/ros/symptoms/${id}`);
		if (res.data.success) {
			dispatch(getRosCategories());
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateRosSymptom = (data) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/ros/symptoms`, data);
		if (res.data.success) {
			dispatch(getRosCategories());
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateRosTemplateName = (data) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/ros/category`, data);
		if (res.data.success) {
			dispatch(getRosCategories());
		}
	} catch (error) {
		console.error(error);
	}
};

export const createConsentForm = (data, setModal) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/consent`, data);
		if (res.data.success) {
			showNotification('Success', 'The Habit is created successfully', 'success');
			setModal(false);
			dispatch({
				type: CREATE_CONSENT,
				payload: res.data.consent,
			});
			// dispatch(getOrgExpenses());
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateConsentForm = (data, setModal) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch(`/doctor/consent`, data);
		if (res.data.success) {
			showNotification('Success', 'The habit is updated successfully', 'success');
			dispatch(getConsentForms());
			setModal(false);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteConsentForm = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/consent/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The habit is deleted successfully', 'success');
			dispatch({
				type: DELETE_CONSENT,
				payload: id,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getMedicineRows = () => async (dispatch) => {
	try {
		const res = await axios.get(`/medicine-fill/list`);
		if (res.data.success) {
			dispatch({
				type: SET_MEDICINE_ROWS,
				payload: res?.data?.result,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleTableLoading(false));
	}
};

export const createMedicineRow = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/medicine-fill`, { result: [data] });
		if (res.data.success) {
			dispatch(getMedicineRows());
			showNotification('Success', 'The Row is created successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteMedicineRow = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/medicine-fill/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_MEDICINE_ROW,
				payload: id,
			});
			showNotification('Success', 'The row is deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getSpecialInstructionTemplates = () => async (dispatch) => {
	console.log('running getSpecialInstructionTemplates');
	try {
		const res = await axios.get('/doctor/special-instruction-template');
		if (res.data.success) {
			dispatch({
				type: SET_SPECIAL_INSTRUCTION_TEMPLATES,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createSpecialInstructionTemplate =
	(newSpecialInstructionTemplate) => async (dispatch) => {
		try {
			const res = await axios.post(
				'/doctor/special-instruction-template',
				newSpecialInstructionTemplate,
			);
			if (res.data.success) {
				showNotification('Success', 'The template is created successfully', 'success');
				dispatch(getSpecialInstructionTemplates());
			}
		} catch (err) {
			console.log(err);
		}
	};

export const updateSpecialInstructionTemplate = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.put('/doctor/special-instruction-template', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The template is updated successfully', 'success');
			dispatch(getSpecialInstructionTemplates());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteSpecialInstructionTemplate = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/special-instruction-template/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_SPECIAL_INSTRUCTION_TEMPLATE,
				payload: id,
			});
			showNotification('Success', 'The template has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};
