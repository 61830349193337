import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';

import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import useDarkMode from '../../../hooks/useDarkMode';
import Tooltips from '../../../components/bootstrap/Tooltips';
import {
	addIpdChiefComplaint,
	deleteIpdChiefComplaint,
	editIpdChiefComplaint,
	getIpdChiefComplaints,
} from '../../../actions/ipdPatientActions';
import { isPermitted } from '../../../actions/helperActions';
import Input from '../../../components/bootstrap/forms/Input';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import { createChiefComplaintNow, getChiefComplaints } from '../../../actions/settingActions';

// eslint-disable-next-line react/prop-types
const IpdChiefComplaints = () => {
	const darkModeStatus = useDarkMode();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const data = useSelector((state) => state.ipdPatient.chiefComplaints);
	const cc = useSelector((state) => state.setting.chiefComplaints);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);

	const [selectedCC, setSelectedCC] = useState(null);
	const [duration, setDuration] = useState('');
	const [addModal, setAddModal] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(data);

	// BEGIN :: Upcoming Events
	const handleEdit = (item) => {
		setSelectedCC({
			label: item?.history_ccvalue?.name,
			value: item?.cheifComplaintId,
		});
		setDuration(item?.duration);
		setEditItem(item);
		setAddModal(true);
	};

	const handleDeleteCC = (ccId) => {
		dispatch(deleteIpdChiefComplaint(ccId));
	};

	const handleSelectCC = (cc) => {
		if (editItem) {
			dispatch(
				editIpdChiefComplaint(
					{
						ipdChiefcomplaintId: editItem?.id,
						chiefComplaintId: cc?.value,
						duration,
					},
					ipdId,
				),
			);
		} else dispatch(addIpdChiefComplaint({ ipdId, chiefComplaints: [cc.value], duration }));
		setEditItem(null);
		setAddModal(false);
		setSelectedCC(null);
		setDuration('');
	};
	// END :: Upcoming Events

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			dispatch(getIpdChiefComplaints(ipdId));
			dispatch(getChiefComplaints());
		}
		return () => (mounted = false);
	}, []);

	return (
		<PageWrapper title={'Patient Chief Complaints'}>
			<Page container='fluid'>
				<Card stretch className='shadow-none'>
					<CardHeader>
						<CardLabel icon='contacts' iconColor='primary'>
							<CardTitle>Chief Complaints</CardTitle>
						</CardLabel>
						<CardActions>
							<Button
								isOutline={darkModeStatus}
								color='primary'
								isLight={!darkModeStatus}
								className={classNames('text-nowrap', {
									'border-light': !darkModeStatus,
								})}
								icon='add'
								onClick={() =>
									isPermitted(
										'Ipd',
										'write',
										() => setAddModal(true),
										'Chief Complaints',
									)
								}>
								Add
							</Button>
						</CardActions>
					</CardHeader>
					<CardBody className='table-responsive'>
						<table className='table table-modern'>
							<thead>
								<tr>
									<th>S.No.</th>
									<th>Complaint</th>
									<th>Duration</th>
									<td />
								</tr>
							</thead>
							<tbody>
								{dataPagination(items, currentPage, perPage).map((item, idx) => (
									<tr key={item.id}>
										<td>{idx + 1}</td>
										<td>{item.history_ccvalue?.name || '-'}</td>
										<td>{item?.duration || '-'}</td>
										<td>
											<Tooltips title='Edit'>
												<Button
													isOutline={!darkModeStatus}
													color='primary'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='edit'
													onClick={() =>
														isPermitted(
															'Ipd',
															'edit',
															() => handleEdit(item),
															'Chief Complaints',
														)
													}></Button>
											</Tooltips>{' '}
											<Tooltips title='Delete'>
												<Button
													isOutline={!darkModeStatus}
													color='danger'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Delete'
													onClick={() =>
														isPermitted(
															'Ipd',
															'delete',
															() => handleDeleteCC(item.id),
															'Chief Complaints',
														)
													}></Button>
											</Tooltips>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</CardBody>
					<PaginationButtons
						data={items}
						label='items'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
					/>
				</Card>
				<Modal setIsOpen={() => {}} isOpen={addModal} isCentered isAnimation={false}>
					<ModalHeader setIsOpen={setAddModal}>
						<ModalTitle>Add Complaint</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<Card className='shadow-none'>
							<CardBody>
								<div className='row'>
									<div className='col-12 pb-3'>
										<Creatable
											className='form-control'
											placeholder='Select Complaints...'
											styles={{
												control: (prev) => ({
													height: '2.6rem',
													alignItems: 'center',
													position: 'relative',
													display: 'flex',
												}),
											}}
											id='cc'
											value={selectedCC}
											options={cc?.map((i) => ({
												value: i.id,
												label: i.name,
											}))}
											onChange={(i) => {
												setSelectedCC(i);
											}}
											onCreateOption={async (i) => {
												const resp = await createChiefComplaintNow({
													name: i,
												});
												setSelectedCC({
													label: resp.name,
													value: resp.id,
												});
											}}
										/>
									</div>
									<div className='col-12'>
										<Input
											className='form-control'
											style={{
												height: '3.5rem',
											}}
											autoComplete='off'
											placeholder='duration'
											onChangeItem={(e) => {
												setDuration(
													(isNaN(parseInt(duration))
														? '1'
														: parseInt(duration)) +
														' ' +
														e.name || 'Days',
												);
											}}
											onChange={(e) => {
												setDuration(e.target.value);
											}}
											value={duration}
											list={[
												{ name: 'Days' },
												{ name: 'Weeks' },
												{ name: 'Months' },
												{ name: 'Lifetime' },
											]}
										/>
									</div>
								</div>
							</CardBody>
						</Card>
					</ModalBody>
					<ModalFooter>
						<Button
							isOutline={darkModeStatus}
							color='primary'
							isLight={!darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='save'
							onClick={() => {
								handleSelectCC(selectedCC);
							}}>
							Save
						</Button>
					</ModalFooter>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default IpdChiefComplaints;
