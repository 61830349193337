import React from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';

const Footer = () => {
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const { darkModeStatus } = useDarkMode();

	return (
		<footer ref={ref} className='footer shadow-none' id='footer'>
			<div className='container-fluid'>
				<div className='row'>
					<div className='col'>
						{/* <span className='fw-light'>
							Copyright &copy; DOCPLIX SOLUTIONS PRIVATE LIMITED. All rights reserved
						</span> */}
					</div>
					<div className='col-auto'>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							{/* <small className='fw-bold'>Docplix EHR</small> */}
							<small>
								Copyright &copy; DOCPLIX SOLUTIONS PRIVATE LIMITED. All rights
								reserved
							</small>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
