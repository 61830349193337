import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import ReactNotification from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
// import { TourProvider } from '@reactour/tour';
import { useSelector, useDispatch } from 'react-redux';

import ThemeContext from '../contexts/themeContext';
import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import { authMenu, dashboardMenu } from '../menu';
import { getUserData, getUserPermissions } from '../actions/profileActions';
import { setInitialOrganisation } from '../actions/organisationActions';
import { getSelectedOrgBackend } from '../actions/helperActions';
import { getPatientList } from '../actions/patientActions';
import useNetwork from '../hooks/useNetwork';
import showNotification from '../components/extras/showNotification';
// import steps, { styles } from '../steps';

const App = () => {
	const { online, downLink } = useNetwork();
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	const loggedIn = useSelector((state) => state.auth.loggedIn);
	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		authMenu.login.path,
		authMenu.signUp.path,
		dashboardMenu.patients.subMenu.patientPrescriptionPdf.path,
		dashboardMenu.patients.subMenu.patientDiagnosesPdf.path,
		dashboardMenu.patients.subMenu.patientVoicePrescriptionPdf.path,
		dashboardMenu.patients.subMenu.patientBillingPdf.path,
		dashboardMenu.patients.subMenu.patientPaymentPdf.path,
		dashboardMenu.patients.subMenu.patientOpdPdf.path,
		dashboardMenu.patients.subMenu.patientHistoryPdf.path,
		dashboardMenu.patients.subMenu.patientVisionPdf.path,
		dashboardMenu.patients.subMenu.patientImmunPdf.path,
		dashboardMenu.patients.subMenu.patientImmunPdf.path,
		dashboardMenu.patients.subMenu.patientChemoNotePdf.path,

		dashboardMenu.ipd.subMenu.dischargeSummaryPdf.path,
		dashboardMenu.ipd.subMenu.dischargeSummary2Pdf.path,
		dashboardMenu.ipd.subMenu.ipdBillingPdf.path,
		dashboardMenu.ipd.subMenu.ipdPaymentsPdf.path,
		dashboardMenu.ipd.subMenu.faceCard.path,
		dashboardMenu.ipd.subMenu.ipdListPdf.path,
		dashboardMenu.ipd.subMenu.roomStatusPdf.path,
		// dashboardMenu.patients.subMenu.patientShortReport.path,
		// dashboardMenu.patients.subMenu.patientLongReport.path,
		dashboardMenu.patients.subMenu.patientDetailedLongReport.path,
		dashboardMenu.patients.subMenu.patientAttachmentPdf.path,
		dashboardMenu.lab.subMenu.billingPdf.path,
		dashboardMenu.lab.subMenu.labReportPdf.path,
		dashboardMenu.pharmacy.subMenu.saleVoucher.path,
		dashboardMenu.pharmacy.subMenu.gstReportsPrint.path,
		dashboardMenu.opd.subMenu.pdfHeader.path,
		dashboardMenu.accounts.subMenu.staffMisPDF.path,
		dashboardMenu.accounts.subMenu.printMis.path,
		dashboardMenu.consultWithDoctor.path,
		dashboardMenu.doctorJoinZoomMeeting.path,
		dashboardMenu.accounts.subMenu.printDrWise.path,
		dashboardMenu.accounts.subMenu.drWiseStaffMisPDF.path,
	];

	const dispatch = useDispatch();
	useEffect(() => {
		let mounted = true;

		const fetchAuth = async () => {
			// CHECK SELECTED ORG
			if (mounted && loggedIn) {
				// GET USER DATA
				dispatch(getUserData(user?.Vendor_detail?.vendorId));
				dispatch(getPatientList());

				const backendOrg = await getSelectedOrgBackend();
				if (backendOrg) dispatch(setInitialOrganisation(backendOrg));
				else dispatch(setInitialOrganisation());

				// GET STAFF PERMISSIONS
				if (
					user
					// && selectedOrg?.userType === 'staff'
				) {
					dispatch(
						getUserPermissions(
							selectedOrg?.organisationId,
							user?.Vendor_detail?.vendorId,
						),
					);
				}
			}
		};

		fetchAuth();

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (!online) {
			showNotification('You are OFFLINE', 'Please connect to a network', 'danger', 'wifi');
		}
	}, [online]);

	return (
		// <ThemeProvider theme={theme}>
		<ToastProvider components={{ ToastContainer, Toast }}>
			<div
				ref={ref}
				className='app'
				style={{
					backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
					zIndex: fullScreenStatus && 1,
					overflow: fullScreenStatus && 'scroll',
				}}>
				<Routes>
					{withOutAsidePages.map((path) => (
						<Route key={path} path={path} />
					))}
					<Route
						path='*'
						element={
							loggedIn ? (
								<Aside />
							) : (
								window.location.url !== '/auth-pages/login' && (
									<Navigate to='/auth-pages/login' />
								)
							)
						}
					/>
				</Routes>
				<Wrapper downloadSpeed={online ? downLink : 0} />
			</div>
			<Portal id='portal-notification'>
				<ReactNotification />
			</Portal>
		</ToastProvider>
		// </ThemeProvider>
	);
};

export default App;
