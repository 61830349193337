import {
	LOADING_TELE_CRM,
	NOT_LOADING_TELE_CRM,
	SET_CALL_LOGS,
	SET_SELECTED_CALL_LOG,
	SET_SELECTED_CALL_LOG_PATIENT,
} from './types';

const initialState = {
	callLogs: {
		docs: [],
		pages: 1,
		total: 1,
	},
	selectedCallLog: null,
	selectedCallLogPatient: null,
	error: null,
	loading: false,
};

const telecrmReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_TELE_CRM:
			return {
				...state,
				loading: true,
			};

		case NOT_LOADING_TELE_CRM:
			return {
				...state,
				loading: false,
			};

		case SET_SELECTED_CALL_LOG_PATIENT:
			return {
				...state,
				selectedCallLogPatient: action.payload,
				loading: false,
			};

		case SET_SELECTED_CALL_LOG:
			return {
				...state,
				selectedCallLog: action.payload,
				loading: false,
			};

		case SET_CALL_LOGS:
			return {
				...state,
				callLogs: {
					docs: action.payload.docs || [],
					pages: action.payload.pages || 1,
					total: action.payload.total || 1,
				},
				loading: false,
			};

		default:
			return state;
	}
};

export default telecrmReducer;
