import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Creatable from 'react-select/creatable';
import Select from 'react-select';
import moment from 'moment';

import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { getTreatments, getVisitingDoc } from '../../../actions/settingActions';
import {
	addIpdProcedure,
	deleteIpdProcedure,
	getIpdProcedures,
	updateIpdProcedure,
} from '../../../actions/ipdPatientActions';
import Tooltips from '../../../components/bootstrap/Tooltips';
import { getDoctors } from '../../../actions/doctorActions';
import { createTreatmentNow, isPermitted } from '../../../actions/helperActions';

const IpdProcedures = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const treatments = useSelector((state) => state.setting.treatments);
	const visitingDocs = useSelector((state) => state.ipd.visitingDoc || []);
	const procedures = useSelector((state) => state.ipdPatient.procedures || []);
	const doctors = useSelector((state) => state.doctor.doctors || []);

	const [addModal, setAddModal] = useState(false);
	const [editItem, setEditItem] = useState(null);

	const handleEdit = (procedure) => {
		setEditItem(procedure);
		formik.setValues((prev) => ({
			...prev,
			date: procedure?.Ipd_procedures?.[0]?.date,
			note: procedure?.Ipd_procedures?.[0]?.note,
			performedById: {
				label: procedure?.Ipd_procedures?.[0]?.Vendor?.Vendor_detail?.name,
				value: procedure?.Ipd_procedures?.[0]?.Vendor?.id,
			},
			treatmentId: procedure?.Ipd_procedures?.map((x) => ({
				label: x?.Treatment?.name,
				value: x?.Treatment?.id,
			})),
		}));
		setAddModal(true);
	};

	const formik = useFormik({
		initialValues: {
			ipdId,
			date: moment().format('YYYY-MM-DD'),
			treatmentId: [],
			performedById: '',
			note: '',
		},
		onSubmit: (values, { resetForm }) => {
			const data = { ...values };
			data['treatmentId'] = values.treatmentId?.map((x) => x.value);
			data['performedById'] = values.performedById?.value;

			console.log({ data });
			if (editItem) {
				data['ipdProcedureId'] = editItem?.id;
				dispatch(updateIpdProcedure(data));
			} else dispatch(addIpdProcedure(data));
			resetForm();
			setAddModal(false);
			setEditItem(null);
		},
	});

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			dispatch(getIpdProcedures(ipdId));
			dispatch(getTreatments());
			dispatch(getVisitingDoc());
			dispatch(getDoctors());
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>Procedures</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							isOutline={!darkModeStatus}
							color='primary'
							isLight={darkModeStatus}
							icon='add'
							onClick={() =>
								isPermitted('Ipd', 'write', () => setAddModal(true), 'Procedures')
							}>
							Add
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody isScrollable className='table-responsive'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>Date</th>
								<th>Procedures</th>
								<th>Performed By</th>
								<th>Note</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{procedures?.map((procedure) => (
								<tr>
									<td>
										{moment(procedure?.Ipd_procedures?.[0]?.date).format(
											'DD-MM-YYYY',
										)}
									</td>
									<td>
										{procedure?.Ipd_procedures?.map(
											(x) => x?.Treatment?.name,
										).join(', ')}
									</td>
									<td>
										{
											procedure?.Ipd_procedures?.[0]?.Vendor?.Vendor_detail
												?.name
										}
									</td>
									<td>{procedure?.Ipd_procedures?.[0]?.note}</td>
									<td>
										<Tooltips title='Edit'>
											<Button
												isLink
												isOutline={!darkModeStatus}
												color='primary'
												isLight={darkModeStatus}
												icon='edit'
												onClick={() =>
													isPermitted(
														'Ipd',
														'edit',
														() => handleEdit(procedure),
														'Procedures',
													)
												}></Button>
										</Tooltips>{' '}
										<Tooltips title='Delete'>
											<Button
												isLink
												isOutline={!darkModeStatus}
												color='danger'
												isLight={darkModeStatus}
												icon='delete'
												onClick={() => {
													isPermitted(
														'Ipd',
														'delete',
														() =>
															dispatch(
																deleteIpdProcedure(
																	procedure?.id,
																	procedure?.ipdId,
																),
															),
														'Procedures',
													);
												}}></Button>
										</Tooltips>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>
			<Modal isOpen={addModal} isCentered isAnimation={false} size={'xl'}>
				<ModalBody>
					<ModalHeader setIsOpen={setAddModal}>
						<ModalTitle>Add Procedures</ModalTitle>
					</ModalHeader>
					<Card className='shadow-none'>
						<CardBody>
							<div className='row'>
								<div className='col-6'>
									<FormGroup label={`Date`} id='date'>
										<Input
											value={formik.values.date}
											type='date'
											onChange={formik.handleChange}
											name='date'
											style={{
												height: '3.5rem',
											}}
										/>
									</FormGroup>
								</div>
								<div className='col-6'>
									<FormGroup label={`Procedures`} id='procedure'>
										<Creatable
											isMulti
											className='form-control'
											styles={{
												control: (prev) => ({
													height: '2.6rem',
													alignItems: 'center',
													position: 'relative',
													display: 'flex',
												}),
											}}
											options={treatments?.map((i) => ({
												label: `${i.name} ${
													i?.Treatment_organisation_tag?.Treatment_tag
														?.name
														? `(${i?.Treatment_organisation_tag?.Treatment_tag?.name})`
														: ''
												}`,
												value: i?.id,
											}))}
											value={formik.values.treatmentId}
											onChange={(e) => formik.setFieldValue('treatmentId', e)}
											onCreateOption={async (e) => {
												const res = await createTreatmentNow({
													name: e,
													perUnitPrice: 0,
												});
												formik.setFieldValue('treatmentId', [
													...formik.values.treatmentId,
													{ label: res?.name, value: res?.id },
												]);
											}}
										/>
									</FormGroup>
								</div>
								<div className='col-6'>
									<FormGroup label={`Performed by`} id='performedby'>
										<Select
											className='form-control'
											styles={{
												control: (prev) => ({
													height: '2.6rem',
													alignItems: 'center',
													position: 'relative',
													display: 'flex',
												}),
											}}
											options={[
												...visitingDocs,
												...doctors.map((x) => x.Vendor_detail),
											]?.map((i) => ({
												label: i?.name,
												value: i?.vendorId,
											}))}
											value={formik.values.performedById}
											onChange={(e) =>
												formik.setFieldValue('performedById', e)
											}
										/>
									</FormGroup>
								</div>
								<div className='col-6'>
									<FormGroup label={`Notes`} id='note'>
										<Input
											placeholder='Notes here...'
											value={formik.values.note}
											type='text'
											onChange={formik.handleChange}
											name='note'
											style={{
												height: '3.5rem',
											}}
										/>
									</FormGroup>
								</div>
							</div>
						</CardBody>
						<CardFooter>
							<CardFooterRight>
								<Button
									isOutline={!darkModeStatus}
									color='primary'
									isLight={darkModeStatus}
									icon='save'
									onClick={formik.handleSubmit}>
									Save
								</Button>
							</CardFooterRight>
						</CardFooter>
					</Card>
				</ModalBody>
			</Modal>
		</>
	);
};

export default IpdProcedures;
