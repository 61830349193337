import {
	SET_APPOINTMENTS,
	LOADING_APPOINTMENTS,
	CREATE_APPOINTMENT,
	UPDATE_APPOINTMENT,
	DELETE_APPOINTMENT,
	SET_APPOINTMENT_SLOTS,
	SET_CALENDAR_APPOINTMENTS,
	SET_APPOINTMENTS_OF_REMOTE_CLINIC,
	SET_APPOINTMENTS_FROM_REMOTE_CLINIC,
} from './types';

const initialState = {
	appointments: [],
	page: 0,
	total: 0,
	slots: [],
	calendarAppointments: [],
	error: null,
	loading: false,
	appointmentsOfRemoteClinic: [],
	appointmentsFromRemoteClinic: [],
	pageOfRemoteClinic:0,
	totalOfRemoteClinic:0,
	pageFromRemoteClinic:0,
	totalFromRemoteClinic:0,
};

const appointmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_APPOINTMENTS:
			return {
				...state,
				loading: true,
			};

		case SET_APPOINTMENTS:
			return {
				...state,
				appointments: action.payload.appointments || [],
				page: action.payload.page || 0,
				total: action.payload.total || 0,
				loading: false,
			};

		case SET_CALENDAR_APPOINTMENTS:
			return {
				...state,
				calendarAppointments: action.payload || [],
				loading: false,
			};

		case CREATE_APPOINTMENT:
			return {
				...state,
				appointments: [action.payload, ...state.appointments],
			};

		case UPDATE_APPOINTMENT:
			const appointmentIdx = _.findIndex(
				state.appointments,
				(i) => i.id === action.payload.appointmentId,
			);
			return {
				...state,
				appointments: [
					...state.appointments.slice(0, appointmentIdx),
					action.payload,
					...state.appointments.slice(appointmentIdx + 1),
				],
			};

		case DELETE_APPOINTMENT:
			return {
				...state,
				appointments: _.reject(state.appointments, (org) => org.id === action.payload),
			};

		case SET_APPOINTMENT_SLOTS:
			return {
				...state,
				slots: action.payload || [],
			};
		case SET_APPOINTMENTS_OF_REMOTE_CLINIC:
			return {
				...state,
				appointmentsOfRemoteClinic: action.payload.appointments || [],
				pageOfRemoteClinic: action.payload.page || 0,
				totalOfRemoteClinic: action.payload.total || 0,
				loading: false,
			};
		case SET_APPOINTMENTS_FROM_REMOTE_CLINIC:
			return {
				...state,
				appointmentsFromRemoteClinic: action.payload.appointments || [],
				pageFromRemoteClinic: action.payload.page || 0,
				totalFromRemoteClinic: action.payload.total || 0,
				loading: false,

			}

		default:
			return state;
	}
};

export default appointmentReducer;
