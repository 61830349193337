import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import RichTextEditor from 'react-rte';

import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { useFormik } from 'formik';
import moment from 'moment';
import { createLabourRecord, getLabourRecord } from '../../../actions/ipdPatientActions';
import { toggleCreateLoading } from '../../../actions/componentActions';

const IpdLabourRecord = () => {
	const dispatch = useDispatch();

	const patientId = useSelector((state) => state.ipd.patientIpdFile?.patientId);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const labourRecord = useSelector((state) => state.ipdPatient.labourRecord);

	const formik = useFormik({
		initialValues: {
			ipdId,
			labourNature: labourRecord?.labourNature || '',
			deliveryDateTime:
				moment(labourRecord?.deliveryDateTime).format('YYYY-MM-DDThh:ss') || '',
			gender: labourRecord?.gender
				? {
						value: labourRecord?.gender,
						label: labourRecord?.gender,
				  }
				: '',
		},
		onSubmit: (values) => {
			const data = { ...values };
			data['gender'] = values.gender?.label;
			data['deliveryDateTime'] = moment(values.deliveryDateTime).format(
				'YYYY-MM-DD hh:mm:ss',
			);
			dispatch(toggleCreateLoading(true));
			dispatch(createLabourRecord(data));
		},
	});

	useEffect(() => dispatch(getLabourRecord(ipdId)), []);

	return (
		<Card stretch className='shadow-none'>
			<CardHeader>
				<CardLabel icon='contacts' iconColor='primary'>
					<CardTitle>Labour Record</CardTitle>
				</CardLabel>
				<CardActions>
					<Button isOutline color='primary' icon='save' onClick={formik.handleSubmit}>
						Save
					</Button>
				</CardActions>
			</CardHeader>
			<CardBody isScrollable>
				<div className='row'>
					<div className='col-12 mb-2'>
						<FormGroup label={`Nature of Labour`}>
							<Input
								style={{
									fontWeight: 'normal',
									padding: '0.60rem',
									height: '3.5rem',
								}}
								className='form-control'
								value={formik.values.labourNature}
								name='labourNature'
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-6 mb-2'>
						<FormGroup label={`Delivery Date & Time`}>
							<Input
								type='datetime-local'
								style={{
									fontWeight: 'normal',
									padding: '0.60rem',
									height: '3.5rem',
								}}
								className='form-control'
								value={formik.values.deliveryDateTime}
								name='deliveryDateTime'
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-6 mb-2'>
						<FormGroup label={`Gender`}>
							<Select
								className='form-control'
								value={formik.values.gender}
								options={[
									{ label: 'Male', value: 'Male' },
									{ label: 'Female', value: 'Female' },
								]}
								styles={{
									control: () => ({
										height: '2.6rem',
										alignItems: 'center',
										position: 'relative',
										display: 'flex',
									}),
								}}
								onChange={(e) => formik.setFieldValue(`gender`, e)}
							/>
						</FormGroup>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default IpdLabourRecord;
