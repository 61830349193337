import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Tooltips from '../../../components/bootstrap/Tooltips';
import axios from 'axios';
import { getIpdPatientHistory } from '../../../actions/ipdPatientActions';
import { isPermitted } from '../../../actions/helperActions';
import { SET_IPDFILES, SET_IPDFILE_MODAL } from '../../../reducers/types';
axios.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');

const IpdFiles = ({ patientId }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const ipdFilesData = useSelector((state) => state.ipdPatient.ipdFiles);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			const fetchOthers = async () => {
				dispatch(getIpdPatientHistory(patientId));
			};
			fetchOthers();
		}
		return () => {
			mounted = false;
			dispatch({
				type: SET_IPDFILES,
				payload: [],
			});
		};
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>IPD History</CardTitle>
					</CardLabel>
					<CardActions></CardActions>
				</CardHeader>
				<CardBody className='pb-5'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>S.No.</th>
								<th>Date/Time of Admission</th>
								<th>Date/Time of Discharge</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{ipdFilesData?.map((ipdFileData, index) => (
								<tr key={index}>
									<td>{index + 1}</td>
									<td>
										{moment(ipdFileData?.createdAt).format(
											'DD-MM-YYYY / hh:mm:ss a',
										)}
									</td>
									<td>
										{ipdFileData?.dischargeDatetime
											? moment(
													new Date(
														parseInt(ipdFileData?.dischargeDatetime),
													),
											  ).format('DD-MM-YYYY / hh:mm:ss a')
											: '-'}
									</td>
									<td>
										<Tooltips title='View'>
											<Button
												isLink
												isOutline={!darkModeStatus}
												color='primary'
												isLight={darkModeStatus}
												icon='eye'
												onClick={() => {
													isPermitted('Ipd', 'read', () =>
														window.open(
															`/ipd/${ipdFileData?.id}/patient/${patientId}`,
														),
													);
													dispatch({
														type: SET_IPDFILE_MODAL,
														payload: false,
													});
												}}
											/>
										</Tooltips>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>
		</>
	);
};

export default IpdFiles;
