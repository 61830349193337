import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PaginationButtons, {
	PER_COUNT,
	dataPagination,
} from '../../../components/PaginationButtons';
import Button from '../../../components/bootstrap/Button';
import {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Checks from '../../../components/bootstrap/forms/Checks';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Label from '../../../components/bootstrap/forms/Label';
import useDarkMode from '../../../hooks/useDarkMode';
import useSelectTable from '../../../hooks/useSelectTable';
import useSortableData from '../../../hooks/useSortableData';
import { renderRupee } from '../../../helpers/helpers';

const validate = (values) => {
	const errors = {};

	if (!values.paymentMode) {
		errors.paymentMode = 'Required';
	}
	if (!values.amountPaid) {
		errors.amountPaid = 'Required';
	}
	if (!values.date) {
		errors.date = 'Required';
	}

	return errors;
};

const PatientOpdPayments = ({ patient }) => {
	const userId = patient?.id;
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const hideBill = useSelector((state) => state.profile.selectedOrg?.Organisation?.isHiddenBill);

	const [editMode, setEditMode] = useState(false);
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState(null);

	const [dropdown, setDropdown] = useState(false);

	const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
	const [toDate, setToDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
	const resetDates = () => {
		setFromDate(moment().startOf('month').format('YYYY-MM-DD'));
		setToDate(moment().endOf('month').format('YYYY-MM-DD'));
	};

	const data = useSelector((state) => state.patient.payments);
	const refunds = useSelector((state) => state.patient.refundPayments || []);
	const bills = useSelector((state) => state.patient.bills);
	const payments = useSelector((state) => state.patient.payments);
	const billingAmt = useMemo(
		() => bills.reduce((i, j) => i + parseFloat(j.billTotal) || 0, 0),
		[bills],
	);
	const opdAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => i?.paymentFrom === 'OPD')
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const paidAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => !i.isEst)
				.filter((i) => i?.paymentFrom !== 'OPD')
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const estAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => i.isEst)
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const refundAmt = useMemo(
		() =>
			refunds
				.filter((i) => i.patientId === userId)
				.reduce((a, b) => a + parseFloat(b.amount || 0), 0),
		[refunds],
	);
	const pendingAmt = useMemo(
		() => billingAmt - paidAmt - refundAmt,
		[billingAmt, paidAmt, refundAmt],
	);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(
		data.filter((i) => i.userId === userId).filter((i) => i?.paymentFrom === 'OPD'),
	);
	items?.sort((a, b) => (a.date > b.date ? -1 : 1));
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);

	const handleDateRangePrint = () => {
		navigate(`/patients/${userId}/opd-pdf`, {
			state: {
				patient,
				payments: items?.filter(
					(i) =>
						new Date(i.date).getTime() >=
							new Date(fromDate + 'T23:59:59.000Z').getTime() &&
						new Date(i.date).getTime() <= new Date(toDate + 'T23:59:59.000Z').getTime(),
				),
			},
		});
	};

	useEffect(() => {
		let mounted = true;

		const fetchOthers = () => {
			if (mounted) {
				// dispatch(getPatientPayments(userId));
				// dispatch(getPatientBills(userId));
			}
		};

		fetchOthers();
		return () => {
			mounted = false;
		};
	}, [editMode, deleteItemId]);

	return (
		<>
			<CardHeader>
				<CardLabel icon='Payments' iconColor='primary'>
					<CardTitle>OPD Payments</CardTitle>
					<CardSubTitle>
						<span className='me-3'>Billing Amount: {renderRupee(billingAmt)}</span>
						<span className='me-3'>OPD Payments: {renderRupee(opdAmt)}</span>
						{hideBill && (
							<span className='me-3'>Estmiate payments: {renderRupee(estAmt)}</span>
						)}
						<span className='me-3'>Other Payments: {renderRupee(paidAmt)}</span>
						<span className='me-3' style={{ color: 'red' }}>
							Balance Pending: {renderRupee(pendingAmt)}
						</span>
					</CardSubTitle>
				</CardLabel>
				<CardActions>
					<div className='d-flex'>
						{/* <Button
							className='me-2'
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='add'
							onClick={() =>
								isPermitted(permissions, 'Billing', 'write', selectedOrg?.userType, () =>
									setEditMode(true),
								)
							}
							aria-label='Create Treatment'>
							Add
						</Button> */}
						{selectTable.values.selectedList?.length === 0 ? (
							<Dropdown isOpen={dropdown} setIsOpen={setDropdown}>
								<DropdownToggle hasIcon={false} outsideClickHide={false}>
									<Button
										icon='Print'
										color='primary'
										isOutline
										data-tour='filter'>
										Print
									</Button>
								</DropdownToggle>
								<DropdownMenu
									style={{ width: '30rem', right: 0 }}
									isAlignmentEnd
									size='lg'
									isCloseAfterLeave={false}
									data-tour='filter-menu'>
									<div className='container py-2'>
										<form className='row g-3' onSubmit={() => {}}>
											<div className='col-12'>
												<FormGroup>
													<Label htmlFor='minDate'>
														Select Date Range
													</Label>
													<InputGroup>
														<Input
															type='date'
															id='minDate'
															ariaLabel='From Date'
															placeholder='From'
															value={fromDate}
															onChange={(e) =>
																setFromDate(e.target.value)
															}
														/>
														<InputGroupText>to</InputGroupText>
														<Input
															type='date'
															id='maxDate'
															ariaLabel='To Date'
															placeholder='To'
															value={toDate}
															onChange={(e) =>
																setToDate(e.target.value)
															}
														/>
													</InputGroup>
												</FormGroup>
											</div>
											<div className='col-6'>
												<Button
													color='primary'
													isOutline
													className='w-100'
													onClick={resetDates}>
													Reset
												</Button>
											</div>
											<div className='col-6'>
												<Button
													color='primary'
													className='w-100'
													onClick={handleDateRangePrint}>
													Print
												</Button>
											</div>
										</form>
									</div>
								</DropdownMenu>
							</Dropdown>
						) : (
							<Button
								isDisable={selectTable.values.selectedList.length === 0}
								isOutline={!darkModeStatus}
								isLight={darkModeStatus}
								color='primary'
								icon='print'
								onClick={() => {
									navigate(`/patients/${userId}/opd-pdf`, {
										state: {
											patient,
											payments: onCurrentPageItems.filter((i) =>
												selectTable.values.selectedList.includes(
													i.id.toString(),
												),
											),
										},
									});
								}}>
								Print
							</Button>
						)}
					</div>
				</CardActions>
			</CardHeader>
			<CardBody style={{ height: '54vh' }} className='table-responsive' isScrollable>
				<table className='table table-modern'>
					<thead>
						<tr>
							<th>{SelectAllCheck}</th>
							<th>Date</th>
							<th>Amount paid</th>
							<th>Payment Mode</th>
							<td />
						</tr>
					</thead>
					<tbody>
						{onCurrentPageItems.map(
							(item) =>
								item?.amountPaid && (
									<tr key={item.id}>
										<td>
											<Checks
												id={item?.id?.toString()}
												name={'selectedList'}
												value={item?.id}
												onChange={selectTable.handleChange}
												checked={selectTable.values.selectedList.includes(
													item?.id?.toString(),
												)}
											/>
										</td>
										<td>{moment(item.date).format('DD-MM-YYYY')}</td>
										<td>{renderRupee(item.amountPaid)}</td>
										<td>{item.paymentMode || '-'}</td>
										<td>
											<Tooltips title='Print'>
												<Button
													isOutline={!darkModeStatus}
													color='primary'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='print'
													onClick={() =>
														navigate(`/patients/${userId}/opd-pdf`, {
															state: {
																patient,
																payments: [item],
															},
														})
													}></Button>
											</Tooltips>
											{/* <Tooltips title='Delete'>
										<Button
											isOutline={!darkModeStatus}
											color='danger'
											isLight={darkModeStatus}
											className={classNames('text-nowrap', {
												'border-light': !darkModeStatus,
											})}
											icon='delete'
											onClick={() =>
												isPermitted(
													permissions,
													'Billing',
													'delete',
													selectedOrg?.userType,
													() => handleDeleteTreatment(item.id),
												)
											}></Button>
									</Tooltips> */}
										</td>
									</tr>
								),
						)}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				data={items}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
			/>
		</>
	);
};

export default PatientOpdPayments;
