import { Augnito, AugnitoAPIServer } from 'augnitosdk';
import { store } from '../store/configureStore';

export const initializeAugnito = () =>
	new Augnito({
		server: AugnitoAPIServer.INDIA,
		accountCode: process.env.REACT_APP_ACCOUNT_CODE,
		accessKey: process.env.REACT_APP_ACCESS_KEY,
		userTag: store.getState()?.auth?.user?.Vendor_detail?.vendorId || 'guy07',
		sourceApp: 'docplix-ehr-dr',
		lmId: '211801200',
	});
