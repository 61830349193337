import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card, {
	CardActions,
	CardBody,
	CardFooterRight,
	CardFooter,
	CardHeader,
	CardLabel,
	CardTitle,
	CardSubTitle,
} from '../../../components/bootstrap/Card';
import Select from '../../../components/bootstrap/forms/Select';
import Button from '../../../components/bootstrap/Button';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Modal, {
	ModalTitle,
	ModalBody,
	ModalHeader,
	ModalFooter,
} from '../../../components/bootstrap/Modal';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import { OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import useDarkMode from '../../../hooks/useDarkMode';
import {
	createPatientPayment,
	getPatientPayments,
	deletePatientPayment,
	getPatientBills,
} from '../../../actions/patientActions';
import { invalidNums, renderNumber, renderRupee } from '../../../helpers/helpers';
import { getBillTypes, getCardNetworks, isPermitted } from '../../../actions/helperActions';
import useSelectTable from '../../../hooks/useSelectTable';
import Checks from '../../../components/bootstrap/forms/Checks';
import Label from '../../../components/bootstrap/forms/Label';
import { getPaymentMethods } from '../../../actions/saleVoucherActions';
import Icon from '../../../components/icon/Icon';

const validate = (values) => {
	const errors = {};

	if (!values.paymentMode) {
		errors.paymentMode = 'Required';
	}
	if (!values.amountPaid) {
		errors.amountPaid = 'Required';
	}
	if (!values.date) {
		errors.date = 'Required';
	}
	if (values.cardNo && `${values.cardNo}`.length != 16) {
		errors.cardNo = 'Card No should be 16 digits';
	}
	if (values.cardNo && !/^\d+$/.test(values.cardNo)) {
		errors.cardNo = 'Card No should only contain numbers';
	}
	if (values.utrNo && `${values.utrNo}`.length != 12) {
		errors.utrNo = 'Reference no should be 12 digits';
	}
	if (values.utrNo && !/^\d+$/.test(values.utrNo)) {
		errors.utrNo = 'Reference No should only contain numbers';
	}

	return errors;
};

const PatientPayment = ({ patient }) => {
	const userId = patient?.id;
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const hideBill = useSelector((state) => state.profile.selectedOrg?.Organisation?.isHiddenBill);

	// Card Networks
	const [cardNetworks, setCardNetworks] = useState([]);

	const [editMode, setEditMode] = useState(false);
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState(null);
	const [billTypes, setBillTypes] = useState({});

	const handleDeleteTreatment = (id) => {
		setDeleteItemId(id);
		setDeleteWarn(true);
	};

	const handleDeleteConfirm = () => {
		dispatch(deletePatientPayment(deleteItemId));
		setDeleteItemId(null);
		setDeleteWarn(false);
	};

	const [dropdown, setDropdown] = useState(false);

	const [fromDate, setFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
	const [toDate, setToDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
	const resetDates = () => {
		setFromDate(moment().startOf('month').format('YYYY-MM-DD'));
		setToDate(moment().endOf('month').format('YYYY-MM-DD'));
	};
	// END :: Upcoming Events

	const formik = useFormik({
		initialValues: {
			userId: '',
			paymentMode: '',
			amountPaid: '',
			date: moment().format('YYYY-MM-DD').toString(),
			isEst: 0,
			cardNo: '',
			cardNetwork: null,
			utrNo: '',
		},
		validate,
		validateOnChange: true,
		onSubmit: (values, { resetForm }) => {
			const data = {};
			for (var key in values) {
				if (values[key] == '' || values[key] == null) continue;
				data[key] = `${values[key]}`;
			}
			data['userId'] = userId;
			data['isEst'] = values.isEst == 1;
			dispatch(createPatientPayment(data));

			resetForm();
			setEditMode(false);
		},
	});

	useEffect(() => {
		formik.setFieldValue('cardNo', '');
		formik.setFieldValue('cardNetwork', null);
		formik.setFieldValue('utrNo', '');
	}, [formik.values.paymentMode]);

	const data = useSelector((state) => state.patient.payments);
	const paymentModes = useSelector((state) => state.saleVoucher.paymentMethods);
	const refunds = useSelector((state) => state.patient.refundPayments || []);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(
		data.filter((i) => i.userId === userId),
	);
	items?.sort((a, b) => (a.date > b.date ? -1 : 1));
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);

	const bills = useSelector((state) => state.patient.bills);
	const payments = useSelector((state) => state.patient.payments);
	const billingAmt = useMemo(
		() => bills.reduce((i, j) => i + parseFloat(j.billTotal) || 0, 0),
		[bills],
	);
	const opdAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => i?.paymentFrom === 'OPD')
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const paidAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => !i.isEst)
				.filter((i) => i?.paymentFrom !== 'OPD')
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const estAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => i.isEst)
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const refundAmt = useMemo(
		() =>
			refunds
				.filter((i) => i.patientId === userId)
				.reduce((a, b) => a + parseFloat(b.amount || 0), 0),
		[refunds],
	);
	const pendingAmt = useMemo(
		() => billingAmt - paidAmt - refundAmt,
		[billingAmt, paidAmt, refundAmt],
	);

	const handleDateRangePrint = () => {
		navigate(`/patients/${userId}/payment-pdf`, {
			state: {
				patient,
				payments: items?.filter(
					(i) =>
						new Date(i.date).getTime() >=
							new Date(fromDate + 'T23:59:59.000Z').getTime() &&
						new Date(i.date).getTime() <= new Date(toDate + 'T23:59:59.000Z').getTime(),
				),
				pendingAmt: pendingAmt,
			},
		});
	};

	useEffect(() => {
		let mounted = true;
		const fetchOthers = async () => {
			if (mounted) {
				setCardNetworks(await getCardNetworks());
				getBillTypes().then((res) => setBillTypes(res));
			}
		};

		fetchOthers();
		return () => {
			mounted = false;
		};
	}, [editMode, deleteItemId]);

	return (
		<>
			<CardHeader>
				<CardLabel icon='Payments' iconColor='primary'>
					<CardTitle>Payments</CardTitle>
					<CardSubTitle>
						<span className='me-3'>Billing Amount: {renderRupee(billingAmt)}</span>
						<span className='me-3'>OPD Payments: {renderRupee(opdAmt)}</span>
						{hideBill && (
							<span className='me-3'>Estimate Payments: {renderRupee(estAmt)}</span>
						)}
						<span className='me-3'>Other Payments: {renderRupee(paidAmt)}</span>
						<span className='me-3' style={{ color: 'red' }}>
							Balance Pending: {renderRupee(pendingAmt)}
						</span>
					</CardSubTitle>
				</CardLabel>
				<CardActions>
					<div className='d-flex'>
						<Button
							className='me-2'
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='add'
							onClick={() => isPermitted('Billing', 'write', () => setEditMode(true))}
							aria-label='Create Treatment'>
							Add
						</Button>
						{selectTable.values.selectedList?.length === 0 ? (
							<Dropdown isOpen={dropdown} setIsOpen={setDropdown}>
								<DropdownToggle hasIcon={false} outsideClickHide={false}>
									<Button
										icon='Print'
										color='primary'
										isOutline
										data-tour='filter'>
										Print
									</Button>
								</DropdownToggle>
								<DropdownMenu
									style={{ width: '30rem', right: 0 }}
									isAlignmentEnd
									size='lg'
									isCloseAfterLeave={false}
									data-tour='filter-menu'>
									<div className='container py-2'>
										<form className='row g-3' onSubmit={() => {}}>
											<div className='col-12'>
												<FormGroup>
													<Label htmlFor='minDate'>
														Select Date Range
													</Label>
													<InputGroup>
														<Input
															type='date'
															id='minDate'
															ariaLabel='From Date'
															placeholder='From'
															value={fromDate}
															onChange={(e) =>
																setFromDate(e.target.value)
															}
														/>
														<InputGroupText>to</InputGroupText>
														<Input
															type='date'
															id='maxDate'
															ariaLabel='To Date'
															placeholder='To'
															value={toDate}
															onChange={(e) =>
																setToDate(e.target.value)
															}
														/>
													</InputGroup>
												</FormGroup>
											</div>
											<div className='col-6'>
												<Button
													color='primary'
													isOutline
													className='w-100'
													onClick={resetDates}>
													Reset
												</Button>
											</div>
											<div className='col-6'>
												<Button
													color='primary'
													className='w-100'
													onClick={handleDateRangePrint}>
													Print
												</Button>
											</div>
										</form>
									</div>
								</DropdownMenu>
							</Dropdown>
						) : (
							<Button
								isDisable={selectTable.values.selectedList.length === 0}
								isOutline={!darkModeStatus}
								isLight={darkModeStatus}
								color='primary'
								icon='print'
								onClick={() => {
									navigate(`/patients/${userId}/payment-pdf`, {
										state: {
											patient,
											payments: onCurrentPageItems.filter((i) =>
												selectTable.values.selectedList.includes(
													i.id.toString(),
												),
											),
											pendingAmt: pendingAmt,
										},
									});
								}}>
								Print
							</Button>
						)}
					</div>
				</CardActions>
			</CardHeader>
			<CardBody style={{ height: '54vh' }} className='table-responsive' isScrollable>
				<table className='table table-modern'>
					<thead>
						<tr>
							<th>{SelectAllCheck}</th>
							<th>Date</th>
							<th>Amount paid</th>
							<th>Mode of Payment</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{onCurrentPageItems.map((item) => {
							const mode = item?.paymentMode;
							const network =
								cardNetworks?.find((i) => i.id === item?.cardNetwork)?.name || null;
							const paymentInfo =
								mode === 'Credit/Debit Card'
									? `Card No: ${item?.cardNo} ${network && `(${network})`}`
									: mode === 'UPI'
									? `UTR No: ${item?.utrNo}`
									: '';

							return (
								<tr key={item.id}>
									<td>
										<Checks
											id={item?.id?.toString()}
											name={'selectedList'}
											value={item?.id}
											onChange={selectTable.handleChange}
											checked={selectTable.values.selectedList.includes(
												item?.id?.toString(),
											)}
										/>
									</td>
									<td>{moment(item.date).format('DD-MM-YYYY')}</td>
									<td>{renderRupee(item?.amountPaid)}</td>
									<td>
										{item?.paymentMode}
										{paymentInfo && (
											<Tooltips title={paymentInfo}>
												<Icon
													icon='Info'
													color='primary'
													size='lg'
													className='ms-2'
												/>
											</Tooltips>
										)}
									</td>
									<td>
										<Tooltips title='Print'>
											<Button
												isOutline={!darkModeStatus}
												color='primary'
												isLight={darkModeStatus}
												className={classNames('text-nowrap', {
													'border-light': !darkModeStatus,
												})}
												icon='print'
												onClick={() =>
													navigate(`/patients/${userId}/payment-pdf`, {
														state: {
															patient,
															payments: [item],
															pendingAmt: pendingAmt,
														},
													})
												}></Button>
										</Tooltips>
										<Tooltips title='Delete'>
											<Button
												isOutline={!darkModeStatus}
												color='danger'
												isLight={darkModeStatus}
												className={classNames('text-nowrap', {
													'border-light': !darkModeStatus,
												})}
												icon='delete'
												onClick={() =>
													isPermitted('Billing', 'delete', () =>
														handleDeleteTreatment(item.id),
													)
												}></Button>
										</Tooltips>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</CardBody>
			<PaginationButtons
				data={items}
				label='items'
				setCurrentPage={setCurrentPage}
				currentPage={currentPage}
				perPage={perPage}
				setPerPage={setPerPage}
			/>
			<Modal
				isOpen={editMode}
				setIsOpen={setEditMode}
				size='xl'
				titleId='upcomingEdit'
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setEditMode}>
					<OffCanvasTitle id='upcomingEdit'>Add New</OffCanvasTitle>
				</ModalHeader>
				<ModalBody>
					<Card stretch tag='form' noValidate onSubmit={formik.handleSubmit}>
						<CardBody>
							<div className='row g-4'>
								<div className={hideBill ? 'col-6' : 'col-4'}>
									<FormGroup id='date' label='Date'>
										<Input
											type='date'
											style={{
												height: 'calc(3.5rem + 2px)',
											}}
											placeholder='Date'
											autoComplete='date'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.date}
											isValid={formik.isValid}
											isTouched={formik.touched.date}
											invalidFeedback={formik.errors.date}
										/>
									</FormGroup>
								</div>
								<div className={hideBill ? 'col-6' : 'col-4'}>
									<FormGroup id='paymentMode' label='Mode of Payment *'>
										<Select
											style={{
												height: 'calc(3.5rem + 2px)',
											}}
											ariaLabel='select-payment-mode'
											placeholder='Select...'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											isValid={formik.isValid}
											isTouched={formik.touched.paymentMode}
											invalidFeedback={formik.errors.paymentMode}
											value={formik.values.paymentMode}
											list={paymentModes.map((i) => ({ value: i, text: i }))}
										/>
									</FormGroup>
								</div>
								{formik.values.paymentMode === 'Credit/Debit Card' && (
									<>
										<div className='col-6'>
											<FormGroup id='cardNo' label='Card No'>
												<Input
													style={{
														height: 'calc(3.5rem + 2px)',
													}}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.cardNo}
													isValid={formik.isValid}
													isTouched={formik.touched.cardNo}
													invalidFeedback={formik.errors.cardNo}
												/>
											</FormGroup>
										</div>

										<div className='col-6'>
											<FormGroup id='cardNetwork' label='Card Network'>
												<Select
													style={{
														height: 'calc(3.5rem + 2px)',
													}}
													placeholder='Select...'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={formik.touched.cardNetwork}
													invalidFeedback={formik.errors.cardNetwork}
													value={formik.values.cardNetwork}
													list={cardNetworks.map(({ id, name }) => ({
														value: id,
														text: name,
													}))}
												/>
											</FormGroup>
										</div>
									</>
								)}
								{formik.values.paymentMode === 'UPI' && (
									<div className='col-12'>
										<FormGroup id='utrNo' label='Reference No'>
											<Input
												style={{
													height: 'calc(3.5rem + 2px)',
												}}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.utrNo}
												isValid={formik.isValid}
												isTouched={formik.touched.utrNo}
												invalidFeedback={formik.errors.utrNo}
											/>
										</FormGroup>
									</div>
								)}
								<div className={hideBill ? 'col-6' : 'col-4'}>
									<FormGroup id='amountPaid' label='Amount Paid'>
										<Input
											style={{
												height: 'calc(3.5rem + 2px)',
											}}
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											placeholder='0'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.amountPaid}
											isValid={formik.isValid}
											isTouched={formik.touched.amountPaid}
											invalidFeedback={formik.errors.amountPaid}
											validFeedback='Looks good!'
										/>
									</FormGroup>
								</div>
								{hideBill && (
									<div className='col-6'>
										<FormGroup id='isEst' label='Bill Type'>
											<Select
												style={{
													height: 'calc(3.5rem + 2px)',
												}}
												className='form-control'
												list={Object.entries(billTypes).map(
													([key, value]) => ({
														text: key,
														value: value,
													}),
												)}
												value={formik.values.isEst}
												onChange={formik.handleChange}
											/>
										</FormGroup>
									</div>
								)}
							</div>
						</CardBody>
						<CardFooter>
							<CardFooterRight>
								<Button
									type='submit'
									icon='Add'
									color='primary'
									isOutline
									isDisable={!formik.isValid && !!formik.submitCount}>
									Add Payment
								</Button>
							</CardFooterRight>
						</CardFooter>
					</Card>
				</ModalBody>
			</Modal>
			<Modal
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteTreatmentLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete the payment?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button color='danger' icon='delete' onClick={handleDeleteConfirm}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default PatientPayment;
