import {
	SET_STAFF,
	CREATE_STAFF,
	UPDATE_STAFF,
	DELETE_STAFF,
	SET_STAFF_ATTENDANCE,
	CREATE_STAFF_ATTENDANCE,
	UPDATE_STAFF_ATTENDANCE,
	DELETE_STAFF_ATTENDANCE,
	SET_STAFF_SALARY,
	CREATE_STAFF_SALARY,
	UPDATE_STAFF_SALARY,
	DELETE_STAFF_SALARY,
	SET_DR_WISE_DATA_FOR_STAFF,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import { toggleCreateLoading } from './componentActions';

export const getStaff = (orgId) => async (dispatch) => {
	console.log('running get staff');
	try {
		const res = await axios.get(`doctor/staff?organisationId=${orgId}`);
		if (res.data.success) {
			dispatch({
				type: SET_STAFF,
				payload: res.data.staffs,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getStaffAttendance = (date) => async (dispatch) => {
	console.log('running get staffAttendance');
	try {
		const res = await axios.get(`/doctor/staff-attendance?date=${date}`);
		if (res.data.success) {
			dispatch({
				type: SET_STAFF_ATTENDANCE,
				payload: res.data.attendance,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createStaff = (newStaff, navigate, orgId) => async (dispatch) => {
	try {
		const res = await axios.post('doctor/staff', newStaff);
		if (res.data.success) {
			showNotification('Success', 'The STAFF is created successfully', 'success');
			dispatch(getStaff(orgId));
			navigate(`/${dashboardMenu.staff.subMenu.viewStaff.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateStaff = (updateBody, navigate, orgId) => async (dispatch) => {
	try {
		const res = await axios.patch('doctor/staff', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The STAFF is updated successfully', 'success');
			dispatch(getStaff(orgId));
			navigate(`/${dashboardMenu.staff.subMenu.viewStaff.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

// helper action
export const removeStaffImage = async (staffId) => {
	try {
		const res = await axios.patch(`/doctor/staff/remove-profile-image/${staffId}`);
		if (res.data.success) {
			showNotification('Success', 'The patient image is removed successfully', 'success');
			return true;
		}
		return false;
	} catch (err) {
		console.log(err);
	}
};

export const deletestaff = (staffId) => async (dispatch) => {
	try {
		const res = await axios.delete(`doctor/staff/${staffId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_STAFF,
				payload: staffId,
			});
			showNotification('Success', 'The STAFF has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const createStaffAttendance = (newAttendance) => async (dispatch) => {
	try {
		const res = await axios.post('doctor/staff-attendance', newAttendance);
		if (res.data.success) {
			showNotification('Success', 'The STAFF Attendance is created successfully', 'success');
			dispatch(getStaffAttendance(newAttendance.date || new Date()));
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateStaffAttendance = (updateBody, navigate) => async (dispatch) => {
	try {
		const res = await axios.patch('doctor/staff-attendance', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The STAFF Attendance is updated successfully', 'success');
			dispatch(getStaffAttendance(updateBody.date || new Date()));
		}
	} catch (err) {
		console.log(err);
	}
};

export const getStaffSalary = () => async (dispatch) => {
	console.log('running get staff salary');
	try {
		const res = await axios.get('doctor/staff-salary');
		if (res.data.success) {
			dispatch({
				type: SET_STAFF_SALARY,
				payload: res.data.salary,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createStaffSalary = (newSalary) => async (dispatch) => {
	try {
		const res = await axios.post('doctor/staff-salary', newSalary);
		if (res.data.success) {
			showNotification('Success', 'The STAFF Salary is created successfully', 'success');
			dispatch(getStaffSalary());
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteStaffSalary = (salaryId, navigate) => async (dispatch) => {
	try {
		const res = await axios.delete(`doctor/staff-salary/${salaryId}`);
		if (res.data.success) {
			dispatch(getStaffSalary());
			showNotification(
				'Success',
				'The STAFF salary has been deleted successfully',
				'success',
			);
			navigate(`/${dashboardMenu.staff.subMenu.viewStaff.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

function convertDate(inputFormat) {
	function pad(s) {
		return s < 10 ? '0' + s : s;
	}
	var d = new Date(inputFormat);
	return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('-');
}

export const findAttendance = (staff_attendances, date) => {
	let find = false;
	let ind = -1;
	staff_attendances.map((attend, index) => {
		if ((convertDate(date) == convertDate(attend.date)) == true) {
			find = true;
			ind = index;
		}
	});
	if (find) return ind;
	return -1;
};

export const getDrDataForStaff = (staffId, params) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.get(
			`doctor/bill/reports/staff-handover/detail-doctor-report/${staffId}`,
			{ params },
		);
		if (res.data.success) {
			dispatch({
				type: SET_DR_WISE_DATA_FOR_STAFF,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getStaffDesignation = async () => {
	try {
		const res = await axios.get(`/doctor/staff/designation`);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

export const createStaffDesignation = async (data) => {
	try {
		const res = await axios.post('/doctor/staff/designation', data);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};
