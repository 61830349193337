import _ from 'lodash';
import {
	LOADING_IPD,
	SET_ACCOMMODATIONS,
	CREATE_ACCOMMODATION,
	UPDATE_ACCOMMODATION,
	DELETE_ACCOMMODATION,
	SET_IPDS,
	DELETE_IPD,
	SET_IPD_ROOMS,
	SET_VISITING_DOC,
	SET_IPD_STATUS,
	SET_COMPANY_LIST,
	SET_PATIENT_STATUS,
	SET_IPD_WARDS,
	SET_IPD_BED_STATUSES,
	SET_PATIENT_IPD_FILE,
	SET_GYNAE_LABLIST,
	SET_GYNAE_RISKLIST,
	SET_IPD_HISTORY_TEMPLATE,
	SET_IPD_PHYSICAL_EXAMINATION_TEMPLATE,
	SET_STORE_ITEMS,
	SET_STORE_PURCHASE,
	SET_STORE_ASSIGN_LIST,
	SET_STORE_INVENTORY,
	SET_STORE_INDENTATION,
	SET_STORE_SALES,
	SET_PATIENT_STORE_ASSIGN,
	SET_PAYMENT_REMARKS,
	SET_NURSING_LIST,
	SET_TPA_LIST,
} from './types';

const initialState = {
	error: null,
	loading: false,
	accommodations: [],
	ipdStatus: [],
	patientStatus: [],
	companyList: [],
	ipds: [],
	page: 1, // Page for ipds
	total: 0, // total Records for all ipd list
	storePage: 1,
	storeTotal: 0,
	indentationPage: 0,
	indentationTotal: 0,
	salesPage: 0,
	salesTotal: 0,
	ipdWards: [],
	ipdRooms: [],
	bedStatuses: [],
	visitingDoc: [],
	gynaeLabList: [],
	gynaeRiskList: [],
	historyTemplate: [],
	physicalExaminationTemplates: [],
	patientIpdFile: null,
	storeItems: [],
	storePurchase: [],
	storeAssigns: [],
	storeSales: [],
	storeIndentation: [],
	storeInventory: [],
	patientStoreAssign: [],
	paymentRemarks: [],
	nursingList: [],
	tpaList: [],
	storePurchaseUrl: null,
	storeItemsUrl: null,
};

const ipdReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_IPD:
			return {
				...state,
				loading: true,
			};

		case SET_ACCOMMODATIONS:
			return {
				...state,
				accommodations: action.payload || [],
				loading: false,
			};

		case CREATE_ACCOMMODATION:
			return {
				...state,
				accommodations: [action.payload, ...state.accommodations],
			};

		case UPDATE_ACCOMMODATION:
			const recordIdx = _.findIndex(state.accommodations, (s) => s.id === action.payload.id);
			return {
				...state,
				accommodations: [
					...state.accommodations.slice(0, recordIdx),
					action.payload,
					...state.accommodations.slice(recordIdx + 1),
				],
			};

		case DELETE_ACCOMMODATION:
			return {
				...state,
				accommodations: _.reject(state.accommodations, (s) => s.id === action.payload),
			};

		case SET_PAYMENT_REMARKS:
			return {
				...state,
				paymentRemarks: action.payload || [],
			};

		case SET_IPD_STATUS:
			return {
				...state,
				ipdStatus: action.payload || [],
			};

		case SET_COMPANY_LIST:
			return {
				...state,
				companyList: action.payload || [],
			};

		case SET_IPDS:
			return {
				...state,
				ipds: action.payload.ipds || [],
				page: action.payload.page || 1,
				total: action.payload.total || 0,
				loading: false,
			};

		case DELETE_IPD:
			return {
				...state,
				ipds: _.reject(
					state.ipds,
					(s) =>
						s.id === action.payload.ipdId &&
						s.organisationId === action.payload.organisationId,
				),
			};
		case SET_IPD_ROOMS:
			return {
				...state,
				ipdRooms: action.payload || [],
				ipdWards: action.payload || [],
			};

		case SET_VISITING_DOC:
			return {
				...state,
				visitingDoc: action.payload || [],
			};

		case SET_PATIENT_STATUS:
			return {
				...state,
				patientStatus: action.payload || [],
			};

		case SET_IPD_WARDS:
			return {
				...state,
				ipdWards: action.payload || [],
			};

		case SET_IPD_BED_STATUSES:
			return {
				...state,
				bedStatuses: action.payload || [],
				loading: false,
			};

		case SET_PATIENT_IPD_FILE:
			return {
				...state,
				patientIpdFile: action.payload || null,
				loading: false,
			};

		case SET_GYNAE_LABLIST:
			return {
				...state,
				gynaeLabList: action.payload || [],
				loading: false,
			};

		case SET_GYNAE_RISKLIST:
			return {
				...state,
				gynaeRiskList: action.payload || [],
				loading: false,
			};

		case SET_IPD_HISTORY_TEMPLATE:
			return {
				...state,
				historyTemplate: action.payload || [],
			};

		case SET_IPD_PHYSICAL_EXAMINATION_TEMPLATE:
			return {
				...state,
				physicalExaminationTemplates: action.payload || [],
			};
		case SET_STORE_ITEMS:
			return {
				...state,
				storeItems: action.payload.data || [],
				storeItemsUrl: action.payload.storeItemsUrl || null,
			};
		case SET_STORE_PURCHASE:
			return {
				...state,
				storePurchase: action.payload.data || [],
				storePurchaseUrl: action.payload.storePurchaseUrl || null,
				storePage: action.payload.page || 1,
				storeTotal: action.payload.total || 0,
			};
		case SET_STORE_ASSIGN_LIST:
			return {
				...state,
				storeAssigns: action.payload || [],
			};
		case SET_STORE_INVENTORY:
			return {
				...state,
				storeInventory: action.payload || [],
			};
		case SET_STORE_INDENTATION:
			return {
				...state,
				storeIndentation: action.payload.data || [],
				indentationPage: action.payload.page || 1,
				indentationTotal: action.payload.total || 0,
			};
		case SET_STORE_SALES:
			return {
				...state,
				storeSales: action.payload.data || [],
				salesPage: action.payload.page || 0,
				salesTotal: action.payload.total || 0,
			};
		case SET_PATIENT_STORE_ASSIGN:
			return {
				...state,
				patientStoreAssign: action.payload || [],
			};
		case SET_NURSING_LIST:
			return {
				...state,
				nursingList: action.payload || [],
			};
		case SET_TPA_LIST:
			return {
				...state,
				tpaList: action.payload || [],
			};
		default:
			return state;
	}
};

export default ipdReducer;
