import {
	SET_PATIENTS,
	DELETE_PATIENT,
	SET_PATIENT_EXAMINATIONS,
	SET_PATIENT_PRESCRIPTIONS,
	SET_PATIENT_BILLS,
	DELETE_PATIENT_BILL,
	SET_PATIENT_PAYMENTS,
	UPDATE_PATIENT_PAYMENT,
	DELETE_PATIENT_PAYMENT,
	SET_PEDIGREE_CHARTS,
	DELETE_PEDIGREE_CHART,
	SET_BONE_AGE,
	DELETE_BONE_AGE,
	SET_TTKG,
	DELETE_TTKG,
	SET_THC,
	DELETE_THC,
	SET_GROWTH,
	DELETE_GROWTH,
	SET_PATIENT_CATEGORY,
	SET_EXAMINATION_PARAMETERS,
	SET_PATIENT_OPD_DATES,
	SET_PATIENT_APPOINTMENTS,
	SET_PATIENT,
	LOADING_PATIENTS,
	SET_TIMINGS,
	SET_CC_SYMPTOMS,
	SET_MEDICAL_ALERTS,
	SET_EXAMINATION_TEMPLATES,
	SET_WHITEBOARD,
	SET_CLINICAL_NOTES,
	SET_PATIENT_VOICE_PRESCRIPTIONS,
	SET_PATIENT_INVESTIGATIONS,
	SET_PATIENT_SHARE_REPORTS,
	SET_PATIENT_INVESTIGATION_TEMPLATES,
	SET_PATIENTS_SEARCH_LIST,
	SET_PATIENT_DOCUMENTS,
	SET_PATIENT_SHARE_REPORTS_NON_EXPIRED,
	SET_SOAP_ATTACHMENTS,
	SET_PATIENT_VISITS,
	SET_PATIENT_REFUND_PAYMENTS,
} from '../reducers/types';
import axios from '../helpers/axios';
import { custom } from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import { toggleCreateLoading, toggleTableLoading } from './componentActions';
import axiosz from 'axios';
import { DefaultPagination, currentOrg } from '../helpers/config';
import { store } from '../store/configureStore';
// let cancelToken;
// export const getInventories =
// 	(params = null, { organisationId }) =>
// 	async (dispatch) => {
// 		var url = '/inventory';
// 		// const permissions = store.getState?.()?.profile?.permissions;
// 		try {
// 			if (typeof cancelToken != typeof undefined) {
// 				cancelToken?.cancel('Cancelling prev req');
// 			}
// 			cancelToken = axiosz.CancelToken?.source();
// 			const res = await axiosz.get(url, {
// 				cancelToken: cancelToken?.token,
// 				params,
// 				baseURL: process.env.REACT_APP_SERVER_URL,
// 				headers: { organisationId },
// 			});
// 			if (res.data.success) {
// 				dispatch({
// 					type: SET_INVENTORIES,
// 					payload: {
// 						inventory: res.data.data,
// 						page: res.data.totalPage,
// 						total: res.data.totalRecord,
// 					},
// 				});
// 			}
// 		} catch (err) {
// 			console.log(err);
// 		} finally {
// 			dispatch(toggleTableLoading(false));
// 		}
// 	};

export const getPatientList = (orgId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/patient/listall`, {
			headers: { organisationId: orgId },
		});
		if (res.data.success) {
			dispatch({
				type: SET_PATIENTS_SEARCH_LIST,
				payload: res.data.users,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

let cancelToken;
export const getPatients =
	(params = { ...DefaultPagination, organisationId: currentOrg }) =>
	async (dispatch) => {
		dispatch(toggleTableLoading(true));
		try {
			if (typeof cancelToken != typeof undefined) {
				cancelToken?.cancel('Cancelling prev req');
			}
			cancelToken = axiosz.CancelToken?.source();
			axiosz.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');
			const res = await axiosz.get('/doctor/patient/list-patient', {
				cancelToken: cancelToken?.token,
				params,
				baseURL: process.env.REACT_APP_SERVER_URL,
			});
			if (res.data.success) {
				dispatch({
					type: SET_PATIENTS,
					payload: {
						patients: res.data.patients,
						page: res.data.totalPage,
						total: res.data.totalRecord,
					},
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const getPatient =
	(patientId, navigate = null) =>
	async (dispatch) => {
		try {
			dispatch({
				type: LOADING_PATIENTS,
			});
			const res = await axios.get(`/doctor/patient/${patientId}`);
			if (res.data.success) {
				dispatch({
					type: SET_PATIENT,
					payload: res.data.user,
				});
				if (navigate) {
					navigate?.(`/patient/${patientId}/prescriptions`);
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

export const getPatientSummary = async (patientId, navigate = null) => {
	try {
		toggleTableLoading(true);
		const res = await axios.get(`/doctor/patient/summary/${patientId}`);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	} finally {
		toggleCreateLoading(false);
	}
};

export const createPatient = async (newPatient, dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/patient', newPatient);
		if (res.data.success) {
			showNotification('Success', 'The patient is created successfully', 'success');
			dispatch(getPatientList());
			if (newPatient.get('organisationId'))
				dispatch(getPatients({ organisationId: newPatient.get('organisationId') }));
			return res.data.userId;
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};
// Helper action
export const removePatientImage = async (patientId) => {
	try {
		const res = await axios.patch(`/doctor/patient/remove-profile-image/${patientId}`);
		if (res.data.success) {
			showNotification('Success', 'The patient image is removed successfully', 'success');
			return true;
		}
		return false;
	} catch (err) {
		console.log(err);
	}
};

export const updatePatient = (updateBody, organisationId, navigate) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/patient/update', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The patient is updated successfully', 'success');
			dispatch(getPatients({ ...DefaultPagination, organisationId }));
			dispatch(getPatientList());
			navigate && navigate(`/${dashboardMenu.patients.subMenu.viewPatients.path}`);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePatient = (patientId, organisationId, navigate) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete('/doctor/patient', {
			data: { userId: patientId, organisationId },
		});
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT,
				payload: patientId,
			});
			dispatch(getPatientList());
			showNotification('Success', 'The patient has been deleted successfully', 'success');
			navigate(`/${dashboardMenu.patients.subMenu.viewPatients.path}`);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

/**-----------------Timings-----------------------**/

export const createTiming = (name, organisationId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/appointment-profile/create-timing`, {
			name,
			organisationId,
		});
		if (res.data.success) {
			//showNotification('Success', 'The Timing is created successfully', 'success');
			dispatch(getTimings(organisationId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getTimings = (organisationId) => async (dispatch) => {
	try {
		// timings
		const res = await axios.get(`/appointment-profile/timings/${organisationId}`);
		if (res.data.success) {
			dispatch({
				type: SET_TIMINGS,
				payload: res.data.data.templates,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientExaminations = (userId) => async (dispatch) => {
	try {
		console.log('running getExaminationRecords');
		const res = await axios.get(`/examination-record?userId=${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_EXAMINATIONS,
				payload: res.data.examinationRecords,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

/** ----------------Examination------------------- **/
export const getPatientCategory = () => async (dispatch) => {
	try {
		const res = await axios.get('/docplix/category');

		if (res.data.success) {
			const categories = res.data.categories;
			for (var i of categories) {
				const subRes = await axios.get(`/docplix/subcategory?categoryId=${i.id}`);
				if (subRes.data.success) i['subCategories'] = subRes.data.subcategories;
			}
			dispatch({
				type: SET_PATIENT_CATEGORY,
				payload: categories,
			});
		}
	} catch (err) {
		console.error(err);
	}
};

export const getPatientParameters = (doctor) => async (dispatch) => {
	try {
		const res = await custom.get(`/v1/pe/exm-params/?doctor_id=${doctor}`);
		if (res.status === 200) {
			dispatch({
				type: SET_PATIENT_CATEGORY,
				payload: res.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createPatientParameter = async (doctor, data, dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await custom.post(`/v1/pe/exm-params/`, data);
		if (res.status === 201) {
			dispatch(getPatientParameters(doctor));
			return res.data;
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getOpdDates = (patientId, doctorId) => async (dispatch) => {
	console.log('running getOpdDates v1');
	try {
		const res = await custom.get(`/v1/pe/opd-dates/?userid=${patientId}&vendorid=${doctorId}`);
		if (res.status === 200) {
			dispatch({
				type: SET_PATIENT_OPD_DATES,
				payload: res.data || [],
			});
			// return res.data.results;
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const addNewOpdDate = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await custom.post(`/v1/pe/opd-dates/`, data);
		if (res.status === 200 || res.status === 201) {
			dispatch(getOpdDates(data.userid, data.vendorid));
			dispatch(getExaminations(data.userid));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getExaminations = (patientId) => async (dispatch) => {
	try {
		const res = await custom.get(`/v1/pe/patient-examination/?patient_id=${patientId}`);
		if (res.status === 200) {
			dispatch({
				type: SET_EXAMINATION_PARAMETERS,
				payload: res.data || [],
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createExamination = (newExamination, patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await custom.post(`/v1/pe/patient-examination/`, newExamination);
		if (res.status === 201) {
			//showNotification('Success', 'Patient Examination created successfully', 'success');
			dispatch(getExaminations(patientId));
		} else {
			//showNotification('Error', res.data.errors[0]?.message, 'danger');
		}
	} catch (error) {
		console.error(error);
		return [];
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateParamName = (data, paramId, patient) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await custom.put(`/v1/pe/exm-params/${paramId}/`, data);
		if (res.status == 200 || res.status == 201) {
			//showNotification('Success', 'Updated Parameter Name', 'success');
			dispatch(getExaminations(patient));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteExamination = (examinationId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await custom.delete(`/v1/pe/patient-examination/${examinationId}/`);
		if (res.data.success) {
			//showNotification('Success', 'Patient Examination deleted successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePatientFreq = (examinationId, data, patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	console.log('running updatePatientFreq');
	try {
		const res = await custom.patch(`/v1/pe/patient-examination/${examinationId}/`, data);
		if (res.status === 200) {
			dispatch(getExaminations(patientId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createExaminationDates = (data, patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	console.log('running createExaminationDates');
	try {
		const res = await custom.post(`/v1/pe/examination-dates/`, data);
		if (res.status === 201) {
			dispatch(getExaminations(patientId));
			// doctorId && dispatch(getOpdDates(patientId, doctorId));
			// //showNotification('Success', 'Table updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateExaminationDates = (data, patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	console.log('running updateExaminationDates');
	try {
		const res = await custom.post(`/v1/pe/examination-opd-dates`, data);
		if (res.status === 200) {
			dispatch(getExaminations(patientId));
			// //showNotification('Success', 'Table updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteExaminationParameter = (examinationId, patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	console.log('running deleteExaminationParameter');
	try {
		const res = await custom.delete(`/v1/pe/patient-examination/${examinationId}/`);
		if (res.status === 204) {
			dispatch(getExaminations(patientId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getExaminationTemplates = () => async (dispatch) => {
	try {
		const res = await custom.get(`v1/pe/examination-template/`);
		if (res.status === 200) {
			dispatch({
				type: SET_EXAMINATION_TEMPLATES,
				payload: res.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const saveExaminationTemplate = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await custom.post(`v1/pe/examination-template/`, data);
		if (res.status === 201 || res.status === 200) {
			dispatch(getExaminationTemplates());
			//showNotification('Success', 'Template created successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPatientPrescriptions = (userId) => async (dispatch) => {
	try {
		console.log('running getPatientPrescriptions');
		const res = await axios.get('/appointment-profile', { params: { userId } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_PRESCRIPTIONS,
				payload: res.data.appointments,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createPatientPrescription = async (newPatientPresc, dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/prescription', newPatientPresc);
		if (res.data.success) {
			showNotification('Success', 'The prescription is created successfully', 'success');
			dispatch(getPatientPrescriptions(newPatientPresc.userId));
			return res.data.appointments;
		}
	} catch (err) {
		console.log(err);
		return null;
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePatientPrescription = async (updateBody, userId, dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/prescription', updateBody);
		if (res.data.success) {
			// //showNotification('Success', 'The prescription is updated successfully', 'success');
			dispatch(getPatientPrescriptions(userId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePatientPrescription = (prescId, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(
			`/appointment-ehr/all-prescription?appointmentId=${prescId}`,
		);
		if (res.data.success) {
			dispatch(getPatientPrescriptions(userId));
			//showNotification(
			// 	'Success',
			// 	'The prescription has been deleted successfully',
			// 	'success',
			// );
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getAdjacentOpd = async (patientId, date) => {
	try {
		console.log('running getAdjacentOpd');
		const res = await axios.get(`/opd/patient?userId=${patientId}&date=${date}`);
		if (res.data.success) {
			return res.data.opd;
		}
	} catch (err) {
		console.log(err);
	}
	return null;
};

export const deletePatientPrescriptionRow = (prescId, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/prescription/${prescId}`);
		if (res.data.success) {
			dispatch(getPatientPrescriptions(userId));
			//showNotification(
			// 	'Success',
			// 	'The prescription row has been deleted successfully',
			// 	'success',
			// );
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPatientVoicePrescriptions = (userId) => async (dispatch) => {
	try {
		console.log('running getPatientVoicePrescriptions');
		const res = await axios.get('/appointment-voice', { params: { userId } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_VOICE_PRESCRIPTIONS,
				payload: res.data.details,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createPatientVoicePrescription = (newVoicePresc) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		console.log('running createPatientVoicePrescription');
		const res = await axios.post('/appointment-voice', newVoicePresc);
		if (res.data.success) {
			// //showNotification('Success', 'The prescription is created successfully', 'success');
			dispatch(getPatientVoicePrescriptions(newVoicePresc.userId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePatientVoicePrescription = (voicePresc) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		console.log('running updatePatientVoicePrescription');
		const res = await axios.patch('/appointment-voice', voicePresc);
		if (res.data.success) {
			// //showNotification('Success', 'The prescription is updated successfully', 'success');
			dispatch(getPatientVoicePrescriptions(voicePresc.userId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

// NEW Inv
export const getInvestigationTemplates = (orgId) => async (dispatch) => {
	try {
		console.log('running getPatientInvestigationTemplates');
		const res = await axios.get('/investigation-template', {
			headers: { organisationId: orgId },
		});

		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_INVESTIGATION_TEMPLATES,
				payload: res.data.investigationTemplates,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createInvestigationTemplate = (name) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		console.log('running createInvestigationTemplates');
		const res = await axios.post('/investigation-template', { name, investigationIds: [] });

		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_INVESTIGATION_TEMPLATES,
				payload: res.data.investigationTemplates,
			});
			dispatch(getInvestigationTemplates());
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateInvestigationTemplate = (updateBody) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		console.log('running updateInvestigationTemplates');
		const res = await axios.patch('/investigation-template', updateBody);

		if (res.data.success) {
			dispatch(getInvestigationTemplates());
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPatientInvestigations = (userId) => async (dispatch) => {
	try {
		console.log('running getPatientInvestigations');
		const res = await axios.get('/user-investigate', { params: { userId } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_INVESTIGATIONS,
				payload: res.data.userInvestigates,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createPatientInvestigation = (newInvestigation) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/user-investigate', newInvestigation);
		if (res.data.success) {
			//showNotification('Success', 'The investigation is created successfully', 'success');
			dispatch(getPatientInvestigations(newInvestigation.userId));
		}
	} catch (err) {
		console.log(err);
		return null;
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePatientInvestigation = (updateBody) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch('/user-investigate', updateBody);
		if (res.data.success) {
			//showNotification('Success', 'The investigation is updated successfully', 'success');
			dispatch(getPatientInvestigations(updateBody.userId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePatientInvestigation = (investigationId, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/user-investigate/${investigationId}`);
		if (res.data.success) {
			dispatch(getPatientInvestigations(userId));
			//showNotification(
			// 	'Success',
			// 	'The investigation has been deleted successfully',
			// 	'success',
			// );
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePatientInvestigationRow =
	(userInvestigateId, userInvestigateListId) => async (dispatch) => {
		dispatch(toggleCreateLoading(true));
		try {
			const res = await axios.delete(`/user-investigate/${prescId}`, {
				userInvestigateId,
				userInvestigateListId,
			});
			if (res.data.success) {
				dispatch(getPatientInvestigations(userId));
				//showNotification(
				// 	'Success',
				// 	'The investigation row has been deleted successfully',
				// 	'success',
				// );
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const getPatientSymptoms = () => async (dispatch) => {
	try {
		const res = await axios.get(`/docplix/symptoms`);
		if (res.data.success) {
			let data = [];
			res.data?.symptomCategories?.forEach((cat) => {
				data = [...data, ...cat.Symptoms];
			});
			dispatch({
				type: SET_CC_SYMPTOMS,
				payload: data,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getMedicalAlerts = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/medical-alert`);
		if (res.data.success) {
			dispatch({
				type: SET_MEDICAL_ALERTS,
				payload: res.data?.data?.medicalAlerts,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createMedicalAlerts = async (title, dispatch) => {
	// dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/medical-alert`, { title });
		if (res.data.success) {
			dispatch(getMedicalAlerts());
			return res.data.data.medicalAlert;
		}
	} catch (error) {
		console.error(error);
		return null;
	} finally {
		// dispatch(toggleCreateLoading(false));
	}
};

export const getPatientBills = (userId) => async (dispatch) => {
	try {
		console.log('running getBills');
		const res = await axios.get('/bills', { params: { userId, limit: 1000 } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_BILLS,
				payload: res.data.bills,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createPatientBill = (newPatientBill) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/bills', newPatientBill);
		if (res.data.success) {
			dispatch(getPatientBills(newPatientBill.userId));
			//showNotification('Success', 'The bill is created successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePatientBill = (updateBody, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch('/bills', updateBody);
		if (res.data.success) {
			//showNotification('Success', 'The bill is updated successfully', 'success');
			dispatch(getPatientBills(userId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePatientBill = (patientBillId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/bills/${patientBillId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_BILL,
				payload: patientBillId,
			});
			//showNotification('Success', 'The bill has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePatientBillRow = (rowId, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/bills/list/${rowId}`);
		if (res.data.success) {
			dispatch(getPatientBills(userId));
			//showNotification('Success', 'The bill row has been deleted successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPatientPayments = (userId) => async (dispatch) => {
	try {
		console.log('running getPayments');
		const res = await axios.get('/payments', { params: { userId, limit: 1000 } });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_PAYMENTS,
				payload: res.data.payments,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createPatientPayment = (newPatientPayment) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/payments', newPatientPayment);
		console.log(res);
		if (res.data.sucess) {
			//showNotification('Success', 'The Payment is created successfully', 'success');
			dispatch(getPatientPayments(newPatientPayment.userId));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePatientPayment = (updateBody) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch('/payments', updateBody);
		if (res.data.success) {
			//showNotification('Success', 'The Payment is updated successfully', 'success');
			dispatch({
				type: UPDATE_PATIENT_PAYMENT,
				payload: updateBody,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePatientPayment = (patientPaymentId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/payments/${patientPaymentId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PATIENT_PAYMENT,
				payload: patientPaymentId,
			});
			//showNotification('Success', 'The Payment has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPatientAppointments = (patientId) => async (dispatch) => {
	console.log('running getPatientAppointments');

	try {
		const res = await axios.get(`/appointment-profile/patient?userId=${patientId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_APPOINTMENTS,
				payload: res.data.appointments,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const sendViaWhatsapp = (patientId, report, link, doctorId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/uploads/send-report`, { patientId, report, link, doctorId });
		if (res.data.success) {
			showNotification('Success', 'Report sent to the patient successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const sendReviewLink = (patientId, experience_with, link) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/whatsapp/review`, { patientId, experience_with, link });
		if (res.data.success) {
			//showNotification('Success', 'Review link sent to the patient', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getWhiteboard = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/whiteboard?userId=${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_WHITEBOARD,
				payload: res.data.whiteboards,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const saveWhiteBoard = (userId, data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/whiteboard`, data);
		if (res.data.success) {
			dispatch(getWhiteboard(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateWhiteBoard = (board, data, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch(`/doctor/whiteboard/${board}`, data);
		if (res.data.success) {
			dispatch(getWhiteboard(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteWhiteBoard = (board, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/whiteboard/${board}`);
		if (res.data.success) {
			//showNotification('Success', 'Whiteboard deleted!', 'success');
			dispatch(getWhiteboard(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getClinicalNotes = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/clinical-notes?userId=${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_CLINICAL_NOTES,
				payload: res.data.clinicalnotes,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const saveClinicalNotes = (data, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	console.log('running saveClinicalNotes');
	try {
		const res = await axios.post(`/clinical-notes`, data);
		if (res.data.success) {
			dispatch(getClinicalNotes(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateClinicalNotes = (board, data, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch(`/clinical-notes/${board}`, data);
		if (res.data.success) {
			dispatch(getClinicalNotes(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteClinicalNotes = (board, userId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/clinical-notes/${board}`);
		if (res.data.success) {
			//showNotification('Success', 'Clinical Note deleted!', 'success');
			dispatch(getClinicalNotes(userId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getSoapAttachments = (doctorId, patientId) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/patient/upload-soap-image`, { doctorId, patientId });
		if (res.data.success) {
			dispatch({
				type: SET_SOAP_ATTACHMENTS,
				payload: [...res.data.data, ...res.data.documents],
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const uploadSoapAttachment = (data, doctorId, patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/patient/document`, data);
		// const res = await axios.post(`/doctor/patient/upload-soap-image`, data);
		if (res.data.success) {
			showNotification('Success', 'Attachment is created successfully', 'success');
			dispatch(getSoapAttachments(doctorId, patientId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const editSoapAttachment = (data) => async (dispatch) => {
	try {
		const res = await axios.put(`/doctor/patient/upload-soap-image`, data);
		if (res.data.success) {
			showNotification('Success', 'Attachment is updated successfully', 'success');
			dispatch(getSoapAttachments(data.get('doctor'), data.get('patient')));
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteSoapAttachment = (id, patient, doctor, isSoapImage) => async (dispatch) => {
	try {
		console.log(id, patient, doctor, isSoapImage);
		let res;
		if (isSoapImage) {
			res = await axios.delete(`/doctor/patient/delete-soap-image/${id}`);
		} else {
			res = await axios.delete(`/doctor/patient/document?documentId=${id}&userId=${patient}`);
		}

		if (res.data.success) {
			dispatch(getSoapAttachments(doctor, patient));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientShareReports = (patient) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/share-report?userId=${patient}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_SHARE_REPORTS,
				payload: { shareReports: res.data.shareReports, url: res.data.url },
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPatientDocument = (patientId) => async (dispatch) => {
	try {
		const res = await axios.get(`/uploads/getUserReports/${patientId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_DOCUMENTS,
				payload: res.data.report,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const isValidSeriesID = async (seriesId) => {
	try {
		const res = await axios.get('/doctor/patient/checkseriesid', { params: { seriesId } });
		if (res.data.success) {
			return res.data.isValid;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const saveSharedDocuments = (data, doctorId, cb) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/share-report/shareUserReportsToVendor`, {
			userReports: data,
		});
		if (res.data.success) {
			cb?.();
			dispatch(getNonExpiredSharedUserReportsToVendor(doctorId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const stopSharedDocuments = (sharedDocumentId, doctorId, cb) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(
			`/doctor/share-report/stopSharingUserReportToVendor?id=${sharedDocumentId}`,
		);
		if (res.data.success) {
			cb?.();
			dispatch(getNonExpiredSharedUserReportsToVendor(doctorId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getNonExpiredSharedUserReportsToVendor = (doctorId) => async (dispatch) => {
	try {
		const res = await axios.get(
			`/doctor/share-report/userReportsByVendorId?vendorId=${doctorId}`,
		);

		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_SHARE_REPORTS_NON_EXPIRED,
				payload: {
					shareReports: res.data.shareReports,
					reportUrl: res.data.reportUrl,
					fileUrl: res.data.fileUrl,
				},
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addPatientDocument = () => async (dispatch) => {};

export const getPatientsByPhone = async (phone) => {
	try {
		const res = await axios.get(`/doctor/patient/list-patient?key=${phone}`);
		if (res.data.success) {
			if (res.data.patients.length > 0) {
				return res.data.patients[0];
			}
		}
		return null;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getPatientById = async (patientId) => {
	if (!patientId) return null;
	try {
		const res = await axios.get(`/doctor/patient/${patientId}`);
		if (res.data.success) {
			return res.data.user;
		}
		return null;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getPatientPaymentsNow = async (userId) => {
	try {
		console.log('running getPatientPaymentsNow');
		const res = await axios.get('/payments', { params: { userId, limit: 1000 } });
		if (res.status == 200) return res.data.payments || [];
	} catch (err) {
		console.log(err);
	}

	return [];
};

export const getPatientRefunds = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/payments/refund-list/${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_REFUND_PAYMENTS,
				payload: res.data.result,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createPatientRefund = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/payments/refund`, data);
		if (res.data.success) {
			dispatch(getPatientRefunds(data.patientId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const deletePatientRefund = (id, userId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/payments/refund-delete/${id}`);
		if (res.data.success) {
			dispatch(getPatientRefunds(userId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientVisits = (userId) => async (dispatch) => {
	try {
		const res = await axios.get(`/opd/patient-opds/${userId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_VISITS,
				payload: res.data.opd,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientAppointmentDiagnoses = async (userId) => {
	try {
		const res = await axios.get(`/doctor/patient/diagnosis/${userId}`);
		if (res.data.success) {
			return res.data.appointmentDiagnosis;
		}
	} catch (error) {
		console.error(error);
	}
	return [];
};

export const getPatientIpdDiagnoses = async (userId) => {
	try {
		const res = await axios.get(`/doctor/patient/diagnosis/${userId}`);
		if (res.data.success) {
			return res.data.ipdDiagnosis;
		}
	} catch (error) {
		console.error(error);
	}
	return [];
};
