import React from 'react'
import Button from '../../../components/bootstrap/Button'

const Header = ({handleCopyChat,handleWholeBotClose,handleGoBack,className=""}) => {
  return (
    <div className={`header-chat df-jcsb ${className}`}>
            <div className="name" style={{fontSize:20}}>
                <Button icon='chevron-compact-left' style={{color:'white'}}  onClick={()=>{handleGoBack()}}/>
                Pāṇini
            </div>
            <div className="bot-actions">
                <Button icon='Clipboard'  onClick={()=>{handleCopyChat()}}/>
                <Button icon='Close'  onClick={()=>{handleWholeBotClose()}}/>
                    {/* <img src="/assets/img/close.png" alt="close" /> */}
            </div>
        </div>
  )
}

export default Header