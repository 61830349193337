import {
	SET_LAB_VENDORS,
	LOADING_LAB_VENDORS,
	CREATE_LAB_VENDOR,
	UPDATE_LAB_VENDOR,
	DELETE_LAB_VENDOR,
	SET_LAB_TRANSACTIONS,
	LOADING_LAB_TRANSACTIONS,
	CREATE_LAB_TRANSACTION,
	UPDATE_LAB_TRANSACTION,
	DELETE_LAB_TRANSACTION,
	SET_LAB_TESTS,
	SET_LAB_BILLS,
	SET_LABTEST_TAGS,
	ADD_LABTEST_TAGS,
	SET_LABPARAMETERS_MASTER,
	SET_LABPARAMETERS,
	SET_LABPARAMETER_UNIT,
	SET_LAB_TEMPLATES,
	SET_LAB_DEPARTMENTS,
	SET_LAB_SAMPLES,
	SET_PARAMETERLIST,
	SET_LAB_TEMPLATE_LIST,
} from './types';

const initialState = {
	vendors: [],
	transactions: [],
	totalBilled: {},
	labTests: [],
	labBills: [],
	tags: [],
	labParametersMaster: [],
	labParameters: [],
	labTemplates: [],
	labTemplateList: [],
	parameterUnits: [],
	departments: [],
	samples: [],
	parameterList: [],
	pagination: {},
	error: null,
	loading: false,
};

const labReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_LAB_VENDORS:
			return {
				...state,
				loading: true,
			};

		case SET_LAB_VENDORS:
			return {
				...state,
				vendors: action.payload || [],
				loading: false,
			};

		case CREATE_LAB_VENDOR:
			return {
				...state,
				vendors: [action.payload, ...state.vendors],
			};

		case UPDATE_LAB_VENDOR:
			state.vendors = state.vendors.filter((o) => o.id != action.payload.id);
			return {
				vendors: [action.payload, ...state.vendors],
				...state,
			};

		case DELETE_LAB_VENDOR:
			state.vendors = state.vendors.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		case LOADING_LAB_TRANSACTIONS:
			return {
				...state,
				loading: true,
			};

		case SET_LAB_TRANSACTIONS:
			return {
				...state,
				transactions: action.payload || [],
				loading: false,
			};

		case CREATE_LAB_TRANSACTION:
			return {
				...state,
				transactions: [action.payload, ...state.transactions],
			};

		case UPDATE_LAB_TRANSACTION:
			state.transactions = state.transactions.filter((o) => o.id != action.payload.id);
			return {
				transactions: [action.payload, ...state.transactions],
				...state,
			};

		case DELETE_LAB_TRANSACTION:
			state.transactions = state.transactions.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		case SET_LAB_TESTS:
			return {
				...state,
				labTests: action.payload || [],
			};

		case SET_LAB_BILLS:
			return {
				...state,
				labBills: action.payload.labBills || [],
				totalBilled: action.payload.totalBilled || {},
				pagination: action.payload.pagination || {},
			};

		case SET_LABTEST_TAGS:
			return {
				...state,
				tags: action.payload || [],
			};

		case ADD_LABTEST_TAGS:
			return {
				...state,
				tags: [action.payload, ...state.tags],
			};

		case SET_LABPARAMETERS_MASTER:
			return {
				...state,
				labParametersMaster: action.payload.parameters || [],
				pagination: { ...state.pagination, ...action.payload.pagination } || {},
			};

		case SET_LABPARAMETERS:
			return {
				...state,
				labParameters: action.payload.parameters || [],
				pagination: { ...state.pagination, ...action.payload.pagination } || {},
			};

		case SET_LABPARAMETER_UNIT:
			return {
				...state,
				parameterUnits: action.payload || [],
			};

		case SET_LAB_TEMPLATES:
			return {
				...state,
				labTemplates: action.payload.parameters || [],
				pagination: { ...state.pagination, ...action.payload.pagination } || {},
			};

		case SET_LAB_TEMPLATE_LIST:
			return {
				...state,
				labTemplateList: action.payload || [],
			};

		case SET_LAB_DEPARTMENTS:
			return {
				...state,
				departments: action.payload || [],
			};

		case SET_LAB_SAMPLES:
			return {
				...state,
				samples: action.payload || [],
			};

		case SET_PARAMETERLIST:
			return {
				...state,
				parameterList: action.payload || [],
			};
		default:
			return state;
	}
};

export default labReducer;
