import JoditEditor from 'jodit-react';
import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
	createDrugHistory,
	createFamilyHistory,
	createPastHistory,
	createSocialHistory,
	getDrugHistoryV2,
	getFamilyHistory,
	getPastHistory,
	getSocialHistoryV2,
} from '../../../../actions/historyActions';
import Button from '../../../../components/bootstrap/Button';
import Card, { CardBody, CardTabItem } from '../../../../components/bootstrap/Card';
import useDarkMode from '../../../../hooks/useDarkMode';
import { isPermitted } from '../../../../actions/helperActions';
import {
	SET_DRUG_HISTORY,
	SET_PAST_HISTORY,
	SET_SOCIAL_HISTORY,
	SET_WHOLE_FAMILY_HISTORY,
} from '../../../../reducers/types';
import useAsr from '../../../../hooks/useAsr';

const History = forwardRef(({ history, afterSave, id }, ref) => {
	const pastRef = useRef();
	const socialRef = useRef();
	const drugRef = useRef();
	const familyRef = useRef();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);

	// SAVE TO PARENT
	useImperativeHandle(ref, () => ({
		save: () => {
			pastRef?.current?.save?.();
			socialRef?.current?.save?.();
			drugRef?.current?.save?.();
			familyRef?.current?.save?.();
		},
	}));

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			isPermitted(
				'Medical profile',
				'read',
				() => {
					dispatch(getPastHistory(user?.Vendor_detail?.vendorId, id));
					dispatch(getSocialHistoryV2(user?.Vendor_detail?.vendorId, id));
					dispatch(getDrugHistoryV2(user?.Vendor_detail?.vendorId, id));
					dispatch(getFamilyHistory(id, user?.Vendor_detail?.vendorId));
				},
				null,
				false,
			);
		}
		return () => {
			mounted = false;
			dispatch({ type: SET_PAST_HISTORY, payload: [] });
			dispatch({ type: SET_SOCIAL_HISTORY, payload: [] });
			dispatch({ type: SET_DRUG_HISTORY, payload: [] });
			dispatch({ type: SET_WHOLE_FAMILY_HISTORY, payload: [] });
		};
	}, []);

	return (
		<Card hasTab stretch className='shadow-none'>
			<CardTabItem id='pastHistory' title='Past history'>
				<PastHistory editItem={history?.past} ref={pastRef} afterSave={afterSave} />
			</CardTabItem>
			<CardTabItem id='socialHistory' title='Social History'>
				<SocialHistory editItem={history?.social} ref={socialRef} afterSave={afterSave} />
			</CardTabItem>
			<CardTabItem id='drugHistory' title='Drug History'>
				<DrugHistory editItem={history?.drug} ref={drugRef} afterSave={afterSave} />
			</CardTabItem>
			<CardTabItem id='familyHistory' title='Family History'>
				<FamilyHistory editItem={history?.family} ref={familyRef} afterSave={afterSave} />
			</CardTabItem>
		</Card>
	);
});

const PastHistory = forwardRef(({ editItem, afterSave }, ref) => {
	const { darkModeStatus } = useDarkMode();
	const phEditor = useRef(null);
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const patient = useSelector((state) => state.patient.patient);
	const pastHistoryData = useSelector((state) => state.history.pastHistory?.data);

	const prevData = useMemo(() => {
		if (editItem) {
			return (
				Object.keys(editItem || [])
					?.map((heading) =>
						editItem[heading]
							? `<strong>${heading?.toUpperCase?.()} : </strong>${
									editItem[heading]
							  }<br/>`
							: '',
					)
					?.join('') + (pastHistoryData?.text || '')
			);
		} else if (pastHistoryData) {
			return pastHistoryData?.text;
		} else return '';
	}, [editItem]);


	const [pastHistory, setPastHistory] = useState(prevData);

	const handleSubmit = () => {
		dispatch(
			createPastHistory({
				date: moment().format('YYYY-MM-DD'),
				text: pastHistory,
				doctor_id: user?.Vendor_detail?.vendorId,
				patient_id: patient?.id,
			}),
		);
		if (afterSave) afterSave?.();
	};

	useImperativeHandle(ref, () => ({
		save: () => handleSubmit(),
	}));

	useEffect(() => {
		setPastHistory(pastHistoryData?.text);
	}, [pastHistoryData]);

	/* ----------- NEW ASR START ----------- */
	const { toggleAsr, listening, speech, speechTemp, setSpeech } = useAsr();
	// useEffect(() => {
	// 	if (speech) setPastHistory(speech?.replace('\n', '</p><p>'));
	// }, [speech]);
	// useEffect(() => {
	// 	setSpeech(pastHistory);
	// }, [pastHistory]);
	/* ----------- NEW ASR END ----------- */

	return (
		<CardBody isScrollable className='d-flex flex-column h-100'>
			<div className='row'>
				<div className='col-12 mb-3'>
					<h3>Past History</h3>
				</div>
				{/* <div className='col-2 mb-2'>
					<Button
						isOutline={!listening}
						color={listening ? 'danger' : 'primary'}
						icon='Mic'
						onClick={toggleAsr}>
						{listening ? 'Stop' : 'Speak'}
					</Button>
				</div> */}
				<div className='col-12'>
					<JoditEditor
						config={{
							defaultActionOnPaste: 'insert_as_html',
							height: '50vh',
						}}
						ref={phEditor}
						value={speechTemp ? pastHistory + speechTemp : pastHistory}
						tabIndex={1}
						onBlur={(newContent) => setPastHistory(newContent)}
					/>
				</div>
				<div className='col-2 mt-3'>
					<Button
						isOutline={darkModeStatus}
						color='primary'
						className='border-0'
						onClick={() => isPermitted('Medical Profile', 'write', handleSubmit)}>
						Save
					</Button>
				</div>
			</div>
		</CardBody>
	);
});

const DrugHistory = forwardRef(({ editItem, afterSave }, ref) => {
	const { darkModeStatus } = useDarkMode();
	const phEditor = useRef(null);
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const patient = useSelector((state) => state.patient.patient);
	const drugHistoryData = useSelector((state) => state.history.drugHistory?.data);

	const prevData = useMemo(() => {
		if (editItem) {
			if (editItem.hasOwnProperty('length') && typeof editItem == typeof [])
				return [editItem?.join?.(', '), drugHistoryData?.text || '']?.join?.(',');
			else return [editItem, drugHistoryData?.text || '']?.join?.(', ');
		} else if (drugHistoryData) return drugHistoryData?.text;
		else return '';
	}, [editItem]);

	const [drugHistory, setDrugHistory] = useState(prevData);

	const handleSubmit = () => {
		dispatch(
			createDrugHistory({
				date: moment().format('YYYY-MM-DD'),
				text: drugHistory,
				doctor_id: user?.Vendor_detail?.vendorId,
				patient_id: patient?.id,
			}),
		);
		if (afterSave) afterSave?.();
	};

	useImperativeHandle(ref, () => ({
		save: () => handleSubmit(),
	}));

	useEffect(() => {
		setDrugHistory(drugHistoryData?.text);
	}, [drugHistoryData]);

	/* ----------- NEW ASR START ----------- */
	const { toggleAsr, listening, speech, speechTemp, setSpeech } = useAsr();
	// useEffect(() => {
	// 	if (speech) setDrugHistory(speech?.replace('\n', '</p><p>'));
	// }, [speech]);
	// useEffect(() => {
	// 	setSpeech(drugHistory);
	// }, [drugHistory]);
	/* ----------- NEW ASR END ----------- */

	return (
		<CardBody isScrollable className='d-flex flex-column h-100'>
			<div className='row'>
				<div className='col-12 mb-3'>
					<h3>Drug History</h3>
				</div>
				{/* <div className='col-2 mb-2'>
					<Button
						isOutline={!listening}
						color={listening ? 'danger' : 'primary'}
						icon='Mic'
						onClick={toggleAsr}>
						{listening ? 'Stop' : 'Speak'}
					</Button>
				</div> */}
				<div className='col-12'>
					<JoditEditor
						config={{
							defaultActionOnPaste: 'insert_as_html',
							height: '50vh',
						}}
						ref={phEditor}
						value={speechTemp ? drugHistory + speechTemp : drugHistory}
						tabIndex={1}
						onBlur={(newContent) => setDrugHistory(newContent)}
					/>
				</div>
				<div className='col-2 mt-3'>
					<Button
						isOutline={darkModeStatus}
						color='primary'
						className='border-0'
						onClick={() => isPermitted('Medical Profile', 'write', handleSubmit)}>
						Save
					</Button>
				</div>
			</div>
		</CardBody>
	);
});

const FamilyHistory = forwardRef(({ editItem, afterSave }, ref) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const fhEditor = useRef(null);

	const user = useSelector((state) => state.auth.user);
	const patient = useSelector((state) => state.patient.patient);
	const familyHistoryData = useSelector((state) => state.history.familyHistory?.data);

	const prevData = useMemo(() => {
		if (editItem) {
			if (editItem.hasOwnProperty('length') && typeof editItem == typeof [])
				return [editItem?.join?.(', '), familyHistoryData?.text || '']?.join?.(',');
			else return [editItem, familyHistoryData?.text || '']?.join?.(', ');
		} else if (familyHistoryData) return familyHistoryData?.text;
		else return '';
	}, [editItem]);

	const [familyHistory, setFamilyHistory] = useState(prevData);

	const handleSubmit = () => {
		dispatch(
			createFamilyHistory({
				date: moment().format('YYYY-MM-DD'),
				text: familyHistory,
				doctor_id: user?.Vendor_detail?.vendorId,
				patient_id: patient?.id,
			}),
		);
		if (afterSave) afterSave?.();
	};

	useImperativeHandle(ref, () => ({
		save: () => handleSubmit(),
	}));

	useEffect(() => {
		setFamilyHistory(familyHistoryData?.text);
	}, [familyHistoryData]);

	/* ----------- NEW ASR START ----------- */
	const { toggleAsr, listening, speech, speechTemp, setSpeech } = useAsr();
	// useEffect(() => {
	// 	if (speech) setFamilyHistory(speech?.replace('\n', '</p><p>'));
	// }, [speech]);
	// useEffect(() => {
	// 	setSpeech(familyHistory);
	// }, [familyHistory]);
	/* ----------- NEW ASR END ----------- */

	return (
		<CardBody isScrollable className='d-flex flex-column h-100'>
			<div className='row'>
				<div className='col-12 mb-3'>
					<h3>Family History</h3>
				</div>
				{/* <div className='col-2 mb-2'>
					<Button
						isOutline={!listening}
						color={listening ? 'danger' : 'primary'}
						icon='Mic'
						onClick={toggleAsr}>
						{listening ? 'Stop' : 'Speak'}
					</Button>
				</div> */}

				<div className='col-12'>
					<JoditEditor
						config={{
							defaultActionOnPaste: 'insert_as_html',
							height: '50vh',
						}}
						ref={fhEditor}
						value={speechTemp ? familyHistory + speechTemp : familyHistory}
						tabIndex={1}
						onBlur={(newContent) => setFamilyHistory(newContent)}
					/>
				</div>
				<div className='col-2 mt-3'>
					<Button
						isOutline={darkModeStatus}
						color='primary'
						className='border-0'
						onClick={() => isPermitted('Medical Profile', 'write', handleSubmit)}>
						Save
					</Button>
				</div>
			</div>
		</CardBody>
	);
});

const SocialHistory = forwardRef(({ editItem, afterSave }, ref) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const shEditor = useRef(null);

	const user = useSelector((state) => state.auth.user);
	const patient = useSelector((state) => state.patient.patient);
	const socialHistoryData = useSelector((state) => state.history.socialHistory?.data);

	const prevData = useMemo(() => {
		if (editItem) {
			if (editItem.hasOwnProperty('length') && typeof editItem == typeof [])
				return [editItem?.join?.(', '), socialHistoryData?.text || '']?.join?.(',');
			else return [editItem, socialHistoryData?.text]?.join?.(', ');
		} else if (socialHistoryData) return socialHistoryData?.text;
		else return '';
	}, [editItem]);

	const [socialHistory, setSocialHistory] = useState(prevData);

	const handleSubmit = () => {
		dispatch(
			createSocialHistory({
				date: moment().format('YYYY-MM-DD'),
				text: socialHistory,
				doctor_id: user?.Vendor_detail?.vendorId,
				patient_id: patient?.id,
			}),
		);
		if (afterSave) afterSave?.();
	};

	useImperativeHandle(ref, () => ({
		save: () => handleSubmit(),
	}));

	useEffect(() => {
		setSocialHistory(socialHistoryData?.text);
	}, [socialHistoryData]);
	/* ----------- NEW ASR START ----------- */
	const { toggleAsr, listening, speech, speechTemp, setSpeech } = useAsr();
	// useEffect(() => {
	// 	if (speech) setSocialHistory(speech?.replace('\n', '</p><p>'));
	// }, [speech]);
	// useEffect(() => {
	// 	setSpeech(socialHistory);
	// }, [socialHistory]);
	/* ----------- NEW ASR END ----------- */

	return (
		<CardBody isScrollable className='d-flex flex-column h-100'>
			<div className='row'>
				<div className='col-12 mb-3'>
					<h3>Social History</h3>
				</div>
				{/* <div className='col-2 mb-2'>
					<Button
						isOutline={!listening}
						color={listening ? 'danger' : 'primary'}
						icon='Mic'
						onClick={toggleAsr}>
						{listening ? 'Stop' : 'Speak'}
					</Button>
				</div> */}

				<div className='col-12'>
					<JoditEditor
						config={{
							defaultActionOnPaste: 'insert_as_html',
							height: '50vh',
						}}
						ref={shEditor}
						value={speechTemp ? socialHistory + speechTemp : socialHistory}
						tabIndex={1}
						onBlur={(newContent) => setSocialHistory(newContent)}
					/>
				</div>
				<div className='col-2 mt-3'>
					<Button
						isOutline={darkModeStatus}
						color='primary'
						className='border-0'
						onClick={() => isPermitted('Medical Profile', 'write', handleSubmit)}>
						Save
					</Button>
				</div>
			</div>
		</CardBody>
	);
});

export default History;
