import React from 'react';

import Lottie from 'react-lottie';
import loadergif from '../animation/loadergif.gif';

const Loader = () => {
	// const defaultOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: animationData,
	// 	rendererSettings: {
	// 		preserveAspectRatio: 'xMidYMid slice',
	// 		className: 'border-rounded',
	// 	},
	// };
	return (
		<div className='create-loader'>
			<div className='overflow-hidden' style={{ borderRadius: '50%' }}>
				{/* <Lottie height={100} width={100} options={defaultOptions} /> */}
				<img
					style={{ width: '150px', height: '150px', objectFit: 'contain' }}
					src={loadergif}
				/>
			</div>
		</div>
	);
};

export default Loader;
