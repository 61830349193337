// Navbar Icons
import Appointments from './assets/img/menu/appointments/appointments.png';
import AddAppointments from './assets/img/menu/appointments/add-appointment.png';
import MIS from './assets/img/menu/accounts/mis.png';
import Transactions from './assets/img/menu/accounts/transactions.png';
import Vendors from './assets/img/menu/accounts/vendors.png';
import AddOPD from './assets/img/menu/opd/add-opd.png';
import OPDs from './assets/img/menu/opd/opds.png';
import Inventory from './assets/img/menu/pharmacy/inventory.png';
import PharmacyVendors from './assets/img/menu/pharmacy/pharmacy-vendors.png';
import PurchaseVouchers from './assets/img/menu/pharmacy/purchase-vouchers.png';
import SaleVouchers from './assets/img/menu/pharmacy/sale-vouchers.png';
import Settings from './assets/img/menu/pharmacy/settings.png';
import AddStaff from './assets/img/menu/staff/add-staff.png';
import Attendance from './assets/img/menu/staff/attendance.png';
import Staff from './assets/img/menu/staff/staff.png';
import Summary from './assets/img/menu/ipd/summary.png';

// Settings Icons
import Doctors from './assets/img/menu/settings/doctors.png';
import Organisations from './assets/img/menu/settings/organisations.png';
import OPDStatus from './assets/img/menu/settings/opd-status.png';
import ChiefComplaints from './assets/img/menu/settings/chief-complaints.png';
import OralExaminations from './assets/img/menu/settings/oral-examinations.png';
import Treatments from './assets/img/menu/settings/treatments.png';
import PrescriptionTemplates from './assets/img/menu/settings/prescription-templates.png';
import Notes from './assets/img/menu/settings/notes.png';
import Investigations from './assets/img/menu/settings/investigations.png';

// Profile Icons
import Profile from './assets/img/menu/profile/profile.png';
import Logout from './assets/img/menu/profile/logout.png';
import { store } from './store/configureStore';

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	consultWithDoctor: {
		id: 'consultWithDoctor',
		text: 'Consult With Doctor',
		path: 'consultation/:patientId/:expertDoctorId',
		icon: 'Chat',
		subMenu: null,
	},
	doctorJoinZoomMeeting: {
		id: 'consultWithDoctor',
		text: 'Consult With Doctor',
		path: 'consultation/:patientId/:expertDoctorId/:joinMeetingNumber/:joinMeetingPass',
		icon: 'Chat',
		subMenu: null,
	},
	appointments: {
		id: 'appointments',
		text: 'Appointments',
		path: 'appointments',
		icon: 'Today',
		subMenu: {
			addAppointment: {
				id: 'addAppointment',
				text: 'Appointment List',
				path: 'appointments/add',
				icon: 'List',
				subMenu: null,
			},
			viewAppointments: {
				id: 'viewAppointments',
				text: 'Appointment Calendar',
				path: 'appointments/list',
				icon: 'Today',
				subMenu: null,
			},
		},
	},
	preventiveCareProgram: {
		id: 'preventiveCareProgram',
		text: 'Preventive Care Program',
		path: 'pcp',
		icon: 'MedicalServices',
		subMenu: {
			createProgramRequest: {
				id: 'createProgramRequest',
				text: 'Create Program Request',
				path: 'pcp/add',
				icon: 'add',
				subMenu: null,
			},
			programsList: {
				id: 'programsList',
				text: 'Programs',
				path: 'pcp/list',
				icon: 'list',
				subMenu: null,
			},
			classList: {
				id: 'classList',
				text: 'Classes',
				path: 'pcp/class-list',
				icon: 'list',
				subMenu: null,
			},
			programSetup: {
				id: 'programSetup',
				text: 'Program Setup',
				path: 'pcp/:programId/setup',
				icon: 'settings',
				subMenu: null,
				hide: true,
			},
			viewProgram: {
				id: 'viewProgram',
				text: 'View Program',
				path: 'pcp/:programId/view',
				icon: 'list',
				subMenu: null,
				hide: true,
			},
			addSubscriber: {
				id: 'addSubscriber',
				text: 'Add Subscriber',
				path: 'pcp/:programId/subscriber/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			viewSubscriber: {
				id: 'viewSubscriber',
				text: 'View Subscriber',
				path: 'pcp/:programId/subscriber/:subId',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
		},
	},
	patients: {
		id: 'patients',
		text: 'Patients',
		path: 'patients',
		icon: 'people',
		subMenu: {
			addPatient: {
				id: 'addPatient',
				text: 'Add Patient',
				path: 'patients/add',
				icon: 'add',
				subMenu: null,
			},
			viewPatients: {
				id: 'viewPatients',
				text: 'View Patients',
				path: 'patients/list',
				icon: 'list',
				subMenu: null,
			},
			prescriptionView: {
				id: 'prescriptionView',
				text: 'View Patient Prescriptions',
				path: 'patient/:id/prescriptions',
				icon: 'notes',
				subMenu: null,
				hide: true,
			},

			aida: {
				id: 'aida',
				text: 'AiDa',
				path: 'patients/:id/aida',
				icon: 'notes',
				subMenu: null,
				hide: true,
			},
			viewPatient: {
				id: 'viewPatient',
				text: 'View Patient',
				path: 'patients/:id/:tab',
				icon: 'people',
				subMenu: null,
				hide: true,
			},
			patientPrescriptionPdf: {
				id: 'patientPrescriptionPdf',
				text: 'Prescription',
				path: 'patients/:id/prescription-pdf',
				subMenu: null,
				hide: true,
			},
			patientDiagnosesPdf: {
				id: 'patientDiagnosesPdf',
				text: 'Diagnoses',
				path: 'patients/:id/diagnoses-pdf',
				subMenu: null,
				hide: true,
			},
			patientVoicePrescriptionPdf: {
				id: 'patientVoicePrescriptionPdf',
				text: 'Voice Prescription',
				path: 'patients/:id/voice-prescription-pdf',
				subMenu: null,
				hide: true,
			},
			patientBillingPdf: {
				id: 'patientBillingPdf',
				text: 'Billing',
				path: 'patients/:id/billing-pdf',
				subMenu: null,
				hide: true,
			},
			patientPaymentPdf: {
				id: 'patientPaymentPdf',
				text: 'Payment',
				path: 'patients/:id/payment-pdf',
				subMenu: null,
				hide: true,
			},
			patientOpdPdf: {
				id: 'patientOpdPdf',
				text: 'Opd',
				path: 'patients/:id/opd-pdf',
				subMenu: null,
				hide: true,
			},
			patientHistoryPdf: {
				id: 'patientHistoryPdf',
				text: 'History Report',
				path: 'patients/:id/history-report',
				subMenu: null,
				hide: true,
			},
			patientVisionPdf: {
				id: 'patientVisionPdf',
				text: 'Vision Report',
				path: 'patients/:id/vision-report',
				subMenu: null,
				hide: true,
			},
			patientImmunPdf: {
				id: 'patientImmunPdf',
				text: 'Immunization Report',
				path: 'patients/:id/immunisation-report',
				subMenu: null,
				hide: true,
			},
			patientDetailedLongReport: {
				id: 'patientDetailedLongReport',
				text: 'Detailed Long Report',
				path: 'patients/:id/detailed-report',
				subMenu: null,
				hide: true,
			},
			patientChemoNotePdf: {
				id: 'patientChemoNotePdf',
				text: 'Patient Chemo Note PDF',
				path: 'patients/:id/chemo-note-pdf',
				subMenu: null,
				hide: true,
			},
			patientAttachmentPdf: {
				id: 'patientAttachmentPdf',
				text: 'Patient Attachments PDF',
				path: 'patients/:id/attachment-pdf',
				subMenu: null,
				hide: true,
			},
		},
	},
	patientManagement: {
		id: 'patient-management',
		text: 'Patient Management',
		path: 'patients/managements',
		icon: 'people',
		subMenu: null,
		hide: true,
	},
	opd: {
		id: 'opd',
		text: 'OPD',
		path: 'opd',
		icon: 'article',
		subMenu: {
			addOpdRecord: {
				id: 'addOpdRecord',
				text: 'Add OPD Record',
				path: 'opd/add-opd-record',
				icon: 'add',
				subMenu: null,
			},
			viewOpdRecords: {
				id: 'viewOpdRecords',
				text: 'OPD Record',
				path: 'opd/records',
				icon: 'Summarize',
				subMenu: null,
			},
			pdfHeader: {
				id: 'pdfHeader',
				text: 'Pdf Header',
				path: 'opd/header-pdf',
				icon: null,
				subMenu: null,
				hide: true,
			},
		},
	},
	ipd: {
		id: 'ipd',
		text: 'IPD',
		path: 'ipd',
		icon: 'article',
		subMenu: {
			faceCard: {
				id: 'faceCard',
				text: 'Face Card',
				path: 'ipd/face-card',
				icon: 'add',
				subMenu: null,
			},
			createIpd: {
				id: 'createIpd',
				text: 'Create IPD',
				path: 'ipd/create-ipd',
				icon: 'add',
				subMenu: null,
			},
			ipdList: {
				id: 'ipdList',
				text: 'All IPDs',
				path: 'ipd/ipd-list',
				icon: 'add',
				subMenu: null,
			},
			ipdListPdf: {
				id: 'ipdListPdf',
				text: 'IPD Lists',
				path: 'ipd/ipd-list-pdf',
				icon: 'add',
				subMenu: null,
			},
			roomStatus: {
				id: 'roomStatus',
				text: 'Room Status',
				path: 'ipd/room-status',
				icon: 'summarize',
				subMenu: null,
			},
			roomStatusPdf: {
				id: 'roomStatusPdf',
				text: 'Room Status Pdf',
				path: 'ipd/room-status-pdf',
				icon: 'summarize',
				subMenu: null,
			},
			ipdsettings: {
				id: 'ipdsettings',
				text: 'IPD Settings',
				path: 'ipd/settings',
				icon: 'ListAlt',
				subMenu: null,
			},
			MIS: {
				id: 'MIS',
				text: 'IPD MIS',
				path: 'ipd/mis',
				icon: 'summarize',
				subMenu: null,
			},
			patientIpdFile: {
				id: 'patientIpdFile',
				text: 'Patient IPD File',
				path: 'ipd/:ipdId/patient/:patientId',
				icon: 'Person',
				subMenu: null,
			},
			dischargeSummaryPdf: {
				id: 'dischargeSummaryPdf',
				text: 'Discharge Summary Pdf',
				path: 'ipd/discharge-summary',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			dischargeSummary2Pdf: {
				id: 'dischargeSummary2Pdf',
				text: 'Discharge Summary Pdf',
				path: 'ipd/discharge-summary2',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			ipdBillingPdf: {
				id: 'ipdBillingPdf',
				text: 'IPD Billing Pdf',
				path: 'ipd/bill-pdf',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			ipdPaymentsPdf: {
				id: 'ipdPaymentsPdf',
				text: 'IPD Payments Pdf',
				path: 'ipd/payments-pdf',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			// STORE ROUTES AND STORE COMPONENTS ARE DECLARED UNDER THE IPD SECTION BUT HAVE A DIFFERENT NAV MENU
			storeItems: {
				id: 'storeItems',
				text: 'Store Items',
				path: 'store/items',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			storePurchases: {
				id: 'storePurchases',
				text: 'Store Purchases',
				path: 'store/purchases',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			addStorePurchase: {
				id: 'addStorePurchase',
				text: 'Add Store Purchase',
				path: 'store/purchase/:type',
				icon: null,
				subMenu: null,
				hide: true,
			},
			salesIndentation: {
				id: 'salesIndentation',
				text: 'Sales & Indentation',
				path: 'store/sales-indentation',
				icon: null,
				subMenu: null,
				hide: true,
			},
			addSalesIndentation: {
				id: 'salesIndentation',
				text: 'Sales & Indentation',
				path: 'store/sales-indentation/:type',
				icon: null,
				subMenu: null,
				hide: true,
			},
			storeInventory: {
				id: 'storeInventory',
				text: 'Store Inventory',
				path: 'store/inventory',
				icon: null,
			},
		},
	},
	pharmacy: {
		id: 'pharmacy',
		text: 'Pharmacy',
		path: 'pharmacy',
		icon: 'store',
		subMenu: {
			addPharmacy: {
				id: 'addPharmacy',
				text: 'Pharmacy Settings',
				path: 'pharmacy/add-pharmacy',
				icon: 'shop',
				subMenu: null,
				hide: true,
			},
			pharmacySettings: {
				id: 'pharmacy-settings',
				text: 'Pharmacy Settings',
				path: 'pharmacy/pharmacy-settings',
				icon: 'settings',
				subMenu: null,
			},
			addVendor: {
				id: 'addVendor',
				text: 'Add Vendor',
				path: 'pharmacy/add-vendor',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			vendors: {
				id: 'vendors',
				text: 'Vendors',
				path: 'pharmacy/vendors',
				icon: 'people',
				subMenu: null,
			},
			vendorDetails: {
				id: 'vendorsDetails',
				text: 'Vendors details',
				path: 'pharmacy/vendor/:id',
				icon: 'people',
				subMenu: null,
			},
			addPurchaseVoucher: {
				id: 'addPurchaseVoucher',
				text: 'Add Purchase Voucher',
				path: 'pharmacy/add-purchase-voucher',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			purchaseVouchers: {
				id: 'purchase-vouchers',
				text: 'Purchase Vouchers',
				path: 'pharmacy/purchase-vouchers',
				icon: 'receipt',
				subMenu: null,
			},
			returnPurchaseVouchers: {
				id: 'return-purchase-vouchers',
				text: 'Return Purchase Vouchers',
				path: 'pharmacy/purchase-voucher/:id',
				icon: 'receipt',
				subMenu: null,
			},
			inventory: {
				id: 'inventory',
				text: 'Inventory',
				path: 'pharmacy/inventory',
				icon: 'category',
				subMenu: null,
			},
			addSaleVoucher: {
				id: 'addSaleVoucher',
				text: 'Add Sale Voucher',
				path: 'pharmacy/add-sales-voucher',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			saleVouchers: {
				id: 'sale-vouchers',
				text: 'Sales Vouchers',
				path: 'pharmacy/sales-vouchers',
				icon: 'receipt',
				subMenu: null,
			},
			saleVoucher: {
				id: 'saleVoucher',
				text: 'Sale Voucher',
				path: 'pharmacy/sales-voucher',
				subMenu: null,
				hide: true,
			},
			paymentsList: {
				id: 'paymentsList',
				text: 'Payments List',
				path: 'pharmacy/payments-list',
				icon: 'people',
				subMenu: null,
			},
			wholeSaleVoucher: {
				id: 'wholesale-vouchers',
				text: 'PoS(Point of Sales)',
				path: 'pharmacy/whole-sales',
				icon: 'receipt',
				subMenu: null,
			},
			wholeSalePdf: {
				id: 'wholesale-vouchers',
				text: 'PoS(Point of Sales)',
				path: 'pharmacy/whole-sales-pdf',
				icon: 'receipt',
				subMenu: null,
			},
			addWholeSaleVoucher: {
				id: 'add-wholesale-vouchers',
				text: 'PoS(Point of Sales)',
				path: 'pharmacy/add-wholesale',
				icon: 'receipt',
				subMenu: null,
			},
			vendorProfile: {
				id: 'vendorProfile',
				text: 'Stockist Profile',
				path: 'pharmacy/vendor/profile/:id',
				icon: 'people',
				subMenu: null,
			},
			stockMedicine: {
				id: 'stockMedicine',
				text: 'Stock Medicines',
				path: 'pharmacy/stock-medicines',
				icon: 'people',
				subMenu: null,
			},
			expiryDateMedicine: {
				id: 'expiryDateMedicine',
				text: 'Expiry Date Medicines',
				path: 'pharmacy/expiry-date-medicines',
				icon: 'people',
				subMenu: null,
			},
			gstReports: {
				id: 'gstReports',
				text: 'GST Reports',
				path: 'pharmacy/gst-reports',
				icon: 'people',
				subMenu: null,
			},
			gstSummary: {
				id: 'gstSummary',
				text: 'Sales/Purchase Summary',
				path: 'pharmacy/gst-summary',
				icon: 'people',
				subMenu: null,
			},
			gstReport3B: {
				id: 'gstReport3B',
				text: 'GST Summary',
				path: 'pharmacy/gst-report-summary',
				icon: 'people',
				subMenu: null,
			},
			gstReportsPrint: {
				id: 'gstReportsPrint',
				text: 'GST Reports Summary',
				path: 'pharmacy/gst-reports/print',
				icon: 'people',
				subMenu: null,
			},
		},
	},
	lab: {
		id: 'lab',
		text: 'Lab',
		path: 'lab',
		icon: 'article',
		subMenu: {
			// labTest: {
			// 	id: 'labTest',
			// 	text: 'Lab Test',
			// 	path: 'lab/test',
			// 	icon: 'article',
			// 	subMenu: null,
			// },
			labParameters: {
				id: 'labParameters',
				text: 'Lab Parameter Master',
				path: 'lab/parameters',
				icon: 'article',
				subMenu: null,
			},
			labTemplates: {
				id: 'labTemplates',
				text: 'Lab Tests',
				path: 'lab/templates',
				icon: 'article',
				subMenu: null,
			},
			billing: {
				id: 'billing',
				text: 'Billing',
				path: 'lab/billing',
				icon: 'article',
				subMenu: null,
			},
			addBilling: {
				id: 'addBilling',
				text: 'Add Billing',
				path: 'lab/billing/add',
				icon: 'article',
				subMenu: null,
			},
			billingPdf: {
				id: 'billingPdf',
				text: 'BillingPdf',
				path: 'lab/billing/print',
				subMenu: null,
				hide: true,
			},
			labReportPdf: {
				id: 'labReportPdf',
				text: 'ReportPdf',
				path: 'lab/billing/report',
				subMenu: null,
				hide: true,
			},
		},
	},
	staff: {
		id: 'staff',
		text: 'Staff',
		path: 'staff',
		icon: 'article',
		subMenu: {
			addStaff: {
				id: 'addStaff',
				text: 'Add Staff',
				path: 'staff/add',
				icon: 'add',
				subMenu: null,
			},
			viewStaff: {
				id: 'viewStaff',
				text: 'Staff Record',
				path: 'staff/list',
				icon: 'ListAlt',
				subMenu: null,
			},
			staffPermissions: {
				id: 'staffPermissions',
				text: 'Staff Permissions',
				path: 'staff/permissions',
				icon: 'VpnKey',
				subMenu: null,
				hide: true,
			},
			viewStaffAttendance: {
				id: 'viewStaffAttendance',
				text: 'Staff Attendance',
				path: 'staff/attendances',
				icon: 'ListAlt',
				subMenu: null,
			},
			viewStaffDetails: {
				id: 'viewStaffDetails',
				text: 'Staff Details',
				path: 'staff/view',
				icon: 'ListAlt',
				subMenu: null,
				hide: true,
			},
		},
	},
	accounts: {
		id: 'accounts',
		text: 'Accounts & Finance',
		path: 'accounts',
		icon: 'AccountCircle',
		subMenu: {
			addVendor: {
				id: 'addVendor',
				text: 'Add Vendors',
				path: 'account/vendor/add',
				icon: 'Add',
				subMenu: null,
				hide: true,
			},
			vendors: {
				id: 'vendors',
				text: 'Vendors',
				path: 'account/vendors',
				icon: 'people',
				subMenu: null,
			},
			addTransactions: {
				id: 'addTransactions',
				text: 'Add Transactions',
				path: 'account/transaction/add',
				icon: 'Add',
				subMenu: null,
				hide: true,
			},
			transactions: {
				id: 'transactions',
				text: 'Vendor Transactions',
				path: 'account/transactions',
				icon: 'MonetizationOn',
				subMenu: null,
			},
			expenses: {
				id: 'expenses',
				text: 'Expense Book',
				path: 'account/expenses',
				icon: 'MonetizationOn',
				subMenu: null,
			},
			summary: {
				id: 'summary',
				text: 'Summary',
				path: 'account/summary',
				icon: 'Summarize',
				subMenu: null,
			},
			staffMis: {
				id: 'staffMis',
				text: 'Staff Summary',
				path: 'account/mis/staff',
				icon: 'Summarize',
				subMenu: null,
			},
			mis: {
				id: 'mis',
				text: 'Summary',
				path: 'account/mis/summary',
				icon: 'Summarize',
				subMenu: null,
			},
			patientMis: {
				id: 'patientMis',
				text: 'Summary',
				path: 'account/mis/patient/summary',
				icon: 'Summarize',
				subMenu: {
					ageView: {
						id: 'ageView',
						text: 'Age Group MIS',
						path: 'account/mis/summary/age/:ageGroup',
						icon: 'Summarize',
						subMenu: null,
					},
					cityView: {
						id: 'ageView',
						text: 'City Group MIS',
						path: 'account/mis/summary/city/:city',
						icon: 'Summarize',
						subMenu: null,
					},
				},
			},
			doctorMis: {
				id: 'doctorMis',
				text: 'Summary',
				path: 'account/mis/doctor/summary',
				icon: 'Summarize',
				subMenu: {
					doctorView: {
						id: 'doctorView',
						text: 'Doctor MIS',
						path: 'account/mis/doctor/:doctorId',
						icon: 'Summarize',
						subMenu: null,
					},
				},
			},
			rxMis: {
				id: 'doctorMis',
				text: 'Summary',
				path: 'account/mis/rx/summary',
				icon: 'Summarize',
				subMenu: {
					rxView: {
						id: 'rxView',
						text: 'Rx MIS',
						path: 'account/mis/rx/:rxType/:rxId',
						icon: 'Summarize',
						subMenu: null,
					},
				},
			},
			diagnosticMis: {
				id: 'diagnosticMis',
				text: 'Summary',
				path: 'account/mis/diagnostic/summary',
				icon: 'Summarize',
				subMenu: {
					diagnosticView: {
						id: 'diagnosticView',
						text: 'Diagnostic MIS',
						path: 'account/mis/diagnostic/:diagnosticType/:diagnosticId',
						icon: 'Summarize',
						subMenu: null,
					},
				},
			},
			printMis: {
				id: 'printMis',
				text: 'MIS Export',
				path: 'account/mis/export',
				icon: 'Summarize',
				subMenu: null,
			},
			printDrWise: {
				id: 'printDrWise',
				text: 'MIS Export',
				path: 'account/mis/dr/export',
				icon: 'Summarize',
				subMenu: null,
			},
			misSpecific: {
				id: 'misSpecific',
				text: 'Summary',
				path: 'account/mis/:type',
				icon: 'Summarize',
				subMenu: null,
			},
			staffMisSpecific: {
				id: 'staffMisSpecific',
				text: 'Summary',
				path: 'account/mis/staff/:type',
				icon: 'Summarize',
				subMenu: null,
			},
			staffMisPDF: {
				id: 'staffMisPDF',
				text: 'Staff Miss Pdf',
				path: 'account/mis/staff/pdf',
				icon: 'Person',
				subMenu: null,
				hide: true,
			},
			drWiseStaffMisPDF: {
				id: 'drWiseStaffMisPDF',
				text: 'Staff Summary',
				path: 'account/mis/staff/dr/export',
				icon: 'Summarize',
				subMenu: null,
			},
		},
	},
	settings: {
		id: 'settings',
		text: 'Settings',
		path: 'settings',
		icon: 'Settings',
		subMenu: {
			profile: {
				id: 'profile',
				text: 'Profile',
				path: 'profile',
				icon: 'person',
				subMenu: null,
			},
			consents: {
				id: 'consents',
				text: 'Consents/Medical Certificates',
				path: 'settings/consents',
				icon: 'add',
				subMenu: null,
				hide: false,
			},
			vendorSettings: {
				id: 'vendorSettings',
				text: 'Doctor Settings',
				path: 'profile/:vendorId',
				icon: 'person',
				subMenu: null,
			},
			addDoctor: {
				id: 'addDoctor',
				text: 'Add Doctor',
				path: 'settings/doctors/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			viewDoctors: {
				id: 'viewDoctors',
				text: 'Doctors',
				path: 'settings/doctors',
				icon: 'People',
				subMenu: null,
			},
			addOrganisation: {
				id: 'addOrganisation',
				text: 'Add Organisation',
				path: 'settings/organisations/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			viewOrganisations: {
				id: 'viewOrganisations',
				text: 'Organisations',
				path: 'settings/organisations',
				icon: 'Building',
				subMenu: null,
			},
			addDepartment: {
				id: 'addDepartment',
				text: 'Add Department',
				path: 'settings/departments/add',
				icon: 'add',
				subMenu: null,
				hide: true,
			},
			viewDepartments: {
				id: 'viewDepartments',
				text: 'View Departments',
				path: 'settings/departments',
				icon: 'list',
				subMenu: null,
				hide: true,
			},
			opdStatuses: {
				id: 'opd-statuses',
				text: 'OPD Statuses',
				path: 'settings/statuses',
				icon: 'ListAlt',
				subMenu: null,
			},
			chiefComplaints: {
				id: 'chief-complaints',
				text: 'Chief Complaints',
				path: 'settings/chief-complaints',
				icon: 'Feed',
				subMenu: null,
			},
			oralExaminations: {
				id: 'oral-examinations',
				text: 'Oral Examinations',
				path: 'settings/oral-examinations',
				icon: 'Article',
				subMenu: null,
			},
			algorithms: {
				id: 'algorithms',
				text: 'Algorithms',
				path: 'settings/algorithms',
				icon: 'Article',
				subMenu: null,
			},
			diagnoses: {
				id: 'diagnoses',
				text: 'Diagnosis',
				path: 'settings/diagnoses',
				icon: 'Medication',
				subMenu: null,
			},
			communicationGroups: {
				id: 'communication-groups',
				text: 'Communication Groups',
				path: 'settings/communication-groups',
				icon: 'Announcement',
				subMenu: null,
			},
			referralTypes: {
				id: 'referral-types',
				text: 'Referral Types',
				path: 'settings/referral-types',
				icon: 'BorderAll',
				subMenu: null,
				hide: true,
			},
			externalReferrers: {
				id: 'external-referrars',
				text: 'External Referrers',
				path: 'settings/external-referrars',
				icon: 'PersonAdd',
				subMenu: null,
				hide: true,
			},
			treatments: {
				id: 'treatments',
				text: 'Procedures',
				path: 'settings/procedures',
				icon: 'LocalHospital',
				subMenu: null,
			},
			rosTemplates: {
				id: 'rosTemplates',
				text: 'ROS Templates',
				path: 'settings/rosTemplates',
				icon: 'ContentPaste',
				subMenu: null,
			},
			prescriptionTemplates: {
				id: 'prescriptionTemplates',
				text: 'Prescription Templates',
				path: 'settings/prescriptionTemplates',
				icon: 'ContentPaste',
				subMenu: null,
			},
			investigationTemplates: {
				id: 'investigationTemplates',
				text: 'Investigation Templates',
				path: 'settings/investigationTemplates',
				icon: 'ContentPaste',
				subMenu: null,
			},
			notes: {
				id: 'notes',
				text: 'Notes',
				path: 'settings/notes',
				icon: 'Note',
				subMenu: null,
			},
			specialInstructionTemplates: {
				id: 'specialInstructionTemplates',
				text: 'Special Instructions',
				path: 'settings/special-instructions',
				icon: 'Special Instructions',
				subMenu: null,
			},
			billprefix: {
				id: 'billprefix',
				text: 'Bill No. Prefix',
				path: 'settings/billprefix',
				icon: 'Note',
				subMenu: null,
			},
			investigations: {
				id: 'investigations',
				text: 'Investigations',
				path: 'settings/investigations',
				icon: 'FindInPage',
				subMenu: null,
			},
			medicineRows: {
				id: 'medicineRows',
				text: 'Medicine Rows',
				path: 'settings/medicine-rows',
				icon: 'FindInPage',
				subMenu: null,
			},
			doctorMedicines: {
				id: 'doctorMedicines',
				text: "Doctor's Medicines",
				path: 'settings/doctor-medicines',
				icon: 'FindInPage',
				subMenu: null,
			},
		},
	},
	store: {
		id: 'store',
		text: 'Store',
		path: 'store',
		icon: '',
		subMenu: {
			items: {
				id: 'items',
				text: 'Items',
				path: 'store/items',
				icon: 'LocalHospital',
			},
			purchase: {
				id: 'purchase',
				text: 'Store Purchase',
				path: 'store/purchase',
				icon: 'LocalHospital',
			},
		},
	},
	telecrm: {
		id: 'tele-crm',
		text: 'TeleCRM',
		path: 'tele-crm',
		icon: '',
		subMenu: {
			allPatients: {
				id: 'allPatients',
				text: 'All Patients',
				path: 'tele-crm/all-patients',
				icon: '',
				subMenu: null,
			},
			addLeadSuggestion: {
				id: 'addLeadSuggestion',
				text: 'Add Lead Suggestion',
				path: 'tele-crm/add-lead-suggestion',
				icon: '',
				subMenu: null,
			},
			allNotes: {
				id: 'allNotes',
				text: 'All Notes',
				path: 'tele-crm/all-notes',
				icon: '',
				subMenu: null,
			},
			patientDetails: {
				id: 'patientDetails',
				text: 'Patient Details',
				path: 'tele-crm/call-logs/patient-details',
				icon: '',
				subMenu: null,
				hide: true,
			},
			patientCallLogs: {
				id: 'patientCallLogs',
				text: 'Patient Call Logs',
				path: 'tele-crm/call-logs/patient-call-logs',
				icon: '',
				subMenu: null,
				hide: true,
			},
			patientAppointmentLogs: {
				id: 'patientAppointmentLogs',
				text: 'Patient Appointment Logs',
				path: 'tele-crm/call-logs/patient-appointment-logs',
				icon: '',
				subMenu: null,
				hide: true,
			},
			callLogs: {
				id: 'callLogs',
				text: 'Call Logs',
				path: 'tele-crm/call-logs',
				icon: '',
				subMenu: null,
			},
			leads: {
				id: 'leads',
				text: 'Leads',
				path: 'tele-crm/leads',
				icon: '',
				subMenu: null,
			},
		},
	},
	// remoteClinic: {
	// 	id: 'store',
	// 	text: 'Store',
	// 	path: 'store',
	// 	icon: '',
	// 	subMenu: {
	// 		sharedFiles: {
	// 			id: 'sharedFiles',
	// 			text: 'Shared Files',
	// 			path: '/shared-files',
	// 			icon: 'receipt',
	// 			subMenu: null,
	// 		},

	// 		remoteClinicAppointments: {
	// 			id: 'remoteClinicAppointments',
	// 			text: 'Shared Files',
	// 			path: '/remote-appointments',
	// 			icon: 'sharedFiles',
	// 			subMenu: null,
	// 		},

	// 		myReferrals: {
	// 			id: 'myReferrals',
	// 			text: 'My Referrals',
	// 			path: '/my-referrals',
	// 			icon: 'sharedFiles',
	// 			subMenu: null,
	// 		},
	// 	},
	// },
};

export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth-pages/login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'auth-pages/sign-up',
		icon: 'PersonAdd',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
	logout: {
		id: 'logout',
		text: 'Logging Out',
		path: 'auth-pages/logout',
		icon: 'logout',
	},
};

export const navMenu = {
	appointments: {
		id: 'appointments',
		text: 'Appointments',
		type: 'doctor',
		subMenu: {
			addAppointment: {
				id: 'addAppointment',
				icon: AddAppointments,

				iconFamily: 'bootstrap',
				text: 'Add Appointment',
				path: dashboardMenu.appointments.subMenu.addAppointment.path,
			},
			viewAppointments: {
				id: 'viewAppointments',
				icon: Appointments,
				iconFamily: 'bootstrap',
				text: 'View Appointments',
				path: dashboardMenu.appointments.subMenu.viewAppointments.path,
			},
		},
	},
	patients: {
		id: 'patients',
		text: 'Patients',
		type: 'doctor',
		subMenu: {
			addPatient: {
				id: 'addPatient',
				icon: AddStaff,
				text: 'Add Patient',
				path: dashboardMenu.patients.subMenu.addPatient.path,
			},
			patients: {
				id: 'patients',
				icon: Vendors,
				text: 'Patients',
				path: dashboardMenu.patients.subMenu.viewPatients.path,
			},
		},
	},
	opd: {
		id: 'opd',
		text: 'OPD',
		type: 'doctor',
		subMenu: {
			addOpd: {
				id: 'addOpd',
				icon: AddOPD,
				text: 'Add OPD',
				path: dashboardMenu.opd.subMenu.addOpdRecord.path,
			},
			viewOpds: {
				id: 'viewOpds',
				icon: OPDs,
				text: 'View OPDs',
				path: dashboardMenu.opd.subMenu.viewOpdRecords.path,
			},
		},
	},
	ipd: {
		id: 'ipd',
		text: 'IPD',
		type: 'ipd',
		subMenu: {
			addIpd: {
				type: 'ipd',
				id: 'addIpd',
				icon: AddOPD,
				text: 'Admit Patient',
				path: dashboardMenu.ipd.subMenu.createIpd.path,
			},
			addOpd: {
				type: 'ipd',
				id: 'ipdList',
				icon: Summary,
				text: 'All IPDs',
				path: dashboardMenu.ipd.subMenu.ipdList.path,
			},
			roomStatus: {
				type: 'ipd',
				id: 'roomStatus',
				icon: OPDs,
				text: 'Room Status',
				path: dashboardMenu.ipd.subMenu.roomStatus.path,
			},
			ipdSettings: {
				type: 'ipd',
				id: 'ipdSettings',
				icon: Settings,
				text: 'IPD Settings',
				path: dashboardMenu.ipd.subMenu.ipdsettings.path,
			},
			mis: {
				type: 'ipd',
				id: 'mis',
				icon: MIS,
				text: 'MIS',
				path: dashboardMenu.ipd.subMenu.MIS.path,
			},
		},
	},
	pharmacy: {
		id: 'pharmacy',
		text: 'Pharmacy',
		type: 'pharmacy',
		subMenu: {
			vendors: {
				id: 'vendors',
				icon: PharmacyVendors,
				text: 'Stockists',
				path: dashboardMenu.pharmacy.subMenu.vendors.path,
			},
			payments: {
				id: 'payments',
				icon: MIS,
				text: 'Payments',
				path: dashboardMenu.pharmacy.subMenu.paymentsList.path,
			},
			purchaseVouchers: {
				id: 'purchaseVouchers',
				icon: PurchaseVouchers,
				text: 'Purchase Vouchers',
				path: dashboardMenu.pharmacy.subMenu.purchaseVouchers.path,
			},
			saleVouchers: {
				id: 'saleVouchers',
				icon: SaleVouchers,
				text: 'PoS(Point of Sales)',
				path: dashboardMenu.pharmacy.subMenu.saleVouchers.path,
			},
			wholeSales: {
				id: 'wholeSales',
				icon: SaleVouchers,
				text: 'Wholesales',
				path: dashboardMenu.pharmacy.subMenu.wholeSaleVoucher.path,
			},
			inventory: {
				id: 'inventory',
				icon: Inventory,
				text: 'Inventory',
				path: dashboardMenu.pharmacy.subMenu.inventory.path,
			},
			// mis: {
			// 	id: 'mis',
			// 	icon: MIS,
			// 	text: 'MIS',
			// 	path: 'not-found',
			// },
			stockMedicine: {
				id: 'stockMedicine',
				icon: Inventory,
				text: 'Stock Medicines',
				path: dashboardMenu.pharmacy.subMenu.stockMedicine.path,
			},
			expiryDateMedicine: {
				id: 'expiryDateMedicine',
				icon: Inventory,
				text: 'Expiry Date Medicines',
				path: dashboardMenu.pharmacy.subMenu.expiryDateMedicine.path,
			},
			gstReports: {
				id: 'gstReports',
				icon: SaleVouchers,
				text: 'GST Reports',
				path: dashboardMenu.pharmacy.subMenu.gstReports.path,
			},
			gstSummary: {
				id: 'gstSummary',
				icon: SaleVouchers,
				text: 'Sales/Purchase Summary',
				path: dashboardMenu.pharmacy.subMenu.gstSummary.path,
			},
			gstReport3B: {
				id: 'gstReport3B',
				icon: SaleVouchers,
				text: 'GST Summary',
				path: dashboardMenu.pharmacy.subMenu.gstReport3B.path,
			},
			settings: {
				id: 'settings',
				icon: Settings,
				text: 'Settings',
				path: dashboardMenu.pharmacy.subMenu.pharmacySettings.path,
			},
		},
	},
	store: {
		id: 'store',
		text: 'Store',
		path: 'store',
		icon: 'store',
		subMenu: {
			storeItems: {
				type: 'ipd',
				id: 'storeItems',
				icon: Transactions,
				text: 'Store Items',
				path: dashboardMenu.ipd.subMenu.storeItems.path,
			},
			inventory: {
				type: 'ipd',
				id: 'inventory',
				icon: Inventory,
				text: 'Inventory',
				path: dashboardMenu.ipd.subMenu.storeInventory.path,
			},
			purchaseItems: {
				type: 'ipd',
				id: 'purchaseItems',
				icon: PurchaseVouchers,
				text: 'Store Purchases',
				path: dashboardMenu.ipd.subMenu.storePurchases.path,
			},
			salesIndentation: {
				type: 'ipd',
				id: 'salesIndentation',
				icon: SaleVouchers,
				text: 'Sales & Indentation',
				path: dashboardMenu.ipd.subMenu.salesIndentation.path,
			},
		},
	},
	preventiveCareProgram: {
		id: 'preventiveCareProgram',
		text: 'PCP',
		path: 'pcp',
		icon: 'MedicalServices',
		subMenu: {
			programsList: {
				id: 'programsList',
				text: 'Programs',
				path: 'pcp/list',
				icon: SaleVouchers,
				subMenu: null,
			},
			classList: {
				id: 'classList',
				text: 'Classes',
				path: 'pcp/class-list',
				icon: SaleVouchers,
				subMenu: null,
			},
		},
	},
	lab: {
		id: 'lab',
		text: 'Lab',
		path: 'lab',
		icon: 'article',
		subMenu: {
			// labTest: {
			// 	id: 'labTest',
			// 	text: 'Lab Test',
			// 	path: dashboardMenu.lab.subMenu.labTest.path,
			// 	icon: AddOPD,
			// 	subMenu: null,
			// },
			labParameterMaster: {
				id: 'labParameterMaster',
				text: 'Parameters',
				path: dashboardMenu.lab.subMenu.labParameters.path,
				icon: Notes,
				subMenu: null,
			},
			labTemplates: {
				id: 'labTemplates',
				text: 'Tests',
				path: dashboardMenu.lab.subMenu.labTemplates.path,
				icon: Summary,
				subMenu: null,
			},
			billing: {
				id: 'billing',
				text: 'Billing',
				path: dashboardMenu.lab.subMenu.billing.path,
				icon: OPDs,
				subMenu: null,
			},
		},
	},
	staff: {
		id: 'staff',
		text: 'Staff',
		type: 'staff',
		subMenu: {
			staff: {
				id: 'staff',
				icon: Staff,
				text: 'Staff',
				path: dashboardMenu.staff.subMenu.viewStaff.path,
			},
			addStaff: {
				id: 'addStaff',
				icon: AddStaff,
				text: 'Add Staff',
				path: dashboardMenu.staff.subMenu.addStaff.path,
			},
			attendance: {
				id: 'attendance',
				icon: Attendance,
				text: 'Attendance',
				path: dashboardMenu.staff.subMenu.viewStaffAttendance.path,
			},
			staffMis: {
				id: 'staffMis',
				text: 'Staff MIS',
				path: dashboardMenu.accounts.subMenu.staffMis.path,
				icon: Staff,
			},
		},
	},
	accounts: {
		id: 'accounts',
		text: 'Accounts',
		type: 'account',
		subMenu: {
			vendors: {
				id: 'vendors',
				icon: Vendors,
				text: 'Vendors',
				path: dashboardMenu.accounts.subMenu.vendors.path,
			},
			transactions: {
				id: 'transactions',
				icon: Transactions,
				text: 'Transactions',
				path: dashboardMenu.accounts.subMenu.transactions.path,
			},
			expenses: {
				id: 'expenses',
				icon: Transactions,
				text: 'Expense Book',
				path: dashboardMenu.accounts.subMenu.expenses.path,
			},
			summary: {
				id: 'summary',
				text: 'Account Summary',
				path: dashboardMenu.accounts.subMenu.summary.path,
				icon: Summary,
			},
			mis: {
				id: 'mis',
				text: 'Organisation MIS',
				path: dashboardMenu.accounts.subMenu.mis.path,
				icon: MIS,
			},
		},
	},
	// tele: {
	// 	id: 'tele',
	// 	text: 'TeleCRM',
	// 	type: 'tele',
	// 	subMenu: {
	// 		allPatients: {
	// 			id: 'allPatients',
	// 			icon: Vendors,
	// 			text: 'All Patients',
	// 			path: dashboardMenu.telecrm.subMenu.allPatients.path,
	// 		},
	// 		allNotes: {
	// 			id: 'allNotes',
	// 			text: 'All Notes',
	// 			path: 'tele-crm/all-notes',
	// 			icon: Notes,
	// 			subMenu: null,
	// 		},
	// 		addLeadSuggestion: {
	// 			id: 'addLeadSuggestion',
	// 			icon: AddStaff,
	// 			text: 'Add Lead Suggestion',
	// 			path: dashboardMenu.telecrm.subMenu.addLeadSuggestion.path,
	// 		},
	// 		callLogs: {
	// 			id: 'callLogs',
	// 			icon: SaleVouchers,
	// 			text: 'Call Logs',
	// 			path: dashboardMenu.telecrm.subMenu.callLogs.path,
	// 		},
	// 		leads: {
	// 			id: 'leads',
	// 			icon: SaleVouchers,
	// 			text: 'Leads',
	// 			path: dashboardMenu.telecrm.subMenu.leads.path,
	// 		},
	// 	},
	// },
	// remoteClinic: {
	// 	id: 'remoteClinic',
	// 	text: 'Remote Clinic',
	// 	icon: 'MedicalServices',
	// 	subMenu: {
	// 		myReferrals: {
	// 			id: 'myReferrals',
	// 			text: 'My Referrals',
	// 			path: dashboardMenu.remoteClinic.subMenu.myReferrals.path,
	// 			icon: PharmacyVendors,
	// 			subMenu: null,
	// 		},
	// 		remoteClinic: {
	// 			id: 'remoteClinic',
	// 			text: 'Remote Clinic',
	// 			path: dashboardMenu.remoteClinic.subMenu.remoteClinicAppointments.path,
	// 			icon: AddOPD,
	// 			subMenu: null,
	// 		},
	// 		sharedFiles: {
	// 			id: 'sharedFiles',
	// 			text: 'Shared Files',
	// 			path: dashboardMenu.remoteClinic.subMenu.sharedFiles.path,
	// 			icon: SaleVouchers,
	// 		},
	// 	},
	// },
};

export const settingsMenu = {
	doctors: {
		id: 'doctors',
		icon: Doctors,
		text: 'Doctors',
		path: dashboardMenu.settings.subMenu.viewDoctors.path,
	},
	organisations: {
		id: 'organisations',
		icon: Organisations,
		text: 'Organisations',
		path: dashboardMenu.settings.subMenu.viewOrganisations.path,
	},
	consents: {
		id: 'consents',
		icon: OralExaminations,
		text: 'Consents/Medical Certificates',
		path: dashboardMenu.settings.subMenu.consents.path,
	},
	opdStatuses: {
		id: 'opdStatuses',
		icon: OPDStatus,
		text: 'OPD Status',
		path: dashboardMenu.settings.subMenu.opdStatuses.path,
	},
	algorithms: {
		id: 'algorithms',
		icon: Investigations,
		text: 'Algorithms Subscription',
		path: dashboardMenu.settings.subMenu.algorithms.path,
	},
	chiefComplaints: {
		id: 'chiefComplaints',
		icon: ChiefComplaints,
		text: 'Chief Complaints',
		path: dashboardMenu.settings.subMenu.chiefComplaints.path,
	},
	// oralExaminations: {
	// 	id: 'oralExaminations',
	// 	icon: OralExaminations,
	// 	text: 'Oral Examinations',
	// 	path: dashboardMenu.settings.subMenu.oralExaminations.path,
	// },
	treatments: {
		id: 'treatments',
		icon: Treatments,
		text: 'Procedures',
		path: dashboardMenu.settings.subMenu.treatments.path,
	},
	prescriptionTemplates: {
		id: 'prescriptionTemplates',
		icon: PrescriptionTemplates,
		text: 'Prescription Templates',
		path: dashboardMenu.settings.subMenu.prescriptionTemplates.path,
	},
	rosTemplates: {
		id: 'rosTemplates',
		icon: PrescriptionTemplates,
		text: 'ROS Templates',
		path: dashboardMenu.settings.subMenu.rosTemplates.path,
	},
	// investigationTemplates: {
	// 	id: 'investigationTemplates',
	// 	icon: Investigations,
	// 	text: 'Investigation Templates',
	// 	path: dashboardMenu.settings.subMenu.investigationTemplates.path,
	// },
	notes: {
		id: 'notes',
		icon: Notes,
		text: 'Notes',
		path: dashboardMenu.settings.subMenu.notes.path,
	},
	specialInstructionTemplates: {
		id: 'specialInstructionTemplates',
		icon: Treatments,
		text: 'Special Instructions Templates',
		path: dashboardMenu.settings.subMenu.specialInstructionTemplates.path,
	},
	billprefix: {
		id: 'billprefix',
		icon: Notes,
		text: 'Bill No. Prefix',
		path: dashboardMenu.settings.subMenu.billprefix.path,
	},
	investigations: {
		id: 'investigations',
		icon: Investigations,
		text: 'Investigations',
		path: dashboardMenu.settings.subMenu.investigations.path,
	},
	medicineRows: {
		id: 'medicineRows',
		icon: Inventory,
		text: 'Medicine Rows',
		path: dashboardMenu.settings.subMenu.medicineRows.path,
	},
	doctorMedicines: {
		id: 'doctorMedicines',
		icon: Inventory,
		text: "Doctor's Medicines",
		path: dashboardMenu.settings.subMenu.doctorMedicines.path,
	},
};

export const profileMenu = {
	profile: {
		id: 'profile',
		icon: Profile,
		text: 'Profile',
		path: dashboardMenu.settings.subMenu.profile.path,
	},
	logout: {
		id: 'logout',
		icon: Logout,
		text: 'Logout',
		path: 'auth-pages/logout',
	},
	privacyPolicy: {
		id: 'privacyPolicy',
		icon: SaleVouchers,
		text: 'Privacy Policy',
		path: 'privacy-policy',
	},
	refundPolicy: {
		id: 'refundPolicy',
		icon: SaleVouchers,
		text: 'Refund or Cancellation Policy',
		path: 'refund-or-cancellation-policy',
	},
	termsAndConditions: {
		id: 'termsAndConditions',
		icon: SaleVouchers,
		text: 'Terms and Conditions',
		path: 'terms-and-conditions',
	},
	contactUs: {
		id: 'contactUs',
		icon: Vendors,
		text: 'Contact Us',
		path: 'contact-us',
	},
};

export const calculatorMenu = {
	mainPage: {
		id: 'mainPage',
		text: 'Calculators',
		path: 'patients/calculator',
		icon: 'Calculator',
		subMenu: null,
	},
	alleregy: {
		id: 'alleregy',
		text: 'ALLERGY AND IMMUNOLOGY CALCULATORS',
		path: 'patients/calculator/alleregy/:category/:id',
		icon: 'Calculator',
		subMenu: null,
	},
	oncology: {
		id: 'oncology',
		text: 'ONCOLOGY CALCULATORS',
		path: 'patients/calculator/oncology/:category/:id',
		icon: 'Calculator',
		subMenu: null,
	},
	anesthesiology: {
		id: 'anesthesiology',
		text: 'ANESTHESIOLOGY CALCULATORS',
		path: 'patients/calculator/anesthesiology/:category/:id',
		icon: 'Calculator',
		subMenu: null,
	},
	anesthesiologys: {
		id: 'anesthesiologys',
		text: 'Medical Equations',
		path: 'patients/calculator/anesthesiology/:category/:id',
		icon: 'Calculator',
		subMenu: null,
	},
};
