import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';

import Card, {
	CardActions,
	CardBody,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import Select from '../../../../components/bootstrap/forms/Select';
import {
	addBillInvestigation,
	addBillNursingItem,
	addBillOther,
	addBillProcedure,
	addBillVisitingDoctor,
	addConsummableChargestoOtherChargesBill,
	addIpdBill,
	deleteBillOther,
	deleteBillPayment,
	getIpdBillDetails,
	getRefunds,
	updateBillInvestigation,
	updateBillNursingItem,
	updateBillProcedure,
	updateBillType,
} from '../../../../actions/ipdPatientActions';
import moment from 'moment';
import { invalidNums, renderNumber } from '../../../../helpers/helpers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { useNavigate } from 'react-router-dom';
import {
	getBillPrefix,
	getIpdOther,
	getNursingList,
	getTreatments,
} from '../../../../actions/settingActions';
import {
	confirmPatientStore,
	deleteIpdPatientBed,
	getVisitingDoctorSuggestions,
	updateIpdPatientBed,
} from '../../../../actions/ipdActions';
import classNames from 'classnames';
import { getBillTypes, isPermitted } from '../../../../actions/helperActions';
import { getPatientStoreAssignList } from '../../../../actions/storeActions';

const validate = (values) => {
	const errors = {};

	if (!values.item) {
		if (!values.type) errors.item = 'Please select type';
		else errors.item = 'Required';
	}
	if (!values.unit) {
		errors.unit = 'Required';
	}
	if (!values.rate) {
		errors.rate = 'Required';
	}
	if (!values.type) {
		errors.type = 'Required';
	}

	return errors;
};

const IpdBillingTab = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const hideBill = useSelector((state) => state.profile.selectedOrg?.Organisation?.isHiddenBill);
	const investigations = useSelector((state) => state.setting.investigationRateList);
	const moreInvestigations = useSelector((state) => state.setting.investigations);
	const treatments = useSelector((state) => state.setting.treatments);
	const ipdOthers = useSelector((state) => state.setting.ipdOthers);
	const nursingList = useSelector((state) => state.ipd.nursingList);
	const billDetails = useSelector((state) => state.ipdPatient.billDetails);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const patientStoreAssign = useSelector((state) => state.ipd.patientStoreAssign);
	const refunds = useSelector((state) => state.ipdPatient.refunds);
	const patientBedList = useSelector((state) => state.ipdPatient.bedList);
	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);
	const visitingDoctorSuggestions = useSelector(
		(state) => state.ipdPatient.vistingDoctorSuggestions,
	);

	const refundAmount = useMemo(
		() => refunds?.map((x) => x.amount).reduce((a, b) => a + b, 0),
		[refunds],
	);

	const paymentsTotal = useMemo(
		() =>
			billDetails?.Ipd_payments?.map((i) => (i.amount ? parseFloat(i.amount) : 0)).reduce(
				(i, j) => i + j,
				0,
			),
		[billDetails],
	);

	const [addItemModal, setAddItemModal] = useState(false);
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [editItem, setEditItem] = useState(null);
	const [dicountModal, setDicountModal] = useState(false);
	const [editStayModal, setEditStayModal] = useState(false);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [discountPercentage, setDiscountPercentage] = useState(0);
	const [remark, setRemark] = useState('');
	const [item, setItem] = useState(null);
	const [billTypes, setBillTypes] = useState({});
	const [consummableChargeList, setConsummableChargeList] = useState([]);

	const formikStay = useFormik({
		initialValues: {
			id: '',
			ipdId,
			startDate: undefined,
			endDate: undefined,
		},
		onSubmit: (values, { resetForm }) => {
			dispatch(updateIpdPatientBed(values));
			setEditStayModal(false);
			resetForm();
		},
	});

	const formikItem = useFormik({
		initialValues: {
			item: '',
			unit: '',
			rate: '',
			type: '',
			discountAmount: 0,
			discountPercentage: 0,
		},
		validate,
		onSubmit: async (values) => {
			switch (values.type) {
				case 'investigation':
					if (editItem?.id) {
						const dataInv = {
							ipdInvestigateId: editItem?.id,
							unit: values.unit,
							rate: values.rate?.toString?.(),
							discountAmount: values.discountAmount?.toString(),
							discountPercentage: values.discountPercentage?.toString(),
						};
						dispatch(updateBillInvestigation(dataInv, ipdId));
					} else {
						const dataInv = {
							ipdId,
							investigateId: values.item?.split(';')?.[0],
							userInvestigateList: [
								{
									unit: values.unit,
									rate: values.rate?.toString?.(),
									discountAmount: values.discountAmount?.toString(),
									discountPercentage: values.discountPercentage?.toString(),
								},
							],
						};
						dispatch(addBillInvestigation(dataInv));
					}
					updateBill({
						inv:
							values.unit * values.rate -
							values.discountAmount -
							(editItem?.rate || editItem?.Investigation?.perUnitPrice)
								? parseFloat(editItem.unit || 1) *
										parseFloat(
											editItem.rate || editItem.Investigation?.perUnitPrice,
										) -
								  editItem.discountAmount
								: 0,
					});
					break;
				case 'procedure':
					if (editItem?.id) {
						const dataPro = {
							// ipdId,
							// treatmentId: [values.item?.split(';')?.[0]],
							procedureId: editItem?.id,
							unit: values.unit,
							rate: values.rate?.toString?.(),
							discountAmount: values.discountAmount?.toString(),
							discountPercentage: values.discountPercentage?.toString(),
						};
						dispatch(updateBillProcedure(dataPro, ipdId));
					} else {
						const dataPro = {
							ipdId,
							treatmentId: [values.item?.split(';')?.[0]],
							unit: values.unit,
							rate: values.rate?.toString?.(),
							discountAmount: values.discountAmount?.toString(),
							discountPercentage: values.discountPercentage?.toString(),
						};
						dispatch(addBillProcedure(dataPro));
					}
					updateBill({
						proc:
							values.unit * values.rate -
							values.discountAmount -
							(editItem?.rate
								? parseFloat(editItem?.unit || 1) * parseFloat(editItem?.rate) -
								  editItem?.discountAmount
								: 0),
					});
					break;
				case 'nursing':
					if (editItem?.id) {
						const dataNurse = {
							ipdNursingsId: editItem?.id,
							ipdId,
							nursingsId: values.item?.split(';')?.[0],
							unit: values.unit,
							rate: values.rate?.toString?.(),
							discountAmount: values.discountAmount?.toString(),
							discountPercentage: values.discountPercentage?.toString(),
						};
						dispatch(updateBillNursingItem(dataNurse));
					} else {
						const dataNurse = {
							ipdId,
							nursingsId: values.item?.split(';')?.[0],
							unit: values.unit,
							rate: values.rate?.toString?.(),
							discountAmount: values.discountAmount?.toString(),
							discountPercentage: values.discountPercentage?.toString(),
						};
						dispatch(addBillNursingItem(dataNurse));
					}
					updateBill({
						nurse:
							values.unit * values.rate -
							values.discountAmount -
							(editItem?.rate
								? editItem?.rate * (i.unit || 1) - i.discountAmount
								: 0),
					});
					break;
				case 'other':
					const dataOther = {
						ipdId,
						otherId: values.item?.split(';')?.[0],
						unit: values.unit,
						rate: values.rate.toString?.(),
						discountAmount: values.discountAmount?.toString(),
						discountPercentage: values.discountPercentage?.toString(),
					};
					dispatch(addBillOther(dataOther));
					updateBill({
						other: values.unit * values.rate - values.discountAmount,
					});
					break;
				default:
					break;
			}

			formikItem.resetForm();
			setAddItemModal(false);
			setEditItem(null);
		},
	});

	const handleSuggestion = (data, amount = 0) => {
		dispatch(confirmPatientStore(data, ipdFile?.patientId));
		updateBill({ store: amount });
	};

	const updateBill = ({ inv = 0, proc = 0, other = 0, visit = 0, nurse = 0, store = 0 }) => {
		const stayCharge =
			patientBedList
				?.map((i) => {
					const stayDays = i.endDate
						? Math.abs(moment(i.endDate).diff(moment(i.date), 'days')) + 1
						: Math.abs(moment().diff(moment(i.date), 'days')) + 1;
					const curRoomCharge =
						parseFloat(i?.Accommodation_detail?.Accommodation?.charges) || 0;
					return curRoomCharge * stayDays;
				})
				.reduce((a, b) => a + b, 0) || 0;

		const totalAmount = (function () {
			const invTotal =
				billDetails?.Ipd_investigations?.map(
					(i) =>
						parseFloat(i.rate || i.Investigation?.perUnitPrice || '0') *
							parseFloat(i.unit || 1) -
						(i.discountAmount || 0),
				).reduce((i, j) => i + j, 0) + inv;

			const proTotal =
				billDetails?.Ipd_procedures?.map((i) =>
					i.rate
						? parseFloat(i.rate) * parseFloat(i.unit || 1) - (i.discountAmount || 0)
						: 0,
				).reduce((i, j) => i + j, 0) + proc;

			const otherTotal =
				billDetails?.Ipd_others_bills?.map((i) =>
					i.rate
						? parseFloat(i.rate) * parseFloat(i.unit || 0) - (i.discountAmount || 0)
						: 0,
				).reduce((i, j) => i + j, 0) + other;

			const visitTotal =
				billDetails?.Ipd_visiting_doctors?.map((i) => parseInt(i.fee || 0)).reduce(
					(i, j) => i + j,
					0,
				) + visit;

			const nursingTotal =
				billDetails?.Ipd_nursing_bills?.map(
					(i) => parseInt(i.rate * i.unit) - (i.discountAmount || 0),
				).reduce((i, j) => i + j, 0) + nurse;

			const storeTotal =
				patientStoreAssign
					?.filter((it) => it.isPayConfirm)
					?.map((i) => parseFloat(i.totalAmount || 0))
					.reduce((i, j) => i + j, 0) + store;

			return (
				invTotal +
				proTotal +
				otherTotal +
				stayCharge +
				storeTotal +
				visitTotal +
				nursingTotal +
				(ipdFile?.admissionFee || 0)
			);
		})();
		const finalAmount = (function () {
			return (
				totalAmount -
				totalAmount *
					0.01 *
					(ipdFile?.InsuranceCompany?.Tpa_discount?.discountPercentage || 0) -
				(billDetails?.Ipd_billing?.discountAmount || 0)
			);
		})();
		const balanceAmount = finalAmount - paymentsTotal;
		dispatch(
			addIpdBill({
				ipdId,
				patientId: ipdFile?.patientId,
				totalAmount: totalAmount,
				finalAmount,
				balanceAmount,
				stayCharge,
				remark,
				discountAmount: billDetails?.Ipd_billing?.discountAmount || 0,
				discountPercentage: billDetails?.Ipd_billing?.discountPercentage || 0,
			}),
		);
	};

	const calculateDiscAmt = (rate, unit, discountPercentage) => {
		if (rate && unit && discountPercentage) {
			const tempTotal = rate * unit;
			const discountAmount = discountPercentage * 0.01 * tempTotal;
			const total = tempTotal - discountAmount;

			formikItem.setValues((prev) => ({
				...prev,
				discountAmount: parseFloat(discountAmount?.toFixed(2)),
				total,
			}));
		} else if (rate && unit) {
			formikItem.setValues((prev) => ({
				...prev,
				discountAmount: 0,
				total: rate * unit,
			}));
		} else {
			formikItem.setValues((prev) => ({
				...prev,
				discountAmount: 0,
				total: 0,
			}));
		}
	};

	const calculateDiscPercent = (rate, unit, discountAmount) => {
		if (rate && unit && discountAmount) {
			const tempTotal = rate * unit;
			const discountPercentage = (discountAmount * 100) / tempTotal;
			const total = tempTotal - discountAmount;

			formikItem.setValues((prev) => ({
				...prev,
				discountPercentage: parseFloat(discountPercentage?.toFixed(2)),
				total,
			}));
		} else if (rate && unit) {
			formikItem.setValues((prev) => ({
				...prev,
				discountPercentage: 0,
				total: rate * unit,
			}));
		} else {
			formikItem.setValues((prev) => ({
				...prev,
				discountPercentage: 0,
				total: 0,
			}));
		}
	};

	const handleConfirmChangeBillType = async (e) => {
		if (await updateBillType(e.target.value == 1, billDetails?.Ipd_billing?.id)) {
			dispatch(getIpdBillDetails(ipdId));
		}
	};

	const addConsummableChargeToOtherCharges = () => {
		dispatch(addConsummableChargestoOtherChargesBill(ipdId, setConsummableChargeList));
	};
	useEffect(() => {
		const perUnitCost = formikItem.values.rate;
		const unit = formikItem.values.unit;
		if (formikItem.values.discountPercentage) {
			const discountPercentage = formikItem.values.discountPercentage;
			calculateDiscAmt(perUnitCost, unit, discountPercentage);
		}
		if (formikItem.values.discountAmount) {
			const discountAmount = formikItem.values.discountAmount;
			calculateDiscPercent(perUnitCost, unit, discountAmount);
		}
	}, [formikItem.values.rate, formikItem.values.unit]);

	useEffect(() => {
		if (formikItem.values.type === 'investigation') {
			formikItem.setFieldValue('unit', 1);
		} else {
			formikItem.setFieldValue('unit', '');
		}
	}, [formikItem.values.type]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getBillTypes().then((res) => setBillTypes(res));
			dispatch(getTreatments());
			dispatch(getIpdOther());
			dispatch(getNursingList());
			dispatch(getBillPrefix());
			dispatch(getPatientStoreAssignList(ipdFile?.patientId));
			dispatch(getVisitingDoctorSuggestions(ipdId));
			dispatch(getRefunds(ipdId));
		}
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		updateBill({});
	}, [patientBedList]);

	useEffect(() => {
		if (billDetails?.Ipd_consummables?.length > 0)
			setConsummableChargeList(billDetails?.Ipd_consummables);
	}, [billDetails?.Ipd_consummables]);

	return (
		<>
			<Card shadow='none'>
				<div className='d-flex justify-content-between px-4'>
					<CardLabel icon='Payments' className='ms-2' iconColor='primary'>
						<CardTitle>Billing</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							onClick={() =>
								navigate(`/ipd/bill-pdf`, {
									state: {
										billDetails,
										patientStoreAssign,
										ipdFile,
										refunds,
									},
								})
							}
							icon='Print'
							color='primary'
							isOutline>
							Print
						</Button>
						<Button
							onClick={() =>
								isPermitted('Ipd', 'write', () => setAddItemModal(true), 'Billing')
							}
							icon='Add'
							color='primary'
							isOutline>
							Add Item
						</Button>
					</CardActions>
				</div>
				<CardBody>
					{/* summary */}
					<table className='table table-bordered' style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<th>Grand Total</th>
								<td>&#8377; {billDetails?.Ipd_billing?.totalAmount || 0}</td>
							</tr>
							{billDetails?.Ipd_billing?.discountAmount != null && (
								<>
									<tr className='position-relative'>
										<th>Discount Amount</th>
										<td>
											&#8377;{' '}
											{billDetails?.Ipd_billing?.discountAmount || '0'}
											<Tooltips title='Add Discount'>
												<Button
													isLink
													color='primary'
													className='position-absolute top-0 end-0'
													icon='edit'
													onClick={() =>
														isPermitted(
															'ipd',
															'edit',
															() => {
																setDicountModal(true);
																setDiscountAmount(
																	billDetails?.Ipd_billing
																		?.discountAmount,
																);
																setDiscountPercentage(
																	billDetails?.Ipd_billing
																		?.discountPercentage,
																);
																setRemark(
																	billDetails?.Ipd_billing
																		?.remark || '',
																);
															},
															'billing',
														)
													}
												/>
											</Tooltips>
										</td>
									</tr>
									<tr>
										<th>Remark</th>
										<td>{billDetails?.Ipd_billing?.remark || '-'}</td>
									</tr>
								</>
							)}
							{ipdFile?.InsuranceCompany?.Tpa_discount?.discountPercentage && (
								<tr>
									<th className='w-25'>TPA Discount</th>
									<td>
										{
											ipdFile?.InsuranceCompany?.Tpa_discount
												?.discountPercentage
										}
										%
									</td>
								</tr>
							)}
							<tr>
								<th className='w-25'>Net Amount</th>
								<td>&#8377; {billDetails?.Ipd_billing?.finalAmount || 0}</td>
							</tr>
							<tr>
								<th>Payments Total</th>
								<td>&#8377; {paymentsTotal}</td>
							</tr>
							<tr>
								<th>Balance Due</th>
								<td>&#8377; {billDetails?.Ipd_billing?.balanceAmount || 0}</td>
							</tr>
							<tr>
								<th>Refund Amount</th>
								<td>&#8377; {refundAmount}</td>
							</tr>
							{hideBill && (
								<tr>
									<th>Billing Type</th>
									<td className='p-0'>
										<Select
											disabled={billDetails?.Ipd_billing?.isEst}
											className='rounded-0 shadow-none'
											style={{ border: '1px solid #cecece' }}
											list={Object.entries(billTypes).map(([key, value]) => ({
												text: key,
												value: value,
											}))}
											value={billDetails?.Ipd_billing?.isEst ? 1 : 0}
											onChange={(e) => {
												handleConfirmChangeBillType(e);
											}}
										/>
									</td>
								</tr>
							)}
						</tbody>
					</table>

					{/* admission fee */}
					<table className='table table-bordered my-2' style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<td className='fw-bold'>Admission Fee</td>
								<td>&#8377; {ipdFile?.admissionFee || 0}</td>
							</tr>
						</tbody>
					</table>

					{/* Stay Charges */}
					<h6 className='fw-bold my-2'>Stay Charges</h6>
					<table className='table table-bordered' style={{ tableLayout: 'fixed' }}>
						<tbody>
							<tr>
								<th>S.No.</th>
								<th>Date</th>
								<th>Ward</th>
								<th>Room</th>
								<th>Bed No</th>
								<th>Days Spent</th>
								<th>Total</th>
								<th />
							</tr>
							{patientBedList?.map((i, idx) => {
								const stayDays = i.endDate
									? Math.abs(moment(i.endDate).diff(moment(i.date), 'days')) + 1
									: Math.abs(moment().diff(moment(i.date), 'days')) + 1;
								const curRoomCharge =
									parseFloat(i?.Accommodation_detail?.Accommodation?.charges) ||
									0;
								const curStayCharges = curRoomCharge * stayDays;

								return (
									<tr>
										<td>{idx + 1}</td>
										<td>{moment(i?.date).format('DD-MM-YYYY') || '-'}</td>
										<td>
											{i?.Accommodation_detail?.Accommodation?.Hospital_ward
												?.name || '-'}
										</td>
										<td>
											{i?.Accommodation_detail?.Accommodation?.name || '-'}
										</td>
										<td>{i?.Accommodation_detail?.bedNo || '-'}</td>
										<td>{stayDays}</td>
										<td> &#8377; {curStayCharges}</td>
										<td>
											<Tooltips title={'Edit'}>
												<Button
													icon='edit'
													color='primary'
													isLink
													onClick={() => {
														formikStay.setValues({
															ipdId,
															id: i.id,
															startDate: moment(i?.date).format(
																'YYYY-MM-DD',
															),
															endDate: i.endDate
																? moment(i.endDate).format(
																		'YYYY-MM-DD',
																  )
																: undefined,
														});
														setEditStayModal(true);
													}}
												/>
											</Tooltips>
											{idx !== 0 && (
												<Tooltips title={'Delete'}>
													<Button
														icon='delete'
														color='danger'
														isLink
														onClick={() => {
															dispatch(
																deleteIpdPatientBed(ipdId, i.id),
															);
														}}
													/>
												</Tooltips>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>

					{/* Investigations */}
					{billDetails?.Ipd_investigations?.length > 0 && (
						<>
							<div className='my-2'>
								<h6 className='fw-bold'>Investigations Charges</h6>
							</div>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Item</th>
										<th>Date of Investigation</th>
										<th>Unit</th>
										<th>Rate</th>
										<th>Discount</th>
										<th>Total</th>
										<th>Actions</th>
									</tr>
									{billDetails?.Ipd_investigations?.map((i, idx) => (
										<tr>
											<td>{idx + 1}</td>
											<td>{i.Investigation?.name || '-'}</td>
											<td>
												{moment(i?.investigationDate).format(
													'DD-MM-YYYY',
												) || '-'}
											</td>
											<td>{i.unit || '1'}</td>
											<td>
												{i.rate || i.Investigation?.perUnitPrice || '-'}
											</td>
											<td>&#8377; {i.discountAmount || '0'}</td>
											<td>
												&#8377;{' '}
												{i.rate || i.Investigation?.perUnitPrice
													? parseFloat(i.unit || 1) *
															parseFloat(
																i.rate ||
																	i.Investigation?.perUnitPrice,
															) -
													  i.discountAmount
													: 0}
											</td>
											<td>
												<Tooltips title='Edit'>
													<Button
														isLink
														color='primary'
														icon='Edit'
														onClick={() =>
															isPermitted(
																'Ipd',
																'edit',
																() => {
																	setAddItemModal(true);
																	setEditItem(i);
																	setItem({
																		value: `${i.id};${
																			i?.rate ||
																			i?.Investigation
																				?.perUnitPrice ||
																			0
																		}`,
																		label: i?.Investigation
																			?.name,
																	});
																	formikItem.setValues(
																		(prev) => ({
																			...prev,
																			type: 'investigation',
																			item: `${
																				i?.investigationId
																			};${
																				i?.rate ||
																				i?.Investigation
																					?.perUnitPrice ||
																				0
																			}`,
																			unit: i?.unit || '1',
																			rate:
																				i?.rate ||
																				i?.Investigation
																					?.perUnitPrice ||
																				0,
																			discountAmount:
																				i?.discountAmount,
																		}),
																	);
																},
																'Billing',
															)
														}
													/>
												</Tooltips>{' '}
												<Tooltips title='Print'>
													<Button
														isLink
														color='primary'
														icon='print'
														onClick={() => {
															navigate(`/ipd/bill-pdf`, {
																state: {
																	billDetails: {
																		...billDetails,
																		Ipd_investigations: [i],
																		Ipd_procedures: [],
																		Ipd_others_bills: [],
																		Ipd_nursing_bills: [],
																		Ipd_visiting_doctors: [],
																	},
																	patientStoreAssign: null,
																	ipdFile,
																	isSolo: true,
																},
															});
														}}
													/>
												</Tooltips>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</>
					)}
					{/* Procedures */}
					{billDetails?.Ipd_procedures?.length > 0 && (
						<>
							<h6 className='fw-bold my-2'>Procedures Charges</h6>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Item</th>
										<th>Unit</th>
										<th>Rate</th>
										<th>Discount</th>
										<th>Total</th>
										<th>Actions</th>
									</tr>
									{billDetails?.Ipd_procedures?.map((i, idx) => (
										<tr>
											<td>{idx + 1}</td>
											<td>{i.Treatment?.name || '-'}</td>
											<td>{i.unit || '-'}</td>
											<td>{i.rate || '-'}</td>
											<td>&#8377; {i.discountAmount || '0'}</td>
											<td>
												&#8377;{' '}
												{i.rate
													? parseFloat(i.unit || 1) * parseFloat(i.rate) -
													  i.discountAmount
													: 0}
											</td>
											<td>
												<Tooltips title='Edit'>
													<Button
														isLink
														color='primary'
														icon='Edit'
														onClick={() =>
															isPermitted(
																'Ipd',
																'edit',
																() => {
																	setAddItemModal(true);
																	setEditItem(i);
																	setItem({
																		value: `${i.id};${
																			i?.rate ||
																			i?.Treatment
																				?.perUnitPrice ||
																			0
																		}`,
																		label: i?.Treatment?.name,
																	});
																	formikItem.setValues(
																		(prev) => ({
																			...prev,
																			type: 'procedure',
																			item: `${
																				i?.ipdProcedureId
																			};${
																				i?.rate ||
																				i?.Treatment
																					?.perUnitPrice ||
																				0
																			}`,
																			unit: i?.unit || '1',
																			rate:
																				i?.rate ||
																				i?.Treatment
																					?.perUnitPrice ||
																				0,
																			discountAmount:
																				i?.discountAmount ||
																				0,
																		}),
																	);
																},
																'Billing',
															)
														}
													/>
												</Tooltips>{' '}
												<Tooltips title='Print'>
													<Button
														isLink
														color='primary'
														icon='print'
														onClick={() => {
															navigate(`/ipd/bill-pdf`, {
																state: {
																	billDetails: {
																		...billDetails,
																		Ipd_investigations: [],
																		Ipd_procedures: [i],
																		Ipd_others_bills: [],
																		Ipd_nursing_bills: [],
																		Ipd_visiting_doctors: [],
																	},
																	patientStoreAssign: null,
																	ipdFile,
																	isSolo: true,
																},
															});
														}}
													/>
												</Tooltips>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</>
					)}
					{/* Visiting DOctor */}
					{billDetails?.Ipd_visiting_doctors?.length > 0 && (
						<>
							<h6 className='fw-bold my-2'>Doctor Visit Charges</h6>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Date</th>
										<th>Doctor</th>
										<th>Total</th>
										{/* <th>Actions</th> */}
									</tr>
									{billDetails?.Ipd_visiting_doctors?.map((i, idx) => (
										<tr>
											<td>{idx + 1}</td>
											<td>{moment(i?.date).format('DD-MM-YYYY')}</td>
											<td>
												{i.Vesiting_doctor?.name
													? `Dr. ${i?.Vesiting_doctor?.name}`
													: `Dr.${i?.Vendor_detail?.name || ''}`}
											</td>
											<td>&#8377; {i.fee || '-'}</td>
											{/* <td></td> */}
										</tr>
									))}
								</tbody>
							</table>
						</>
					)}
					{/* Nursing charges */}
					{billDetails?.Ipd_nursing_bills?.length > 0 && (
						<>
							<h6 className='fw-bold my-2'>Nursing Charges</h6>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Name</th>
										<th>Unit</th>
										<th>Rate</th>
										<th>Discount </th>
										<th>Total</th>
										<th>Actions</th>
									</tr>
									{billDetails?.Ipd_nursing_bills?.map((i, idx) => (
										<tr>
											<td>{idx + 1}</td>
											<td>{i?.Ipd_nursing?.name || '-'}</td>
											<td>{i?.unit || '1'}</td>
											<td>&#8377; {i.rate || '-'}</td>
											<td>&#8377; {i.discountAmount || '0'}</td>
											<td>
												&#8377; {i.rate * (i.unit || 1) - i.discountAmount}
											</td>
											<td>
												<Tooltips title='Edit'>
													<Button
														isLink
														color='primary'
														icon='Edit'
														onClick={() =>
															isPermitted(
																'Ipd',
																'edit',
																() => {
																	setAddItemModal(true);
																	setEditItem(i);
																	setItem({
																		value: `${i.id};${
																			i?.rate ||
																			i?.Ipd_nursing
																				?.perUnitPrice ||
																			0
																		}`,
																		label: i?.Ipd_nursing?.name,
																	});
																	formikItem.setValues(
																		(prev) => ({
																			...prev,
																			type: 'nursing',
																			item: `${
																				i?.nursingsId
																			};${
																				i?.rate ||
																				i?.Ipd_nursing
																					?.perUnitPrice ||
																				0
																			}`,
																			unit: i?.unit || '1',
																			rate:
																				i?.rate ||
																				i?.Ipd_nursing
																					?.perUnitPrice ||
																				0,
																			discountAmount:
																				i?.discountAmount ||
																				0,
																		}),
																	);
																},
																'Billing',
															)
														}
													/>
												</Tooltips>{' '}
												<Tooltips title='Print'>
													<Button
														isLink
														color='primary'
														icon='print'
														onClick={() => {
															navigate(`/ipd/bill-pdf`, {
																state: {
																	billDetails: {
																		...billDetails,
																		Ipd_investigations: [],
																		Ipd_procedures: [],
																		Ipd_others_bills: [],
																		Ipd_nursing_bills: [i],
																		Ipd_visiting_doctors: [],
																	},
																	patientStoreAssign: null,
																	ipdFile,
																	isSolo: true,
																},
															});
														}}
													/>
												</Tooltips>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</>
					)}
					{/* Others */}
					{billDetails?.Ipd_others_bills?.length > 0 && (
						<>
							<h6 className='fw-bold my-2'>Others</h6>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Item</th>
										<th>Unit</th>
										<th>Rate</th>
										<th>Discount</th>
										<th>Total</th>
										<th>Actions</th>
									</tr>
									{billDetails?.Ipd_others_bills?.map((i, idx) => (
										<tr>
											<td>{idx + 1}</td>
											<td>{i.Ipd_other?.name || '-'}</td>
											<td>{i.unit || '-'}</td>
											<td>{i.rate || '-'}</td>
											<td>&#8377; {i.discountAmount || '-'}</td>
											<td>
												&#8377;{' '}
												{i.unit && i.rate
													? parseFloat(i.unit) * parseFloat(i.rate) -
													  i.discountAmount
													: 0}
											</td>
											<td>
												<Tooltips title='Print'>
													<Button
														isLink
														color='primary'
														icon='print'
														onClick={() => {
															navigate(`/ipd/bill-pdf`, {
																state: {
																	billDetails: {
																		...billDetails,
																		Ipd_investigations: [],
																		Ipd_procedures: [],
																		Ipd_others_bills: [i],
																		Ipd_nursing_bills: [],
																		Ipd_visiting_doctors: [],
																	},
																	patientStoreAssign: null,
																	ipdFile,
																	isSolo: true,
																},
															});
														}}
													/>
												</Tooltips>{' '}
												<Tooltips title='Delete Other'>
													<Button
														isLink
														color='danger'
														icon='Delete'
														onClick={() =>
															isPermitted(
																'Ipd',
																'delete',
																() => {
																	setDeleteItem({
																		type: 'other',
																		amount: i.rate
																			? parseFloat(
																					i.unit || 1,
																			  ) *
																					parseFloat(
																						i.rate,
																					) -
																			  i.discountAmount
																			: 0,
																		id: i.id,
																	});
																	setDeleteWarn(true);
																},
																'Billing',
															)
														}
													/>
												</Tooltips>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</>
					)}
					{/* consummable charges */}
					{consummableChargeList?.length > 0 && (
						<>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginBottom: '10px',
								}}>
								<h6 className='fw-bold mb-0'>Consummable Charges</h6>
								<Button
									onClick={addConsummableChargeToOtherCharges}
									color='primary'>
									Add Charges in Bill
								</Button>
							</div>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Name</th>
										<th>Quantity</th>
										<th>Rate</th>
										<th>Total</th>
									</tr>
									{consummableChargeList?.map((i, idx) => (
										<tr key={idx}>
											<td>{idx + 1}</td>
											<td>{i?.Ipd_other?.name || '-'}</td>
											<td>{i?.quantity || '-'}</td>
											<td>{i?.Ipd_other?.perUnitPrice || '-'}</td>
											<td>
												&#8377;{' '}
												{i?.Ipd_other?.perUnitPrice && i?.quantity
													? parseFloat(i?.Ipd_other?.perUnitPrice) *
													  parseFloat(i?.quantity)
													: 0}
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</>
					)}

					{/* store charges */}
					{patientStoreAssign?.filter((it) => it.isPayConfirm)?.length > 0 && (
						<>
							<h6 className='fw-bold my-2'>Store Charges</h6>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Item</th>
										<th>Unit</th>
										<th>Rate</th>
										<th>Total</th>
										{/* <th>Remark</th> */}
										<th>Actions</th>
									</tr>
									{patientStoreAssign
										?.filter((it) => it.isPayConfirm)
										?.map((i, idx) => (
											<tr>
												<td>{idx + 1}</td>
												<td>{i?.Store_item?.name || '-'}</td>
												<td>{i.quantity || '-'}</td>
												<td>{i.mrp || '-'}</td>
												<td>{i.totalAmount || '-'}</td>
												{/* <td>{i.remark || '-'}</td> */}
												<td>
													<Tooltips title='Remove'>
														<Button
															isOutline={!false}
															color='primary'
															isLight={true}
															className={classNames('text-nowrap', {
																'border-light': !false,
															})}
															icon='arrow-counterclockwise'
															onClick={() => {
																handleSuggestion(
																	{
																		assignStoreId: i.id,
																		isConfirmed: false,
																	},
																	i?.totalAmount * -1,
																);
															}}></Button>
													</Tooltips>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</>
					)}
					{/* store suggestions */}
					{patientStoreAssign?.filter((it) => !it.isPayConfirm)?.length > 0 && (
						<>
							<h6 className='fw-bold my-2'>Suggestions from Store</h6>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Item</th>
										<th>Unit</th>
										<th>Rate</th>
										<th>Total</th>
										<th>Remark</th>
										<th>Actions</th>
									</tr>
									{patientStoreAssign
										?.filter((it) => !it.isPayConfirm)
										?.map((i, idx) => (
											<tr>
												<td>{idx + 1}</td>
												<td>{i?.Store_item?.name || '-'}</td>
												<td>{i.quantity || '-'}</td>
												<td>{i.mrp || '-'}</td>
												<td>{i.totalAmount || '-'}</td>
												<td>{i.remark || '-'}</td>
												<td>
													<Tooltips title='Confirm'>
														<Button
															isOutline={!false}
															color='primary'
															isLight={true}
															className={classNames('text-nowrap', {
																'border-light': !false,
															})}
															icon='Add'
															onClick={() =>
																isPermitted(
																	'Ipd',
																	'write',
																	() => {
																		handleSuggestion(
																			{
																				assignStoreId: i.id,
																				isConfirmed: true,
																			},
																			i.totalAmount,
																		);
																	},
																	'Billing',
																)
															}></Button>
													</Tooltips>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</>
					)}
					{/* visting suggestions */}
					{visitingDoctorSuggestions?.length > 0 && (
						<>
							<h6 className='fw-bold my-2'>Suggestions for Visiting Doctor</h6>
							<table
								className='table table-bordered'
								style={{ tableLayout: 'fixed' }}>
								<tbody>
									<tr>
										<th>S.No.</th>
										<th>Date</th>
										<th>Doctor</th>
										<th>Total</th>
										<th>Actions</th>
									</tr>
									{visitingDoctorSuggestions?.map((i, idx) => (
										<tr>
											<td>{idx + 1}.</td>
											<td>{moment(i?.date).format('DD-MM-YYYY')}</td>
											<td>
												{i?.Vendor?.Vesiting_doctor
													? `Dr. ${
															i?.Vendor?.Vesiting_doctor?.name || ''
													  }`
													: `Dr. ${i?.Vendor?.Vendor_detail?.name || ''}`}
											</td>
											<td>
												{i.Vendor?.Vesiting_doctor
													? i.Vendor?.Vesiting_doctor?.Visiting_doctor_fees.find(
															(x) => x.wardId == i.wardId,
													  )?.patientAlocateFee || '0'
													: i.Vendor?.Vendor_detail?.ticketingPrice ||
													  '0'}
											</td>
											<td>
												<Tooltips title='Confirm'>
													<Button
														isOutline={!false}
														color='primary'
														isLight={true}
														className={classNames('text-nowrap', {
															'border-light': !false,
														})}
														icon='Add'
														onClick={() =>
															isPermitted(
																'Ipd',
																'write',
																() => {
																	dispatch(
																		addBillVisitingDoctor({
																			ipdId,
																			visitingDoctorId:
																				i?.visitDoctorId,
																			fee: (i.Vendor
																				?.Vesiting_doctor
																				? i.Vendor?.Vesiting_doctor?.Visiting_doctor_fees.find(
																						(x) =>
																							x.wardId ==
																							i.wardId,
																				  )
																						?.patientAlocateFee ||
																				  '0'
																				: i.Vendor
																						?.Vendor_detail
																						?.ticketingPrice ||
																				  '0'
																			)?.toString?.(),
																			visitNoteId: i.id,
																		}),
																	);
																	updateBill({
																		visit: i.Vendor
																			?.Vesiting_doctor
																			? i.Vendor?.Vesiting_doctor?.Visiting_doctor_fees.find(
																					(x) =>
																						x.wardId ==
																						i.wardId,
																			  )
																					?.patientAlocateFee ||
																			  '0'
																			: i.Vendor
																					?.Vendor_detail
																					?.ticketingPrice ||
																			  '0',
																	});
																},
																'Billing',
															)
														}></Button>
												</Tooltips>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</>
					)}
				</CardBody>
			</Card>

			{/* Add Modal */}
			<Modal isOpen={addItemModal} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setAddItemModal}>
					<ModalTitle id='deletePharmLabel'>Add Item</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='type' className='mb-2'>
						<Select
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Select Type...'
							onChange={formikItem.handleChange}
							onBlur={formikItem.handleBlur}
							isValid={formikItem.isValid}
							isTouched={formikItem.touched.type}
							invalidFeedback={formikItem.errors.type}
							value={formikItem.values.type}
							list={[
								{ value: 'investigation', text: 'Investigation' },
								{ value: 'procedure', text: 'Procedure' },
								{ value: 'nursing', text: 'Nursing' },
								{ value: 'other', text: 'Other' },
							]}
						/>
					</FormGroup>
					<FormGroup id='item' label='Item' className='mb-2'>
						<ReactSelect
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
								}),
							}}
							value={item}
							onChange={(e) => {
								const rate = e.value?.split?.(';')?.[1];
								const itemId = e.value?.split?.(';')?.[0];
								formikItem.setFieldValue(
									'rate',
									parseFloat(rate) ? parseFloat(rate) : 0,
								);
								formikItem.setFieldValue('item', itemId);
								setItem(e);
							}}
							options={
								formikItem.values.type === 'investigation'
									? [...investigations, ...moreInvestigations]?.map((i) => ({
											value: `${i.id};${i.perUnitPrice}`,
											label: i.name,
									  }))
									: formikItem.values.type === 'procedure'
									? treatments?.map((i) => ({
											value: `${i.id};${i.perUnitPrice}`,
											label: `${i.name} ${
												i?.Treatment_organisation_tag?.Treatment_tag
													? `(${i?.Treatment_organisation_tag?.Treatment_tag?.name})`
													: ''
											}`,
									  }))
									: formikItem.values.type === 'other'
									? ipdOthers?.map((i) => ({
											value: `${i.id};${i.perUnitPrice}`,
											label: i.name,
									  }))
									: formikItem.values.type === 'nursing'
									? nursingList?.map((i) => ({
											value: `${i.id};${i.perUnitPrice}`,
											label: i?.name,
									  }))
									: []
							}
						/>
					</FormGroup>
					<FormGroup id='unit' label='Unit' isFloating className='mb-2'>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Unit'
							onChange={formikItem.handleChange}
							onBlur={formikItem.handleBlur}
							value={formikItem.values.unit}
							isValid={formikItem.isValid}
							isTouched={formikItem.touched.unit}
							invalidFeedback={formikItem.errors.unit}
							disabled={formikItem.values.type === 'investigation'}
						/>
					</FormGroup>
					<FormGroup id='rate' label='Rate' isFloating className='mb-2'>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Rate'
							onChange={formikItem.handleChange}
							onBlur={formikItem.handleBlur}
							value={formikItem.values.rate}
							isValid={formikItem.isValid}
							isTouched={formikItem.touched.rate}
							invalidFeedback={formikItem.errors.rate}
						/>
					</FormGroup>
					<FormGroup
						id='discountAmount'
						label='Discount Amount'
						isFloating
						className='mb-2'>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Discount Amount'
							onChange={(e) => {
								const perUnitCost = parseFloat(formikItem.values.rate);
								const unit = parseFloat(formikItem.values.unit);
								const discountAmount = parseFloat(e.target.value);
								calculateDiscPercent(perUnitCost, unit, discountAmount);
								formikItem.handleChange(e);
							}}
							onBlur={formikItem.handleBlur}
							value={formikItem.values.discountAmount}
							isValid={formikItem.isValid}
							isTouched={formikItem.touched.discountAmount}
							invalidFeedback={formikItem.errors.discountAmount}
						/>
					</FormGroup>
					<FormGroup
						id='discountPercentage'
						label='Discount Percentage'
						isFloating
						className='mb-2'>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Discount Percentage'
							onChange={(e) => {
								const perUnitCost = parseFloat(formikItem.values.rate);
								const unit = parseFloat(formikItem.values.unit);
								const discountPercentage = parseFloat(e.target.value);
								calculateDiscAmt(perUnitCost, unit, discountPercentage);
								formikItem.handleChange(e);
							}}
							onBlur={formikItem.handleBlur}
							value={formikItem.values.discountPercentage}
							isValid={formikItem.isValid}
							isTouched={formikItem.touched.discountPercentage}
							invalidFeedback={formikItem.errors.discountPercentage}
						/>
					</FormGroup>
					<FormGroup id='total' label='Total' isFloating className='mb-2'>
						<Input
							disabled
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Total'
							onChange={formikItem.handleChange}
							onBlur={formikItem.handleBlur}
							value={formikItem.values.total}
							isValid={formikItem.isValid}
							isTouched={formikItem.touched.total}
							invalidFeedback={formikItem.errors.total}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setEditItem(null);
							setAddItemModal(false);
						}}>
						Cancel
					</Button>
					<Button color='primary' onClick={formikItem.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal isOpen={deleteWarn} setIsOpen={setDeleteWarn} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deletePharmLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setDeleteWarn(false);
							setDeleteItem(null);
						}}>
						Cancel
					</Button>
					<Button
						color='danger'
						icon='delete'
						onClick={() => {
							if (deleteItem?.type === 'payment')
								dispatch(deleteBillPayment(deleteItem.id, ipdId));
							else if (deleteItem?.type === 'other') {
								dispatch(deleteBillOther(deleteItem.id, ipdId));
								updateBill({ other: deleteItem?.amount * -1 });
							}
							setDeleteWarn(false);
						}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>

			{/* discount modal */}
			<Modal isOpen={dicountModal} setIsOpen={setDicountModal} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setDicountModal}>
					<ModalTitle id='deletePharmLabel'>Add Discount</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-12'>
							<FormGroup id='disamt' label='Total Amount' isFloating className='mb-2'>
								<Input
									type='number'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									disabled
									value={billDetails?.Ipd_billing?.totalAmount || 0}
								/>
							</FormGroup>
							<FormGroup
								id='disamt'
								label='Discount Amount'
								isFloating
								className='mb-2'>
								<Input
									type='number'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									onChange={(e) => {
										setDiscountAmount(e.target.value);
										setDiscountPercentage(
											(
												(e.target.value * 100) /
												(billDetails?.Ipd_billing?.totalAmount || 0)
											)?.toFixed(2),
										);
									}}
									value={discountAmount}
								/>
							</FormGroup>
							<FormGroup
								id='disper'
								label='Discount Percentage'
								isFloating
								className='mb-2'>
								<Input
									type='number'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									onChange={(e) => {
										setDiscountPercentage(e.target.value);
										setDiscountAmount(
											e.target.value *
												0.01 *
												(billDetails?.Ipd_billing?.totalAmount || 0),
										);
									}}
									value={discountPercentage}
								/>
							</FormGroup>
							<FormGroup id='disamt' label='Net Amount' isFloating className='mb-2'>
								<Input
									type='number'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									disabled
									value={
										(billDetails?.Ipd_billing?.totalAmount || 0) -
										discountAmount
									}
								/>
							</FormGroup>
							<FormGroup id='remark' label='Remarks' isFloating className='mb-2'>
								<Input
									type='text'
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									onChange={(e) => {
										setRemark(e.target.value);
									}}
									value={remark}
								/>
							</FormGroup>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setDicountModal(false);
						}}>
						Cancel
					</Button>
					<Button
						color='primary'
						icon='save'
						onClick={() => {
							dispatch(
								addIpdBill({
									ipdId,
									patientId: ipdFile?.patientId,
									totalAmount: billDetails?.Ipd_billing?.totalAmount || 0,
									finalAmount:
										(billDetails?.Ipd_billing?.totalAmount || 0) -
										(billDetails?.Ipd_billing?.totalAmount || 0) *
											0.01 *
											(ipdFile?.InsuranceCompany?.Tpa_discount
												?.discountPercentage || 0) -
										discountAmount,

									balanceAmount: renderNumber(
										(billDetails?.Ipd_billing?.totalAmount || 0) -
											(billDetails?.Ipd_billing?.totalAmount || 0) *
												0.01 *
												(ipdFile?.InsuranceCompany?.Tpa_discount
													?.discountPercentage || 0) -
											discountAmount -
											paymentsTotal,
									),
									discountAmount,
									discountPercentage,
									remark,
									stayCharge: billDetails?.Ipd_billing?.stayCharge || 0,
								}),
							);
							setDicountModal(false);
						}}>
						Save
					</Button>
				</ModalFooter>
			</Modal>

			{/* edit stay modal */}
			<Modal
				isOpen={editStayModal}
				setIsOpen={setEditStayModal}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setEditStayModal}>
					<ModalTitle id='deletePharmLabel'>Edit Bed Data</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-12'>
							<FormGroup label={'Start Date'}>
								<Input
									type='date'
									name='startDate'
									className='form-control'
									value={formikStay.values.startDate}
									onChange={formikStay.handleChange}
									style={{
										height: '3.5rem',
									}}
								/>
							</FormGroup>
						</div>
						{formikStay.values.endDate && (
							<div className='col-12'>
								<FormGroup label={'End Date'}>
									<Input
										type='date'
										name='endDate'
										className='form-control'
										value={formikStay.values.endDate}
										onChange={formikStay.handleChange}
										style={{
											height: '3.5rem',
										}}
									/>
								</FormGroup>
							</div>
						)}
					</div>
				</ModalBody>
				<ModalFooter>
					<Button isOutline color='primary' icon='save' onClick={formikStay.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default IpdBillingTab;
