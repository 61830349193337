import {
	SET_PHARMACIES,
	LOADING_PHARMACIES,
	CREATE_PHARMACY,
	UPDATE_PHARMACY,
	DELETE_PHARMACY,
	SET_PHARMACY_TAGS,
	ADD_PHARMACY_TAGS,
	SET_SALES_SUGGESTIONS,
	SET_STOCKISTS_LIST,
	SET_GST_PURCHASEDATA,
	SET_GST_SALESDATA,
	SET_WHOLESALES_LIST,
	DELETE_WHOLESALEVOUCHER,
} from './types';

const initialState = {
	pharmacies: [],
	tags: [],
	salesSuggestions: [],
	stockists: [], // all stockists used to select filter in purchase Voucher
	gstPurchaseData: [],
	gstSalesData: [],
	wholeSalesList: [],
	totalBilled: {},
	total: 0,
	page: 1,
	error: null,
	loading: false,
};

const pharmacyReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PHARMACIES:
			return {
				...state,
				loading: true,
			};

		case SET_STOCKISTS_LIST:
			return {
				...state,
				stockists: action.payload || [],
			};

		case SET_PHARMACIES:
			return {
				...state,
				pharmacies: action.payload || [],
				loading: false,
			};

		case CREATE_PHARMACY:
			return {
				...state,
				pharmacies: [action.payload, ...state.pharmacies],
			};

		case UPDATE_PHARMACY:
			state.pharmacies = state.pharmacies.filter((o) => o.id != action.payload.id);
			return {
				pharmacies: [action.payload, ...state.pharmacies],
				...state,
			};

		case DELETE_PHARMACY:
			state.pharmacies = state.pharmacies.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};

		case SET_PHARMACY_TAGS:
			return {
				...state,
				tags: action.payload || [],
			};

		case ADD_PHARMACY_TAGS:
			return {
				...state,
				tags: [action.payload, ...state.tags],
			};

		case SET_SALES_SUGGESTIONS:
			return {
				...state,
				salesSuggestions: action.payload.vouchers || [],
				total: action.payload.total || [],
				page: action.payload.page || [],
			};

		case SET_GST_PURCHASEDATA:
			return {
				...state,
				gstPurchaseData: action.payload || [],
			};

		case SET_GST_SALESDATA:
			return {
				...state,
				gstSalesData: action.payload || [],
			};

		case SET_WHOLESALES_LIST:
			return {
				...state,
				wholeSalesList: action.payload.data || [],
				totalBilled: action.payload.totalBilled || {},
				total: action.payload.total || 0,
				page: action.payload.page || 1,
			};

		case DELETE_WHOLESALEVOUCHER:
			state.wholeSalesList = state.wholeSalesList.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};
		default:
			return state;
	}
};

export default pharmacyReducer;
