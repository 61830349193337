import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';

import Card, {
	CardActions,
	CardBody,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import {
	addBillPayment,
	deleteBillOther,
	deleteBillPayment,
} from '../../../../actions/ipdPatientActions';
import moment from 'moment';
import { getPaymentTypes, invalidNums } from '../../../../helpers/helpers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { useNavigate } from 'react-router-dom';
import Select from '../../../../components/bootstrap/forms/Select';
import { createIpdPaymentRemark, getIpdPaymentRemarks } from '../../../../actions/ipdActions';
import { getPaymentMethods } from '../../../../actions/saleVoucherActions';
import { getBillTypes, getCardNetworks, isPermitted } from '../../../../actions/helperActions';
import { toggleCreateLoading } from '../../../../actions/componentActions';
import Icon from '../../../../components/icon/Icon';

const validatePayment = (values) => {
	const errors = {};

	if (!values.date) {
		errors.date = 'Required';
	}
	if (!values.amount) {
		errors.amount = 'Required';
	}
	if (!values.paymentMode) {
		errors.paymentMode = 'Required';
	}
	if (values.cardNo && `${values.cardNo}`.length != 16) {
		errors.cardNo = 'Card No should be 16 digits';
	}
	if (values.cardNo && !/^\d+$/.test(values.cardNo)) {
		errors.cardNo = 'Card No should only contain numbers';
	}
	if (values.utrNo && `${values.utrNo}`.length != 12) {
		errors.utrNo = 'Reference no should be 12 digits';
	}
	if (values.utrNo && !/^\d+$/.test(values.utrNo)) {
		errors.utrNo = 'Reference No should only contain numbers';
	}

	return errors;
};

const IpdPaymentsTab = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// card networks
	const [cardNetworks, setCardNetworks] = useState([]);

	const paymentModes = useSelector((state) => state.saleVoucher.paymentMethods);
	const hideBill = useSelector((state) => state.profile.selectedOrg?.Organisation?.isHiddenBill);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);
	const paymentRemarks = useSelector((state) => state.ipd.paymentRemarks);
	const billDetails = useSelector((state) => state.ipdPatient.billDetails);

	const [addPaymentModal, setAddPaymentModal] = useState(false);
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [paymentRemark, setPaymentRemark] = useState(null);
	const [showEst, setShowEst] = useState(0);
	const [billTypes, setBillTypes] = useState({});

	const formikPayment = useFormik({
		initialValues: {
			date: moment().format('YYYY-MM-DD'),
			amount: '',
			paymentMode: 'Cash',
			paymentRemark: '',
			cardNo: '',
			cardNetwork: null,
			utrNo: '',
		},
		validate: validatePayment,
		validateOnChange: true,
		onSubmit: async (values) => {
			const data = {
				ipdId,
				date: values.date,
				amount: values?.amount?.toString(),
				paymentMode: values.paymentMode?.toLocaleLowerCase()?.includes('wallet')
					? 'Wallet'
					: values.paymentMode,
				paymentRemark: paymentRemark?.value || '',
				cardNo: values.cardNo || '',
				cardNetwork: values.cardNetwork || null,
				utrNo: values.utrNo || '',
			};
			data['isEst'] = showEst == 1;
			if (!data['paymentRemark']) delete data['paymentRemark'];
			dispatch(toggleCreateLoading(true));
			dispatch(addBillPayment(data));
			formikPayment.resetForm();
			setAddPaymentModal(false);
		},
	});

	useEffect(() => {
		formikPayment.setFieldValue('cardNo', '');
		formikPayment.setFieldValue('cardNetwork', null);
		formikPayment.setFieldValue('utrNo', '');
	}, [formikPayment.values.paymentMode]);

	useEffect(() => {
		let mounted = true;

		const fetchOthers = async () => {
			if (mounted) {
				setCardNetworks(await getCardNetworks());
				getBillTypes().then((res) => setBillTypes(res));
				dispatch(getIpdPaymentRemarks());
				dispatch(getPaymentMethods());
			}
		};

		fetchOthers();

		return () => {
			formikPayment.resetForm();
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card shadow='none'>
				<div className='d-flex justify-content-between px-4'>
					<CardLabel icon='Payments' className='ms-2' iconColor='primary'>
						<CardTitle>Payments</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							onClick={() =>
								navigate(`/ipd/payments-pdf`, {
									state: {
										billDetails,
										ipdFile,
										isEst: false,
									},
								})
							}
							icon='Print'
							color='primary'
							isOutline>
							Print
						</Button>
						<Button
							onClick={() =>
								isPermitted(
									'Ipd',
									'write',
									() => setAddPaymentModal(true),
									'Billing',
								)
							}
							icon='Add'
							color='primary'
							isOutline>
							Add Payment
						</Button>
					</CardActions>
				</div>
				<CardBody>
					<table className='table table-bordered'>
						<tbody>
							<tr>
								<th>S.No.</th>
								<th>Date</th>
								<th>Payment</th>
								<th>Payment Mode</th>
								<th>Remarks</th>
								<th>Actions</th>
							</tr>
							{billDetails?.Ipd_payments?.filter((x) => !x.isEst)?.map((i, idx) => {
								const mode = i?.paymentMode;
								const network =
									cardNetworks?.find((j) => j.id === i.cardNetwork)?.name || '';
								const paymentInfo =
									mode === 'Credit/Debit Card'
										? `Card No: ${i?.cardNo} ${network && `(${network})`}`
										: mode === 'UPI'
										? `UTR No: ${i?.utrNo}`
										: '';

								return (
									<tr>
										<td>{idx + 1}</td>
										<td>
											{i.date ? moment(i.date)?.format('DD-MM-YYYY') : '-'}
										</td>
										<td>&#8377; {i.amount}</td>
										<td>
											{i.paymentMode || '-'}
											{paymentInfo && (
												<Tooltips title={paymentInfo}>
													<Icon
														icon='Info'
														color='primary'
														size='lg'
														className='ms-2'
													/>
												</Tooltips>
											)}
										</td>
										<td>{i.Ipd_payment_remark?.message || '-'}</td>
										<td>
											<Tooltips title='print'>
												<Button
													isLink
													onClick={() =>
														navigate(`/ipd/payments-pdf`, {
															state: {
																billDetails: {
																	...billDetails,
																	Ipd_payments: [i],
																},
																ipdFile,
																isEst: false,
															},
														})
													}
													icon='Print'
													color='primary'
													isOutline
												/>
											</Tooltips>
											<Tooltips title='Delete Payment'>
												<Button
													isLink
													color='danger'
													icon='Delete'
													onClick={() =>
														isPermitted(
															'Ipd',
															'delete',
															() => {
																setDeleteItem({
																	type: 'payment',
																	id: i.id,
																});
																setDeleteWarn(true);
															},
															'Billing',
														)
													}
												/>
											</Tooltips>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</CardBody>
			</Card>

			{/* Add Modal */}
			<Modal
				isOpen={addPaymentModal}
				// setIsOpen={setAddPaymentModal}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setAddPaymentModal}>
					<ModalTitle id='deletePharmLabel'>Add Payment</ModalTitle>
				</ModalHeader>
				<ModalBody className='d-flex flex-column gap-3'>
					<FormGroup id='date' label='Date' isFloating>
						<Input
							type='date'
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Date'
							onChange={formikPayment.handleChange}
							onBlur={formikPayment.handleBlur}
							value={formikPayment.values.date}
							isValid={formikPayment.isValid}
							isTouched={formikPayment.touched.date}
							invalidFeedback={formikPayment.errors.date}
						/>
					</FormGroup>
					<FormGroup id='amount' label='Amount' isFloating>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Amount'
							onChange={formikPayment.handleChange}
							onBlur={formikPayment.handleBlur}
							value={formikPayment.values.amount}
							isValid={formikPayment.isValid}
							isTouched={formikPayment.touched.amount}
							invalidFeedback={formikPayment.errors.amount}
						/>
					</FormGroup>
					<FormGroup id='paymentMode' label='Payment Mode' isFloating>
						<Select
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Select...'
							onChange={formikPayment.handleChange}
							onBlur={formikPayment.handleBlur}
							isValid={formikPayment.isValid}
							isTouched={formikPayment.touched.paymentMode}
							invalidFeedback={formikPayment.errors.paymentMode}
							value={formikPayment.values.paymentMode}
							list={paymentModes.map((i) => ({ value: i, text: i }))}
						/>
					</FormGroup>
					{formikPayment.values.paymentMode === 'Credit/Debit Card' && (
						<>
							<div className='col-12'>
								<FormGroup id='cardNo' label='Card No' isFloating>
									<Input
										style={{
											height: 'calc(3.5rem + 2px)',
										}}
										onChange={formikPayment.handleChange}
										onBlur={formikPayment.handleBlur}
										value={formikPayment.values.cardNo}
										isValid={formikPayment.isValid}
										isTouched={formikPayment.touched.cardNo}
										invalidFeedback={formikPayment.errors.cardNo}
									/>
								</FormGroup>
							</div>

							<div className='col-12'>
								<FormGroup id='cardNetwork' label='Card Network' isFloating>
									<Select
										style={{
											height: 'calc(3.5rem + 2px)',
										}}
										placeholder='Select...'
										onChange={formikPayment.handleChange}
										onBlur={formikPayment.handleBlur}
										isValid={formikPayment.isValid}
										isTouched={formikPayment.touched.cardNetwork}
										invalidFeedback={formikPayment.errors.cardNetwork}
										value={formikPayment.values.cardNetwork}
										list={cardNetworks.map(({ id, name }) => ({
											value: id,
											text: name,
										}))}
									/>
								</FormGroup>
							</div>
						</>
					)}
					{formikPayment.values.paymentMode === 'UPI' && (
						<div className='col-12'>
							<FormGroup id='utrNo' label='Reference No' isFloating>
								<Input
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									onChange={formikPayment.handleChange}
									onBlur={formikPayment.handleBlur}
									value={formikPayment.values.utrNo}
									isValid={formikPayment.isValid}
									isTouched={formikPayment.touched.utrNo}
									invalidFeedback={formikPayment.errors.utrNo}
								/>
							</FormGroup>
						</div>
					)}
					{hideBill && (
						<FormGroup label='Bill Type' isFloating>
							<Select
								className='form-control'
								style={{ border: '1px solid #cecece' }}
								list={Object.entries(billTypes).map(([key, value]) => ({
									text: key,
									value: value,
								}))}
								value={showEst}
								onChange={(e) => {
									setShowEst(e.target.value);
								}}
							/>
						</FormGroup>
					)}
					<FormGroup
						id='paymentType'
						label='Payment Remark'
						labelClassName='mb-1'
						isFloating>
						<Creatable
							className='form-control'
							styles={{
								container: () => ({
									paddingTop: '1.325rem',
								}),
								control: () => ({
									display: 'flex',
									height: '2rem',
								}),
								valueContainer: (styles) => ({ ...styles, paddingLeft: 0 }),
								placeholder: (styles) => ({
									...styles,
									marginLeft: 0,
									fontWeight: '450',
								}),
								indicatorSeparator: () => null,
								indicatorsContainer: (styles) => ({ ...styles, padding: 0 }),
								input: (styles) => ({ ...styles, marginLeft: 0, fontWeight: 300 }),
								singleValue: (styles) => ({ ...styles, marginLeft: 0 }),
							}}
							placeholder={'Type Here...'}
							value={paymentRemark}
							onChange={(e) => setPaymentRemark(e)}
							onCreateOption={async (e) => {
								const res = await createIpdPaymentRemark({ message: e }, dispatch);
								setPaymentRemark({
									label: res?.message,
									value: res?.id,
								});
							}}
							options={paymentRemarks?.map((x) => ({
								value: x?.id,
								label: x?.message,
							}))}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setAddPaymentModal(false)}>
						Cancel
					</Button>
					<Button color='primary' onClick={formikPayment.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal isOpen={deleteWarn} setIsOpen={setDeleteWarn} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deletePharmLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button
						color='danger'
						icon='delete'
						onClick={() => {
							if (deleteItem?.type === 'payment')
								dispatch(deleteBillPayment(deleteItem.id, ipdId));
							else if (deleteItem?.type === 'other')
								dispatch(deleteBillOther(deleteItem.id, ipdId));
							setDeleteWarn(false);
						}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default IpdPaymentsTab;
