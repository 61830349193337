import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import { useFormik } from 'formik';
import Modal, {
	ModalTitle,
	ModalBody,
	ModalHeader,
	ModalFooter,
} from '../../../../components/bootstrap/Modal';
import { SET_APPOINTMENT_SLOTS } from '../../../../reducers/types';
import moment from 'moment';
import showNotification from '../../../../components/extras/showNotification';
import { isPermitted } from '../../../../actions/helperActions';
import { createNote, getNotes, getOpdStatuses } from '../../../../actions/settingActions';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Creatable from 'react-select/creatable';
import Input from '../../../../components/bootstrap/forms/Input';
import {
	createAppointment,
	createAppointmentRemoteClinic,
	getSlotsByDate,
	getSlotsByVendorAndOrg,
} from '../../../../actions/appointmentActions';
import { getDoctorBySpeciality } from '../../../../actions/specialityActions';
import Icon from '../../../../components/icon/Icon';
import { Action } from '../../../../components/Table';

const validate = (values) => {
	const errors = {};

	if (!values.date) {
		errors.name = 'Required';
	}
	if (!values.date) {
		errors.type = 'Required';
	}
	// if (!values.time) {
	// 	errors.time = 'Required';
	// }
	// if (!values.duration) {
	// 	errors.duration = 'Required';
	// }
	return errors;
};
const DoctorListing = ({ selectedSpeciality, changeModeToFileSharing }) => {
	const { darkModeStatus } = useDarkMode();
	const patient = useSelector((state) => state.patient.patient);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const slots = useSelector((state) => state.appointment.slots);
	const [bookAppointmentModalVisible, setBookAppointmentVisible] = useState(false);
	const [selectedPatient, setSelectedPatient] = useState({
		value: patient,
		label: patient?.Members?.[0]?.name || 'Unnamed',
	});
	const [selectedDoctor, setSelectedDoctor] = useState({});
	const notes = useSelector((state) => state.setting.notes);
	const [selectedNote, setSelectedNote] = useState(null);
	const user = useSelector((state) => state.auth.user);

	const patients = useSelector((state) => state.patient.searchPatientList);
	const [selectedOrg, setSelectedOrg] = useState(null);
	const doctors = useSelector((state) => state.doctor.doctors);
	const userSelectedOrg = useSelector((state) => state.profile.selectedOrg);

	useEffect(() => {
		setSelectedPatient({
			value: patient,
			label: patient?.Members?.[0]?.name || 'Unnamed',
		});
	}, [patient]);
	const formik = useFormik({
		initialValues: {
			userId: patient?.id,
			vendorId: null,
			time: '',
			date: moment().format('YYYY-MM-DD').toString(),
			duration: '',
			notes: '',
		},
		validate,
		onSubmit: (values, { resetForm }) => {
			// if (!formik.values.userId) {
			// 	showNotification('Error', 'Please Select Patient', 'danger');
			// 	return;
			// }
			if (!formik.values.organisationId) {
				showNotification('Error', 'Please Select Doctor Organisation', 'danger');
				return;
			}

			const data = {};
			for (var key in values) {
				data[key] = `${values[key]}`;
			}
			data['time'] = data['time']?.slice(0, 5) || '';
			data['duration'] =
				moment(data['time'], 'HH:mm').add(data['duration'], 'minutes').format('HH:mm') ||
				'';

			data['appointmentType'] = 'teleConsultation';
			data['vendorId'] = parseInt(selectedDoctor?.value?.id);
			data['userId'] = parseInt(data['userId']);
			data['organisationId'] = data['organisationId'];
			data['bookedBy'] = user?.Vendor_detail?.vendorId;
			data['bookedByOrganisation'] = userSelectedOrg?.Organisation?.id;
			dispatch(
				createAppointmentRemoteClinic(data, true, () => {
					resetForm();
					setBookAppointmentVisible(false);
					dispatch({
						type: SET_APPOINTMENT_SLOTS,
						payload: [],
					});
				}),
			);

			// setEditMode(false);
		},
	});

	// useEffect(() => {
	// 	setSelectedDoctor({
	// 		value: user,
	// 		label: user?.Vendor_detail?.name || 'Unnamed',
	// 	});
	// }, [user]);
	const handleUpcomingEdit = (item) => {
		formik.resetForm();
		setSelectedNote(null);
		setSelectedOrg(null);
		setSelectedDoctor({
			value: item.Vendor,
			label: item.Vendor?.Vendor_detail?.name || 'Unnamed',
		});
		setBookAppointmentVisible(true);
	};

	useEffect(() => {
		if (selectedSpeciality?.id) {
			dispatch(getDoctorBySpeciality(selectedSpeciality?.id));
		}
	}, [selectedSpeciality]);

	const specialityDoctors =
		useSelector((state) => state.speciality.specialityDoctors?.[selectedSpeciality?.id]) || [];

	useEffect(() => {
		if (
			selectedDoctor?.value &&
			selectedDoctor?.value?.inClinicType !== 'ticketingSystem' &&
			formik.values.date &&
			selectedOrg?.value?.Organisation?.id
		) {
			dispatch(
				getSlotsByVendorAndOrg({
					vendorId: selectedDoctor?.value?.id,
					organisationId: selectedOrg?.value?.Organisation?.id,
					date: formik.values.date,
				}),
			);
		}
	}, [formik.values.date, selectedDoctor, selectedOrg]);
	return (
		<>
			<table className='table table-modern'>
				<thead>
					<tr>
						<th>S.No.</th>
						<th>Speciality</th>
						<th>Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{specialityDoctors?.map((item, index) => (
						<tr>
							<td>{index + 1}</td>
							<td>{item?.Vendor?.Vendor_detail?.name}</td>
							<td>
								<Icon
									icon='circle-fill'
									size={'sm'}
									style={{ marginRight: '5px' }}
									color={item?.Vendor?.isOnline ? 'success' : ''}
								/>
								{item?.Vendor?.isOnline ? 'Online' : 'Offline'}
							</td>
							<td>
								{item?.Vendor?.isOnline && (
									<>
										<Action
											title='Call'
											icon='Telephone'
											onClickFunction={() => {
												window.open(
													`/consultation/${patient?.id}/${item?.vendorId}`,
													'_blank',
												);
											}}
										/>
										&nbsp;
									</>
								)}
								<Action
									title='Book Appointment'
									icon='calendar'
									onClickFunction={() => {
										isPermitted(
											'Appointment',
											'write',
											handleUpcomingEdit(item),
										);
									}}
								/>
								&nbsp;&nbsp;
								<Action
									title='Share Files'
									icon='share'
									onClickFunction={() => changeModeToFileSharing(item)}
								/>
								&nbsp;&nbsp;
							</td>
						</tr>
					))}
				</tbody>
			</table>
			<Modal
				isOpen={bookAppointmentModalVisible}
				setIsOpen={setBookAppointmentVisible}
				titleId='appointmentModalTitle'
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setBookAppointmentVisible}>
					<ModalTitle id='appointmentModalTitle'>{'Add New'}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup label='Patient'>
								<ReactSelect
									isDisabled
									id='userId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={selectedPatient}
									options={patients?.map?.((p) => ({
										value: p,
										label: p.Members?.[0]?.name,
									}))}
									onChange={(p) => {
										formik.values.userId = p.value?.id;
										setSelectedPatient(p);
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup label='Doctor'>
								<ReactSelect
									isDisabled={true}
									id='vendorId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={selectedDoctor}
									options={doctors?.map((p) => ({
										value: p,
										label: p.Vendor_detail?.name,
									}))}
									onChange={(p) => {
										formik.values.vendorId = p?.value?.id;
										setSelectedDoctor(p);
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup label='Doctor Organisation'>
								<ReactSelect
									id='organisationId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={selectedOrg}
									options={selectedDoctor?.value?.Vendor_organisations?.map(
										(p) => ({
											value: p,
											label: p?.Organisation?.name || 'Unnamed',
										}),
									)}
									onChange={(p) => {
										formik.values.organisationId = p?.value?.Organisation.id;
										setSelectedOrg(p);
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='date' label='Appointment Date' isFloating>
								<Input
									type='date'
									autoComplete='off'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.date}
									isValid={formik.isValid}
									isTouched={formik.touched.date}
									invalidFeedback={formik.errors.date}
								/>
							</FormGroup>
						</div>
						{selectedDoctor &&
						selectedDoctor?.value?.inClinicType !== 'ticketingSystem' ? (
							slots?.length > 0 ? (
								<div className='col-12'>
									{slots?.map((slot, idx) => (
										<Button
											color='primary'
											className='m-1'
											isOutline={
												formik.values.time != slot?.startTime?.slice(0, -3)
											}
											onClick={() =>
												formik.setFieldValue(
													`time`,
													slot?.startTime?.slice(0, -3),
												)
											}
											key={idx}>
											{slot?.startTime?.slice(0, -3)}
										</Button>
									))}
								</div>
							) : (
								<em className='text-muted text-center'>No Slots Avaiable</em>
							)
						) : (
							<>
								<div className='col-12'>
									<FormGroup id='time' label='Appointment Time' isFloating>
										<Input
											type='time'
											autoComplete='off'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.time}
											isValid={formik.isValid}
											isTouched={formik.touched.time}
											invalidFeedback={formik.errors.time}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='duration' label='Duration (in mins)' isFloating>
										<Input
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											autoComplete='duration'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.duration}
											isValid={formik.isValid}
											isTouched={formik.touched.duration}
											invalidFeedback={formik.errors.duration}
										/>
									</FormGroup>
								</div>
							</>
						)}
						{/* <div className='col-12'>
							<FormGroup id='time' label='Appointment Time' isFloating>
								<Input
									type='time'
									autoComplete='off'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.time}
									isValid={formik.isValid}
									isTouched={formik.touched.time}
									invalidFeedback={formik.errors.time}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='duration' label='Duration (in mins)' isFloating>
								<Input
									type='number'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									autoComplete='duration'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.duration}
									isValid={formik.isValid}
									isTouched={formik.touched.duration}
									invalidFeedback={formik.errors.duration}
								/>
							</FormGroup>
						</div> */}
						<div className='col-12'>
							<FormGroup label='Notes'>
								<Creatable
									id='notes'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
											height: '2.6rem',
											alignItems: 'center',
										}),
									}}
									placeholder='Write here...'
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}}
									value={selectedNote}
									options={notes
										.filter((i) => i.type === 'Appointment')
										.map((i) => ({
											value: i,
											label: i.name,
										}))}
									onCreateOption={(i) => {
										dispatch(
											createNote({
												name: i,
												type: 'Appointment',
												text: i,
											}),
										);
										formik.values.notes = i;
										setSelectedNote({ value: i, label: i });
									}}
									onChange={(i) => {
										formik.values.notes = i.value.text;
										setSelectedNote({ value: i, label: i.value.text });
									}}
								/>
							</FormGroup>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='bg-transparent'>
					<Button
						icon='Add'
						color='primary'
						isOutline
						isDisable={!formik.isValid && !!formik.submitCount}
						onClick={() => isPermitted('Appointment', 'write', formik.handleSubmit)}>
						Add Appointment
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default DoctorListing;
