import {
    SET_VISION_AXIS_VALUES,
    SET_VISION_REMARKS,
    SET_VISION_VALUES,
    SET_PATIENT_VISION,
    SET_GLASS_BY_DATE,
    SET_AUTO_BY_DATE,
    SET_VISION_SUGESSIONS
} from './types';

const initialState = {
    autoVision : [],
    glassVision : [],
    oldGlassVision : [],
    patientVision : [],
	visionRemarks : [],
    visionValues : [],
    visionSuggestions : [],
    visionAxisValues : [],
	error: null,
	loading: false,
};

const visionReducer = (state = initialState, action) => {
	switch (action.type) {
        case SET_PATIENT_VISION:
            // console.log(action.payload);
            return {
                ...state,
                autoVision : action.payload?.autoVision || [],
                glassVision : action.payload?.glassVision || [],
                oldGlassVision : action.payload?.oldGlassVision || [],
                patientVision: action.payload,
            }
        case SET_AUTO_BY_DATE:{
            return {
                ...state,
                dateAutoVision : action.payload,
            }
        }
        case SET_GLASS_BY_DATE:{
            console.log(action.payload);
            return {
                ...state,
                dateGlassVision : action.payload.glassVision || [],
                dateOldGlassVision : action.payload.oldGlassVision || [],
            }
        }
        case SET_VISION_SUGESSIONS:
            return {
                ...state,
                visionSuggestions : action.payload || [],
            }
        case SET_VISION_REMARKS:
            return {
                ...state,
                visionRemarks: action.payload || [],
                loading: false,
            }
        case SET_VISION_VALUES:
            return {
                ...state,
                visionValues: action.payload || [],
                loading: false,
            }
        case SET_VISION_AXIS_VALUES:
            return {
                ...state,
                visionAxisValues: action.payload || [],
                loading: false,
            }
        default:
			return state;
	}
};

export default visionReducer;
