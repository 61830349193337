import React from 'react';
import { useSelector } from 'react-redux';
import Checks from '../../../../components/bootstrap/forms/Checks';

function Risk({ riskFormik, formik }) {
	const riskList = useSelector((state) => state.ipd.gynaeRiskList);

	return (
		<div className='row mb-3'>
			<div className='col-12 mb-2 text-center'>
				<strong>Risk Stratification</strong>
			</div>
			{riskList?.map((riskItem, index) => (
				<div className='row mb-3' key={index}>
					<div className='col-12 mb-2'>
						<strong>{riskItem?.name + 'S '}:</strong>
					</div>
					<div
						className='d-grid'
						style={{ gridTemplateColumns: '1fr 1fr 1fr', gridRowGap: '1rem' }}>
						{riskItem?.Gyno_risk_stratifications?.map((risk, riskIndex) => (
							<div key={riskIndex}>
								<Checks
									id={risk?.id}
									type='checkbox'
									label={risk?.title}
									checked={riskFormik.values[risk?.id]?.isChecked}
									onChange={() =>
										riskFormik.setFieldValue(risk?.id, {
											...riskFormik.values[risk?.id],
											isChecked:
												!riskFormik.values[risk?.id]?.isChecked || false,
										})
									}
								/>
							</div>
						))}
					</div>
				</div>
			))}
			<table className='table table-bordered'>
				<tbody>
					<tr>
						<th colSpan={3} className='text-center'>
							Risk Summary
						</th>
					</tr>
					{riskList?.map((risk, index) => (
						<tr key={index}>
							<th>{risk?.name + 'S :'}</th>
							<td colSpan={2}>
								{risk?.Gyno_risk_stratifications?.filter(
									(x) => riskFormik.values[x?.id]?.isChecked || false,
								)?.length?.toString()}
							</td>
						</tr>
					))}
					<tr>
						<td>
							<Checks
								id='atNoRisk'
								type='radio'
								label='At No Risk'
								name='riskSummary'
								onFocus={formik.handleChange}
								value={'at no risk'}
								checked={formik.values.riskSummary}
							/>
						</td>
						<td>
							<Checks
								id='atRisk'
								type='radio'
								label='At Risk'
								name='riskSummary'
								onFocus={formik.handleChange}
								value={'at risk'}
								checked={formik.values.riskSummary}
							/>
						</td>
						<td>
							<Checks
								id='atHighRisk'
								type='radio'
								label='At High Risk'
								name='riskSummary'
								onFocus={formik.handleChange}
								value={'at high risk'}
								checked={formik.values.riskSummary}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default Risk;
