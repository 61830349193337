import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Navigation from '../Navigation/Navigation';
import { dashboardMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import useAsideTouch from '../../hooks/useAsideTouch';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../components/bootstrap/OffCanvas';
import DashboardImg from '../../assets/img/menu/patient/dashboard.png';

import WhiteBoard from '../../pages/patients/components/WhiteBoard';
import ClinicalNotes from '../../pages/patients/components/ClinicalNotes';
import Investigations from '../../pages/patients/components/Investigations';
import PatientAppointments from '../../pages/patients/components/PatientAppointments';
import PatientBilling from '../../pages/patients/components/PatientBilling';
import PrescriptionDraw from '../../pages/patients/components/PatientPrescription/PrescriptionDraw';

// Patient Icons
import ProfileImg from '../../assets/img/menu/patient/profile.png';
import Aida from '../../assets/img/menu/patient/aida.png';

import RxImg from '../../assets/img/menu/patient/rx.png';
import InvestigationsImg from '../../assets/img/menu/patient/investigations.png';
import FollowupSheetImg from '../../assets/img/menu/patient/followup-sheet.png';
import ClinicalNotesImg from '../../assets/img/menu/patient/clinical-notes.png';
import WhiteboardImg from '../../assets/img/menu/patient/whiteboard.png';
import PatientFileImg from '../../assets/img/menu/patient/patient-file.png';
import BillingImg from '../../assets/img/menu/patient/billing.png';
import AppointmentsImg from '../../assets/img/menu/patient/appointments.png';
import ClinicalNotesImgHighLighted from '../../assets/img/menu/patient/highlighted/gg_menu-grid-o2.png';
import Allergy from '../../assets/img/menu/ipd/allergy.png';
import PatientHistory from '../../assets/img/menu/ipd/patient_history.png';
import PhysicalExamination from '../../assets/img/menu/ipd/physical_examination.png';
import Procedure from '../../assets/img/menu/ipd/procedure.png';
import Summary from '../../assets/img/menu/ipd/summary.png';
import Gynaecology from '../../assets/img/menu/ipd/gynaecology.png';
import Labour from '../../assets/img/menu/ipd/labour_record.png';

// TYPES
import {
	SET_FOLLOWUP_SHEETS,
	SET_WHITE_BOARD,
	SET_CLINICALNOTES,
	SET_INVESTIGATION_MODAL,
	SET_IMMUNE_MODAL,
	SET_HISTORY_MODAL,
	SET_BILLING_MODAL,
	SET_APPOINTMENT_MODAL,
	SET_PATIENT_ATTACHMENTS_MODAL,
	SET_SHARED_REPORTS,
	SET_IPD_CC_MODAL,
	SET_IPD_RX_MODAL,
	SET_IPD_INVESTIGATIONS_MODAL,
	SET_IPD_VISIT_NOTES_MODAL,
	SET_IPD_CONSUMABLES_MODAL,
	SET_IPD_WHITEBOARD_MODAL,
	SET_SUBSCRIPTION_MODAL,
	SET_IPD_PROCEDURES_MODAL,
	SET_IPD_PHYSICAL_EXAMINATION_MODAL,
	SET_IPD_HISTORY_MODAL,
	SET_IPD_ALLERGY_MODAL,
	SET_GYNAE_MODAL,
	SET_IPD_BILLING_MODAL,
	SET_LABOUR_MODAL,
	SET_DISCHARGE_MODAL,
	SET_OPD_GYANE_MODAL,
	SET_DASHBOARDMENU_TYPE,
	SET_IPDFILE_MODAL,
	SET_VIEW_CONSULT2DOCTOR_MODAL,
	UPDATE_ONLINE_STATUS,
	SET_CHEMO_NOTES_MODAL,
	SET_DISCHARGE2_MODAL,
	SET_CONSENT_MODAL,
	SET_VISITS_MODAL,
	SET_APPOINTMENT_DIAGNOSES_MODAL,
	SET_IPD_DIAGNOSES_MODAL,
} from '../../reducers/types';
import PatientImmunisation from '../../pages/patients/components/PatientImmun';
import History from '../../pages/patients/components/PatientHistory/History';
import PatientSharedReports from '../../pages/patients/components/PatientSharedReports';
import GeneralConsent from '../../pages/patients/components/GeneralConsent';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalFooter,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import PatientAttachment from '../../pages/patients/components/PatientAttachment';
import IpdChiefComplaints from '../../pages/ipd/Modals/IpdChiefComplaints';
// import IpdRx from '../../pages/ipd/Modals/IpdRx';
import IpdVisitNotes from '../../pages/ipd/Modals/IpdVisitNotes';
import IpdWhiteBoard from '../../pages/ipd/Modals/IpdWhiteboard';
import IpdConsummables from '../../pages/ipd/Modals/IpdConsummables';
import PatientSubscription from '../../pages/patients/components/PatientSubscription';
import IpdInvestigations from '../../pages/ipd/Modals/IpdInvestigations';
import IpdProcedures from '../../pages/ipd/Modals/IpdProcedures';
import IpdPhysicalExaminations from '../../pages/ipd/Modals/IpdPhysicalExaminations';
import IpdHistory from '../../pages/ipd/Modals/IpdHistory';
import IpdAllergy from '../../pages/ipd/Modals/IpdAllergy';
import IpdGynae from '../../pages/ipd/Modals/IpdGynae';
import IpdBilling from '../../pages/ipd/Modals/IpdBilling';
import IpdLabourRecord from '../../pages/ipd/Modals/IpdLabourRecord';
import IpdDischargeSummary from '../../pages/ipd/Modals/IpdDischargeSummary';
import Gynae from '../../pages/patients/components/Gynae';
import Input from '../../components/bootstrap/forms/Input';
import { isPermitted, updatDoctorOnlineStatus } from '../../actions/helperActions';
import IpdFiles from '../../pages/ipd/Modals/IpdFiles';
import ConsultWithExpert from '../../pages/patients/components/ConsultWithExpert/ConsultWithExpert';
import ChemoNotes from '../../pages/patients/components/ChemoNotes/ChemoNotes';
import IpdDischargeSummary2 from '../../pages/ipd/Modals/IpdDischargeSummary2';
import AppointmentDiagnoses from '../../pages/patients/components/Diagnoses/AppointmentDiagnoses';
import IpdDiagnoses from '../../pages/ipd/Diagnoses/IpdDiagnoses';

const Aside = () => {
	const navigate = useNavigate();
	const { asideStatus } = useContext(ThemeContext);
	const { asideStyle, touchStatus, hasTouchButton } = useAsideTouch();

	const darkModeStatus = useDarkMode();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const patient = useSelector((state) => state.patient.patient);
	//For attachments Offcanvas header actions
	const [viewAdd, setViewAdd] = useState(false);
	const isOnline = useSelector((state) => state.auth.isOnline);
	// Logic
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector((state) => state.auth.user);
	const currentPatient = useSelector((state) => state.patient.patient);
	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const {
		viewFollowupSheets,
		viewClinicalNotes,
		viewWhiteBoard,
		viewImmuneModal,
		viewInvestigations,
		viewHistoryModal,
		viewBillingModal,
		viewAppointmentModal,
		viewPatientAttachment,
		viewIpdCC,
		viewIpdRx,
		viewIpdInvestigations,
		viewIpdVisitNotes,
		viewIpdConsumables,
		viewIpdWhiteboard,
		viewIpdProcedures,
		viewPhysicalExaminations,
		viewIpdHistory,
		viewIpdAllergy,
		viewIpdGynae,
		viewOpdGynae,
		viewIpdLabour,
		viewDischargeSummary,
		viewDischargeSummary2,
		viewSharedReports,
		viewIpdBillingModal,
		viewIpdFiles,
		viewConsult2DoctorModal,
		viewChemoNotes,
		viewAppointmentDiagnosesModal,
		viewIpdDiagnosesModal,
		viewConsents: generalConsent,
	} = useSelector((state) => state.button);

	const viewSubscriptionModal = useSelector((state) => state.button.viewSubscriptionModal);

	const toggleHistoryModal = () => {
		dispatch({
			type: SET_HISTORY_MODAL,
			payload: !viewHistoryModal,
		});
	};

	const toggleOpdGynaeModal = () => {
		dispatch({
			type: SET_OPD_GYANE_MODAL,
			payload: !viewOpdGynae,
		});
	};

	const toggleWhiteBoard = () => {
		dispatch({
			type: SET_WHITE_BOARD,
			payload: !viewWhiteBoard,
		});
	};

	const toggleClinicalNotes = () => {
		dispatch({
			type: SET_CLINICALNOTES,
			payload: !viewClinicalNotes,
		});
	};

	const toggleInvestigations = () => {
		dispatch({
			type: SET_INVESTIGATION_MODAL,
			payload: !viewInvestigations,
		});
	};

	const toggleImmune = () => {
		dispatch({
			type: SET_IMMUNE_MODAL,
			payload: !viewImmuneModal,
		});
	};

	const toggleBillingModal = () => {
		dispatch({
			type: SET_BILLING_MODAL,
			payload: !viewBillingModal,
		});
	};

	const toggleAppointmentModal = () => {
		dispatch({
			type: SET_APPOINTMENT_MODAL,
			payload: !viewAppointmentModal,
		});
	};

	const togglePatientAttachment = () => {
		dispatch({
			type: SET_PATIENT_ATTACHMENTS_MODAL,
			payload: !viewPatientAttachment,
		});
	};

	const toggleSharedReports = () => {
		dispatch({
			type: SET_SHARED_REPORTS,
			payload: !viewSharedReports,
		});
	};

	const toggleIpdCC = () => {
		dispatch({
			type: SET_IPD_CC_MODAL,
			payload: !viewIpdCC,
		});
	};

	const toggleIpdRx = () => {
		dispatch({
			type: SET_IPD_RX_MODAL,
			payload: !viewIpdRx,
		});
	};

	const toggleIpdInvestigations = () => {
		dispatch({
			type: SET_IPD_INVESTIGATIONS_MODAL,
			payload: !viewIpdInvestigations,
		});
	};

	const toggleIpdVisitNotes = () => {
		dispatch({
			type: SET_IPD_VISIT_NOTES_MODAL,
			payload: !viewIpdVisitNotes,
		});
	};

	const toggleIpdConsumables = () => {
		dispatch({
			type: SET_IPD_CONSUMABLES_MODAL,
			payload: !viewIpdConsumables,
		});
	};

	const toggleIpdWhiteboard = () => {
		dispatch({
			type: SET_IPD_WHITEBOARD_MODAL,
			payload: !viewIpdWhiteboard,
		});
	};

	const toggleIpdProcedures = () => {
		dispatch({
			type: SET_IPD_PROCEDURES_MODAL,
			payload: !viewIpdProcedures,
		});
	};

	const togglePhysicalEaxmiantions = () => {
		dispatch({
			type: SET_IPD_PHYSICAL_EXAMINATION_MODAL,
			payload: !viewPhysicalExaminations,
		});
	};

	const toggleIpdHistory = () => {
		dispatch({
			type: SET_IPD_HISTORY_MODAL,
			payload: !viewIpdHistory,
		});
	};

	const toggleIpdAllergy = () => {
		dispatch({
			type: SET_IPD_ALLERGY_MODAL,
			payload: !viewIpdAllergy,
		});
	};

	const toggleIpdGynae = () => {
		dispatch({
			type: SET_GYNAE_MODAL,
			payload: !viewIpdGynae,
		});
	};

	const toggleIpdBillingModal = () => {
		dispatch({
			type: SET_IPD_BILLING_MODAL,
			payload: !viewIpdBillingModal,
		});
	};

	const toggleIpdFilesModal = () => {
		dispatch({
			type: SET_IPDFILE_MODAL,
			payload: !viewIpdFiles,
		});
	};

	const toggleIpdLabour = () => {
		dispatch({
			type: SET_LABOUR_MODAL,
			payload: !viewIpdLabour,
		});
	};

	const toggleDischargeSummary = () => {
		dispatch({
			type: SET_DISCHARGE_MODAL,
			payload: !viewDischargeSummary,
		});
	};

	const toggleDischargeSummary2 = () => {
		dispatch({
			type: SET_DISCHARGE2_MODAL,
			payload: !viewDischargeSummary2,
		});
	};

	const toggleSubscriptionModal = () => {
		dispatch({
			type: SET_SUBSCRIPTION_MODAL,
			payload: !viewSubscriptionModal,
		});
	};
	const toggleViewConsult2DoctorModal = () => {
		dispatch({
			type: SET_VIEW_CONSULT2DOCTOR_MODAL,
			payload: !viewConsult2DoctorModal,
		});
	};
	const toggleChemoNotesModal = () => {
		dispatch({
			type: SET_CHEMO_NOTES_MODAL,
			payload: !viewChemoNotes,
		});
	};
	const toggleAppointmentDiagnosesModal = () => {
		dispatch({
			type: SET_APPOINTMENT_DIAGNOSES_MODAL,
			payload: !viewAppointmentDiagnosesModal,
		});
	};
	const toggleIpdDiagnosesModal = () => {
		dispatch({
			type: SET_IPD_DIAGNOSES_MODAL,
			payload: !viewIpdDiagnosesModal,
		});
	};

	const asideMenu = {
		dashboard: {
			id: 'dashboard',
			text: 'Dashboard',
			isButton: true,
			path: () => {
				navigate(`/`);
			},
			icon: DashboardImg,
		},
		// admin: {
		// 	id: 'admin',
		// 	text: 'Admin',
		// 	isButton: true,
		// 	path: () => dispatch({ type: SET_DASHBOARDMENU_TYPE, payload: 'admin' }),
		// 	icon: Admin,
		// },
		// doctor: {
		// 	id: 'doctor',
		// 	text: 'Doctor',
		// 	isButton: true,
		// 	path: () => dispatch({ type: SET_DASHBOARDMENU_TYPE, payload: 'doctor' }),
		// 	icon: Doctors,
		// },
		// pharmacy: {
		// 	id: 'pharmacy',
		// 	text: 'Pharmacy',
		// 	isButton: true,
		// 	path: () => dispatch({ type: SET_DASHBOARDMENU_TYPE, payload: 'pharmacy' }),
		// 	icon: Pharmacy,
		// },
		// staff: {
		// 	id: 'staff',
		// 	text: 'Staff',
		// 	isButton: true,
		// 	path: () => dispatch({ type: SET_DASHBOARDMENU_TYPE, payload: 'staff' }),
		// 	icon: Staff,
		// },
		// account: {
		// 	id: 'account',
		// 	text: 'Account',
		// 	isButton: true,
		// 	path: () => dispatch({ type: SET_DASHBOARDMENU_TYPE, payload: 'account' }),
		// 	icon: BillingImg,
		// },
	};

	const patientAsideMenu = {
		aiDa: {
			id: 'aida',
			text: '',
			path: `/patients/${currentPatient?.id}/aida`,
			icon: Aida,
		},
		appointments: {
			id: 'appointments',
			text: 'Visits / Appointments',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_APPOINTMENT_MODAL,
					payload: !viewAppointmentModal,
				}),
			icon: AppointmentsImg,
		},
		rx: {
			id: 'rx',
			text: 'Rx',
			path: `/patient/${currentPatient?.id}/prescriptions`,
			icon: RxImg,
		},
		investigations: {
			id: 'investigations',
			text: 'Investigations',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_INVESTIGATION_MODAL,
					payload: !viewInvestigations,
				}),
			icon: InvestigationsImg,
		},
		clinicalNotes: {
			id: 'clinicalNotes',
			text: 'Clinical Notes',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_CLINICALNOTES,
					payload: !viewClinicalNotes,
				}),
			icon: ClinicalNotesImg,
		},
		whiteboard: {
			id: 'whiteboard',
			text: 'Whiteboard',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_WHITE_BOARD,
					payload: !viewWhiteBoard,
				}),
			icon: WhiteboardImg,
		},
		attachments: {
			id: 'attachments',
			text: 'Patient Attachments',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_PATIENT_ATTACHMENTS_MODAL,
					payload: !viewPatientAttachment,
				}),
			icon: FollowupSheetImg,
		},
		gynae: {
			id: 'gynae',
			text: 'Obs & Gynae.',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_OPD_GYANE_MODAL,
					payload: !viewOpdGynae,
				}),
			icon: Gynaecology,
			hide: currentPatient?.Members?.[0]?.gender?.toLowerCase?.()?.startsWith?.('f')
				? false
				: true,
		},
		patientHistory: {
			id: 'patientHistory',
			text: 'Patient History',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_HISTORY_MODAL,
					payload: !viewHistoryModal,
				}),
			icon: PatientFileImg,
		},
		patientImmune: {
			id: 'patientImmune',
			text: 'Patient Immunisation',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IMMUNE_MODAL,
					payload: !viewImmuneModal,
				}),
			icon: PatientFileImg,
		},
		billing: {
			id: 'billing',
			text: 'Billing',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_BILLING_MODAL,
					payload: !viewBillingModal,
				}),
			icon: BillingImg,
		},
		generalConsent: {
			id: 'generalConsent',
			text: 'Consents/Medical Certificates',
			path: () => {
				dispatch({
					type: SET_CONSENT_MODAL,
					payload: !generalConsent,
				});
			},
			icon: PatientFileImg,
			isButton: true,
		},
		ipdFiles: {
			id: 'ipdFiles',
			text: 'IPD History',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPDFILE_MODAL,
					payload: !viewIpdFiles,
				}),
			icon: PatientHistory,
		},
		chemoNotes: {
			id: 'chemoNotes',
			text: 'Chemo Notes',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_CHEMO_NOTES_MODAL,
					payload: !viewChemoNotes,
				}),
			icon: PatientHistory,
		},
		diagnoses: {
			id: 'diagnoses',
			text: 'Diagnoses',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_APPOINTMENT_DIAGNOSES_MODAL,
					payload: !viewAppointmentDiagnosesModal,
				}),
			icon: PatientFileImg,
		},
		profile: {
			id: 'profile',
			text: 'Profile',
			path: `/patients/${currentPatient?.id}/profile`,
			icon: ProfileImg,
		},
	};

	const misAsideMenu = {
		overview: {
			id: 'overview',
			text: 'Overview',
			isButton: false,
			path: dashboardMenu.accounts.subMenu.mis.path,
			icon: PatientHistory,
		},
		patientMIS: {
			id: 'patientMIS',
			text: 'Patient MIS',
			isButton: false,
			path: dashboardMenu.accounts.subMenu.patientMis.path,
			icon: PatientHistory,
		},
		doctorMIS: {
			id: 'doctorMIS',
			text: 'Doctor MIS',
			isButton: false,
			path: dashboardMenu.accounts.subMenu.doctorMis.path,
			icon: PatientHistory,
		},
		rxMIS: {
			id: 'rxMIS',
			text: 'Rx MIS',
			isButton: false,
			path: dashboardMenu.accounts.subMenu.rxMis.path,
			icon: PatientHistory,
		},
		diagnosticMIS: {
			id: 'diagnosticMIS',
			text: 'Diagnostic MIS',
			isButton: false,
			path: dashboardMenu.accounts.subMenu.diagnosticMis.path,
			icon: PatientHistory,
		},
		// frontDesk: {
		// 	id: 'frontDesk',
		// 	text: 'FrontDesk',
		// 	isButton: false,
		// 	path: '',
		// 	icon: WhiteboardImg,
		// },
		// doctor: {
		// 	id: 'doctor',
		// 	text: 'Doctor',
		// 	isButton: false,
		// 	path: '',
		// 	icon: FollowupSheetImg,
		// },
		// clinicalReports: {
		// 	id: 'clinicalReports',
		// 	text: 'Clinical Reports',
		// 	isButton: false,
		// 	path: '',
		// 	icon: ClinicalNotesImg,
		// },
	};

	const ipdAsideMenu = {
		chiefComplaints: {
			id: 'chiefComplaints',
			text: 'Chief Complaints',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_CC_MODAL,
					payload: !viewIpdCC,
				}),
			icon: ProfileImg,
		},
		allergies: {
			id: 'allergies',
			text: 'Allergies',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_ALLERGY_MODAL,
					payload: !viewIpdAllergy,
				}),
			icon: Allergy,
		},
		patientHistory: {
			id: 'patientHistory',
			text: 'Patient History',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_HISTORY_MODAL,
					payload: !viewIpdHistory,
				}),
			icon: PatientHistory,
		},
		physcicalExamiantions: {
			id: 'physcicalExamiantions',
			text: 'Physical Examinations',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_PHYSICAL_EXAMINATION_MODAL,
					payload: !viewPhysicalExaminations,
				}),
			icon: PhysicalExamination,
		},
		investigations: {
			id: 'investigations',
			text: 'Investigations',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_INVESTIGATIONS_MODAL,
					payload: !viewIpdInvestigations,
				}),
			icon: InvestigationsImg,
		},
		visitNotes: {
			id: 'visitNotes',
			text: 'Visit Notes',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_VISIT_NOTES_MODAL,
					payload: !viewIpdVisitNotes,
				}),
			icon: ClinicalNotesImg,
		},
		procedures: {
			id: 'procedures',
			text: 'Procedures',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_PROCEDURES_MODAL,
					payload: !viewIpdProcedures,
				}),
			icon: Procedure,
		},
		whiteboard: {
			id: 'whiteboard',
			text: 'Whiteboard',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_WHITEBOARD_MODAL,
					payload: !viewIpdWhiteboard,
				}),
			icon: WhiteboardImg,
		},
		gynae: {
			id: 'gynae',
			text: 'Obs & Gynae.',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_GYNAE_MODAL,
					payload: !viewIpdGynae,
				}),
			icon: Gynaecology,
			hide: ipdFile?.Member?.gender?.toLowerCase?.()?.startsWith?.('f') ? false : true,
		},
		labour: {
			id: 'labour',
			text: 'Labour Record',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_LABOUR_MODAL,
					payload: !viewIpdLabour,
				}),
			icon: Labour,
			hide: ipdFile?.Member?.gender?.toLowerCase?.()?.startsWith?.('f') ? false : true,
		},
		chemoNotes: {
			id: 'chemoNotes',
			text: 'Chemo Notes',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_CHEMO_NOTES_MODAL,
					payload: !viewChemoNotes,
				}),
			icon: PatientHistory,
		},
		consumables: {
			id: 'consumables',
			text: 'Consumables /Others',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_CONSUMABLES_MODAL,
					payload: !viewIpdConsumables,
				}),
			icon: PatientFileImg,
		},
		discharge: {
			id: 'discharge',
			text: 'Discharge Summary',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_DISCHARGE_MODAL,
					payload: !viewDischargeSummary,
				}),
			icon: Summary,
		},
		discharge2: {
			id: 'discharge2',
			text: 'Discharge Summary 2',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_DISCHARGE2_MODAL,
					payload: !viewDischargeSummary2,
				}),
			icon: Summary,
		},
		billing: {
			id: 'billing',
			text: 'Billing',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_BILLING_MODAL,
					payload: !viewIpdBillingModal,
				}),
			icon: BillingImg,
		},
		ipdFiles: {
			id: 'ipdFiles',
			text: 'IPD Files',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPDFILE_MODAL,
					payload: !viewIpdFiles,
				}),
			icon: PatientHistory,
		},
		diagnoses: {
			id: 'diagnoses',
			text: 'Diagnoses',
			isButton: true,
			path: () =>
				dispatch({
					type: SET_IPD_DIAGNOSES_MODAL,
					payload: !viewIpdDiagnosesModal,
				}),
			icon: PatientFileImg,
		},
	};

	const teleCrmAsideMenu = {
		patientDetails: {
			id: 'patientDetails',
			text: 'Patient Details',
			path: `/tele-crm/call-logs/patient-details`,
			icon: ProfileImg,
		},
		// callLogs: {
		// 	id: 'callLogs',
		// 	text: 'Call Logs',
		// 	path: `/tele-crm/call-logs/patient-call-logs`,
		// 	icon: PatientFileImg,
		// },
		appointmentLogs: {
			id: 'appointmentLogs',
			text: 'Appointment Logs',
			path: `/tele-crm/call-logs/patient-appointment-logs`,
			icon: AppointmentsImg,
		},
	};

	// Draw
	const [drawModal, setDrawModal] = useState(false);
	const [drawImage, setDrawImage] = useState('');
	const [chooseDrawModal, setChooseDrawModal] = useState(false);

	const blobToBase64 = (blob) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleDrawImgChange = async (e) => {
		if (!e.target.files) {
			setDrawImage(null);
			return;
		}
		const img = e.target.files[0];
		setDrawImage(await blobToBase64(img));
	};

	return (
		<>
			<motion.aside
				style={{ ...asideStyle, zIndex: 1 }}
				className={classNames(
					'aside shadow-none',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-body' style={{ marginTop: '5rem' }}>
					<Navigation
						menu={
							location?.pathname == `/patients/${currentPatient?.id}/profile` ||
							location?.pathname == `	/patients/${currentPatient?.id}/aida` ||
							location?.pathname == `/patient/${currentPatient?.id}/prescriptions` ||
							location?.pathname.includes('/account/mis/')
								? { dashboard: asideMenu.dashboard }
								: asideMenu
						}
						id='aside-dashboard'
					/>
					{(location?.pathname.includes('/tele-crm/call-logs/patient-details') ||
						location?.pathname.includes('/tele-crm/call-logs/patient-call-logs') ||
						location?.pathname.includes(
							'/tele-crm/call-logs/patient-appointment-logs',
						)) && <Navigation menu={teleCrmAsideMenu} id='aside-dashboard' />}
					{(location?.pathname == `/patients/${currentPatient?.id}/profile` ||
						location?.pathname == `/patients/${currentPatient?.id}/aida` ||
						location?.pathname == `/patient/${currentPatient?.id}/prescriptions`) && (
						<Navigation menu={patientAsideMenu} id='aside-dashboard' />
					)}
					{location?.pathname == `/ipd/${ipdId}/patient/${currentPatient?.id}` && (
						<Navigation menu={ipdAsideMenu} id='aside-dashboard' />
					)}
					{location?.pathname.includes('/account/mis/') && (
						<Navigation menu={misAsideMenu} id='aside-dashboard' />
					)}
				</div>
				{/* {!user?.isRemoteClinic ? (
					<div style={{ marginLeft: 10 }}>
						<p>Online Status</p>
						<Checks
							type='switch'
							checked={isOnline}
							onChange={(e) => {
								updatDoctorOnlineStatus(!isOnline);
								dispatch({
									type: UPDATE_ONLINE_STATUS,
									payload: { status: !isOnline },
								});
							}}
						/>
					</div>
				) : null} */}
			</motion.aside>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewWhiteBoard}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleWhiteBoard}></OffCanvasHeader>
				<OffCanvasBody style={{ overflowY: 'hidden' }}>
					<WhiteBoard
						userId={currentPatient?.id}
						vendorId={user?.Vendor_detail?.vendorId}
					/>
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleWhiteBoard();
							toggleClinicalNotes();
						}}>
						Clinical Notes
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleWhiteBoard();
							togglePatientAttachment();
						}}>
						Patient Attachments
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={generalConsent}
				isBodyScroll
				placement='end'
				width='70%'>
				<OffCanvasHeader
					setOpen={() =>
						dispatch({
							type: SET_CONSENT_MODAL,
							payload: !generalConsent,
						})
					}>
					{/* <OffCanvasTitle> </OffCanvasTitle> */}
				</OffCanvasHeader>
				<OffCanvasBody>
					<GeneralConsent />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewClinicalNotes}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleClinicalNotes}></OffCanvasHeader>
				<OffCanvasBody>
					<ClinicalNotes
						userId={currentPatient?.id}
						vendorId={user?.Vendor_detail?.vendorId}
					/>
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleClinicalNotes();
							toggleInvestigations();
						}}>
						Investigations
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleClinicalNotes();
							toggleWhiteBoard();
						}}>
						WhiteBoard
					</Button>
				</div>
			</OffCanvas>

			<OffCanvas
				setOpen={() => {}}
				isOpen={viewInvestigations}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={toggleInvestigations}></OffCanvasHeader>
				<OffCanvasBody>
					<Investigations
						userId={currentPatient?.id}
						vendorId={user?.Vendor_detail?.vendorId}
					/>
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0 ms-auto'
						onClick={() => {
							toggleInvestigations();
							toggleClinicalNotes();
						}}>
						Clinical Notes
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={toggleImmune}
				isOpen={viewImmuneModal}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={toggleImmune}></OffCanvasHeader>
				<OffCanvasBody>
					<PatientImmunisation
						doctor_id={user?.Vendor_detail?.vendorId}
						patient={currentPatient}
						userId={currentPatient?.id}
					/>
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleImmune();
							toggleHistoryModal();
						}}>
						Patient History
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleImmune();
							toggleBillingModal();
						}}>
						Patient Billing
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewSubscriptionModal}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={toggleSubscriptionModal}></OffCanvasHeader>
				<OffCanvasBody>
					<PatientSubscription patientId={currentPatient?.id} />
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleSubscriptionModal();
							togglePatientAttachment();
						}}>
						Patient Attachments
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleSubscriptionModal();
							toggleHistoryModal();
						}}>
						Patient History
					</Button>
				</div>
			</OffCanvas>

			<OffCanvas
				setOpen={() => {}}
				isOpen={viewPatientAttachment}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={togglePatientAttachment}></OffCanvasHeader>
				<OffCanvasBody>
					<div className='attachment-header mb-4'>
						<div className='attach-title'>
							<img
								src={ClinicalNotesImgHighLighted}
								className='img-fluid me-2 ms-3'
								style={{ height: '35px' }}
							/>
							<span style={{ fontSize: '1.5rem', marginRight: '20px' }}>
								<strong>Patient Attachments</strong>
							</span>
						</div>
						<div className='attach-add'>
							<Button
								isOutline={!darkModeStatus}
								color='primary'
								icon={viewAdd ? 'Close' : 'Add'}
								isLight={darkModeStatus}
								className={classNames('text-nowrap', {
									'border-light': !darkModeStatus,
								})}
								onClick={() =>
									isPermitted('Medical Profile', 'write', () =>
										setViewAdd(!viewAdd),
									)
								}>
								{viewAdd ? 'Close' : 'Add'}
							</Button>
						</div>
						<div style={{ width: '1rem' }}></div>
						<div>
							<Button
								isOutline={!darkModeStatus}
								color='primary'
								icon='Draw'
								isLight={darkModeStatus}
								className={classNames('text-nowrap', {
									'border-light': !darkModeStatus,
								})}
								onClick={() =>
									isPermitted('Medical Profile', 'write', () =>
										setChooseDrawModal(true),
									)
								}>
								Draw
							</Button>
						</div>
					</div>
					<PatientAttachment
						viewAdd={viewAdd}
						setViewAdd={setViewAdd}
						id={currentPatient?.id}
					/>
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							togglePatientAttachment();
							toggleWhiteBoard();
						}}>
						WhiteBoard
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							togglePatientAttachment();
							toggleHistoryModal();
						}}>
						Patient History
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewHistoryModal}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={toggleHistoryModal}></OffCanvasHeader>
				<OffCanvasBody>
					<History id={currentPatient?.id} />
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleHistoryModal();
							togglePatientAttachment();
						}}>
						Patient Attachments
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleHistoryModal();
							toggleImmune();
						}}>
						Patient Immunisation
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewOpdGynae}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleOpdGynaeModal}></OffCanvasHeader>
				<OffCanvasBody>
					<Gynae />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={toggleSharedReports}
				isOpen={viewSharedReports}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={toggleSharedReports}></OffCanvasHeader>
				<OffCanvasBody>
					<div className='attachment-header mb-4'>
						<div className='attach-title'>
							<img
								src={ClinicalNotesImgHighLighted}
								className='img-fluid me-2 ms-3'
								style={{ height: '35px' }}
							/>
							<span style={{ fontSize: '1.5rem', marginRight: '20px' }}>
								<strong>Patient Shared Reports</strong>
							</span>{' '}
						</div>
					</div>
					<PatientSharedReports
						viewAdd={viewAdd}
						setViewAdd={setViewAdd}
						id={currentPatient?.id}
					/>
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleSharedReports();
							toggleImmune();
						}}>
						Patient Immunisation
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleSharedReports();
							toggleBillingModal();
						}}>
						Patient Billing
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewBillingModal}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={toggleBillingModal}></OffCanvasHeader>
				<OffCanvasBody>
					<PatientBilling patient={currentPatient} />
				</OffCanvasBody>

				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleBillingModal();
							toggleImmune();
						}}>
						Patient Immunisation
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleBillingModal();
							toggleAppointmentModal();
						}}>
						Patient Appointments
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewAppointmentModal}
				isBodyScroll
				placement='end'
				width='87%'>
				<OffCanvasHeader setOpen={toggleAppointmentModal}></OffCanvasHeader>
				<OffCanvasBody>
					<PatientAppointments patient={currentPatient} />
				</OffCanvasBody>
				<div className='offcanvas-buttons'>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							toggleAppointmentModal();
							toggleBillingModal();
						}}>
						Patient Billing
					</Button>
				</div>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewChemoNotes}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleChemoNotesModal}></OffCanvasHeader>
				<OffCanvasBody>
					<ChemoNotes userId={currentPatient?.id} ipdFile={ipdFile} />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewAppointmentDiagnosesModal}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleAppointmentDiagnosesModal}></OffCanvasHeader>
				<OffCanvasBody>
					<AppointmentDiagnoses userId={currentPatient?.id} />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdDiagnosesModal}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdDiagnosesModal}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdDiagnoses userId={currentPatient?.id} />
				</OffCanvasBody>
			</OffCanvas>

			{/* IPD SECTION MODALS */}
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdCC}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdCC}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdChiefComplaints />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdInvestigations}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdInvestigations}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdInvestigations />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdVisitNotes}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdVisitNotes}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdVisitNotes />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdConsumables}
				isBodyScroll
				placement='end'
				width='70%'>
				<OffCanvasHeader setOpen={toggleIpdConsumables}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdConsummables />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdWhiteboard}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdWhiteboard}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdWhiteBoard />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdProcedures}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdProcedures}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdProcedures />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewPhysicalExaminations}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={togglePhysicalEaxmiantions}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdPhysicalExaminations />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdHistory}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdHistory}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdHistory />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdAllergy}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdAllergy}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdAllergy />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdGynae}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdGynae}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdGynae />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdBillingModal}
				isBodyScroll
				placement='end'
				width='80%'>
				<OffCanvasHeader setOpen={toggleIpdBillingModal}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdBilling />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdFiles}
				isBodyScroll
				placement='end'
				width='80%'>
				<OffCanvasHeader setOpen={toggleIpdFilesModal}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdFiles
						patientId={
							location?.pathname == `/patients/${currentPatient?.id}/profile` ||
							location?.pathname == `/patient/${currentPatient?.id}/prescriptions`
								? currentPatient?.id
								: ipdFile?.patientId
						}
					/>
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewIpdLabour}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleIpdLabour}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdLabourRecord />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewDischargeSummary}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleDischargeSummary}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdDischargeSummary />
				</OffCanvasBody>
			</OffCanvas>
			<OffCanvas
				setOpen={() => {}}
				isOpen={viewDischargeSummary2}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleDischargeSummary2}></OffCanvasHeader>
				<OffCanvasBody>
					<IpdDischargeSummary2 />
				</OffCanvasBody>
			</OffCanvas>
			{/* IPD Section Modals END */}

			<OffCanvas
				setOpen={() => {}}
				isOpen={viewConsult2DoctorModal}
				isBodyScroll
				placement='end'
				width='75%'>
				<OffCanvasHeader setOpen={toggleViewConsult2DoctorModal}></OffCanvasHeader>
				<OffCanvasBody>
					<ConsultWithExpert />
				</OffCanvasBody>
			</OffCanvas>

			<Modal
				isOpen={chooseDrawModal}
				setIsOpen={() => {}}
				isCentered
				size='lg'
				isAnimation={false}>
				<ModalHeader setIsOpen={setChooseDrawModal}>
					<ModalTitle id='chooseDrawModalLabel'>Choose Draw Image</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='col-12'>
						<div className='row g-4 align-items-center'>
							{drawImage ? (
								<img
									src={drawImage}
									className='mx-auto w-50 d-block img-fluid mb-3'
								/>
							) : (
								<p className='lead text-muted text-center'>
									Preview will be displayed here
								</p>
							)}
							<div className='row justify-content-center m-3'>
								<div className='col-auto'>
									<Input
										type='file'
										accept='image/*'
										autoComplete='profile-img'
										onChange={handleDrawImgChange}
									/>
								</div>
								<div className='col-auto'>
									<Button
										onClick={handleDrawImgChange}
										color='dark'
										isLight
										icon='Delete'>
										Remove Image
									</Button>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setChooseDrawModal(false)}>
						Cancel
					</Button>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							setChooseDrawModal(false);
							setDrawModal(true);
						}}>
						Next
					</Button>
				</ModalFooter>
			</Modal>
			<Modal isOpen={drawModal} setIsOpen={() => {}} isCentered size='xl' isAnimation={false}>
				<ModalHeader setIsOpen={setDrawModal}>
					<ModalTitle id='drawModalLabel'>Draw</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<PrescriptionDraw
						patient={currentPatient}
						drawImage={drawImage}
						setDrawModal={setDrawModal}
						setChooseDrawModal={setChooseDrawModal}
					/>
				</ModalBody>
			</Modal>
		</>
	);
};

export default Aside;
