import { SET_PATIENT_IMMUN,SET_IMMUN_REMARKS } from './types';

const initialState = {
    patientImmun : [],
    immunRemarks : [],
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PATIENT_IMMUN:
            return {
                ...state,   
                patientImmun : action.payload || [],
            }
        case SET_IMMUN_REMARKS:
            return {
                ...state,
                immunRemarks : action.payload || [],
            }
		default:
			return state;
	}
};

export default authReducer;
