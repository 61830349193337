import {
	SET_APPOINTMENTS,
	CREATE_APPOINTMENT,
	UPDATE_APPOINTMENT,
	DELETE_APPOINTMENT,
	SET_APPOINTMENT_SLOTS,
	SET_CALENDAR_APPOINTMENTS,
	SET_APPOINTMENTS_FROM_REMOTE_CLINIC,
	SET_APPOINTMENTS_OF_REMOTE_CLINIC,
} from '../reducers/types';
import axios from '../helpers/axios';
import axiosz from 'axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import { getPatientAppointments } from './patientActions';
import { toggleCreateLoading, toggleTableLoading } from './componentActions';
import { DefaultPagination, currentOrg } from '../helpers/config';
import moment from 'moment';

let cancelToken;
export const getAppointments =
	(params = DefaultPagination, { organisationId } = { organisationId: currentOrg }) =>
	async (dispatch) => {
		var orgId = organisationId;
		try {
			if (typeof cancelToken != typeof undefined) {
				cancelToken?.cancel('Cancelling prev req');
			}
			if (!organisationId) {
				const res = await axios.get('/organisation-ehr');
				if (res.data.success) {
					if (res.data.organisations.length > 0) {
						orgId = res.data.organisations[0].id;
					}
				}
			}
			cancelToken = axiosz.CancelToken?.source();
			axiosz.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');
			const res = await axiosz.get(`/appointment-ehr/upcomming`, {
				cancelToken: cancelToken?.token,
				params: { ...params },
				baseURL: process.env.REACT_APP_SERVER_URL,
			});
			if (res.data.success) {
				dispatch({
					type: SET_APPOINTMENTS,
					payload: {
						appointments: res.data.appointments,
						page: res.data.totalPage,
						total: res.data.totalRecord,
					},
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const getAppointment = async (appointmentId) => {
	try {
		const res = await axios.get(`'/appointment-ehr/${appointmentId}'`);
		if (res.data.success) {
			return res.data.appointment;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const createAppointment =
	(newAppointment, orgId, isNoti = true, setModal) =>
	async (dispatch) => {
		try {
			const res = await axios.post('/appointment-ehr', newAppointment);
			if (res.data.success) {
				if (isNoti)
					showNotification(
						'Success',
						'The appointment is created successfully',
						'success',
					);
				dispatch(getPatientAppointments(newAppointment.userId));
				orgId && dispatch(getAppointments(DefaultPagination, { organisationId: orgId }));
				setModal?.(false);
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const updateAppointment = (updateBody, orgId, patientId, setModal) => async (dispatch) => {
	try {
		const res = await axios.post('/appointment-ehr/update', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The appointment is updated successfully', 'success');
			if (patientId) dispatch(getPatientAppointments(patientId));
			dispatch(getAppointments({ pageNo: 1, limit: 10 }, { organisationId: orgId }));
			dispatch(
				getAppointmentsOfExpertDoctor({ pageNo: 1, limit: 10 }, { organisationId: orgId }),
			);
			dispatch(
				getAppointmentsBookedByRemoteClinic(
					{ pageNo: 1, limit: 10 },
					{ organisationId: orgId },
				),
			);

			dispatch(
				getAppointmentsInRange(
					moment().startOf('month').format('YYYY-MM-DD'),
					moment().endOf('month').format('YYYY-MM-DD'),
					orgId,
				),
			);
			setModal(false);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateAppointmentStatus = (updateBody, orgId) => async (dispatch) => {
	try {
		const res = await axios.post('/appointment-ehr/status', updateBody);
		if (res.data.success) {
			showNotification('Success', 'Status Updated Successfully', 'success');
			dispatch(getAppointments({ pageNo: 1, limit: 10 }, { organisationId: orgId }));
			dispatch(
				getAppointmentsOfExpertDoctor({ pageNo: 1, limit: 10 }, { organisationId: orgId }),
			);
			dispatch(
				getAppointmentsBookedByRemoteClinic(
					{ pageNo: 1, limit: 10 },
					{ organisationId: orgId },
				),
			);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteAppointment = (appointmentId, patientId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/appointment-ehr/${appointmentId}`);
		if (res.data.success) {
			if (patientId) dispatch(getPatientAppointments(patientId));
			dispatch({
				type: DELETE_APPOINTMENT,
				payload: appointmentId,
			});
			showNotification('Success', 'The appointment has been deleted', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getAppointmentsInRange = (startDate, endDate, orgId) => async (dispatch) => {
	try {
		const res = await axios.get(
			`/doctor/appointment/list?start=${startDate}&end=${endDate}&type=DATE&organisationId=${orgId}`,
		);
		if (res.data.success) {
			dispatch({
				type: SET_CALENDAR_APPOINTMENTS,
				payload: res.data.appointments,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getSlotsByDate = (params) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/appointment/slots`, { params });
		if (res.data.success) {
			dispatch({
				type: SET_APPOINTMENT_SLOTS,
				payload: res.data.slots,
			});
		}
	} catch (error) {
		dispatch({
			type: SET_APPOINTMENT_SLOTS,
			payload: [],
		});
		console.error(error);
	}
};

export const createAppointmentRemoteClinic =
	(newAppointment, isNoti = true, cb) =>
	async (dispatch) => {
		try {
			const res = await axios.post('/appointment-ehr/remoteClinic', newAppointment);
			if (res.data.success) {
				if (isNoti)
					showNotification(
						'Success',
						'The appointment is created successfully',
						'success',
					);
				// dispatch(getPatientAppointments(newAppointment.userId));
				// orgId && dispatch(getAppointments(DefaultPagination, { organisationId: orgId }));
				cb?.();
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const getSlotsByVendorAndOrg = (params) => async (dispatch) => {
	try {
		const res = await axios.get(
			`/doctor/appointment/getSlotByOrgAndVendor?organisationId=${params?.organisationId}&vendorId=${params?.vendorId}&date=${params?.date}`,
		);
		if (res.data.success) {
			dispatch({
				type: SET_APPOINTMENT_SLOTS,
				payload: res.data.slots,
			});
		}
	} catch (error) {
		dispatch({
			type: SET_APPOINTMENT_SLOTS,
			payload: [],
		});
		console.error(error);
	}
};

let cancelTokenAppointmentRemoteClinic;
export const getAppointmentsBookedByRemoteClinic =
	(params = DefaultPagination, { organisationId } = { organisationId: currentOrg }) =>
	async (dispatch) => {
		var orgId = organisationId;
		try {
			if (typeof cancelTokenAppointmentRemoteClinic != typeof undefined) {
				cancelTokenAppointmentRemoteClinic?.cancel('Cancelling prev req');
			}
			if (!organisationId) {
				const res = await axios.get('/organisation-ehr');
				if (res.data.success) {
					if (res.data.organisations.length > 0) {
						orgId = res.data.organisations[0].id;
					}
				}
			}
			cancelTokenAppointmentRemoteClinic = axiosz.CancelToken?.source();
			axiosz.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');
			const res = await axiosz.get(`/appointment-ehr/getAppointmentsBookedByRemoteClinic`, {
				cancelToken: cancelTokenAppointmentRemoteClinic?.token,
				params: { ...params },
				baseURL: process.env.REACT_APP_SERVER_URL,
			});
			if (res.data.success) {
				dispatch({
					type: SET_APPOINTMENTS_OF_REMOTE_CLINIC,
					payload: {
						appointments: res.data.appointments,
						page: res.data.totalPage,
						total: res.data.totalRecord,
					},
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

let cancelTokenAppointmentExpertDoctor;
export const getAppointmentsOfExpertDoctor =
	(params = DefaultPagination, { organisationId } = { organisationId: currentOrg }) =>
	async (dispatch) => {
		var orgId = organisationId;
		try {
			if (typeof cancelTokenAppointmentExpertDoctor != typeof undefined) {
				cancelTokenAppointmentExpertDoctor?.cancel('Cancelling prev req');
			}
			if (!organisationId) {
				const res = await axios.get('/organisation-ehr');
				if (res.data.success) {
					if (res.data.organisations.length > 0) {
						orgId = res.data.organisations[0].id;
					}
				}
			}
			cancelTokenAppointmentExpertDoctor = axiosz.CancelToken?.source();
			axiosz.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');
			const res = await axiosz.get(`/appointment-ehr/getAppointmentsOfExpertDoctor`, {
				cancelToken: cancelTokenAppointmentExpertDoctor?.token,
				params: { ...params },
				baseURL: process.env.REACT_APP_SERVER_URL,
			});
			if (res.data.success) {
				dispatch({
					type: SET_APPOINTMENTS_FROM_REMOTE_CLINIC,
					payload: {
						appointments: res.data.appointments,
						page: res.data.totalPage,
						total: res.data.totalRecord,
					},
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const getPatientsforAppointment = async (orgainsationId) => {
	try {
		const res = await axios.get(`/doctor/patient/listall`, { params: { orgainsationId } });
		if (res.data.success) {
			return res.data.users;
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const getDoctorsforAppointment = async (organisationId) => {
	try {
		const res = await axios.get(`/doctor/list-doctor`, { params: { organisationId } });
		if (res.data.success) {
			return res.data.vendors;
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};
