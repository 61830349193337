import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDarkMode from '../../hooks/useDarkMode';
import Button from '../bootstrap/Button';
import classNames from 'classnames';
import Tooltips from '../bootstrap/Tooltips';
import Loader from './Loader';
import { toggleCreateLoading, toggleTableLoading } from '../../actions/componentActions';
import { CardBody } from '../bootstrap/Card';
import PaginationButtons from './Pagination';
import { isPermitted } from '../../actions/helperActions';
import { GetNestedValue } from '../../helpers/helpers';
import { DefaultPagination } from '../../helpers/config';
// import { Loader } from '@storybook/components';

const Table = forwardRef(
	(
		{
			headers,
			footer = [],
			dispatchCall,
			renderingData,
			cardBody = true,
			pages,
			total,
			label,
			dispatchParams,
			queryParams = {},
			module = null,
			pagination = true,
			height = 'auto',
			style = {},
		},
		ref,
	) => {
		const dispatch = useDispatch();

		const loading = useSelector((state) => state.component.tableLoading);

		const [page, setPage] = useState(DefaultPagination);

		const setCurrentPage = (page) => {
			setPage((prev) => ({ ...prev, pageNo: page }));
		};

		const setPerPage = (perPage) => {
			setPage((prev) => ({ ...prev, limit: perPage }));
		};

		useImperativeHandle(ref, () => ({
			getPage: () => page,
			setPage: (page) => setCurrentPage(page),
		}));

		useEffect(() => {
			let mounted = true;
			const fetchTableData = () => {
				if (mounted) {
					dispatchCall && dispatch(toggleTableLoading(true));
					if (module) {
						isPermitted(
							module,
							'read',
							() =>
								dispatchCall &&
								dispatch(
									dispatchCall(
										{
											...page,
											...queryParams,
										},
										dispatchParams,
									),
								),
							null,
							false,
						);
					} else {
						dispatchCall &&
							dispatch(
								dispatchCall(
									{
										...page,
										...queryParams,
									},
									dispatchParams,
								),
							);
					}
				}
			};

			fetchTableData();

			return () => {
				mounted = false;
			};
		}, [page]);

		return (
			<>
				<CardBody
					className={classNames(`p-0 table-responsive height-${height}`, {
						'display-flex': loading,
					})}
					style={style}
					isScrollable>
					{loading ? (
						<Loader primary />
					) : (
						<table className='table table-modern'>
							<thead>
								<tr>
									{headers?.map((it) => (
										<th
											style={{
												position: 'sticky',
												top: 0,
												background: '#f5f5f5',
												borderRadius: 0,
												zIndex: 2,
											}}>
											{it.name}
										</th>
									))}
								</tr>
							</thead>
							<tbody>
								{renderingData?.length > 0 ? (
									<>
										{renderingData?.map((row, idx) => (
											<tr
												key={idx}
												style={row.color ? { background: row.color } : {}}>
												{headers?.map((it, i) =>
													it.renderCell ? (
														<td key={i} className='rounded-0 py-2'>
															{it.renderCell(row, idx)}
														</td>
													) : (
														<td key={i} className='rounded-0 py-2'>
															{row[it.key] || '-'}
														</td>
													),
												)}
											</tr>
										))}
										{footer.length > 0 && (
											<tr>
												{headers?.map((it, i) =>
													footer.includes(it.name) ? (
														<th key={i}>
															{renderingData
																?.map((row, idx) =>
																	it.renderCell
																		? it.renderCell(row, idx) ||
																		  0
																		: row[it.key] || 0,
																)
																?.reduce(
																	(a, b) => a + parseFloat(b),
																	0,
																)
																.toFixed(2)}
														</th>
													) : (
														<td />
													),
												)}
											</tr>
										)}
									</>
								) : (
									<tr>
										<td
											colSpan={headers?.length}
											className='text-center rounded-0'>
											<em className='text-muted'>No Record found</em>
										</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
				</CardBody>
				{pagination && (
					<>
						<PaginationButtons
							setCurrentPage={setCurrentPage}
							setPerPage={setPerPage}
							perPage={page.limit}
							currentPage={page.pageNo}
							totalItems={total}
							label={label}
						/>
					</>
				)}
			</>
		);
	},
);

export default Table;

export const Action = ({ title, icon, onClickFunction, color = 'primary' }) => {
	const { darkModeStatus } = useDarkMode();
	return (
		<Tooltips title={title}>
			<Button
				isOutline={!darkModeStatus}
				color={title == 'Delete' ? 'danger' : color}
				isLight={darkModeStatus}
				className={classNames('text-nowrap', {
					'border-light': !darkModeStatus,
				})}
				icon={icon}
				onClick={(params) => onClickFunction(params)}
			/>
		</Tooltips>
	);
};

export const NonPaginated = forwardRef(
	(
		{
			headers,
			renderingData,
			height = 'auto',
			dispatchCall = null,
			queryParams = {},
			dispatchParams = null,
		},
		ref,
	) => {
		const dispatch = useDispatch();

		useEffect(() => {
			let mounted = true;
			const fetchTableData = () => {
				if (mounted) {
					if (typeof dispatchCall == 'function') {
						isPermitted(
							module,
							'read',
							() => {
								dispatch(toggleTableLoading(true));
								dispatch(dispatchCall({ ...queryParams }, dispatchParams));
							},
							null,
							false,
						);
					}
				}
			};

			fetchTableData();

			return () => {
				mounted = false;
			};
		}, []);

		return (
			<>
				<CardBody
					className={`p-2 table-responsive height-${height}`}
					style={{ height: height }}
					isScrollable>
					<table className='table table-modern'>
						<thead>
							<tr>
								{headers?.map((it) => (
									<th
										style={{
											position: 'sticky',
											top: 0,
											background: '#f5f5f5',
											zIndex: 2,
										}}>
										{it.name}
									</th>
								))}
							</tr>
						</thead>
						<tbody>
							{renderingData?.length > 0 ? (
								renderingData?.map((row, idx) => (
									<tr key={idx}>
										{headers?.map((it) =>
											it.renderCell ? (
												<td>{it.renderCell(row, idx)}</td>
											) : (
												<>
													<td>{GetNestedValue(row, it.key, '-')}</td>
												</>
											),
										)}
									</tr>
								))
							) : (
								<tr>
									<td colSpan={headers?.length} className='text-center'>
										<em className='text-muted'>No Record found</em>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</CardBody>
			</>
		);
	},
);
