import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { deleteChemoNote, getChemoNotes } from '../../../../actions/helperActions';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';

import AddNewChemoNote from './AddNewChemoNote';

const DeleteWarnModal = ({ deleteWarn, setDeleteWarn, handleDeleteConfirm }) => {
	return (
		<Modal isOpen={deleteWarn} setIsOpen={setDeleteWarn} isCentered isAnimation={false}>
			<ModalHeader setIsOpen={setDeleteWarn}>
				<ModalTitle>Warning</ModalTitle>
			</ModalHeader>
			<ModalBody>Are you sure you want to delete the Chemo Note?</ModalBody>
			<ModalFooter>
				<Button
					color='primary'
					isOutline
					className='border-0'
					onClick={() => setDeleteWarn(false)}>
					Cancel
				</Button>
				<Button color='danger' icon='delete' onClick={handleDeleteConfirm}>
					Delete
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const ChemoNote = ({ chemoNote, userId, setDeleteWarn, setDeleteId }) => {
	const navigate = useNavigate();

	const doctors = useSelector((state) => state.doctor.doctors);

	return (
		<Card className='shadow-none border-1 rounded-1'>
			<CardBody>
				{/* <div className='col-12 row my-2'>
					<div className='col-sm-2'>IPD</div>
					<div className='col-sm-10'>IPD NAME</div>
				</div> */}

				{/* Chemo Administration Note */}
				<div className='row'>
					<div className='col-11'>
						<h5>
							<u>Chemo Administration Note</u>
						</h5>
					</div>

					<div className='col-1 d-flex justify-content-end'>
						<Tooltips title='Print'>
							<Button
								color='primary'
								icon='Print'
								isLink
								onClick={() =>
									navigate(`/patients/${userId}/chemo-note-pdf`, {
										state: {
											chemoNote,
										},
									})
								}
							/>
						</Tooltips>
						<Tooltips title='Delete'>
							<Button
								color='danger'
								icon='Delete'
								isLink
								onClick={() => {
									setDeleteId(chemoNote?.id);
									setDeleteWarn(true);
								}}
							/>
						</Tooltips>
					</div>
				</div>

				<div className='col-12 row my-3'>
					<div className='col-sm-2'>
						<b>Plan</b>
					</div>
					<div className='col-sm-10'>{chemoNote?.Chemo_plans?.[0]?.name}</div>
				</div>
				<div className='col-12 row my-3'>
					<div className='col-sm-2'>
						<b>Diagnosis</b>
					</div>
					<div className='col-sm-10'>
						{chemoNote?.Chemo_diagnoses?.[0]?.Diagnosis?.name}
					</div>
				</div>

				{/* <div className='col-12 row my-3'>
						<div className='col-sm-2'>Height</div>
						<div className='col-sm-10'>'Height Value'</div>
					</div>

					<div className='col-12 row my-3'>
						<div className='col-sm-2'>Weight</div>
						<div className='col-sm-10'>'Weight Value'</div>
					</div> */}

				<div className='col-12 row my-3'>
					<div className='col-sm-2'>
						<b>Date</b>
					</div>
					<div className='col-sm-10'>
						{chemoNote?.date ? moment(chemoNote?.date)?.format('DD-MM-YYYY') : ''}
					</div>
				</div>

				<div className='col-12 row my-3'>
					<div className='col-sm-2'>
						<b>BSA</b>
					</div>
					<div className='col-sm-10'>{chemoNote?.bsa}</div>
				</div>

				<div className='col-12 row my-3'>
					<div className='col-sm-2'>
						<b>Cycle</b>
					</div>
					<div className='col-sm-10'>{chemoNote?.cycle}</div>
				</div>

				{/* Investigation Table */}
				<table className='table table-bordered border-dark my-4'>
					<tbody>
						<tr>
							<th colSpan={7}>Investigation</th>
							<th colSpan={2}></th>
						</tr>
						<tr>
							<th></th>
							<th>LFT</th>
							<th>KFT</th>
							<th>CBC DIFF</th>
							<th>Viral Marker</th>
							<th>ECHO</th>
							<th>FAMILY Counseling</th>
							<th>IV Access/Site</th>
						</tr>
						<tr>
							<th>Investigation</th>
							<td>
								{chemoNote?.Chemo_investigations?.[0]?.lft
									? 'Acceptable'
									: 'Not Acceptable'}
							</td>
							<td>
								{chemoNote?.Chemo_investigations?.[0]?.kft
									? 'Acceptable'
									: 'Not Acceptable'}
							</td>
							<td>
								{chemoNote?.Chemo_investigations?.[0]?.cbc
									? 'Acceptable'
									: 'Not Acceptable'}
							</td>
							<td>
								{chemoNote?.Chemo_investigations?.[0]?.viralMaker
									? 'Acceptable'
									: 'Not Acceptable'}
							</td>
							<td>
								{chemoNote?.Chemo_investigations?.[0]?.echo
									? 'Acceptable'
									: 'Not Acceptable'}
							</td>
							<td>
								{chemoNote?.Chemo_investigations?.[0]?.familyCounseling
									? 'Acceptable'
									: 'Not Acceptable'}
							</td>
							<td>
								{chemoNote?.Chemo_investigations?.[0]?.ivAccess
									? 'Acceptable'
									: 'Not Acceptable'}
							</td>
						</tr>
					</tbody>
				</table>

				<div className='col-12 row my-3'>
					<div className='col-sm-2'>
						<b>Day</b>
					</div>
					<div className='col-sm-10'>{moment(chemoNote.date)?.format('dddd')}</div>
				</div>

				{/* 		<div className='col-12'>
								<FormGroup
									label={'On'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input placeholder='On' />
								</FormGroup>
							</div> */}

				<div className='col-12 row my-3'>
					<div className='col-sm-2'>
						<b>Specific Instruction</b>
					</div>
					<div className='col-sm-10'>{chemoNote?.specificInstuction}</div>
				</div>

				<h5 className='my-4'>
					<u>Pre Medication Dose</u>
				</h5>
				{/* PreMedicatoin Table */}
				<table className='table table-bordered border-dark my-4'>
					<tbody>
						<tr>
							<th colSpan={5}>Premedication</th>
							<th colSpan={2}></th>
						</tr>
						<tr>
							<th>Medicine</th>
							<th>Checked by</th>
							<th>Prepared by</th>
							<th>Administered by</th>
							<th>Pharmacist's Review</th>
							<th>Start Time</th>
							<th>End Time</th>
						</tr>
						{chemoNote.Chemo_premedication_doses?.map?.((i, idx) => {
							const checkedBy =
								doctors.find((j) => j.id === i.checkedBy)?.Vendor_detail?.name ||
								'-';
							const preparedBy =
								doctors.find((j) => j.id === i.preparedBy)?.Vendor_detail?.name ||
								'-';
							const administredBy =
								doctors.find((j) => j.id === i.administredBy)?.Vendor_detail
									?.name || '-';

							return (
								<tr key={idx}>
									<th>{i.Medicine?.name}</th>
									<td>{checkedBy}</td>
									<td>{preparedBy}</td>
									<td>{administredBy}</td>
									<td>{i.pharmacistReview ? 'Acceptable' : 'Not Acceptable'}</td>
									<td>{i.startTime}</td>
									<td>{i.endTime}</td>
								</tr>
							);
						})}
					</tbody>
				</table>

				{/* Chemotherapy Table */}
				<table className='table table-bordered border-dark my-4'>
					<tbody>
						<tr>
							<th colSpan={5}>Chemotherapy Dose</th>
							<th colSpan={2}></th>
						</tr>
						<tr>
							<th>Medicine</th>
							<th>Checked by</th>
							<th>Prepared by</th>
							<th>Administered by</th>
							<th>Pharmacist's Review</th>
							<th>Start Time</th>
							<th>End Time</th>
						</tr>
						{chemoNote.Chemo_theraphy_doses?.map?.((i, idx) => {
							const checkedBy =
								doctors.find((j) => j.id === i.checkedBy)?.Vendor_detail?.name ||
								'-';
							const preparedBy =
								doctors.find((j) => j.id === i.preparedBy)?.Vendor_detail?.name ||
								'-';
							const administredBy =
								doctors.find((j) => j.id === i.administredBy)?.Vendor_detail
									?.name || '-';

							return (
								<tr key={idx}>
									<th>{i.Medicine?.name}</th>
									<td>{checkedBy}</td>
									<td>{preparedBy}</td>
									<td>{administredBy}</td>
									<td>{i.pharmacistReview ? 'Acceptable' : 'Not Acceptable'}</td>
									<td>{i.startTime}</td>
									<td>{i.endTime}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</CardBody>
		</Card>
	);
};

const ChemoNotes = ({ userId, ipdFile }) => {
	const [addNewModal, setAddNewModal] = useState(false);
	const [deleteWarnModal, setDeleteWarnModal] = useState(false);
	const [deleteId, setDeleteId] = useState(false);
	const handleDeleteConfirm = async () => {
		await deleteChemoNote(deleteId);
		setData(await getChemoNotes(userId));
		setDeleteWarnModal(false);
	};

	const [data, setData] = useState([]);

	useEffect(() => {
		let mounted = true;
		const fetchOthers = async () => {
			if (mounted) {
				setData(await getChemoNotes(userId));
			}
		};
		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Card stretch className='shadow-none'>
			<CardHeader borderSize={1}>
				<CardLabel icon='Feed' iconColor='primary'>
					<CardTitle>Chemo Notes</CardTitle>
				</CardLabel>
				<CardActions>
					<Button color='primary' icon='Add' isLight onClick={() => setAddNewModal(true)}>
						Add New
					</Button>
				</CardActions>
			</CardHeader>
			<CardBody className='table-responsive'>
				{data.map((i) => (
					<ChemoNote
						chemoNote={i}
						userId={userId}
						setDeleteWarn={setDeleteWarnModal}
						setDeleteId={setDeleteId}
					/>
				))}
			</CardBody>
			<AddNewChemoNote
				isOpen={addNewModal}
				setOpen={setAddNewModal}
				userId={userId}
				ipdFile={ipdFile}
				setData={setData}
			/>
			<DeleteWarnModal
				deleteWarn={deleteWarnModal}
				setDeleteWarn={setDeleteWarnModal}
				handleDeleteConfirm={handleDeleteConfirm}
			/>
		</Card>
	);
};

export default ChemoNotes;
