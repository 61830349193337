import {
	DELETE_IPD_CHIEF_COMPLAINT,
	DELETE_IPD_CONSUMABLE,
	DELETE_IPD_DIAGNOSIS,
	DELETE_IPD_INVESTIGATION,
	DELETE_IPD_MEDICINE,
	DELETE_IPD_PROCEDURE,
	DELETE_IPD_VISIT_NOTE,
	DELETE_IPD_WHITEBOARD,
	DELETE_PHYSICAL_EXAMINATIONS,
	LOADING_IPD_PATIENT,
	SET_BILL_VISITING_DOCTOR,
	SET_DISCHARGE_SUMMARY,
	SET_GYNAE_DATA,
	SET_IPDFILES,
	SET_IPD_ALLERGY,
	SET_IPD_BILL_DETAILS,
	SET_IPD_CHIEF_COMPLAINTS,
	SET_IPD_CONSUMABLES,
	SET_IPD_DIAGNOSIS,
	SET_IPD_GYNAE_DATA,
	SET_IPD_HISTORY,
	SET_IPD_INVESTIGATIONS,
	SET_IPD_LABOUR_RECORD,
	SET_IPD_MEDICINES,
	SET_IPD_PROCEDURES,
	SET_IPD_REFUND,
	SET_IPD_TPA,
	SET_IPD_VISIT_NOTES,
	SET_IPD_WHITEBOARD,
	SET_PHYSICAL_EXAMINATIONS,
} from '../reducers/types';
import { getPatientIpdFile, getVisitingDoctorSuggestions } from './ipdActions';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { toggleCreateLoading } from './componentActions';

export const getIpdChiefComplaints = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-cheif-complaints/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_CHIEF_COMPLAINTS,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const addIpdChiefComplaint = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/ipd/ipd-add-cheif-complaints', data);
		if (res.data.success) {
			dispatch(getIpdChiefComplaints(data.ipdId));
			showNotification('Success', 'The chief complaint is added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const editIpdChiefComplaint = (data, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put('/doctor/ipd/ipd-update-cheif-complaints', data);
		if (res.data.success) {
			dispatch(getIpdChiefComplaints(ipdId));
			showNotification('Success', 'The chief complaint is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIpdChiefComplaint = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/ipd-delete-cheif-complaints/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_IPD_CHIEF_COMPLAINT,
				payload: id,
			});
			// showNotification(
			// 	'Success',
			// 	'The chief complaint has been removed successfully',
			// 	'success',
			// );
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdVisitNotes = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd-notes/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_VISIT_NOTES,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const addIpdVisitNote = (formdata, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/ipd-notes', formdata);
		if (res.data.success) {
			dispatch(getIpdVisitNotes(ipdId));
			// showNotification('Success', 'The chief complaint is added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateIpdVisitNote = (formdata, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch('/doctor/ipd-notes', formdata);
		if (res.data.success) {
			dispatch(getIpdVisitNotes(ipdId));
			// showNotification('Success', 'The chief complaint is added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIpdVisitNote = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd-notes/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_IPD_VISIT_NOTE,
				payload: id,
			});
			// showNotification(
			// 	'Success',
			// 	'The chief complaint has been removed successfully',
			// 	'success',
			// );
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdConsumables = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-consummables/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_CONSUMABLES,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const addIpdConsumable = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/ipd/ipd-add-consummables', data);
		if (res.data.success) {
			dispatch(getIpdConsumables(data.ipdId));
			// showNotification('Success', 'The chief complaint is added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateIpdConsumable = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put('/doctor/ipd/ipd-update-consummables', data);
		if (res.data.success) {
			dispatch(getIpdConsumables(data.ipdId));
			// showNotification('Success', 'The chief complaint is added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIpdConsumable = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/ipd-delete-consummables/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_IPD_CONSUMABLE,
				payload: id,
			});
			// showNotification(
			// 	'Success',
			// 	'The chief complaint has been removed successfully',
			// 	'success',
			// );
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdWhiteboard = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-whiteboard/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_WHITEBOARD,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createIpdWhiteBoard = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-whiteboard`, data);
		if (res.data.success) {
			dispatch(getIpdWhiteboard(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateIpdWhiteBoard = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/ipd-update-whiteboard`, data);
		if (res.data.success) {
			dispatch(getIpdWhiteboard(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIpdWhiteBoard = (whiteBoardId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/ipd-delete-whiteboard/${whiteBoardId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_IPD_WHITEBOARD,
				payload: whiteBoardId,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdMedicines = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-medicine/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_MEDICINES,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addIpdMedicines = (data, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-medicine`, data);
		if (res.data.success) {
			showNotification('Success', 'The medicine is added successfully', 'success');
			dispatch(getIpdMedicines(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const stopIpdMedicine = (data, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/ipd-stop-medicine`, data);
		if (res.data.success) {
			dispatch(getIpdMedicines(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteMedicine = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-delete-medicine`, data);
		if (res.data.success) {
			dispatch({ type: DELETE_IPD_MEDICINE, payload: data?.ipdMedicineIds?.[0] });
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdInvestigations = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-investigation/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_INVESTIGATIONS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addIpdInvestigation = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-investigation`, data);
		if (res.data.success) {
			dispatch(getIpdInvestigations(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateIpdInvestigation = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/ipd-update-investigation`, data);
		if (res.data.success) {
			dispatch(getIpdInvestigations(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIpdInvestigation = (id, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/ipd-delete-investigation/${id}`);
		if (res.data.success) {
			// dispatch({
			// 	type: DELETE_IPD_INVESTIGATION,
			// 	payload: id,
			// });
			dispatch(getIpdInvestigations(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdProcedures = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-procedures/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_PROCEDURES,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addIpdProcedure = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-procedures`, data);
		if (res.data.success) {
			dispatch(getIpdProcedures(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateIpdProcedure = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/ipd-update-procedures`, data);
		if (res.data.success) {
			dispatch(getIpdProcedures(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIpdProcedure = (id, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/ipd-delete-procedures/${id}`);
		if (res.data.success) {
			// dispatch({
			// 	type: DELETE_IPD_PROCEDURE,
			// 	paylaod: id,
			// });
			dispatch(getIpdProcedures(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPhysicalExaminations = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-physical-examination/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PHYSICAL_EXAMINATIONS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addPhysicalExamination = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-physical-examination`, data);
		if (res.data.success) {
			dispatch(getPhysicalExaminations(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePhysicalExamination = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/ipd-update-physical-examination`, data);
		if (res.data.success) {
			dispatch(getPhysicalExaminations(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deletePhysicalExaminations = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`doctor/ipd/ipd-delete-physical-examination/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_PHYSICAL_EXAMINATIONS,
				payload: id,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdDiagnosis = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-get-diagnosis/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_DIAGNOSIS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addIpdDiagnosis = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-diagnosis`, data);
		if (res.data.success) {
			dispatch(getIpdDiagnosis(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIpdDiagnosis = (id) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/ipd-delete-diagnosis/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_IPD_DIAGNOSIS,
				payload: id,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdHistory = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`doctor/ipd/ipd-get-patient-history/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_HISTORY,
				payload: res.data.data?.[0],
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addSurgicalHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-update-patient-surgical-history`, data);
		if (res.data.success) {
			dispatch(getIpdHistory(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addPastHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-update-patient-past-history`, data);
		if (res.data.success) {
			dispatch(getIpdHistory(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addSocialHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-update-patient-social-history`, data);
		if (res.data.success) {
			dispatch(getIpdHistory(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addFamilyHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-update-patient-family-history`, data);
		if (res.data.success) {
			dispatch(getIpdHistory(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addDrugHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-update-patient-drug-history`, data);
		if (res.data.success) {
			dispatch(getIpdHistory(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdAllergy = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`doctor/ipd/ipd-get-patient-allergy/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_ALLERGY,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addIpdAllergy = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/ipd-add-update-patient-allergy`, data);
		if (res.data.success) {
			dispatch(getIpdAllergy(data.ipdId));
			showNotification('Success', 'Allergy saved successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdBillDetails = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`doctor/ipd/bill/ipd-get-payment-detail/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_BILL_DETAILS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addBillProcedure = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/ipd/bill/ipd-bill-add-procedures', data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateBillProcedure = (data, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/bill/ipd-bill-update-procedures`, data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addBillNursingItem = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/ipd/bill/ipd-bill-add-nursing`, data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateBillNursingItem = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/bill/ipd-bill-update-nursing`, data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const changePatientStatus = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/ipd-update-patient-status`, data);
		if (res.data.success) {
			dispatch(getPatientIpdFile(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addBillInvestigation = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('doctor/ipd/bill/ipd-bill-add-investigation', data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateBillInvestigation = (data, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put('doctor/ipd/bill/ipd-bill-update-investigation', data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addBillOther = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('doctor/ipd/bill/ipd-bill-add-other', data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteBillOther = (id, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/bill/ipd-bill-delete-other/${id}`);
		if (res.data.success) {
			dispatch(getIpdBillDetails(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addBillPayment = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/ipd/bill/ipd-add-payment', data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteBillPayment = (id, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/bill/ipd-delete-payment/${id}`);
		if (res.data.success) {
			dispatch(getIpdBillDetails(ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getGynaeData =
	(userId, isOpd = false) =>
	async (dispatch) => {
		try {
			const res = await axios.get(`/gynae?userId=${userId}`);
			if (res.data.success) {
				if (isOpd) {
					dispatch({
						type: SET_GYNAE_DATA,
						payload: res.data.data?.list,
					});
				} else
					dispatch({
						type: SET_IPD_GYNAE_DATA,
						payload: res.data.data?.list,
					});
			}
		} catch (error) {
			console.error(error);
		}
	};

export const createGynaeData =
	(data, isOpd = false) =>
	async (dispatch) => {
		dispatch(toggleCreateLoading(true));
		try {
			const res = await axios.put(`/gynae?userId=${data?.userId}`, data);
			if (res.data.success) {
				dispatch(getGynaeData(data?.userId, isOpd));
				showNotification('Success', 'Gynae details saved successfully', 'success');
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const getLabourRecord = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/gynae/get-labour-record/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_LABOUR_RECORD,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createLabourRecord = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/gynae/labour-record`, data);
		if (res.data.success) {
			dispatch(getLabourRecord(data?.ipdId));
			showNotification('Success', 'Labour record saved successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getDischargeSummary = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`doctor/ipd/discharge/detail/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_DISCHARGE_SUMMARY,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createDischargeSummary = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/discharge/detail`, data);
		if (res.data.success) {
			dispatch(getDischargeSummary(data?.ipdId));
			showNotification('Success', 'Discharge summary saved successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const dischargePatient = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/discharge`, data);
		if (res.data.success) {
			dispatch(getPatientIpdFile(data?.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIPDTPA = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/tpa/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_TPA,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const createIPDTPA = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/tpa`, data);
		if (res.data.success) {
			showNotification('Success', 'The TPA created successfully', 'success');
			dispatch(getIPDTPA(data.ipdId));
		}
	} catch (error) {
		console.log(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updateIPDTPA = (data, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/tpa`, data);
		if (res.data.success) {
			showNotification('Success', 'The TPA updated successfully', 'success');
			dispatch(getIPDTPA(ipdId));
		}
	} catch (error) {
		console.log(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteIPDTPA = (id, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/doctor/ipd/tpa/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The TPA deleted successfully', 'success');
			dispatch(getIPDTPA(ipdId));
		}
	} catch (error) {
		console.log(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getIpdPatientHistory = (patientId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/${patientId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPDFILES,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const addBillVisitingDoctor = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/bill/ipd-bill-add-visiting-doctor`, data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data.ipdId));
			dispatch(getVisitingDoctorSuggestions(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addIpdBill = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/bill/ipd-bill-add`, data);
		if (res.data.success) {
			dispatch(getIpdBillDetails(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const addConsummableChargestoOtherChargesBill =
	(ipdId, setConsummableChargeList) => async (dispatch) => {
		dispatch(toggleCreateLoading(true));
		try {
			const res = await axios.get(`/doctor/ipd/bill/ipd-bill-add-consummable/${ipdId}`);

			if (res.data.success) {
				dispatch(getIpdBillDetails(ipdId));
				setConsummableChargeList([]);
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const updateBillType = async (isEst, ipdBillId) => {
	try {
		const res = await axios.put(`/doctor/ipd/bill/ipd-bill-update-type`, { isEst, ipdBillId });
		return res.data.success;
	} catch (error) {
		console.error(error);
	}
};

export const getRefunds = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/bill/ipd-bill-refund-amount-list/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_REFUND,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createRefund = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/ipd/bill/ipd-bill-refund-amount`, data);
		if (res.data.success) {
			dispatch(getRefunds(data.ipdId));
			showNotification('Success', 'Refunds created.', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteRefund = (refundId, ipdId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(
			`/doctor/ipd/bill/ipd-bill-refund-amount-delete/${refundId}`,
		);
		if (res.data.success) {
			dispatch(getRefunds(ipdId));
			showNotification('Success', 'Refunds deleted.', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};
