import React, { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import DoctorListing from './DoctorListing';
import Specialities from './Specialities';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorBySpeciality, getSpecialities } from '../../../../actions/specialityActions';
import UserFileSharing from './UserFileSharing';
const MODES = {
	SPECIALITIES: 'specialities',
	DOCTORS: 'doctors',
	FILE_SHARING: 'file_sharing',
};
const ConsultWithExpert = () => {
	const [mode, setMode] = useState(MODES.SPECIALITIES);
	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [selectedSpeciality, setSelectedSpeciality] = useState(null);
	
	const changeModeToDoctorListing = (speciality) => {
		setSelectedSpeciality(speciality);
		setMode(MODES.DOCTORS);
	};
	const changeModeToFileSharing = (doctor) => {
		setSelectedDoctor(doctor);
		console.log(doctor, 'doctor');
		setMode(MODES.FILE_SHARING);
	};

	const dispatch = useDispatch();
	const specialities = useSelector((state) => state.speciality.specialities);
	useEffect(() => {
		let mounted = true;
		const fetchOthers = () => {
			if (mounted) {
				dispatch(getSpecialities());
			}
		};
		fetchOthers();
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<div className='container-fluid'>
			<div className='row'>
				<div className='col-12'>
					<div className='card shadow-none'>
						<div className='card-body'>
							<div className='row'>
								<div className='col-12'>
									{mode === 'specialities' ? (
										<>
											<h4 className='card-title'>Specialities</h4>
											<Specialities
												specialities={specialities}
												selectedSpeciality={selectedSpeciality}
												setSelectedSpeciality={setSelectedSpeciality}
												changeModeToDoctorListing={
													changeModeToDoctorListing
												}
											/>
										</>
									) : mode === 'doctors' ? (
										<>
											<div
												className='d-flex'
												style={{ alignItems: 'baseline' }}>
												<Button
													isLink
													icon='ArrowBack'
													color='primary'
													onClick={() => {
														setMode(MODES.SPECIALITIES);
													}}></Button>
												<h4 className='card-title ml-2'>Doctors</h4>
											</div>
											<DoctorListing
												selectedSpeciality={selectedSpeciality}
												changeModeToFileSharing={changeModeToFileSharing}
											/>
										</>
									) : (
										<>
											<div
												className='d-flex'
												style={{ alignItems: 'baseline' }}>
												<Button
													isLink
													icon='ArrowBack'
													color='primary'
													onClick={() => {
														setMode(MODES.DOCTORS);
													}}></Button>
												<h4 className='card-title ml-2'>File Sharing</h4>
											</div>
											<UserFileSharing doctor={selectedDoctor} />
										</>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConsultWithExpert;
