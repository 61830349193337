import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JoditEditor from 'jodit-react';
import Select from 'react-select';

import Card, { CardBody, CardTabItem } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import {
	addDrugHistory,
	addFamilyHistory,
	addPastHistory,
	addSocialHistory,
	addSurgicalHistory,
	getIpdHistory,
} from '../../../actions/ipdPatientActions';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { addHistoryTemplate, getHistoryTemplates } from '../../../actions/ipdActions';
import Checks from '../../../components/bootstrap/forms/Checks';
import Input from '../../../components/bootstrap/forms/Input';
import { isPermitted } from '../../../actions/helperActions';

const IpdHistory = () => {
	const dispatch = useDispatch();
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			dispatch(getIpdHistory(ipdId));
			dispatch(getHistoryTemplates());
		}
	}, []);

	return (
		<Card hasTab stretch className='shadow-none'>
			<CardTabItem id='pastHistory' title='Past history'>
				<PastHistory editItem={history?.past} />
			</CardTabItem>
			<CardTabItem id='socialHistory' title='Social History'>
				<SocialHistory editItem={history?.social} />
			</CardTabItem>
			<CardTabItem id='drugHistory' title='Drug History'>
				<DrugHistory editItem={history?.drug} />
			</CardTabItem>
			<CardTabItem id='familyHistory' title='Family History'>
				<FamilyHistory editItem={history?.family} />
			</CardTabItem>
			<CardTabItem id='surgicalHistory' title='Surgical History'>
				<SurgicaHistory editItem={history?.surgical} />
			</CardTabItem>
		</Card>
	);
};

const SurgicaHistory = () => {
	const { darkModeStatus } = useDarkMode();
	const phEditor = useRef(null);
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const history = useSelector((state) => state.ipdPatient.history?.surgical);
	const historyTemplates = useSelector((state) => state.ipd.historyTemplate);

	const [tempModal, setTempModal] = useState(false);
	const [tempName, setTempName] = useState(null);
	const [addTemplate, setAddTemplate] = useState(false);
	const [pastHistory, setPastHistory] = useState(history || '');

	const handleSubmit = () => {
		if (addTemplate && !tempName) {
			return;
		}
		if (addTemplate) {
			dispatch(
				addHistoryTemplate({
					type: 'SURGICAL',
					name: tempName,
					template: pastHistory,
				}),
			);
			setAddTemplate(false);
			setTempName(null);
		}
		dispatch(addSurgicalHistory({ ipdId, surgical: pastHistory }));
	};

	const loadTemplate = (value) => {
		setPastHistory(value);
		setTempModal(false);
	};

	return (
		<>
			<CardBody isScrollable className='d-flex flex-column h-100'>
				<div className='row'>
					<div className='col-9 mb-3'>
						<h3>Surgical History</h3>
					</div>
					<div className='col-3 mb-3'>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='save'
							aria-label='Create Treatment'
							onClick={() => {
								setTempModal(true);
							}}>
							Load template
						</Button>
					</div>
					<div className='col-12'>
						<JoditEditor
							config={{
								defaultActionOnPaste: 'insert_as_html',
								height: '50vh',
							}}
							ref={phEditor}
							value={pastHistory}
							tabIndex={1}
							onBlur={(newContent) => setPastHistory(newContent)}
						/>
					</div>
					<div className='col-2 mt-3'>
						<Checks
							id='template'
							label='Save as Template'
							checked={addTemplate}
							onChange={(e) => setAddTemplate(!addTemplate)}
						/>{' '}
					</div>
					{addTemplate && (
						<div className='col-3 mt-3'>
							<Input
								value={tempName}
								onChange={(e) => setTempName(e.target.value)}
								className='form-control'
								placeholder='Enter Template Name'
							/>
						</div>
					)}
					<div className='col-1 mt-3'>
						<Button
							isOutline={darkModeStatus}
							color='primary'
							className='border-0'
							onClick={() =>
								isPermitted('Ipd', 'write', handleSubmit, 'Patient History')
							}>
							Save
						</Button>
					</div>
				</div>
			</CardBody>
			<Modal
				isOpen={tempModal}
				setIsOpen={setTempModal}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setTempModal}>
					<ModalTitle id='deletePharmLabel'>Load Template</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='name' label='Choose/Select Template name'>
						<Select
							id='name'
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
								}),
							}}
							value={tempName}
							options={historyTemplates
								?.filter((x) => x?.type == 'SURGICAL')
								.map((x) => ({ label: x?.name, value: x?.template }))}
							onChange={(i) => loadTemplate(i.value)}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setTempModal(false);
						}}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const PastHistory = () => {
	const { darkModeStatus } = useDarkMode();
	const phEditor = useRef(null);
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const history = useSelector((state) => state.ipdPatient.history?.past);
	const historyTemplates = useSelector((state) => state.ipd.historyTemplate);

	const [tempModal, setTempModal] = useState(false);
	const [tempName, setTempName] = useState(null);
	const [addTemplate, setAddTemplate] = useState(false);
	const [pastHistory, setPastHistory] = useState(history || '');

	const handleSubmit = () => {
		if (addTemplate && !tempName) {
			return;
		}
		if (addTemplate) {
			dispatch(
				addHistoryTemplate({
					type: 'PAST',
					name: tempName,
					template: pastHistory,
				}),
			);
			setAddTemplate(false);
			setTempName(null);
		}
		dispatch(addPastHistory({ ipdId, past: pastHistory }));
	};

	const loadTemplate = (value) => {
		setPastHistory(value);
		setTempModal(false);
	};

	return (
		<>
			<CardBody isScrollable className='d-flex flex-column h-100'>
				<div className='row'>
					<div className='col-9 mb-3'>
						<h3>Past History</h3>
					</div>
					<div className='col-3 mb-3'>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='save'
							aria-label='Create Treatment'
							onClick={() => {
								setTempModal(true);
							}}>
							Load template
						</Button>
					</div>
					<div className='col-12'>
						<JoditEditor
							config={{
								defaultActionOnPaste: 'insert_as_html',
								height: '50vh',
							}}
							ref={phEditor}
							value={pastHistory}
							tabIndex={1}
							onBlur={(newContent) => setPastHistory(newContent)}
						/>
					</div>
					<div className='col-2 mt-3'>
						<Checks
							id='template'
							label='Save as Template'
							checked={addTemplate}
							onChange={(e) => setAddTemplate(!addTemplate)}
						/>{' '}
					</div>
					{addTemplate && (
						<div className='col-3 mt-3'>
							<Input
								value={tempName}
								onChange={(e) => setTempName(e.target.value)}
								className='form-control'
								placeholder='Enter Template Name'
							/>
						</div>
					)}
					<div className='col-1 mt-3'>
						<Button
							isOutline={darkModeStatus}
							color='primary'
							className='border-0'
							onClick={() =>
								isPermitted('Ipd', 'write', handleSubmit, 'Patient History')
							}>
							Save
						</Button>
					</div>
				</div>
			</CardBody>
			<Modal
				isOpen={tempModal}
				setIsOpen={setTempModal}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setTempModal}>
					<ModalTitle id='deletePharmLabel'>Load Template</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='name' label='Choose/Select Template name'>
						<Select
							id='name'
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
								}),
							}}
							value={tempName}
							options={historyTemplates
								?.filter((x) => x?.type == 'PAST')
								.map((x) => ({ label: x?.name, value: x?.template }))}
							onChange={(i) => loadTemplate(i.value)}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setTempModal(false);
						}}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const DrugHistory = () => {
	const { darkModeStatus } = useDarkMode();
	const phEditor = useRef(null);
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const history = useSelector((state) => state.ipdPatient.history?.drug);
	const historyTemplates = useSelector((state) => state.ipd.historyTemplate);

	const [tempModal, setTempModal] = useState(false);
	const [tempName, setTempName] = useState(null);
	const [addTemplate, setAddTemplate] = useState(false);
	const [pastHistory, setPastHistory] = useState(history || '');

	const handleSubmit = () => {
		if (addTemplate && !tempName) {
			return;
		}
		if (addTemplate) {
			dispatch(
				addHistoryTemplate({
					type: 'DRUG',
					name: tempName,
					template: pastHistory,
				}),
			);
			setAddTemplate(false);
			setTempName(null);
		}
		dispatch(addDrugHistory({ ipdId, drug: pastHistory }));
	};

	const loadTemplate = (value) => {
		setPastHistory(value);
		setTempModal(false);
	};

	return (
		<>
			<CardBody isScrollable className='d-flex flex-column h-100'>
				<div className='row'>
					<div className='col-9 mb-3'>
						<h3>Drug History</h3>
					</div>
					<div className='col-3 mb-3'>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='save'
							aria-label='Create Treatment'
							onClick={() => {
								setTempModal(true);
							}}>
							Load template
						</Button>
					</div>
					<div className='col-12'>
						<JoditEditor
							config={{
								defaultActionOnPaste: 'insert_as_html',
								height: '50vh',
							}}
							ref={phEditor}
							value={pastHistory}
							tabIndex={1}
							onBlur={(newContent) => setPastHistory(newContent)}
						/>
					</div>
					<div className='col-2 mt-3'>
						<Checks
							id='template'
							label='Save as Template'
							checked={addTemplate}
							onChange={(e) => setAddTemplate(!addTemplate)}
						/>{' '}
					</div>
					{addTemplate && (
						<div className='col-3 mt-3'>
							<Input
								value={tempName}
								onChange={(e) => setTempName(e.target.value)}
								className='form-control'
								placeholder='Enter Template Name'
							/>
						</div>
					)}
					<div className='col-1 mt-3'>
						<Button
							isOutline={darkModeStatus}
							color='primary'
							className='border-0'
							onClick={() =>
								isPermitted('Ipd', 'write', handleSubmit, 'Patient History')
							}>
							Save
						</Button>
					</div>
				</div>
			</CardBody>
			<Modal
				isOpen={tempModal}
				setIsOpen={setTempModal}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setTempModal}>
					<ModalTitle id='deletePharmLabel'>Load Template</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='name' label='Choose/Select Template name'>
						<Select
							id='name'
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
								}),
							}}
							value={tempName}
							options={historyTemplates
								?.filter((x) => x?.type == 'DRUG')
								.map((x) => ({ label: x?.name, value: x?.template }))}
							onChange={(i) => loadTemplate(i.value)}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setTempModal(false);
						}}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const FamilyHistory = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const fhEditor = useRef(null);

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const history = useSelector((state) => state.ipdPatient.history?.family);
	const historyTemplates = useSelector((state) => state.ipd.historyTemplate);

	const [tempModal, setTempModal] = useState(false);
	const [tempName, setTempName] = useState(null);
	const [addTemplate, setAddTemplate] = useState(false);
	const [familyHistory, setFamilyHistory] = useState(history || '');

	const handleSubmit = () => {
		if (addTemplate && !tempName) {
			return;
		}
		if (addTemplate) {
			dispatch(
				addHistoryTemplate({
					type: 'FAMILY',
					name: tempName,
					template: familyHistory,
				}),
			);
			setAddTemplate(false);
			setTempName(null);
		}
		dispatch(addFamilyHistory({ ipdId, family: familyHistory }));
	};

	const loadTemplate = (value) => {
		setFamilyHistory(value);
		setTempModal(false);
	};

	return (
		<>
			<CardBody isScrollable className='d-flex flex-column h-100'>
				<div className='row'>
					<div className='col-9 mb-3'>
						<h3>Family History</h3>
					</div>
					<div className='col-3 mb-3'>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='save'
							aria-label='Create Treatment'
							onClick={() => {
								setTempModal(true);
							}}>
							Load template
						</Button>
					</div>
					<div className='col-12'>
						<JoditEditor
							config={{
								defaultActionOnPaste: 'insert_as_html',
								height: '50vh',
							}}
							ref={fhEditor}
							// value={partial ? familyHistory + partial : familyHistory}
							value={familyHistory}
							tabIndex={1}
							onBlur={(newContent) => setFamilyHistory(newContent)}
						/>
					</div>
					<div className='col-2 mt-3'>
						<Checks
							id='template'
							label='Save as Template'
							checked={addTemplate}
							onChange={(e) => setAddTemplate(!addTemplate)}
						/>{' '}
					</div>
					{addTemplate && (
						<div className='col-3 mt-3'>
							<Input
								value={tempName}
								onChange={(e) => setTempName(e.target.value)}
								className='form-control'
								placeholder='Enter Template Name'
							/>
						</div>
					)}
					<div className='col-1 mt-3'>
						<Button
							isOutline={darkModeStatus}
							color='primary'
							className='border-0'
							onClick={() =>
								isPermitted('Ipd', 'write', handleSubmit, 'Patient History')
							}>
							Save
						</Button>
					</div>
				</div>
			</CardBody>
			<Modal
				isOpen={tempModal}
				setIsOpen={setTempModal}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setTempModal}>
					<ModalTitle id='deletePharmLabel'>Load Template</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='name' label='Choose/Select Template name'>
						<Select
							id='name'
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
								}),
							}}
							value={tempName}
							options={historyTemplates
								?.filter((x) => x?.type == 'FAMILY')
								.map((x) => ({ label: x?.name, value: x?.template }))}
							onChange={(i) => loadTemplate(i.value)}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setTempModal(false);
						}}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const SocialHistory = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const shEditor = useRef(null);

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const history = useSelector((state) => state.ipdPatient.history?.social);
	const historyTemplates = useSelector((state) => state.ipd.historyTemplate);

	const [tempModal, setTempModal] = useState(false);
	const [tempName, setTempName] = useState(null);
	const [addTemplate, setAddTemplate] = useState(false);
	const [socialHistory, setSocialHistory] = useState(history || '');

	const handleSubmit = () => {
		if (addTemplate && !tempName) {
			return;
		}
		if (addTemplate) {
			dispatch(
				addHistoryTemplate({
					type: 'SOCIAL',
					name: tempName,
					template: socialHistory,
				}),
			);
			setAddTemplate(false);
			setTempName(null);
		}
		dispatch(addSocialHistory({ ipdId, social: socialHistory }));
	};

	const loadTemplate = (value) => {
		setSocialHistory(value);
		setTempModal(false);
	};

	return (
		<>
			<CardBody isScrollable className='d-flex flex-column h-100'>
				<div className='row'>
					<div className='col-9 mb-3'>
						<h3>Social History</h3>
					</div>
					<div className='col-3 mb-3'>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='save'
							aria-label='Create Treatment'
							onClick={() => {
								setTempModal(true);
							}}>
							Load template
						</Button>
					</div>
					<div className='col-12'>
						<JoditEditor
							config={{
								defaultActionOnPaste: 'insert_as_html',
								height: '50vh',
							}}
							ref={shEditor}
							value={socialHistory}
							tabIndex={1}
							onBlur={(newContent) => setSocialHistory(newContent)}
						/>
					</div>
					<div className='col-2 mt-3'>
						<Checks
							id='template'
							label='Save as Template'
							checked={addTemplate}
							onChange={(e) => setAddTemplate(!addTemplate)}
						/>{' '}
					</div>
					{addTemplate && (
						<div className='col-3 mt-3'>
							<Input
								value={tempName}
								onChange={(e) => setTempName(e.target.value)}
								className='form-control'
								placeholder='Enter Template Name'
							/>
						</div>
					)}
					<div className='col-1 mt-3'>
						<Button
							isOutline={darkModeStatus}
							color='primary'
							className='border-0'
							onClick={() =>
								isPermitted('Ipd', 'write', handleSubmit, 'Patient History')
							}>
							Save
						</Button>
					</div>
				</div>
			</CardBody>
			<Modal
				isOpen={tempModal}
				setIsOpen={setTempModal}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setTempModal}>
					<ModalTitle id='deletePharmLabel'>Load Template</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='name' label='Choose/Select Template name'>
						<Select
							id='name'
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
								}),
							}}
							value={tempName}
							options={historyTemplates
								?.filter((x) => x?.type == 'SOCIAL')
								.map((x) => ({ label: x?.name, value: x?.template }))}
							onChange={(i) => loadTemplate(i.value)}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setTempModal(false);
						}}>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default IpdHistory;
