import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardTitle,
} from '../../../components/bootstrap/Card';
import axios from '../../../helpers/axios';
import { staticUrl } from '../../../helpers/helpers';
import { SET_UPDATES } from '../../../reducers/types';

function Updates() {
	const dispatch = useDispatch();
	const updates = useSelector((state) => state.setting.updates);

	useEffect(() => {
		let mounted = true;

		const fetchUpdates = async () => {
			const res = await axios.get(`/doctor/ehr-update`);
			if (res.data.success) {
				console.log(res.data.updates);
				dispatch({
					type: SET_UPDATES,
					payload: res.data.updates,
				});
			}
		};

		if (mounted) {
			fetchUpdates();
		}

		return () => {
			mounted = false;
		};
	}, []);

	return updates ? updates?.map((update, index) => (
		<Card key={index} className='p-0'>
			<CardBody className='p-0'>
				<img className='img-fluid' src={`${staticUrl}/ehr-update/${update?.image1}`} />
				<div className='px-4 pb-3'>
					<h4>{update?.title}</h4>
					<div className='mt-3'>
						<p dangerouslySetInnerHTML={{ __html: update?.update }} />
					</div>
				</div>
			</CardBody>
			<CardFooter>
				<CardFooterRight>
					<Button icon='book' color='primary' className='w-100 px-3 delete-pat-bttn'>
						Read More
					</Button>
				</CardFooterRight>
			</CardFooter>
		</Card>
	)) : <></>;
}

export default Updates;
