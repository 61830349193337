import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import USERS from '../../common/data/userDummyData';
import Button from '../../components/bootstrap/Button';
import Input from '../../components/bootstrap/forms/Input';
import { checkSTTLicense, getMedicineByName, staticUrl } from '../../helpers/helpers';
import Chat from '../../assets/img/chat-avatar.png';
import Header from './components/Header';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentVoicePrescription } from '../../actions/paniniActions';
import { getClinicalNotes, getMedicines, getVitals } from '../../helpers/nlp';
import { initializeAugnito } from '../../helpers/augnito';
import showNotification from '../../components/extras/showNotification';
import { createCCValue } from '../../actions/helperActions';
const augnito = initializeAugnito();

const initialMsg = [
	{
		text:
			'The current date and time is ' +
			moment().format('DD/MM/yyyy') +
			' ' +
			moment().format('hh:mm:ss'),
		type: 'bot',
	},
	{
		text: `Hi, I am here to let you add Short Prescriptions by voice. <br/> Enter 00 to go back <br/> <b>Enter .. to skip any field`,
		type: 'bot',
	},
	{
		text: 'What are the vitals of the patient?',
		type: 'bot',
	},
];

const PrescriptionVoice = ({ handleWholeBotClose, handleGoBack, whichBot, user, UserAvatar }) => {
	const bottomRef = useRef(null);
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const [inputData, setInputData] = useState('');
	const [copied, setCopied] = useState(false);
	const [loading, setLoading] = useState(true);
	const [messages, setMessages] = useState(initialMsg);

	const { currentVoicePrescription } = useSelector((state) => state.panini);
	const cc = useSelector((state) => state.history.chiefComplainsValues);

	const userImgDir = selectedOrg?.userType === 'staff' ? 'staffs' : 'doctor-profile';

	const handleAddMessage = (data = '') => {
		if (data.trim() != '') {
			setMessages((prev) => [
				...prev,
				{
					text: data,
					type: 'user',
				},
			]);
			setInputData('');
			inputRef.current?.focus();
		} else if (inputData?.trim() != '') {
			setMessages((prev) => [
				...prev,
				{
					text: inputData,
					type: 'user',
				},
			]);
			setInputData('');
			setLoading(true);
		}
	};

	const appendBotMessage = (msg) => {
		setMessages((prev) => [
			...prev,
			{
				text: msg,
				type: 'bot',
			},
		]);
	};

	const appendBotMessageWithType = (msgs) => {
		setLoading(true);
		for (let i = 0; i < msgs.length; i++) {
			setTimeout(() => {
				setMessages((prev) => [
					...prev,
					{
						text: msgs[i]?.text ? msgs[i]?.text : msgs,
						type: msgs[i]?.type || 'bot',
					},
				]);
				if (i == msgs.length - 1) setLoading(false);
				inputRef.current?.focus();
			}, 1000 * (i + 1));
		}
	};

	const handleCopyMessage = (msg) => {
		navigator.clipboard.writeText(msg.text);
		setCopied(true);
	};

	const handleCopyChat = () => {
		let text = '';
		for (let i = 0; i < messages.length; i++) {
			if (messages[i].type === 'bot')
				text += 'Pãnini : ' + messages[i].text?.replace(/<\/?[^>]+(>|$)/g, '') + '\n';
			else text += 'You : ' + messages[i].text + '\n';
		}
		navigator.clipboard.writeText(text);
		setCopied(true);
	};

	const getLastBotMessage = () => {
		let obj = {};
		for (let i = messages.length - 1; i >= 0; i--) {
			if (messages[i]?.type == 'bot') {
				obj = messages[i];
				break;
			}
		}
		return obj;
	};

	const handleChangePrescription = (inputs) => {
		let prescription = {
			...currentVoicePrescription,
			...inputs,
		};
		console.log(prescription);
		dispatch(setCurrentVoicePrescription(prescription));
	};

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 2000);
		}
	}, [copied]);

	useEffect(() => {
		let mounted = true;
		const appendInitialBotMessage = async () => {
			if (mounted) {
				setMessages([]);
				setLoading(true);
				for (let i = 0; i < initialMsg.length; i++) {
					if (initialMsg[i].type === 'bot') {
						setTimeout(() => {
							appendBotMessage(initialMsg[i].text);
							if (i == initialMsg.length - 1) {
								setLoading(false);
								inputRef.current?.focus();
							}
						}, 500 * (i + 1));
					}
				}
			}
		};
		appendInitialBotMessage();
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (!loading) {
			inputRef.current?.focus();
		}
	}, [loading]);

	useEffect(() => {
		bottomRef.current?.scrollIntoView({
			top: bottomRef.current.scrollHeight,
			behavior: 'smooth',
			block: 'end',
		});
	}, [loading]);

	useEffect(() => {
		bottomRef.current?.scrollIntoView({
			top: bottomRef.current.scrollHeight,
			behavior: 'smooth',
			block: 'end',
		});
		if (messages.length > 0) {
			if (messages[messages.length - 1].type === 'user') {
				if (messages[messages.length - 1].text == '01') {
					setMessages(initialMsg);
					dispatch(setCurrentVoicePrescription({}));
					setTimeout(() => {
						setLoading(false);
						inputRef.current?.focus();
					}, 500);
					return;
				}
				if (messages[messages.length - 1].text == '00') {
					handleGoBack();
					setLoading(false);
				}
				if (getLastBotMessage().text?.includes('What are the vitals of the patient?')) {
					getVitalsFromText(messages[messages.length - 1].text);

					appendBotMessageWithType(['What are the complaints? (comma separated)']);
				} else if (
					getLastBotMessage().text?.includes('What are the complaints? (comma separated)')
				) {
					getComplaintsFromText(messages[messages.length - 1].text);

					// appendBotMessageWithType(['What is the history of the patient?']);
					// }
					// else if (
					// 	getLastBotMessage().text?.includes('What is the history of the patient?')
					// ) {
					// 	getHistoryFromText(messages[messages.length - 1].text);

					// 	appendBotMessageWithType([
					// 		'Do you have any investigation of the patient? (comma separated)',
					// 	]);
					// } else if (
					// 	getLastBotMessage().text?.includes(
					// 		'Do you have any investigation of the patient? (comma separated)',
					// 	)
					// ) {
					// 	getInvestigationsFromText(messages[messages.length - 1].text);

					appendBotMessageWithType([
						'Do you have any examinations of the patient? (comma separated)',
					]);
				} else if (
					getLastBotMessage().text?.includes(
						'Do you have any examinations of the patient? (comma separated)',
					)
				) {
					getExaminationsFromText(messages[messages.length - 1].text);

					appendBotMessageWithType(['Do you want to write any notes?']);
				} else if (getLastBotMessage().text?.includes('Do you want to write any notes?')) {
					getNotesFromText(messages[messages.length - 1].text);

					appendBotMessageWithType(['What is patient diagnosis? (comma separated)']);
				} else if (
					getLastBotMessage().text?.includes(
						'What is patient diagnosis? (comma separated)',
					)
				) {
					getDiagnosisFromText(messages[messages.length - 1].text);

					appendBotMessageWithType([
						'What are the medicines prescribed? (comma separated)',
					]);
				} else if (
					getLastBotMessage().text?.includes(
						'What are the medicines prescribed? (comma separated)',
					)
				) {
					getMedicinesFromText(messages[messages.length - 1].text);

					appendBotMessageWithType(['What are the tests required? (comma separated)']);
				} else if (
					getLastBotMessage().text?.includes(
						'What are the tests required? (comma separated)',
					)
				) {
					getTestsRequiredFromText(messages[messages.length - 1].text);

					appendBotMessageWithType([
						'What are the special instructions? (comma separated)',
					]);
				} else if (
					getLastBotMessage().text?.includes(
						'What are the special instructions? (comma separated)',
					)
				) {
					getSpecialInstructionsFromText(messages[messages.length - 1].text);

					getClinicalNotesFromText(currentVoicePrescription);
					enableListening();
					handleWholeBotClose();
				} else appendBotMessageWithType(['Press 01 to restart and 00 to go back!']);
			}
		}
	}, [messages]);

	// Speech Functions --------------------------------------------------------------------
	const getVitalsFromText = async (text) => {
		const vitals = await getVitals(text);
		handleChangePrescription({ vitals });
	};

	const getComplaintsFromText = async (text) => {
		const complaints = text.split(',') || [];
		var comps = [];

		for (var i of complaints) {
			const complaint = cc.find(
				(j) => j?.name?.toLowerCase?.()?.trim?.() == i?.toLowerCase?.()?.trim?.(),
			);
			if (complaint) {
				comps.push({ label: complaint?.name, value: complaint?.id });
			} else {
				const resp = await createCCValue(i, user?.Vendor_detail?.vendorId);
				comps.push({
					label: resp.name,
					value: resp.id,
				});
			}
		}
		handleChangePrescription({ complaints: comps });
	};

	const getHistoryFromText = (text) => {
		console.log(text);
	};

	const getInvestigationsFromText = (text) => {
		handleChangePrescription({ investigations: text.split(',')?.map((i) => i.trim?.() || i) });
	};

	const getExaminationsFromText = (text) => {
		handleChangePrescription({ examinations: text.split(',')?.map((i) => i?.trim?.() || i) });
	};

	const getDiagnosisFromText = (text) => {
		handleChangePrescription({
			diagnoses: text.split(',')?.map((i) => ({ name: i?.trim?.() || i })),
		});
	};

	const getNotesFromText = (text) => {
		handleChangePrescription({ ros: text?.trim?.() });
	};

	const getMedicinesFromText = async (text) => {
		const medicines = (await getMedicines(text))?.medicines || [];
		var meds = [];
		for (var i of medicines) {
			const name = i?.name?.trim?.();
			if (name) {
				const med = await getMedicineByName(name);
				if (med) meds.push({ ...i, name: med.name, composition: med.composition });
				else meds.push(i);
			}
		}
		handleChangePrescription({ medicines: meds });
	};

	const getTestsRequiredFromText = (text) => {
		handleChangePrescription({
			tests: text.split(',')?.map((i) => i?.trim?.() || i),
		});
	};

	const getSpecialInstructionsFromText = (text) => {
		handleChangePrescription({
			specialInstructions: text.split(',')?.map((i) => i?.trim?.() || i),
		});
	};

	const getClinicalNotesFromText = async (text) => {
		const clinicalNotes = await getClinicalNotes(text);
		handleChangePrescription({
			detailedClinicalNotes: clinicalNotes,
		});
	};

	// Speech to Text (Augnito)
	const enableListening = async () => {
		if (await checkSTTLicense()) {
			augnito.toggleListening();
		} else {
			showNotification(
				'Permission Denied',
				'You are not subscribed to use speech to text',
				'danger',
			);
		}
	};

	const [listening, setListening] = useState(false);
	const [partial, setPartial] = useState('');
	const [preFinal, setPreFinal] = useState('');

	augnito.onStateChanged = (isConnected) => {
		if (listening) setInputData((prev) => prev + '');
		setListening(isConnected);
	};

	augnito.onMicrophoneOnError = (isWebClient) => {
		augnito.requestMicOff();
		augnito.toggleListening();
	};

	augnito.onPartialResult = (text) => {
		setPartial(text);
	};

	augnito.onFinalResult = (finalResult) => {
		if (preFinal && preFinal.name === finalResult.name) return;
		setInputData((prev) => prev + finalResult?.receivedText?.replace('\n', ' '));
		setPreFinal(finalResult);
		if (
			finalResult.name?.toLowerCase().includes('nextsection') ||
			finalResult.name?.toLowerCase().includes('next section')
		) {
			handleAddMessage(inputData);
		}
	};

	useEffect(() => {
		if (!listening) enableListening();
	}, []);

	return (
		<div className='chatbot-tile prescription-chatbot'>
			<div className='header-prescription-hover'>
				<Header
					handleCopyChat={handleCopyChat}
					handleWholeBotClose={handleWholeBotClose}
					handleGoBack={handleGoBack}
					// className={'bg-transparent-pres'}
				/>
			</div>
			<div className='fields bg-transparent ht-350'>
				{messages.map((message, index) => (
					<div
						key={index}
						className={message?.type == 'user' ? 'message df-jcfe' : 'message df-jcfs'}>
						{message?.type == 'bot' || message?.type == 'result' ? (
							<div className='user-avtar'>
								<img src={Chat} alt='bot' />
							</div>
						) : (
							<Button
								className='copy-message'
								icon='Clipboard'
								onClick={() => handleCopyMessage(message)}
							/>
						)}
						<div
							className={
								message.type === 'bot'
									? 'bot-message'
									: message.type == 'result'
									? 'result-message'
									: 'user-message'
							}
							key={index}
							dangerouslySetInnerHTML={{ __html: message.text }}></div>
						{message?.type == 'user' && (
							<UserAvatar
								srcSet={
									user?.Vendor_detail?.profileImage &&
									`${staticUrl}/${userImgDir}/${user?.Vendor_detail.profileImage}`
								}
								src={USERS.JOHN.src}
							/>
						)}
					</div>
				))}
				{loading && (
					<>
						<div className='message df-jcfs'>
							<div className='user-avtar'>
								<img src={Chat} alt='bot' />
							</div>
							<div className={'bot-message loading-div'}>
								<p className='loading'></p>
							</div>
						</div>
					</>
				)}
				<div className='ref-div' ref={bottomRef} />
			</div>
			<div className='bottom-input'>
				<Input
					placeholder='Type your message here'
					onChange={(e) => {
						setInputData(e.target.value);
					}}
					value={inputData}
					type='text'
					rows={3}
					disabled={loading}
					autoFocus
					ref={inputRef}
				/>
				{/* <Button
					className={`send-btn ${listening ? 'bg-danger' : ''}`}
					onClick={enableListening}>
					{listening ? 'Stop' : 'Speak'}
				</Button> */}
			</div>
			{copied && <div className='copied-text'>Data copied to clipboard!</div>}
		</div>
	);
};

export default PrescriptionVoice;
