import showNotification from '../components/extras/showNotification';
import axios, { custom } from '../helpers/axios';
import {
	SET_CHIEF_COMPLAINTS_VALUES,
	SET_HISTORY_LOADING,
	SET_MEDICAL_ALERTS,
} from '../reducers/types';
import { store } from '../store/configureStore';
import { setAuthorizationHeader } from './authActions';
import { getVendorMessageCredits } from './profileActions';

export const getMedicines = async (search) => {
	try {
		const res = await axios.get(`/doctor/medicine?search=${search || ''}`);
		if (res.data.success) {
			return res.data.medicines;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getInventoryByComposition = async (key) => {
	try {
		const res = await axios.get(`/inventory/composition?composition=${key}`);
		if (res.data.success) {
			return res.data.medicines?.[0] || null;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const getPurchasedItems = async () => {
	try {
		const res = await axios.get('sales-voucher/pruchase-item-list');
		if (res.data.success) {
			return res.data.purchaseItems;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getInventories = async () => {
	try {
		const res = await axios.get('/inventory');
		if (res.data.success) {
			return res.data.inventories;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getOpdValidity = async (userId) => {
	try {
		const res = await axios.post('/opd/validity', { userId });
		if (res.data.success) {
			return res.data.registrationFee;
		}
	} catch (err) {
		console.log(err);
	}
};

export const getLanguages = async () => {
	try {
		const res = await axios.get('/language');
		if (res.data.success) {
			return res.data.languages;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getFrequencies = async () => {
	try {
		const res = await axios.get('/doctor/dose');
		if (res.data.success) {
			return res.data.doses;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const getPermissions = async (orgId, vendorId) => {
	try {
		const res = await axios.get(
			`/staff-permission?organisationId=${orgId}&vendorId=${vendorId}`,
		);
		if (res.data.success) {
			return res.data.actions;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const updatePermissions = async (updateBody) => {
	try {
		const res = await axios.post(`/staff-permission`, updateBody);
		if (res.data.success) {
			showNotification('Success', 'Permission updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getOpdByAppointmentId = async (appointmentId) => {
	try {
		const res = await axios.get(`/opd/getOpdByAppointment?appointmentId=${appointmentId}`);
		if (res.data.success) {
			return res.data.opd;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const isPermitted = async (
	module,
	action,
	next,
	subAction = null,
	isNotification = true,
) => {
	let permissions = store.getState?.()?.profile?.permissions;
	const userType = store.getState?.()?.profile?.selectedOrg?.userType;

	if (userType !== 'staff') {
		next();
		return;
	}
	if (permissions?.length == 0) {
		await new Promise((res, rej) => setTimeout(res, 2500));
		permissions = store.getState?.()?.profile?.permissions;
	}
	let f = permissions?.find((i) => i.name?.toLowerCase?.() === module?.toLowerCase?.());
	if (subAction) {
		f = f?.subAction?.find((x) => x?.name?.toLowerCase?.() === subAction?.toLowerCase?.());
	}
	if (f) {
		if (f.Vendor_action?.[action]) {
			await next();
		} else {
			if (isNotification)
				showNotification(
					'Permission Denied',
					'User is not authorized to perform the action',
					'danger',
				);
		}
	} else {
		if (isNotification) showNotification('Error', 'Module not found', 'danger');
	}
};

export const returnPermission = (module, action) => {
	let permissions = store.getState?.()?.profile?.permissions;
	const userType = store.getState?.()?.profile?.selectedOrg?.userType;
	let f = permissions?.find((i) => i.name?.toLowerCase?.() === module?.toLowerCase?.());
	if (f) {
		if (f.Vendor_action?.[action]) {
			return true;
		}
		return false;
	}
	return false;
};

export const getPdf = async (userId, type, reportId) => {
	try {
		const res = await axios.get('/uploads', { params: { userId, type, reportId } });
		return res.data.report?.name || null;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const uploadPdf = async (data) => {
	try {
		const res = await axios.post(`/uploads`, data);
		if (res.data.success) {
			return `${res.data.url}/${res.data.reprot.name}`;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getAllPayments = async (data) => {
	try {
		const res = await axios.get('/payments/organisation');
		if (res.data.success) {
			return res.data.payments;
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const createCustomRemark = async (name, type, doctor) => {
	try {
		const res = await axios.get(`/doctor/patient/remarks`, name);
		if (res.status === 200) {
			console.log(res, 'res');

			return res.data;
		}
	} catch (err) {
		console.log(err);
		return {};
	}
};

export const createDiagnosis = async (name) => {
	try {
		const res = await axios.post('/doctor/diagnosis', { name });
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
};

export const createNote = async (newNote) => {
	try {
		const res = await axios.post('/notes', newNote);
		if (res.data.success) {
			return res.data.note;
		}
	} catch (err) {
		console.log(err);
	}
};

export const createCCValue = async (name, doctor) => {
	try {
		const res = await custom.post('/v1/hs/cc-values/', { name, doctor });
		if (res.status == 201) {
			return res.data;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const createTreatmentNow = async (data) => {
	try {
		const res = await axios.post(`/treatment`, { ...data, type: 'GP' });
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const createLabTestHelper = async (data) => {
	try {
		const res = await axios.post(`/doctor/labtests`, data);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const getStorePurchaseList = async () => {
	try {
		const res = await axios.get(`/doctor/purchase/store/list`);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const createMedicine = async (name) => {
	try {
		const res = await axios.post('/doctor/medicine', { name, composition: '' });
		if (res.data.success) {
			return res.data.medicine;
		}
	} catch (err) {
		console.log(err);
	}
};

export const setSelectedOrgBackend = async (organisationId) => {
	console.log('Setting org:', organisationId);
	try {
		const res = await axios.post('/organisation-ehr/assign', { organisationId });
		if (res.data.success) {
			return setAuthorizationHeader(res.data.token);
		}
	} catch (err) {
		console.log('Error setting org:', err);
	}
};

export const getSelectedOrgBackend = async () => {
	try {
		const res = await axios.get('/organisation-ehr/assign');
		return res.data.organisation;
	} catch (err) {
		console.log('Error getting org:', err);
	}
	return null;
};

export const calculateAge = (dob, long = false) => {
	// Convert the date of birth string to a Date object
	const dobDate = new Date(dob);

	// Get the current date
	const currentDate = new Date();

	// Calculate the difference between current date and DOB in milliseconds
	const ageInMilliseconds = currentDate - dobDate;

	if (currentDate < dobDate) return `Invalid DOB`;

	// Convert milliseconds to years and months
	const msPerDay = 1000 * 60 * 60 * 24;
	const years = Math.floor(ageInMilliseconds / (msPerDay * 365));
	const months = Math.floor((ageInMilliseconds % (msPerDay * 365)) / (msPerDay * 30));
	const days = Math.floor(((ageInMilliseconds % (msPerDay * 365)) % (msPerDay * 30)) / msPerDay);

	if (years > 0) {
		if (long) return `${years} Years`;
		return `${years} Y`;
	}
	if (months > 0) {
		if (long) return `${months} Months`;
		return `${months} M`;
	}
	if (days > 0) {
		if (long) return `${days} Days`;
		return `${days} D`;
	}
	return '-';
};

export const getSalesVoucherSummary = async (params) => {
	var url = '/sales-voucher';
	try {
		const res = await axios.get(url);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
	return [];
};

export const getPurchaseVoucherSummary = async (params) => {
	var url = '/purchase-voucher';
	try {
		const res = await axios.get(url);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (err) {
		console.log(err);
	}
	return [];
};

export const createZoomMeeting = async () => {
	try {
		const res = await axios.get('/doctor/zoom/createZoomMeeting');
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const sendTeleconsultationWhatsappNotification = async (data) => {
	try {
		const res = await axios.post('/whatsapp/teleconsultationNotification', data);
		if (res.data.success) {
			return res.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const createAyuSynkStream = async () => {
	try {
		const streamToken = new Date().getTime();
		const res = await axios.post(process.env.REACT_APP_AYUSYNK_URL + 'online_stream/', {
			stream_token: `${streamToken}_stream_token`,
		});

		return res.data;
	} catch (error) {
		console.error(error);
		return {
			status: false,
		};
	}
};

export const updatDoctorOnlineStatus = async (isOnline) => {
	try {
		const res = await axios.patch('/doctor/updateOnlineStatus', {
			isOnline,
		});
		if (res.data.success) {
			return res.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const markSaleItemSuggestionAsRead = async (id) => {
	try {
		const res = await axios.put(`/sales-voucher/confirm-sale-suggestion/${id}`);
	} catch (error) {
		console.error(error);
	}
};

export const getBillTypes = async () => {
	try {
		const res = await axios.get(`/bills/bill-schedule`);
		if (res.data.success) {
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
		return {};
	}
};

/* ------------------------------------------- Bulk Messages START ------------------------------------------- */
export const getMedicalAlerts = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/medical-alert`);
		if (res.data.success) {
			dispatch({
				type: SET_MEDICAL_ALERTS,
				payload: res.data?.data?.medicalAlerts,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getMessagePacks = async () => {
	try {
		const res = await axios.get(`/doctor/message-packs`);
		if (res.data.success) {
			return res.data.result || [];
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const getMessageTemplates = async () => {
	try {
		const res = await axios.get(`/doctor/message-templates`);
		if (res.data.success) {
			return res.data.result || [];
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const getCustomFilterPatientsId = async (
	visitStartDate,
	visitEndDate,
	setLoading,
	startAge = null,
	endAge = null,
	gender = null,
) => {
	setLoading?.(true);

	const organisationId = store.getState?.()?.profile?.selectedOrg?.organisationId;

	try {
		const opds =
			(
				await axios.get('/opd', {
					params: { startDate: visitStartDate, endDate: visitEndDate },
				})
			)?.data?.opds?.docs || [];
		const appointments =
			(
				await axios.get(`/doctor/appointment/list`, {
					params: {
						start: visitStartDate,
						end: visitEndDate,
						type: 'DATE',
						organisationId,
					},
				})
			)?.data?.appointments || [];

		const opdPatients = opds.map((i) => i.Member || null).filter((i) => i);
		const appointmentPatients = appointments.map((i) => i.Member || null).filter((i) => i);

		if (!!startAge?.toString?.() && !!endAge?.toString?.() && !!gender?.toString?.()) {
			return [
				...new Set(
					[...opdPatients, ...appointmentPatients]
						.filter(
							(i) =>
								i.age >= startAge &&
								i.age <= endAge &&
								(gender?.toLowerCase?.() === 'all'
									? true
									: gender?.toLowerCase?.() === i.gender?.toLowerCase?.()),
						)
						.map((i) => i.userId),
				),
			];
		}

		return [
			...new Set([
				...opdPatients.map((i) => i.userId),
				...appointmentPatients.map((i) => i.userId),
			]),
		];
	} catch (err) {
		console.log(err);
		return [];
	} finally {
		setLoading?.(false);
	}
};

export const getUpcomingVisitPatientsId = async (setLoading) => {
	setLoading?.(true);
	try {
		const appointments = (await axios.get(`/appointment-ehr/upcomming`))?.data?.appointments;
		const appointmentPatients = appointments
			?.map((i) => i.Member?.userId || null)
			.filter((i) => i);
		return appointmentPatients || [];
	} catch (err) {
		console.log(err);
		return [];
	} finally {
		setLoading?.(false);
	}
};

export const getAllPatientsId = async (setLoading) => {
	setLoading?.(true);
	const organisationId = store.getState?.()?.profile?.selectedOrg?.organisationId;
	try {
		const allPatients = (
			await axios.get('/doctor/patient/listall', { headers: { organisationId } })
		)?.data?.users;

		const allPatientsId = allPatients?.map((i) => i.selfUserId || null)?.filter((i) => i);

		return allPatientsId || [];
	} catch (err) {
		console.log(err);
		return [];
	} finally {
		setLoading?.(false);
	}
};

export const handleBuyMsgPack = async (messagePackId, dispatch) => {
	if (!messagePackId) {
		showNotification('Error', 'Invalid Package', 'danger');
		return;
	}

	const user = store.getState?.()?.auth?.user;
	try {
		const res = await axios.post('/doctor/message-payments/initiate', { messagePackId });

		if (res.data.success) {
			const order = res.data.order;

			const options = {
				key: process.env.REACT_APP_RAZORPAY_MERCHANT_ID,
				amount: order.amount,
				currency: 'INR',
				name: 'Docplix Solutions Pvt. Ltd.',
				description: 'Transaction to buy Message/Whatsapp Credits',
				image: 'https://docplix.com/wp-content/uploads/2023/01/cropped-Screenshot_2023-01-21_at_1.43.04_PM-removebg-preview.png',
				order_id: order.id,
				handler: async (result) => {
					const {
						razorpay_payment_id: payment_id,
						razorpay_order_id: order_id,
						razorpay_signature: signature,
					} = result;
					const res2 = await axios.post('/doctor/message-payments/verify', {
						payment_id,
						order_id,
						signature,
					});
					if (res2.data.success) {
						dispatch(getVendorMessageCredits());
						showNotification(
							'Payment Success',
							'Credits Added Successfully!',
							'success',
						);
					} else {
						showNotification('Payment Failure', 'Some error occured!', 'danger');
					}
				},
				prefill: {
					name: user?.Vendor_detail?.name || 'Unknown',
					email: user?.email || '',
					contact: user?.phone || '',
				},
				notes: {
					address: 'Razorpay Corporate Office',
				},
				theme: {
					color: '#ff9652',
				},
			};

			const razor = new window.Razorpay(options);
			razor.on('payment.failed', async (response) => {
				await axios.post('/doctor/message-payments/fail', {
					error: response.error,
				});
				// showNotification(response.error.code, response.error.description, 'danger');
			});
			razor.open();
		}
	} catch (err) {
		console.log(err);
	}
};

export const sendBulkMessage = async (
	userIds,
	templateId,
	templateParams,
	sendCategory,
	setSendForm,
	setSendCat,
	dispatch,
) => {
	if (userIds?.length == 0) {
		showNotification('No Patients', 'There were 0 patients in this list', 'danger');
		return;
	}
	try {
		const res = await axios.post('/doctor/bulk-message/send-bulk-message', {
			userIds,
			templateId,
			templateParams,
			sendCategory,
		});
		if (res.data.success) {
			setSendForm?.(false);
			setSendCat?.(false);
			showNotification('Success', 'Bulk Messages Sent Successfully!', 'success');
		}
	} catch (err) {
		console.log(err);
		showNotification('Error', 'Error Sending Bulk Messages!', 'danger');
	} finally {
		dispatch(getVendorMessageCredits());
	}
};

export const getBulkMessageRecords = async () => {
	try {
		const res = await axios.get('/doctor/bulk-message');
		if (res.data.success) {
			return res.data.records || [];
		}
		return [];
	} catch (err) {
		console.log(err);
		return [];
	}
};

/* ------------------------------------------- Bulk Messages END ------------------------------------------- */

export const getCardNetworks = async () => {
	try {
		const res = await axios.get('/payments/card-networks');
		if (res.data.success) {
			return res.data.data;
		}
		return [];
	} catch (error) {
		console.log(error);
		return [];
	}
};

/* ------------------- Lab Referred By ------------------- */
export const getLabReferredBy = async () => {
	try {
		const res = await axios.get('/doctor/referredby');
		if (res.data.success) {
			return res.data.referredlby;
		}
		return [];
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const createLabReferredBy = async (name) => {
	try {
		const res = await axios.post('/doctor/referredby', { name });
		if (res.data.success) {
			return res.data.referredlby;
		}
		return null;
	} catch (error) {
		console.log(error);
		return null;
	}
};

/* ------------------- Lab Referred By END------------------- */

// Fetch All IPDs by userId
export const getIpdPatientHistoryNow = async (patientId) => {
	try {
		const res = await axios.get(`/doctor/ipd/${patientId}`);
		if (res.data.success) {
			return res.data.data || [];
		}
	} catch (error) {
		console.error(error);
	}
	return [];
};

// Fetch All Chemo Notes
export const getChemoNotes = async (userId) => {
	try {
		const res = await axios.get('/doctor/chemo-prescription/list', { params: { userId } });
		if (res.data.success) {
			return res.data.chemoPrescriptions || [];
		}
	} catch (error) {
		console.error(error);
	}
	return [];
};

export const createNewChemoNote = async (data) => {
	try {
		const res = await axios.post('/doctor/chemo-prescription', data);
		if (res.data.success) {
			showNotification('Success', 'Chemo Note created successfully', 'success');
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteChemoNote = async (id) => {
	try {
		const res = await axios.delete(`/doctor/chemo-prescription/${id}`);
		if (res.data.success) {
			showNotification('Success', 'Chemo Note deleted successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const getDoctorsMedicines = async () => {
	try {
		const res = await axios.get('/doctor/medicine/doctor-medicine');
		if (res.data.success) return res.data.appointmentMedicine;
	} catch (error) {
		console.error(error);
	}
	return [];
};

export const updateMedicineHSN = async (data) => {
	try {
		const res = axios.put(`/doctor/medicine/hsn`, data);
		if ((await res).data.success) {
			return await getDoctorsMedicines();
		}
	} catch (error) {
		console.error(error);
	}
};

export const getCountryList = async () => {
	try {
		const res = await axios.get(`/docplix/country-state-city/country`);
		if (res.data.success) return res.data.countries || [];
	} catch (error) {
		console.error(error);
	}
};

export const getStateList = async (countryCode) => {
	try {
		const res = await axios.get(`/docplix/country-state-city/state/${countryCode}`);
		if (res.data.success) return res.data.states || [];
	} catch (error) {
		console.error(error);
	}
};

export const getCityList = async (countryCode, stateCode) => {
	try {
		const res = await axios.get(`/docplix/country-state-city/city/${countryCode}/${stateCode}`);
		if (res.data.success) return res.data.cities;
	} catch (error) {
		console.error(error);
	}
};

export const getTeleCrmSuggestions = async () => {
	try {
		const res = await axios.get('/doctor/patient/get-tele-crm-suggestion-list');
		return res?.data?.data || [];
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const linkSuggestionToCallLog = async (callLogId, patientId) => {
	try {
		await axios.put('/doctor/tele-crm/call-logs/attach-patient', { callLogId, patientId });
		showNotification('Success', 'Patient linked successfully', 'success');
	} catch (err) {
		console.log(err);
		showNotification('Error', 'Some error occured!', 'danger');
	}
};

export const convertLeadToPatient = async (callLogId) => {
	try {
		await axios.get(`/doctor/patient/convert-lead-to-patient/${callLogId}`);
		showNotification('Success', 'Converted to patient successfully', 'success');
	} catch (err) {
		console.log(err);
		showNotification('Error', 'Some error occured!', 'danger');
	}
};
