import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import ReactSelect from 'react-select';

import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Card, { CardBody, CardFooter, CardFooterRight } from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Select from '../../../../components/bootstrap/forms/Select';
import Input from '../../../../components/bootstrap/forms/Input';
import { getDiagnoses } from '../../../../actions/settingActions';
import {
	createNewChemoNote,
	getChemoNotes,
	getIpdPatientHistoryNow,
	isPermitted,
} from '../../../../actions/helperActions';
import Button from '../../../../components/bootstrap/Button';
import { invalidNums } from '../../../../helpers/helpers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
axios.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');

const options = [
	{ label: 'Acceptable', value: true },
	{ label: 'Not Acceptable', value: false },
];

const AddNewChemoNote = ({ isOpen, setOpen, userId, ipdFile, setData }) => {
	const dispatch = useDispatch();
	const doctors = useSelector((state) => state.doctor.doctors);

	const [patientIpds, setPatientIpds] = useState([]);

	const formik = useFormik({
		initialValues: {
			ipdId: '',
			userId: userId,
			date: moment().format('YYYY-MM-DD'),
			specificInstuction: '',
			bsa: '',
			cycle: '',
			diagnoses: [],
			plan: '',
			// doctors: [1, 2, 3],
			investigation: [
				{
					lft: false,
					kft: false,
					cbc: false,
					viralMaker: false,
					echo: false,
					familyCounseling: false,
					ivAccess: false,
					remarks: '',
				},
			],
			premedicationDose: [],
			theraphyDose: [],
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			let data = {};
			for (var key in values) {
				if (values[key] === null || values[key] === '' || values[key] === 'null') continue;
				if (Array.isArray(values[key])) data[key] = values[key];
				data[key] = `${values[key]}`;
			}
			if (selectedDiagnosis)
				data['diagnoses'] = [{ diagnosesId: selectedDiagnosis?.value?.id, remark: '' }];
			data['plan'] = [values.plan || ''];
			data['investigation'] = values.investigation;
			data['premedicationDose'] = values.premedicationDose?.map((i) => ({
				administredBy: i.administredBy,
				checkedBy: i.checkedBy,
				endTime: i.endTime,
				medicineId: i.medicineId,
				pharmacistReview: i.pharmacistReview,
				preparedBy: i.preparedBy,
				startTime: i.startTime,
			}));
			data['theraphyDose'] = values.theraphyDose?.map((i) => ({
				administredBy: i.administredBy,
				checkedBy: i.checkedBy,
				endTime: i.endTime,
				medicineId: i.medicineId,
				pharmacistReview: i.pharmacistReview,
				preparedBy: i.preparedBy,
				startTime: i.startTime,
			}));

			await createNewChemoNote(data);
			setData(await getChemoNotes(userId));
			formik.resetForm();
			setOpen(false);
		},
	});

	// Medicines State
	const [medicines, setMedicines] = useState([]);

	// Medicine Fetch on Input
	let cancelToken;
	const handleFetchMedicines = async (inputVal, firstCall = false) => {
		if (!inputVal && !firstCall) setMedicines([]);
		try {
			if (typeof cancelToken != typeof undefined) {
				cancelToken.cancel('Cancelling prev req');
			}
			cancelToken = axios.CancelToken.source();
			const res = await axios.get(
				`${process.env.REACT_APP_SERVER_URL}/doctor/medicine/suggestion?search=${
					inputVal || ''
				}`,
				{ cancelToken: cancelToken.token },
			);
			const meds = [
				...res.data?.inventories?.map((i) => ({
					id: i?.id,
					isInventory: true,
					name: i?.name,
					color: 'orange',
					composition: i?.composition,
					quantity: `[${i?.Purchase_items?.[0]?.quantity || '0'}]`,
				})),
				...res.data?.medicines?.map((i) => ({
					id: i?.id,
					name: i?.name,
					composition: i?.composition,
				})),
			];
			setMedicines(meds || []);
		} catch (err) {
			console.log(err);
		}
	};

	const [newInvVal, setNewInvVal] = useState(null);

	// Diagnosis
	const diagnoses = useSelector((state) => state.setting?.diagnoses).filter((i) => !i?.isAdmin);
	const [selectedDiagnosis, setSelectedDiagnosis] = useState(null);

	useEffect(() => {
		let mounted = true;
		const fetchOthers = async () => {
			if (mounted) {
				setPatientIpds(await getIpdPatientHistoryNow(userId));
				isPermitted(
					'medical profile',
					'write',
					() => dispatch(getDiagnoses()),
					null,
					false,
				);
			}
		};
		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Modal isOpen={isOpen} size={'xl'} fullScreen={'lg'}>
			<ModalHeader setIsOpen={setOpen}>
				<ModalTitle>Add New Chemo Note</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<Card className='shadow-none border-1 rounded-1'>
					<CardBody>
						{/* Choose IPD */}
						<div className='col-12 mb-4'>
							<FormGroup
								label={'Choose IPD'}
								isColForLabel
								labelClassName='col-sm-2 text-capitalize'
								childWrapperClassName='col-sm-10'
								id='ipdId'>
								<Select
									//disabled={ipdFile?.id ? true : false}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isValid={formik.isValid}
									isTouched={formik.touched.ipdId}
									invalidFeedback={formik.errors.ipdId}
									value={formik.values.ipdId}
									list={[
										{ value: 'null', text: 'none' },
										...(patientIpds?.map((i) => ({
											value: i.id,
											text: `${moment(i?.dateOfAdmission).format(
												'DD-MM-YYYY',
											)} - ${
												i?.dischargeDatetime
													? moment(
															new Date(
																parseInt(i?.dischargeDatetime),
															),
													  ).format('DD-MM-YYYY')
													: 'N/A'
											}`,
										})) || []),
									]}
								/>
							</FormGroup>
						</div>

						{/* Chemo Administration Note */}
						<h5 className='mb-4'>
							<u>Chemo Administration Note</u>
						</h5>
						<form className='row g-4'>
							<div className='col-12'>
								<FormGroup
									id='plan'
									label={'Plan'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input
										placeholder='Plan'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.plan}
										isValid={formik.isValid}
										isTouched={formik.touched.plan}
										invalidFeedback={formik.errors.plan}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup
									label={'Diagnosis'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<ReactSelect
										className='form-control py-0 px-1'
										placeholder='Diagnosis'
										styles={{
											control: () => ({
												position: 'relative',
												display: 'flex',
											}),
										}}
										components={{
											IndicatorSeparator: () => null,
										}}
										value={selectedDiagnosis}
										options={diagnoses?.map((i) => ({
											value: i,
											label: i.name,
										}))}
										onChange={(i) => setSelectedDiagnosis(i)}
									/>
								</FormGroup>
							</div>

							{/* <div className='col-12'>
								<FormGroup
									label={'Height'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input
										type='number'
										onKeyDown={(e) =>
											invalidNums.includes(e.key) && e.preventDefault()
										}
										placeholder='Height'
									/>
								</FormGroup>
							</div>

							<div className='col-12'>
								<FormGroup
									label={'Weight'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input
										type='number'
										onKeyDown={(e) =>
											invalidNums.includes(e.key) && e.preventDefault()
										}
										placeholder='Weight'
									/>
								</FormGroup>
							</div> */}

							<div className='col-12'>
								<FormGroup
									id='date'
									label={'Date'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input
										type='date'
										placeholder='Date'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.date}
										isValid={formik.isValid}
										isTouched={formik.touched.date}
										invalidFeedback={formik.errors.date}
									/>
								</FormGroup>
							</div>

							<div className='col-12'>
								<FormGroup
									id='bsa'
									label={'BSA'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input
										type='number'
										onKeyDown={(e) =>
											invalidNums.includes(e.key) && e.preventDefault()
										}
										placeholder='BSA'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.bsa}
										isValid={formik.isValid}
										isTouched={formik.touched.bsa}
										invalidFeedback={formik.errors.bsa}
									/>
								</FormGroup>
							</div>

							<div className='col-12'>
								<FormGroup
									id='cycle'
									label={'Cycle'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input
										type='number'
										onKeyDown={(e) =>
											invalidNums.includes(e.key) && e.preventDefault()
										}
										placeholder='Cycle'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.cycle}
										isValid={formik.isValid}
										isTouched={formik.touched.cycle}
										invalidFeedback={formik.errors.cycle}
									/>
								</FormGroup>
							</div>
						</form>

						{/* Investigation Table */}
						<table className='table table-bordered border-dark my-4'>
							<tbody>
								<tr>
									<th colSpan={7}>Investigation</th>
									<th colSpan={2}></th>
								</tr>
								<tr>
									<th></th>
									<th>LFT</th>
									<th>KFT</th>
									<th>CBC DIFF</th>
									<th>Viral Marker</th>
									<th>ECHO</th>
									<th>FAMILY Counseling</th>
									<th>IV Access/Site</th>
								</tr>
								<tr>
									<th>Investigation</th>
									<td className='p-0'>
										<FormGroup id='investigation[0].lft'>
											<Select
												className='shadow-none border-0 rounded-0'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isValid={formik.isValid}
												isTouched={formik.touched.investigation?.[0]?.lft}
												invalidFeedback={
													formik.errors.investigation?.[0]?.lft
												}
												value={formik.values.investigation?.[0]?.lft}
												list={options}
											/>
										</FormGroup>
									</td>
									<td className='p-0'>
										<FormGroup id='investigation[0].kft'>
											<Select
												className='shadow-none border-0 rounded-0'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isValid={formik.isValid}
												isTouched={formik.touched.investigation?.[0]?.kft}
												invalidFeedback={
													formik.errors.investigation?.[0]?.kft
												}
												value={formik.values.investigation?.[0]?.kft}
												list={options}
											/>
										</FormGroup>
									</td>
									<td className='p-0'>
										<FormGroup id='investigation[0].cbc'>
											<Select
												className='shadow-none border-0 rounded-0'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isValid={formik.isValid}
												isTouched={formik.touched.investigation?.[0]?.cbc}
												invalidFeedback={
													formik.errors.investigation?.[0]?.cbc
												}
												value={formik.values.investigation?.[0]?.cbc}
												list={options}
											/>
										</FormGroup>
									</td>
									<td className='p-0'>
										<FormGroup id='investigation[0].viralMaker'>
											<Select
												className='shadow-none border-0 rounded-0'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isValid={formik.isValid}
												isTouched={
													formik.touched.investigation?.[0]?.viralMaker
												}
												invalidFeedback={
													formik.errors.investigation?.[0]?.viralMaker
												}
												value={formik.values.investigation?.[0]?.viralMaker}
												list={options}
											/>
										</FormGroup>
									</td>
									<td className='p-0'>
										<FormGroup id='investigation[0].echo'>
											<Select
												className='shadow-none border-0 rounded-0'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isValid={formik.isValid}
												isTouched={formik.touched.investigation?.[0]?.echo}
												invalidFeedback={
													formik.errors.investigation?.[0]?.echo
												}
												value={formik.values.investigation?.[0]?.echo}
												list={options}
											/>
										</FormGroup>
									</td>
									<td className='p-0'>
										<FormGroup id='investigation[0].familyCounseling'>
											<Select
												className='shadow-none border-0 rounded-0'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isValid={formik.isValid}
												isTouched={
													formik.touched.investigation?.[0]
														?.familyCounseling
												}
												invalidFeedback={
													formik.errors.investigation?.[0]
														?.familyCounseling
												}
												value={
													formik.values.investigation?.[0]
														?.familyCounseling
												}
												list={options}
											/>
										</FormGroup>
									</td>
									<td className='p-0'>
										<FormGroup id='investigation[0].ivAccess'>
											<Select
												className='shadow-none border-0 rounded-0'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												isValid={formik.isValid}
												isTouched={
													formik.touched.investigation?.[0]?.ivAccess
												}
												invalidFeedback={
													formik.errors.investigation?.[0]?.ivAccess
												}
												value={formik.values.investigation?.[0]?.ivAccess}
												list={options}
											/>
										</FormGroup>
									</td>
								</tr>
							</tbody>
						</table>

						<form className='row g-4'>
							{/* 	<div className='col-12'>
								<FormGroup
									label={'Day'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input placeholder='Day' />
								</FormGroup>
							</div>

							<div className='col-12'>
								<FormGroup
									label={'On'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input placeholder='On' />
								</FormGroup>
							</div> */}

							<div className='col-12'>
								<FormGroup
									id='specificInstuction'
									label={'Specific Instructions'}
									isColForLabel
									labelClassName='col-sm-2 text-capitalize'
									childWrapperClassName='col-sm-10'>
									<Input
										placeholder='Specific Instructions'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.specificInstuction}
										isValid={formik.isValid}
										isTouched={formik.touched.specificInstuction}
										invalidFeedback={formik.errors.specificInstuction}
									/>
								</FormGroup>
							</div>
						</form>

						<h5 className='my-4'>
							<u>Pre Medication Dose</u>
						</h5>
						{/* PreMedicatoin Table */}
						<table className='table table-bordered border-dark my-4'>
							<tbody>
								<tr>
									<th colSpan={5}>Premedication</th>
									<th colSpan={2}></th>
									<th></th>
								</tr>
								<tr>
									<th>Medicine</th>
									<th>Checked by</th>
									<th>Prepared by</th>
									<th>Administered by</th>
									<th>Pharmacist's Review</th>
									<th>Start Time</th>
									<th>End Time</th>
									<th></th>
								</tr>
								{formik.values.premedicationDose?.map?.((i, idx) => (
									<tr key={idx}>
										<th>{i.medicineName}</th>
										<td className='p-0'>
											<FormGroup id={`premedicationDose[${idx}].checkedBy`}>
												<Select
													list={[
														{ value: null, label: '' },
														...doctors.map((i) => ({
															value: i.id,
															text: i.Vendor_detail?.name || '',
														})),
													]}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.premedicationDose?.[idx]
															?.checkedBy
													}
													invalidFeedback={
														formik.errors.premedicationDose?.[idx]
															?.checkedBy
													}
													value={
														formik.values.premedicationDose?.[idx]
															?.checkedBy
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`premedicationDose[${idx}].preparedBy`}>
												<Select
													list={[
														{ value: null, label: '' },
														...doctors.map((i) => ({
															value: i.id,
															text: i.Vendor_detail?.name || '',
														})),
													]}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.premedicationDose?.[idx]
															?.preparedBy
													}
													invalidFeedback={
														formik.errors.premedicationDose?.[idx]
															?.preparedBy
													}
													value={
														formik.values.premedicationDose?.[idx]
															?.preparedBy
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup
												id={`premedicationDose[${idx}].administredBy`}>
												<Select
													list={[
														{ value: null, label: '' },
														...doctors.map((i) => ({
															value: i.id,
															text: i.Vendor_detail?.name || '',
														})),
													]}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.premedicationDose?.[idx]
															?.administredBy
													}
													invalidFeedback={
														formik.errors.premedicationDose?.[idx]
															?.administredBy
													}
													value={
														formik.values.premedicationDose?.[idx]
															?.administredBy
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup
												id={`premedicationDose[${idx}].pharmacistReview`}>
												<Select
													list={options}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.premedicationDose?.[idx]
															?.pharmacistReview
													}
													invalidFeedback={
														formik.errors.premedicationDose?.[idx]
															?.pharmacistReview
													}
													value={
														formik.values.premedicationDose?.[idx]
															?.pharmacistReview
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`premedicationDose[${idx}].startTime`}>
												<Input
													type='time'
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.premedicationDose?.[idx]
															?.startTime
													}
													invalidFeedback={
														formik.errors.premedicationDose?.[idx]
															?.startTime
													}
													value={
														formik.values.premedicationDose?.[idx]
															?.startTime
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`premedicationDose[${idx}].endTime`}>
												<Input
													type='time'
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.premedicationDose?.[idx]
															?.endTime
													}
													invalidFeedback={
														formik.errors.premedicationDose?.[idx]
															?.endTime
													}
													value={
														formik.values.premedicationDose?.[idx]
															?.endTime
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<Tooltips title='Delete Row'>
												<Button
													color='danger'
													icon='Delete'
													isLink
													onClick={() => {
														formik.setValues((prev) => ({
															...prev,
															premedicationDose: [
																...prev.premedicationDose.slice(
																	0,
																	idx,
																),
																...prev.premedicationDose.slice(
																	idx + 1,
																),
															],
														}));
													}}
												/>
											</Tooltips>
										</td>
									</tr>
								))}
								<tr>
									<th style={{ width: '15rem' }}>
										<ReactSelect
											components={{
												IndicatorSeparator: () => null,
											}}
											className='p-0'
											value={newInvVal}
											styles={{
												control: (base) => ({
													...base,
													border: 0,
													boxShadow: 'none',
												}),
											}}
											options={medicines?.map((i) => ({
												label:
													i?.name +
													(i?.quantity ? ` --${i?.quantity}` : ''),
												value: i,
											}))}
											onInputChange={(e) => {
												handleFetchMedicines(e);
											}}
											onChange={(i) => {
												formik.setValues((prev) => ({
													...prev,
													premedicationDose: [
														...prev.premedicationDose,
														{
															checkedBy: null,
															preparedBy: null,
															administredBy: null,
															pharmacistReview: false,
															startTime: '',
															endTime: '',
															medicineId: i.value?.id,
															medicineName: i.value?.name,
														},
													],
												}));
											}}
										/>
									</th>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>

						{/* Chemotherapy Table */}
						<table className='table table-bordered border-dark my-4'>
							<tbody>
								<tr>
									<th colSpan={5}>Chemotherapy Dose</th>
									<th colSpan={2}></th>
									<th></th>
								</tr>
								<tr>
									<th>Medicine</th>
									<th>Checked by</th>
									<th>Prepared by</th>
									<th>Administered by</th>
									<th>Pharmacist's Review</th>
									<th>Start Time</th>
									<th>End Time</th>
									<th></th>
								</tr>
								{formik.values.theraphyDose?.map?.((i, idx) => (
									<tr key={idx}>
										<th>{i.medicineName}</th>
										<td className='p-0'>
											<FormGroup id={`theraphyDose[${idx}].checkedBy`}>
												<Select
													list={[
														{ value: null, label: '' },
														...doctors.map((i) => ({
															value: i.id,
															text: i.Vendor_detail?.name || '',
														})),
													]}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.theraphyDose?.[idx]
															?.checkedBy
													}
													invalidFeedback={
														formik.errors.theraphyDose?.[idx]?.checkedBy
													}
													value={
														formik.values.theraphyDose?.[idx]?.checkedBy
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`theraphyDose[${idx}].preparedBy`}>
												<Select
													list={[
														{ value: null, label: '' },
														...doctors.map((i) => ({
															value: i.id,
															text: i.Vendor_detail?.name || '',
														})),
													]}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.theraphyDose?.[idx]
															?.preparedBy
													}
													invalidFeedback={
														formik.errors.theraphyDose?.[idx]
															?.preparedBy
													}
													value={
														formik.values.theraphyDose?.[idx]
															?.preparedBy
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`theraphyDose[${idx}].administredBy`}>
												<Select
													list={[
														{ value: null, label: '' },
														...doctors.map((i) => ({
															value: i.id,
															text: i.Vendor_detail?.name || '',
														})),
													]}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.theraphyDose?.[idx]
															?.administredBy
													}
													invalidFeedback={
														formik.errors.theraphyDose?.[idx]
															?.administredBy
													}
													value={
														formik.values.theraphyDose?.[idx]
															?.administredBy
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`theraphyDose[${idx}].pharmacistReview`}>
												<Select
													list={options}
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.theraphyDose?.[idx]
															?.pharmacistReview
													}
													invalidFeedback={
														formik.errors.theraphyDose?.[idx]
															?.pharmacistReview
													}
													value={
														formik.values.theraphyDose?.[idx]
															?.pharmacistReview
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`theraphyDose[${idx}].startTime`}>
												<Input
													type='time'
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.theraphyDose?.[idx]
															?.startTime
													}
													invalidFeedback={
														formik.errors.theraphyDose?.[idx]?.startTime
													}
													value={
														formik.values.theraphyDose?.[idx]?.startTime
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<FormGroup id={`theraphyDose[${idx}].endTime`}>
												<Input
													type='time'
													className='shadow-none border-0 rounded-0'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isValid={formik.isValid}
													isTouched={
														formik.touched.theraphyDose?.[idx]?.endTime
													}
													invalidFeedback={
														formik.errors.theraphyDose?.[idx]?.endTime
													}
													value={
														formik.values.theraphyDose?.[idx]?.endTime
													}
												/>
											</FormGroup>
										</td>
										<td className='p-0'>
											<Tooltips title='Delete Row'>
												<Button
													color='danger'
													icon='Delete'
													isLink
													onClick={() => {
														formik.setValues((prev) => ({
															...prev,
															theraphyDose: [
																...prev.theraphyDose.slice(0, idx),
																...prev.theraphyDose.slice(idx + 1),
															],
														}));
													}}
												/>
											</Tooltips>
										</td>
									</tr>
								))}
								<tr>
									<th style={{ width: '15rem' }}>
										<ReactSelect
											components={{
												IndicatorSeparator: () => null,
											}}
											className='p-0'
											value={newInvVal}
											styles={{
												control: (base) => ({
													...base,
													border: 0,
													boxShadow: 'none',
												}),
											}}
											options={medicines?.map((i) => ({
												label:
													i?.name +
													(i?.quantity ? ` --${i?.quantity}` : ''),
												value: i,
											}))}
											onInputChange={(e) => {
												handleFetchMedicines(e);
											}}
											onChange={(i) => {
												formik.setValues((prev) => ({
													...prev,
													theraphyDose: [
														...prev.theraphyDose,
														{
															checkedBy: null,
															preparedBy: null,
															administredBy: null,
															pharmacistReview: false,
															startTime: '',
															endTime: '',
															medicineId: i.value?.id,
															medicineName: i.value?.name,
														},
													],
												}));
											}}
										/>
									</th>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</CardBody>
					<CardFooter>
						<CardFooterRight>
							<Button
								icon='Save'
								color='primary'
								isOutline
								isDisable={!formik.isValid && !!formik.submitCount}
								onClick={formik.handleSubmit}>
								Save
							</Button>
						</CardFooterRight>
					</CardFooter>
				</Card>
			</ModalBody>
		</Modal>
	);
};

export default AddNewChemoNote;
