import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import CommonHeaderRight from './CommonHeaderRight';
import Nav, { NavItem, NavLinkDropdown } from '../../../components/bootstrap/Nav';
import Button from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import LogoImg from '../../../assets/logos/logo rx (1).png';
import { dashboardMenu, navMenu } from '../../../menu';
import { useSelector } from 'react-redux';
import PharmacyVendors from '../../../assets/img/menu/pharmacy/pharmacy-vendors.png';
import SaleVouchers from '../../../assets/img/menu/pharmacy/sale-vouchers.png';
import AddOPD from '../../../assets/img/menu/opd/add-opd.png';

// Images

const DefaultHeader = () => {
	const navigate = useNavigate();
	const menuType = useSelector((state) => state.button.dashBoardMenuType);
	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);

	// IPD Org Change
	const notViewable = [];
	if (!user?.isPcp) notViewable.push('preventiveCareProgram');
	if (!selectedOrg?.Organisation?.isIpd) notViewable.push('ipd');

	let menuItems = Object.keys(navMenu).filter((x) => !notViewable.includes(x));

	// const menuItems =
	// 	menuType != 'admin'
	// 		? Object.keys(navMenu).filter((i) => navMenu[i]?.type == menuType)
	// 		: Object.keys(navMenu);

	return (
		<Header>
			<HeaderLeft style={{ paddingLeft: 0 }}>
				<Nav style={{ flexWrap: 'nowrap' }}>
					<NavItem>
						<NavLink to='/' style={{ background: 'transparent', paddingLeft: 0 }}>
							<img src={LogoImg} width={125} height={42} className='p-0' alt='logo' />
						</NavLink>
					</NavItem>
					{
						// menuItems.length == 1 && menuType != 'admin'
						// 	? Object.keys(navMenu[menuItems?.[0]]?.subMenu).map((item) => (
						// 			<NavLinkDropdown>
						// 				<Button
						// 					isLink
						// 					color='dark'
						// 					onClick={() =>
						// 						navigate(navMenu[menuItems?.[0]]?.subMenu[item].path)
						// 					}>
						// 					{navMenu[menuItems?.[0]]?.subMenu[item].text}
						// 				</Button>
						// 			</NavLinkDropdown>
						// 	  ))
						// 	:
						menuItems.map((item) => {
							let subMenu = navMenu[item].subMenu;
							if (item == 'remoteClinic') {
								if (user?.isRemoteClinic) {
									subMenu = {
										myReferrals: {
											id: 'myReferrals',
											text: 'My Referrals',
											path: dashboardMenu.remoteClinic.subMenu.myReferrals
												.path,
											icon: PharmacyVendors,
											subMenu: null,
										},
									};
								} else {
									subMenu = {
										remoteClinic: {
											id: 'remoteClinic',
											text: 'Remote Clinic',
											path: dashboardMenu.remoteClinic.subMenu
												.remoteClinicAppointments.path,
											icon: AddOPD,
											subMenu: null,
										},
										sharedFiles: {
											id: 'sharedFiles',
											text: 'Shared Files',
											path: dashboardMenu.remoteClinic.subMenu.sharedFiles
												.path,
											icon: SaleVouchers,
										},
									};
								}
							}
							return (
								<NavItem>
									{subMenu ? (
										<Dropdown>
											<DropdownToggle>
												<NavLinkDropdown
													style={{
														color: 'black',
														paddingBottom: 0,
														paddingLeft: 0,
													}}>
													<Button
														className='pe-0'
														isDisable
														isLink
														style={{ opacity: 1 }}>
														{navMenu[item].text}
													</Button>
												</NavLinkDropdown>
											</DropdownToggle>
											<DropdownMenu>
												{subMenu &&
													Object.keys(subMenu).map((subItem) => (
														<DropdownItem
															onClick={() => {
																document
																	.getElementById('footer')
																	.click();
															}}>
															<NavLink to={subMenu[subItem].path}>
																{subMenu[subItem].icon && (
																	<img
																		className='me-2'
																		width='20px'
																		height='20px'
																		src={subMenu[subItem].icon}
																	/>
																)}
																<span style={{ color: 'black' }}>
																	{subMenu[subItem].text}
																</span>
															</NavLink>
														</DropdownItem>
													))}
											</DropdownMenu>
										</Dropdown>
									) : (
										<NavLinkDropdown>
											<Button
												className='pe-0'
												isLink
												color='dark'
												onClick={() => navigate(navMenu[item].path)}>
												{navMenu[item].text} abc
											</Button>
										</NavLinkDropdown>
									)}
								</NavItem>
							);
						})
					}
				</Nav>
			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
		</Header>
	);
};

export default DefaultHeader;
