import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';
import RichTextEditor from 'react-rte';

import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import moment from 'moment';
import LabTests from './components/LabTests';
import FetalSurvey from './components/FetalSurvey';
import EDC from './components/EDC';
import PregnancyHistory from './components/PregnancyHistory';
import Risk from './components/Risk';
import FlowSheet from './components/FlowSheet';
import { createGynaeData } from '../../../actions/ipdPatientActions';
import { getIpdLabList, getIpdRiskList } from '../../../actions/ipdActions';
import { isPermitted } from '../../../actions/helperActions';

const IpdGynae = () => {
	const darkModeStatus = useDarkMode();
	const dispatch = useDispatch();

	const gynaeData = useSelector((state) => state.ipdPatient.gynaeData)?.sort?.((a, b) =>
		moment(a?.createdAt) < moment(b?.createdAt) ? 1 : -1,
	)?.[0];
	const patientId = useSelector((state) => state.ipd.patientIpdFile?.patientId);
	const riskList = useSelector((state) => state.ipd.gynaeRiskList);
	const labList = useSelector((state) => state.ipd.gynaeLabList);

	const labsFormik = useFormik({
		initialValues: useMemo(
			() =>
				Object.fromEntries(
					new Map(
						labList
							.map((x) => x.Gyno_lab_other_tests)
							.flat()
							.map((x) => [
								x?.id,
								{
									labTestId: x?.id,
									date: moment().format('YYYY-MM-DD'),
									result: '',
								},
							]),
					),
				),
			[],
		),
	});

	const riskFormik = useFormik({
		initialValues: useMemo(
			() =>
				Object.fromEntries(
					new Map(
						riskList
							?.map((x) => x?.Gyno_risk_stratifications)
							.flat()
							.map((x) => [x?.id, { isChecked: false, id: x?.id }]),
					),
				),
			[],
		),
	});

	const formik = useFormik({
		initialValues: {
			gravida: '',
			para: '',
			abortion: RichTextEditor.createEmptyValue(),
			maritalHistory: RichTextEditor.createEmptyValue(),
			presentPregnancyComplication: RichTextEditor.createEmptyValue(),
			allergies: RichTextEditor.createEmptyValue(),
			initialExam: false,
			AFP: false,
			GCT: false,
			GBS: false,
			tubal: false,
			initialLabObtained: false,
			pnreWeeks: '',
			breast: false,
			ageOfOnSet: '',
			menoPause: '',
			uterus: '',
			bottle: false,
			pelvicExamination: RichTextEditor.createEmptyValue(),
			rectalExamination: RichTextEditor.createEmptyValue(),
			vaginalExamination: RichTextEditor.createEmptyValue(),
			breastExamination: RichTextEditor.createEmptyValue(),
			cervixExamination: RichTextEditor.createEmptyValue(),
			perAbdomen: RichTextEditor.createEmptyValue(),
			generalExamination: RichTextEditor.createEmptyValue(),
			abdomenPalpatation: RichTextEditor.createEmptyValue(),
			presentingPartToPelvis: RichTextEditor.createEmptyValue(),
			fhPositionRate: RichTextEditor.createEmptyValue(),
			onsetPainHours: '',
			onsetPainShow: '',
			onsetPainBleeding: '',
			membranesIntactRuptured: '',
			membranesHoursAgo: '',
			heightOfFundus: '',
			uterusCondition: '',
			presentation: '',
			position: '',
			riskSummary: '',
			edcDetermination: [
				{
					gestationalAge: '',
					date: moment().format('YYYY-MM-DD'),
					EDC: '',
					type: 'LMP',
					description: '',
				},
				{
					gestationalAge: '',
					date: moment().format('YYYY-MM-DD'),
					EDC: '',
					type: 'Initial Exam',
					description: '',
				},
				{
					gestationalAge: '',
					date: moment().format('YYYY-MM-DD'),
					EDC: '',
					type: 'FHR-Doppler',
					description: '',
				},
				{
					gestationalAge: '',
					date: moment().format('YYYY-MM-DD'),
					EDC: '',
					type: 'Ultrasound',
					description: '',
				},
				{
					gestationalAge: '',
					date: moment().format('YYYY-MM-DD'),
					EDC: '',
					type: 'Repeat U/S',
					description: '',
				},
				{
					gestationalAge: '',
					date: moment().format('YYYY-MM-DD'),
					EDC: '',
					type: 'Consensus EDC',
					description: '',
				},
			],
			pregnancyHistory: [],
			fetalSurvey: [],
			flowSheet: [],
			labTestReports: [],
			risks: [],
		},
		onSubmit: (values) => {
			const data = { ...values };
			data['ageOfOnSet'] = values.ageOfOnSet?.toString();
			data['abdomenPalpatation'] = values.abdomenPalpatation.toString('html');
			data['abortion'] = values.abortion.toString('html');
			data['allergies'] = values.allergies.toString('html');
			data['breastExamination'] = values.breastExamination.toString('html');
			data['cervixExamination'] = values.cervixExamination.toString('html');
			data['fhPositionRate'] = values.fhPositionRate.toString('html');
			data['maritalHistory'] = values.maritalHistory.toString('html');
			data['pelvicExamination'] = values.pelvicExamination.toString('html');
			data['perAbdomen'] = values.perAbdomen.toString('html');
			data['presentPregnancyComplication'] =
				values.presentPregnancyComplication.toString('html');
			data['presentingPartToPelvis'] = values.presentingPartToPelvis.toString('html');
			data['rectalExamination'] = values.rectalExamination.toString('html');
			data['vaginalExamination'] = values.vaginalExamination.toString('html');
			data['generalExamination'] = values.generalExamination.toString('html');
			data['ageOfOnSet'] = values.ageOfOnSet.toString();
			data['pregnancyHistory'] = values.pregnancyHistory?.map((x) => ({
				...x,
				gender: x.gender?.label,
				mode: x?.mode?.label,
			}));
			const risks = [];
			Object.values(riskFormik.values).forEach((x) => {
				if (x.isChecked) risks.push(x.id);
			});
			data['risks'] = risks;
			data['labTestReports'] = Object.values(labsFormik.values);
			data['userId'] = patientId;
			dispatch(createGynaeData(data));
		},
	});

	useEffect(() => {
		if (gynaeData) {
			formik.setValues((prev) => ({
				...prev,
				id: gynaeData?.id,
				gravida: gynaeData?.gravida || '',
				para: gynaeData?.para || '',
				abortion: RichTextEditor.createValueFromString(gynaeData?.abortion || '', 'html'),
				maritalHistory: RichTextEditor.createValueFromString(
					gynaeData?.maritalHistory || '',
					'html',
				),
				presentPregnancyComplication: RichTextEditor.createValueFromString(
					gynaeData?.presentPregnancyComplication || '',
					'html',
				),
				allergies: RichTextEditor.createValueFromString(gynaeData?.allergies || '', 'html'),
				initialExam: gynaeData?.initialExam == 0 ? false : true,
				AFP: gynaeData?.AFP || false,
				GCT: gynaeData?.GCT || false,
				GBS: gynaeData?.GBS || false,
				tubal: gynaeData?.tubal || false,
				initialLabObtained: gynaeData?.initialLabObtained || false,
				pnreWeeks: gynaeData?.pnreWeeks || '',
				breast: gynaeData?.breast || false,
				ageOfOnSet: gynaeData?.ageOfOnSet?.toString() || '',
				menoPause: gynaeData?.menoPause || '',
				uterus: gynaeData?.uterus || '',
				bottle: gynaeData?.bottle || false,
				pelvicExamination: RichTextEditor.createValueFromString(
					gynaeData?.pelvicExamination || '',
					'html',
				),
				rectalExamination: RichTextEditor.createValueFromString(
					gynaeData?.rectalExamination || '',
					'html',
				),
				vaginalExamination: RichTextEditor.createValueFromString(
					gynaeData?.vaginalExamination || '',
					'html',
				),
				breastExamination: RichTextEditor.createValueFromString(
					gynaeData?.breastExamination || '',
					'html',
				),
				cervixExamination: RichTextEditor.createValueFromString(
					gynaeData?.cervixExamination || '',
					'html',
				),
				perAbdomen: RichTextEditor.createValueFromString(
					gynaeData?.perAbdomen || '',
					'html',
				),
				generalExamination: RichTextEditor.createValueFromString(
					gynaeData?.generalExamination || '',
					'html',
				),
				abdomenPalpatation: RichTextEditor.createValueFromString(
					gynaeData?.abdomenPalpatation || '',
					'html',
				),
				presentingPartToPelvis: RichTextEditor.createValueFromString(
					gynaeData?.presentingPartToPelvis || '',
					'html',
				),
				fhPositionRate: RichTextEditor.createValueFromString(
					gynaeData?.fhPositionRate || '',
					'html',
				),
				onsetPainHours: gynaeData?.onsetPainHours || '',
				onsetPainShow: gynaeData?.onsetPainShow || '',
				onsetPainBleeding: gynaeData?.onsetPainBleeding || '',
				membranesIntactRuptured: gynaeData?.membranesIntactRuptured || '',
				membranesHoursAgo: gynaeData?.membranesHoursAgo || '',
				heightOfFundus: gynaeData?.heightOfFundus || '',
				uterusCondition: gynaeData?.uterusCondition || '',
				presentation: gynaeData?.presentation || '',
				position: gynaeData?.position || '',
				riskSummary: gynaeData?.riskSummary || '',
				edcDetermination: [
					...gynaeData?.Gynae_edc_determinations?.map((x) => ({
						id: x?.id || '',
						gestationalAge: x?.gestationalAge || '',
						date: x?.date || moment().format('YYYY-MM-DD'),
						EDC: x?.EDC || '',
						type: x?.type || '',
						description: x?.description || '',
					})),
					...[
						{
							gestationalAge: '',
							date: moment().format('YYYY-MM-DD'),
							EDC: '',
							type: 'LMP',
							description: '',
						},
						{
							gestationalAge: '',
							date: moment().format('YYYY-MM-DD'),
							EDC: '',
							type: 'Initial Exam',
							description: '',
						},
						{
							gestationalAge: '',
							date: moment().format('YYYY-MM-DD'),
							EDC: '',
							type: 'FHR-Doppler',
							description: '',
						},
						{
							gestationalAge: '',
							date: moment().format('YYYY-MM-DD'),
							EDC: '',
							type: 'Ultrasound',
							description: '',
						},
						{
							gestationalAge: '',
							date: moment().format('YYYY-MM-DD'),
							EDC: '',
							type: 'Repeat U/S',
							description: '',
						},
						{
							gestationalAge: '',
							date: moment().format('YYYY-MM-DD'),
							EDC: '',
							type: 'Consensus EDC',
							description: '',
						},
					].filter(
						(x) =>
							!gynaeData?.Gynae_edc_determinations.map((x) => x?.type)?.includes(
								x?.type,
							),
					),
				],
				pregnancyHistory:
					gynaeData?.Gynae_pregnancy_histories?.map((x) => ({
						id: x?.id,
						pregnancyNo: x?.pregnancyNo || '',
						duration: x?.duration || '',
						complications: x?.complications || '',
						extraNotes: x?.extraNotes || '',
						gender: { value: x?.gender, label: x?.gender },
						mode: { value: x?.mode, label: x?.mode },
					})) || [],
				fetalSurvey:
					gynaeData?.Gynae_fetal_survey_pertinent_findings?.map((x) => ({
						id: x?.id,
						date: x?.date || moment().format('YYYY-MM-DD'),
						findings: x?.findings || '',
					})) || [],
				flowSheet:
					gynaeData?.Gynae_flow_sheets?.map((x) => ({
						id: x?.id,
						ega: x?.ega || '',
						date: x?.date || moment().format('YYYY-MM-DD'),
						headache: x?.headache || '',
						bleeding: x?.bleeding || '',
						crampsPain: x?.crampsPain || '',
						nauseaVomit: x?.nauseaVomit || '',
						swelling: x?.swelling || '',
						fetalMvmt: x?.fetalMvmt || '',
						weight: x?.weight || '',
						bp: x?.bp || '',
						urineProtein: x?.urineProtein || '',
						urineGlucose: x?.urineGlucose || '',
						fundalHt: x?.fundalHt || '',
						position: x?.position || '',
						cervix: x?.cervix || '',
						edema: x?.edema || '',
						fhr: x?.fhr || '',
						comments: x?.comments || '',
						returnVisit: x?.returnVisit || '',
						mdInitials: x?.mdInitials || '',
					})) || [],
			}));
			labsFormik.setValues(
				Object.fromEntries(
					new Map(
						gynaeData?.Gynae_lab_test_reports?.map((x) => [
							x?.labTestId,
							{
								id: x?.id,
								labTestId: x?.labTestId,
								date: moment(x?.date).format('YYYY-MM-DD'),
								result: x?.result,
							},
						]),
					),
				),
			);
			riskFormik.setValues((prev) => ({
				...prev,
				...Object.fromEntries(
					new Map(
						gynaeData?.Gynae_risks?.map((x) => [
							x?.riskId,
							{ isChecked: true, id: x?.riskId },
						]),
					),
				),
			}));
		}
	}, [gynaeData]);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			dispatch(getIpdRiskList());
			dispatch(getIpdLabList());
		}
	}, []);

	return (
		<Card stretch className='shadow-none'>
			<CardHeader>
				<CardLabel icon='contacts' iconColor='primary'>
					<CardTitle>Obs. & Gynae</CardTitle>
				</CardLabel>
				<CardActions>
					<Button
						isOutline
						color='primary'
						icon='save'
						onClick={() => {
							isPermitted('Ipd', 'write', formik.handleSubmit, 'Obs & Gynae');
						}}>
						Save
					</Button>
				</CardActions>
			</CardHeader>
			<CardBody isScrollable>
				<div className='row mb-3'>
					<div className='col-6'>
						<FormGroup label={'Gravida'}>
							<Input
								className='form-control py-3'
								name='gravida'
								value={formik.values.gravida}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-6'>
						<FormGroup label={'Para'}>
							<Input
								className='form-control py-3'
								name='para'
								value={formik.values.para}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Marital History`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.maritalHistory}
								onChange={(x) => formik.setFieldValue(`maritalHistory`, x)}
							/>
						</FormGroup>
					</div>
					<div className='col-12 mb-3'>
						<FormGroup label={`Abortion`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.abortion}
								onChange={(x) => formik.setFieldValue(`abortion`, x)}
							/>
						</FormGroup>
					</div>
					<div className='col-12'>
						<FormGroup label={`Complications in  present Pregnancy`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.presentPregnancyComplication}
								onChange={(x) =>
									formik.setFieldValue(`presentPregnancyComplication`, x)
								}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 text-center'>
						<strong>Quick Check List</strong>
					</div>
					<div className='col-2'>
						<Checks
							id='InitialExam'
							type='checkbox'
							label='Initial Exam'
							checked={formik.values.initialExam}
							onChange={() =>
								formik.setFieldValue('initialExam', !formik.values.initialExam)
							}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='afp'
							type='checkbox'
							label='AFP'
							checked={formik.values.AFP}
							onChange={() => formik.setFieldValue('AFP', !formik.values.AFP)}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='initialLabsObtained'
							type='checkbox'
							label='Initial Labs Obtained'
							checked={formik.values.initialLabObtained}
							onChange={() =>
								formik.setFieldValue(
									'initialLabObtained',
									!formik.values.initialLabObtained,
								)
							}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='GCT'
							type='checkbox'
							label='GCT'
							checked={formik.values.GCT}
							onChange={() => formik.setFieldValue('GCT', !formik.values.GCT)}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='tubal'
							type='checkbox'
							label='Tubal'
							checked={formik.values.tubal}
							onChange={() => formik.setFieldValue('tubal', !formik.values.tubal)}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='gbs'
							type='checkbox'
							label='GBS'
							checked={formik.values.GBS}
							onChange={() => formik.setFieldValue('GBS', !formik.values.GBS)}
						/>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 text-center'>
						<strong>PN RE. to L & D</strong>
					</div>
					<div className='col-2'>
						<Checks
							id='20weeks'
							type='radio'
							label='20 Weeks'
							name='pnreWeeks'
							onFocus={formik.handleChange}
							value={'20 Weeks'}
							checked={formik.values.pnreWeeks}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='28weeks'
							type='radio'
							label='28 Weeks'
							name='pnreWeeks'
							onFocus={formik.handleChange}
							value={'28 Weeks'}
							checked={formik.values.pnreWeeks}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='36weeks'
							type='radio'
							label='36 Weeks'
							name='pnreWeeks'
							onFocus={formik.handleChange}
							value={'36 Weeks'}
							checked={formik.values.pnreWeeks}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='38weeks'
							type='radio'
							label='38 Weeks'
							name='pnreWeeks'
							onFocus={formik.handleChange}
							value={'38 Weeks'}
							checked={formik.values.pnreWeeks}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='40weeks'
							type='radio'
							label='40 Weeks'
							name='pnreWeeks'
							onFocus={formik.handleChange}
							value={'40 Weeks'}
							checked={formik.values.pnreWeeks}
						/>
					</div>
				</div>
				<FetalSurvey formik={formik} />
				<div className='row mb-3'>
					<div className='col-4'></div>
					<div className='col-2'>
						<Checks
							id='breast'
							type='checkbox'
							label='Breast'
							checked={formik.values.breast}
							onChange={() => formik.setFieldValue('breast', !formik.values.breast)}
						/>
					</div>
					<div className='col-2'>
						<Checks
							id='bottle'
							type='checkbox'
							label='Bottle'
							checked={formik.values.bottle}
							onChange={() => formik.setFieldValue('bottle', !formik.values.bottle)}
						/>
					</div>
					<div className='col-4'></div>
				</div>
				<EDC formik={formik} />
				<div className='row mb-3'>
					<div className='col-12 mb-2 text-center'>
						<strong>Menstural History</strong>
					</div>
					<div className='col-4'>
						<FormGroup label={`Age of Onset`}>
							<Input
								type='number'
								className='form-control p-3'
								value={formik.values.ageOfOnSet}
								name={`ageOfOnSet`}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-4'>
						<FormGroup label={`Menopause`}>
							<Input
								type='text'
								className='form-control p-3'
								value={formik.values.menoPause}
								name={`menoPause`}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-4'>
						<FormGroup label={`Uterus (in weeks)`}>
							<Input
								type='number'
								className='form-control p-3'
								value={formik.values.uterus}
								name={`uterus`}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Allergies`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.allergies}
								onChange={(x) => formik.setFieldValue(`allergies`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<LabTests labsFormik={labsFormik} />
				<PregnancyHistory formik={formik} />
				<Risk riskFormik={riskFormik} formik={formik} />
				<FlowSheet formik={formik} />
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Pelvic Examination`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.pelvicExamination}
								onChange={(x) => formik.setFieldValue(`pelvicExamination`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Rectal Examination`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.rectalExamination}
								onChange={(x) => formik.setFieldValue(`rectalExamination`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Vaginal Examination`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.vaginalExamination}
								onChange={(x) => formik.setFieldValue(`vaginalExamination`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Breast Examination`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.breastExamination}
								onChange={(x) => formik.setFieldValue(`breastExamination`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Cervix Examination`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.cervixExamination}
								onChange={(x) => formik.setFieldValue(`cervixExamination`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Per Abdomen`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.perAbdomen}
								onChange={(x) => formik.setFieldValue(`perAbdomen`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-1'>
						<strong>General Examination</strong>
					</div>
					<div className='col-12 mb-3'>
						<FormGroup label={`Important Findings`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.generalExamination}
								onChange={(x) => formik.setFieldValue(`generalExamination`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-1'>
						<strong>Onset of Pain :</strong>
					</div>
					<div className='col-4 mb-3'>
						<FormGroup label={`Hours`}>
							<Input
								type='number'
								className='form-control py-3'
								name='onsetPainHours'
								value={formik.values.onsetPainHours}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-4 mb-3'>
						<FormGroup label={`Show`}>
							<Input
								className='form-control py-3'
								name='onsetPainShow'
								value={formik.values.onsetPainShow}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-4 mb-3'>
						<FormGroup label={`Important Findings`}>
							<Input
								className='form-control py-3'
								name='onsetPainBleeding'
								value={formik.values.onsetPainBleeding}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-1'>
						<strong>Membranes :</strong>
					</div>
					<div className='col-6 mb-3'>
						<FormGroup label={`Intact/Ruptured`}>
							<Input
								className='form-control py-3'
								name='membranesIntactRuptured'
								value={formik.values.membranesIntactRuptured}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-6 mb-3'>
						<FormGroup label={`Hours Ago`}>
							<Input
								type='number'
								className='form-control py-3'
								name='membranesHoursAgo'
								value={formik.values.membranesHoursAgo}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Abdominal Palpatation`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.abdomenPalpatation}
								onChange={(x) => formik.setFieldValue(`abdomenPalpatation`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-6 mb-3'>
						<FormGroup label={`Height of Fundus`}>
							<Input
								className='form-control py-3'
								name='heightOfFundus'
								value={formik.values.heightOfFundus}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-6 mb-3'>
						<FormGroup label={`Condition of Uterus`}>
							<Input
								className='form-control py-3'
								name='uterusCondition'
								value={formik.values.uterusCondition}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-6 mb-3'>
						<FormGroup label={`Presentation`}>
							<Input
								className='form-control py-3'
								name='presentation'
								value={formik.values.presentation}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
					<div className='col-6 mb-3'>
						<FormGroup label={`Position`}>
							<Input
								className='form-control py-3'
								name='position'
								value={formik.values.position}
								onChange={formik.handleChange}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`Relationship of Presenting Part to Pelvis`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.presentingPartToPelvis}
								onChange={(x) => formik.setFieldValue(`presentingPartToPelvis`, x)}
							/>
						</FormGroup>
					</div>
				</div>
				<div className='row mb-3'>
					<div className='col-12 mb-3'>
						<FormGroup label={`F.H. Position & Rate`}>
							<RichTextEditor
								editorStyle={{ height: '10vh' }}
								value={formik.values.fhPositionRate}
								onChange={(x) => formik.setFieldValue(`fhPositionRate`, x)}
							/>
						</FormGroup>
					</div>
				</div>
			</CardBody>
		</Card>
	);
};

export default IpdGynae;
