import React, { lazy } from 'react';
import { authMenu, calculatorMenu, dashboardMenu, profileMenu } from '../menu';

const ConsultWithExpert = {
	ZOOM: lazy(() => import('../pages/patients/components/ConsultWithExpert/ZoomCall')),
};
const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
// const REMOTE_CLINIC = {
// 	SHARED_FILES: lazy(() => import('../pages/remoteClinic/SharedFiles')),
// 	REMOTE_CLINIC_APPOINTMENTS: lazy(() =>
// 		import('../pages/remoteClinic/RemoteClinicAppointments'),
// 	),
// 	MY_REFERRALS: lazy(() => import('../pages/remoteClinic/MyReferrals')),
// };

const TELE_CRM = {
	ALL_PATIENTS: lazy(() => import('../pages/tele-crm/AllPatients')),

	ALL_NOTES: lazy(() => import('../pages/tele-crm/AllNotes')),

	ADD_LEAD_SUGGESTION: lazy(() => import('../pages/tele-crm/AddLeadSuggestion')),

	CALL_LOGS: lazy(() => import('../pages/tele-crm/CallLogs')),
	PATIENT_DETAILS: lazy(() => import('../pages/tele-crm/PatientDetails')),
	PATIENT_CALL_LOGS: lazy(() => import('../pages/tele-crm/PatientCallLogs')),
	PATIENT_APPOINTMENT_LOGS: lazy(() => import('../pages/tele-crm/PatientAppointmentLogs')),

	LEADS: lazy(() => import('../pages/tele-crm/Leads')),
};

const APPOINTMENTS = {
	ADD: lazy(() => import('../pages/appointment/AppointmentList')),
	LIST: lazy(() => import('../pages/appointment/CalendarPage')),
};
const PCP = {
	CREATE_PROGRAM: lazy(() => import('../pages/pcp/CreateRequest')),
	LIST: lazy(() => import('../pages/pcp/ProgramList')),
	CLASS_LIST: lazy(() => import('../pages/pcp/ClassList')),
	PROGRAM_SETUP: lazy(() => import('../pages/pcp/components/ProgramSetup')),
	VIEW_PROGRAM: lazy(() => import('../pages/pcp/components/ViewProgram')),
	ADD_SUBSCRIBER: lazy(() => import('../pages/pcp/components/AddSubscriber')),
	VIEW_SUBSCRIBER: lazy(() => import('../pages/pcp/components/ViewSubscriber')),
};
const PATIENTS = {
	ADD: lazy(() => import('../pages/patients/AddPatient')),
	LIST: lazy(() => import('../pages/patients/PatientsList')),
	VIEW: lazy(() => import('../pages/patients/ViewPatient')),
	PRESCRIPTION_VIEW: lazy(() => import('../pages/patients/PrescriptionView')),
	PRESCRIPTION_PDF: lazy(() => import('../pages/patients/components/PrescriptionPdf')),
	DIAGNOSES_PDF: lazy(() => import('../pages/patients/components/DiagnosesPdf')),
	VOICE_PRESCRIPTION_PDF: lazy(() => import('../pages/patients/components/VoicePrescriptionPdf')),
	BILLING_PDF: lazy(() => import('../pages/patients/components/BillingPdf')),
	PAYMENT_PDF: lazy(() => import('../pages/patients/components/PaymentPdf')),
	OPD_PDF: lazy(() => import('../pages/patients/components/OpdPdf')),
	HISTORY_PDF: lazy(() => import('../pages/patients/components/PatientHistory/HistoryPdf')),
	VISION_PDF: lazy(() => import('../pages/patients/components/VisionPdf')),
	IMMUNISATION_PDF: lazy(() => import('../pages/patients/components/ImmunPdf')),
	DETAILED_LONG_REPORT: lazy(() => import('../pages/patients/components/DetailedLongReport')),
	CHEMO_NOTE_PDF: lazy(() => import('../pages/patients/components/ChemoNotes/ChemoNotePdf')),
	PATIENT_ATTACHMENT_PDF: lazy(() =>
		import('../pages/patients/components/PatientAttachmentPdf.js'),
	),
	AIDA: lazy(() => import('../pages/patients/PatientAiDa.js')),
};

const OPD = {
	ADD_RECORD: lazy(() => import('../pages/opd/AddOPDRecord')),
	VIEW_RECORDS: lazy(() => import('../pages/opd/OPDRecordList')),
	PDF_HEADER: lazy(() => import('../pages/opd/components/HeaderPdf')),
};

const IPD = {
	// ADD_ACCOMMODATION: lazy(() => import('../pages/ipd/AddAccomodation')),
	// VIEW_ACCOMMODATIONS: lazy(() => import('../pages/ipd/AccomodationList')),
	CREATE_IPD: lazy(() => import('../pages/ipd/CreateIpd')),
	ROOM_STATUS: lazy(() => import('../pages/ipd/RoomStatus')),
	ROOM_STATUS_PDF: lazy(() => import('../pages/ipd/Pdfs/RoomStatusPdf')),
	IPD_SETTINGS: lazy(() => import('../pages/ipd/IpdSettings/IPDSettings')),
	IPD_MIS: lazy(() => import('../pages/ipd/MIS/DoctorsList')),
	PATIENT_IPD_FILE: lazy(() => import('../pages/ipd/PatientIpdFile')),
	IPD_LIST: lazy(() => import('../pages/ipd/IpdList')),
	DISCHARGE_SUMMARY_PDF: lazy(() => import('../pages/ipd/Pdfs/DischargeSummaryPdf')),
	DISCHARGE_SUMMARY2_PDF: lazy(() => import('../pages/ipd/Pdfs/DischargeSummary2Pdf')),
	IPD_BILLING_PDF: lazy(() => import('../pages/ipd/Pdfs/IpdBillingPdf')),
	IPD_PAYMENTS_PDF: lazy(() => import('../pages/ipd/Pdfs/IpdPaymentPdf')),
	IPD_FACE_CARD: lazy(() => import('../pages/ipd/Pdfs/IpdFaceCard')),
	IPD_LIST_PDF: lazy(() => import('../pages/ipd/Pdfs/IpdListPdf')),
};

const STORE = {
	STORE_ITEMS: lazy(() => import('../pages/store/Items')),
	STORE_PURCHASE: lazy(() => import('../pages/store/Purchase')),
	ADD_STORE_PURCHASE: lazy(() => import('../pages/store/AddPurchase')),
	SALES_INDENTATION: lazy(() => import('../pages/store/SalesIndentation')),
	ADD_SALES_INDENTATION: lazy(() => import('../pages/store/AddSalesIndentation')),
	STORE_INVENTORY: lazy(() => import('../pages/store/StoreInventory')),
};

const CALCULATOR = {
	MAIN_PAGE: lazy(() => import('../pages/calculator/index')),
	ALLERGY_CAL: lazy(() => import('../pages/calculator/allergy/index')),
	ANESTHESIOLOGY_CAL: lazy(() => import('../pages/calculator/anesthesiology/index')),
	ONCOLOGY_CAL: lazy(() => import('../pages/calculator/oncology/index')),
};

const PHARMACY = {
	ADD_PHARMACY: lazy(() => import('../pages/pharmacy/AddPharmacy')),
	PHARMACY_SETTINGS: lazy(() => import('../pages/pharmacy/PharmacySettings')),
	ADD_VENDOR: lazy(() => import('../pages/pharmacy/AddVendor')),
	VENDORS: lazy(() => import('../pages/pharmacy/VendorsList')),
	PAYMENT_LIST: lazy(() => import('../pages/pharmacy/PaymentList')),
	ADD_PURCHASE_VOUCHER: lazy(() => import('../pages/pharmacy/PurchaseVoucher/AddEdit.js')),
	RETURN_PURCHASE_VOUCHER: lazy(() => import('../pages/pharmacy/PurchaseVoucher/index')),
	PURCHASE_VOUCHERS: lazy(() => import('../pages/pharmacy/PurchaseVoucher/List')),
	INVENTORY: lazy(() => import('../pages/pharmacy/InventoriesList')),
	ADD_SALE_VOUCHER: lazy(() => import('../pages/pharmacy/AddSalesVoucher')),
	SALE_VOUCHERS: lazy(() => import('../pages/pharmacy/SalesVouchersList')),
	WHOLESALES: lazy(() => import('../pages/pharmacy/WholeSales/WholeSalesList.js')),

	WHOLESALES_PDF: lazy(() => import('../pages/pharmacy/WholeSales/WholeSalePdf.js')),
	ADD_WHOLESALES: lazy(() => import('../pages/pharmacy/WholeSales/AddWholeSale.js')),
	SALE_VOUCHER_PDF: lazy(() => import('../pages/pharmacy/SalesVoucherPdf')),
	VENDOR_PROFILE: lazy(() => import('../pages/pharmacy/StockistProfile')),
	STOCK_MEDICINE: lazy(() => import('../pages/pharmacy/StockMedicineList')),
	EXPIRY_DATE_MEDICINE: lazy(() => import('../pages/pharmacy/ExpiryDateMedicineList')),
	GST_REPORTS_SUMMARY: lazy(() => import('../pages/pharmacy/GstReportsList')),
	GST_REPORTS: lazy(() => import('../pages/pharmacy/GstReports')),
	GST_REPORT_3B: lazy(() => import('../pages/pharmacy/GstSummary')),
	GST_REPORTS_PDF: lazy(() => import('../pages/pharmacy/GstReportPdf')),
};
const STAFF = {
	ADD_STAFF: lazy(() => import('../pages/staff/AddStaff')),
	VIEW_STAFF: lazy(() => import('../pages/staff/StaffList')),
	VIEW_STAFF_PERMISSIONS: lazy(() => import('../pages/staff/StaffPermissions')),
	VIEW_STAFF_ATTENDANCE: lazy(() => import('../pages/staff/StaffAttendanceList')),
	VIEW_STAFF_DETAILS: lazy(() => import('../pages/staff/ViewStaffDetails')),
	EXPORT_DR_MISS: lazy(() => import('../pages/accounts/StaffExportDr')),
};
const ACCOUNTS = {
	ADD_VENDOR: lazy(() => import('../pages/accounts/AddVendor')),
	VENDORS: lazy(() => import('../pages/accounts/VendorList')),
	ADD_TRANSACTION: lazy(() => import('../pages/accounts/AddTransaction')),
	TRANSACTION: lazy(() => import('../pages/accounts/TransactionList')),
	EXPENSE: lazy(() => import('../pages/accounts/ExpenseList')),
	SUMMARY: lazy(() => import('../pages/accounts/Summary')),
	MIS: lazy(() => import('../pages/accounts/OrganisationMIS/Overview.js')),
	EXPORT_MIS: lazy(() => import('../pages/accounts/OrgMISPrint')),
	EXPORT_DR_MIS: lazy(() => import('../pages/accounts/ExportDrMIS.js')),
	STAFF_MIS: lazy(() => import('../pages/accounts/StaffMIS')),
	MIS_SPECIFIC: lazy(() => import('../pages/accounts/OrganisationMIS/Specific.js')),
	STAFF_MIS_SPECIFIC: lazy(() => import('../pages/accounts/StaffMisSpecific')),
	STAFF_MIS_SPECIFIC_PDF: lazy(() => import('../pages/accounts/StaffHandoverPdf')),
	PATIENT_MIS: lazy(() => import('../pages/mis/patient-mis/index.js')),
	PATIENT_MIS_AGE_VIEW: lazy(() => import('../pages/mis/patient-mis/AgeView.js')),
	PATIENT_MIS_CITY_VIEW: lazy(() => import('../pages/mis/patient-mis/CityView.js')),
	DOCTOR_MIS: lazy(() => import('../pages/mis/doctor-mis/index.jsx')),
	DOCTOR_MIS_VIEW: lazy(() => import('../pages/mis/doctor-mis/DoctorView.js')),
	RX_MIS: lazy(() => import('../pages/mis/rx-mis/index.jsx')),
	RX_MIS_VIEW: lazy(() => import('../pages/mis/rx-mis/RxView.jsx')),
	DIAGNOSTIC_MIS: lazy(() => import('../pages/mis/rx-mis/DiagnosticMis.jsx')),
	DIAGNOSTIC_MIS_VIEW: lazy(() => import('../pages/mis/rx-mis/DiagnosticView.jsx')),
};

// const LAB = {
// 	ADD_LAB_VENDOR: lazy(() => import('../pages/lab/AddLabVendor')),
// 	LAB_VENDORS: lazy(() => import('../pages/lab/LabVendorList')),
// 	ADD_LAB_TRANSACTION: lazy(() => import('../pages/lab/AddLabTransaction')),
// 	LAB_TRANSACTIONS: lazy(() => import('../pages/lab/LabTransactionsList')),
// 	LAB_SUMMARY: lazy(() => import('../pages/lab/LabSummary')),
// };

const LAB = {
	// LAB_TEST: lazy(() => import('../pages/lab/LabTest')),
	LAB_BILLING: lazy(() => import('../pages/lab/LabBilling')),
	ADD_LAB_BILLING: lazy(() => import('../pages/lab/AddLabBilling')),
	LAB_BILLING_PDF: lazy(() => import('../pages/lab/pdfs/LabBillingPdf')),
	LAB_REPORT_PDF: lazy(() => import('../pages/lab/pdfs/LabReportPdf')),
	LAB_PARAMETERS: lazy(() => import('../pages/lab/parameters/ParameterList')),
	LAB_TEMPLATES: lazy(() => import('../pages/lab/LabTemplates')),
};

const SETTINGS = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
	DOCTOR_PROFILE: lazy(() => import('../pages/settings/DoctorSettings')),
	ADD_DOCTOR: lazy(() => import('../pages/doctors/AddDoctor')),
	DOCTORS: lazy(() => import('../pages/doctors/DoctorsList')),
	CONSENTS: lazy(() => import('../pages/settings/Consents')),
	ADD_ORGANISATION: lazy(() => import('../pages/organisations/AddOrganisation')),
	ORGANISATIONS: lazy(() => import('../pages/organisations/OrganisationsList')),
	ADD_DEPARTMENT: lazy(() => import('../pages/departments/AddDepartment')),
	DEPARTMENTS: lazy(() => import('../pages/departments/DepartmentsList')),
	OPD_STATUSES: lazy(() => import('../pages/settings/OPDStatuses')),
	CHIEF_COMPLAINTS: lazy(() => import('../pages/settings/ChiefComplaints')),
	ORAL_EXAMINATIONS: lazy(() => import('../pages/settings/OralExaminations')),
	DIAGNOSES: lazy(() => import('../pages/settings/Diagnoses')),
	COMMUNICATION_GROUPS: lazy(() => import('../pages/settings/CommunicationGroups')),
	REFERRAL_TYPES: lazy(() => import('../pages/settings/ReferralTypes')),
	EXTERNAL_REFERRERS: lazy(() => import('../pages/settings/ExternalReferrers')),
	TREATMENTS: lazy(() => import('../pages/settings/Treatments')),
	PRESCRIPTION_TEMPLATES: lazy(() => import('../pages/settings/PrescriptionTemplates')),
	INVESTIONGATION_TEMPLATES: lazy(() => import('../pages/settings/InvestigationTemplates')),
	ROS_TEMPLATES: lazy(() => import('../pages/settings/RosTemplates.js')),
	NOTES: lazy(() => import('../pages/settings/Notes')),
	SPECIAL_INSTRUCTION_TEMPLATES: lazy(() =>
		import('../pages/settings/SpecialInstructionTemplates'),
	),
	BILL_PREFIX: lazy(() => import('../pages/settings/BillPrefix')),
	INVESTIGATIONS: lazy(() => import('../pages/settings/Investigations')),
	ALGOSUBSCRIPTION: lazy(() => import('../pages/settings/AlgoSubscription')),
	MEDICINE_ROWS: lazy(() => import('../pages/settings/MedicineRows')),
	DOCTOR_MEDICINES: lazy(() => import('../pages/settings/DoctorMedicines')),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
	LOGIN: lazy(() => import('../pages/auth/Login')),
	LOGOUT: lazy(() => import('../pages/auth/Logout')),
	PRIVACY_POLICY: lazy(() => import('../pages/auth/PrivacyPolicy')),
	REFUND_POLICY: lazy(() => import('../pages/auth/RefundPolicy')),
	TERMS_AND_CONDITIONS: lazy(() => import('../pages/auth/TermsAndConditions')),
	CONTACT_US: lazy(() => import('../pages/auth/ContactUs')),
};

const PATIENT_MANAGEMENT = {
	MAIN_PAGE: lazy(() => import('../pages/patient-management/index')),
};

const contents = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.remoteClinic.subMenu.sharedFiles.path,
	// 	element: <REMOTE_CLINIC.SHARED_FILES />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.remoteClinic.subMenu.remoteClinicAppointments.path,
	// 	element: <REMOTE_CLINIC.REMOTE_CLINIC_APPOINTMENTS />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.remoteClinic.subMenu.myReferrals.path,
	// 	element: <REMOTE_CLINIC.MY_REFERRALS />,
	// 	exact: true,
	// },

	{
		path: dashboardMenu.appointments.subMenu.addAppointment.path,
		element: <APPOINTMENTS.ADD />,
		exact: true,
	},
	{
		path: dashboardMenu.appointments.subMenu.viewAppointments.path,
		element: <APPOINTMENTS.LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.preventiveCareProgram.subMenu.programsList.path,
		element: <PCP.LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.preventiveCareProgram.subMenu.classList.path,
		element: <PCP.CLASS_LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.preventiveCareProgram.subMenu.createProgramRequest.path,
		element: <PCP.CREATE_PROGRAM />,
		exact: true,
	},
	{
		path: dashboardMenu.preventiveCareProgram.subMenu.programSetup.path,
		element: <PCP.PROGRAM_SETUP />,
		exact: true,
	},
	{
		path: dashboardMenu.preventiveCareProgram.subMenu.viewProgram.path,
		element: <PCP.VIEW_PROGRAM />,
		exact: true,
	},
	{
		path: dashboardMenu.preventiveCareProgram.subMenu.addSubscriber.path,
		element: <PCP.ADD_SUBSCRIBER />,
		exact: true,
	},
	{
		path: dashboardMenu.preventiveCareProgram.subMenu.viewSubscriber.path,
		element: <PCP.VIEW_SUBSCRIBER />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.addPatient.path,
		element: <PATIENTS.ADD />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.prescriptionView.path,
		element: <PATIENTS.PRESCRIPTION_VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.aida.path,
		element: <PATIENTS.AIDA />,
		exact: true,
	},

	{
		path: dashboardMenu.patients.subMenu.aida.path,
		element: <PATIENTS.PRESCRIPTION_VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.viewPatient.path,
		element: <PATIENTS.VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.viewPatients.path,
		element: <PATIENTS.LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.algorithms.path,
		element: <SETTINGS.ALGOSUBSCRIPTION />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.medicineRows.path,
		element: <SETTINGS.MEDICINE_ROWS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.doctorMedicines.path,
		element: <SETTINGS.DOCTOR_MEDICINES />,
		exact: true,
	},

	// --------- Patient Management
	{
		path: dashboardMenu.patientManagement.path,
		element: <PATIENT_MANAGEMENT.MAIN_PAGE />,
		exact: true,
	},

	// --------- Patient PDFs
	// {
	// 	path: dashboardMenu.patients.subMenu.patientExaminationPdf.path,
	// 	element: <PATIENTS.EXAMINATION_PDF />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.patients.subMenu.patientTreatmentPlanPdf.path,
	// 	element: <PATIENTS.TREATMENT_PLAN_PDF />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.patients.subMenu.patientTreatmentPdf.path,
	// 	element: <PATIENTS.TREATMENT_PDF />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.patients.subMenu.patientPrescriptionPdf.path,
		element: <PATIENTS.PRESCRIPTION_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientDiagnosesPdf.path,
		element: <PATIENTS.DIAGNOSES_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientVoicePrescriptionPdf.path,
		element: <PATIENTS.VOICE_PRESCRIPTION_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientBillingPdf.path,
		element: <PATIENTS.BILLING_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientPaymentPdf.path,
		element: <PATIENTS.PAYMENT_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientOpdPdf.path,
		element: <PATIENTS.OPD_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientHistoryPdf.path,
		element: <PATIENTS.HISTORY_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientVisionPdf.path,
		element: <PATIENTS.VISION_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientImmunPdf.path,
		element: <PATIENTS.IMMUNISATION_PDF />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.patients.subMenu.patientShortReport.path,
	// 	element: <PATIENTS.SHORT_REPORT />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.patients.subMenu.patientLongReport.path,
	// 	element: <PATIENTS.LONG_REPORT />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.patients.subMenu.patientDetailedLongReport.path,
		element: <PATIENTS.DETAILED_LONG_REPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientChemoNotePdf.path,
		element: <PATIENTS.CHEMO_NOTE_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientAttachmentPdf.path,
		element: <PATIENTS.PATIENT_ATTACHMENT_PDF />,
		exact: true,
	},

	// ------ END OF Patient PDFs

	{
		path: dashboardMenu.opd.subMenu.addOpdRecord.path,
		element: <OPD.ADD_RECORD />,
		exact: true,
	},
	{
		path: dashboardMenu.opd.subMenu.viewOpdRecords.path,
		element: <OPD.VIEW_RECORDS />,
		exact: true,
	},
	{
		path: dashboardMenu.opd.subMenu.pdfHeader.path,
		element: <OPD.PDF_HEADER />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.ipd.subMenu.viewAccommodations.path,
	// 	element: <IPD.VIEW_ACCOMMODATIONS />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.ipd.subMenu.addAccommodation.path,
	// 	element: <IPD.ADD_ACCOMMODATION />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.ipd.subMenu.createIpd.path,
		element: <IPD.CREATE_IPD />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.roomStatus.path,
		element: <IPD.ROOM_STATUS />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.roomStatusPdf.path,
		element: <IPD.ROOM_STATUS_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.MIS.path,
		element: <IPD.IPD_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.ipdsettings.path,
		element: <IPD.IPD_SETTINGS />,
	},
	{
		path: dashboardMenu.ipd.subMenu.patientIpdFile.path,
		element: <IPD.PATIENT_IPD_FILE />,
	},
	{
		path: dashboardMenu.ipd.subMenu.dischargeSummaryPdf.path,
		element: <IPD.DISCHARGE_SUMMARY_PDF />,
	},
	{
		path: dashboardMenu.ipd.subMenu.dischargeSummary2Pdf.path,
		element: <IPD.DISCHARGE_SUMMARY2_PDF />,
	},
	{
		path: dashboardMenu.ipd.subMenu.ipdBillingPdf.path,
		element: <IPD.IPD_BILLING_PDF />,
	},
	{
		path: dashboardMenu.ipd.subMenu.ipdPaymentsPdf.path,
		element: <IPD.IPD_PAYMENTS_PDF />,
	},
	{
		path: dashboardMenu.ipd.subMenu.faceCard.path,
		element: <IPD.IPD_FACE_CARD />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.storeItems.path,
		element: <STORE.STORE_ITEMS />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.storePurchases.path,
		element: <STORE.STORE_PURCHASE />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.addStorePurchase.path,
		element: <STORE.ADD_STORE_PURCHASE />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.salesIndentation.path,
		element: <STORE.SALES_INDENTATION />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.addSalesIndentation.path,
		element: <STORE.ADD_SALES_INDENTATION />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.storeInventory.path,
		element: <STORE.STORE_INVENTORY />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.ipdList.path,
		element: <IPD.IPD_LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.ipd.subMenu.ipdListPdf.path,
		element: <IPD.IPD_LIST_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addPharmacy.path,
		element: <PHARMACY.ADD_PHARMACY />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.pharmacySettings.path,
		element: <PHARMACY.PHARMACY_SETTINGS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addVendor.path,
		element: <PHARMACY.ADD_VENDOR />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.vendors.path,
		element: <PHARMACY.VENDORS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.paymentsList.path,
		element: <PHARMACY.PAYMENT_LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addPurchaseVoucher.path,
		element: <PHARMACY.ADD_PURCHASE_VOUCHER />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.returnPurchaseVouchers.path,
		element: <PHARMACY.RETURN_PURCHASE_VOUCHER />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.purchaseVouchers.path,
		element: <PHARMACY.PURCHASE_VOUCHERS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.inventory.path,
		element: <PHARMACY.INVENTORY />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.stockMedicine.path,
		element: <PHARMACY.STOCK_MEDICINE />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.expiryDateMedicine.path,
		element: <PHARMACY.EXPIRY_DATE_MEDICINE />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.gstSummary.path,
		element: <PHARMACY.GST_REPORTS_SUMMARY />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.gstReports.path,
		element: <PHARMACY.GST_REPORTS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.gstReport3B.path,
		element: <PHARMACY.GST_REPORT_3B />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.gstReportsPrint.path,
		element: <PHARMACY.GST_REPORTS_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.addSaleVoucher.path,
		element: <PHARMACY.ADD_SALE_VOUCHER />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.saleVouchers.path,
		element: <PHARMACY.SALE_VOUCHERS />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.wholeSaleVoucher.path,
		element: <PHARMACY.WHOLESALES />,
		exact: true,
	},

	{
		path: dashboardMenu.pharmacy.subMenu.wholeSalePdf.path,
		element: <PHARMACY.WHOLESALES_PDF />,
		exact: true,
	},

	{
		path: dashboardMenu.pharmacy.subMenu.addWholeSaleVoucher.path,
		element: <PHARMACY.ADD_WHOLESALES />,
		exact: true,
	},
	{
		path: dashboardMenu.pharmacy.subMenu.saleVoucher.path,
		element: <PHARMACY.SALE_VOUCHER_PDF />,
		exact: true,
	},

	{
		path: dashboardMenu.pharmacy.subMenu.vendorProfile.path,
		element: <PHARMACY.VENDOR_PROFILE />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.addStaff.path,
		element: <STAFF.ADD_STAFF />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.viewStaff.path,
		element: <STAFF.VIEW_STAFF />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.staffPermissions.path,
		element: <STAFF.VIEW_STAFF_PERMISSIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.viewStaffAttendance.path,
		element: <STAFF.VIEW_STAFF_ATTENDANCE />,
		exact: true,
	},
	{
		path: dashboardMenu.staff.subMenu.viewStaffDetails.path,
		element: <STAFF.VIEW_STAFF_DETAILS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.drWiseStaffMisPDF.path,
		element: <STAFF.EXPORT_DR_MISS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.addVendor.path,
		element: <ACCOUNTS.ADD_VENDOR />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.vendors.path,
		element: <ACCOUNTS.VENDORS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.addTransactions.path,
		element: <ACCOUNTS.ADD_TRANSACTION />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.transactions.path,
		element: <ACCOUNTS.TRANSACTION />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.expenses.path,
		element: <ACCOUNTS.EXPENSE />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.summary.path,
		element: <ACCOUNTS.SUMMARY />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.printDrWise.path,
		element: <ACCOUNTS.EXPORT_DR_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.printMis.path,
		element: <ACCOUNTS.EXPORT_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.mis.path,
		element: <ACCOUNTS.MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.misSpecific.path,
		element: <ACCOUNTS.MIS_SPECIFIC />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.staffMis.path,
		element: <ACCOUNTS.STAFF_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.staffMisSpecific.path,
		element: <ACCOUNTS.STAFF_MIS_SPECIFIC />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.staffMisPDF.path,
		element: <ACCOUNTS.STAFF_MIS_SPECIFIC_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.patientMis.path,
		element: <ACCOUNTS.PATIENT_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.patientMis.subMenu.ageView.path,
		element: <ACCOUNTS.PATIENT_MIS_AGE_VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.patientMis.subMenu.cityView.path,
		element: <ACCOUNTS.PATIENT_MIS_CITY_VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.doctorMis.path,
		element: <ACCOUNTS.DOCTOR_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.doctorMis.subMenu.doctorView.path,
		element: <ACCOUNTS.DOCTOR_MIS_VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.rxMis.path,
		element: <ACCOUNTS.RX_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.diagnosticMis.path,
		element: <ACCOUNTS.DIAGNOSTIC_MIS />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.diagnosticMis.subMenu.diagnosticView.path,
		element: <ACCOUNTS.DIAGNOSTIC_MIS_VIEW />,
		exact: true,
	},
	{
		path: dashboardMenu.accounts.subMenu.rxMis.subMenu.rxView.path,
		element: <ACCOUNTS.RX_MIS_VIEW />,
		exact: true,
	},
	// Lab Pages
	// {
	// 	path: dashboardMenu.lab.subMenu.labTest.path,
	// 	element: <LAB.LAB_TEST />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.lab.subMenu.labParameters.path,
		element: <LAB.LAB_PARAMETERS />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.labTemplates.path,
		element: <LAB.LAB_TEMPLATES />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.billing.path,
		element: <LAB.LAB_BILLING />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.addBilling.path,
		element: <LAB.ADD_LAB_BILLING />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.billingPdf.path,
		element: <LAB.LAB_BILLING_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.lab.subMenu.labReportPdf.path,
		element: <LAB.LAB_REPORT_PDF />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.consents.path,
		element: <SETTINGS.CONSENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.profile.path,
		element: <SETTINGS.PROFILE />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.vendorSettings.path,
		element: <SETTINGS.DOCTOR_PROFILE />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.addDoctor.path,
		element: <SETTINGS.ADD_DOCTOR />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.viewDoctors.path,
		element: <SETTINGS.DOCTORS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.addOrganisation.path,
		element: <SETTINGS.ADD_ORGANISATION />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.viewOrganisations.path,
		element: <SETTINGS.ORGANISATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.opdStatuses.path,
		element: <SETTINGS.OPD_STATUSES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.chiefComplaints.path,
		element: <SETTINGS.CHIEF_COMPLAINTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.diagnoses.path,
		element: <SETTINGS.DIAGNOSES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.communicationGroups.path,
		element: <SETTINGS.COMMUNICATION_GROUPS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.referralTypes.path,
		element: <SETTINGS.REFERRAL_TYPES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.externalReferrers.path,
		element: <SETTINGS.EXTERNAL_REFERRERS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.treatments.path,
		element: <SETTINGS.TREATMENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.prescriptionTemplates.path,
		element: <SETTINGS.PRESCRIPTION_TEMPLATES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.investigationTemplates.path,
		element: <SETTINGS.INVESTIONGATION_TEMPLATES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.rosTemplates.path,
		element: <SETTINGS.ROS_TEMPLATES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.notes.path,
		element: <SETTINGS.NOTES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.specialInstructionTemplates.path,
		element: <SETTINGS.SPECIAL_INSTRUCTION_TEMPLATES />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.billprefix.path,
		element: <SETTINGS.BILL_PREFIX />,
		exact: true,
	},

	{
		path: dashboardMenu.consultWithDoctor.path,
		element: <ConsultWithExpert.ZOOM />,
		exact: true,
	},
	{
		path: dashboardMenu.doctorJoinZoomMeeting.path,
		element: <ConsultWithExpert.ZOOM />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.investigations.path,
		element: <SETTINGS.INVESTIGATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.oralExaminations.path,
		element: <SETTINGS.ORAL_EXAMINATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.telecrm.subMenu.allPatients.path,
		element: <TELE_CRM.ALL_PATIENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.telecrm.subMenu.allNotes.path,
		element: <TELE_CRM.ALL_NOTES />,
		exact: true,
	},
	{
		path: dashboardMenu.telecrm.subMenu.addLeadSuggestion.path,
		element: <TELE_CRM.ADD_LEAD_SUGGESTION />,
		exact: true,
	},
	{
		path: dashboardMenu.telecrm.subMenu.callLogs.path,
		element: <TELE_CRM.CALL_LOGS />,
		exact: true,
	},
	{
		path: dashboardMenu.telecrm.subMenu.patientDetails.path,
		element: <TELE_CRM.PATIENT_DETAILS />,
		exact: true,
	},
	{
		path: dashboardMenu.telecrm.subMenu.patientCallLogs.path,
		element: <TELE_CRM.PATIENT_CALL_LOGS />,
		exact: true,
	},
	{
		path: dashboardMenu.telecrm.subMenu.patientAppointmentLogs.path,
		element: <TELE_CRM.PATIENT_APPOINTMENT_LOGS />,
		exact: true,
	},

	{
		path: dashboardMenu.telecrm.subMenu.leads.path,
		element: <TELE_CRM.LEADS />,
		exact: true,
	},

	// Unused
	{
		path: dashboardMenu.settings.subMenu.addDepartment.path,
		element: <SETTINGS.ADD_DEPARTMENT />,
		exact: true,
	},
	{
		path: dashboardMenu.settings.subMenu.viewDepartments.path,
		element: <SETTINGS.DEPARTMENTS />,
		exact: true,
	},
	/** ************************************************** */

	//Calculator Route
	{
		path: calculatorMenu.mainPage.path,
		element: <CALCULATOR.MAIN_PAGE />,
		exact: true,
	},
	{
		path: calculatorMenu.alleregy.path,
		element: <CALCULATOR.ALLERGY_CAL />,
		exact: true,
	},
	{
		path: calculatorMenu.anesthesiology.path,
		element: <CALCULATOR.ANESTHESIOLOGY_CAL />,
		exact: true,
	},
	{
		path: calculatorMenu.oncology.path,
		element: <CALCULATOR.ONCOLOGY_CAL />,
		exact: true,
	},
	/**
	 * Auth Page
	 */
	{
		path: authMenu.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: authMenu.logout.path,
		element: <AUTH.LOGOUT />,
		exact: true,
	},
	{
		path: profileMenu.privacyPolicy.path,
		element: <AUTH.PRIVACY_POLICY />,
		exact: true,
	},
	{
		path: profileMenu.refundPolicy.path,
		element: <AUTH.REFUND_POLICY />,
		exact: true,
	},
	{
		path: profileMenu.termsAndConditions.path,
		element: <AUTH.TERMS_AND_CONDITIONS />,
		exact: true,
	},
	{
		path: profileMenu.contactUs.path,
		element: <AUTH.CONTACT_US />,
		exact: true,
	},
	// {
	// 	path: authMenu.signUp.path,
	// 	element: <Login isSignUp />,
	// 	exact: true,
	// },
];

export default contents;
