import {
	SET_ALL_CHIEF_COMPLAINTS,
	SET_CHIEF_COMPLAINTS_VALUES,
	SET_WHOLE_FAMILY_HISTORY,
	SET_SOCIAL_HISTORY,
	SET_DRUG_HISTORY,
	SET_WHOLE_PATIENT_HISTORY,
	SET_DISEASES,
	SET_HISTORY_LOADING,
	SET_PAST_HISTORY,
} from '../reducers/types';
import showNotification from '../components/extras/showNotification';
import axios, { custom } from '../helpers/axios';
import { toggleCreateLoading } from './componentActions';

export const getPatientHistory = (patient_id) => async (dispatch) => {
	try {
		const response = await custom.get(`/v1/hs/patient-history/?patient_id=${patient_id}`);
		// console.log(response.data)
		if (response.status == 200) {
			dispatch({
				type: SET_WHOLE_PATIENT_HISTORY,
				payload: response.data[0],
			});
		}
	} catch (err) {
		console.log(err.message);
	}
};

export const getCustomRemarks = (actionType, type) => async (dispatch) => {
	try {
		console.log(type);
		const response = await axios.get(`/doctor/patient/remarks?type=${type}`);
		if (response.status === 200) {
			dispatch({
				type: actionType,
				payload: response.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

// Chief Complains Actions Starts

export const getCCValues = (doctor_id) => async (dispatch) => {
	try {
		dispatch({
			type: SET_HISTORY_LOADING,
		});
		const response = await custom.get(`/v1/hs/cc-values/?doctor_id=${doctor_id}`);
		if (response.status == 200) {
			dispatch({
				type: SET_CHIEF_COMPLAINTS_VALUES,
				payload: response.data,
			});
		}
	} catch (err) {
		console.log(err.message);
	}
};

export const getFamilyHistory = (patientId, doctorId) => async (dispatch) => {
	try {
		const res = await axios.patch(`doctor/patient/family-history`, {
			doctor_id: doctorId,
			patient_id: patientId,
		});
		dispatch({ type: SET_WHOLE_FAMILY_HISTORY, payload: res.data });
	} catch (error) {
		console.error(error);
	}
};

export const createFamilyHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/patient/family-history', data);
		if (res.status == 200) {
			showNotification('Success', 'Family History created successfully', 'success');

			dispatch(getFamilyHistory(data?.patient_id, data?.doctor_id));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPastHistory = (doctorId, patinetId) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/patient/past-history`, {
			doctor_id: doctorId,
			patient_id: patinetId,
		});
		dispatch({ type: SET_PAST_HISTORY, payload: res.data });
	} catch (error) {
		console.error(error);
	}
};

export const createPastHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post(`/doctor/patient/past-history`, data);
		if (res.status == 200) {
			showNotification('Success', 'Past History created successfully', 'success');
			dispatch(getPastHistory(data?.doctor_id, data?.patient_id));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getSocialHistoryV2 = (doctorId, patinetId) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/patient/social-history`, {
			doctor_id: doctorId,
			patient_id: patinetId,
		});
		dispatch({ type: SET_SOCIAL_HISTORY, payload: res.data });
	} catch (error) {
		console.error(error);
	}
};

export const createSocialHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/patient/social-history', data);
		if (res.status == 200) {
			showNotification('Success', 'Social History created successfully', 'success');

			dispatch(getSocialHistoryV2(data?.doctor_id, data?.patient_id));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getDrugHistoryV2 = (doctor, patient) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/patient/drug-allergy-history`, {
			doctor_id: doctor,
			patient_id: patient,
		});
		dispatch({ type: SET_DRUG_HISTORY, payload: res.data });
	} catch (error) {
		console.error(error);
	}
};

export const createDrugHistory = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/patient/drug-allergy-history', data);
		if (res.status == 200) {
			showNotification('Success', 'Drug History created successfully', 'success');
			dispatch(getDrugHistoryV2(data?.doctor_id, data?.patient_id));
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};
