import moment from 'moment';
import React from 'react';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';

function FetalSurvey({ formik }) {
	return (
		<div className='row mb-3'>
			<div className='col-10 text-center mb-2'>
				<strong>Fetal Survey Pertinent Findings</strong>
			</div>
			<div className='col-2 mb-2'>
				<Button
					isOutline
					color='primary'
					icon='add'
					onClick={(e) =>
						formik.setFieldValue('fetalSurvey', [
							...formik.values.fetalSurvey,
							{
								date: moment().format('YYYY-MM-DD'),
								findings: '',
							},
						])
					}>
					Add Row
				</Button>
			</div>
			<table className='table table-bordered'>
				<tbody>
					<tr>
						<th>Date</th>
						<th>Findings</th>
						<th></th>
					</tr>
					{formik.values.fetalSurvey?.map((survey, index) => (
						<tr key={index}>
							<td>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='date'
									className='form-control p-3 shadow-none'
									value={survey.date}
									name={`fetalSurvey[${index}].date`}
									onChange={formik.handleChange}
								/>
							</td>
							<td>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='text'
									className='form-control p-3 shadow-none'
									value={survey.findings}
									name={`fetalSurvey[${index}].findings`}
									onChange={formik.handleChange}
								/>
							</td>
							<td>
								<Button
									icon='delete'
									color='danger'
									isOutline
									onClick={() =>
										formik.setFieldValue('fetalSurvey', [
											...formik.values.fetalSurvey.slice(0, index),
											...formik.values.fetalSurvey.slice(index + 1),
										])
									}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default FetalSurvey;
