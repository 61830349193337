import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import moment from 'moment';
import { ModalFooter } from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Icon from '../../../../components/icon/Icon';
import Spinner from '../../../../components/bootstrap/Spinner';
import { isPermitted } from '../../../../actions/helperActions';
import { uploadSoapAttachment } from '../../../../actions/patientActions';

const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];
const strokes = {
	Pen: 5,
	Pencil: 10,
	Brush: 15,
};

const PrescriptionDraw = ({ patient, drawImage, setDrawModal, setChooseDrawModal }) => {
	const canvasRef = useRef(null);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);

	const [strokeColor, setStrokeColor] = useState(colors[0]);
	const [strokeWidth, setStrokeWidth] = useState('Pen');
	const [eraseMode, setEraseMode] = useState(false);
	const [exporting, setExporting] = useState(false);

	const getColorValue = (color) => {
		return getComputedStyle(document.body).getPropertyValue(`--bs-${color}`);
	};

	const handleSaveAttachment = async () => {
		setExporting(true);

		let data = new FormData();
		data.append('patient', patient?.id);
		data.append('doctor', user?.Vendor_detail?.vendorId);
		data.append('date', moment().format('YYYY-MM-DD'));

		// SVG to File
		const svg = await canvasRef.current.exportSvg();

		const blob = new Blob([svg], { type: 'image/svg+xml' });
		const file = new File([blob], 'new.svg', { type: 'image/svg+xml' });

		data.append('images', file);

		// Upload
		dispatch(uploadSoapAttachment(data));

		setExporting(false);
		if (setDrawModal) setDrawModal(false);
		if (setChooseDrawModal) setChooseDrawModal(false);
	};

	return (
		<>
			<div className='row'>
				<div className='col-1'>
					{colors.map((c) => (
						<tr key={c}>
							<td
								style={{ padding: '0.7rem' }}
								className={
									c === strokeColor && strokeColor === 'light'
										? 'bg-dark rounded'
										: c === strokeColor
										? 'bg-light rounded'
										: ''
								}>
								<Button
									className='rounded-circle'
									color={c}
									onClick={() => setStrokeColor(c)}
								/>
							</td>
						</tr>
					))}
				</div>
				<div className='col-10'>
					<ReactSketchCanvas
						ref={canvasRef}
						width='910px'
						height='580px'
						preserveBackgroundImageAspectRatio='xMidYMid meet'
						backgroundImage={drawImage}
						exportWithBackgroundImage
						strokeWidth={strokes[strokeWidth]}
						eraserWidth={strokes[strokeWidth]}
						strokeColor={getColorValue(strokeColor)}
					/>
				</div>
				<div className='col-1'>
					{Object.keys(strokes).map((s) => (
						<tr key={s}>
							<td className={s === strokeWidth ? 'bg-light rounded' : ''}>
								<Button className='p-3' onClick={() => setStrokeWidth(s)}>
									<Icon icon={s} size='2x' forceFamily='bootstrap' />
								</Button>
							</td>
						</tr>
					))}
					<tr style={{ height: '5px' }}></tr>
					<tr>
						<td className={eraseMode ? 'bg-light rounded' : ''}>
							<Button
								className='p-3'
								onClick={() => {
									setEraseMode((prev) => {
										canvasRef.current.eraseMode(!prev);
										return !prev;
									});
								}}>
								<Icon icon={'Eraser'} size='2x' />
							</Button>
						</td>
					</tr>
					<tr>
						<td>
							<Button className='p-3' onClick={() => canvasRef.current.undo()}>
								<Icon icon={'Undo'} size='2x' />
							</Button>
						</td>
					</tr>
					<tr>
						<td>
							<Button className='p-3' onClick={() => canvasRef.current.redo()}>
								<Icon icon={'Redo'} size='2x' />
							</Button>
						</td>
					</tr>
					<tr>
						<td>
							<Button className='p-3' onClick={() => canvasRef.current.clearCanvas()}>
								<Icon icon={'DeleteOutline'} size='2x' />
							</Button>
						</td>
					</tr>
				</div>
			</div>

			<ModalFooter className='mt-2'>
				<Button
					isDisable={exporting}
					color='primary'
					isOutline
					className='border-0 me-3'
					onClick={() => setDrawModal(false)}>
					Cancel
				</Button>

				<Button
					color='primary'
					icon={!exporting && 'check'}
					isDisable={exporting}
					onClick={() => isPermitted('Medical Profile', 'write', handleSaveAttachment)}>
					{exporting ? (
						<>
							<Spinner isGrow size='1.2rem' className='me-2' /> Uploading
						</>
					) : (
						'Save'
					)}
				</Button>
			</ModalFooter>
		</>
	);
};

export default PrescriptionDraw;
