import moment from 'moment';
import * as XLSX from 'xlsx';
import { getInventoryByComposition } from '../actions/helperActions';
import { custom } from './axios';
import axios from './axios';

export const isValidData = (value) => {
	if (value === null || value === undefined) return false;

	if (typeof value === 'number') return !isNaN(value);

	if (Array.isArray(value)) return value.length > 0;

	if (typeof value === 'object') {
		return Object.keys(value).length > 0;
	}

	return value !== '';
};

export function test() {
	return null;
}

export function getCountryPhoneCodes() {
	return [
		{ country: 'Afghanistan', code: '93', iso: 'AF' },
		{ country: 'Albania', code: '355', iso: 'AL' },
		{ country: 'Algeria', code: '213', iso: 'DZ' },
		{ country: 'American Samoa', code: '1-684', iso: 'AS' },
		{ country: 'Andorra', code: '376', iso: 'AD' },
		{ country: 'Angola', code: '244', iso: 'AO' },
		{ country: 'Anguilla', code: '1-264', iso: 'AI' },
		{ country: 'Antarctica', code: '672', iso: 'AQ' },
		{ country: 'Antigua and Barbuda', code: '1-268', iso: 'AG' },
		{ country: 'Argentina', code: '54', iso: 'AR' },
		{ country: 'Armenia', code: '374', iso: 'AM' },
		{ country: 'Aruba', code: '297', iso: 'AW' },
		{ country: 'Australia', code: '61', iso: 'AU' },
		{ country: 'Austria', code: '43', iso: 'AT' },
		{ country: 'Azerbaijan', code: '994', iso: 'AZ' },
		{ country: 'Bahamas', code: '1-242', iso: 'BS' },
		{ country: 'Bahrain', code: '973', iso: 'BH' },
		{ country: 'Bangladesh', code: '880', iso: 'BD' },
		{ country: 'Barbados', code: '1-246', iso: 'BB' },
		{ country: 'Belarus', code: '375', iso: 'BY' },
		{ country: 'Belgium', code: '32', iso: 'BE' },
		{ country: 'Belize', code: '501', iso: 'BZ' },
		{ country: 'Benin', code: '229', iso: 'BJ' },
		{ country: 'Bermuda', code: '1-441', iso: 'BM' },
		{ country: 'Bhutan', code: '975', iso: 'BT' },
		{ country: 'Bolivia', code: '591', iso: 'BO' },
		{ country: 'Bosnia and Herzegovina', code: '387', iso: 'BA' },
		{ country: 'Botswana', code: '267', iso: 'BW' },
		{ country: 'Brazil', code: '55', iso: 'BR' },
		{ country: 'British Indian Ocean Territory', code: '246', iso: 'IO' },
		{ country: 'British Virgin Islands', code: '1-284', iso: 'VG' },
		{ country: 'Brunei', code: '673', iso: 'BN' },
		{ country: 'Bulgaria', code: '359', iso: 'BG' },
		{ country: 'Burkina Faso', code: '226', iso: 'BF' },
		{ country: 'Burundi', code: '257', iso: 'BI' },
		{ country: 'Cambodia', code: '855', iso: 'KH' },
		{ country: 'Cameroon', code: '237', iso: 'CM' },
		{ country: 'Canada', code: '1', iso: 'CA' },
		{ country: 'Cape Verde', code: '238', iso: 'CV' },
		{ country: 'Cayman Islands', code: '1-345', iso: 'KY' },
		{ country: 'Central African Republic', code: '236', iso: 'CF' },
		{ country: 'Chad', code: '235', iso: 'TD' },
		{ country: 'Chile', code: '56', iso: 'CL' },
		{ country: 'China', code: '86', iso: 'CN' },
		{ country: 'Christmas Island', code: '61', iso: 'CX' },
		{ country: 'Cocos Islands', code: '61', iso: 'CC' },
		{ country: 'Colombia', code: '57', iso: 'CO' },
		{ country: 'Comoros', code: '269', iso: 'KM' },
		{ country: 'Cook Islands', code: '682', iso: 'CK' },
		{ country: 'Costa Rica', code: '506', iso: 'CR' },
		{ country: 'Croatia', code: '385', iso: 'HR' },
		{ country: 'Cuba', code: '53', iso: 'CU' },
		{ country: 'Curacao', code: '599', iso: 'CW' },
		{ country: 'Cyprus', code: '357', iso: 'CY' },
		{ country: 'Czech Republic', code: '420', iso: 'CZ' },
		{ country: 'Democratic Republic of the Congo', code: '243', iso: 'CD' },
		{ country: 'Denmark', code: '45', iso: 'DK' },
		{ country: 'Djibouti', code: '253', iso: 'DJ' },
		{ country: 'Dominica', code: '1-767', iso: 'DM' },
		{ country: 'Dominican Republic', code: '1-809, 1-829, 1-849', iso: 'DO' },
		{ country: 'East Timor', code: '670', iso: 'TL' },
		{ country: 'Ecuador', code: '593', iso: 'EC' },
		{ country: 'Egypt', code: '20', iso: 'EG' },
		{ country: 'El Salvador', code: '503', iso: 'SV' },
		{ country: 'Equatorial Guinea', code: '240', iso: 'GQ' },
		{ country: 'Eritrea', code: '291', iso: 'ER' },
		{ country: 'Estonia', code: '372', iso: 'EE' },
		{ country: 'Ethiopia', code: '251', iso: 'ET' },
		{ country: 'Falkland Islands', code: '500', iso: 'FK' },
		{ country: 'Faroe Islands', code: '298', iso: 'FO' },
		{ country: 'Fiji', code: '679', iso: 'FJ' },
		{ country: 'Finland', code: '358', iso: 'FI' },
		{ country: 'France', code: '33', iso: 'FR' },
		{ country: 'French Polynesia', code: '689', iso: 'PF' },
		{ country: 'Gabon', code: '241', iso: 'GA' },
		{ country: 'Gambia', code: '220', iso: 'GM' },
		{ country: 'Georgia', code: '995', iso: 'GE' },
		{ country: 'Germany', code: '49', iso: 'DE' },
		{ country: 'Ghana', code: '233', iso: 'GH' },
		{ country: 'Gibraltar', code: '350', iso: 'GI' },
		{ country: 'Greece', code: '30', iso: 'GR' },
		{ country: 'Greenland', code: '299', iso: 'GL' },
		{ country: 'Grenada', code: '1-473', iso: 'GD' },
		{ country: 'Guam', code: '1-671', iso: 'GU' },
		{ country: 'Guatemala', code: '502', iso: 'GT' },
		{ country: 'Guernsey', code: '44-1481', iso: 'GG' },
		{ country: 'Guinea', code: '224', iso: 'GN' },
		{ country: 'Guinea-Bissau', code: '245', iso: 'GW' },
		{ country: 'Guyana', code: '592', iso: 'GY' },
		{ country: 'Haiti', code: '509', iso: 'HT' },
		{ country: 'Honduras', code: '504', iso: 'HN' },
		{ country: 'Hong Kong', code: '852', iso: 'HK' },
		{ country: 'Hungary', code: '36', iso: 'HU' },
		{ country: 'Iceland', code: '354', iso: 'IS' },
		{ country: 'India', code: '91', iso: 'IN' },
		{ country: 'Indonesia', code: '62', iso: 'ID' },
		{ country: 'Iran', code: '98', iso: 'IR' },
		{ country: 'Iraq', code: '964', iso: 'IQ' },
		{ country: 'Ireland', code: '353', iso: 'IE' },
		{ country: 'Isle of Man', code: '44-1624', iso: 'IM' },
		{ country: 'Israel', code: '972', iso: 'IL' },
		{ country: 'Italy', code: '39', iso: 'IT' },
		{ country: 'Ivory Coast', code: '225', iso: 'CI' },
		{ country: 'Jamaica', code: '1-876', iso: 'JM' },
		{ country: 'Japan', code: '81', iso: 'JP' },
		{ country: 'Jersey', code: '44-1534', iso: 'JE' },
		{ country: 'Jordan', code: '962', iso: 'JO' },
		{ country: 'Kazakhstan', code: '7', iso: 'KZ' },
		{ country: 'Kenya', code: '254', iso: 'KE' },
		{ country: 'Kiribati', code: '686', iso: 'KI' },
		{ country: 'Kosovo', code: '383', iso: 'XK' },
		{ country: 'Kuwait', code: '965', iso: 'KW' },
		{ country: 'Kyrgyzstan', code: '996', iso: 'KG' },
		{ country: 'Laos', code: '856', iso: 'LA' },
		{ country: 'Latvia', code: '371', iso: 'LV' },
		{ country: 'Lebanon', code: '961', iso: 'LB' },
		{ country: 'Lesotho', code: '266', iso: 'LS' },
		{ country: 'Liberia', code: '231', iso: 'LR' },
		{ country: 'Libya', code: '218', iso: 'LY' },
		{ country: 'Liechtenstein', code: '423', iso: 'LI' },
		{ country: 'Lithuania', code: '370', iso: 'LT' },
		{ country: 'Luxembourg', code: '352', iso: 'LU' },
		{ country: 'Macao', code: '853', iso: 'MO' },
		{ country: 'Macedonia', code: '389', iso: 'MK' },
		{ country: 'Madagascar', code: '261', iso: 'MG' },
		{ country: 'Malawi', code: '265', iso: 'MW' },
		{ country: 'Malaysia', code: '60', iso: 'MY' },
		{ country: 'Maldives', code: '960', iso: 'MV' },
		{ country: 'Mali', code: '223', iso: 'ML' },
		{ country: 'Malta', code: '356', iso: 'MT' },
		{ country: 'Marshall Islands', code: '692', iso: 'MH' },
		{ country: 'Mauritania', code: '222', iso: 'MR' },
		{ country: 'Mauritius', code: '230', iso: 'MU' },
		{ country: 'Mayotte', code: '262', iso: 'YT' },
		{ country: 'Mexico', code: '52', iso: 'MX' },
		{ country: 'Micronesia', code: '691', iso: 'FM' },
		{ country: 'Moldova', code: '373', iso: 'MD' },
		{ country: 'Monaco', code: '377', iso: 'MC' },
		{ country: 'Mongolia', code: '976', iso: 'MN' },
		{ country: 'Montenegro', code: '382', iso: 'ME' },
		{ country: 'Montserrat', code: '1-664', iso: 'MS' },
		{ country: 'Morocco', code: '212', iso: 'MA' },
		{ country: 'Mozambique', code: '258', iso: 'MZ' },
		{ country: 'Myanmar', code: '95', iso: 'MM' },
		{ country: 'Namibia', code: '264', iso: 'NA' },
		{ country: 'Nauru', code: '674', iso: 'NR' },
		{ country: 'Nepal', code: '977', iso: 'NP' },
		{ country: 'Netherlands', code: '31', iso: 'NL' },
		{ country: 'Netherlands Antilles', code: '599', iso: 'AN' },
		{ country: 'New Caledonia', code: '687', iso: 'NC' },
		{ country: 'New Zealand', code: '64', iso: 'NZ' },
		{ country: 'Nicaragua', code: '505', iso: 'NI' },
		{ country: 'Niger', code: '227', iso: 'NE' },
		{ country: 'Nigeria', code: '234', iso: 'NG' },
		{ country: 'Niue', code: '683', iso: 'NU' },
		{ country: 'North Korea', code: '850', iso: 'KP' },
		{ country: 'Northern Mariana Islands', code: '1-670', iso: 'MP' },
		{ country: 'Norway', code: '47', iso: 'NO' },
		{ country: 'Oman', code: '968', iso: 'OM' },
		{ country: 'Pakistan', code: '92', iso: 'PK' },
		{ country: 'Palau', code: '680', iso: 'PW' },
		{ country: 'Palestine', code: '970', iso: 'PS' },
		{ country: 'Panama', code: '507', iso: 'PA' },
		{ country: 'Papua New Guinea', code: '675', iso: 'PG' },
		{ country: 'Paraguay', code: '595', iso: 'PY' },
		{ country: 'Peru', code: '51', iso: 'PE' },
		{ country: 'Philippines', code: '63', iso: 'PH' },
		{ country: 'Pitcairn', code: '64', iso: 'PN' },
		{ country: 'Poland', code: '48', iso: 'PL' },
		{ country: 'Portugal', code: '351', iso: 'PT' },
		{ country: 'Puerto Rico', code: '1-787, 1-939', iso: 'PR' },
		{ country: 'Qatar', code: '974', iso: 'QA' },
		{ country: 'Republic of the Congo', code: '242', iso: 'CG' },
		{ country: 'Reunion', code: '262', iso: 'RE' },
		{ country: 'Romania', code: '40', iso: 'RO' },
		{ country: 'Russia', code: '7', iso: 'RU' },
		{ country: 'Rwanda', code: '250', iso: 'RW' },
		{ country: 'Saint Barthelemy', code: '590', iso: 'BL' },
		{ country: 'Saint Helena', code: '290', iso: 'SH' },
		{ country: 'Saint Kitts and Nevis', code: '1-869', iso: 'KN' },
		{ country: 'Saint Lucia', code: '1-758', iso: 'LC' },
		{ country: 'Saint Martin', code: '590', iso: 'MF' },
		{ country: 'Saint Pierre and Miquelon', code: '508', iso: 'PM' },
		{ country: 'Saint Vincent and the Grenadines', code: '1-784', iso: 'VC' },
		{ country: 'Samoa', code: '685', iso: 'WS' },
		{ country: 'San Marino', code: '378', iso: 'SM' },
		{ country: 'Sao Tome and Principe', code: '239', iso: 'ST' },
		{ country: 'Saudi Arabia', code: '966', iso: 'SA' },
		{ country: 'Senegal', code: '221', iso: 'SN' },
		{ country: 'Serbia', code: '381', iso: 'RS' },
		{ country: 'Seychelles', code: '248', iso: 'SC' },
		{ country: 'Sierra Leone', code: '232', iso: 'SL' },
		{ country: 'Singapore', code: '65', iso: 'SG' },
		{ country: 'Sint Maarten', code: '1-721', iso: 'SX' },
		{ country: 'Slovakia', code: '421', iso: 'SK' },
		{ country: 'Slovenia', code: '386', iso: 'SI' },
		{ country: 'Solomon Islands', code: '677', iso: 'SB' },
		{ country: 'Somalia', code: '252', iso: 'SO' },
		{ country: 'South Africa', code: '27', iso: 'ZA' },
		{ country: 'South Korea', code: '82', iso: 'KR' },
		{ country: 'South Sudan', code: '211', iso: 'SS' },
		{ country: 'Spain', code: '34', iso: 'ES' },
		{ country: 'Sri Lanka', code: '94', iso: 'LK' },
		{ country: 'Sudan', code: '249', iso: 'SD' },
		{ country: 'Suriname', code: '597', iso: 'SR' },
		{ country: 'Svalbard and Jan Mayen', code: '47', iso: 'SJ' },
		{ country: 'Swaziland', code: '268', iso: 'SZ' },
		{ country: 'Sweden', code: '46', iso: 'SE' },
		{ country: 'Switzerland', code: '41', iso: 'CH' },
		{ country: 'Syria', code: '963', iso: 'SY' },
		{ country: 'Taiwan', code: '886', iso: 'TW' },
		{ country: 'Tajikistan', code: '992', iso: 'TJ' },
		{ country: 'Tanzania', code: '255', iso: 'TZ' },
		{ country: 'Thailand', code: '66', iso: 'TH' },
		{ country: 'Togo', code: '228', iso: 'TG' },
		{ country: 'Tokelau', code: '690', iso: 'TK' },
		{ country: 'Tonga', code: '676', iso: 'TO' },
		{ country: 'Trinidad and Tobago', code: '1-868', iso: 'TT' },
		{ country: 'Tunisia', code: '216', iso: 'TN' },
		{ country: 'Turkey', code: '90', iso: 'TR' },
		{ country: 'Turkmenistan', code: '993', iso: 'TM' },
		{ country: 'Turks and Caicos Islands', code: '1-649', iso: 'TC' },
		{ country: 'Tuvalu', code: '688', iso: 'TV' },
		{ country: 'U.S. Virgin Islands', code: '1-340', iso: 'VI' },
		{ country: 'Uganda', code: '256', iso: 'UG' },
		{ country: 'Ukraine', code: '380', iso: 'UA' },
		{ country: 'United Arab Emirates', code: '971', iso: 'AE' },
		{ country: 'United Kingdom', code: '44', iso: 'GB' },
		{ country: 'United States', code: '1', iso: 'US' },
		{ country: 'Uruguay', code: '598', iso: 'UY' },
		{ country: 'Uzbekistan', code: '998', iso: 'UZ' },
		{ country: 'Vanuatu', code: '678', iso: 'VU' },
		{ country: 'Vatican', code: '379', iso: 'VA' },
		{ country: 'Venezuela', code: '58', iso: 'VE' },
		{ country: 'Vietnam', code: '84', iso: 'VN' },
		{ country: 'Wallis and Futuna', code: '681', iso: 'WF' },
		{ country: 'Western Sahara', code: '212', iso: 'EH' },
		{ country: 'Yemen', code: '967', iso: 'YE' },
		{ country: 'Zambia', code: '260', iso: 'ZM' },
		{ country: 'Zimbabwe', code: '263', iso: 'ZW' },
	];
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const prescriptionHeader = {
	UHID: 'true',
	'OPD No': 'true',
	Name: 'true',
	Contact: 'true',
	'Age/Gender': 'true',
	Validity: 'true',
	Address: 'true',
	Doctor: 'true',
};

export const billingHeader = {
	UHID: 'true',
	Name: 'true',
	Contact: 'true',
	'Age/Gender': 'true',
	Address: 'true',
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const truncate = (source, size) =>
	source.length > size ? source.slice(0, size - 1) + '…' : source;

export const invalidNums = ['e', 'E', '+', '-'];

export const staticUrl = process.env.REACT_APP_STATIC_URL;

export const camelize = (str) => {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
};

// input: {min: 1, max: 5, type: 'number'} or [{min: 1, max: 5, type: 'number'}, {min: 0, max: 4, type: 'number'}]
export const checkValidBotInput = (input, type) => {
	const invalidErr = 'Oops! You entered wrong input';
	if (!input) throw new Error(invalidErr);
	if (Array.isArray(type)) {
		if (!Array.isArray(input) || type.length != input.length) throw new Error(invalidErr);

		type.forEach((valueType, index) => {
			if (
				typeof input[index] != valueType.type ||
				input[index] < (valueType.min || -Infinity) ||
				input[index] > (valueType.max || +Infinity)
			)
				throw new Error(invalidErr);
		});
	} else {
		if (
			typeof input != type.type ||
			input < (type.min || -Infinity) ||
			input > (type.max || +Infinity)
		)
			throw new Error(invalidErr);
	}
};

export const getMedicineByName = async (name) => {
	try {
		const res = await axios.get(
			`${process.env.REACT_APP_SERVER_URL}/doctor/medicine/suggestion?search=${name}`,
			// `https://app.docplix.online/api/doctor/medicine/suggestion?search=${name}`,
			// {
			// 	headers: {
			// 		Authorization:
			// 			'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiVkVORE9SIiwic3ViIjoxODAsImtleSI6InBhV1F1bzZoSHRvVlFHMEE0ZEdWIiwiaWF0IjoxNjc3ODMxMzk0MDU1LCJpc3MiOiJEb2NGbGl4LVZFTkRPUiIsImV4cCI6MTY5MzM4MzM5NDA1NX0.l4thgNcPJjKtoNndQAkTOswSlrqXlZ4m3ONODBhABJo',
			// 	},
			// },
		);

		const allMeds = [...res?.data?.inventories, ...res?.data?.medicines];

		if (allMeds.length > 0) {
			let med = null;
			allMeds?.forEach?.((m) => {
				if (m?.name?.toLowerCase?.().includes?.(name?.toLowerCase?.())) med = m;
			});
			return med;
		} else {
			return null;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const createDrawing = async (userId, date, image) => {
	try {
		const formData = new FormData();
		formData.append('userId', userId);
		formData.append('date', date);
		formData.append('image', image);
		formData.append('drawing', 'png');

		const res = await axios.post('/drawing', formData);
		if (res.status === 200) {
			showNotification('Success', 'Saved!', 'success');
		}
	} catch (err) {
		showNotification('Error', 'Cannot Save!', 'danger');
		console.log(err);
	}
};

export const checkSTTLicense = async () => {
	try {
		const res = await axios.get('/doctor/profile/check-speech-to-text-licence');

		if (res.data.success) {
			return res.data.isSpeechToTextLicence;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const postRXAudio = async (audio, text) => {
	try {
		const formData = new FormData();
		formData.append('audio', audio, 'audio.wav');
		formData.append('text', text);

		const res = await axios.post('https://data-asr.docplix.com/augnitoAudio', formData);
		// if (res.status === 200) {
		// showNotification('Success', 'Saved!', 'success');
		// }
	} catch (err) {
		// showNotification('Error', 'Cannot Save!', 'danger');
		console.log(err);
	}
};

export const checkAnalysisLicense = async () => {
	try {
		const res = await axios.get('/doctor/profile/check-analyze-licence');

		if (res.data.success) {
			return res.data.isAnalyze;
		} else {
			return false;
		}
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const createMedRows = async (medRows) => {
	try {
		const res = await axios.post('/medicine-fill', {
			result: medRows,
		});

		if (res.data.success) {
			console.log('rows saved successfully');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getMedRow = async (medId) => {
	try {
		const res = await axios.get(`/medicine-fill?medicineId=${medId}`);

		if (res.data.success) return res.data.result;
		return null;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getPaymentTypes = async () => {
	try {
		const res = await axios.get('/doctor/ipd/bill/ipd-payment-type');

		if (res.data.success) return res.data.data;
		return [];
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const MISSummaryRoutes = {
	labtestBill: {
		path: 'lab-billing',
		name: 'Lab Billing',
		key: 'labtestBill',
	},
	treatmentBill: {
		path: 'treatment-billing',
		name: 'Treatment Billing',
		key: 'treatmentBill',
	},
	purchaseVouchersBill: {
		path: 'purchase-voucher',
		name: 'Purchase Voucher',
		key: 'purchaseVouchersBill',
	},
	saleVouchersBill: {
		path: 'sales-voucher',
		name: 'Sales Voucher',
		key: 'saleVouchersBill',
	},
	ledgerBill: {
		path: 'ledger',
		name: 'Ledger',
		key: 'ledgerBill',
	},
	opdBill: {
		path: 'opd-billing',
		name: 'Consultation Billing',
		key: 'opdBill',
	},
	ipdBill: {
		path: 'ipd-billing',
		name: 'IPD Billing',
		key: 'ipdBill',
	},
};

export const billingTypes = [
	{ name: 'Total Billed', key: 'totalBill' },
	{ name: 'Total Collected', key: 'totalPaidBill' },
	{ name: 'Cash', key: 'modePayBills;billByCash' },
	{ name: 'Card', key: 'modePayBills;billByCard' },
	{ name: 'UPI', key: 'modePayBills;billByUpi' },
	{ name: 'Net Banking', key: 'modePayBills;billByBanking' },
	{ name: 'Wallet', key: 'modePayBills;billByWallet' },
	{ name: 'Return', key: 'returnPaidBill' },
	{ name: 'Outstanding', key: 'outstanding' },
];

export const renderNumber = (number) => {
	if (!isNaN(parseFloat(number))) return parseFloat(number)?.toFixed?.(2);
	return 0;
};

export const renderIntNumber = (number) => {
	if (!isNaN(parseInt(number))) return parseInt(number)?.toFixed?.(2);
	return 0;
};

export const renderPercentage = (number) => {
	if (!isNaN(parseFloat(number))) return parseFloat(number)?.toFixed?.(2) + '%';
	return '0%';
};

export const returnNum = (number) => {
	if (isNaN(parseFloat(number))) return 0;
	return parseFloat(number);
};

export const renderRupee = (number) => {
	if (!isNaN(parseFloat(number))) return `₹${parseFloat(number)?.toFixed?.(2)}`;
	return `₹0.00`;
};

export const renderInt = (num) => {
	if (!isNaN(parseInt(num))) return parseInt(num);
	return 0;
};

export const coalesce = (...args) => {
	return args.find((arg) => arg !== null && arg !== undefined);
};

export const calculateAge = (dob, long = false) => {
	if (!dob) return null;
	// Convert the date of birth string to a Date object
	const dobDate = new Date(dob);

	// Get the current date
	const currentDate = new Date();

	// Calculate the difference between current date and DOB in milliseconds
	const ageInMilliseconds = currentDate - dobDate;

	// Convert milliseconds to years and months
	const msPerDay = 1000 * 60 * 60 * 24;
	const years = Math.floor(ageInMilliseconds / (msPerDay * 365));
	const months = Math.floor((ageInMilliseconds % (msPerDay * 365)) / (msPerDay * 30));
	const days = Math.floor(((ageInMilliseconds % (msPerDay * 365)) % (msPerDay * 30)) / msPerDay);

	// Construct the result string

	if (years > 0) {
		if (long) return `${years} Years`;
		return `${years} Y`;
	}
	if (months > 0) {
		if (long) return `${months} Months`;
		return `${months} M`;
	}
	if (days > 0) {
		if (long) return `${days} Days`;

		return `${days} D`;
	}
};

export const fontSizes = {
	'Extra Small': 5,
	Smaller: 7,
	Small: 9,
	Normal: 11,
	Medium: 13,
	Large: 15,
	Larger: 18,
	'Extra Large': 21,
};

export const formatAddress = (object) => {
	let address = '';
	if (object) {
		if (object.address1) address += object.address1.toString();
		if (object.address2) {
			if (address == '') address += object.address2.toString();
			else address += ', ' + object.address2.toString();
		}
		if (object.city) {
			if (address == '') address += object.city.toString();
			else address += ', ' + object.city.toString();
		}
		if (object.state) {
			if (address == '') address += object.state.toString();
			else address += ', ' + object.state.toString();
		}
		if (object.pincode) {
			if (address == '') address += object.pincode.toString();
			else address += ', ' + object.pincode.toString();
		}
		if (object.country) {
			if (address == '') address += object.country.toString();
			else address += ', ' + object.country.toString();
		}
	}
	return address;
};

export const matchInventoryItem = async (item, inventory) => {
	let invItem = inventory.find((x) => x.medicineId == item.medicineId);
	if (!invItem && item.composition) invItem = await getInventoryByComposition(item.composition);
	return invItem;
};

export const deepCopyObject = (obj) => {
	let newObj = {};
	for (let key in obj) {
		if (obj[key] != '' && obj[key] != null && obj[key] != undefined) {
			if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
				newObj[key] = deepCopyObject(obj[key]);
			} else {
				newObj[key] = obj[key];
			}
		}
	}
	return newObj;
};

export const handlePaymentRatio = (value, balance) => {
	switch (value) {
		case 'oneForth':
			return Math.round(balance / 4);

		case 'half':
			return Math.round(balance / 2);

		case 'full':
			return Math.round(balance);

		default:
			return 0;
	}
};

/** getGst
 * @param cgstPercentage {number}
 * @param sgstPercentage {number}
 * @param amount {number} cost price
 */
export const getGST = (cgst, sgst, amount) => {
	let cgstAmount = (renderNumber(cgst) * renderNumber(amount)) / 100;
	let sgstAmount = (renderNumber(sgst) * renderNumber(amount)) / 100;
	return { cgstAmount, sgstAmount };
};

function dateDiff(startDate, endDate) {
	const start = new Date(startDate);
	const end = new Date(endDate);

	const yearDiff = end.getFullYear() - start.getFullYear();
	const monthDiff = end.getMonth() - start.getMonth();
	const dayDiff = end.getDate() - start.getDate();

	let result = '';
	if (yearDiff !== 0) {
		result += `${yearDiff} year${yearDiff !== 1 ? 's' : ''}`;
	}
	if (monthDiff !== 0) {
		if (result) result += ', ';
		result += `${monthDiff} month${monthDiff !== 1 ? 's' : ''}`;
	}
	if (dayDiff !== 0) {
		if (result) result += ', ';
		result += `${dayDiff} day${dayDiff !== 1 ? 's' : ''}`;
	}

	return result;
}

export function dateDiffInDays(startDate, endDate) {
	const start = new Date(startDate);
	const end = new Date(endDate);

	const timeDifference = end - start;
	const dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24)); // Milliseconds in a day

	return dayDifference || 0;
}

export const camelToFlat = (camel = '') => {
	const camelCase = camel?.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');

	let flat = '';

	camelCase.forEach((word) => {
		flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + ' ';
	});
	return flat;
};

export const GetNestedValue = (obj, path, defaultVal) => {
	try {
		if (!obj || typeof obj !== 'object') {
			return defaultVal; // Return default value if obj is not an object or is null/undefined
		}

		const keys = path?.split('.');
		let currentObj = obj;

		for (const key of keys) {
			if (currentObj && typeof currentObj === 'object' && key in currentObj) {
				currentObj = currentObj[key];
			} else {
				return defaultVal; // Return default value if the key is not present or the value is undefined
			}
		}
		return currentObj ?? defaultVal;
	} catch (err) {
		console.error(err);
		return defaultVal;
	}
};

export function removeUndefinedKeys(obj) {
	return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== undefined));
}

export const formatDates = (obj) => {
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			obj[key] = moment(obj[key]).format('YYYY-MM-DD');
		}
	}
	return obj;
};

export const getDayByNumber = (num) => {
	const days = {
		0: 'Sunday',
		1: 'Monday',
		2: 'Tuesday',
		3: 'Wednesday',
		4: 'Thursday',
		5: 'Friday',
		6: 'Saturday',
	};
	return days[num];
};

export const months = {
	0: 'January',
	1: 'February',
	2: 'March',
	3: 'April',
	4: 'May',
	5: 'June',
	6: 'July',
	7: 'August',
	8: 'September',
	9: 'October',
	10: 'November',
	11: 'December',
};

export const getMonthByNumber = (num) => {
	return months[num];
};

export const getExportFileName = (fileName) => {
	return `${fileName}_${moment().format('DD-MM-YYYY')}`;
};

export const handleMultiExcelExport = (
	AllHeaders = [],
	AllData = [],
	footers = [],
	fileNames = [],
	exportName = 'new file',
) => {
	const workbook = XLSX.utils.book_new();
	AllHeaders.forEach((headers, index) => {
		let excelData = [];
		const data = AllData[index] || [];
		excelData.push(headers.map((item) => item.name));
		data.forEach((item, idx) => {
			let subArray = headers.map((header) => {
				if (header.renderCell) return header.renderCell(item, idx);
				return GetNestedValue(item, header.key, '-');
			});
			excelData.push(subArray);
		});
		if (footers.length > 0) excelData.push(footers[index]);
		const worksheet = XLSX.utils.aoa_to_sheet(excelData);
		XLSX.utils.book_append_sheet(workbook, worksheet, fileNames[index]);
	});
	const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
	const blob = new Blob([excelBuffer], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	const fileName = getExportFileName(exportName);
	a.download = `${fileName}.${'xlsx'}`;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
};

export const noteTypes = {
	NOTE_TYPE_OPD: 'OPD',
	NOTE_TYPE_PAYMENT: 'PAYMENT',
	NOTE_TYPE_IPD_PAYMENT: 'IPD_PAYMENT',
	NOTE_TYPE_IPD_REFUND_PAYMENT: 'IPD_REFUND_PAYMENT',
	NOTE_TYPE_IPD_TPA_PAYMENT: 'IPD_TPA_PAYMENT',
	NOTE_TYPE_STOCKIST_PAYMENT: 'STOCKIST_PAYMENT',
	NOTE_TYPE_SALE_VOUCHER_COLLECT_PAYMENT: 'SALES_VOUCHER_COLLECT_BALANCE',
	NOTE_TYPE_BILL_ITEM: 'BILL_ITEM',
};

export const roundOffMoney = (number) => {
	return Math.round(number * 100) / 100;
};

export const renderInteger = () => {
	if (!isNaN(parseFloat(number))) return parseFloat(number)?.toFixed?.(2);
};

export const parseInteger = (value) => {
	if (!value || isNaN(value)) return 0;
	return parseInt(value);
};
