import React from 'react';
import { useSelector } from 'react-redux';
import showNotification from './extras/showNotification';

const PermissionCheck = ({ module, action, children, subAction = null }) => {
	const permissions = useSelector((state) => state.profile?.permissions);
	// const userType = useSelector((state) => state.profile?.selectedOrg?.userType);
	const userType = useSelector((state) => state.profile?.selectedOrg?.userType);

	if (userType !== 'staff') {
		return children;
	}

	let f = permissions?.find((i) => i.name?.toLowerCase?.() === module?.toLowerCase?.());
	if (subAction) {
		f = f?.subAction?.find((x) => x?.name === subAction);
	}
	if (f) {
		if (f.Vendor_action?.[action]) {
			return children;
		}
		// else {
		// 	showNotification(
		// 		'Permission Denied',
		// 		'User is not authorized to perform the action',
		// 		'danger',
		// 	);
		// }
	} else {
		// console.log(module);
		// showNotification('Error', 'Module not found', 'danger');
	}

	return (
		<div className='m-3 mx-5 text-muted fst-italic text-center'>
			You're not permitted to access this module :(
		</div>
	);
};

export default PermissionCheck;
