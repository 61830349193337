import {
	SET_SELECTED_ORGANISATION,
	SET_INCLINIC_SLOTS,
	LOADING_INCLINIC_SLOTS,
	CREATE_INCLINIC_SLOT,
	UPDATE_INCLINIC_SLOT,
	DELETE_INCLINIC_SLOT,
	SET_TELECON_SLOTS,
	LOADING_PRESCRIPTION_PAD,
	SET_PRESCRIPTION_PAD,
	CREATE_PRESCRIPTION_PAD,
	UPDATE_PRESCRIPTION_PAD,
	DELETE_PRESCRIPTION_PAD,
	LOADING_BILLING_PAD,
	SET_BILLING_PAD,
	CREATE_BILLING_PAD,
	UPDATE_BILLING_PAD,
	DELETE_BILLING_PAD,
	LOADING_TELECON_SLOTS,
	CREATE_TELECON_SLOT,
	UPDATE_TELECON_SLOT,
	DELETE_TELECON_SLOT,
	SET_QUALIFICATIONS,
	LOADING_QUALIFICATIONS,
	CREATE_QUALIFICATION,
	UPDATE_QUALIFICATION,
	DELETE_QUALIFICATION,
	SET_PERMISSIONS,
	SET_SERIES_ID_SETTINGS,
	SET_LABREPORT_PAD,
	SET_SMS_CREDITS,
	SET_WHATSAPP_CREDITS,
} from './types';

const initialState = {
	qualifications: [],
	inClinicSlots: [],
	teleConSlots: [],
	permissions: [],
	prescriptionPad: null,
	billingPad: null,
	labReportPad: null,
	selectedOrg: null,
	error: null,
	seriesIdSettings: false,
	loading: false,
};

const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_SELECTED_ORGANISATION:
			return {
				...state,
				selectedOrg: action.payload,
			};

		case LOADING_QUALIFICATIONS:
			return {
				...state,
				loading: true,
			};

		case SET_PERMISSIONS:
			return {
				...state,
				permissions: action.payload || [],
				loading: false,
			};

		case SET_QUALIFICATIONS:
			return {
				...state,
				qualifications: action.payload || [],
				loading: false,
			};

		case CREATE_QUALIFICATION:
			return {
				...state,
				qualifications: [action.payload, ...state.qualifications],
			};

		case UPDATE_QUALIFICATION:
			const qualificationIdx = _.findIndex(
				state.qualifications,
				(org) => org.id === action.payload.id,
			);
			return {
				...state,
				qualifications: [
					...state.qualifications.slice(0, qualificationIdx),
					action.payload,
					...state.qualifications.slice(qualificationIdx + 1),
				],
			};

		case DELETE_QUALIFICATION:
			return {
				...state,
				qualifications: _.reject(state.qualifications, (i) => i.id === action.payload),
			};

		case LOADING_INCLINIC_SLOTS:
			return {
				...state,
				loading: true,
			};

		case SET_INCLINIC_SLOTS:
			return {
				...state,
				inClinicSlots: action.payload || [],
				loading: false,
			};

		case CREATE_INCLINIC_SLOT:
			return {
				...state,
				inClinicSlots: [action.payload, ...state.inClinicSlots],
			};

		case UPDATE_INCLINIC_SLOT:
			const slotIdx = _.findIndex(state.inClinicSlots, (dep) => dep.id === action.payload.id);
			return {
				...state,
				inClinicSlots: [
					...state.inClinicSlots.slice(0, slotIdx),
					action.payload,
					...state.inClinicSlots.slice(slotIdx + 1),
				],
			};

		case DELETE_INCLINIC_SLOT:
			return {
				...state,
				inClinicSlots: _.reject(state.inClinicSlots, (dep) => dep.id === action.payload),
			};

		case LOADING_PRESCRIPTION_PAD:
			return {
				...state,
				loading: true,
			};

		case SET_PRESCRIPTION_PAD:
			return {
				...state,
				prescriptionPad: action.payload || null,
				loading: false,
			};

		case CREATE_PRESCRIPTION_PAD:
			return {
				...state,
				prescriptionPad: action.payload || null,
			};

		case UPDATE_PRESCRIPTION_PAD:
			return {
				...state,
				prescriptionPad: action.payload || nul,
			};

		case DELETE_PRESCRIPTION_PAD:
			return {
				...state,
				prescriptionPad: null,
			};

		case LOADING_BILLING_PAD:
			return {
				...state,
				loading: true,
			};

		case SET_LABREPORT_PAD:
			return {
				...state,
				labReportPad: action.payload || null,
				loading: false,
			};

		case SET_BILLING_PAD:
			return {
				...state,
				billingPad: action.payload || null,
				loading: false,
			};

		case CREATE_BILLING_PAD:
			return {
				...state,
				billingPad: action.payload || null,
			};

		case UPDATE_BILLING_PAD:
			return {
				...state,
				billingPad: action.payload || null,
			};

		case DELETE_BILLING_PAD:
			return {
				...state,
				billingPad: null,
			};

		case SET_SERIES_ID_SETTINGS:
			return {
				...state,
				seriesIdSettings: action.payload || false,
			};

		case LOADING_TELECON_SLOTS:
			return {
				...state,
				loading: true,
			};

		case SET_TELECON_SLOTS:
			return {
				...state,
				teleConSlots: action.payload || [],
				loading: false,
			};

		case CREATE_TELECON_SLOT:
			return {
				...state,
				teleConSlots: [action.payload, ...state.teleConSlots],
			};

		case UPDATE_TELECON_SLOT:
			const teleConSlotIdx = _.findIndex(
				state.teleConSlots,
				(i) => i.id === action.payload.id,
			);
			return {
				...state,
				teleConSlots: [
					...state.teleConSlots.slice(0, teleConSlotIdx),
					action.payload,
					...state.teleConSlots.slice(teleConSlotIdx + 1),
				],
			};

		case DELETE_TELECON_SLOT:
			return {
				...state,
				teleConSlots: _.reject(state.teleConSlots, (dep) => dep.id === action.payload),
			};

		case SET_SMS_CREDITS:
			return {
				...state,
				smsCredits: action.payload || 0,
			};

		case SET_WHATSAPP_CREDITS:
			return {
				...state,
				whatsappCredits: action.payload || 0,
			};

		default:
			return state;
	}
};

export default profileReducer;
