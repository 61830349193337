import axios, { custom } from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { SET_IMMUN_REMARKS, SET_PATIENT_IMMUN } from '../reducers/types';
import { getCustomRemarks } from './historyActions';
import { toggleCreateLoading } from './componentActions';

export const createPatientImmun = (patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/immunisations', { patientId });
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_IMMUN,
				payload: [{ immunisation_chartvalues: res.data.data }],
			});
			showNotification('Success', 'Immunisation added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getPatientImmun = (patientId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.get(`/doctor/immunisations/${patientId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_IMMUN,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const updatePatientImmun =
	(date, durationType, immunisationChartValueId, patientId) => async (dispatch) => {
		dispatch(toggleCreateLoading(true));
		try {
			const res = await axios.put('/doctor/immunisations', {
				date,
				durationType: 'abc',
				immunisationChartValueId,
			});
			if (res.data.success) {
				dispatch(getPatientImmun(patientId));
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const changeImmunRemark =
	(remark, immunisationChartValueId, patientId) => async (dispatch) => {
		dispatch(toggleCreateLoading(true));
		try {
			const res = await axios.put('/doctor/immunisations/update-remark', {
				immunisationChartValueId,
				remark,
			});
			if (res.data.success) {
				dispatch(getPatientImmun(patientId));
				// showNotification('Success', 'Remark updated successfully', 'success');
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const getImmunRemarks = async () => {
	try {
		const res = await axios.get(`/doctor/immunisations/remark-suggestions`);
		if (res.data.success) {
			return res.data.data;
		}
		return [];
	} catch (err) {
		console.log(err);
		return [];
	}
};
