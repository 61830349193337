import axiosz from 'axios';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';
import { store } from '../store/configureStore';
import { logout } from '../actions/authActions';

let session = true;

const axios = axiosz.create({
	baseURL: process.env.REACT_APP_SERVER_URL,
});

axios.interceptors.response.use(
	function (res) {
		return res;
	},
	function (error) {
		if (error.response.status == 401) {
			if (!session) return;
			session = false;
			showNotification('', 'Session Expired!', 'danger');
			store.dispatch(logout());
			return;
		}
		session = true;
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon='x-circle' size='lg' className='me-1' />
				<span>Error</span>
			</span>,
			error.response ? error.response.data.errors[0] : error.message,
			'danger',
		);
		return Promise.reject(error);
	},
);

const abdm = axiosz.create({
	baseURL: process.env.REACT_APP_ABDM_URL,
});

abdm.interceptors.response.use(
	function (res) {
		return res;
	},
	function (error) {
		console.log(error);
		// showNotification(
		// 	<span className='d-flex align-items-center'>
		// 		<Icon icon='x-circle' size='lg' className='me-1' />
		// 		<span>Error</span>
		// 	</span>,
		// 	'Sorry ABDM server is not responding!',
		// 	'danger',
		// );
		return Promise.reject(error);
	},
);

const custom = axiosz.create({
	baseURL: process.env.REACT_APP_SERVER_LIS_URL,
});

custom.interceptors.response.use(
	function (res) {
		return res;
	},
	function (error) {
		console.log(error);
		// showNotification(
		// 	<span className='d-flex align-items-center'>
		// 		<Icon icon='x-circle' size='lg' className='me-1' />
		// 		<span>Error</span>
		// 	</span>,
		// 	'Something went wrong!',
		// 	'danger',
		// );
		return Promise.reject(error);
	},
);

export default axios;
export { custom, abdm };
