import {
	SET_STAFF,
	LOADING_STAFF,
	CREATE_STAFF,
	UPDATE_STAFF,
	DELETE_STAFF,
	SET_STAFF_ATTENDANCE,
	CREATE_STAFF_ATTENDANCE,
	UPDATE_STAFF_ATTENDANCE,
	DELETE_STAFF_ATTENDANCE,
	SET_STAFF_SALARY,
	CREATE_STAFF_SALARY,
	UPDATE_STAFF_SALARY,
	DELETE_STAFF_SALARY,
	SET_DR_WISE_DATA_FOR_STAFF,
} from './types';

const initialState = {
	staff: [],
	staff_attendance: [],
	drWiseMISData: {},
	staff_salary: [],
	error: null,
	loading: false,
};

const staffReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_STAFF:
			return {
				...state,
				loading: true,
			};

		case SET_STAFF:
			return {
				...state,
				staff: action.payload || [],
				loading: false,
			};

		case CREATE_STAFF:
			return {
				...state,
				staff: [action.payload, ...state.staff],
			};

		case UPDATE_STAFF:
			const staffIdx = _.findIndex(state.staff, (staff) => staff.id === action.payload.id);
			return {
				...state,
				staff: [
					...state.staff.slice(0, staffIdx),
					action.payload,
					...state.staff.slice(staffIdx + 1),
				],
			};

		case DELETE_STAFF:
			return {
				...state,
				staff: _.reject(state.staff, (staff) => staff.id === action.payload),
			};

		case SET_STAFF_ATTENDANCE:
			return {
				...state,
				staff_attendance: action.payload || [],
				loading: false,
			};

		case CREATE_STAFF_ATTENDANCE:
			return {
				...state,
				staff_attendance: [action.payload, ...state.staff_attendance],
			};

		case UPDATE_STAFF_ATTENDANCE:
			const staffattenIdx = _.findIndex(
				state.staff_attendance,
				(staffatten) => staffatten.id === action.payload.id,
			);
			return {
				...state,
				staff_attendance: [
					...state.staff_attendance.slice(0, staffattenIdx),
					action.payload,
					...state.staff_attendance.slice(staffattenIdx + 1),
				],
			};

		case DELETE_STAFF_ATTENDANCE:
			return {
				...state,
				staff_attendance: _.reject(
					state.staff_attendance,
					(staffatten) => staffatten.id === action.payload,
				),
			};

		case SET_STAFF_SALARY:
			return {
				...state,
				staff_salary: action.payload || [],
				loading: false,
			};

		case CREATE_STAFF_SALARY:
			return {
				...state,
				staff_salary: [action.payload, ...state.staff_salary],
			};

		case UPDATE_STAFF_SALARY:
			const staffsalaryIdx = _.findIndex(
				state.staff_salary,
				(staffsal) => staffsal.id === action.payload.id,
			);
			return {
				...state,
				staff_salary: [
					...state.staff_salary.slice(0, staffsalaryIdx),
					action.payload,
					...state.staff_salary.slice(staffsalaryIdx + 1),
				],
			};

		case DELETE_STAFF_SALARY:
			return {
				...state,
				staff_salary: _.reject(
					state.staff_salary,
					(staffsal) => staffsal.id === action.payload,
				),
			};

		case SET_DR_WISE_DATA_FOR_STAFF:
			return {
				...state,
				drWiseMISData: action.payload || {},
			};
		default:
			return state;
	}
};

export default staffReducer;
