import { useFormik } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RichTextEditor from 'react-rte';
import Select from 'react-select';
import moment from 'moment';

import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Input from '../../../components/bootstrap/forms/Input';
import TextArea from '../../../components/bootstrap/forms/Textarea';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import {
	addIpdProcedure,
	addPhysicalExamination,
	deletePhysicalExaminations,
	getPhysicalExaminations,
	updatePhysicalExamination,
} from '../../../actions/ipdPatientActions';
import { invalidNums } from '../../../helpers/helpers';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import Tooltips from '../../../components/bootstrap/Tooltips';
import { isPermitted } from '../../../actions/helperActions';

const IpdPhysicalExaminations = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const physicalExaminations = useSelector((state) => state.ipdPatient.physicalExaminations);

	const [addModal, setAddModal] = useState(false);
	const [editItem, setEditItem] = useState(null);

	const handleUpcomingEdit = (item) => {
		setEditItem(item);
		formik.setValues((prev) => ({
			...prev,
			date: item?.date,
			weight: item?.Ipd_physical_examination_vital?.weight || '',
			height: item?.Ipd_physical_examination_vital?.height || '',
			bpSystilic: item?.Ipd_physical_examination_vital?.bpSystilic || '',
			bpDiastolic: item?.Ipd_physical_examination_vital?.bpDiastolic || '',
			bmi: item?.Ipd_physical_examination_vital?.bmi || '',
			bsa: item?.Ipd_physical_examination_vital?.bsa || '',
			pulse: item?.Ipd_physical_examination_vital?.pulse || '',
			spo2: item?.Ipd_physical_examination_vital?.spo2 || '',
			sensorium:
				{
					label: item?.Ipd_physical_examination_other?.sensorium,
					value: item?.Ipd_physical_examination_other?.sensorium,
				} || '',
			pallor:
				item?.Ipd_physical_examination_other?.pallor?.toString() == 'true'
					? {
							label: 'Yes',
							value: true,
					  }
					: item?.Ipd_physical_examination_other?.pallor?.toString() == 'false'
					? {
							label: 'No',
							value: false,
					  }
					: '',
			jaundice:
				item?.Ipd_physical_examination_other?.jaundice?.toString() == 'true'
					? {
							label: 'Yes',
							value: true,
					  }
					: item?.Ipd_physical_examination_other?.jaundice?.toString() == 'false'
					? {
							label: 'No',
							value: false,
					  }
					: '',
			cyanosis:
				item?.Ipd_physical_examination_other?.cyanosis?.toString() == 'true'
					? {
							label: 'Yes',
							value: true,
					  }
					: item?.Ipd_physical_examination_other?.cyanosis?.toString() == 'false'
					? {
							label: 'No',
							value: false,
					  }
					: '',
			oedema:
				item?.Ipd_physical_examination_other?.oedema?.toString() == 'true'
					? {
							label: 'Yes',
							value: true,
					  }
					: item?.Ipd_physical_examination_other?.oedema?.toString() == 'false'
					? {
							label: 'No',
							value: false,
					  }
					: '',
			clubbing:
				item?.Ipd_physical_examination_other?.clubbing?.toString() == 'true'
					? {
							label: 'Yes',
							value: true,
					  }
					: item?.Ipd_physical_examination_other?.clubbing?.toString() == 'false'
					? {
							label: 'No',
							value: false,
					  }
					: '',
			hair: item?.Ipd_physical_examination_other?.hair || '',
			skin: item?.Ipd_physical_examination_other?.skin || '',
			nails: item?.Ipd_physical_examination_other?.nails || '',
			respiratorySystem:
				RichTextEditor.createValueFromString(
					item?.Ipd_physical_examination_system?.respiratorySystem || '',
					'html',
				) || RichTextEditor.createEmptyValue(),
			cardiovascularSystem:
				RichTextEditor.createValueFromString(
					item?.Ipd_physical_examination_system?.cardiovascularSystem || '',
					'html',
				) || RichTextEditor.createEmptyValue(),
			centralNervousSystem:
				RichTextEditor.createValueFromString(
					item?.Ipd_physical_examination_system?.centralNervousSystem || '',
					'html',
				) || RichTextEditor.createEmptyValue(),
			urinarySystem:
				RichTextEditor.createValueFromString(
					item?.Ipd_physical_examination_system?.urinarySystem || '',
					'html',
				) || RichTextEditor.createEmptyValue(),
			others:
				RichTextEditor.createValueFromString(
					item?.Ipd_physical_examination_system?.others || '',
					'html',
				) || RichTextEditor.createEmptyValue(),
		}));
		setAddModal(true);
	};

	const formik = useFormik({
		initialValues: {
			ipdId,
			date: moment().format('YYYY-MM-DD'),
			weight: '',
			height: '',
			bpSystilic: '',
			bpDiastolic: '',
			bmi: '',
			bsa: '',
			pulse: '',
			spo2: '',
			sensorium: '',
			pallor: '',
			jaundice: '',
			cyanosis: '',
			oedema: '',
			clubbing: '',
			hair: '',
			skin: '',
			nails: '',
			respiratorySystem: RichTextEditor.createEmptyValue(),
			cardiovascularSystem: RichTextEditor.createEmptyValue(),
			centralNervousSystem: RichTextEditor.createEmptyValue(),
			urinarySystem: RichTextEditor.createEmptyValue(),
			others: RichTextEditor.createEmptyValue(),
		},
		onSubmit: (values, { resetForm }) => {
			const data = { ...values };
			data['sensorium'] = values.sensorium?.value;
			data['pallor'] = values.pallor?.value;
			data['jaundice'] = values.jaundice?.value;
			data['cyanosis'] = values.cyanosis?.value;
			data['oedema'] = values.oedema?.value;
			data['clubbing'] = values.clubbing?.value;
			data['respiratorySystem'] = values.respiratorySystem.toString('html');
			data['cardiovascularSystem'] = values.cardiovascularSystem.toString('html');
			data['centralNervousSystem'] = values.centralNervousSystem.toString('html');
			data['urinarySystem'] = values.urinarySystem.toString('html');
			data['others'] = values.others.toString('html');
			data['height'] = values.height.toString();
			data['weight'] = values.weight.toString();
			data['bpSystilic'] = values.bpSystilic.toString();
			data['bpDiastolic'] = values.bpDiastolic.toString();
			data['pulse'] = values.pulse.toString();
			data['spo2'] = values.spo2.toString();

			if (editItem) {
				data['ipdPhysicalExaminationId'] = editItem?.id;
				dispatch(updatePhysicalExamination(data));
			} else {
				dispatch(addPhysicalExamination(data));
			}
			resetForm();
			setAddModal(false);
			setEditItem(null);
		},
	});

	useEffect(() => {
		if (formik.values.height && formik.values.weight) {
			const bmi =
				formik.values.weight /
				((formik.values.height / 100) * (formik.values.height / 100));
			formik.setFieldValue('bmi', bmi.toFixed(2));
			const bsa = Math.sqrt((formik.values.height * formik.values.weight) / 3600);
			formik.setFieldValue('bsa', bsa.toFixed(2));
		} else {
			formik.setFieldValue('bsa', '');
			formik.setFieldValue('bmi', '');
		}
	}, [formik.values.weight, formik.values.height]);

	useEffect(() => dispatch(getPhysicalExaminations(ipdId)), []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>Physical Examinations</CardTitle>
					</CardLabel>
					<CardActions>
						{addModal ? (
							<>
								<Button
									isOutline={!darkModeStatus}
									color='danger'
									isLight={darkModeStatus}
									icon='cancel'
									onClick={() => setAddModal(false)}>
									Cancel
								</Button>
								<Button
									isOutline={!darkModeStatus}
									color='primary'
									isLight={darkModeStatus}
									icon='save'
									onClick={() =>
										isPermitted(
											'Ipd',
											'write',
											formik.handleSubmit,
											'Physical Examinations',
										)
									}>
									Save
								</Button>
							</>
						) : (
							<Button
								isOutline={!darkModeStatus}
								color='primary'
								isLight={darkModeStatus}
								icon='add'
								onClick={() => {
									isPermitted(
										'Ipd',
										'write',
										() => {
											setAddModal(true);
											formik.resetForm();
										},
										'Physical Examinations',
									);
								}}>
								Add
							</Button>
						)}
					</CardActions>
				</CardHeader>
				<CardBody isScrollable>
					{addModal ? (
						<Card className='shadow-none'>
							<CardBody className='h-100' isScrollable>
								<div className='row mb-3'>
									<div className='col-6'>
										<FormGroup label={`Date`} id='date'>
											<Input
												value={formik.values.date}
												type='date'
												onChange={formik.handleChange}
												name='date'
												style={{
													height: '3.5rem',
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-12 mt-2'>
										<h5>Vitals:</h5>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='weight' label='Weight(in kgs)'>
											<Input
												type='number'
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												onKeyDown={(e) =>
													invalidNums.includes(e.key) &&
													e.preventDefault()
												}
												autoComplete='weight'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.weight}
												isValid={formik.isValid}
												isTouched={formik.touched.weight}
												invalidFeedback={formik.errors.weight}
											/>
										</FormGroup>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='height' label='Height(in cms)'>
											<Input
												type='number'
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												onKeyDown={(e) =>
													invalidNums.includes(e.key) &&
													e.preventDefault()
												}
												autoComplete='height'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.height}
												isValid={formik.isValid}
												isTouched={formik.touched.height}
												invalidFeedback={formik.errors.height}
											/>
										</FormGroup>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='bmi' label='BMI(in kg/m²)'>
											<Input
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												disabled
												autoComplete='off'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.bmi}
												isValid={formik.isValid}
												isTouched={formik.touched.bmi}
												invalidFeedback={formik.errors.bmi}
											/>
										</FormGroup>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='bsa' label='BSA(in m²)'>
											<Input
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												onKeyDown={(e) =>
													invalidNums.includes(e.key) &&
													e.preventDefault()
												}
												disabled
												autoComplete='off'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.bsa}
												isValid={formik.isValid}
												isTouched={formik.touched.bsa}
												invalidFeedback={formik.errors.bsa}
											/>
										</FormGroup>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='bpSystilic' label='BP (Systolic)'>
											<Input
												type='number'
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												onKeyDown={(e) =>
													invalidNums.includes(e.key) &&
													e.preventDefault()
												}
												autoComplete='off'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.bpSystilic}
												isValid={formik.isValid}
												isTouched={formik.touched.bpSystilic}
												invalidFeedback={formik.errors.bpSystilic}
											/>
										</FormGroup>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='bpDiastolic' label='BP (Diastolic)'>
											<Input
												type='number'
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												onKeyDown={(e) =>
													invalidNums.includes(e.key) &&
													e.preventDefault()
												}
												autoComplete='off'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.bpDiastolic}
												isValid={formik.isValid}
												isTouched={formik.touched.bpDiastolic}
												invalidFeedback={formik.errors.bpDiastolic}
											/>
										</FormGroup>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='pulse' label='Pulse'>
											<Input
												type='number'
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												onKeyDown={(e) =>
													invalidNums.includes(e.key) &&
													e.preventDefault()
												}
												autoComplete='off'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.pulse}
												isValid={formik.isValid}
												isTouched={formik.touched.pulse}
												invalidFeedback={formik.errors.pulse}
											/>
										</FormGroup>
									</div>
									<div className='' style={{ flex: 1 }}>
										<FormGroup id='spo2' label='SPO&#8322;'>
											<Input
												type='number'
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
												}}
												onKeyDown={(e) =>
													invalidNums.includes(e.key) &&
													e.preventDefault()
												}
												autoComplete='off'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.spo2}
												isValid={formik.isValid}
												isTouched={formik.touched.spo2}
												invalidFeedback={formik.errors.spo2}
											/>
										</FormGroup>
									</div>
								</div>
								<div className='row mb-3'>
									<div className='col-6'>
										<FormGroup label={`Sensorium`} id='sensorium'>
											<Select
												className='form-control'
												value={formik.values.sensorium}
												options={['Conscious', 'Drowsy', 'Comatose'].map(
													(x) => ({
														label: x,
														value: x,
													}),
												)}
												onChange={(e) =>
													formik.setFieldValue('sensorium', e)
												}
												styles={{
													control: () => ({
														height: '2.6rem',
														alignItems: 'center',
														position: 'relative',
														display: 'flex',
													}),
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup label={`Pallor`} id='pallor'>
											<Select
												className='form-control'
												value={formik.values.pallor}
												options={[
													{ label: 'Yes', value: true },
													{ label: 'No', value: false },
												]}
												onChange={(e) => formik.setFieldValue('pallor', e)}
												styles={{
													control: () => ({
														height: '2.6rem',
														alignItems: 'center',
														position: 'relative',
														display: 'flex',
													}),
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup label={`Jaundice`} id='jaundice'>
											<Select
												className='form-control'
												value={formik.values.jaundice}
												options={[
													{ label: 'Yes', value: true },
													{ label: 'No', value: false },
												]}
												onChange={(e) =>
													formik.setFieldValue('jaundice', e)
												}
												styles={{
													control: () => ({
														height: '2.6rem',
														alignItems: 'center',
														position: 'relative',
														display: 'flex',
													}),
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup label={`Cyanosis`} id='cyanosis'>
											<Select
												className='form-control'
												value={formik.values.cyanosis}
												options={[
													{ label: 'Yes', value: true },
													{ label: 'No', value: false },
												]}
												onChange={(e) =>
													formik.setFieldValue('cyanosis', e)
												}
												styles={{
													control: () => ({
														height: '2.6rem',
														alignItems: 'center',
														position: 'relative',
														display: 'flex',
													}),
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup label={`Oedema`} id='oedema'>
											<Select
												className='form-control'
												value={formik.values.oedema}
												options={[
													{ label: 'Yes', value: true },
													{ label: 'No', value: false },
												]}
												onChange={(e) => formik.setFieldValue('oedema', e)}
												styles={{
													control: () => ({
														height: '2.6rem',
														alignItems: 'center',
														position: 'relative',
														display: 'flex',
													}),
												}}
											/>
										</FormGroup>
									</div>
									<div className='col-6'>
										<FormGroup label={`Clubbing`} id='clubbing'>
											<Select
												className='form-control'
												value={formik.values.clubbing}
												options={[
													{ label: 'Yes', value: true },
													{ label: 'No', value: false },
												]}
												onChange={(e) =>
													formik.setFieldValue('clubbing', e)
												}
												styles={{
													control: () => ({
														height: '2.6rem',
														alignItems: 'center',
														position: 'relative',
														display: 'flex',
													}),
												}}
											/>
										</FormGroup>
									</div>
								</div>
								<div className='row mb-3'>
									<div className='col-12'>
										<FormGroup label={`Hair`} id='hair'>
											<TextArea
												placeholder={`text here...`}
												name={`hair`}
												className='form-control'
												value={formik.values.hair}
												onChange={formik.handleChange}
												rows={2}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup label={`Skin`} id='skin'>
											<TextArea
												placeholder={`text here...`}
												name={`skin`}
												className='form-control'
												value={formik.values.skin}
												onChange={formik.handleChange}
												rows={2}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup label={`Nails`} id='nails'>
											<TextArea
												placeholder={`text here...`}
												name={`nails`}
												className='form-control'
												value={formik.values.nails}
												onChange={formik.handleChange}
												rows={2}
											/>
										</FormGroup>
									</div>
								</div>
								<div className='row mb-3'>
									<div className='col-12 mb-3'>
										<FormGroup label={`Respiratory System`}>
											<RichTextEditor
												editorStyle={{ height: '20vh' }}
												value={formik.values.respiratorySystem}
												onChange={(x) =>
													formik.setFieldValue(`respiratorySystem`, x)
												}
											/>
										</FormGroup>
									</div>
									<div className='col-12 mb-3'>
										<FormGroup label={`Cardiovascular System`}>
											<RichTextEditor
												editorStyle={{ height: '20vh' }}
												value={formik.values.cardiovascularSystem}
												onChange={(x) =>
													formik.setFieldValue(`cardiovascularSystem`, x)
												}
											/>
										</FormGroup>
									</div>
									<div className='col-12 mb-3'>
										<FormGroup label={`Central Nervous System`}>
											<RichTextEditor
												editorStyle={{ height: '20vh' }}
												value={formik.values.centralNervousSystem}
												onChange={(x) =>
													formik.setFieldValue(`centralNervousSystem`, x)
												}
											/>
										</FormGroup>
									</div>
									<div className='col-12 mb-3'>
										<FormGroup label={`Urinary System`}>
											<RichTextEditor
												editorStyle={{ height: '20vh' }}
												value={formik.values.urinarySystem}
												onChange={(x) =>
													formik.setFieldValue(`urinarySystem`, x)
												}
											/>
										</FormGroup>
									</div>
									<div className='col-12 mb-3'>
										<FormGroup label={`Others`}>
											<RichTextEditor
												editorStyle={{ height: '20vh' }}
												value={formik.values.others}
												onChange={(x) => formik.setFieldValue(`others`, x)}
											/>
										</FormGroup>
									</div>
								</div>
							</CardBody>
						</Card>
					) : (
						<div className='table-responsive'>
							<table className='table table-modern'>
								<thead>
									<tr>
										<th>Date</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{physicalExaminations?.map((x, i) => (
										<tr key={i}>
											<td>{moment(x?.date).format('DD-MM-YYYY')}</td>
											<td>
												<Button
													isOutline={!darkModeStatus}
													color='primary'
													isLight={darkModeStatus}
													onClick={() =>
														isPermitted(
															'Ipd',
															'read',
															() => handleUpcomingEdit(x),
															'Physical Examinations',
														)
													}>
													View Record
												</Button>{' '}
												<Tooltips title='Delete'>
													<Button
														isOutline={!darkModeStatus}
														color='danger'
														icon='delete'
														isLight={darkModeStatus}
														onClick={() =>
															isPermitted(
																'Ipd',
																'delete',
																() =>
																	dispatch(
																		deletePhysicalExaminations(
																			x?.id,
																		),
																	),
																'Physical Examinations',
															)
														}></Button>
												</Tooltips>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</CardBody>
			</Card>
		</>
	);
};

export default IpdPhysicalExaminations;
