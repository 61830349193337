import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Creatable from 'react-select/creatable';
import ReactSelect from 'react-select';
import CanvasJSReact from '../../../assets/canvasjs/canvasjs.react';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import showNotification from '../../../components/extras/showNotification';
import axios from '../../../helpers/axios';
import useDarkMode from '../../../hooks/useDarkMode';
import { getOpdDates } from '../../../actions/patientActions';
import { getInvestigations, getInvestigationsNow } from '../../../actions/settingActions';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Progress from '../../../components/bootstrap/Progress';
import { getInvestigationDescription } from '../../../helpers/nlp';
import { useNavigate } from 'react-router-dom';
import useSelectTable from '../../../hooks/useSelectTable';
import Checks from '../../../components/bootstrap/forms/Checks';
import { isPermitted } from '../../../actions/helperActions';
import { invalidNums } from '../../../helpers/helpers';
import { toggleCreateLoading } from '../../../actions/componentActions';
import PermissionCheck from '../../../components/PermissionCheck';

const validate = (values) => {
	let errors = {};

	if (!values.name) {
		errors.name = 'Required';
	}
	if (!values.units?.[0]?.unit) {
		errors = {
			...errors,
			units: [
				{
					...(errors.units?.[0] || []),
					unit: 'Required',
				},
			],
		};
	}

	return errors;
};

const Investigations = ({ userId, vendorId }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const organisationId = useSelector((state) => state.profile.selectedOrg)?.id;
	const opdDates = useSelector((state) => state.patient.opdDates);
	const investigations = useSelector((state) => state.setting.investigations);
	const prescriptions = useSelector((state) => state.patient.prescriptions);
	const patient = useSelector((state) => state.patient.patient);

	// Global Loading
	const [loading, setLoading] = useState(false);
	const [saving, setSaving] = useState(false);

	// Modals
	const [tempModal, setTempModal] = useState(false);
	const [newTemp, setNewTemp] = useState('');
	const [tempLoadModal, setTempLoadModal] = useState(false);
	const [selectedLoadTemp, setSelectedLoadTemp] = useState(false);
	const [addInvUnitModal, setAddInvUnitModal] = useState(false);

	const [dateModal, setDateModal] = useState(false);
	const [newDate, setNewDate] = useState(null);

	const [modalInvIdices, setModalInvIdices] = useState([]);
	const [loadingDesc, setLoadingDesc] = useState(false);
	const [modalInvDesc, setModalInvDesc] = useState('');

	const [chartModal, setChartModal] = useState(false);
	const [chartData, setChartData] = useState([]);
	const handleGenerateChart = (invName, invData) => {
		if (!invData && !invName) {
			setChartData([]);

			Object.keys(formik.values.table)
				.filter((i) =>
					selectTable.values.selectedList.includes(
						formik.values.table?.[i]?.id?.toString(),
					),
				)
				.forEach((invName) => {
					const data = [];
					invData = formik.values.table[invName]['dates'];

					Object.keys(invData)?.map((i) => {
						if (!isNaN(parseFloat(invData[i])) && i != 'id') {
							data.push({
								y: parseFloat(invData[i]),
								label: moment(i).format('DD-MM-YYYY'),
							});
						}
					});

					data.sort((a, b) =>
						moment(a.label, 'DD-MM-YYYY') > moment(b.label, 'DD-MM-YYYY') ? 1 : -1,
					);
					setChartData((prev) => [
						...prev,
						{
							showInLegend: true,
							type: 'spline',
							name: invName,
							dataPoints: data,
						},
					]);
				});

			setModalInvIdices(
				Object.keys(formik.values.table)
					.filter((i) =>
						selectTable.values.selectedList.includes(
							formik.values.table?.[i]?.id?.toString(),
						),
					)
					.map((i) => formik.values.table?.[i]?.id),
			);
		} else if (invData && invName) {
			setChartData([]);
			const data = [];

			Object.keys(invData)?.map((i) => {
				if (!isNaN(parseFloat(invData[i])) && i != 'id') {
					data.push({
						y: parseFloat(invData[i]),
						label: moment(i).format('DD-MM-YYYY'),
					});
				}
			});
			data.sort((a, b) =>
				moment(a.label, 'DD-MM-YYYY') > moment(b.label, 'DD-MM-YYYY') ? 1 : -1,
			);
			setChartData([
				{
					showInLegend: true,
					type: 'spline',
					name: invName,
					dataPoints: data,
				},
			]);

			setModalInvIdices([formik.values.table?.[invName]?.id]);
		}

		// - - - - - Load Investigation Descripition - - - - -
		// setLoadingDesc(true);
		// getInvestigationDescription(
		// 	invName,
		// 	Object.keys(formik.values.table)
		// 		.filter((i) =>
		// 			selectTable.values.selectedList.includes(
		// 				formik.values.table?.[i]?.id?.toString(),
		// 			),
		// 		)
		// 		.map((i) => ({
		// 			investigation: i,
		// 			values: Object.keys(formik.values.table?.[i]?.['dates'])?.map((j) => ({
		// 				date: j,
		// 				value: formik.values.table?.[i]?.['dates'][j],
		// 			})),
		// 		})),
		// ).then((res) => {
		// 	setModalInvDesc(res);
		// 	setLoadingDesc(false);
		// });
		setChartModal(true);
	};

	// Settings Investigations
	const [editInv, setEditInv] = useState(null);
	const [editInvVal, setEditInvVal] = useState('');

	const [addUnitFormikInv, setAddUnitFormikInv] = useState(null);

	const formikInvUnit = useFormik({
		initialValues: {
			name: '',
			perUnitPrice: 0,
			units: [
				{
					unit: '',
					min: '',
					max: '',
				},
			],
		},
		validate,
		onSubmit: async (values, { resetForm }) => {
			const reqData = ['name', 'perUnitPrice', 'type'];
			const data = {};
			for (var key in values) {
				if (!reqData.includes(key)) continue;
				data[key] = `${values[key]}`;
			}
			data['investigationUnits'] = values.units
				?.map((i) => {
					let out = {};
					if (i.unit) out['unit'] = i.unit;
					if (i.min) out['min'] = i.min;
					if (i.max) out['max'] = i.max;

					return out;
				})
				.filter((i) => i.unit);
			data['type'] = 'GP';
			data['investigationId'] = values['id'];
			// Update Inv here
			await handleUpdateInvestigation(data);

			const newInvs = await getInvestigationsNow();

			const newInv = newInvs?.find(
				(i) => i.name?.toLowerCase?.() === data?.name?.toLowerCase?.(),
			);

			await handleUpdateUserInv(data['name'], {
				...addUnitFormikInv,
				unitId: newInv?.Investigation_units?.[0]?.id,
			});
			await getPatientInvestigations();

			resetForm();
			setAddInvUnitModal(false);
			setAddUnitFormikInv(null);
		},
	});

	const handleUpdateInvestigation = async (updateBody) => {
		try {
			setSaving(true);
			await axios.patch('/investigation', updateBody, {
				headers: {
					organisationId,
				},
			});
		} catch (err) {
			console.log(err);
		} finally {
			setSaving(false);
		}
	};

	const handleCreateNewInv = async (invName) => {
		try {
			const data = {
				name: invName,
				perUnitPrice: 0,
				type: 'GP',
				investigationUnits: [],
			};

			const res = await axios.post('/investigation', data);
			if (res.data.success) {
				showNotification('Success', 'The investigation is created successfully', 'success');
				const newInvs = await getInvestigationsNow();

				const newInv = newInvs?.find(
					(i) => i.name?.toLowerCase?.() === invName?.toLowerCase?.(),
				);

				if (newInv) {
					handleAddUserInv({ label: invName, value: newInv });
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	// Templates
	const [templates, setTemplates] = useState([]);

	const handleCreateNewTemp = async () => {
		try {
			setSaving(true);
			setTempModal(false);

			const res = await axios.post(
				'/investigation-template',
				{
					name: newTemp,
					investigationIds: selectTable?.values?.selectedList,
				},
				{ headers: { organisationId } },
			);
			if (res.data.success) {
				await getInvestigationTemplates();
				showNotification('Success', 'New template created successfully', 'success');
			}
		} catch (err) {
			console.log(err);
		} finally {
			setSaving(false);
		}
	};

	const getInvestigationTemplates = async () => {
		try {
			setLoading(true);

			const res = await axios.get('/investigation-template', {
				headers: { organisationId },
			});
			if (res.data.success) {
				const temps = res.data.investigationTemplates;
				setTemplates(
					temps?.sort((a, b) => (moment(a.createdAt) > moment(b.createdAt) ? 1 : -1)),
				);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	// User Investigations
	const [patientInvestigations, setPatientInvestigations] = useState([]);
	const [newPInvestigation, setNewPInvestigation] = useState(null);

	const getPatientInvestigations = async () => {
		try {
			setLoading(true);
			const res = await axios.get('/user-investigate', { params: { userId } });
			if (res.data.success) {
				setPatientInvestigations(res.data.userInvestigates);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const createUserInv = async (newInv) => {
		try {
			setSaving(true);
			if (Array.isArray(newInv)) {
				for (let singleInv of newInv) {
					await axios.post('/user-investigate', singleInv);
				}
			} else {
				await axios.post('/user-investigate', newInv);
			}

			await getPatientInvestigations();
		} catch (err) {
			console.log(err);
		} finally {
			setSaving(false);
		}
	};

	// Local Table
	const formik = useFormik({
		initialValues: {
			table: [],
		},
	});

	const [dates, setDates] = useState([]);

	const handleAddDate = async () => {
		dispatch(toggleCreateLoading(true));
		try {
			setSaving(true);
			setDateModal(false);

			const invs = Object.keys(formik.values.table);

			// If more than investigations
			if (invs?.length > 0) {
				// Update date in all
				for (var invName of invs) {
					const formikInv = formik.values.table[invName];
					const userInvestigateList = [
						...Object.keys(formikInv.dates)
							.filter((i) => !!i)
							.map((i) => ({
								date: i,
								value: formikInv?.dates?.[i],
							})),
					];
					if (newDate) userInvestigateList.push({ date: newDate, value: '' });
					await axios.patch('/user-investigate', {
						userId: userId,
						name: invName,
						investigationId: formikInv?.id,
						investigationUnitId: formikInv?.unitId,
						userInvestigateList,
						userInvestigateId: formikInv?.userInvestigateId,
					});
				}

				// Fetch updated user investigates
				await getPatientInvestigations();
			} else if (invs?.length == 0) {
				// If 0 investigations, update date locally
				const newDates = [...dates, newDate];
				setDates(newDates?.sort?.());
			}
		} catch (err) {
			console.log(err);
		} finally {
			setSaving(false);
			dispatch(toggleCreateLoading(false));
		}
	};

	const [showLess, setShowLess] = useState(true);

	const handleAddUserInv = (creatableInv) => {
		const inv = creatableInv?.value;

		createUserInv({
			userId: userId,
			name: inv.name,
			investigationId: inv.id,
			investigationUnitId: inv?.Investigation_units?.[0]?.id,
			userInvestigateList: dates?.filter((i) => !!i)?.map((i) => ({ date: i, value: '' })),
		});
	};

	const handleUpdateUserInv = async (invName, formikInv) => {
		try {
			setSaving(true);
			const res = await axios.patch('/user-investigate', {
				userId: userId,
				name: invName,
				investigationId: formikInv?.id,
				investigationUnitId: formikInv?.unitId,
				userInvestigateList: Object.keys(formikInv.dates)
					.filter((i) => !!i)
					.map((i) => ({
						date: i,
						value: formikInv?.dates?.[i],
					})),
				userInvestigateId: formikInv?.userInvestigateId,
			});

			// Not updating coz local table already updated via formik
			if (res.data.success) {
				console.log('updated');
			}
		} catch (err) {
			console.log(err);
		} finally {
			setSaving(false);
		}
	};

	const handleDeleteUserInv = async (invName) => {
		const inv = formik.values.table[invName];

		try {
			setLoading(true);

			// Delete User Investigation
			await axios.delete(`/user-investigate/${inv.userInvestigateId}`);

			// Refetch to rerender table
			await getPatientInvestigations();
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	// Build Local Table Dates
	useEffect(() => {
		setDates(
			[
				...new Set([
					...patientInvestigations
						?.map((i) => i.User_investigate_lists)
						?.flat(1)
						?.map((i) =>
							i.date
								? moment(i.date).format('YYYY-MM-DD')
								: moment(new Date().toISOString()).format('YYYY-MM-DD'),
						),
					moment(new Date().toISOString()).format('YYYY-MM-DD'),
					...opdDates?.map((i) =>
						i.date
							? moment(i.date).format('YYYY-MM-DD')
							: moment(new Date().toISOString()).format('YYYY-MM-DD'),
					),
				]),
			].sort(),
		);
	}, [patientInvestigations, opdDates]);

	// Build Table
	useEffect(() => {
		formik.setValues({
			table: Object.assign(
				{},
				...patientInvestigations
					?.filter?.((i) => i.Investigation)
					?.map((i) => ({
						[i.Investigation?.name || i.name]: {
							userInvestigateId: i?.id,
							id: i.Investigation.id,
							inv: i.Investigation,
							from: 'api',
							unit: i.Investigation_unit?.Investigate_Unit?.name,
							unitId: i.Investigation_unit?.id,
							min: i.Investigation_unit?.min,
							max: i.Investigation_unit?.max,
							dates: Object.assign(
								{},
								...i.User_investigate_lists?.filter((j) => !!j.date).map((j) => ({
									[moment(j.date)?.format('YYYY-MM-DD')]: j.value || '',
								})),
							),
						},
					})),
			),
		});
	}, [patientInvestigations]);

	const { selectTable, SelectAllCheck } = useSelectTable(
		Object.keys(formik.values.table)?.map((investigation) => {
			return { id: formik.values.table[investigation].id };
		}) || [],
	);

	useEffect(() => {
		// dispatch(getOpdDates(userId, vendorId));
		dispatch(getInvestigations());
		getInvestigationTemplates();
		getPatientInvestigations();
	}, []);

	return (
		<>
			<Card shadow='none' stretch>
				<CardHeader>
					<CardLabel icon='Biotech' iconColor='primary'>
						<CardTitle>Investigations</CardTitle>
						{(saving || loading) && (
							<CardSubTitle className='text-muted fst-italic fw-normal'>
								{loading ? 'Loading...' : 'Saving...'}
							</CardSubTitle>
						)}
					</CardLabel>
					<CardActions>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='Print'
							onClick={() =>
								navigate(`/patients/${userId}/prescription-pdf`, {
									state: {
										prescription: prescriptions.filter(
											(x) => x?.Appointment_medicines?.length > 0,
										)?.[0],
										patient,
										fromInvestigations: true,
									},
								})
							}>
							Print
						</Button>
						<Button
							isDisable={selectTable.values.selectedList.length === 0}
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='Save'
							onClick={() =>
								isPermitted('Medical Profile', 'write', () => setTempModal(true))
							}>
							Save as Template
						</Button>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='Download'
							onClick={() =>
								isPermitted('Medical Profile', 'write', () =>
									setTempLoadModal(true),
								)
							}>
							Load Template
						</Button>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='Add'
							onClick={() =>
								isPermitted('Medical Profile', 'write', () => setDateModal(true))
							}>
							Add Date
						</Button>
						<Button
							isOutline={!darkModeStatus}
							isDisable={selectTable.values.selectedList.length === 0}
							isLight={darkModeStatus}
							color='primary'
							icon='TrendingUp'
							onClick={() =>
								isPermitted('Medical Profile', 'read', handleGenerateChart)
							}>
							Generate Chart
						</Button>
					</CardActions>
				</CardHeader>

				<PermissionCheck module={'Medical Profile'} action={'read'}>
					<CardBody className='table-responsive'>
						<table className='table table-bordered table-sm'>
							<tbody>
								<tr>
									<th className='p-2'>{SelectAllCheck}</th>
									<th>
										<div
											className='d-flex justify-content-between'
											style={{ minWidth: '12rem' }}>
											Test Name
											<Tooltips title={showLess ? 'Expand' : 'Shrink'}>
												<Button
													style={{ height: '1.6rem' }}
													isLink
													color='dark'
													className='p-0 m-0'
													size='md'
													icon={showLess ? 'LastPage' : 'FirstPage'}
													onClick={() => setShowLess((prev) => !prev)}
												/>
											</Tooltips>
										</div>
									</th>
									{(showLess ? dates?.slice(-3) : dates)?.map((date) => (
										<th style={{ minWidth: '12rem' }}>
											{date && moment(date).format('DD-MM-YYYY')}
										</th>
									))}
								</tr>

								{Object.keys(formik.values.table)?.map((investigation, idx) => (
									<tr key={idx}>
										<td className='p-2'>
											<Checks
												id={formik.values.table[investigation].id}
												name={'selectedList'}
												value={formik.values.table?.[
													investigation
												]?.id?.toString()}
												onChange={selectTable.handleChange}
												checked={selectTable?.values?.selectedList?.includes(
													formik.values.table?.[
														investigation
													]?.id?.toString(),
												)}
											/>
										</td>
										<td style={{ minWidth: '12rem' }}>
											<div>
												<div
													className='d-flex justify-content-between align-items-center'
													style={{
														cursor: 'pointer',
													}}
													onDoubleClick={() =>
														isPermitted(
															'Medical Profile',
															'write',
															() => {
																setEditInv(
																	formik.values.table[
																		investigation
																	].id,
																);
																setEditInvVal(investigation);
															},
														)
													}>
													{editInv ===
													formik.values.table[investigation].id ? (
														<Input
															style={{
																borderRadius: 0,
																background: 'transparent',
																height: '2.5rem',
															}}
															value={editInvVal}
															onChange={(e) =>
																isPermitted(
																	'Medical Profile',
																	'write',
																	() =>
																		setEditInvVal(
																			e.target.value,
																		),
																)
															}
															onBlur={(e) => {
																if (editInvVal != investigation) {
																	const inv =
																		formik.values.table[
																			investigation
																		];
																	handleUpdateInvestigation({
																		investigationId: inv.id,
																		name: editInvVal,
																		type: 'GP',
																		investigationUnits: [],
																	}).then(() => {
																		let tableKeys = Object.keys(
																			formik.values.table,
																		);
																		tableKeys = [
																			...tableKeys.slice(
																				0,
																				idx,
																			),
																			editInvVal,
																			...tableKeys.slice(
																				idx + 1,
																			),
																		];
																		const newTable =
																			Object.assign(
																				{},
																				...tableKeys.map(
																					(i) => ({
																						[i]: formik
																							.values
																							.table[
																							i
																						],
																					}),
																				),
																			);

																		newTable[editInvVal] =
																			formik.values.table[
																				investigation
																			];
																		formik.setValues({
																			table: newTable,
																		});
																	});
																}
																setEditInv(null);
																setEditInvVal('');
															}}
														/>
													) : (
														<h6 className='m-0 ms-1'>
															{investigation}
														</h6>
													)}

													<div className='d-flex'>
														<Tooltips title='View Trend'>
															<Button
																isLink
																color='primary'
																className='p-0'
																size='sm'
																icon='TrendingUp'
																iconColor='primary'
																onClick={() =>
																	isPermitted(
																		'Medical Profile',
																		'read',
																		() =>
																			handleGenerateChart(
																				investigation,
																				formik.values.table[
																					investigation
																				]['dates'],
																			),
																	)
																}
															/>
														</Tooltips>
														<Tooltips title='delete'>
															<Button
																style={{
																	height: '2rem',
																}}
																isLink
																color='danger'
																className='p-0'
																size='sm'
																icon='cancel'
																iconColor='danger'
																onClick={() =>
																	isPermitted(
																		'Medical Profile',
																		'delete',
																		() =>
																			handleDeleteUserInv(
																				investigation,
																			),
																	)
																}
															/>
														</Tooltips>
													</div>
												</div>
												{formik.values?.table?.[investigation]?.unitId ? (
													<div className='d-flex justify-content-evenly w-75'>
														<span
															style={{
																fontSize: '10px',
															}}>
															{
																formik.values.table?.[investigation]
																	?.unit
															}
														</span>
														<Input
															style={{
																fontSize: '10px',
																fontWeight: 'normal',
																borderRadius: '0.1rem',
																padding: 2,
																textAlign: 'center',
																width: '30%',
															}}
															placeholder='min'
															value={
																formik.values.table[investigation]
																	.min
															}
															onChange={(e) =>
																isPermitted(
																	'Medical Profile',
																	'write',
																	() =>
																		formik.setValues(
																			(prev) => ({
																				table: {
																					...prev.table,
																					[investigation]:
																						{
																							...prev
																								.table[
																								investigation
																							],
																							min:
																								parseInt(
																									e
																										.target
																										.value,
																								) ||
																								formik
																									.values
																									.table[
																									investigation
																								]
																									.min ||
																								'',
																						},
																				},
																			}),
																		),
																)
															}
															onBlur={() => {
																const inv =
																	formik.values.table[
																		investigation
																	];
																handleUpdateInvestigation({
																	investigationId: inv.id,
																	name: investigation,
																	type: 'GP',
																	investigationUnits: [
																		{
																			investigationUnitId:
																				inv.unitId,
																			unit: inv.unit,
																			min: formik.values
																				.table[
																				investigation
																			].min,
																			max: formik.values
																				.table[
																				investigation
																			].max,
																		},
																	],
																});
															}}
														/>
														<Input
															style={{
																fontSize: '10px',
																fontWeight: 'normal',
																borderRadius: '0.1rem',
																padding: 2,
																textAlign: 'center',
																width: '30%',
															}}
															placeholder='max'
															value={
																formik.values.table[investigation]
																	.max
															}
															onChange={(e) =>
																isPermitted(
																	'Medical Profile',
																	'write',
																	() =>
																		formik.setValues(
																			(prev) => ({
																				table: {
																					...prev.table,
																					[investigation]:
																						{
																							...prev
																								.table[
																								investigation
																							],
																							max:
																								parseInt(
																									e
																										.target
																										.value,
																								) ||
																								formik
																									.values
																									.table[
																									investigation
																								]
																									.max ||
																								'',
																						},
																				},
																			}),
																		),
																)
															}
															onBlur={() => {
																const inv =
																	formik.values.table[
																		investigation
																	];
																handleUpdateInvestigation({
																	investigationId: inv.id,
																	name: investigation,
																	type: 'GP',
																	investigationUnits: [
																		{
																			investigationUnitId:
																				inv.unitId,
																			unit: inv.unit,
																			min: formik.values
																				.table[
																				investigation
																			].min,
																			max: formik.values
																				.table[
																				investigation
																			].max,
																		},
																	],
																});
															}}
														/>
													</div>
												) : !formik.values.table[investigation]?.inv
														?.isAdmin ? (
													<Button
														color='primary'
														isLink
														className='border-0 px-1 py-0'
														onClick={() => {
															formikInvUnit.setValues((prev) => ({
																...prev,
																...formik.values.table[
																	investigation
																].inv,
															}));
															setAddUnitFormikInv(
																formik.values.table[investigation],
															);
															setAddInvUnitModal(true);
														}}>
														<span style={{ fontSize: '10px' }}>
															Add Unit
														</span>
													</Button>
												) : (
													<></>
												)}
											</div>
										</td>
										{(showLess ? dates?.slice(-3) : dates)?.map((date) => {
											const val =
												formik.values.table?.[investigation]?.['dates']?.[
													moment(date)?.format('YYYY-MM-DD')
												] || '';
											const min =
												formik.values.table?.[investigation]?.min || '';
											const max =
												formik.values.table?.[investigation]?.max || '';

											return (
												<td>
													<Input
														style={{
															fontWeight: 'normal',
															padding: '0.60rem',
															borderRadius: '0.3rem',
															borderColor: '#dee2e6',
															boxShadow: 'none',
															border: 0,
														}}
														className={
															min && max && val
																? val <= max && val >= min
																	? `bg-success text-white`
																	: 'bg-danger text-white'
																: ''
														}
														value={
															formik.values.table?.[investigation]?.[
																'dates'
															]?.[
																moment(date)?.format('YYYY-MM-DD')
															] || ''
														}
														onChange={(e) =>
															isPermitted(
																'Medical Profile',
																'write',
																() =>
																	formik.setValues((prev) => ({
																		...prev,
																		table: {
																			...prev.table,
																			[investigation]: {
																				...prev.table[
																					investigation
																				],
																				dates: {
																					...prev.table[
																						investigation
																					]['dates'],
																					[moment(
																						date,
																					)?.format(
																						'YYYY-MM-DD',
																					)]:
																						e.target
																							.value,
																				},
																			},
																		},
																	})),
															)
														}
														onBlur={(e) =>
															handleUpdateUserInv(
																investigation,
																formik.values.table[investigation],
															)
														}
														disabled={moment(date) > moment()}
													/>
												</td>
											);
										})}
									</tr>
								))}

								<tr>
									<td></td>
									<td>
										<Creatable
											components={{
												DropdownIndicator: () => null,
												IndicatorSeparator: () => null,
											}}
											className='p-0'
											value={newPInvestigation}
											styles={{
												control: (base) => ({
													...base,
													border: 0,
													boxShadow: 'none',
												}),
											}}
											options={investigations?.map((i) => ({
												label: i?.name,
												value: i,
											}))}
											onChange={(i) =>
												isPermitted('Medical Profile', 'write', () =>
													handleAddUserInv(i),
												)
											}
											onCreateOption={(i) =>
												isPermitted('Medical Profile', 'write', () =>
													handleCreateNewInv(i),
												)
											}
										/>
									</td>
									{(showLess ? dates?.slice(0, 3) : dates)?.map((i) => (
										<td></td>
									))}
								</tr>
							</tbody>
						</table>
					</CardBody>
				</PermissionCheck>
			</Card>

			<Modal
				isOpen={dateModal}
				setIsOpen={setDateModal}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDateModal}>
					<ModalTitle>Add Date</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='date' label='Date'>
						<Input
							autoComplete='off'
							onChange={(e) => setNewDate(e.target.value)}
							type='date'
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Date'
							value={newDate}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setDateModal(false);
						}}>
						Cancel
					</Button>
					<Button
						color='primary'
						icon='save'
						onClick={() => isPermitted('Medical Profile', 'write', handleAddDate)}>
						Save
					</Button>
				</ModalFooter>
			</Modal>

			{/* Add Template Modal */}
			<Modal isOpen={tempModal} setIsOpen={setTempModal} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setTempModal}>
					<ModalTitle id='deletePharmLabel'>Add Template</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='name' label='Template Name' isFloating>
						<Input
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							onChange={(e) => setNewTemp(e.target.value)}
							value={newTemp}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setTempModal(false);
						}}>
						Cancel
					</Button>
					<Button
						color='primary'
						icon='save'
						onClick={() =>
							isPermitted('Medical Profile', 'write', handleCreateNewTemp)
						}>
						Save
					</Button>
				</ModalFooter>
			</Modal>

			{/* Load Template Modal */}
			<Modal
				isOpen={tempLoadModal}
				setIsOpen={setTempLoadModal}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setTempLoadModal}>
					<ModalTitle>Load Template</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup label='Template Name'>
						<ReactSelect
							id='tempLoadName'
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
									height: '2.6rem',
								}),
							}}
							options={templates.map((i) => ({ label: i.name, value: i }))}
							value={selectedLoadTemp}
							onChange={(i) => setSelectedLoadTemp(i)}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setTempModal(false);
						}}>
						Cancel
					</Button>
					<Button
						color='primary'
						icon='save'
						onClick={() =>
							isPermitted('Medical Profile', 'write', () => {
								const tempInvs =
									selectedLoadTemp?.value?.Investigation_template_lists;
								const userInvs = patientInvestigations.map(
									(i) => i.Investigation?.id,
								);
								const newUserInvs = tempInvs
									.filter((i) => !userInvs.includes(i.investigationId))
									.map((i) => i.Investigation)
									.filter((i) => i)
									.map((inv) => ({
										userId: userId,
										name: inv.name,
										investigationId: inv.id,
										investigationUnitId: inv?.Investigation_units?.[0]?.id,
										userInvestigateList: dates?.map((i) => ({
											date: i,
											value: '',
										})),
									}));
								createUserInv(newUserInvs);
								setTempLoadModal(false);
							})
						}>
						Load
					</Button>
				</ModalFooter>
			</Modal>

			{/* Chart Modal */}
			<Modal
				size='xl'
				isOpen={chartModal}
				setIsOpen={() => {
					setModalInvDesc('');
					setModalInvIdices([]);
					setChartModal(false);
				}}
				isCentered
				isAnimation={false}>
				<ModalHeader
					setIsOpen={() => {
						setModalInvDesc('');
						setModalInvIdices([]);
						setChartModal(false);
					}}>
					<ModalTitle id='deletePharmLabel'>Chart</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<CanvasJSChart
						options={{
							animationEnabled: true,
							title: {
								text: `${chartData?.map?.((i) => i.name)?.join?.(', ')} Trend`,
							},
							axisY: {
								title: 'Value',
							},
							axisX: {
								title: 'Dates',
							},
							data: chartData,
						}}
					/>

					{/* {(loadingDesc || modalInvDesc) && ( */}
					<CardBody className='table-responsive'>
						<table className='table table-bordered table-sm'>
							<tbody>
								<tr>
									<th>
										<div
											className='d-flex justify-content-between'
											style={{ minWidth: '12rem' }}>
											Test Name
											<Tooltips title={showLess ? 'Expand' : 'Shrink'}>
												<Button
													style={{ height: '1.6rem' }}
													isLink
													color='dark'
													className='p-0 m-0'
													size='md'
													icon={showLess ? 'LastPage' : 'FirstPage'}
													onClick={() => setShowLess((prev) => !prev)}
												/>
											</Tooltips>
										</div>
									</th>
									{(showLess ? dates?.slice(-3) : dates)?.map((date) => (
										<th style={{ minWidth: '12rem' }}>
											{date && moment(date).format('DD-MM-YYYY')}
										</th>
									))}
								</tr>
								{Object.keys(formik.values.table)
									.filter((i) =>
										modalInvIdices?.includes(formik.values.table?.[i]?.id),
									)
									.map((investigation, idx) => (
										<tr key={idx}>
											<td style={{ minWidth: '10rem' }}>
												<div className='d-flex justify-content-between align-items-center'>
													<h6 className='m-0 ms-1'>{investigation}</h6>
												</div>
											</td>
											{(showLess ? dates?.slice(-3) : dates)?.map((date) => {
												const val =
													formik.values.table[investigation]['dates'][
														moment(date)?.format('YYYY-MM-DD')
													] || '';
												const min =
													formik.values.table[investigation].min || '';
												const max =
													formik.values.table[investigation].max || '';

												return (
													<td>
														<Input
															style={{
																fontWeight: 'normal',
																padding: '0.60rem',
																borderRadius: '0.3rem',
																borderColor: '#dee2e6',
																boxShadow: 'none',
																border: 0,
															}}
															className={
																min && max && val
																	? val <= max && val >= min
																		? `bg-success text-white`
																		: 'bg-danger text-white'
																	: ''
															}
															value={val}
															onChange={(e) =>
																formik.setValues((prev) => ({
																	...prev,
																	table: {
																		...prev.table,
																		[investigation]: {
																			...prev.table[
																				investigation
																			],
																			dates: {
																				...prev.table[
																					investigation
																				]['dates'],
																				[moment(
																					date,
																				)?.format(
																					'YYYY-MM-DD',
																				)]: e.target.value,
																			},
																		},
																	},
																}))
															}
															onBlur={(e) =>
																handleUpdateUserInv(
																	investigation,
																	formik.values.table[
																		investigation
																	],
																)
															}
															disabled={moment(date) > moment()}
														/>
													</td>
												);
											})}
										</tr>
									))}
							</tbody>
						</table>
					</CardBody>
					{/* )} */}

					{(loadingDesc || modalInvDesc) && (
						<CardBody>
							<h4>Description</h4>
							<p>
								{loadingDesc ? (
									<Progress
										value={100}
										min={0}
										max={100}
										height={10}
										isStriped
										isAnimated
									/>
								) : (
									modalInvDesc
								)}
							</p>
						</CardBody>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							setChartModal(false);
						}}>
						Close
					</Button>
				</ModalFooter>
			</Modal>

			{/* Add Unit Modal */}
			<Modal isOpen={addInvUnitModal} setIsOpen={() => {}} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setAddInvUnitModal}>
					<ModalTitle>Add Investigation Unit</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row g-4 mb-4'>
						<div className='col-12'>
							<FormGroup id='name' label='Name' isFloating>
								<Input
									className='form-control'
									placeholder='Name'
									onChange={formikInvUnit.handleChange}
									onBlur={formikInvUnit.handleBlur}
									value={formikInvUnit.values.name}
									isValid={formikInvUnit.isValid}
									isTouched={formikInvUnit.touched.name}
									invalidFeedback={formikInvUnit.errors.name}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='perUnitPrice' label='Per Unit Price' isFloating>
								<Input
									className='form-control'
									type='number'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									placeholder='Per Unit Price'
									onChange={formikInvUnit.handleChange}
									onBlur={formikInvUnit.handleBlur}
									value={formikInvUnit.values.perUnitPrice}
									isValid={formikInvUnit.isValid}
									isTouched={formikInvUnit.touched.perUnitPrice}
									invalidFeedback={formikInvUnit.errors.perUnitPrice}
								/>
							</FormGroup>
						</div>
					</div>
					{formikInvUnit.values.units.map((unit, idx) => (
						<div className='row my-2 align-items-center'>
							<div className='col-5'>
								<FormGroup id={`units[${idx}].unit`} label='Unit' isFloating>
									<Input
										className='form-control'
										placeholder='Unit'
										onChange={formikInvUnit.handleChange}
										value={unit.unit}
										onBlur={formikInvUnit.handleBlur}
										isValid={formikInvUnit.isValid}
										isTouched={formikInvUnit.touched.units?.[idx]?.unit}
										invalidFeedback={formikInvUnit.errors.units?.[idx]?.unit}
									/>
								</FormGroup>
							</div>
							<div className='col-3'>
								<FormGroup id={`units[${idx}].min`} label='Min' isFloating>
									<Input
										className='form-control'
										type='number'
										onKeyDown={(e) =>
											invalidNums.includes(e.key) && e.preventDefault()
										}
										placeholder='Min'
										onChange={formikInvUnit.handleChange}
										value={unit.min}
										onBlur={formikInvUnit.handleBlur}
										isValid={formikInvUnit.isValid}
										isTouched={formikInvUnit.touched.units?.[idx]?.min}
										invalidFeedback={formikInvUnit.errors.units?.[idx]?.min}
									/>
								</FormGroup>
							</div>
							<div className='col-3'>
								<FormGroup id={`units[${idx}].max`} label='Max' isFloating>
									<Input
										className='form-control'
										type='number'
										onKeyDown={(e) =>
											invalidNums.includes(e.key) && e.preventDefault()
										}
										placeholder='Max'
										onChange={formikInvUnit.handleChange}
										value={unit.max}
										onBlur={formikInvUnit.handleBlur}
										isValid={formikInvUnit.isValid}
										isTouched={formikInvUnit.touched.units?.[idx]?.max}
										invalidFeedback={formikInvUnit.errors.units?.[idx]?.max}
									/>
								</FormGroup>
							</div>
							{/* <div className='col-1 p-0'>
								<Button
									isLink
									icon='Delete'
									color='danger'
									isDisable={formikInvUnit.values.units.length === 1}
									onClick={() => {
										formikInvUnit.setValues((prev) => ({
											...prev,
											units: [
												...prev.units.slice(0, idx),
												...prev.units.slice(idx + 1),
											],
										}));
									}}></Button>
							</div> */}
						</div>
					))}
					{/* <div className='w-100 d-flex'>
						<Button
							isLink
							icon='Add'
							color='primary'
							className='ms-auto'
							onClick={() =>
								formikInvUnit.setFieldValue('units', [
									...formikInvUnit.values.units,
									{ unit: '', min: '', max: '' },
								])
							}>
							Add Row
						</Button>
					</div> */}
				</ModalBody>
				<ModalFooter className='bg-transparent'>
					<Button
						icon='Save'
						color='primary'
						isOutline
						isDisable={
							(!formikInvUnit.isValid && !!formikInvUnit.submitCount) ||
							loading ||
							saving
						}
						onClick={formikInvUnit.handleSubmit}>
						{loading || saving ? 'Saving...' : 'Save'}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default Investigations;
