import {
	SET_ACCOMMODATIONS,
	DELETE_ACCOMMODATION,
	SET_IPDS,
	DELETE_IPD,
	SET_IPD_STATUS,
	SET_COMPANY_LIST,
	SET_PATIENT_STATUS,
	SET_IPD_ROOMS,
	SET_IPD_WARDS,
	SET_IPD_BED_STATUSES,
	SET_PATIENT_IPD_FILE,
	SET_GYNAE_RISKLIST,
	SET_GYNAE_LABLIST,
	SET_IPD_HISTORY_TEMPLATE,
	SET_IPD_PHYSICAL_EXAMINATION_TEMPLATE,
	SET_STORE_ITEMS,
	SET_STORE_PURCHASE,
	SET_STORE_INVENTORY,
	SET_STORE_INDENTATION,
	SET_STORE_SALES,
	SET_PATIENT_STORE_ASSIGN,
	SET_PAYMENT_REMARKS,
	SET_IPD_PATIENT_BED_LIST,
	SET_VISITING_DOCTOR_SUGGESTIONS,
	SET_TPA_LIST,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import { tr } from 'date-fns/locale';
import axiosz from 'axios';
import { toggleCreateLoading, toggleTableLoading } from './componentActions';
import { DefaultPagination } from '../helpers/config';
import { getPatientStoreAssignList } from './storeActions';

export const getAccommodations = () => async (dispatch) => {
	try {
		const res = await axios.get('/ipd/accomodation');
		if (res.data.success) {
			dispatch({
				type: SET_ACCOMMODATIONS,
				payload: res.data?.data?.accommodations,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createAccommodation = (newRec, navigate) => async (dispatch) => {
	try {
		const res = await axios.post('/ipd/accomodation', newRec);
		if (res.data.success) {
			dispatch(getAccommodations());
			showNotification('Success', 'The accommodation is created successfully', 'success');
			navigate(`/${dashboardMenu.ipd.subMenu.viewAccommodations.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateAccommodation = (updateBody, navigate) => async (dispatch) => {
	try {
		const res = await axios.patch('/ipd/accomodation', updateBody);
		if (res.data.success) {
			dispatch(getAccommodations());
			showNotification('Success', 'The accommodation is updated successfully', 'success');
			if (navigate) navigate(`/${dashboardMenu.ipd.subMenu.viewAccommodations.path}`);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteAccommodation = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/ipd/accomodation/${id}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_ACCOMMODATION,
				payload: id,
			});
			showNotification(
				'Success',
				'The accommodation has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getIpdStatus = () => async (dispatch) => {
	try {
		const res = await axios.get(`/ipd-status`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_STATUS,
				payload: res.data.data.ipdStatuses,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getCompanyList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/insurance-company-list`);
		if (res.data.success) {
			dispatch({
				type: SET_COMPANY_LIST,
				payload: res.data.data.list,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createIPD = (newRec, navigate) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.post('/doctor/ipd', newRec);
		if (res.data.success) {
			// dispatch(getIpds());
			showNotification('Success', 'The IPD is created successfully', 'success');
			navigate(`/${dashboardMenu.ipd.subMenu.roomStatus.path}`);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

let cancelToken;
export const getIpds =
	(params = DefaultPagination, { organisationId } = { organisationId: currentOrg }) =>
	async (dispatch) => {
		var url = '/doctor/ipd/ipd-all-ipd-by-organisation';
		// if (params) {
		// 	url += '?';
		// 	Object.keys(params).forEach((key, idx) => {
		// 		url += `${key}=${params[key]}&`;
		// 	});
		// }
		try {
			if (typeof cancelToken != typeof undefined) {
				cancelToken?.cancel('Cancelling prev req');
			}
			cancelToken = axiosz.CancelToken?.source();
			axiosz.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');
			// const res = await axios.get(url);
			const res = await axiosz.get(url, {
				cancelToken: cancelToken?.token,
				params,
				baseURL: process.env.REACT_APP_SERVER_URL,
			});
			if (res.data.success) {
				dispatch({
					type: SET_IPDS,
					payload: {
						ipds: res.data?.data,
						page: res.data.totalPage,
						total: res.data.totalRecord,
					},
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const deleteIPD = (ipdId, organisationId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/ipd`, { ipdId, organisationId });
		if (res.data.success) {
			dispatch({
				type: DELETE_IPD,
				payload: { ipdId, organisationId },
			});
			showNotification('Success', 'The IPD has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientStatus = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/patient-condition-status`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_STATUS,
				payload: res.data.result,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getIPDRooms = (orgId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.get(`/doctor/hospital-ward/ward-rooms/${orgId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_ROOMS,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const createIPDRoom = (data, orgId) => async (dispatch) => {
	try {
		const res = await axios.post('/ipd/accomodation', data);
		if (res.data.success) {
			showNotification('Success', 'The room is created successfully', 'success');
			dispatch(getIPDRooms(orgId));
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateIPDRoom = (data, orgId) => async (dispatch) => {
	try {
		const res = await axios.patch('/ipd/accomodation', data);
		if (res.data.success) {
			showNotification('Success', 'The room is updated successfully', 'success');
			dispatch(getIPDRooms(orgId));
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteIPDRoom = (id, orgId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/ipd/accomodation/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The room has been deleted successfully', 'success');
			dispatch(getIPDRooms(orgId));
		}
	} catch (err) {
		console.log(err);
	}
};

export const getWardList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/hospital-ward`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_WARDS,
				payload: res.data.result,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createWard = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/hospital-ward`, data);
		if (res.data.success) {
			showNotification('Success', 'The Ward created successfully', 'success');
			dispatch(getWardList());
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateWard = (data) => async (dispatch) => {
	try {
		const res = await axios.put(`/doctor/hospital-ward`, data);
		if (res.data.success) {
			showNotification('Success', 'The Ward updated successfully', 'success');
			dispatch(getWardList());
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteWard = (id) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/hospital-ward/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The Ward deleted successfully', 'success');
			dispatch(getWardList());
		}
	} catch (error) {
		console.error(error);
	}
};

export const getIpdBedStatuses = () => async (dispatch) => {
	try {
		const res = await axios.get('/doctor/ipd/all-occupied-bed-status');
		if (res.data.success) {
			dispatch({
				type: SET_IPD_BED_STATUSES,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPatientIpdFile = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-patient-detail/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_IPD_FILE,
				payload: res.data.ipds,
			});
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const shiftIpdRoom = (data) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.put(`/doctor/ipd/update-bed-status`, data);
		if (res.data.success) {
			dispatch(getPatientIpdFile(data?.ipdId));
			showNotification('Success', 'The room has been shifted successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const getHistoryTemplates = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd-template/ipd-get-history-template/`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_HISTORY_TEMPLATE,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPhysicalExaminationTemplates = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd-template/ipd-get-system-template`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_PHYSICAL_EXAMINATION_TEMPLATE,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createPhysicalExaminationTemplate = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/ipd-template/ipd-add-system-template`, data);
		if (res.data.success) {
			dispatch(getPhysicalExaminationTemplates());
		}
	} catch (error) {
		console.error(error);
	}
};

export const addHistoryTemplate = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/ipd-template/ipd-add-history-template`, data);
		if (res.data.success) {
			dispatch(getHistoryTemplates(data?.type));
		}
	} catch (error) {
		console.error(error);
	}
};

export const getIpdRiskList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/gyno/gyno-risk-stratifications`);
		if (res.data.success) {
			dispatch({
				type: SET_GYNAE_RISKLIST,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getIpdLabList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/gyno/gyno-lab-other-test`);
		if (res.data.success) {
			dispatch({
				type: SET_GYNAE_LABLIST,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getIpdPaymentRemarks = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/bill/payment-remark`);
		if (res.data.success) {
			dispatch({
				type: SET_PAYMENT_REMARKS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createIpdPaymentRemark = async (data, dispatch) => {
	try {
		const res = await axios.post(`/doctor/ipd/bill/payment-remark`, data);
		if (res.data.success) {
			dispatch(getIpdPaymentRemarks());
			return res.data.data;
		}
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const getDoctorsWiseSummary = async (vendorId, startDate, endDate) => {
	try {
		const res = await axios.get(`/doctor/bill/reports/ipd/${vendorId}`, {
			params: { startDate, endDate },
		});
		if (res.data.success) {
			return res.data.ipdBills;
		}
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const getVisitingDoctorSuggestions = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd-notes/visit-doctor-suggestions-for-bill/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_VISITING_DOCTOR_SUGGESTIONS,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const confirmPatientStore = (data, patientId) => async (dispatch) => {
	try {
		const res = await axios.put(`/payments/confirm-assign-store-pay`, data);
		if (res.data.success) {
			showNotification('Success', 'The Store Payment confirmed successfully', 'success');
			dispatch(getPatientStoreAssignList(patientId));
		}
	} catch (error) {
		console.log(error);
	}
};

export const getPatientBedList = (ipdId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/ipd/ipd-all-assign-bed-list/${ipdId}`);
		if (res.data.success) {
			dispatch({
				type: SET_IPD_PATIENT_BED_LIST,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const getTpaCompanyList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/tpa`);
		if (res.data.success) {
			dispatch({
				type: SET_TPA_LIST,
				payload: res.data.data || [],
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createTpaCompany = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/tpa`, data);
		if (res.data.success) {
			dispatch(getTpaCompanyList());
			showNotification('Success', 'TPA Company added', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateTpaCompany = (data) => async (dispatch) => {
	try {
		const res = await axios.put(`/doctor/tpa`, data);
		if (res.data.success) {
			dispatch(getTpaCompanyList());
			showNotification('Success', 'TPA Company updated', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteTpaCompany = (companyId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/tpa/${companyId}`);
		if (res.data.success) {
			dispatch(getTpaCompanyList());
			showNotification('Success', 'TPA Company deleted', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteTpaIcon = async (tpaId) => {
	try {
		const res = await axios.put(`/doctor/tpa/remove-icon/${tpaId}`);
		if (res.data.success) {
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteIpdPatientBed = (ipdId, rowId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/ipd/remove-allotted-bed/${ipdId}/${rowId}`);
		if (res.data.success) {
			dispatch(getPatientBedList(ipdId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateIpdPatientBed = (data) => async (dispatch) => {
	try {
		const res = await axios.put(`doctor/ipd/update-start-end-date-allotted-bed`, data);
		if (res.data.success) {
			dispatch(getPatientBedList(data.ipdId));
		}
	} catch (error) {
		console.error(error);
	}
};
