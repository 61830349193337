import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import ReactSelect from 'react-select';
import Creatable from 'react-select/creatable';

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Modal, {
	ModalTitle,
	ModalBody,
	ModalHeader,
	ModalFooter,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Tooltips from '../../../components/bootstrap/Tooltips';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import useDarkMode from '../../../hooks/useDarkMode';

import {
	createAppointment,
	deleteAppointment,
	getDoctorsforAppointment,
	getSlotsByDate,
	updateAppointment,
} from '../../../actions/appointmentActions';
import {
	getPatientAppointments,
	getPatientList,
	getPatients,
} from '../../../actions/patientActions';
import { createNote, getNotes } from '../../../actions/settingActions';
import { invalidNums } from '../../../helpers/helpers';
import showNotification from '../../../components/extras/showNotification';
import { isPermitted } from '../../../actions/helperActions';
import { SET_APPOINTMENT_SLOTS, SET_PATIENT_APPOINTMENTS } from '../../../reducers/types';
import PatientVisits from './PatientVisits';

const validate = (values) => {
	const errors = {};

	if (!values.date) {
		errors.name = 'Required';
	}
	if (!values.date) {
		errors.type = 'Required';
	}
	// if (!values.time) {
	// 	errors.time = 'Required';
	// }
	// if (!values.duration) {
	// 	errors.duration = 'Required';
	// }
	return errors;
};

const AppointmentList = ({ patient, scrollId }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);
	const slots = useSelector((state) => state.appointment.slots);

	// BEGIN :: Upcoming Events
	const [editItem, setEditItem] = useState(null);

	const [editMode, setEditMode] = useState(false);
	const handleUpcomingEdit = (item) => {
		formik.resetForm();
		setSelectedNote(null);
		if (item?.id) {
			const duration = moment
				.duration(moment(item.details, 'HH:mm').diff(moment(item.startTime, 'HH:mm')))
				.asMinutes();

			formik.setValues({
				time: item.startTime || '',
				date: item.date || moment().format('YYYY-MM-DD').toString(),
				duration: duration || '',
				notes: item.description || '',
			});
			setSelectedNote({
				label: item.description || '',
				value: item.description || '',
			});
			setEditItem(item);
		} else {
			setEditItem(null);
		}
		setEditMode(!editMode);
	};

	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState(null);

	const handleDeleteItem = (recId) => {
		setDeleteItemId(recId);
		setDeleteWarn(true);
	};

	const handleDeleteConfirm = () => {
		dispatch(deleteAppointment(deleteItemId, patient?.id));
		setDeleteItemId(null);
		setDeleteWarn(false);
	};
	// END :: Upcoming Events

	const formik = useFormik({
		initialValues: {
			userId: patient?.id,
			vendorId: user?.Vendor_detail?.vendorId,
			time: '',
			date: moment().format('YYYY-MM-DD').toString(),
			duration: '',
			notes: '',
		},
		validate,
		onSubmit: (values, { resetForm }) => {
			if (!formik.values.userId && !editItem) {
				showNotification('Error', 'Please Select Patient', 'danger');
				return;
			}
			if (!formik.values.vendorId && !editItem) {
				showNotification('Error', 'Please Select Doctor', 'danger');
				return;
			}

			const data = {};
			for (var key in values) {
				data[key] = `${values[key]}`;
			}
			data['time'] = data['time']?.slice(0, 5) || '';
			data['duration'] = moment(data['time'], 'HH:mm')
				.add(data['duration'] || 20, 'minutes')
				.format('HH:mm');

			if (editItem) {
				data['appointmentId'] = editItem.id;
				dispatch(updateAppointment(data, selectedOrg?.organisationId, patient?.id));
			} else {
				data['appointmentType'] = 'inClinic';
				data['vendorId'] = parseInt(data['vendorId']);
				data['userId'] = parseInt(data['userId']);
				data['organisationId'] = selectedOrganisation.value;
				dispatch(createAppointment(data, selectedOrg?.organisationId));
			}
			resetForm();
			setEditMode(false);
			dispatch({
				type: SET_APPOINTMENT_SLOTS,
				payload: [],
			});
		},
	});

	const data = useSelector((state) => state.patient.appointments || []);
	const organisations = useSelector((state) => state.organisation.organisations);

	const notes = useSelector((state) => state.setting.notes);
	const [selectedNote, setSelectedNote] = useState(null);

	const patients = useSelector((state) => state.patient.searchPatientList);
	const [selectedPatient, setSelectedPatient] = useState({
		value: patient,
		label: patient?.Members?.[0]?.name || 'Unnamed',
	});
	const [doctors, setDoctors] = useState([]);
	const [selectedDoctor, setSelectedDoctor] = useState({
		value: user,
		label: user?.Vendor_detail?.name || 'Unnamed',
	});
	const [selectedOrganisation, setSelectedOrganisation] = useState(
		selectedOrg
			? {
					label: selectedOrg?.Organisation?.name,
					value: selectedOrg?.organisationId || selectedOrg?.id,
			  }
			: null,
	);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(data);
	items?.sort((a, b) => (a.date > b.date ? -1 : 1));

	useEffect(() => {
		getDoctorsforAppointment(selectedOrganisation.value).then((data) => setDoctors(data));
	}, [selectedOrganisation]);

	useEffect(() => {
		if (
			formik.values.vendorId &&
			selectedDoctor &&
			selectedDoctor?.value?.inClinicType !== 'ticketingSystem' &&
			formik.values.date
		)
			dispatch(
				getSlotsByDate({
					vendorId: formik.values.vendorId,
					date: formik.values.date,
					organisationId: selectedOrganisation.value,
				}),
			);
	}, [formik.values.date, selectedDoctor]);

	useEffect(() => {
		let mounted = true;

		const fetchOthers = () => {
			if (mounted) {
				isPermitted(
					'appointment',
					'write',
					() => {
						dispatch(getNotes());
						// dispatch(getPatientList(selectedOrg?.organisationId));
					},
					null,
					false,
				);
				isPermitted(
					'appointment',
					'read',
					() => {
						dispatch(getPatientAppointments(patient?.id));
					},
					null,
					false,
				);
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
			dispatch({
				type: SET_PATIENT_APPOINTMENTS,
				payload: [],
			});
		};
	}, []);

	return (
		<>
			<Card hasTab className='shadow-none'>
				<CardTabItem title='Appointment' id='appointments'>
					<Card stretch className='shadow-none'>
						<CardHeader borderSize={1}>
							<CardLabel icon='Alarm' iconColor='primary'>
								<CardTitle>Appointments</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									color='primary'
									icon='Add'
									isLight
									onClick={() =>
										isPermitted('Appointment', 'write', handleUpcomingEdit)
									}>
									Add New
								</Button>
							</CardActions>
						</CardHeader>
						<CardBody className='table-responsive'>
							<table className='table table-modern'>
								<thead>
									<tr>
										<th
											onClick={() => requestSort('date')}
											className='cursor-pointer'>
											Time / Date{' '}
										</th>
										<th>End Time</th>
										<th>Doctor</th>
										<th>Notes</th>
										<td />
									</tr>
								</thead>
								<tbody>
									{dataPagination(items, currentPage, perPage).map((item) => (
										<tr key={item.id}>
											<td>
												<div className='ms-2'>
													<div>
														{moment(
															`${item.date} ${item.startTime}`,
														).format('hh:mm a')}
													</div>
													<div className='small text-muted'>
														{moment(
															`${item.date} ${item.startTime}`,
														).format('MMM Do YYYY')}
													</div>
												</div>
											</td>
											<td>
												{item.details
													? moment(item.details, 'HH mm').format(
															'hh:mm a',
													  )
													: '-'}
											</td>
											<td>{'Dr. ' + item?.Vendor?.Vendor_detail?.name}</td>
											<td>{item.description || '-'}</td>
											<td>
												<Tooltips title='Edit'>
													<Button
														isOutline={!darkModeStatus}
														isLight={darkModeStatus}
														className={classNames('text-nowrap', {
															'border-light': !darkModeStatus,
														})}
														icon='Edit'
														onClick={() =>
															isPermitted('Appointment', 'edit', () =>
																handleUpcomingEdit(item),
															)
														}></Button>
												</Tooltips>
												<Tooltips title='Delete'>
													<Button
														isOutline={!darkModeStatus}
														color='danger'
														isLight={darkModeStatus}
														className={classNames('text-nowrap', {
															'border-light': !darkModeStatus,
														})}
														icon='Delete'
														onClick={() =>
															isPermitted(
																'Appointment',
																'delete',
																() => handleDeleteItem(item.id),
															)
														}></Button>
												</Tooltips>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</CardBody>
						<PaginationButtons
							id={scrollId}
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				</CardTabItem>
				<CardTabItem title='Visits' id='visits'>
					<PatientVisits patient={patient} />
				</CardTabItem>
			</Card>

			<Modal
				isOpen={editMode}
				setIsOpen={setEditMode}
				titleId='upcomingEdit'
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setEditMode}>
					<ModalTitle id='upcomingEdit'>{editItem ? 'Edit' : 'Add New'}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup label='Patient'>
								<ReactSelect
									isDisabled
									id='userId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={selectedPatient}
									options={patients?.map?.((p) => ({
										value: p,
										label: p?.name,
									}))}
									onChange={(p) => {
										formik.values.userId = p.value?.selfUserId;
										setSelectedPatient(p);
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup label={'Organisation'}>
								<ReactSelect
									id='userId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={selectedOrganisation}
									options={organisations?.map?.((p) => ({
										value: p.id,
										label: p?.name,
									}))}
									onChange={(p) => {
										setSelectedOrganisation(p);
									}}
									isDisabled={!!editItem}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup label='Doctor'>
								<ReactSelect
									isDisabled={!!editItem}
									id='vendorId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={selectedDoctor}
									options={doctors.map((p) => ({
										value: p,
										label: p.Vendor_detail?.name,
									}))}
									onChange={(p) => {
										formik.values.vendorId = p.value?.id;
										setSelectedDoctor(p);
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='date' label='Appointment Date' isFloating>
								<Input
									type='date'
									autoComplete='off'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.date}
									isValid={formik.isValid}
									isTouched={formik.touched.date}
									invalidFeedback={formik.errors.date}
								/>
							</FormGroup>
						</div>
						{selectedDoctor &&
						selectedDoctor?.value?.inClinicType !== 'ticketingSystem' &&
						slots?.length > 0 ? (
							<div className='col-12'>
								{slots?.map((slot, idx) => (
									<Button
										color='primary'
										className='m-1'
										isOutline={
											formik.values.time != slot?.startTime?.slice(0, -3)
										}
										onClick={() => {
											formik.setFieldValue(
												`time`,
												slot?.startTime?.slice(0, -3),
											);
											formik.setFieldValue(`duration`, slot?.slotTime);
										}}
										key={idx}>
										{moment(slot?.startTime?.slice(0, -3), 'HH:mm').format(
											'hh:mm A',
										)}
										{/* {slot?.startTime?.slice(0, -3)} */}
									</Button>
								))}
							</div>
						) : (
							<>
								<div className='col-12'>
									<FormGroup id='time' label='Appointment Time' isFloating>
										<Input
											type='time'
											autoComplete='off'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.time}
											isValid={formik.isValid}
											isTouched={formik.touched.time}
											invalidFeedback={formik.errors.time}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<FormGroup id='duration' label='Duration (in mins)' isFloating>
										<Input
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											autoComplete='duration'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.duration}
											isValid={formik.isValid}
											isTouched={formik.touched.duration}
											invalidFeedback={formik.errors.duration}
										/>
									</FormGroup>
								</div>
							</>
						)}
						{/* <div className='col-12'>
							<FormGroup id='time' label='Appointment Time' isFloating>
								<Input
									type='time'
									autoComplete='off'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.time}
									isValid={formik.isValid}
									isTouched={formik.touched.time}
									invalidFeedback={formik.errors.time}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='duration' label='Duration (in mins)' isFloating>
								<Input
									type='number'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									autoComplete='duration'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.duration}
									isValid={formik.isValid}
									isTouched={formik.touched.duration}
									invalidFeedback={formik.errors.duration}
								/>
							</FormGroup>
						</div> */}
						<div className='col-12'>
							<FormGroup label='Notes'>
								<Creatable
									id='notes'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
											height: '2.6rem',
											alignItems: 'center',
										}),
									}}
									placeholder='Write here...'
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}}
									value={selectedNote}
									options={notes
										.filter((i) => i.type === 'Appointment')
										.map((i) => ({
											value: i,
											label: i.name,
										}))}
									onCreateOption={(i) => {
										dispatch(
											createNote({
												name: i,
												type: 'Appointment',
												text: i,
											}),
										);
										formik.values.notes = i;
										setSelectedNote({ value: i, label: i });
									}}
									onChange={(i) => {
										formik.values.notes = i.value.text;
										setSelectedNote({ value: i, label: i.value.text });
									}}
								/>
							</FormGroup>
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='bg-transparent'>
					{editItem ? (
						<Button
							icon='Save'
							color='primary'
							isOutline
							isDisable={!formik.isValid && !!formik.submitCount}
							onClick={() =>
								isPermitted('Appointment', 'write', formik.handleSubmit)
							}>
							Save
						</Button>
					) : (
						<Button
							icon='Add'
							color='primary'
							isOutline
							isDisable={!formik.isValid && !!formik.submitCount}
							onClick={() =>
								isPermitted('Appointment', 'write', formik.handleSubmit)
							}>
							Add Appointment
						</Button>
					)}
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deleteRec'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteRecLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete the appointment?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button
						color='danger'
						icon='delete'
						onClick={() => isPermitted('Appointment', 'delete', handleDeleteConfirm)}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default AppointmentList;
