import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { toBlob } from '@progress/kendo-drawing/pdf';
import moment from 'moment';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { staticUrl } from '../../../helpers/helpers';
import Button from '../../../components/bootstrap/Button';
import { getPrescriptionPad } from '../../../actions/profileActions';
import './prescPdf.scss';
import ReactSelect from 'react-select';
import { getDoctors } from '../../../actions/doctorActions';
import { addPatientDocument, uploadSoapAttachment } from '../../../actions/patientActions';
import { toggleCreateLoading } from '../../../actions/componentActions';
import { getConsentForms } from '../../../actions/settingActions';
import * as htmlToImage from 'html-to-image';
import showNotification from '../../../components/extras/showNotification';
import { Action } from '../../../components/Table';

const doctorRegex = /\b[Dd]r(\.?)\b/;

const styles = {
	border: '0.0625rem solid #9c9c9c',
	borderRadius: '0.25rem',
};

const language = ['English', 'Hindi', 'Both'];

function GeneralConsent() {
	const id = useSelector((state) => state.patient.patient)?.id;
	const dispatch = useDispatch();
	const nextRef = useRef(null);
	const canvasRef = useRef(null);
	const drCanvasRef = useRef(null);

	const [selectedDoctor, setSelectedDoctor] = useState(null);
	const [selectedConsent, setSelectedConsent] = useState(null);

	const [printingOrSaving, setPrintingOrSaving] = useState(false);
	const [printLanguage, setPrintLanguage] = useState(language[2]);

	//Patient Signature Variables
	const [displayHindiImage, setDisplayHindiImage] = useState(null);
	const [data, setData] = useState(null);
	const [showSign, setShowSign] = useState(false);
	const [signPad, setSignPad] = useState(false);
	//Doctor Signature Variables
	const [drData, setDrData] = useState(null);
	const [showDrSign, setShowDrSign] = useState(false);
	const [drSignPad, setDrSignPad] = useState(false);

	const selectedOrg = useSelector((state) => state.profile.selectedOrg);
	const pad = useSelector((state) => state.profile.prescriptionPad);
	const patient = useSelector((state) => state.patient.patient);
	const doctors = useSelector((state) => state.doctor.doctors);
	const consents = useSelector((state) => state?.setting?.consents) || [];

	const { vendorId } = useSelector((state) => state.auth.user.Vendor_detail);
	const doctor_id = vendorId;

	const handlePrint = async (pad = true) => {
		if (!selectedDoctor)
			return showNotification('Success', 'Please Select Doctor first', 'danger');
		if (!selectedConsent)
			return showNotification('Success', 'Please Select Consent first', 'danger');
		dispatch(toggleCreateLoading(true));
		const group = await drawDOM(nextRef.current, {
			paperSize: ['595.28pt', '841.89pt'],
		});
		const dataUri = await exportPDF(group);
		var win = window.open('about:blank');
		win.document.write(
			`<body style="margin: 0; padding: 0"><iframe src="${dataUri}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`,
		);
		setDisplayHindiImage(null);
		setPrintingOrSaving(false);
		dispatch(toggleCreateLoading(false));

		// setShowSign(false);
		// setDrSignPad(false);
	};

	const handleExport = (type) => {
		dispatch(toggleCreateLoading(true));
		setPrintingOrSaving(true);
		try {
			var node = document.getElementById('hindiContent');
			if (printLanguage == language[0]) {
				type == 'print' ? handlePrint() : handleSaveConsent();
			}
			htmlToImage
				.toPng(node)
				.then(function (dataUrl) {
					setDisplayHindiImage(dataUrl);
					setShowSign(true);
					setShowDrSign(true);
					type == 'print' ? handlePrint() : handleSaveConsent();
				})
				.catch(function (error) {
					console.error('oops, something went wrong!', error);
					setPrintingOrSaving(false);
				})
				.finally(() => {
					dispatch(toggleCreateLoading(false));
				});
		} catch (e) {
			showNotification('Error', 'Something went wrong', 'danger');
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

	const handleSaveAttachments = async (file, fileName) => {
		try {
			let data = new FormData();
			data.append('patient', id);
			data.append('doctor', doctor_id);
			data.append('date', moment().format('YYYY-MM-DD'));
			data.append('images', file);
			data.append('name', fileName);
			// Upload
			dispatch(uploadSoapAttachment(data));
			setDisplayHindiImage(null);
			setPrintingOrSaving(false);
		} catch (err) {
			console.log(err);
		}
	};

	const handleSaveConsent = async () => {
		dispatch(toggleCreateLoading(true));
		new Promise((resolve) => setTimeout(resolve, 1000))
			.then(async () => {
				const group = await drawDOM(nextRef.current, {
					paperSize: ['595.28pt', '841.89pt'],
				});
				var data = new FormData();
				const promise = new Promise((resolve, reject) =>
					toBlob(group, (blob) => resolve(blob)),
				);
				promise
					.then((value) => {
						const fileName = `${patient?.Members?.[0]?.name}-${moment().format(
							'DD/MM/YYYY',
						)}-${selectedDoctor?.label}`.replaceAll(' ', '_');
						var file = new File([value], `${fileName}.pdf`, {
							type: 'application/pdf',
						});
						handleSaveAttachments(file, fileName);
					})
					.catch((e) => {
						dispatch(toggleCreateLoading(false));
					});
			})
			.catch((e) => {
				dispatch(toggleCreateLoading(false));
			});
	};

	const renderSignData = () => {
		return (
			<div className='row'>
				<div className='col-6'>
					<div>
						<Button
							onClick={() => {
								setSignPad((prev) => !prev);
								setShowSign(false);
							}}>
							<em className='text-muted'>Patient Signature here</em>
						</Button>
						{showSign && <img src={data} className='img-fluid consent-sign' />}
						{signPad && !showSign && (
							<ReactSketchCanvas
								style={styles}
								onChange={(e) => {
									canvasRef.current.exportImage('png').then((data) => {
										setData(data);
									});
								}}
								width='100%'
								height='400'
								strokeWidth={4}
								strokeColor='black'
								ref={canvasRef}
							/>
						)}
					</div>
				</div>
				{/* drData showDrSign drSignPad */}
				<div className='col-6'>
					<div>
						<Button
							onClick={() => {
								setDrSignPad((prev) => !prev);
								setShowDrSign(false);
							}}>
							<em className='text-muted'>Doctor Signature here</em>
						</Button>
						{showDrSign && <img src={drData} className='img-fluid consent-sign' />}
						{drSignPad && !showDrSign && (
							<ReactSketchCanvas
								style={styles}
								onChange={(e) => {
									drCanvasRef.current.exportImage('png').then((data) => {
										setDrData(data);
									});
								}}
								width='100%'
								height='400'
								strokeWidth={4}
								strokeColor='black'
								ref={drCanvasRef}
							/>
						)}
					</div>
				</div>
			</div>
		);
	};

	const isEnglishConsentAvailable =
		selectedConsent?.value?.englishContent?.length > 0 &&
		selectedConsent?.value?.englishContent != '<p><br></p>';

	const isHindiConsentAvailable =
		selectedConsent?.value?.hindiContent?.length > 0 &&
		selectedConsent?.value?.hindiContent != '<p><br></p>';

	const renderEnglishContent = () => {
		return (
			isEnglishConsentAvailable && (
				<>
					<div className='line justify-content-center my-4 '>
						<>{selectedConsent?.label}</>
					</div>

					<div
						dangerouslySetInnerHTML={{
							__html: selectedConsent?.value?.englishContent?.replace?.(
								doctorRegex,
								`<b>
								<em>${selectedDoctor?.label || ''}</em>
							</b>`,
							),
						}}></div>
				</>
			)
		);
	};

	useEffect(() => {
		let mounted = true;

		const fetchOthers = async () => {
			if (mounted) {
				dispatch(getDoctors());
				dispatch(getPrescriptionPad());
				dispatch(getConsentForms());
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	const consentTitle = selectedConsent?.label
		? `<div style='display: flex; justify-content:center; margin-bottom:2rem'>${selectedConsent?.label}</div>`
		: '';
	const hindiContent = selectedConsent?.value?.hindiContent
		? selectedConsent.value.hindiContent.replace(
				doctorRegex,
				`<b>
					<em>${selectedDoctor?.label}</em>
				</b>`,
		  )
		: '';

	const combinedContent = `${consentTitle}${hindiContent}`;

	return (
		<>
			<div
				className='prescription'
				id='prescription'
				ref={nextRef}
				style={{
					width: '595.28px',
					height: '841.89px',
					padding: `${pad?.paddingTop + 'cm'} ${pad?.paddingRight + 'cm'} ${
						pad?.paddingBottom + 'cm'
					} ${pad?.paddingLeft + 'cm'}`,
					backgroundImage: `url(${staticUrl}/doctor-profile/${pad?.file})`,
					marginBottom: '20px',
				}}>
				<div style={{ marginTop: 20 }}>
					<div className='mb-3'>
						{(printLanguage == language[0] || printLanguage == language[2]) &&
							renderEnglishContent()}
						<br />
						{!!(printLanguage == language[2]) &&
							!!printingOrSaving &&
							isEnglishConsentAvailable &&
							renderSignData()}
						<br />
						{(printLanguage == language[1] || printLanguage == language[2]) &&
							selectedConsent?.value?.hindiContent?.length > 0 && (
								<>
									{displayHindiImage != null ? (
										<img
											src={displayHindiImage}
											className='mb-3'
											style={{ maxWidth: '550px', maxHeight: '830px' }}
										/>
									) : (
										<div
											id='hindiContent'
											className='mb-3'
											dangerouslySetInnerHTML={{
												__html: combinedContent,
											}}
										/>
									)}
								</>
							)}
					</div>

					<div className='row mb-2'>
						<div className='col-6'>
							<div>
								<strong>{`Patient Name:`}</strong> {patient?.Members?.[0]?.name}
							</div>
						</div>
						<div className='col-6' style={{ textAlign: 'right' }}>
							<strong>Date:</strong>{' '}
							{moment(patient?.Members?.[0]?.createdAt).format('DD-MM-YYYY')}
						</div>
					</div>
					{renderSignData()}
				</div>

				<div className='d-flex consents-footer pdf-footer'>
					<p className='mb-0 footer-text'>Not Valid for Medico Legal Purposes</p>
					<p className='mb-0 ms-auto'>Powered by docplix.com</p>
				</div>
				<div className='mb-3'></div>
			</div>
			{/* Before Buttons */}
			<div
				className='buttons position-absolute top-0 end-0 m-2'
				style={{ display: 'flex', width: '700px	' }}>
				<ReactSelect
					id='vendorId'
					menuPlacement='top'
					className='form-control'
					styles={{
						control: () => ({
							position: 'relative',
							display: 'flex',
							// height: '1.5rem',
						}),
					}}
					placeholder='Select Doctor'
					components={{
						IndicatorSeparator: () => null,
					}}
					value={selectedDoctor}
					options={doctors?.map((i) => ({
						value: i,
						label: `Dr. ${i.Vendor_detail.name}`,
					}))}
					onChange={(i) => {
						setSelectedDoctor(i);
						setDisplayHindiImage(null);
					}}
				/>
				&emsp;
				<ReactSelect
					id='vendorId'
					className='form-control'
					menuPlacement='top'
					styles={{
						control: () => ({
							position: 'relative',
							display: 'flex',
							// height: '1.5rem',
						}),
					}}
					placeholder='Select Consent'
					components={{
						IndicatorSeparator: () => null,
					}}
					value={selectedConsent}
					options={consents?.map((i) => ({
						value: i,
						label: i?.name,
					}))}
					onChange={(i) => {
						setSelectedConsent(i);
						setDisplayHindiImage(null);
					}}
				/>
				&emsp;
				<ReactSelect
					id='vendorId'
					className='form-control'
					menuPlacement='top'
					styles={{
						control: () => ({
							position: 'relative',
							display: 'flex',
							// height: '1.5rem',
						}),
					}}
					placeholder='Language'
					components={{
						IndicatorSeparator: () => null,
					}}
					value={{ label: printLanguage, value: printLanguage }}
					options={language?.map((i) => ({
						value: i,
						label: i,
					}))}
					onChange={(i) => {
						setPrintLanguage(i.value);
					}}
				/>
				&emsp;
				<div className='consent-btn'>
					<Action title='Save' icon='save' onClickFunction={() => handleExport('save')} />
					&emsp;
					<Action
						title='Print'
						icon='print'
						onClickFunction={() => handleExport('print')}
					/>
				</div>
				&emsp;
			</div>
		</>
	);
}

export default GeneralConsent;
