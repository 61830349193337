import {
	SET_ASSESSMENT_SCHEDULE,
	SET_CLASS_SCHEDULE,
	SET_DIETCHART,
	SET_GENERAL_TASK_SCHEDULE,
	SET_PCP_ELEMENTS,
	SET_PCP_PROGRAMS,
	SET_PCP_PROGRAM_DETAILS,
	SET_PCP_SCHEDULES,
	SET_PCP_SUBSCRIBER_DATA,
	SET_VITALS_DATA,
	SET_VITALS_SCHEDULE,
	SET_VITAL_FREQ,
	SET_CLASSSTATUS_LIST,
	SET_CLASS_ATTENDANCE,
	SET_PCP_CLASSES,
	SET_SPECIFIC_VITALS_DATA,
} from './types';

const initialState = {
	programsList: [],
	specificVital: {},
	classList: [],
	programDetails: null,
	scheduleList: [],
	classSchedules: [],
	assessmentSchedules: [],
	generalTaskSchedules: [],
	vitalsSchedules: [],
	elementsList: [],
	vitalsData: [],
	dietChartData: [],
	vitalsFrequency: [],
	classStatusList: [],
	classAttendance: [],
	subscriberData: null,
	loading: false,
};

const pcpReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PCP_PROGRAMS:
			return {
				...state,
				programsList: action.payload || [],
				loading: false,
			};

		case SET_PCP_CLASSES:
			return {
				...state,
				classList: action.payload || [],
				loading: false,
			};

		case SET_PCP_PROGRAM_DETAILS:
			return {
				...state,
				programDetails: action.payload || null,
				loading: false,
			};

		case SET_PCP_SCHEDULES:
			return {
				...state,
				scheduleList: action.payload || [],
				loading: false,
			};

		case SET_PCP_ELEMENTS:
			return {
				...state,
				elementsList: action.payload || [],
				loading: false,
			};

		case SET_CLASS_SCHEDULE:
			return {
				...state,
				classSchedules: action.payload || [],
				loading: false,
			};

		case SET_ASSESSMENT_SCHEDULE:
			return {
				...state,
				assessmentSchedules: action.payload || [],
				loading: false,
			};

		case SET_GENERAL_TASK_SCHEDULE:
			return {
				...state,
				generalTaskSchedules: action.payload || [],
				loading: false,
			};

		case SET_VITALS_SCHEDULE:
			return {
				...state,
				vitalsSchedules: action.payload || [],
				loading: false,
			};

		case SET_PCP_SUBSCRIBER_DATA:
			return {
				...state,
				subscriberData: action.payload || null,
				loading: false,
			};

		case SET_VITALS_DATA:
			return {
				...state,
				vitalsData: action.payload || [],
				loading: false,
			};

		case SET_DIETCHART:
			return {
				...state,
				dietChartData: action.payload || [],
				loading: false,
			};
		case SET_VITAL_FREQ:
			return {
				...state,
				vitalsFrequency: action.payload || [],
			};
		case SET_CLASSSTATUS_LIST:
			return {
				...state,
				classStatusList: action.payload || [],
			};
		case SET_CLASS_ATTENDANCE:
			return {
				...state,
				classAttendance: action.payload || [],
			};
		case SET_SPECIFIC_VITALS_DATA:
			return {
				...state,
				specificVital: action.payload || {},
			};

		default:
			return state;
	}
};

export default pcpReducer;
