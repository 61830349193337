import { SET_EXAMS_INFO,SET_SUB_EXAM_CHARAC,SET_EXAMS_REMARKS,SET_PATIENT_EXAMS,SET_STATUS_VALUES,SET_ATTACHMENT_RECORD,SET_ATTACH_REMARKS } from './types';

const initialState = {
	examsData : [],
	attachmentRecord : [],
	attachRemarks : [],
	statusValues : [],
	subExamCharac : [],
	examRemarks : [],
	patientExams : [],
};

const examReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_EXAMS_INFO:
            return {
                ...state,   
                examsData : action.payload || [],
            }
		case SET_STATUS_VALUES : 
			// console.log(action.payload);
			return {
				...state,
				statusValues : action.payload || [],
			}
		case SET_SUB_EXAM_CHARAC:
			return {
				...state,
				examCharac : action.payload || [],
			}
		case SET_EXAMS_REMARKS:
			return {
				...state,
				examRemarks : action.payload || [],
			}
		case SET_PATIENT_EXAMS :
			return {
				...state,
				patientExams : action.payload || [],
			}
		case SET_ATTACHMENT_RECORD :
			return {
				...state,
				attachmentRecord : action.payload || [],
			}
		case SET_ATTACH_REMARKS : 
			return {
				...state,
				attachRemarks : action.payload || [],
			}
		default:
			return state;
	}
};

export default examReducer;
