import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';

import Modal, {
	ModalBody,
	ModalHeader,
	ModalFooter,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
	CardActions,
} from '../../../components/bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';
import {
	createPatientImmun,
	getPatientImmun,
	updatePatientImmun,
	changeImmunRemark,
	getImmunRemarks,
} from '../../../actions/immunActions';
import { isPermitted } from '../../../actions/helperActions';
import Tooltips from '../../../components/bootstrap/Tooltips';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/bootstrap/Alert';
import { SET_PATIENT_IMMUN } from '../../../reducers/types';

const PatientImmunisation = ({ patient, userId, scrollId }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [remarks, setRemarks] = useState([]);

	const patientImmun = useSelector((state) => state.immun?.patientImmun);
	console.log(patientImmun);
	const formik = useFormik({
		initialValues: {
			chart: patientImmun?.[0]?.immunisation_chartvalues || [],
		},
		enableReinitialize: true,
	});

	const [dobModal, setDobModal] = useState(false);

	const { darkModeStatus } = useDarkMode();

	const handlePrint = () => {
		navigate(`/patients/${userId}/immunisation-report`, {
			state: {
				patient,
			},
		});
	};

	const handleAddImmun = () => {
		dispatch(createPatientImmun(userId));
	};

	useEffect(() => {
		let mounted = true;

		const fetchOthers = async () => {
			if (mounted) {
				isPermitted(
					'Medical profile',
					'read',
					() => {
						dispatch(getPatientImmun(parseInt(userId)));
					},
					null,
					false,
				);
				setRemarks(await getImmunRemarks());
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
			// dispatch({
			// 	type: SET_PATIENT_IMMUN,
			// 	payload: [],
			// });
		};
	}, []);

	return (
		<>
			<Card shadow='none'>
				<CardHeader>
					<CardLabel icon='ContentPaste'>
						<CardTitle>Patient Immunisation</CardTitle>
					</CardLabel>
					<CardActions>
						<div id={scrollId}></div>
						{formik.values.chart?.length > 0 && patient?.Members[0]?.dob && (
							<Tooltips title='Print'>
								<Button
									isOutline={!darkModeStatus}
									color='primary'
									isLight={darkModeStatus}
									className={classNames('text-nowrap', {
										'border-light': !darkModeStatus,
									})}
									icon='Print'
									onClick={handlePrint}
								/>
							</Tooltips>
						)}
						{patient?.Members?.[0]?.dob && patientImmun?.length == 0 && (
							<Button
								isOutline={!darkModeStatus}
								color='primary'
								icon='Add'
								isLight={darkModeStatus}
								className={classNames('text-nowrap', {
									'border-light': !darkModeStatus,
								})}
								onClick={() =>
									isPermitted('Medical Profile', 'write', handleAddImmun)
								}>
								Add
							</Button>
						)}
					</CardActions>
				</CardHeader>
				<CardBody>
					<div className='row'>
						<div className='col-12 table-responsive'>
							{patientImmun?.length > 0 && (
								<table className='table table-bordered'>
									<tbody>
										<tr>
											<th style={{ maxWidth: '80px' }}>Vaccine</th>
											<th style={{ maxWidth: '80px' }}>When</th>
											<th style={{ maxWidth: '80px' }}>Dose</th>
											<th style={{ maxWidth: '80px' }}>Route</th>
											<th style={{ maxWidth: '80px' }}>Site</th>
											<th style={{ maxWidth: '80px' }}>Date</th>
											{/* <th>Type</th> */}
											<th>Remarks</th>
										</tr>
										{formik.values.chart.map((item, idx) => (
											<tr key={idx}>
												<td style={{ maxWidth: '80px' }}>
													{item?.vaccine}
												</td>
												<td>
													{moment(patient?.Members[0]?.dob)
														.add(parseInt(item?.when), 'days')
														.format('DD-MM-YYYY')}
												</td>
												<td style={{ maxWidth: '80px' }}>{item?.dose}</td>
												<td style={{ maxWidth: '80px' }}>{item?.route}</td>
												<td style={{ maxWidth: '80px' }}>{item?.site}</td>
												<td style={{ maxWidth: '80px' }} className='p-0'>
													<div className='row'>
														<FormGroup>
															<Input
																id={`chart.${idx}.date`}
																style={{
																	height: 'calc(3.5rem + 2px)',
																	fontWeight: 'normal',
																	padding: '0.60rem',
																	borderRadius: '0.3rem',
																	boxShadow: 'none',
																}}
																type='date'
																placeholder='Date'
																value={
																	formik.values.chart?.[idx]?.date
																}
																onChange={formik.handleChange}
																onBlur={(e) =>
																	isPermitted(
																		'Medical Profile',
																		'write',
																		() =>
																			dispatch(
																				updatePatientImmun(
																					e.target?.value,
																					'',
																					item?.id,
																					parseInt(
																						userId,
																					),
																				),
																			),
																	)
																}
															/>
														</FormGroup>
													</div>
												</td>
												<td style={{ maxWidth: '80px' }} className='p-0'>
													<FormGroup>
														<Input
															id={`chart.${idx}.immunisation_immunisationremark.name`}
															style={{
																height: 'calc(3.5rem + 2px)',
																fontWeight: 'normal',
																padding: '0.60rem',
																borderRadius: '0.3rem',
																boxShadow: 'none',
															}}
															onChange={formik.handleChange}
															value={
																formik.values.chart?.[idx]
																	?.immunisation_immunisationremark
																	?.name || ''
															}
															onBlur={(e) =>
																isPermitted(
																	'Medical Profile',
																	'write',
																	() =>
																		dispatch(
																			changeImmunRemark(
																				e.target?.value,
																				item?.id,
																				parseInt(userId),
																			),
																		),
																)
															}
														/>
													</FormGroup>
												</td>
											</tr>
										))}
									</tbody>
								</table>
							)}
							{patientImmun?.length == 0 && !patient?.Members[0]?.dob && (
								<em>Please Enter Date of Birth of the patient</em>
							)}
							{patientImmun?.length == 0 && patient?.Members[0]?.dob && (
								<em>Please Add Patient Immunisation</em>
							)}
						</div>
					</div>
				</CardBody>
			</Card>
			<Modal
				isOpen={dobModal}
				setIsOpen={setDobModal}
				titleId={'saveReport'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDobModal}>
					<ModalTitle id='saveReportLabel'>Alert</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-12'>
							Please Update the Date of Birth of the Patient!
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDobModal(false)}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default PatientImmunisation;
