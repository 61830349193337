import {
	SET_ALL_CHIEF_COMPLAINTS,
	SET_CHIEF_COMPLAINTS_VALUES,
	SET_HOPI,
	SET_HOPI_REMARKS,
	SET_HOPI_VALUES,
	SET_SYMPTOMS_DATA,
	SET_PATIENT_SYMPTOMS,
	SET_CHRONIC_DATA,
	SET_SIMILAR_CONDITIONS,
	SET_PRE_HOSPITAL,
	SET_PRE_SURGICAL,
	SET_PRE_BLOOD,
	SET_BLOOD_UNITS,
	SET_PRE_HOSPITAL_REMARKS,
	SET_PRE_HOSPITAL_REASON,
	SET_PRE_SURGICAL_REMARKS,
	SET_PRE_SURGICAL_REASON,
	SET_FAMILY_CHRONIC,
	SET_FAMILY_SIMILAR,
	SET_FAMILY_OTHER,
	SET_WHOLE_FAMILY_HISTORY,
	SET_SOCIAL_HISTORY,
	SET_GYNECOLOGY_HISTORY,
	SET_FOODS,
	SET_DRUG_HISTORY,
	SET_WHOLE_PATIENT_HISTORY,
	SET_CHIEF_COMPLAINTS_REMARKS,
	SET_SYMPTOMS_REMARKS,
	SET_FH_CHRONIC,
	SET_FH_SIMILAR,
	SET_HOW_DIAGNOSED,
	SET_WHERE,
	SET_FH_SM_WHERE,
	SET_FH_SM_HOW,
	SET_ILL_REMARKS,
	SET_SPECIAL_VALUE,
	SET_TRAVEL_REMARKS,
	SET_TRAVEL_WHERE,
	SET_GYNE_REMARKS,
	SET_DELIVERY_REMARKS,
	SET_DELIVERY_MODES,
	SET_PH_CHRONIC_REMARKS,
	SET_PH_SIM_REMARKS,
	SET_PH_CHRONIC_HOW,
	SET_PH_CHRONIC_WHERE,
	SET_PH_SIM_WHERE,
	SET_PH_SIM_HOW,
	SET_PH_PRE_HOSPITALS,
	SET_PH_SURGICAL_HOSPITAL,
	SET_PH_SUR_SURGERY,
	SET_BLOOD_CENTERS,
	SET_BLOOD_REMARKS,
	SET_MANAGE_HISTORY,
	SET_HOUSING,
	SET_ANIMAL,
	SET_SOCIAL,
	SET_SUB_SYMPTOM_CHARAC,
	SET_HABITS_VALUES,
	SET_DISEASES,
	SET_MEDICATIONS,
	SET_HISTORY_STATUS,
	SET_HISTORY_LOADING,
	SET_PAST_HISTORY,
	SET_ROS_TEMPLATES,
} from './types';

const initialState = {
	patientHistory: {},

	statusValues: [],
	socialHistory: [],
	familyHistory: [],
	pastHistory: [],

	chiefComplains: [],
	chiefComplainsValues: [],
	chiefComplainsRemarks: [],
	editChiefComplainsValue: null,

	HOPI: [],
	HOPIValues: [],
	HOPIRemarks: [],

	symptomsData: [],
	symptomsRemarks: [],
	patientSymptoms: [],
	symptomCharac: [],

	chronicDisease: [],
	phChronicRemarks: [],
	phChronicWhere: [],
	phChronicHow: [],

	phSimilarRemarks: [],
	phSimilarWhere: [],
	phSimilarHow: [],
	similarConditions: [],

	preHospital: [],
	phPreHosipitals: [],
	preHospitalRemarks: [],
	preHospitalReason: [],

	preSurgical: [],
	preSurgicalRemarks: [],
	preSurgicalReason: [],

	phSurHospital: [],
	phSurSurgery: [],

	preBlood: [],
	bloodRemarks: [],
	bloodCenters: [],
	bloodUnits: [],

	diseases: [],

	chWhere: [],
	chHowDiagnosed: [],
	simWhere: [],
	simHowDiagnosed: [],
	fhChronicRemark: [],
	fhSimilarRemark: [],
	familyChronic: [],
	familySimilar: [],
	familyOther: [],

	housing: [],
	animal: [],
	social: [],
	IllRemarks: [],
	habitsValues: [],
	IllValues: [],
	travelRemarks: [],
	travelWhere: [],

	drugHistory: [],
	medications: [],
	rosTemplates: [],

	gyneHistory: [],
	deliveryModes: [],
	gyneRemarks: [],
	deliveryRemarks: [],

	error: null,
	loading: false,
};

const historyReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_HISTORY_LOADING:
			return {
				...state,
				loading: true,
			};
		case SET_DISEASES:
			return {
				...state,
				diseases: action.payload || [],
			};
		case SET_HISTORY_STATUS:
			// console.log('SET_HISTORY_STATUS', action.payload);
			return {
				...state,
				statusValues: action.payload || [],
			};
		case SET_WHOLE_PATIENT_HISTORY:
			// console.log(action.payload)
			return {
				...state,
				patientHistory: action.payload,
			};
		case SET_MANAGE_HISTORY:
			return {
				...state,
				manageHistory: action.payload,
			};
		case SET_ALL_CHIEF_COMPLAINTS:
			return {
				...state,
				chiefComplains: action.payload || [],
			};
		case SET_CHIEF_COMPLAINTS_REMARKS:
			return {
				...state,
				chiefComplainsRemarks: action.payload || [],
			};
		case SET_CHIEF_COMPLAINTS_VALUES:
			return {
				...state,
				chiefComplainsValues: action.payload || [],
				loading: false,
			};
		case SET_HOPI:
			return {
				...state,
				HOPI: action.payload || [],
			};
		case SET_HOPI_REMARKS:
			return {
				...state,
				HOPIRemarks: action.payload || [],
			};
		case SET_HOPI_VALUES:
			return {
				...state,
				HOPIValues: action.payload || [],
			};
		case SET_SYMPTOMS_DATA:
			return {
				...state,
				symptomsData: action.payload || [],
				loading: false,
			};
		case SET_PATIENT_SYMPTOMS:
			return {
				...state,
				patientSymptoms: action.payload || [],
			};
		case SET_SYMPTOMS_REMARKS:
			return {
				...state,
				symptomsRemarks: action.payload || [],
			};
		case SET_SUB_SYMPTOM_CHARAC:
			return {
				...state,
				symptomCharac: action.payload || [],
			};
		case SET_WHERE:
			return {
				...state,
				chWhere: action.payload || [],
			};
		case SET_HOW_DIAGNOSED:
			return {
				...state,
				chHowDiagnosed: action.payload || [],
			};
		case SET_CHRONIC_DATA:
			return {
				...state,
				chronicDisease: action.payload || [],
			};
		case SET_SIMILAR_CONDITIONS:
			return {
				...state,
				similarConditions: action.payload || [],
			};
		case SET_PH_CHRONIC_REMARKS:
			return {
				...state,
				phChronicRemarks: action.payload || [],
			};
		case SET_PH_CHRONIC_WHERE:
			return {
				...state,
				phChronicWhere: action.payload || [],
			};
		case SET_PH_CHRONIC_HOW:
			return {
				...state,
				phChronicHow: action.payload || [],
			};
		case SET_PH_SIM_WHERE:
			return {
				...state,
				phSimilarWhere: action.payload || [],
			};
		case SET_PH_SIM_HOW:
			return {
				...state,
				phSimilarHow: action.payload || [],
			};
		case SET_PH_SIM_REMARKS:
			return {
				...state,
				phSimilarRemarks: action.payload || [],
			};
		case SET_PH_PRE_HOSPITALS:
			return {
				...state,
				phPreHosipitals: action.payload || [],
			};
		case SET_PRE_HOSPITAL:
			return {
				...state,
				preHospital: action.payload || [],
			};
		case SET_PRE_SURGICAL:
			return {
				...state,
				preSurgical: action.payload || [],
			};

		case SET_PH_SURGICAL_HOSPITAL:
			return {
				...state,
				phSurHospital: action.payload || [],
			};
		case SET_PH_SUR_SURGERY:
			return {
				...state,
				phSurSurgery: action.payload || [],
			};
		case SET_PRE_BLOOD:
			return {
				...state,
				preBlood: action.payload || [],
			};
		case SET_BLOOD_UNITS:
			return {
				...state,
				bloodUnits: action.payload || [],
			};
		case SET_BLOOD_REMARKS:
			return {
				...state,
				bloodRemarks: action.payload || [],
			};
		case SET_BLOOD_CENTERS:
			return {
				...state,
				bloodCenters: action.payload || [],
			};
		case SET_PRE_HOSPITAL_REMARKS:
			return {
				...state,
				preHospitalRemarks: action.payload || [],
			};
		case SET_PRE_HOSPITAL_REASON:
			return {
				...state,
				preHospitalReason: action.payload || [],
			};
		case SET_PRE_SURGICAL_REMARKS:
			return {
				...state,
				preSurgicalRemarks: action.payload || [],
			};
		case SET_PRE_SURGICAL_REASON:
			return {
				...state,
				preSurgicalReason: action.payload || [],
			};
		case SET_WHOLE_FAMILY_HISTORY:
			return {
				...state,
				familyHistory: action.payload || [],
			};
		case SET_FH_SM_WHERE: {
			return {
				...state,
				simWhere: action.payload || [],
			};
		}
		case SET_FH_SM_HOW: {
			return {
				...state,
				simHowDiagnosed: action.payload || [],
			};
		}
		case SET_FH_CHRONIC:
			return {
				...state,
				fhChronicRemark: action.payload || [],
			};
		case SET_FH_SIMILAR:
			return {
				...state,
				fhSimilarRemark: action.payload || [],
			};
		case SET_FAMILY_CHRONIC:
			return {
				...state,
				familyChronic: action.payload || [],
			};
		case SET_FAMILY_SIMILAR:
			return {
				...state,
				familySimilar: action.payload || [],
			};
		case SET_FAMILY_OTHER:
			return {
				...state,
				familyOther: action.payload || [],
			};
		case SET_HABITS_VALUES:
			return {
				...state,
				habitsValues: action.payload || [],
			};
		case SET_ILL_REMARKS:
			return {
				...state,
				IllRemarks: action.payload || [],
			};
		case SET_SPECIAL_VALUE:
			return {
				...state,
				IllValues: action.payload || [],
			};
		case SET_TRAVEL_REMARKS:
			return {
				...state,
				travelRemarks: action.payload || [],
			};
		case SET_TRAVEL_WHERE:
			return {
				...state,
				travelWhere: action.payload || [],
			};
		case SET_SOCIAL_HISTORY:
			return {
				...state,
				socialHistory: action.payload || [],
			};
		case SET_GYNECOLOGY_HISTORY:
			return {
				...state,
				gyneHistory: action.payload || [],
			};
		case SET_HOUSING:
			return {
				...state,
				housing: action.payload || [],
			};
		case SET_ANIMAL:
			return {
				...state,
				animal: action.payload || [],
			};
		case SET_SOCIAL:
			return {
				...state,
				social: action.payload || [],
			};
		case SET_FOODS:
			return {
				...state,
				foods: action.payload || [],
			};
		case SET_DRUG_HISTORY:
			return {
				...state,
				drugHistory: action.payload || [],
			};
		case SET_MEDICATIONS:
			return {
				...state,
				medications: action.payload || [],
			};
		case SET_DELIVERY_REMARKS:
			return {
				...state,
				deliveryRemarks: action.payload || [],
			};
		case SET_GYNE_REMARKS:
			return {
				...state,
				gyneRemarks: action.payload || [],
			};
		case SET_DELIVERY_MODES:
			return {
				...state,
				deliveryModes: action.payload || [],
			};
		case SET_PAST_HISTORY:
			return {
				...state,
				pastHistory: action.payload || [],
			};
		case SET_ROS_TEMPLATES:
			return {
				...state,
				rosTemplates: action.payload || [],
			};
		default:
			return state;
	}
};

export default historyReducer;
