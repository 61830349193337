import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import Draggable from 'react-draggable';

import Card, { CardBody } from '../../components/bootstrap/Card';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';
import Bots from '../Bots';
import Loader from '../../components/Loader';

const Content = () => {
	const { loggedIn } = useSelector((state) => state.auth);
	const loading = useSelector((state) => state.component.createLoading);

	// const [isDragging, setIsDragging] = useState(false);
	// const eventControl = (event, info) => {
	// 	if (event.type === 'mousemove' || event.type === 'touchmove') {
	// 		setIsDragging(true);
	// 	}

	// 	if (event.type === 'mouseup' || event.type === 'touchend') {
	// 		setTimeout(() => {
	// 			setIsDragging(false);
	// 		}, 100);
	// 	}
	// };

	return (
		<main className='content position-relative'>
			<Suspense fallback={<Loader />}>
				<ContentRoutes />
			</Suspense>
			{loading && <Loader />}
			{loggedIn && (
				// <Draggable
				// 	defaultPosition={{ x: -54, y: 702 }}
				// 	onDrag={eventControl}
				// 	onStop={eventControl}>
				// 	<div style={{ zIndex: 99999, maxWidth: '100px', maxHeight: '100px' }}>
				// 		{isDragging ? (
				// 			<div
				// 				style={{
				// 					width: '80px',
				// 					height: '80px',
				// 				}}>
				// 				<Bots />
				// 			</div>
				// 		) : (
				<>{/* <Bots /> */}</>
				// 			)}
				// 		</div>
				// 	</Draggable>
			)}
		</main>
	);
};

export default Content;
