import React,{useEffect,useState} from 'react';
import moment from 'moment';
import useDarkMode from '../../../hooks/useDarkMode';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../../components/bootstrap/Alert';
import { getPatientShareReports } from '../../../actions/patientActions';

const PatientSharedReports = ({id,viewAdd,setViewAdd}) => {
	// const { id } = useParams()
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const { soapAttachments,soapAttachRemark } = useSelector((state) => state.soap);
	const { vendorId } = useSelector((state) => state.auth.user.Vendor_detail);
	const doctor_id = vendorId;

	const shareReports = useSelector((state) => state.patient.shareReports);
	const shareURL = useSelector((state) => state.patient.shareURL);

	const [ attachments,setAttachments ] = useState([]);
	const [ attachDate,setAttachDate] = useState(moment().format('YYYY-MM-DD'));
	const [ attachRemark,setAttachRemark ] = useState({});

  const handleAddAttachImage = (e) => {
		if (!e.target.files) {
			return;
		}
		const img = {
			file : e.target.files[0],
			blob : URL.createObjectURL(e.target.files[0])
		};
		setAttachments((prev)=>[...prev,img]);
		e.target.value = null;	
	};

  useEffect(() => {
		let mounted = true;
		const fetchData = async () => {
			if (mounted) {
				dispatch(getPatientShareReports(id))
			}
		};
		fetchData();
		return () => {
			mounted = false;
		};
	}, []);

  return (
    <>
		
		<div className="row">
			<div className="col-12">
				{shareReports?.length > 0 ? (
					<table className='table table-modern me-2 pb-3'
					style={{
						minWidth: '40vw',
					}}>
						<thead>
							<tr>
								<th>Date</th>
								<th>File </th>
							</tr>
						</thead>
						<tbody>
							{shareReports?.map((i) => (
								<tr>
									<td>{moment(i?.createdAt).format('DD-MM-YYYY')}</td>
									<td>
										<a href={`${shareURL}${i?.filename}`} target="_blank">{i?.filename}</a>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					<div className='col-6'>
						<Alert>
							No shared reports found!
						</Alert>
					</div>
				)}
			</div>
		</div>
    </>
  )
}

export default PatientSharedReports