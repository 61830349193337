import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';

import Card, {
	CardActions,
	CardBody,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import moment from 'moment';
import { invalidNums, noteTypes } from '../../../../helpers/helpers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { useNavigate } from 'react-router-dom';
import {
	createIPDTPA,
	getIPDTPA,
	deleteIPDTPA,
	getRefunds,
	deleteRefund,
	createRefund,
} from '../../../../actions/ipdPatientActions';
import { isPermitted } from '../../../../actions/helperActions';
import { createNote, getNotes, getNotesByType } from '../../../../actions/settingActions';

const IpdRefund = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const notes = useSelector((state) => state.setting.notes);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);
	const refunds = useSelector((state) => state.ipdPatient.refunds);

	const [addRefundModal, setAddRefundModal] = useState(false);

	const formik = useFormik({
		initialValues: {
			ipdId,
			amount: 0,
			remarks: [],
			date: moment().format('YYYY-MM-DD'),
		},
		onSubmit: async (values, { resetForm }) => {
			const data = { ...values };
			data['remarks'] = values.remarks.map((x) => x.value);
			dispatch(createRefund(data));
			resetForm();
			setAddRefundModal(false);
		},
	});

	useEffect(() => {
		let mounted = true;
		const fetchOthers = async () => {
			if (mounted) {
				// dispatch(getRefunds(ipdId));
				dispatch(getNotesByType(noteTypes.NOTE_TYPE_IPD_REFUND_PAYMENT));
			}
		};
		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card shadow='none'>
				<div className='d-flex justify-content-between px-4'>
					<CardLabel icon='Payments' className='ms-2' iconColor='primary'>
						<CardTitle>Refunds</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							onClick={() =>
								isPermitted(
									'Ipd',
									'write',
									() => setAddRefundModal(true),
									'Billing',
								)
							}
							icon='Add'
							color='primary'
							isOutline>
							Add Refund
						</Button>
					</CardActions>
				</div>
				<CardBody>
					<table className='table table-bordered'>
						<tbody>
							<tr>
								<th>S.No.</th>
								<th>Date</th>
								<th>Amount</th>
								<th>Remark</th>
								<th>Actions</th>
							</tr>
							{refunds?.map((i, idx) => (
								<tr>
									<td>{idx + 1}</td>
									<td>{moment(i.date).format('DD MMM YYYY')}</td>
									<td>&#8377; {i.amount || '0'}</td>
									<td>
										{i.Ipd_bill_refund_remarks?.map((x) => x.remark)?.join(
											', ',
										) || '-'}
									</td>
									<td>
										<Tooltips title='Delete Refund'>
											<Button
												isLink
												color='danger'
												icon='Delete'
												onClick={() => {
													isPermitted(
														'Ipd',
														'delete',
														() => {
															dispatch(deleteRefund(i.id, ipdId));
														},
														'Billing',
													);
												}}
											/>
										</Tooltips>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>

			{/* Add Modal */}
			<Modal
				isOpen={addRefundModal}
				// setIsOpen={setAddRefundModal}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setAddRefundModal}>
					<ModalTitle id='deletePharmLabel'>Add Refund</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='date' label='Date' isFloating className='mb-2'>
						<Input
							type='date'
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Date'
							value={formik.values.date}
							onChange={formik.handleChange}
						/>
					</FormGroup>
					<FormGroup id='amount' label='Amount' isFloating className='mb-2'>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Amount'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.amount}
							isValid={formik.isValid}
							isTouched={formik.touched.amount}
							invalidFeedback={formik.errors.amount}
						/>
					</FormGroup>
					<FormGroup id={'remarks'} label={'Remarks'}>
						<Creatable
							isMulti
							className='form-control'
							components={{
								IndicatorSeparator: () => null,
							}}
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
									alignItems: 'center',
								}),
							}}
							value={formik.values.remarks}
							options={notes?.map((x) => ({
								label: x.name,
								value: x.name,
							}))}
							onCreateOption={(i) => {
								createNote({
									name: i,
									type: noteTypes.NOTE_TYPE_IPD_REFUND_PAYMENT,
									text: i,
								});
								formik.setFieldValue('remarks', [
									...formik.values.remarks,
									{
										value: i,
										label: i,
									},
								]);
							}}
							onChange={(i) => {
								formik.setFieldValue('remarks', i);
							}}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setAddRefundModal(false)}>
						Cancel
					</Button>
					<Button color='primary' onClick={formik.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default IpdRefund;
