import {
	SET_PURCHASE_VOUCHERS,
	SET_CURRENT_PURCHASE_VOUCHER,
	LOADING_PURCHASE_VOUCHERS,
	CREATE_PURCHASE_VOUCHER,
	UPDATE_PURCHASE_VOUCHER,
	DELETE_PURCHASE_VOUCHER,
} from './types';

//this is the initial state of the reducer

const initialState = {
	purchaseVouchers: [],
	currentPurchaseVoucher: null,
	page: 1,
	total: 0,
	error: null,
	loading: false,
};

const purchaseVoucherReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PURCHASE_VOUCHERS:
			return {
				...state,
				loading: true,
			};

		case SET_PURCHASE_VOUCHERS:
			return {
				...state,
				purchaseVouchers: action.payload.vouchers || [],
				page: action.payload.page || 1,
				total: action.payload.total || 0,
				loading: false,
			};

		case SET_CURRENT_PURCHASE_VOUCHER:
			return {
				...state,
				currentPurchaseVoucher: action.payload,
				loading: false,
			};

		case CREATE_PURCHASE_VOUCHER:
			return {
				...state,
				purchaseVouchers: [action.payload, ...state.purchaseVouchers],
			};

		case UPDATE_PURCHASE_VOUCHER:
			state.purchaseVouchers = state.purchaseVouchers.filter(
				(o) => o.id != action.payload.id,
			);
			return {
				purchaseVouchers: [action.payload, ...state.purchaseVouchers],
				...state,
			};

		case DELETE_PURCHASE_VOUCHER:
			state.purchaseVouchers = state.purchaseVouchers.filter((ig) => ig.id != action.payload);
			return {
				...state,
				total: state.total - 1,
			};

		default:
			return state;
	}
};

export default purchaseVoucherReducer;
