import {
	SET_AGE_GROUP_WISE_DATA,
	SET_AGE_WISE_DATA,
	SET_CITY_LEVEL_WISE_DATA,
	SET_CITY_WISE_DATA,
	SET_GENDER_WISE_DATA,
	SET_MIS_OVERVIEW,
} from '../types/mis/patient-mis';

const initialState = {
	overview: {},
	ageData: {},
	cityData: [],
	genderData: {},
	ageGroupData: {},
	cityWiseData: {},
	error: null,
	loading: false,
};

const patientMisReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_MIS_OVERVIEW:
			return {
				...state,
				overview: action.payload,
			};
		case SET_AGE_WISE_DATA:
			return {
				...state,
				ageData: action.payload,
			};
		case SET_CITY_WISE_DATA:
			return {
				...state,
				cityData: action.payload,
			};
		case SET_GENDER_WISE_DATA:
			return {
				...state,
				genderData: action.payload,
			};
		case SET_AGE_GROUP_WISE_DATA:
			return {
				...state,
				ageGroupData: {
					data: action.payload.patients,
					page: Number(action.payload.pageNo),
					total: action.payload.count,
					summary: action.payload.totalPatients,
				},
			};
		case SET_CITY_LEVEL_WISE_DATA:
			return {
				...state,
				cityWiseData: {
					data: action.payload.patients,
					page: action.payload.page,
					total: action.payload.count,
					summary: action.payload.totalPatients,
				},
			};
		default:
			return state;
	}
};

export default patientMisReducer;
