import React from 'react';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from 'react-select';
import Button from '../../../../components/bootstrap/Button';

function PregnancyHistory({ formik }) {
	return (
		<div className='row mb-3'>
			<div className='col-10 mb-2 text-center'>
				<strong>Pregnancy History</strong>
			</div>
			<div className='col-2 mb-2'>
				<Button
					isOutline
					color='primary'
					icon='add'
					onClick={(e) =>
						formik.setFieldValue('pregnancyHistory', [
							...formik.values.pregnancyHistory,
							{
								pregnancyNo: '',
								duration: '',
								complications: '',
								mode: '',
								gender: '',
								extraNotes: '',
							},
						])
					}>
					Add Row
				</Button>
			</div>
			<table className='table table-bordered'>
				<tbody>
					<tr style={{ display: 'flex' }}>
						<th style={{ flex: '0.5' }}>Pregnancy No</th>
						<th style={{ flex: '1' }}>Duration</th>
						<th style={{ flex: '1' }}>Complications</th>
						<th style={{ flex: '1' }}>Mode</th>
						<th style={{ flex: '1' }}>Gender</th>
						<th style={{ flex: '1' }}>Notes</th>
						<th style={{ flex: '0.2' }}></th>
					</tr>
					{formik.values.pregnancyHistory?.map((preg, index) => (
						<tr key={index} style={{ display: 'flex' }}>
							<td style={{ flex: '0.5' }}>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='number'
									className='form-control p-3 shadow-none'
									value={preg.pregnancyNo}
									name={`pregnancyHistory[${index}].pregnancyNo`}
									onChange={formik.handleChange}
								/>
							</td>
							<td style={{ flex: '1' }}>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='text'
									className='form-control p-3 shadow-none'
									value={preg.duration}
									name={`pregnancyHistory[${index}].duration`}
									onChange={formik.handleChange}
								/>
							</td>
							<td style={{ flex: '1' }}>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='text'
									className='form-control p-3 shadow-none'
									value={preg.complications}
									name={`pregnancyHistory[${index}].complications`}
									onChange={formik.handleChange}
								/>
							</td>
							<td style={{ flex: '1' }}>
								<Select
									styles={{
										control: (base) => ({
											...base,
											border: 0,
											boxShadow: 'none',
										}),
									}}
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}}
									options={[
										{ label: 'Normal', value: 'Normal' },
										{ label: 'Caesarean', value: 'Caesarean' },
									]}
									value={preg.mode}
									onChange={(e) => {
										formik.setFieldValue(`pregnancyHistory[${index}].mode`, e);
									}}
								/>
							</td>
							<td style={{ flex: '1' }}>
								<Select
									styles={{
										control: (base) => ({
											...base,
											border: 0,
											boxShadow: 'none',
										}),
									}}
									components={{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}}
									options={[
										{ label: 'Male', value: 'Male' },
										{ label: 'Female', value: 'Female' },
										{ label: 'Others', value: 'Others' },
									]}
									value={preg.gender}
									onChange={(e) => {
										formik.setFieldValue(
											`pregnancyHistory[${index}].gender`,
											e,
										);
									}}
								/>
							</td>
							<td style={{ flex: '1' }}>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='text'
									className='form-control p-3 shadow-none'
									value={preg.extraNotes}
									name={`pregnancyHistory[${index}].extraNotes`}
									onChange={formik.handleChange}
								/>
							</td>
							<td style={{ flex: '0.2' }}>
								<Button
									icon='delete'
									color='danger'
									isOutline
									onClick={() =>
										formik.setFieldValue('pregnancyHistory', [
											...formik.values.pregnancyHistory.slice(0, index),
											...formik.values.pregnancyHistory.slice(index + 1),
										])
									}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default PregnancyHistory;
