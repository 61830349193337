import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	createVendorSubscription,
	deleteVendorSubscription,
	getAlgoSubscriptions,
	getVendorSubscription,
	updateVendorSubscription,
} from '../../../actions/paniniActions';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Tooltips from '../../../components/bootstrap/Tooltips';
import useDarkMode from '../../../hooks/useDarkMode';
import Modal, {
	ModalTitle,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from '../../../components/bootstrap/Modal';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import ReactSelect from 'react-select/creatable';
import { getDoctors } from '../../../actions/doctorActions';
import { getStaff } from '../../../actions/staffActions';

const validate = (values) => {
	const errors = {};
	if (!values.algorithmSubscriptionId.label) {
		errors.algorithmSubscriptionId = 'Required';
	}
	if (!values.modeofpayment) {
		errors.modeofpayment = 'Required';
	}
	if (!values.paymentAmount) {
		errors.paymentAmount = 'Required';
	}
	if (values.vendorIds.length === 0) {
		errors.vendorIds = 'At least one Doctor/Staff is required';
	}
	return errors;
};

const PatientSubscription = ({ patientId }) => {
	// const { id } = useParams()
	const dispatch = useDispatch();
	const darkModeStatus = useDarkMode();

	const data = useSelector((state) => state.panini.vendorSubscription);
	const algoSubscription = useSelector((state) => state.panini.algoSubscription);
	const doctors = useSelector((state) => state.doctor.doctors);
	const staff = useSelector((state) => state.staff.staff);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);

	const [vendorList, setVendorList] = useState([]);

	const [editItem, setEditItem] = useState(null);
	const [editMode, setEditMode] = useState(false);

	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteStatusId, setDelSubId] = useState(null);

	const formik = useFormik({
		initialValues: {
			userId: patientId,
			algorithmSubscriptionId: {},
			modeofpayment: 'Cash',
			paymentAmount: 0,
			startDate: moment().format('YYYY-MM-DD'),
			vendorIds: [],
		},
		validate,
		onSubmit: (values, { resetForm }) => {
			var data = {};
			for (var key in values) {
				if (values[key]) {
					data[key] = values[key];
				}
			}
			data.vendorIds = values?.vendorIds.map((item) => item.value);
			data.algorithmSubscriptionId = values?.algorithmSubscriptionId.value;
			if (editItem) {
				data['algorithmSubscriptionPatientId'] = editItem.id;
				dispatch(updateVendorSubscription(data));
			} else {
				dispatch(createVendorSubscription(data));
			}
			resetForm();
			setEditMode(false);
		},
	});

	const handleUpcomingEdit = (item) => {
		formik.resetForm();
		if (item?.id) {
			formik.setValues({
				userId: patientId,
				modeofpayment: item?.modeofpayment,
				paymentAmount: item?.paymentAmount,
				startDate: moment(item?.startDate).format('YYYY-MM-DD'),
				algorithmSubscriptionId: {
					value: item?.Algorithm_subscription?.id,
					label: item?.Algorithm_subscription?.name,
				},
				vendorIds: item?.Algorithm_subscription_patient_doctors.map((item) => {
					var obj = {
						value: item?.Vendor?.id,
						label: item?.Vendor?.name,
					};
					if (item?.Vendor?.userType != 'staff') {
						obj.label = 'Dr. ' + obj.label;
					} else {
						obj.label = obj.label + ' (Staff)';
					}
					return obj;
				}),
			});
			setEditItem(item);
		} else {
			setEditItem(null);
		}
		setEditMode(!editMode);
	};

	const handleDeleteConfirm = () => {
		dispatch(deleteVendorSubscription(deleteStatusId, patientId));
		setDelSubId(null);
		setDeleteWarn(false);
	};

	const handleDeleteSubs = (statusId) => {
		setDelSubId(statusId);
		setDeleteWarn(true);
	};

	const renderStaffName = (item) => {
		var string = '';
		item?.Algorithm_subscription_patient_doctors?.map((it) => {
			if (it?.Vendor?.userType != 'staff') {
				string += 'Dr. ' + it?.Vendor?.name + ', ';
			} else {
				string += it?.Vendor?.name + ', ';
			}
		});
		return string.slice(0, -2);
	};

	useEffect(() => {
		let mounted = true;
		const fetchData = async () => {
			if (mounted) {
				dispatch(getVendorSubscription(patientId));
				dispatch(getAlgoSubscriptions());
				dispatch(getStaff(selectedOrg?.organisationId));
				dispatch(getDoctors());
			}
		};
		fetchData();
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (staff || doctors) {
			setVendorList([...doctors, ...staff]);
		}
	}, [staff, doctors]);

	return (
		<div>
			<Card stretch={true}>
				<CardHeader borderSize={1}>
					<CardLabel icon='ListAlt' iconcolor='primary'>
						<CardTitle>Algorithm Subscription</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='add'
							onClick={handleUpcomingEdit}
							aria-label=''>
							Add
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>S No.</th>
								<th>Algorithm Subscription</th>
								<th>Amount</th>
								<th>Mode Of Payment</th>
								<th>Doctors</th>
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{data?.map((item, idx) => {
								return (
									<tr key={item.id}>
										<td>{idx + 1}</td>
										<td>{item?.Algorithm_subscription?.name || '-'}</td>
										<td>{item?.paymentAmount || '-'}</td>
										<td>{item?.modeofpayment || '-'}</td>
										<td className='point-cur'>
											<Tooltips title={renderStaffName(item)}>
												{renderStaffName(item)?.substring(0, 50)}
											</Tooltips>
										</td>
										<td>
											<Tooltips title='Edit'>
												<Button
													isOutline={!darkModeStatus}
													color='dark'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Edit'
													onClick={() => {
														handleUpcomingEdit(item);
													}}></Button>
											</Tooltips>{' '}
											<Tooltips title='Delete'>
												<Button
													isOutline={!darkModeStatus}
													color='danger'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Delete'
													onClick={() => {
														handleDeleteSubs(item.id);
													}}></Button>
											</Tooltips>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</CardBody>
			</Card>
			<Modal
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deleteOpdStatus'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteStatusLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete the status?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button color='danger' icon='delete' onClick={handleDeleteConfirm}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={editMode}
				setIsOpen={setEditMode}
				titleId='upcomingEdit'
				isCentered
				size='lg'
				isAnimation={false}>
				<ModalHeader setIsOpen={setEditMode}>
					<ModalTitle id='upcomingEdit'>{editItem ? 'Edit' : 'Add New'}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row g-4'>
						<div className='col-12'>
							<label>Algorithm Subscription</label>
							<ReactSelect
								id='algorithms'
								className='form-control'
								styles={{
									control: () => ({
										position: 'relative',
										display: 'flex',
									}),
								}}
								value={formik.values.algorithmSubscriptionId}
								options={algoSubscription?.map((o) => ({
									value: o?.id,
									label: o?.name,
								}))}
								onChange={(p) => {
									formik.setFieldValue('algorithmSubscriptionId', p);
								}}
							/>
							{formik.touched.algorithmSubscriptionId &&
								formik.errors.algorithmSubscriptionId && (
									<p style={{ color: 'red' }}>
										{formik.errors.algorithmSubscriptionId}
									</p>
								)}
						</div>
						<div className='col-6'>
							<FormGroup id='paymentAmount' label='Payment Amount' isFloating>
								<Input
									placeholder='Payment Amount'
									autoComplete='paymentAmount'
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.paymentAmount}
									isValid={formik.isValid}
									isTouched={formik.touched.paymentAmount}
									invalidFeedback={formik.errors.paymentAmount}
									validFeedback='Looks good!'
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup id='modeofpayment' label='Mode of Payment' isFloating>
								<Input
									placeholder='Mode of Payment'
									autoComplete='modeofpayment'
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.modeofpayment}
									isValid={formik.isValid}
									isTouched={formik.touched.modeofpayment}
									invalidFeedback={formik.errors.modeofpayment}
									validFeedback='Looks good!'
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup id='startDate' label='Start Date' isFloating>
								<Input
									type='date'
									onKeyDown={(e) =>
										invalidNums.includes(e.key) && e.preventDefault()
									}
									placeholder='Start Date'
									autoComplete='startDate'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.startDate}
									isValid={formik.isValid}
									isTouched={formik.touched.startDate}
									invalidFeedback={formik.errors.startDate}
									validFeedback='Looks good!'
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<label>Doctors</label>
							<ReactSelect
								id='vendorIds'
								className='form-control'
								styles={{
									control: () => ({
										position: 'relative',
										display: 'flex',
									}),
								}}
								isMulti
								value={formik.values.vendorIds}
								options={vendorList?.map((o) => {
									return {
										value:
											o?.Vendor?.userType == 'staff'
												? o?.vendorId
												: o?.Vendor_detail?.vendorId,
										label:
											o?.Vendor?.userType == 'staff'
												? o?.firstName + ' ' + o?.lastName + ' (Staff)'
												: 'Dr. ' + o?.Vendor_detail?.name,
									};
									return {
										value:
											o?.userType == 'doctor' || o?.userType == 'admin'
												? o?.Vendor_detail?.vendorId
												: o?.vendorId,
										label:
											o?.userType == 'doctor' || o?.userType == 'admin'
												? 'Dr. ' + o?.Vendor_detail?.name
												: o?.firstName + ' ' + o?.lastName + ' (Staff)',
									};
								})}
								onChange={(p) => {
									formik.setFieldValue('vendorIds', p);
								}}
							/>
							{formik.touched.vendorIds && formik.errors.vendorIds && (
								<p style={{ color: 'red' }}>{formik.errors.vendorIds}</p>
							)}
						</div>
					</div>
				</ModalBody>
				<ModalFooter className='bg-transparent'>
					<Button
						icon={editItem ? 'Save' : 'Add'}
						color='primary'
						isOutline
						isDisable={!formik.isValid && !!formik.submitCount}
						onClick={formik.handleSubmit}>
						{editItem ? 'Save' : 'Add Subscription'}
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default PatientSubscription;
