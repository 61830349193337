import React from 'react';

import Lottie from 'react-lottie';
import Button from '../bootstrap/Button';
import loadergif from '../../animation/loadergif.gif';

const Loader = () => {
	// const defaultOptions = {
	// 	loop: true,
	// 	autoplay: true,
	// 	animationData: animationData,
	// 	rendererSettings: {
	// 		preserveAspectRatio: 'xMidYMid slice',
	// 	},
	//};
	return (
		<>
			<div className='lottie-loader table-loader'>
				<div
					className='m-auto h-100 overflow-hidden d-flex justify-content-center align-items-center '
					style={{ borderRadius: '100%' }}>
					{/* <Lottie height={100} width={100} options={defaultOptions} /> */}
					<img
						style={{
							width: '150px',
							height: '150px',
							objectFit: 'contain',
							borderRadius: '50%',
						}}
						src={loadergif}
					/>
				</div>
			</div>
			{/* <div className="spinner-container">
        <Button loading={true}/>
      </div> */}
		</>
	);
};

export default Loader;
