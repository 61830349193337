import {
	SET_FOLLOWUP_SHEETS,
	SET_WHITE_BOARD,
	SET_DETAILED_MODAL,
	SET_HISTORY_MODAL,
	SET_CLINICALNOTES,
	SET_INVESTIGATION_MODAL,
	SET_IMMUNE_MODAL,
	SET_BILLING_MODAL,
	SET_APPOINTMENT_MODAL,
	SET_PATIENT_ATTACHMENTS_MODAL,
	SET_PRESCRIPTION_TYPE,
	SET_SHARED_REPORTS,
	SET_IPD_CC_MODAL,
	SET_IPD_RX_MODAL,
	SET_IPD_INVESTIGATIONS_MODAL,
	SET_IPD_VISIT_NOTES_MODAL,
	SET_IPD_CONSUMABLES_MODAL,
	SET_IPD_WHITEBOARD_MODAL,
	SET_SUBSCRIPTION_MODAL,
	SET_IPD_PROCEDURES_MODAL,
	SET_IPD_PHYSICAL_EXAMINATION_MODAL,
	SET_IPD_HISTORY_MODAL,
	SET_IPD_ALLERGY_MODAL,
	SET_GYNAE_MODAL,
	SET_IPD_BILLING_MODAL,
	SET_LABOUR_MODAL,
	SET_DISCHARGE_MODAL,
	SET_OPD_GYANE_MODAL,
	SET_DASHBOARDMENU_TYPE,
	SET_IPDFILE_MODAL,
	SET_VIEW_CONSULT2DOCTOR_MODAL,
	SET_CHEMO_NOTES_MODAL,
	SET_DISCHARGE2_MODAL,
	SET_CONSENT_MODAL,
	SET_VISITS_MODAL,
	SET_APPOINTMENT_DIAGNOSES_MODAL,
	SET_IPD_DIAGNOSES_MODAL,
} from './types';

const initialState = {
	viewPatientAttachment: false,
	viewInvestigations: false,
	viewFollowupSheets: false,
	viewWhiteBoard: false,
	viewSubscriptionModal: false,
	viewClinicalNotes: false,
	viewDetailedModal: false,
	viewHistoryModal: false,
	viewImmuneModal: false,
	viewBillingModal: false,
	viewAppointmentModal: false,
	viewSharedReports: false,
	viewIpdCC: false,
	viewIpdRx: false,
	viewIpdInvestigations: false,
	viewIpdVisitNotes: false,
	viewIpdConsumables: false,
	viewIpdWhiteboard: false,
	viewIpdProcedures: false,
	viewPhysicalExaminations: false,
	viewIpdHistory: false,
	viewIpdAllergy: false,
	viewIpdGynae: false,
	viewIpdBillingModal: false,
	viewIpdLabour: false,
	viewIpdFiles: false,
	viewDischargeSummary: false,
	viewDischargeSummary2: false,
	viewOpdGynae: false,
	dashBoardMenuType: 'doctor',
	viewConsult2DoctorModal: false,
	viewVisitModal: false,
	viewAppointmentDiagnosesModal: false,
	viewIpdDiagnosesModal: false,
	viewChemoNotes: false,
	viewConsents: false,
	prescriptionType: { label: 'Short Prescription', value: 'short' },
};

const buttonReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CONSENT_MODAL:
			return {
				...state,
				viewConsents: action.payload,
			};
		case SET_FOLLOWUP_SHEETS:
			return {
				...state,
				viewFollowupSheets: action.payload,
			};
		case SET_WHITE_BOARD:
			return {
				...state,
				viewWhiteBoard: action.payload,
			};
		case SET_CLINICALNOTES:
			return {
				...state,
				viewClinicalNotes: action.payload,
			};
		case SET_DETAILED_MODAL:
			return {
				...state,
				viewDetailedModal: action.payload,
			};
		case SET_HISTORY_MODAL:
			return {
				...state,
				viewHistoryModal: action.payload,
			};
		case SET_INVESTIGATION_MODAL:
			return {
				...state,
				viewInvestigations: action.payload,
			};
		case SET_IMMUNE_MODAL:
			return {
				...state,
				viewImmuneModal: action.payload,
			};
		case SET_BILLING_MODAL:
			return {
				...state,
				viewBillingModal: action.payload,
			};
		case SET_APPOINTMENT_MODAL:
			return {
				...state,
				viewAppointmentModal: action.payload,
			};
		case SET_PATIENT_ATTACHMENTS_MODAL:
			return {
				...state,
				viewPatientAttachment: action.payload,
			};
		case SET_PRESCRIPTION_TYPE:
			return {
				...state,
				prescriptionType: action.payload,
			};
		case SET_SHARED_REPORTS:
			return {
				...state,
				viewSharedReports: action.payload,
			};

		case SET_IPD_CC_MODAL:
			return {
				...state,
				viewIpdCC: action.payload,
			};

		case SET_IPD_RX_MODAL:
			return {
				...state,
				viewIpdRx: action.payload,
			};
		case SET_VIEW_CONSULT2DOCTOR_MODAL:
			return {
				...state,
				viewConsult2DoctorModal: action.payload,
			};
		case SET_IPD_INVESTIGATIONS_MODAL:
			return {
				...state,
				viewIpdInvestigations: action.payload,
			};

		case SET_IPD_VISIT_NOTES_MODAL:
			return {
				...state,
				viewIpdVisitNotes: action.payload,
			};

		case SET_IPD_CONSUMABLES_MODAL:
			return {
				...state,
				viewIpdConsumables: action.payload,
			};

		case SET_IPD_WHITEBOARD_MODAL:
			return {
				...state,
				viewIpdWhiteboard: action.payload,
			};
		case SET_IPD_PROCEDURES_MODAL:
			return {
				...state,
				viewIpdProcedures: action.payload,
			};
		case SET_IPD_PHYSICAL_EXAMINATION_MODAL:
			return {
				...state,
				viewPhysicalExaminations: action.payload,
			};
		case SET_IPD_HISTORY_MODAL:
			return {
				...state,
				viewIpdHistory: action.payload,
			};
		case SET_IPD_ALLERGY_MODAL:
			return {
				...state,
				viewIpdAllergy: action.payload,
			};
		case SET_GYNAE_MODAL:
			return {
				...state,
				viewIpdGynae: action.payload,
			};
		case SET_OPD_GYANE_MODAL:
			return {
				...state,
				viewOpdGynae: action.payload,
			};
		case SET_IPD_BILLING_MODAL:
			return {
				...state,
				viewIpdBillingModal: action.payload,
			};
		case SET_IPDFILE_MODAL:
			return {
				...state,
				viewIpdFiles: action.payload,
			};
		case SET_LABOUR_MODAL:
			return {
				...state,
				viewIpdLabour: action.payload,
			};
		case SET_DISCHARGE_MODAL:
			return {
				...state,
				viewDischargeSummary: action.payload,
			};
		case SET_DISCHARGE2_MODAL:
			return {
				...state,
				viewDischargeSummary2: action.payload,
			};
		case SET_SUBSCRIPTION_MODAL:
			return {
				...state,
				viewSubscriptionModal: action.payload,
			};
		case SET_DASHBOARDMENU_TYPE:
			return {
				...state,
				dashBoardMenuType: action.payload || 'doctor',
			};
		case SET_CHEMO_NOTES_MODAL:
			return {
				...state,
				viewChemoNotes: action.payload,
			};
		case SET_VISITS_MODAL:
			return {
				...state,
				viewVisitModal: action.payload,
			};
		case SET_APPOINTMENT_DIAGNOSES_MODAL:
			return {
				...state,
				viewAppointmentDiagnosesModal: action.payload,
			};

		case SET_IPD_DIAGNOSES_MODAL:
			return {
				...state,
				viewIpdDiagnosesModal: action.payload,
			};

		default:
			return state;
	}
};

export default buttonReducer;
