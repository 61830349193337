import {
	SET_PATIENT_SOAP,
    SET_SOAP_REMARKS,
    SET_SOAP_SUBJ,
    SET_SOAP_OBJ,
    SET_SOAP_ASS,
    SET_SOAP_PLAN,
    SET_SOAP_ATTACHMENTS,
    SET_SOAP_ATTACH_REMARK
} from './types';

const initialState = {
	patientSoap : [],
    soapAttachments : [],
    soapRemarks : [],
    soapAttachRemark : [],
    soapSubj : [],
    soapObj : [],
    soapAss : [],
    soapPlan : [],
    soapFiles : [],
	error: null,
	loading: false,
};

const soapReducer = (state = initialState, action) => {
	switch (action.type) {
        case SET_PATIENT_SOAP:
            return {
                ...state,
                patientSoap: action.payload || [],
            };
        case SET_SOAP_ATTACHMENTS:
            return {
                ...state,
                soapAttachments: action.payload || [],
            }
        case SET_SOAP_ATTACH_REMARK:
            return {
                ...state,
                soapAttachRemark: action.payload || [],
            }
        case SET_SOAP_REMARKS:
            return {
                ...state,
                soapRemarks : action.payload || [],
            };
        case SET_SOAP_SUBJ:
            return {
                ...state,
                soapSubj : action.payload || [],
            };
        case SET_SOAP_OBJ:
            return {
                ...state,
                soapObj : action.payload || [],
            }
        case SET_SOAP_ASS:
            return {
                ...state,
                soapAss : action.payload || [],
            }
        case SET_SOAP_PLAN:
            return {
                ...state,
                soapPlan : action.payload || [],
            }
		default:
			return state;
	}
};

export default soapReducer;
