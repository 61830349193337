import { useState, useRef } from 'react';
import { checkSTTLicense } from '../helpers/helpers';
import showNotification from '../components/extras/showNotification';

const useAsr = () => {
	const reader = new FileReader();

	const [listening, setListening] = useState(false);
	const [speech, setSpeech] = useState('');
	const [speechTemp, setSpeechTemp] = useState('');
	const [waitingStatus, setWaitingStatus] = useState(false);
	const [closeLater, setCloseLater] = useState(false);

	const mediaRecorder = useRef(null);
	const userId = useRef(null);
	const mediaStream = useRef(null);
	const socket = useRef(null);
	const interval = useRef(null);

	const checkPermission = async () => {
		if ('MediaRecorder' in window) {
			try {
				mediaStream.current = await navigator.mediaDevices.getUserMedia({
					audio: true,
					video: false,
				});
				return true;
			} catch (err) {
				console.log('Error', err.message, 'danger');
			}
			return false;
		} else {
			showNotification(
				'Not Supported',
				'The MediaRecorder API is not supported in your browser.',
				'danger',
			);
			return false;
		}
	};

	const recordAndSend = () => {
		let localAudioChunks = [];

		mediaRecorder.current.ondataavailable = (event) => {
			if (typeof event.data === 'undefined') return;
			if (event.data.size === 0) return;
			localAudioChunks.push(event.data);
		};

		mediaRecorder.current.onstop = (e) => {
			const blob = new Blob(localAudioChunks, { type: 'audio/webm' });
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				let base64 = reader.result;
				base64 = base64.split(',')[1];
				if (userId.current)
					socket.current.send(JSON.stringify({ audio: base64, user_id: userId.current }));
				else socket.current.send(JSON.stringify({ audio: base64, new_user: true }));
				setWaitingStatus(true);
			};
		};

		mediaRecorder.current.stop?.();
		mediaRecorder.current.start?.();
	};

	const startAsr = async () => {
		if (!(await checkPermission())) {
			showNotification(
				'Permission Denied',
				'You have not given permission to access mic.',
				'danger',
			);
			return;
		}

		setListening(true);
		// Connect to Transcribe Socket
		socket.current = new WebSocket('wss://asr-socket.docplix.com/transcribe');

		socket.current.onmessage = (event) => {
			if (event.type && event.type === 'message') {
				const res = JSON.parse(event.data);

				if (res.user_id) userId.current = res.user_id;

				if (res.text) {
					setSpeechTemp((prev) => prev + (res?.text || ''));
				}

				if (res.postprocessed_text) {
					setSpeech(res?.postprocessed_text || '');
					setSpeechTemp('');

					setWaitingStatus(false);
					if (closeLater) {
						socket.current.close?.();
						setCloseLater(false);
					}
				}
			} else console.log('bad data from socket');
		};

		socket.current.onclose = () => console.log('asr closed');

		// Get MIC
		mediaRecorder.current = new MediaRecorder(mediaStream.current, { type: 'audio/webm' });

		// generate a new file every 3s
		recordAndSend();
		interval.current = setInterval(() => recordAndSend(), 4500);
	};

	const stopAsr = () => {
		// Remove Sending Audio
		clearInterval(interval.current);
		interval.current = null;

		// Stop MIC
		mediaStream.current.getTracks().forEach((x) => x.stop());
		setListening(false);

		// Remove user
		const removeSocket = new WebSocket('wss://asr-socket.docplix.com/removeUser');
		removeSocket.onopen = () => removeSocket.send(JSON.stringify({ user_id: userId.current }));

		removeSocket.onmessage = (event) => {
			if (event.type && event.type === 'message') {
				const res = JSON.parse(event.data);

				if (res.postprocessed_text) {
					setSpeech((prev) => prev + (res.postprocessed_text || ''));
					setSpeechTemp('');
				}

				if (res.text) setSpeechTemp(res?.text || '');
			} else console.log('bad data from socket');

			userId.current = null;
			removeSocket.close();
		};

		if (waitingStatus) setCloseLater(true);
		else socket.current?.close?.();
	};

	const toggleAsr = async () => {
		if (!listening) {
			if (!(await checkSTTLicense())) {
				showNotification(
					'Permission Denied',
					'You are not subscribed to use speech to text',
					'danger',
				);
				return;
			}

			startAsr();
		} else stopAsr();
	};

	return { toggleAsr, listening, speech, speechTemp, setSpeech };
};

export default useAsr;
