import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Select from 'react-select';
import Button from '../../../components/bootstrap/Button';
import Tooltips from '../../../components/bootstrap/Tooltips';
import CommonHeaderRight from './CommonHeaderRight';
import { deletePatient } from '../../../actions/patientActions';
import LogoImg from '../../../assets/logos/logo.png';
import { NavLink } from 'react-router-dom';
import CommonHeaderChat from './CommonHeaderChat';
import { SET_PRESCRIPTION_TYPE } from '../../../reducers/types';
import { dashboardMenu } from '../../../menu';
import { calculateAge, isPermitted } from '../../../actions/helperActions';
import classNames from 'classnames';
import { getOpdRecordsByUserId } from '../../../actions/opdActions';

const PatientHeader = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const currentPatient = useSelector((state) => state.patient.patient);
	const { selectedOrg } = useSelector((state) => state.profile);
	const prescType = useSelector((state) => state.button.prescriptionType) || {
		label: 'Short Prescription',
		value: 'short',
	};

	const [patientOpds, setPatientOpds] = useState([]);
	const visits = useMemo(
		() => patientOpds?.docs?.filter?.((i) => !i.isCanceled && i.Opd_status)?.length || 0,
		[patientOpds],
	);

	const [deleteWarn, setDeleteWarn] = useState(false);
	const [abhaCardModal, setAbhaCardModal] = useState(false);

	const handleDeleteConfirm = () => {
		if (currentPatient)
			dispatch(deletePatient(currentPatient?.id, selectedOrg?.organisationId, navigate));
		setDeleteWarn(false);
	};

	const [screenSize, getDimension] = useState({
		dynamicWidth: window.innerWidth,
		dynamicHeight: window.innerHeight,
	});
	const setDimension = () => {
		getDimension({
			dynamicWidth: window.innerWidth,
			dynamicHeight: window.innerHeight,
		});
	};

	useEffect(() => {
		let mounted = true;

		const fetchOthers = async () => {
			if (mounted) {
				setPatientOpds(await getOpdRecordsByUserId(currentPatient?.id));
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
		};
	}, [currentPatient]);

	useEffect(() => {
		window.addEventListener('resize', setDimension);

		return () => {
			window.removeEventListener('resize', setDimension);
		};
	}, [screenSize]);

	return (
		<>
			<Header>
				<HeaderLeft className='d-flex ps-0'>
					<NavLink to='/'>
						<img src={LogoImg} width={125} height={42} alt='logo' />
					</NavLink>

					<div className='d-flex align-items-center w-100 mx-5'>
						<Tooltips title='All Patients'>
							<Button
								icon='ArrowBackIos'
								color='primary'
								isLight
								className='mx-3 delete-pat-bttn'
								onClick={() =>
									navigate(`/${dashboardMenu.patients.subMenu.viewPatients.path}`)
								}
							/>
						</Tooltips>
						<div className='d-flex align-items-center' style={{ flex: 1 }}>
							<div style={{ flex: 1 }}>
								<h4
									style={{
										fontSize: 18,
										fontWeight: 500,
										color: '#323232',
										marginBottom: 0,
									}}>
									{currentPatient?.Members?.[0]?.name}
								</h4>
								<p className='m-0' style={{ color: '#323232' }}>
									<strong>Age</strong>:{' '}
									{currentPatient?.Members?.[0]?.dob
										? calculateAge(currentPatient?.Members?.[0]?.dob)
										: currentPatient?.Members?.[0]?.age
										? currentPatient?.Members?.[0]?.age + 'y'
										: 'NA'}
									, <strong>Gender</strong>:{' '}
									{currentPatient?.Members?.[0]?.gender?.toUpperCase()?.[0]},{' '}
									<strong>Visits:</strong> {visits || 'NA'}
									<br />
									<strong>Contact</strong>:{' '}
									{currentPatient?.Members?.[0]?.phone || 'NA'},{' '}
									{currentPatient?.User_medical_alerts?.length > 0 && (
										<>
											<strong>MA:</strong>{' '}
											{currentPatient?.User_medical_alerts?.map(
												(x) => x?.Medical_alert?.title,
											)?.join(', ')}
										</>
									)}
								</p>
							</div>
							<Select
								components={{
									IndicatorSeparator: () => null,
								}}
								styles={{
									dropdownIndicator: (base) => ({
										...base,
										color: 'white',
									}),
									option: (base) => ({ ...base, color: '#323232' }),
									input: (base) => ({
										...base,
										color: 'white',
									}),
									singleValue: (base) => ({
										...base,
										color: 'white',
										fontWeight: '500',
										fontSize: '1.05rem',
									}),
									control: (base) => ({
										...base,
										border: 0,
										background: 'var(--bs-primary)',
										width: '180px',
									}),
									placeholder: (base) => ({
										...base,
										color: '#fff',
									}),
								}}
								value={prescType}
								options={[
									{
										label: 'Short Prescription',
										value: 'short',
									},
									{
										label: 'SOAP Prescription',
										value: 'soap',
									},
									{
										label: 'APSO Prescription',
										value: 'apso',
									},
									// {
									// 	label: 'Voice Prescription',
									// 	value: 'voice',
									// },
								]}
								onChange={(e) =>
									dispatch({ type: SET_PRESCRIPTION_TYPE, payload: e })
								}
							/>
						</div>
						<div className='patient-buttons'>
							{currentPatient?.Members?.[0]?.svgHealthCard && (
								<Tooltips title='ABHA Card'>
									<Button
										icon='eye'
										color='danger'
										isLight
										className='w-100 px-3 delete-pat-bttn'
										onClick={() =>
											isPermitted('Patients', 'read', () =>
												setAbhaCardModal(true),
											)
										}
									/>
								</Tooltips>
							)}
							&nbsp;
							<Tooltips title='Delete Patient'>
								<Button
									icon='Delete'
									color='danger'
									isLight
									className='w-100 px-3 delete-pat-bttn'
									onClick={() =>
										isPermitted('Patients', 'delete', () => setDeleteWarn(true))
									}
								/>
							</Tooltips>
						</div>
					</div>
				</HeaderLeft>
				<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
			</Header>

			<Modal
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deleteRec'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteRecLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete the patient?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button
						color='danger'
						icon='delete'
						onClick={() => isPermitted('Patients', 'delete', handleDeleteConfirm)}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={abhaCardModal}
				setIsOpen={setAbhaCardModal}
				titleId={'deleteRec'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setAbhaCardModal}>
					<ModalTitle id='deleteRecLabel'>Abha Card</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div
						className={classNames({
							'view-abha-card': true,
						})}>
						<img
							src={`data:image/png;base64,${currentPatient?.Members?.[0]?.svgHealthCard}`}
						/>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default PatientHeader;
