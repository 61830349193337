import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';

import { NavLinkDropdown } from '../../../components/bootstrap/Nav';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import ThemeContext from '../../../contexts/themeContext';
import { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';

import { SET_PATIENT } from '../../../reducers/types';
import {
	getOrganisation,
	getOrganisations,
	setInitialOrganisation,
} from '../../../actions/organisationActions';
import { staticUrl } from '../../../helpers/helpers';
import InputGroup from '../../../components/bootstrap/forms/InputGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Updates from '../components/Updates';
import { settingsMenu } from '../../../menu';
import { getPatient, getPatientList } from '../../../actions/patientActions';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [viewUpdates, setViewUpdates] = useState(false);

	// const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				{/* <Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' /> */}
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			'You updated the language of the site. (Only "Aside" was prepared as an example.)',
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const [openDropdown, setOpenDropdown] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const [searchItems, setSearchItems] = useState([]);

	const loggedIn = useSelector((state) => state.auth.loggedIn);
	const organisations = useSelector((state) => state.organisation.organisations);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);
	const patients = useSelector((state) => state.patient.searchPatientList);
	const user = useSelector((state) => state.auth.user);

	const handleSearchPatient = (e) => {
		setSearchInput(e.target.value);
		const key = e.target.value?.toLowerCase?.();
		if (key) {
			let filteredPatients = patients.filter((i) =>
				isNaN(key)
					? i?.name?.toLowerCase?.().includes(key)
					: i?.phone?.toString()?.includes(key) ||
					  i?.selfUserId?.toString()?.includes(key) ||
					  i?.seriesId?.toString()?.includes(key),
			);
			setSearchItems(filteredPatients);
			setOpenDropdown(true);
		} else {
			setSearchItems([]);
			setOpenDropdown(false);
		}
	};

	useEffect(() => {
		let mounted = true;

		const fetchOrganisations = () => {
			if (mounted && loggedIn) {
				dispatch(getOrganisations());
				dispatch(getPatientList(selectedOrg?.organisationId));
			}
		};

		fetchOrganisations();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<HeaderRight>
			<div className='row g-2 align-items-center'>
				{/* {beforeChildren} */}

				{/* Patient Search */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle
							hasIcon={false}
							setIsOpen={setOpenDropdown}
							isOpen={openDropdown}>
							<InputGroup>
								<Input
									className
									placeholder='Search Patient'
									autoComplete='off'
									onChange={handleSearchPatient}
									value={searchInput}
								/>
								<Button isLight icon='Search' color='primary' onClick={() => {}} />
							</InputGroup>
						</DropdownToggle>
						<DropdownMenu>
							{searchItems.map((i) => (
								<DropdownItem key={i.id}>
									<Button
										onClick={() => {
											setSearchInput('');
											setSearchItems([]);
											dispatch(getPatient(i?.selfUserId));
											// dispatch({
											// 	type: SET_PATIENT,
											// 	payload: i,
											// });
											navigate(`/patients/${i?.selfUserId}/aida`);
										}}>
										<p>
											{i?.name}
											<span style={{ fontSize: '0.8rem' }}>
												{' '}
												(UHId: {i?.selfUserId})
											</span>
											<br />
											<span style={{ fontSize: '0.8rem' }}>{i?.phone}</span>
										</p>
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/* Organisation Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								style={{
									fontSize: '12px',
								}}
								icon={!selectedOrg?.Organisation?.logo ? 'building' : ''}
								aria-label='Change Organisation'>
								{selectedOrg ? (
									<>
										{selectedOrg?.Organisation?.logo && (
											<img
												className='rounded-circle'
												src={`${staticUrl}/organisations/${selectedOrg?.Organisation?.logo}`}
												width={24}
												height={24}
											/>
										)}
										{/* {selectedOrg?.Organisation?.pin} */}
									</>
								) : (
									'Not Selected'
								)}
							</Button>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							{organisations.map((i) => (
								<DropdownItem key={i.id}>
									<Button
										icon={!i?.logo ? 'building' : ''}
										onClick={async () => {
											const org = await getOrganisation(i?.id);
											dispatch(setInitialOrganisation(org, true));
										}}>
										{i?.logo && (
											<img
												className='rounded-circle me-3'
												src={`${staticUrl}/organisations/${i?.logo}`}
												width={24}
												height={24}
											/>
										)}
										{i.name}{' '}
										{selectedOrg?.organisationId === i.id && (
											<strong className='ms-1'>(Selected)</strong>
										)}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/* Dark Mode */}
				{/* <div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							aria-label='Toggle fullscreen'
							data-tour='dark-mode'
						/>
					</Popovers>
				</div> */}

				<div className='col-auto'>
					<Popovers trigger='hover' desc='Updates'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={'apps'}
							onClick={() => setViewUpdates(!viewUpdates)}
							aria-label='view Updates'
						/>
					</Popovers>
				</div>

				{/*	Full Screen */}
				{/* <div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div> */}

				{/* Lang Selector */}
				{/* <div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								// icon={getLangWithKey(i18n.language)?.icon}
								icon='Language'
								aria-label='Change language'
								data-tour='lang-selector'
							/>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										// icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div> */}

				{/* Settings */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<NavLinkDropdown style={{ color: 'black', padding: 0 }}>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									isDisable
									icon='Settings'></Button>
							</NavLinkDropdown>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							{Object.keys(settingsMenu).map((subItem) => (
								<DropdownItem>
									<NavLink to={`/${settingsMenu[subItem].path}`}>
										{settingsMenu[subItem].icon && (
											<img
												className='me-2'
												width='20px'
												height='20px'
												src={settingsMenu[subItem].icon}
											/>
										)}
										<span style={{ color: 'black' }}>
											{settingsMenu[subItem].text}
										</span>
									</NavLink>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{afterChildren}
			</div>
			<OffCanvas isOpen={viewUpdates} isBodyScroll placement='end' width={'30%'}>
				<OffCanvasHeader setOpen={setViewUpdates}>
					<OffCanvasTitle>Major Updates</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Updates />
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
