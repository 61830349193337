import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';

import {
	addIpdConsumable,
	deleteIpdConsumable,
	getIpdConsumables,
	updateIpdConsumable,
} from '../../../actions/ipdPatientActions';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalHeader,
	ModalFooter,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Input from '../../../components/bootstrap/forms/Input';
import Select from 'react-select';
import { getIpdOther } from '../../../actions/settingActions';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import Tooltips from '../../../components/bootstrap/Tooltips';
import Button from '../../../components/bootstrap/Button';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import { isPermitted } from '../../../actions/helperActions';

function IpdConsummables() {
	const dispatch = useDispatch();
	const darkModeStatus = useDarkMode();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const ipdOthersData = useSelector((state) => state.setting.ipdOthers);
	const consumables = useSelector((state) => state.ipdPatient.consumables);

	const [deleteItemId, setDeleteItemId] = useState(null);
	const [editMode, setEditMode] = useState(false);
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(consumables);

	const handleUpcomingEdit = (item) => {
		formik.setValues((prev) => ({
			...prev,
			ipdConsummableId: item?.id,
			ipdId: item?.ipdId,
			ipdOther: { label: item?.Ipd_other?.name, value: item?.Ipd_other },
			quantity: item?.quantity,
			date: moment(item?.date).format('YYYY-MM-DD'),
		}));
		setAddModal(true);
		setEditMode(true);
	};

	const handleDeleteConfirm = () => {
		if (deleteItemId) {
			dispatch(deleteIpdConsumable(deleteItemId));
			setDeleteItemId(null);
			setDeleteWarn(false);
		}
	};

	const formik = useFormik({
		initialValues: {
			ipdId,
			date: moment().format('YYYY-MM-DD'),
			ipdOther: '',
			quantity: '',
		},
		onSubmit: (values, { resetForm }) => {
			const data = { ...values };
			data['ipdOtherId'] = [data.ipdOther?.value?.id];
			delete data['ipdOther'];
			if (editMode) {
				data['ipdOtherId'] = data.ipdOtherId?.[0];
				dispatch(updateIpdConsumable(data));
			} else dispatch(addIpdConsumable(data));
			setAddModal(false);
			setEditMode(false);
			resetForm();
		},
	});

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			dispatch(getIpdConsumables(ipdId));
			dispatch(getIpdOther());
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' iconColor='primary'>
						<CardTitle>Consumables</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							isOutline={darkModeStatus}
							color='primary'
							isLight={!darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='add'
							onClick={() =>
								isPermitted(
									'Ipd',
									'write',
									() => setAddModal(true),
									'Consumables /Others',
								)
							}>
							Add
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody isScrollable className='d-flex flex-column'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th className='text-center'>Date</th>
								<th className='text-center'>Ipd Other</th>
								<th className='text-center'>Quantity</th>
								<th className='text-center'>Action</th>
							</tr>
						</thead>
						<tbody>
							{dataPagination(items, currentPage, perPage).map((item, idx) => (
								<tr key={item?.id}>
									<td className='text-center'>
										{moment(item?.date).format('DD-MM-YYYY')}
									</td>
									<td className='text-center'>{item.Ipd_other?.name || '-'}</td>
									<td className='text-center'>{item?.quantity || '-'}</td>
									<td className='text-center'>
										<Tooltips title='Edit'>
											<Button
												isOutline={!darkModeStatus}
												color='primary'
												isLight={darkModeStatus}
												className={classNames('text-nowrap', {
													'border-light': !darkModeStatus,
												})}
												onClick={() => {
													isPermitted(
														'Ipd',
														'edit',
														() => handleUpcomingEdit(item),
														'Consumables /Others',
													);
												}}
												icon='edit'></Button>
										</Tooltips>{' '}
										<Tooltips title='Delete'>
											<Button
												isOutline={!darkModeStatus}
												color='danger'
												isLight={darkModeStatus}
												className={classNames('text-nowrap', {
													'border-light': !darkModeStatus,
												})}
												onClick={() => {
													isPermitted(
														'Ipd',
														'delete',
														() => {
															setDeleteWarn(true);
															setDeleteItemId(item?.id);
														},
														'CONSUMABLES /OTHERS',
													);
												}}
												icon='Delete'></Button>
										</Tooltips>
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<PaginationButtons
						data={items}
						label='items'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
					/>
				</CardBody>
			</Card>
			<Modal
				isOpen={addModal}
				setIsOpen={setAddModal}
				titleId={'deletePharm'}
				size='xl'
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setAddModal}>
					<ModalTitle id='deletePharmLabel'>Add Consumable</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<Card className='shadow-none'>
						<CardBody>
							<div className='row'>
								<div className='col-4'>
									<Input
										value={formik.values.date}
										type='date'
										onChange={formik.handleChange}
										name='date'
										style={{
											height: '3.5rem',
										}}
									/>
								</div>
								<div className='col-4'>
									<Select
										className='form-control'
										placeholder='Select Ipd Other...'
										styles={{
											control: (prev) => ({
												height: '2.6rem',
												alignItems: 'center',
												position: 'relative',
												display: 'flex',
											}),
										}}
										options={ipdOthersData?.map((x) => ({
											label: x.name,
											value: x,
										}))}
										value={formik.values.ipdOther}
										onChange={(e) => {
											formik.setFieldValue('ipdOther', e);
										}}
									/>
								</div>
								<div className='col-4'>
									<Input
										value={formik.values.quantity}
										type='text'
										onChange={formik.handleChange}
										name='quantity'
										placeholder='Quantity'
										style={{
											height: '3.5rem',
										}}
									/>
								</div>
							</div>
						</CardBody>
					</Card>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isLight
						className='border-0'
						onClick={() => {
							setAddModal(false);
							setEditMode(false);
						}}>
						Cancel
					</Button>
					<Button color='primary' isOutlined icon='save' onClick={formik.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deletePharmLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setDeleteItemId(null);
							setDeleteWarn(false);
						}}>
						Cancel
					</Button>
					<Button color='danger' icon='delete' onClick={handleDeleteConfirm}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default IpdConsummables;
