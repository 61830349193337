import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Button from '../../../components/bootstrap/Button';
import Tooltips from '../../../components/bootstrap/Tooltips';
import classNames from 'classnames';
import useDarkMode from '../../../hooks/useDarkMode';
import Creatable from 'react-select/creatable';
import { getCustomRemarks } from '../../../actions/historyActions';
import {
	SET_SOAP_ATTACHMENTS,
	SET_SOAP_ATTACH_REMARK,
	SET_SOAP_REMARKS,
} from '../../../reducers/types';
import AUDIO from '../../../assets/img/audio.png';
import PDF from '../../../assets/img/pdf.png';
import DOC from '../../../assets/img/doc.png';
import CROSS from '../../../assets/img/cross.png';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { createCustomRemark, isPermitted } from '../../../actions/helperActions';
import Spinner from '../../../components/bootstrap/Spinner';
import Card, { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import {
	deleteSoapAttachment,
	getSoapAttachments,
	uploadSoapAttachment,
} from '../../../actions/patientActions';
import { staticUrl } from '../../../helpers/helpers';
import { useNavigate } from 'react-router-dom';
import showNotification from '../../../components/extras/showNotification';

const validImageTypes = /\.(png|jpg|jpeg|JPEG|svg)$/;

const PatientAttachment = ({ id, viewAdd, setViewAdd }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { soapAttachments, soapRemarks } = useSelector((state) => state.soap);
	const { vendorId } = useSelector((state) => state.auth.user.Vendor_detail);
	const doctor_id = vendorId;
	const orgId = useSelector((state) => state?.profile?.selectedOrg?.organisationId);
	const [attachments, setAttachments] = useState([]);
	const [attachDate, setAttachDate] = useState(moment().format('YYYY-MM-DD'));
	const [attachRemark, setAttachRemark] = useState(null);
	const [exporting, setExporting] = useState(false);

	const handleAddAttachImage = (e) => {
		if (!e.target.files) {
			return;
		}

		const imgs = Array.from(e.target.files).map((i) => ({
			file: i,
			blob: URL.createObjectURL(i),
		}));

		setAttachments((prev) => [...prev, ...imgs]);
		e.target.value = null;
	};

	const handleSaveAttachments = async () => {
		setExporting(true);
		try {
			let data = new FormData();
			data.append('userId', id);
			data.append('organisationId', orgId);
			data.append('date', attachDate);

			if (attachments?.length > 0) {
				for (let i = 0; i < attachments.length; i++) {
					data.append('document', attachments[i].file);
				}

				if (attachRemark?.value) data.append('remarks', attachRemark?.label);

				// Upload
				dispatch(uploadSoapAttachment(data, doctor_id, id));
			} else {
				showNotification('error', 'Please upload an image', 'danger');
			}
			// Reset
			setAttachDate(moment().format('YYYY-MM-DD'));
			setAttachments([]);
			setAttachRemark({});
			setViewAdd?.(false);
		} catch (err) {
			console.log(err);
		} finally {
			setExporting(false);
		}
	};

	const handleDeleteAttachment = (index) => {
		setAttachments((prev) => prev.filter((_, i) => i !== index));
	};

	useEffect(() => {
		let mounted = true;
		const fetchData = async () => {
			if (mounted) {
				dispatch(getCustomRemarks(SET_SOAP_REMARKS, 'soap_atthmnt', doctor_id));
				isPermitted(
					'medical profile',
					'read',
					() => dispatch(getSoapAttachments(doctor_id, id)),
					null,
					false,
				);
			}
		};
		fetchData();
		return () => {
			mounted = false;
			dispatch({
				type: SET_SOAP_ATTACHMENTS,
				payload: [],
			});
		};
	}, []);

	return (
		<>
			{viewAdd && (
				<Card>
					<CardHeader className='pb-0'>
						<CardTitle>Add New Attachment</CardTitle>
					</CardHeader>
					<CardBody>
						<div className='row'>
							<div className='col-6'>
								<FormGroup label='Date' id='date'>
									<Input
										id='date'
										type='date'
										value={attachDate}
										onChange={(e) => setAttachDate(e.target.value)}
									/>
								</FormGroup>
							</div>
							<div className='col-6'>
								<FormGroup label='Attachment Images' id='img'>
									<Input
										type='file'
										// accept='image/*'
										autoComplete='profile-img'
										multiple
										onChange={handleAddAttachImage}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<div className='row g-4'>
									<div className='col-12'>
										<div className='row'>
											{attachments.map((i, index) => (
												<>
													<div className='col-3'>
														<div className='image-container'>
															<a
																href={i.blob}
																target='_blank'
																rel='noreferrer'
																className='hover-image-link'>
																{i?.file?.type?.includes(
																	'image',
																) ? (
																	<img
																		src={i.blob}
																		alt={i.file.name}
																	/>
																) : i?.file?.type?.includes(
																		'pdf',
																  ) ? (
																	<img
																		src={PDF}
																		alt={i.file.name}
																	/>
																) : i?.file?.type?.includes(
																		'audio',
																  ) ? (
																	<img
																		src={AUDIO}
																		alt={i.file.name}
																	/>
																) : (
																	<img
																		src={DOC}
																		alt={i.file.name}
																	/>
																)}
															</a>
															<img
																src={CROSS}
																alt={'Cross'}
																className='cross-icon'
																onClick={() =>
																	isPermitted(
																		'Medical Profile',
																		'delete',
																		() =>
																			handleDeleteAttachment(
																				index,
																			),
																	)
																}
															/>
														</div>
													</div>
												</>
											))}
										</div>
									</div>
								</div>
							</div>
							<div className='col-12'>
								<br />
								<FormGroup label='Remark' id='remark'>
									<Creatable
										id='remark'
										className='form-control'
										styles={{
											control: () => ({
												position: 'relative',
												display: 'flex',
												height: '2.6rem',
												alignItems: 'center',
											}),
										}}
										formatCreateLabel={(e) => `${e}`}
										placeholder='Write here...'
										components={{
											DropdownIndicator: () => null,
											IndicatorSeparator: () => null,
										}}
										value={attachRemark}
										options={soapRemarks?.map((i) => ({
											value: i.id,
											label: i.name,
										}))}
										// onCreateOption={async (i) => {
										// 	const resp = await createCustomRemark(
										// 		i,
										// 		'soap_atthmnt',
										// 		doctor_id,
										// 	);
										// 	if (resp) {
										// 		setAttachRemark({
										// 			value: resp.id,
										// 			label: resp.name,
										// 		});
										// 	}
										// 	dispatch(
										// 		getCustomRemarks(
										// 			SET_SOAP_ATTACH_REMARK,
										// 			'soap_atthmnt',
										// 			doctor_id,
										// 		),
										// 	);
										// }}
										onChange={(i) => {
											// console.log(i);
											// formik.values.values = i.value
											setAttachRemark(i);
										}}
									/>
								</FormGroup>
							</div>
							<div className='save-button'>
								<br />

								<Button
									color='primary'
									icon={!exporting && 'check'}
									isDisable={exporting}
									onClick={() =>
										isPermitted(
											'Medical Profile',
											'write',
											handleSaveAttachments,
										)
									}>
									{exporting ? (
										<>
											<Spinner isGrow size='1.2rem' className='me-2' />{' '}
											Uploading
										</>
									) : (
										'Save'
									)}
								</Button>

								<br />
								<br />
							</div>
						</div>
					</CardBody>
				</Card>
			)}
			<div className='row'>
				<div className='col-12'>
					{soapAttachments?.length > 0 ? (
						<table
							className='table table-modern me-2 pb-3'
							style={{
								minWidth: '40vw',
							}}>
							<thead>
								<tr>
									<th>Date</th>
									<th>File </th>
									<th>Remark </th>
									<th>Action </th>
								</tr>
							</thead>
							<tbody>
								{soapAttachments?.map((i) => (
									<tr>
										<td>{moment(i?.date).format('DD-MM-YYYY')}</td>
										<td>
											<div className='image-container'>
												<a
													href={`${staticUrl}/patient-profile/${
														i.filename || i.image
													}`}
													target='_blank'
													rel='noreferrer'
													className='hover-image-link'>
													{(i?.image?.match(validImageTypes) ||
														i?.filename?.match(validImageTypes)) && (
														<img
															src={`${staticUrl}/patient-profile/${
																i.filename || i.image
															}`}
															alt={
																i?.image
																	? i?.image?.substring(
																			i?.image?.lastIndexOf(
																				'/',
																			) + 1,
																	  )
																	: i?.filename?.substring(
																			i?.filename?.lastIndexOf(
																				'/',
																			) + 1,
																	  )
															}
														/>
													)}
													{i?.image?.includes('pdf') && (
														<img
															src={PDF}
															alt={i?.image?.substring(
																i?.image?.lastIndexOf('/') + 1,
															)}
														/>
													)}
													{i?.image?.includes('mp3') && (
														<img
															src={AUDIO}
															alt={i?.image?.substring(
																i?.image?.lastIndexOf('/') + 1,
															)}
														/>
													)}
													{i?.isSoapImage
														? !i?.image?.match(validImageTypes) &&
														  !i?.image?.includes('pdf') &&
														  !i?.image?.includes('mp3') && (
																<img
																	src={DOC}
																	alt={i?.image?.substring(
																		i?.image?.lastIndexOf('/') +
																			1,
																	)}
																/>
														  )
														: !i?.filename?.match(validImageTypes) &&
														  !i?.filename?.includes('pdf') &&
														  !i?.filename?.includes('mp3') && (
																<img
																	src={DOC}
																	alt={i?.filename?.substring(
																		i?.filename?.lastIndexOf(
																			'/',
																		) + 1,
																	)}
																/>
														  )}
													<br />
													{i.name
														? i.name
														: i?.image
														? i?.image?.substring(
																i?.image?.lastIndexOf('/') + 1,
														  )
														: i?.filename?.substring(
																i?.filename?.lastIndexOf('/') + 1,
														  )}
												</a>
											</div>
										</td>
										{/* <td>{i?.symptom_name}</td> */}
										<td>
											{i?.history_generalremarkmodel?.name ||
												i?.remarks ||
												'-'}
										</td>
										<td>
											{(i?.image?.match(validImageTypes) ||
												i?.filename?.match(validImageTypes)) && (
												<Tooltips title='Print'>
													<Button
														isOutline={!darkModeStatus}
														color='primary'
														isLight={darkModeStatus}
														className={classNames('text-nowrap', {
															'border-light': !darkModeStatus,
														})}
														icon='Print'
														onClick={() => {
															navigate(
																`/patients/${id}/attachment-pdf`,
																{ state: i },
															);
														}}></Button>
												</Tooltips>
											)}
											&nbsp;
											<Tooltips title='Delete'>
												<Button
													isOutline={!darkModeStatus}
													color='primary'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Delete'
													onClick={() =>
														isPermitted(
															'Medical Profile',
															'delete',
															() =>
																dispatch(
																	deleteSoapAttachment(
																		i?.id,
																		id,
																		doctor_id,
																		i?.isSoapImage,
																	),
																),
														)
													}></Button>
											</Tooltips>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					) : (
						<div className='m-3 mx-5 text-muted fst-italic'>No Attachments Found</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PatientAttachment;
