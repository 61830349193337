import { SET_OPD_RECORDS, DELETE_OPD_RECORD } from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import moment from 'moment';
import { toggleCreateLoading, toggleTableLoading } from './componentActions';

export const getOpdRecordsByUserId = async (userId) => {
	try {
		const res = await axios.get('/opd', { params: { userId } });
		if (res.data.success) {
			return res.data.opds;
		}
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const getOpdRecords =
	(params = null) =>
	async (dispatch) => {
		// console.log(params);
		dispatch(toggleTableLoading(true));
		try {
			const res = await axios.get('/opd', { params: params });
			if (res.data.success) {
				dispatch({
					type: SET_OPD_RECORDS,
					payload: {
						records: res.data.opds.docs,
						pages: res.data.opds.pages,
						total: res.data.opds.total,
					},
				});
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const getOpdTransactionRecords = async () => {
	try {
		const res = await axios.get('/opd/organisations');
		if (res.data.success) {
			// dispatch({
			// 	type: SET_OPD_RECORDS,
			// 	payload: res.data.opds,
			// });
			return res.data.opds;
		}
	} catch (err) {
		console.log(err);
	}
};

export const createOpdRecord =
	(newRec, navigate, selectedOrg = null, patient = null, selectedDoctor = null) =>
	async (dispatch) => {
		dispatch(toggleCreateLoading(true));
		try {
			const res = await axios.post('/opd', newRec);
			if (res.data.success) {
				dispatch(
					getOpdRecords({
						startDate: moment().format('YYYY-MM-DD'),
						endDate: moment().format('YYYY-MM-DD'),
						pageNo: 1,
						limit: 10,
					}),
				);
				showNotification('Success', 'The record is created successfully', 'success');
				navigate(`/${dashboardMenu.opd.subMenu.viewOpdRecords.path}`);
				// if (selectedOrg?.userType == 'staff' && newRec?.registrationFee)
				// 	navigate(`/patients/${patient?.selfUserId}/opd-pdf`, {
				// 		state: {
				// 			patient,
				// 			payments: [
				// 				{
				// 					amountPaid: newRec?.registrationFee,
				// 					paymentMode: newRec?.paymentMode,
				// 					date: new Date(newRec?.date)?.toISOString?.(),
				// 					Opd: {
				// 						Vendor_detail: selectedDoctor?.Vendor_detail,
				// 					},
				// 				},
				// 			],
				// 		},
				// 	});
				// else navigate(`/${dashboardMenu.opd.subMenu.viewOpdRecords.path}`);
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const updateOpdRecord = (updateBody, navigate, params) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch('/opd', updateBody);
		if (res.data.success) {
			const res = await axios.get(`/opd/${updateBody.opdId}`);
			if (res.data.success && res.data.opd) {
				dispatch(getOpdRecords(params));
				showNotification('Success', 'The record is updated successfully', 'success');
				if (navigate) navigate(`/${dashboardMenu.opd.subMenu.viewOpdRecords.path}`);
			}
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteOpdRecord = (recordId) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.delete(`/opd/${recordId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_OPD_RECORD,
				payload: recordId,
			});
			showNotification('Success', 'The record has been deleted successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};
