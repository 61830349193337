import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';

import Card, {
	CardActions,
	CardBody,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import moment from 'moment';
import { invalidNums } from '../../../../helpers/helpers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { useNavigate } from 'react-router-dom';
import { createIPDTPA, getIPDTPA, deleteIPDTPA } from '../../../../actions/ipdPatientActions';
import { isPermitted } from '../../../../actions/helperActions';

const validatePayment = (values) => {
	const errors = {};

	if (!values.amount) {
		errors.amount = 'Required';
	}
	return errors;
};

const IPDTPA = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const ipdTPA = useSelector((state) => state.ipdPatient.tpa);
	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);

	const [addPaymentModal, setAddPaymentModal] = useState(false);
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);

	const formikPayment = useFormik({
		initialValues: {
			amount: '0',
			ipdId: ipdId,
		},
		validate: validatePayment,
		onSubmit: async (values) => {
			const data = { ...values };
			dispatch(createIPDTPA(data));
			formikPayment.resetForm();
			setAddPaymentModal(false);
		},
	});

	useEffect(() => {
		let mounted = true;
		const fetchOthers = async () => {
			if (mounted) {
				dispatch(getIPDTPA(ipdId));
			}
		};
		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card shadow='none'>
				<div className='d-flex justify-content-between px-4'>
					<CardLabel icon='Payments' className='ms-2' iconColor='primary'>
						<CardTitle>Payments</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							onClick={() =>
								isPermitted(
									'Ipd',
									'write',
									() => setAddPaymentModal(true),
									'Billing',
								)
							}
							icon='Add'
							color='primary'
							isOutline>
							Add TPA
						</Button>
					</CardActions>
				</div>
				<CardBody>
					<table className='table table-bordered'>
						<tbody>
							<tr>
								<th>S.No.</th>
								<th>Date</th>
								<th>Amount</th>
								<th>Actions</th>
							</tr>
							{ipdTPA?.map((i, idx) => (
								<tr>
									<td>{idx + 1}</td>
									<td>{moment(i.createdAt).format('DD MMM YYYY')}</td>
									<td>&#8377; {i.amount}</td>
									<td>
										<Tooltips title='Delete TPA'>
											<Button
												isLink
												color='danger'
												icon='Delete'
												onClick={() => {
													isPermitted(
														'Ipd',
														'write',
														() => {
															setDeleteItem({
																type: 'payment',
																id: i.id,
															});
															setDeleteWarn(true);
														},
														'Billing',
													);
												}}
											/>
										</Tooltips>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>

			{/* Add Modal */}
			<Modal
				isOpen={addPaymentModal}
				// setIsOpen={setAddPaymentModal}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setAddPaymentModal}>
					<ModalTitle id='deletePharmLabel'>Add TPA</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='date' label='Date' isFloating className='mb-2'>
						<Input
							type='date'
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Date'
							value={moment().format('YYYY-MM-DD')}
							disabled
						/>
					</FormGroup>
					<FormGroup id='amount' label='Amount' isFloating className='mb-2'>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Amount'
							onChange={formikPayment.handleChange}
							onBlur={formikPayment.handleBlur}
							value={formikPayment.values.amount}
							isValid={formikPayment.isValid}
							isTouched={formikPayment.touched.amount}
							invalidFeedback={formikPayment.errors.amount}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setAddPaymentModal(false)}>
						Cancel
					</Button>
					<Button color='primary' onClick={formikPayment.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal isOpen={deleteWarn} setIsOpen={setDeleteWarn} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deletePharmLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button
						color='danger'
						icon='delete'
						onClick={() => {
							dispatch(deleteIPDTPA(deleteItem?.id, ipdId));
							setDeleteWarn(false);
						}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default IPDTPA;
