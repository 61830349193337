import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import USERS from '../../common/data/userDummyData';
import Button from '../../components/bootstrap/Button';
import Input from '../../components/bootstrap/forms/Input';
import { staticUrl } from '../../helpers/helpers';
import Chat from '../../assets/img/chat-avatar.png';
import Header from './components/Header';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { isPermitted, createCCValue, createDiagnosis } from '../../actions/helperActions';
import {
	setCurrentPrescription,
	setMedAlertsModal,
	setPaniniPrescription,
	setSavePrescription,
} from '../../actions/paniniActions';
import { getCCValues } from '../../actions/historyActions';
import {
	createInvestigation,
	createNote,
	getDiagnoses,
	getInvestigations,
	createExamination,
} from '../../actions/settingActions';
import axios from 'axios';

const initialMsg = [
	{
		text:
			'The current date and time is ' +
			moment().format('DD/MM/yyyy') +
			' ' +
			moment().format('hh:mm:ss'),
		type: 'bot',
	},
	{
		text: `Hi, I am here to let you add Short Prescriptions at an ease. <br/> Enter 00 to go back <br/> <b>Enter .. to skip any field`,
		type: 'bot',
	},
	{
		text: 'Please enter Weight(in kg) and Height(in cm) separated by /',
		type: 'bot',
	},
	// {
	//     text : "Please enter Diagnosis of the patients",
	//     type : "bot"
	// }
];

export const Prescription = ({ handleWholeBotClose, handleGoBack, whichBot, user, UserAvatar }) => {
	const bottomRef = useRef(null);
	const dispatch = useDispatch();
	const inputRef = useRef(null);
	const [inputData, setInputData] = useState('');
	const [copied, setCopied] = useState(false);
	const [botFields, setBotFields] = useState({});
	const [loading, setLoading] = useState(true);
	const [messages, setMessages] = useState(initialMsg);
	const [multiValueType, setMultiValueType] = useState(null);
	const [renderValues, setRenderValues] = useState([]);
	const [multiValue, setMultiValue] = useState([]);
	const [viewCreate, setViewCreate] = useState(false);
	const [medicines, setMedicines] = useState([]);

	const examinations = useSelector((state) => state.setting.examinations);

	const { currentPrescription } = useSelector((state) => state.panini);
	const { chiefComplainsValues } = useSelector((state) => state.history);
	const diagnoses = useSelector((state) => state.setting.diagnoses).filter((i) => !i?.isAdmin);
	const investigations = useSelector((state) => state.setting.investigations);
	const notes = useSelector((state) => state.setting.notes);

	const userImgDir = selectedOrg?.userType === 'staff' ? 'staffs' : 'doctor-profile';

	const handleAddMessage = (data = '') => {
		if (data.trim() != '') {
			setMessages((prev) => [
				...prev,
				{
					text: data,
					type: 'user',
				},
			]);
			setInputData('');
			inputRef.current?.focus();
		} else if (inputData?.trim() != '') {
			setMessages((prev) => [
				...prev,
				{
					text: inputData,
					type: 'user',
				},
			]);
			setInputData('');
			setLoading(true);
		}
	};

	const appendBotMessageWithDelay = (texts) => {
		setTimeout(() => {
			texts.forEach((text) => {
				setMessages((prev) => [
					...prev,
					{
						text: text,
						type: 'bot',
					},
				]);
			});
			setLoading(false);
			inputRef.current?.focus();
		}, 1000);
	};
	const appendBotMessage = (msg) => {
		const appendBotMessageWithDelay = (texts) => {
			setTimeout(() => {
				texts.forEach((text) => {
					setMessages((prev) => [
						...prev,
						{
							text: text,
							type: 'bot',
						},
					]);
				});
				setLoading(false);
				inputRef.current?.focus();
			}, 1000);
		};
		const appendBotMessage = (msg) => {
			setMessages((prev) => [
				...prev,
				{
					text: msg,
					type: 'bot',
				},
			]);
		};

		const appendBotMessageWithType = (msgs) => {
			setLoading(true);
			for (let i = 0; i < msgs.length; i++) {
				setTimeout(() => {
					setMessages((prev) => [
						...prev,
						{
							text: msgs[i]?.text,
							type: msgs[i]?.type || 'bot',
						},
					]);
					if (i == msgs.length - 1) setLoading(false);
					inputRef.current?.focus();
				}, 1000 * (i + 1));
			}
		};

		const handleCopyMessage = (msg) => {
			navigator.clipboard.writeText(msg.text);
			setCopied(true);
		};

		const handleCopyChat = () => {
			let text = '';
			for (let i = 0; i < messages.length; i++) {
				if (messages[i].type === 'bot')
					text += 'Pãnini : ' + messages[i].text?.replace(/<\/?[^>]+(>|$)/g, '') + '\n';
				else text += 'You : ' + messages[i].text + '\n';
			}
			navigator.clipboard.writeText(text);
			setCopied(true);
		};

		const getLastBotMessage = () => {
			let obj = {};
			for (let i = messages.length - 1; i >= 0; i--) {
				if (messages[i]?.type == 'bot') {
					obj = messages[i];
					break;
				}
			}
			return obj;
		};

		let cancelToken;
		const handleFetchMedicines = async (inputVal, firstCall = false) => {
			if (!inputVal && !firstCall) setRenderValues([]);
			try {
				if (typeof cancelToken != typeof undefined) {
					cancelToken.cancel('Cancelling prev req');
				}
				cancelToken = axios.CancelToken.source();
				const res = await axios.get(
					`${process.env.REACT_APP_SERVER_URL}/doctor/medicine/suggestion?search=${
						inputVal || ''
					}`,
					{ cancelToken: cancelToken.token },
				);
				const meds = [
					...res.data?.inventories?.map((i) => ({
						isInventory: true,
						name: i?.name,
						color: 'orange',
						composition: i?.composition,
						quantity: `[${i?.Purchase_items?.[0]?.quantity || '0'}]`,
					})),
					...res.data?.medicines?.map((i) => ({
						name: i?.name,
						composition: i?.composition,
					})),
				];
				setRenderValues(meds || []);
				bottomRef.current?.scrollIntoView({
					top: bottomRef.current.scrollHeight,
					behavior: 'smooth',
					block: 'end',
				});
				bottomRef.current?.scrollIntoView({
					top: bottomRef.current.scrollHeight,
					behavior: 'smooth',
					block: 'end',
				});
			} catch (err) {
				console.log(err);
			}
		};

		const handleChangePrescription = (inputs) => {
			let prescription = {
				...currentPrescription,
				...inputs,
			};
			dispatch(setCurrentPrescription(prescription));
		};

		const setSelectMultiValue = (type) => {
			setMultiValueType(type);
			setMultiValue([]);
			switch (type) {
				case 'chiefComplain':
					setRenderValues(chiefComplainsValues);
					break;
				case 'medicines':
					setRenderValues([]);
					setMedicines([]);
					break;
				case 'testsRequested':
					setRenderValues(investigations);
					break;
				case 'spclInstructions':
					setRenderValues(notes.filter((i) => i.type === 'Special Instructions'));
					break;
				case 'provisionalDiagnosis':
				case 'diagnosis':
					setRenderValues(diagnoses);
					break;
				case 'exams':
					setRenderValues(examinations);
					break;
				default:
					setMultiValueType(null);
					setRenderValues([]);
					setViewCreate(false);
					break;
			}
		};

		const handleCreateMultiValue = async () => {
			setLoading(true);
			switch (multiValueType) {
				case 'chiefComplain':
					const resC = await createCCValue(inputData, user?.Vendor_detail?.vendorId);
					handleSelectMultipicker(resC);
					dispatch(getCCValues(user?.Vendor_detail?.vendorId));
					break;
				case 'testsRequested':
					dispatch(createInvestigation({ name: inputData }));
					handleSelectMultipicker({ name: inputData });
					dispatch(getInvestigations());
					break;
				case 'spclInstructions':
					dispatch(
						createNote({
							name: inputData,
							type: 'Special Instructions',
							text: inputData,
						}),
					);
					handleSelectMultipicker({ name: inputData });
					break;
				case 'provisionalDiagnosis':
				case 'diagnosis':
					await createDiagnosis(inputData);
					handleSelectMultipicker({ name: inputData });
					dispatch(getDiagnoses());
					break;
				case 'exams':
					dispatch(createExamination({ name: inputData }));
					handleSelectMultipicker({ name: inputData });
					break;
			}
			setLoading(false);
			inputRef.current?.focus();
		};

		const handleSelectMultipicker = (item) => {
			switch (multiValueType) {
				case 'medicines':
					setMultiValue((prev) => [...prev, item]);
					setInputData(`${item?.name}-${item?.composition};`);
					setMultiValueType('timing');
					setRenderValues([
						{ name: 'Before Meal' },
						{ name: 'After Meal' },
						{ name: 'Bed Time' },
						{ name: 'SOS' },
						{ name: 'Empty Stomach' },
					]);
					break;
				case 'timing':
					setInputData((prev) => prev + `${item.name};`);
					setMultiValueType('medNotes');
					setRenderValues([]);
					break;
				case 'medNotes':
					let input = inputData?.split(';');
					let append = '';
					for (let i = 0; i < input.length - 1; i++) {
						append += input[i] + ';';
					}
					setInputData(append + `${item.name}`);
					handleAddMessage(append + `${item?.name}`);
					setMultiValueType(null);
					setRenderValues([]);
					setViewCreate(false);
					break;
				default:
					setMultiValue((prev) => [...prev, item]);
					setMessages((prev) => [
						...prev,
						{
							text: item.name,
							type: 'user',
						},
					]);
					let prescription = { ...currentPrescription };
					prescription[multiValueType] = [...multiValue, item];
					dispatch(setCurrentPrescription(prescription));
					setInputData('');
			}
			inputRef.current?.focus();
		};

		function getYearsMonthsWeeksDays(numberOfDays) {
			var years = Math.floor(numberOfDays / 365);
			var months = Math.floor((numberOfDays % 365) / 30);
			var weeks = Math.floor(((numberOfDays % 365) % 30) / 7);
			var days = Math.floor((numberOfDays % 365) % 30);

			return { years, months, days, weeks };
		}

		useEffect(() => {
			if (copied) {
				setTimeout(() => {
					setCopied(false);
				}, 2000);
			}
		}, [copied]);
		useEffect(() => {
			if (copied) {
				setTimeout(() => {
					setCopied(false);
				}, 2000);
			}
		}, [copied]);

		useEffect(() => {
			setMessages(initialMsg);
		}, [whichBot]);
		useEffect(() => {
			setMessages(initialMsg);
		}, [whichBot]);

		useEffect(() => {
			if (!loading) {
				inputRef.current?.focus();
			}
		}, [loading]);

		useEffect(() => {
			const keyDownHandler = (event) => {
				if (event.key === 'Enter') {
					event.preventDefault();
					if (
						multiValueType &&
						!(multiValueType == 'medicines' || multiValueType == 'medNotes') &&
						inputData != '..'
					)
						handleCreateMultiValue();
					else handleAddMessage();
				}
			};

			document.addEventListener('keydown', keyDownHandler);

			return () => {
				document.removeEventListener('keydown', keyDownHandler);
			};
		}, [inputData]);
		useEffect(() => {
			if (!loading) {
				inputRef.current?.focus();
			}
		}, [loading]);

		useEffect(() => {
			const keyDownHandler = (event) => {
				if (event.key === 'Enter') {
					event.preventDefault();
					if (
						multiValueType &&
						!(multiValueType == 'medicines' || multiValueType == 'medNotes') &&
						inputData != '..'
					)
						handleCreateMultiValue();
					else handleAddMessage();
				}
			};

			document.addEventListener('keydown', keyDownHandler);

			return () => {
				document.removeEventListener('keydown', keyDownHandler);
			};
		}, [inputData]);

		useEffect(() => {
			bottomRef.current?.scrollIntoView({
				top: bottomRef.current.scrollHeight,
				behavior: 'smooth',
				block: 'end',
			});
		}, [loading, renderValues]);
		useEffect(() => {
			bottomRef.current?.scrollIntoView({
				top: bottomRef.current.scrollHeight,
				behavior: 'smooth',
				block: 'end',
			});
		}, [loading, renderValues]);

		useEffect(() => {
			// bottomRef.current?.scrollIntoView({behavior: 'smooth'});
			// console.log(currentPrescription, "currentPrescription");
			bottomRef.current?.scrollIntoView({
				top: bottomRef.current.scrollHeight,
				behavior: 'smooth',
				block: 'end',
			});
			if (messages.length > 0) {
				if (messages[messages.length - 1].type === 'user') {
					if (messages[messages.length - 1].text == '01') {
						setMessages(initialMsg);
						setSelectMultiValue(null);
						dispatch(setCurrentPrescription({}));
						setTimeout(() => {
							setLoading(false);
							inputRef.current?.focus();
						}, 500);
						return;
					}
					if (messages[messages.length - 1].text == '00') {
						handleGoBack();
						setLoading(false);
					}
					if (
						getLastBotMessage().text?.includes(
							'Weight(in kg) and Height(in cm) separated by /',
						)
					) {
						if (messages[messages.length - 1].text?.includes('/')) {
							let weight = parseFloat(
								messages[messages.length - 1].text.split('/')[0],
							);
							let height = parseFloat(
								messages[messages.length - 1].text.split('/')[1],
							);
							setBotFields((prev) => ({ ...prev, weight, height }));
							let inputs = { ...botFields, weight, height };
							handleChangePrescription(inputs);
							let array = [];
							const BMI = weight / (height / 100) ** 2;
							const BSA = Math.sqrt((height * weight) / 3600);
							array.push({ text: `BMI : ${BMI?.toFixed(2)}`, type: 'result' });
							array.push({ text: `BSA : ${BSA?.toFixed(2)}`, type: 'result' });
							array.push({
								text: 'Please enter BP systolic and BP diastolic separated by / ',
							});
							appendBotMessageWithType(array);
							// appendBotMessageWithDelay(["Please enter BP systolic and BP diastolic separated by / "])
						} else if (messages[messages.length - 1].text == '..')
							appendBotMessageWithDelay([
								'Please enter BP systolic and BP diastolic separated by / ',
							]);
						else
							appendBotMessageWithDelay([
								'Please enter Weight(in kg) and Height(in cm) separated by /',
							]);
					} else if (
						getLastBotMessage().text?.includes(
							'BP systolic and BP diastolic separated by / ',
						)
					) {
						if (messages[messages.length - 1].text?.includes('/')) {
							let bpSystolic = parseFloat(
								messages[messages.length - 1].text.split('/')[0],
							);
							let bpDiastolic = parseFloat(
								messages[messages.length - 1].text.split('/')[1],
							);
							let inputs = { ...botFields, bpDiastolic, bpSystolic };
							handleChangePrescription(inputs);
							// appendBotMessageWithDelay(["Please enter Pulse Rate and SPO₂ separated by /"])
							appendBotMessageWithDelay([
								'Please enter Chief Complains of the patients',
							]);
							setSelectMultiValue('chiefComplain');
						} else if (messages[messages.length - 1].text == '..')
							appendBotMessageWithDelay([
								'Please enter Pulse Rate and SPO₂ separated by /',
							]);
						else
							appendBotMessageWithDelay([
								'Please enter BP systolic and BP diastolic separated by / ',
							]);
					} else if (
						getLastBotMessage().text?.includes('Pulse Rate and SPO₂ separated by /')
					) {
						if (messages[messages.length - 1].text?.includes('/')) {
							let pulse = parseFloat(
								messages[messages.length - 1].text.split('/')[0],
							);
							let spo2 = parseFloat(messages[messages.length - 1].text.split('/')[1]);
							let inputs = { ...botFields, pulse, spo2, chiefComplain: [] };
							handleChangePrescription(inputs);
							appendBotMessageWithDelay([
								'Please enter Chief Complains of the patients',
							]);
							setSelectMultiValue('chiefComplain');
						} else if (messages[messages.length - 1].text == '..') {
							appendBotMessageWithDelay([
								'Please enter Chief Complains of the patients :',
							]);
							let inputs = { ...botFields, chiefComplain: [] };
							handleChangePrescription(inputs);
							setSelectMultiValue('chiefComplain');
						} else
							appendBotMessageWithDelay([
								'Please enter Chief Complains of the patients :',
							]);
					} else if (
						getLastBotMessage()?.text?.includes('Chief Complains of the patients')
					) {
						if (messages[messages.length - 1].text == '..') {
							handleChangePrescription({ ...botFields, exams: [] });
							appendBotMessageWithDelay([
								'Please enter Examinations of the patients :',
							]);
							setSelectMultiValue('exams');
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 2000);
						}
					} else if (
						getLastBotMessage()?.text?.includes(
							'Please enter Examinations of the patients :',
						)
					) {
						if (messages[messages.length - 1].text == '..') {
							handleChangePrescription({ ...botFields, notes: '' });
							appendBotMessageWithDelay(['Please type notes for the patient:']);
							setSelectMultiValue(null);
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 2000);
						}
					} else if (
						getLastBotMessage()?.text?.includes(
							'Examinations of the patients separated by ;',
						)
					) {
						if (messages[messages.length - 1].text?.includes(';')) {
							let array = messages[messages.length - 1].text.split(';');
							let exams = [];
							for (let i = 0; i < array?.length; i++) {
								let obj = {
									name: array[i],
									id: i + 1,
								};
								exams.push(obj);
							}
							let inputs = { ...botFields, exams, notes: '' };
							handleChangePrescription(inputs);
							appendBotMessageWithDelay(['Please type notes for the patient:']);
						} else if (messages[messages.length - 1].text == '..') {
							appendBotMessageWithDelay(['Please type notes for the patient:']);
							setSelectMultiValue(null);
						} else
							appendBotMessageWithDelay([
								'Please enter Examinations of the patients separated by ;',
							]);
					} else if (getLastBotMessage()?.text?.includes('type notes for the patient')) {
						if (messages[messages.length - 1].text != '..') {
							let notes = messages[messages.length - 1]?.text;
							handleChangePrescription({ ...botFields, notes, diagnoses: [] });
						}
						appendBotMessageWithDelay(['Please enter Diagnosis of the patients']);
						setSelectMultiValue('diagnosis');
					} else if (
						getLastBotMessage()?.text?.includes('enter Diagnosis of the patients')
					) {
						if (messages[messages.length - 1].text == '..') {
							handleChangePrescription({ ...botFields, medicines: [] });
							appendBotMessageWithDelay([
								'Please select Medicines for the patients as Medicine; Timing; Frequency; Duration (in Days); Notes',
							]);
							setSelectMultiValue('medicines');
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 2000);
						}
					} else if (
						getLastBotMessage()?.text?.includes('select Medicines for the patients')
					) {
						if (messages[messages.length - 1].text == '..') {
							handleChangePrescription({ ...botFields, investigations: [] });
							appendBotMessageWithDelay(['Please enter Tests for the patients']);
							setTimeout(() => {
								setSelectMultiValue('testsRequested');
							}, 1000);
						} else if (messages[messages.length - 1].text?.includes(';')) {
							let array = messages[messages.length - 1].text.split(';');
							let currMed = multiValue?.find(
								(item) => item.name == array[0]?.split('-')[0],
							);
							setMultiValueType('medicines');
							setTimeout(() => {
								setLoading(false);
							}, 2000);
							useEffect(() => {
								// bottomRef.current?.scrollIntoView({behavior: 'smooth'});
								// console.log(currentPrescription, "currentPrescription");
								bottomRef.current?.scrollIntoView({
									top: bottomRef.current.scrollHeight,
									behavior: 'smooth',
									block: 'end',
								});
								if (messages.length > 0) {
									if (messages[messages.length - 1].type === 'user') {
										if (messages[messages.length - 1].text == '01') {
											setMessages(initialMsg);
											setSelectMultiValue(null);
											dispatch(setCurrentPrescription({}));
											setTimeout(() => {
												setLoading(false);
												inputRef.current?.focus();
											}, 500);
											return;
										}
										if (messages[messages.length - 1].text == '00') {
											handleGoBack();
											setLoading(false);
										}
										if (
											getLastBotMessage().text?.includes(
												'Weight(in kg) and Height(in cm) separated by /',
											)
										) {
											if (messages[messages.length - 1].text?.includes('/')) {
												let weight = parseFloat(
													messages[messages.length - 1].text.split(
														'/',
													)[0],
												);
												let height = parseFloat(
													messages[messages.length - 1].text.split(
														'/',
													)[1],
												);
												setBotFields((prev) => ({
													...prev,
													weight,
													height,
												}));
												let inputs = { ...botFields, weight, height };
												handleChangePrescription(inputs);
												let array = [];
												const BMI = weight / (height / 100) ** 2;
												const BSA = Math.sqrt((height * weight) / 3600);
												array.push({
													text: `BMI : ${BMI?.toFixed(2)}`,
													type: 'result',
												});
												array.push({
													text: `BSA : ${BSA?.toFixed(2)}`,
													type: 'result',
												});
												array.push({
													text: 'Please enter BP systolic and BP diastolic separated by / ',
												});
												appendBotMessageWithType(array);
												// appendBotMessageWithDelay(["Please enter BP systolic and BP diastolic separated by / "])
											} else if (messages[messages.length - 1].text == '..')
												appendBotMessageWithDelay([
													'Please enter BP systolic and BP diastolic separated by / ',
												]);
											else
												appendBotMessageWithDelay([
													'Please enter Weight(in kg) and Height(in cm) separated by /',
												]);
										} else if (
											getLastBotMessage().text?.includes(
												'BP systolic and BP diastolic separated by / ',
											)
										) {
											if (messages[messages.length - 1].text?.includes('/')) {
												let bpSystolic = parseFloat(
													messages[messages.length - 1].text.split(
														'/',
													)[0],
												);
												let bpDiastolic = parseFloat(
													messages[messages.length - 1].text.split(
														'/',
													)[1],
												);
												let inputs = {
													...botFields,
													bpDiastolic,
													bpSystolic,
												};
												handleChangePrescription(inputs);
												// appendBotMessageWithDelay(["Please enter Pulse Rate and SPO₂ separated by /"])
												appendBotMessageWithDelay([
													'Please enter Chief Complains of the patients',
												]);
												setSelectMultiValue('chiefComplain');
											} else if (messages[messages.length - 1].text == '..')
												appendBotMessageWithDelay([
													'Please enter Pulse Rate and SPO₂ separated by /',
												]);
											else
												appendBotMessageWithDelay([
													'Please enter BP systolic and BP diastolic separated by / ',
												]);
										} else if (
											getLastBotMessage().text?.includes(
												'Pulse Rate and SPO₂ separated by /',
											)
										) {
											if (messages[messages.length - 1].text?.includes('/')) {
												let pulse = parseFloat(
													messages[messages.length - 1].text.split(
														'/',
													)[0],
												);
												let spo2 = parseFloat(
													messages[messages.length - 1].text.split(
														'/',
													)[1],
												);
												let inputs = {
													...botFields,
													pulse,
													spo2,
													chiefComplain: [],
												};
												handleChangePrescription(inputs);
												appendBotMessageWithDelay([
													'Please enter Chief Complains of the patients',
												]);
												setSelectMultiValue('chiefComplain');
											} else if (messages[messages.length - 1].text == '..') {
												appendBotMessageWithDelay([
													'Please enter Chief Complains of the patients :',
												]);
												let inputs = { ...botFields, chiefComplain: [] };
												handleChangePrescription(inputs);
												setSelectMultiValue('chiefComplain');
											} else
												appendBotMessageWithDelay([
													'Please enter Chief Complains of the patients :',
												]);
										} else if (
											getLastBotMessage()?.text?.includes(
												'Chief Complains of the patients',
											)
										) {
											if (messages[messages.length - 1].text == '..') {
												handleChangePrescription({
													...botFields,
													exams: [],
												});
												appendBotMessageWithDelay([
													'Please enter Examinations of the patients :',
												]);
												setSelectMultiValue('exams');
											} else {
												setTimeout(() => {
													setLoading(false);
												}, 2000);
											}
										} else if (
											getLastBotMessage()?.text?.includes(
												'Please enter Examinations of the patients :',
											)
										) {
											if (messages[messages.length - 1].text == '..') {
												handleChangePrescription({
													...botFields,
													notes: '',
												});
												appendBotMessageWithDelay([
													'Please type notes for the patient:',
												]);
												setSelectMultiValue(null);
											} else {
												setTimeout(() => {
													setLoading(false);
												}, 2000);
											}
										} else if (
											getLastBotMessage()?.text?.includes(
												'Examinations of the patients separated by ;',
											)
										) {
											if (messages[messages.length - 1].text?.includes(';')) {
												let array =
													messages[messages.length - 1].text.split(';');
												let exams = [];
												for (let i = 0; i < array?.length; i++) {
													let obj = {
														name: array[i],
														id: i + 1,
													};
													exams.push(obj);
												}
												let inputs = { ...botFields, exams, notes: '' };
												handleChangePrescription(inputs);
												appendBotMessageWithDelay([
													'Please type notes for the patient:',
												]);
											} else if (messages[messages.length - 1].text == '..') {
												appendBotMessageWithDelay([
													'Please type notes for the patient:',
												]);
												setSelectMultiValue(null);
											} else
												appendBotMessageWithDelay([
													'Please enter Examinations of the patients separated by ;',
												]);
										} else if (
											getLastBotMessage()?.text?.includes(
												'type notes for the patient',
											)
										) {
											if (messages[messages.length - 1].text != '..') {
												let notes = messages[messages.length - 1]?.text;
												handleChangePrescription({
													...botFields,
													notes,
													diagnoses: [],
												});
											}
											appendBotMessageWithDelay([
												'Please enter Diagnosis of the patients',
											]);
											setSelectMultiValue('diagnosis');
										} else if (
											getLastBotMessage()?.text?.includes(
												'enter Diagnosis of the patients',
											)
										) {
											if (messages[messages.length - 1].text == '..') {
												handleChangePrescription({
													...botFields,
													medicines: [],
												});
												appendBotMessageWithDelay([
													'Please select Medicines for the patients as Medicine; Timing; Frequency; Duration (in Days); Notes',
												]);
												setSelectMultiValue('medicines');
											} else {
												setTimeout(() => {
													setLoading(false);
												}, 2000);
											}
										} else if (
											getLastBotMessage()?.text?.includes(
												'select Medicines for the patients',
											)
										) {
											if (messages[messages.length - 1].text == '..') {
												handleChangePrescription({
													...botFields,
													investigations: [],
												});
												appendBotMessageWithDelay([
													'Please enter Tests for the patients',
												]);
												setTimeout(() => {
													setSelectMultiValue('testsRequested');
												}, 1000);
											} else if (
												messages[messages.length - 1].text?.includes(';')
											) {
												let array =
													messages[messages.length - 1].text.split(';');
												let currMed = multiValue?.find(
													(item) => item.name == array[0]?.split('-')[0],
												);
												setMultiValueType('medicines');
												setTimeout(() => {
													setLoading(false);
												}, 2000);

												let timing = array.length > 1 ? array[1] : '';
												let freq = array.length > 2 ? array[2] : '';
												let days = array.length > 3 ? array[3] : 0;
												let notes = array.length > 4 ? array[4] : '';
												days = parseInt(days);
												if (isNaN(days)) days = 0;
												let result = '';
												if (freq != '') {
													while (freq.length > 0) {
														result =
															result + '-' + freq.substring(0, 1); // Insert space character
														freq = freq.substring(1); // Trim String
													}
												}
												result = result.replace('-', '');
												var { years, months, weeks } =
													getYearsMonthsWeeksDays(days);
												let duration = `${days} days`;
												if (years > 0) {
													duration = years + ' years ';
												} else if (months > 0) {
													duration = months + ' months ';
												} else if (weeks > 0) {
													duration = weeks + ' weeks ';
												}

												let obj = {
													medicineName: {
														label: currMed?.name || array[0],
														value: currMed || array[0],
													},
													frequency: {
														label: result,
														value: result,
													},
													duration: duration,
													notes: {
														label: notes,
														value: notes,
													},
													timing: {
														label: timing,
														value: timing,
													},
												};
												let input = [...medicines, obj];
												setMedicines(input);
												handleChangePrescription({
													...botFields,
													medicines: input,
												});
											} else {
												setTimeout(() => {
													setLoading(false);
												}, 2000);
											}
										} else if (
											getLastBotMessage()?.text?.includes(
												'enter Tests for the patients',
											)
										) {
											if (messages[messages.length - 1].text == '..') {
												handleChangePrescription({
													...botFields,
													specialInstructions: [],
												});
												appendBotMessageWithDelay([
													'Please write Special Instrunctions for the patients',
												]);
												setSelectMultiValue('spclInstructions');
											} else {
												setTimeout(() => {
													setLoading(false);
												}, 2000);
											}
										} else if (
											getLastBotMessage()?.text?.includes(
												'write Special Instrunctions for the patients',
											)
										) {
											if (messages[messages.length - 1].text == '..') {
												handleChangePrescription({
													...botFields,
													advice: [],
												});
												appendBotMessageWithDelay([
													'Please type next visit for the patient (in days)',
												]);
												setSelectMultiValue(null);
											} else {
												setTimeout(() => {
													setLoading(false);
												}, 2000);
											}
										} else if (
											getLastBotMessage()?.text?.includes(
												'type next visit for the patient',
											)
										) {
											if (messages[messages.length - 1].text != '..') {
												let nextVisit = parseInt(
													messages[messages.length - 1].text,
												);
												if (nextVisit > 0) {
													// var date = new Date();
													// date.setDate(date.getDate() + nextVisit);
													nextVisit = nextVisit + ' days';
													let input = { ...botFields, nextVisit };
													handleChangePrescription(input);
												}
											}
											appendBotMessageWithDelay([
												'Type Save to save the prescription, 01 to restart and 00 to go back',
											]);
										} else if (
											getLastBotMessage()?.text?.includes(
												'Type Save to save the prescription',
											)
										) {
											if (
												messages[messages.length - 1].text
													?.toLowerCase()
													?.includes('save')
											) {
												dispatch(setSavePrescription(true));
												setTimeout(() => {
													setLoading(false);
													handleWholeBotClose();
													dispatch(setPaniniPrescription(false, false));
												}, 2000);
											} else {
												setTimeout(() => {
													setLoading(false);
												}, 2000);
											}
										}
										// else appendBotMessageWithDelay(["Press 01 to restart and 00 to go back!"])
									}
								}
							}, [messages]);
							let obj = {
								medicineName: {
									label: currMed?.name || array[0],
									value: currMed || array[0],
								},
								frequency: {
									label: result,
									value: result,
								},
								duration: duration,
								notes: {
									label: notes,
									value: notes,
								},
								timing: {
									label: timing,
									value: timing,
								},
							};
							let input = [...medicines, obj];
							setMedicines(input);
							handleChangePrescription({ ...botFields, medicines: input });
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 2000);
						}
					} else if (
						getLastBotMessage()?.text?.includes('enter Tests for the patients')
					) {
						if (messages[messages.length - 1].text == '..') {
							handleChangePrescription({ ...botFields, specialInstructions: [] });
							appendBotMessageWithDelay([
								'Please write Special Instrunctions for the patients',
							]);
							setSelectMultiValue('spclInstructions');
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 2000);
						}
					} else if (
						getLastBotMessage()?.text?.includes(
							'write Special Instrunctions for the patients',
						)
					) {
						if (messages[messages.length - 1].text == '..') {
							handleChangePrescription({ ...botFields, advice: [] });
							appendBotMessageWithDelay([
								'Please type next visit for the patient (in days)',
							]);
							setSelectMultiValue(null);
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 2000);
						}
					} else if (
						getLastBotMessage()?.text?.includes('type next visit for the patient')
					) {
						if (messages[messages.length - 1].text != '..') {
							let nextVisit = parseInt(messages[messages.length - 1].text);
							if (nextVisit > 0) {
								// var date = new Date();
								// date.setDate(date.getDate() + nextVisit);
								nextVisit = nextVisit + ' days';
								let input = { ...botFields, nextVisit };
								handleChangePrescription(input);
							}
						}
						appendBotMessageWithDelay([
							'Type Save to save the prescription, 01 to restart and 00 to go back',
						]);
					} else if (
						getLastBotMessage()?.text?.includes('Type Save to save the prescription')
					) {
						if (messages[messages.length - 1].text?.toLowerCase()?.includes('save')) {
							dispatch(setSavePrescription(true));
							setTimeout(() => {
								setLoading(false);
								handleWholeBotClose();
								dispatch(setPaniniPrescription(false, false));
							}, 2000);
						} else {
							setTimeout(() => {
								setLoading(false);
							}, 2000);
						}
					}
					// else appendBotMessageWithDelay(["Press 01 to restart and 00 to go back!"])
				}
			}
		}, [messages]);

		// useEffect(()=>{
		//     setTimeout(()=>{
		//         setLoading(false);
		//     },1000)
		//     setMultiValueType(null);
		//     setViewCreate(false);
		// },[])
		// useEffect(()=>{
		//     setTimeout(()=>{
		//         setLoading(false);
		//     },1000)
		//     setMultiValueType(null);
		//     setViewCreate(false);
		// },[])

		useEffect(() => {
			let mounted = true;
			const appendInitialBotMessage = async () => {
				if (mounted) {
					setMessages([]);
					setLoading(true);
					for (let i = 0; i < initialMsg.length; i++) {
						if (initialMsg[i].type === 'bot') {
							setTimeout(() => {
								appendBotMessage(initialMsg[i].text);
								if (i == initialMsg.length - 1) {
									setLoading(false);
									inputRef.current?.focus();
									setMultiValueType(null);
									setViewCreate(false);
								}
							}, 500 * (i + 1));
						}
					}
				}
			};
			appendInitialBotMessage();
			return () => {
				mounted = false;
			};
		}, []);
		useEffect(() => {
			let mounted = true;
			const appendInitialBotMessage = async () => {
				if (mounted) {
					setMessages([]);
					setLoading(true);
					for (let i = 0; i < initialMsg.length; i++) {
						if (initialMsg[i].type === 'bot') {
							setTimeout(() => {
								appendBotMessage(initialMsg[i].text);
								if (i == initialMsg.length - 1) {
									setLoading(false);
									inputRef.current?.focus();
									setMultiValueType(null);
									setViewCreate(false);
								}
							}, 500 * (i + 1));
						}
					}
				}
			};
			appendInitialBotMessage();
			return () => {
				mounted = false;
			};
		}, []);

		useEffect(() => {
			if (multiValueType != null) {
				if (inputData != '') {
					setViewCreate(true);
					switch (multiValueType) {
						case 'chiefComplain':
							let array = [...chiefComplainsValues];
							let arr = array.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							// if(arr.length == 0) setViewCreate(true)
							setRenderValues(arr);
							break;
						case 'medicines':
							if (!inputData?.includes(';')) handleFetchMedicines(inputData);
							else if (inputData?.includes(';')) setMultiValueType('timing');
							break;
						case 'medNotes':
							if (inputData?.includes(';')) {
								let split = inputData?.split(';');
								if (split.length == 5) {
									let medNotes = notes?.filter(
										(i) => i.type === 'Prescription Medicine',
									);
									medNotes = medNotes?.filter((item) =>
										item.name?.toLowerCase()?.includes(split[4]?.toLowerCase()),
									);
									setRenderValues(medNotes);
								} else if (split.length == 4) {
									setRenderValues([]);
								}
							} else {
								setMultiValueType('medicines');
							}
							break;
						case 'exams':
							let array4 = [...examinations];
							let arr4 = array4.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr4);
							break;
						case 'testsRequested':
							let array2 = [...investigations];
							let arr2 = array2.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr2);
							break;
						case 'spclInstructions':
							let array3 = notes.filter((i) => i.type === 'Special Instructions');
							let arr3 = array3.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr3);
							break;
						case 'provisionalDiagnosis':
						case 'diagnosis':
							let array1 = [...diagnoses];
							let arr1 = array1.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr1);
							break;
					}
				} else {
					setViewCreate(false);
					switch (multiValueType) {
						case 'chiefComplain':
							setRenderValues(chiefComplainsValues);
							break;
						case 'medicines':
							setRenderValues([]);
							break;
						case 'medNotes':
							if (inputData?.includes(';')) {
								let split = inputData?.split(';');
								if (split.length == 5) {
									let medNotes = notes?.filter(
										(i) => i.type === 'Prescription Medicine',
									);
									setRenderValues(medNotes);
								}
							}
							break;
						case 'testsRequested':
							setRenderValues(investigations);
							break;
						case 'exams':
							setRenderValues(examinations);
							break;
						case 'spclInstructions':
							setRenderValues(notes.filter((i) => i.type === 'Special Instructions'));
							break;
						case 'provisionalDiagnosis':
						case 'diagnosis':
							setRenderValues(diagnoses);
							break;
					}
				}
			}
		}, [inputData]);
		useEffect(() => {
			if (multiValueType != null) {
				if (inputData != '') {
					setViewCreate(true);
					switch (multiValueType) {
						case 'chiefComplain':
							let array = [...chiefComplainsValues];
							let arr = array.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							// if(arr.length == 0) setViewCreate(true)
							setRenderValues(arr);
							break;
						case 'medicines':
							if (!inputData?.includes(';')) handleFetchMedicines(inputData);
							else if (inputData?.includes(';')) setMultiValueType('timing');
							break;
						case 'medNotes':
							if (inputData?.includes(';')) {
								let split = inputData?.split(';');
								if (split.length == 5) {
									let medNotes = notes?.filter(
										(i) => i.type === 'Prescription Medicine',
									);
									medNotes = medNotes?.filter((item) =>
										item.name?.toLowerCase()?.includes(split[4]?.toLowerCase()),
									);
									setRenderValues(medNotes);
								} else if (split.length == 4) {
									setRenderValues([]);
								}
							} else {
								setMultiValueType('medicines');
							}
							break;
						case 'exams':
							let array4 = [...examinations];
							let arr4 = array4.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr4);
							break;
						case 'testsRequested':
							let array2 = [...investigations];
							let arr2 = array2.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr2);
							break;
						case 'spclInstructions':
							let array3 = notes.filter((i) => i.type === 'Special Instructions');
							let arr3 = array3.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr3);
							break;
						case 'provisionalDiagnosis':
						case 'diagnosis':
							let array1 = [...diagnoses];
							let arr1 = array1.filter((item) =>
								item.name?.toLowerCase()?.includes(inputData?.toLowerCase()),
							);
							setRenderValues(arr1);
							break;
					}
				} else {
					setViewCreate(false);
					switch (multiValueType) {
						case 'chiefComplain':
							setRenderValues(chiefComplainsValues);
							break;
						case 'medicines':
							setRenderValues([]);
							break;
						case 'medNotes':
							if (inputData?.includes(';')) {
								let split = inputData?.split(';');
								if (split.length == 5) {
									let medNotes = notes?.filter(
										(i) => i.type === 'Prescription Medicine',
									);
									setRenderValues(medNotes);
								}
							}
							break;
						case 'testsRequested':
							setRenderValues(investigations);
							break;
						case 'exams':
							setRenderValues(examinations);
							break;
						case 'spclInstructions':
							setRenderValues(notes.filter((i) => i.type === 'Special Instructions'));
							break;
						case 'provisionalDiagnosis':
						case 'diagnosis':
							setRenderValues(diagnoses);
							break;
					}
				}
			}
		}, [inputData]);

		return (
			<div className='chatbot-tile bg-transparent prescription-chatbot'>
				<div className='header-prescription-hover'>
					<Header
						handleCopyChat={handleCopyChat}
						handleWholeBotClose={handleWholeBotClose}
						handleGoBack={handleGoBack}
						className={'bg-transparent-pres'}
					/>
				</div>
				<div className='fields bg-transparent ht-350'>
					{messages.map((message, index) => (
						<div
							key={index}
							className={
								message?.type == 'user' ? 'message df-jcfe' : 'message df-jcfs'
							}>
							{message?.type == 'bot' || message?.type == 'result' ? (
								<div className='user-avtar'>
									<img src={Chat} alt='bot' />
								</div>
							) : (
								<Button
									className='copy-message'
									icon='Clipboard'
									onClick={() => {
										handleCopyMessage(message);
									}}
								/>
							)}
							<div
								className={
									message.type === 'bot'
										? 'bot-message'
										: message.type == 'result'
										? 'result-message'
										: 'user-message'
								}
								key={index}
								dangerouslySetInnerHTML={{ __html: message.text }}></div>
							{message?.type == 'user' && (
								<UserAvatar
									srcSet={
										user?.Vendor_detail?.profileImage &&
										`${staticUrl}/${userImgDir}/${user?.Vendor_detail.profileImage}`
									}
									src={USERS.JOHN.src}
								/>
							)}
						</div>
					))}
					{loading && (
						<>
							<div className='message df-jcfs'>
								<div className='user-avtar'>
									<img src={Chat} alt='bot' />
								</div>
								<div className={'bot-message loading-div'}>
									<p className='loading'></p>
								</div>
							</div>
						</>
					)}
					<div className='multi-picker'>
						{multiValueType != null &&
							!loading &&
							renderValues?.slice(0, 10)?.map((item, idx) => (
								<>
									<Button
										className='inline-buttons p-2 suggestion-buttons'
										onClick={() => {
											handleSelectMultipicker(item);
										}}>
										{item?.name}{' '}
										{multiValueType == 'medicines' &&
											item.composition &&
											`- ${item?.composition}`}
									</Button>
								</>
							))}
						{viewCreate &&
							!loading &&
							inputData != '..' &&
							!inputData?.includes(';') &&
							multiValueType != 'medicines' &&
							renderValues.length == 0 && (
								<>
									<Button
										className='inline-buttons p-2'
										onClick={() => {
											handleCreateMultiValue();
										}}>
										Create {inputData}
									</Button>
								</>
							)}
					</div>
					<div className='ref-div' ref={bottomRef} />
				</div>
				<div className='bottom-input'>
					<Input
						placeholder='Type your message here'
						onChange={(e) => {
							setInputData(e.target.value);
						}}
						value={inputData}
						type='text'
						disabled={loading}
						autoFocus
						ref={inputRef}
					/>
					<Button className='send-btn' onClick={handleAddMessage}>
						Send
					</Button>
				</div>
				{copied && <div className='copied-text'>Data copied to clipboard!</div>}
			</div>
		);
	};
	return (
		<div className='chatbot-tile bg-transparent prescription-chatbot'>
			<div className='header-prescription-hover'>
				<Header
					handleCopyChat={handleCopyChat}
					handleWholeBotClose={handleWholeBotClose}
					handleGoBack={handleGoBack}
					className={'bg-transparent-pres'}
				/>
			</div>
			<div className='fields bg-transparent ht-350'>
				{messages.map((message, index) => (
					<div
						key={index}
						className={message?.type == 'user' ? 'message df-jcfe' : 'message df-jcfs'}>
						{message?.type == 'bot' || message?.type == 'result' ? (
							<div className='user-avtar'>
								<img src={Chat} alt='bot' />
							</div>
						) : (
							<Button
								className='copy-message'
								icon='Clipboard'
								onClick={() => {
									handleCopyMessage(message);
								}}
							/>
						)}
						<div
							className={
								message.type === 'bot'
									? 'bot-message'
									: message.type == 'result'
									? 'result-message'
									: 'user-message'
							}
							key={index}
							dangerouslySetInnerHTML={{ __html: message.text }}></div>
						{message?.type == 'user' && (
							<UserAvatar
								srcSet={
									user?.Vendor_detail?.profileImage &&
									`${staticUrl}/${userImgDir}/${user?.Vendor_detail.profileImage}`
								}
								src={USERS.JOHN.src}
							/>
						)}
					</div>
				))}
				{loading && (
					<>
						<div className='message df-jcfs'>
							<div className='user-avtar'>
								<img src={Chat} alt='bot' />
							</div>
							<div className={'bot-message loading-div'}>
								<p className='loading'></p>
							</div>
						</div>
					</>
				)}
				<div className='multi-picker'>
					{multiValueType != null &&
						!loading &&
						renderValues?.slice(0, 10)?.map((item, idx) => (
							<>
								<Button
									className='inline-buttons p-2 suggestion-buttons'
									onClick={() => {
										handleSelectMultipicker(item);
									}}>
									{item?.name}{' '}
									{multiValueType == 'medicines' &&
										item.composition &&
										`- ${item?.composition}`}
								</Button>
							</>
						))}
					{viewCreate &&
						!loading &&
						inputData != '..' &&
						!inputData?.includes(';') &&
						multiValueType != 'medicines' &&
						renderValues.length == 0 && (
							<>
								<Button
									className='inline-buttons p-2'
									onClick={() => {
										handleCreateMultiValue();
									}}>
									Create {inputData}
								</Button>
							</>
						)}
				</div>
				<div className='ref-div' ref={bottomRef} />
			</div>
			<div className='bottom-input'>
				<Input
					placeholder='Type your message here'
					onChange={(e) => {
						setInputData(e.target.value);
					}}
					value={inputData}
					type='text'
					disabled={loading}
					autoFocus
					ref={inputRef}
				/>
				<Button className='send-btn' onClick={handleAddMessage}>
					Send
				</Button>
			</div>
			{copied && <div className='copied-text'>Data copied to clipboard!</div>}
		</div>
	);
};

export const PrescriptionBot = ({
	handleWholeBotClose,
	loading,
	setPrescription,
	setWhichBot,
	setCurrentCategory,
}) => {
	const dispatch = useDispatch();
	const permissions = useSelector((state) => state.profile.permissions);
	const user = useSelector((state) => state.auth.user);

	const handlePrescription = () => {
		dispatch(setPaniniPrescription(false, true));
		dispatch(setCurrentPrescription({}));
		setWhichBot('prescription');
	};

	const handlePrescriptionVoice = () => {
		dispatch(setPaniniPrescription(false, true));
		dispatch(setCurrentPrescription({}));
		setWhichBot('prescriptionVoice');
	};

	return (
		<div className='chatbot-tile'>
			<div className='header-chat df-jcsb'>
				<div className='name' style={{ fontSize: 20 }}>
					Pãnini
				</div>
				<div className='bot-actions'>
					<Button icon='Close' onClick={() => handleWholeBotClose()} />
					{/* <img src="/assets/img/close.png" alt="close" /> */}
				</div>
			</div>
			<div className='fields'>
				{!loading && (
					<>
						<div class='message df-jcfs'>
							<div class='user-avtar'>
								<img src={Chat} alt='bot' />
							</div>
							<div class='bot-message'>
								Namaskar! I am Pãnini, your AI enabled Health Assistant
							</div>
						</div>
						<div class='message df-jcfs'>
							<div class='user-avtar'>
								<img src={Chat} alt='bot' />
							</div>
							<div class='bot-message'>What would you like me to help you with?</div>
						</div>
					</>
				)}
				<div className='bot-btns'>
					{/* {allBots?.map((bot) => (
								<Button
									className='inline-buttons p-2'
									onClick={() => handleOpenChatbot(bot?.Algorithm?.identifier)}>
									{bot?.Algorithm?.name}
								</Button>
							))} */}
					{!loading && (
						<>
							<Button
								className='inline-buttons p-2'
								onClick={() => {
									setPrescription(false);
									setCurrentCategory(null);
								}}>
								Medical Algorithms
							</Button>
							<Button
								className='inline-buttons p-2'
								onClick={() => {
									isPermitted('Prescription', 'write', handlePrescription);
								}}>
								Add Prescription
							</Button>
							<Button
								className='inline-buttons p-2'
								onClick={() => {
									isPermitted('Prescription', 'write', handlePrescriptionVoice);
								}}>
								Add Voice Prescription
							</Button>
						</>
					)}
				</div>
				{loading && (
					<>
						<div className='message df-jcfs'>
							<div className='user-avtar'>
								<img src={Chat} alt='bot' />
							</div>
							<div className={'bot-message loading-div'}>
								<p className='loading'></p>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
