import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';

import Card, {
	CardActions,
	CardBody,
	CardLabel,
	CardTabItem,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';
import Input from '../../../../components/bootstrap/forms/Input';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import {
	addBillPayment,
	deleteBillOther,
	deleteBillPayment,
} from '../../../../actions/ipdPatientActions';
import moment from 'moment';
import { getPaymentTypes, invalidNums } from '../../../../helpers/helpers';
import Tooltips from '../../../../components/bootstrap/Tooltips';
import { useNavigate } from 'react-router-dom';
import Select from '../../../../components/bootstrap/forms/Select';
import { createIpdPaymentRemark, getIpdPaymentRemarks } from '../../../../actions/ipdActions';
import { getPaymentMethods } from '../../../../actions/saleVoucherActions';
import { getBillTypes, isPermitted } from '../../../../actions/helperActions';
import { toggleCreateLoading } from '../../../../actions/componentActions';

const validatePayment = (values) => {
	const errors = {};

	if (!values.date) {
		errors.date = 'Required';
	}
	if (!values.amount) {
		errors.amount = 'Required';
	}
	if (!values.paymentMode) {
		errors.paymentMode = 'Required';
	}
	return errors;
};

const paymentModes = [
	'Cash',
	'UPI',
	'Credit/Debit Card',
	'Net Banking',
	'Wallet(Paytm,Google Pay)',
];

const IpdPaymentsEstTab = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const hideBill = useSelector((state) => state.profile.selectedOrg?.Organisation?.isHiddenBill);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);
	const paymentRemarks = useSelector((state) => state.ipd.paymentRemarks);
	const billDetails = useSelector((state) => state.ipdPatient.billDetails);

	const [addPaymentModal, setAddPaymentModal] = useState(false);
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItem, setDeleteItem] = useState(null);
	const [paymentRemark, setPaymentRemark] = useState(null);
	const [showEst, setShowEst] = useState(0);
	const [billTypes, setBillTypes] = useState({});

	const formikPayment = useFormik({
		initialValues: {
			date: moment().format('YYYY-MM-DD'),
			amount: '',
			paymentMode: 'Cash',
			paymentRemark: '',
		},
		validate: validatePayment,
		onSubmit: async (values) => {
			const data = {
				ipdId,
				date: values.date,
				amount: values?.amount?.toString(),
				paymentMode: values.paymentMode?.toLocaleLowerCase()?.includes('wallet')
					? 'Wallet'
					: values.paymentMode,
				paymentRemark: paymentRemark?.value || '',
			};
			data['isEst'] = showEst == 1;
			if (!data['paymentRemark']) delete data['paymentRemark'];
			dispatch(toggleCreateLoading(true));
			dispatch(addBillPayment(data));
			formikPayment.resetForm();
			setAddPaymentModal(false);
		},
	});

	useEffect(() => {
		let mounted = true;
		const fetchOthers = async () => {
			if (mounted) {
				getBillTypes().then((res) => setBillTypes(res));
				dispatch(getIpdPaymentRemarks());
				dispatch(getPaymentMethods());
			}
		};
		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card shadow='none'>
				<div className='d-flex justify-content-between px-4'>
					<CardLabel icon='Payments' className='ms-2' iconColor='primary'>
						<CardTitle>Estimate Payments</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							onClick={() =>
								navigate(`/ipd/payments-pdf`, {
									state: {
										billDetails,
										ipdFile,
										isEst: true,
									},
								})
							}
							icon='Print'
							color='primary'
							isOutline>
							Print
						</Button>
						{/* <Button
							onClick={() =>
								isPermitted(
									'Ipd',
									'write',
									() => setAddPaymentModal(true),
									'Billing',
								)
							}
							icon='Add'
							color='primary'
							isOutline>
							Add Payment
						</Button> */}
					</CardActions>
				</div>
				<CardBody>
					<table className='table table-bordered'>
						<tbody>
							<tr>
								<th>S.No.</th>
								<th>Date</th>
								<th>Payment</th>
								<th>Payment Mode</th>
								<th>Remarks</th>
								<th>Actions</th>
							</tr>
							{billDetails?.Ipd_payments?.filter((x) => x.isEst)?.map((i, idx) => (
								<tr>
									<td>{idx + 1}</td>
									<td>{i.date ? moment(i.date)?.format('DD-MM-YYYY') : '-'}</td>
									<td>&#8377; {i.amount}</td>
									<td>{i.paymentMode || '-'}</td>
									<td>{i.Ipd_payment_remark?.message || '-'}</td>
									<td>
										<Tooltips title='Delete Payment'>
											<Button
												isLink
												color='danger'
												icon='Delete'
												onClick={() =>
													isPermitted(
														'Ipd',
														'delete',
														() => {
															setDeleteItem({
																type: 'payment',
																id: i.id,
															});
															setDeleteWarn(true);
														},
														'Billing',
													)
												}
											/>
										</Tooltips>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
			</Card>

			{/* Add Modal */}
			<Modal
				isOpen={addPaymentModal}
				// setIsOpen={setAddPaymentModal}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setAddPaymentModal}>
					<ModalTitle id='deletePharmLabel'>Add Payment</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='date' label='Date' isFloating className='mb-2'>
						<Input
							type='date'
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Date'
							onChange={formikPayment.handleChange}
							onBlur={formikPayment.handleBlur}
							value={formikPayment.values.date}
							isValid={formikPayment.isValid}
							isTouched={formikPayment.touched.date}
							invalidFeedback={formikPayment.errors.date}
						/>
					</FormGroup>
					<FormGroup id='amount' label='Amount' isFloating className='mb-2'>
						<Input
							type='number'
							onKeyDown={(e) => invalidNums.includes(e.key) && e.preventDefault()}
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Amount'
							onChange={formikPayment.handleChange}
							onBlur={formikPayment.handleBlur}
							value={formikPayment.values.amount}
							isValid={formikPayment.isValid}
							isTouched={formikPayment.touched.amount}
							invalidFeedback={formikPayment.errors.amount}
						/>
					</FormGroup>
					<FormGroup id='paymentMode' label='Payment Mode' isFloating className='mb-2'>
						<Select
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Select...'
							onChange={formikPayment.handleChange}
							onBlur={formikPayment.handleBlur}
							isValid={formikPayment.isValid}
							isTouched={formikPayment.touched.paymentMode}
							invalidFeedback={formikPayment.errors.paymentMode}
							value={formikPayment.values.paymentMode}
							list={paymentModes?.map((i) => ({
								value: i,
								text: i,
							}))}
						/>
					</FormGroup>
					{hideBill && (
						<FormGroup label={'Bill Type'} isFloating>
							<Select
								className='form-control'
								style={{ border: '1px solid #cecece' }}
								list={Object.entries(billTypes).map(([key, value]) => ({
									text: key,
									value: value,
								}))}
								value={showEst}
								onChange={(e) => {
									setShowEst(e.target.value);
								}}
							/>
						</FormGroup>
					)}
					<FormGroup id='paymentType' label='Payment Remark' className='mb-2 mt-2'>
						<Creatable
							className='form-control'
							styles={{
								control: () => ({
									position: 'relative',
									display: 'flex',
								}),
							}}
							placeholder='Type Here...'
							value={paymentRemark}
							onChange={(e) => setPaymentRemark(e)}
							onCreateOption={async (e) => {
								const res = await createIpdPaymentRemark({ message: e }, dispatch);
								setPaymentRemark({
									label: res?.message,
									value: res?.id,
								});
							}}
							options={paymentRemarks?.map((x) => ({
								value: x?.id,
								label: x?.message,
							}))}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setAddPaymentModal(false)}>
						Cancel
					</Button>
					<Button color='primary' onClick={formikPayment.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>

			{/* Delete Modal */}
			<Modal isOpen={deleteWarn} setIsOpen={setDeleteWarn} isCentered isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deletePharmLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button
						color='danger'
						icon='delete'
						onClick={() => {
							if (deleteItem?.type === 'payment')
								dispatch(deleteBillPayment(deleteItem.id, ipdId));
							else if (deleteItem?.type === 'other')
								dispatch(deleteBillOther(deleteItem.id, ipdId));
							setDeleteWarn(false);
						}}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default IpdPaymentsEstTab;
