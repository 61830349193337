import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import { NavLinkDropdown } from '../../../components/bootstrap/Nav';
import AvatarImage from '../../../components/extras/AvatarImage';
import { staticUrl } from '../../../helpers/helpers';
import { profileMenu } from '../../../menu';

const CommonHeaderChat = () => {
	const user = useSelector((state) => state.auth.user);
	const selectedOrg = useSelector((state) => state.profile.selectedOrg);
	const userImgDir = selectedOrg?.userType === 'staff' ? 'staffs' : 'doctor-profile';

	return (
		<>
			<div className='col d-flex align-items-center cursor-pointer' role='presentation'>
				<div className='position-relative'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							<NavLinkDropdown style={{ color: 'black', padding: 0 }}>
								{user && user?.Vendor_detail?.profileImage ? (
									<img
										src={`${staticUrl}/${userImgDir}/${user.Vendor_detail.profileImage}`}
										alt=''
										width={40}
										height={40}
										className='mx-auto d-block img-thumbnail'
									/>
								) : (
									<AvatarImage
										avatar={'doctor'}
										width={36}
										height={36}
										className='mx-auto d-block img-fluid mb-3 rounded'
									/>
								)}
								<span className='position-absolute top-85 start-85 translate-middle badge border border-2 border-light rounded-circle bg-success p-2'>
									<span className='visually-hidden'>Online user</span>
								</span>
							</NavLinkDropdown>
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd>
							{Object.keys(profileMenu).map((subItem) => (
								<DropdownItem>
									<NavLink to={`/${profileMenu[subItem].path}`}>
										{profileMenu[subItem].icon && (
											<img
												className='me-2'
												width='20px'
												height='20px'
												src={profileMenu[subItem].icon}
											/>
										)}
										<span style={{ color: 'black' }}>
											{profileMenu[subItem].text}
										</span>
									</NavLink>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		</>
	);
};

export default CommonHeaderChat;
