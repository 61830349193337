import { SET_DOCTOR_MIS_OVERVIEW } from '../types/mis/doctor-mis';

const initialState = {
	overview: [],
	error: null,
	loading: false,
};

const doctorMisReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DOCTOR_MIS_OVERVIEW:
			return {
				...state,
				overview: action.payload,
			};
		default:
			return state;
	}
};

export default doctorMisReducer;
