import {
	SET_ABHA_CARD,
	SET_ABHA_TXN,
	SET_ABHA_STATUS,
	SET_ABHA_PROFILE,
	SET_MAPPED_PHR,
	SET_ABHA_SUGGESTIONS,
	SET_HEALTH_ID_NUMBER,
} from './types';

export const ABHA_STATUS = {
	otpScreen: 'OtpVerification', // Common for both
	cardScreen: 'abhacard', // Common for both
	linkMobileScreen: 'linkMobileWithAadhar', //For Aadhar
	phrMappingScreen: 'abhaByMobile', // For Mobile
	createAbhaScreen: 'createAbhaMobile', // For Mobile
	healthIdScreen: 'healthIdScreen', // Common for both
};

const initialState = {
	txnId: '',
	mobile: '',
	abhaStatus: ABHA_STATUS.otpScreen, // OtpVerification | abhacard | linkMobileWithAadhar | abhaByMobile
	abhaCard: '',
	mappedPhrAddress: [], // this is for mobile
	suggestions: [], //Suggestions for health id creation via mobile
	profile: {},
	error: null,
	loading: false,
};

const abhaReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ABHA_TXN:
			return {
				...state,
				txnId: action.payload.txnId,
				mobile: action.payload.mobile,
				abhaCard: '',
			};
		case SET_ABHA_CARD:
			return {
				...state,
				abhaCard: action.payload,
			};
		case SET_HEALTH_ID_NUMBER:
			return {
				...state,
				healthIdNumber: action.payload,
			};
		case SET_ABHA_STATUS:
			return {
				...state,
				abhaStatus: action.payload,
			};
		case SET_ABHA_PROFILE:
			return {
				...state,
				profile: action.payload,
			};
		case SET_MAPPED_PHR:
			return {
				...state,
				mappedPhrAddress: action.payload,
			};
		case SET_ABHA_SUGGESTIONS:
			return {
				...state,
				suggestions: action.payload,
			};
		default:
			return state;
	}
};

export default abhaReducer;
