import React, { useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import PaginationButtons, {
	dataPagination,
	PER_COUNT,
} from '../../../components/PaginationButtons';
import useSortableData from '../../../hooks/useSortableData';
import { useEffect } from 'react';
import { getPatientVisits } from '../../../actions/patientActions';

const PatientVisits = ({ patient }) => {
	const dispatch = useDispatch();
	const loadingPatient = useSelector((state) => state.patient.loading);
	const data = useSelector((state) => state.patient.opds || []);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(data);

	if (loadingPatient) return <></>;

	useEffect(() => {
		dispatch(getPatientVisits(patient?.id));
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader borderSize={1}>
					<CardLabel icon='Alarm' iconColor='primary'>
						<CardTitle>Visits</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody className='table-responsive'>
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>S.no.</th>
								<th onClick={() => requestSort('date')} className='cursor-pointer'>
									Date
								</th>
								<th>OPD No.</th>
								<th>Remarks</th>
							</tr>
						</thead>
						<tbody>
							{dataPagination(items, currentPage, perPage).map((item, idx) => (
								<tr key={item.id}>
									<td>{idx + 1}.</td>
									<td>
										<div className='ms-2'>
											{moment(item.date).format('DD-MM-YYYY')}
										</div>
									</td>
									<td>{item.opdNo || '-'}</td>
									<td>{item.note || '-'}</td>
								</tr>
							))}
						</tbody>
					</table>
				</CardBody>
				<PaginationButtons
					data={items}
					label='items'
					setCurrentPage={setCurrentPage}
					currentPage={currentPage}
					perPage={perPage}
					setPerPage={setPerPage}
				/>
			</Card>
		</>
	);
};

export default PatientVisits;
