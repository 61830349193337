import _ from 'lodash';
import {
	DELETE_IPD_CHIEF_COMPLAINT,
	DELETE_IPD_CONSUMABLE,
	DELETE_IPD_DIAGNOSIS,
	DELETE_IPD_INVESTIGATION,
	DELETE_IPD_MEDICINE,
	DELETE_IPD_PROCEDURE,
	DELETE_IPD_VISIT_NOTE,
	DELETE_IPD_WHITEBOARD,
	DELETE_PHYSICAL_EXAMINATIONS,
	LOADING_IPD_PATIENT,
	SET_CURRENT_IPD,
	SET_DISCHARGE_SUMMARY,
	SET_IPD_ALLERGY,
	SET_IPD_CHIEF_COMPLAINTS,
	SET_IPD_CONSUMABLES,
	SET_IPD_DIAGNOSIS,
	SET_IPD_GYNAE_DATA,
	SET_IPD_HISTORY,
	SET_IPD_INVESTIGATIONS,
	SET_IPD_LABOUR_RECORD,
	SET_IPD_MEDICINES,
	SET_IPD_PROCEDURES,
	SET_IPD_VISIT_NOTES,
	SET_IPD_WHITEBOARD,
	SET_PHYSICAL_EXAMINATIONS,
	SET_IPD_BILL_DETAILS,
	SET_IPD_TPA,
	SET_IPDFILES,
	SET_IPD_PATIENT_BED_LIST,
	SET_VISITING_DOCTOR_SUGGESTIONS,
	SET_IPD_REFUND,
} from './types';

const initialState = {
	error: null,
	loading: false,
	currentIpd: null,
	chiefComplaints: [],
	diagnosis: [],
	medicines: [],
	investigations: [],
	visitNotes: [],
	consumables: [],
	whiteboard: [],
	procedures: [],
	physicalExaminations: [],
	ipdFiles: [],
	history: null,
	labourRecord: null,
	dischargeSummary: null,
	allergies: [],
	billDetails: null,
	refunds: [],
	bedList: [],
	gynaeData: [],
	tpa: [],
	vistingDoctorSuggestions: [],
};

const ipdPatientReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_IPD_PATIENT:
			return {
				...state,
				loading: true,
			};

		case SET_CURRENT_IPD:
			return {
				...state,
				currentIpd: action.payload,
				loading: false,
			};

		case SET_IPD_CHIEF_COMPLAINTS:
			return {
				...state,
				chiefComplaints: action.payload || [],
				loading: false,
			};

		case DELETE_IPD_CHIEF_COMPLAINT:
			return {
				...state,
				chiefComplaints: _.reject(state.chiefComplaints, (s) => s.id === action.payload),
			};

		case SET_IPD_MEDICINES:
			return {
				...state,
				medicines: action.payload || [],
				loading: false,
			};

		case DELETE_IPD_MEDICINE:
			return {
				...state,
				medicines: _.reject(state.medicines, (s) => s.id === action.payload),
			};

		case SET_IPD_INVESTIGATIONS:
			return {
				...state,
				investigations: action.payload || [],
				loading: false,
			};

		case DELETE_IPD_INVESTIGATION:
			return {
				...state,
				investigations: _.reject(state.investigations, (s) => s.id === action.payload),
			};

		case SET_IPD_VISIT_NOTES:
			return {
				...state,
				visitNotes: action.payload || [],
				loading: false,
			};

		case DELETE_IPD_VISIT_NOTE:
			return {
				...state,
				visitNotes: _.reject(state.visitNotes, (s) => s.id === action.payload),
			};

		case SET_IPD_CONSUMABLES:
			return {
				...state,
				consumables: action.payload || [],
				loading: false,
			};

		case DELETE_IPD_CONSUMABLE:
			return {
				...state,
				consumables: _.reject(state.consumables, (s) => s.id === action.payload),
			};

		case SET_IPD_WHITEBOARD:
			return {
				...state,
				whiteboard: action.payload || [],
				loading: false,
			};

		case DELETE_IPD_WHITEBOARD:
			return {
				...state,
				whiteboard: _.reject(state.whiteboard, (s) => s.id === action.payload),
			};

		case SET_IPD_PROCEDURES:
			return {
				...state,
				procedures: action.payload || [],
			};

		case DELETE_IPD_PROCEDURE:
			return {
				...state,
				procedures: _.reject(state.procedures, (s) => s.id === action.payload),
			};

		case SET_PHYSICAL_EXAMINATIONS:
			return {
				...state,
				physicalExaminations: action.payload || [],
			};

		case DELETE_PHYSICAL_EXAMINATIONS:
			return {
				...state,
				physicalExaminations: _.reject(
					state.physicalExaminations,
					(s) => s.id === action.payload,
				),
			};

		case SET_IPD_HISTORY:
			return {
				...state,
				history: action.payload || null,
			};

		case SET_IPD_ALLERGY:
			return {
				...state,
				allergies: action.payload || null,
			};

		case SET_IPD_DIAGNOSIS:
			return {
				...state,
				diagnosis: action.payload || [],
			};

		case SET_IPD_BILL_DETAILS:
			return {
				...state,
				billDetails: action.payload || null,
			};

		case SET_IPD_REFUND:
			return {
				...state,
				refunds: action.payload || [],
			};

		case SET_IPD_PATIENT_BED_LIST:
			return {
				...state,
				bedList: action.payload || [],
			};

		case DELETE_IPD_DIAGNOSIS:
			return {
				...state,
				diagnosis: _.reject(state.diagnosis, (s) => s.id === action.payload),
			};

		case SET_IPD_GYNAE_DATA:
			return {
				...state,
				gynaeData: action.payload || [],
			};

		case SET_IPD_LABOUR_RECORD:
			return {
				...state,
				labourRecord: action.payload || null,
			};

		case SET_DISCHARGE_SUMMARY:
			return {
				...state,
				dischargeSummary: action.payload || null,
			};

		case SET_IPD_TPA:
			return {
				...state,
				tpa: action.payload || [],
			};

		case SET_IPDFILES:
			return {
				...state,
				ipdFiles: action.payload || [],
			};

		case SET_VISITING_DOCTOR_SUGGESTIONS:
			return {
				...state,
				vistingDoctorSuggestions: action.payload || [],
			};

		default:
			return state;
	}
};

export default ipdPatientReducer;
