import {
	SET_SPECIALITIES,
	CREATE_SPECIALITY,
	UPDATE_SPECIALITY,
	DELETE_SPECIALITY,
	SET_SPECIALITY_ERROR,
	SET_SPECIALITY_DOCTORS,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';

export const getSpecialities = () => async (dispatch) => {
	console.log('running getSpecialities');
	try {
		const res = await axios.get('/doctor/speciality');
		if (res.data.success) {
			dispatch({
				type: SET_SPECIALITIES,
				payload: res.data.specialities,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createSpeciality = (newSpec) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/speciality', newSpec);
		if (res.data.success) {
			showNotification('Success', 'The speciality is created successfully', 'success');
			dispatch({
				type: CREATE_SPECIALITY,
				payload: newSpec,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateSpeciality = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/doctor/speciality', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The speciality is updated successfully', 'success');
			dispatch({
				type: UPDATE_SPECIALITY,
				payload: updateBody,
			});
			window.location.reload();
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteSpeciality = (specialityId) => async (dispatch) => {
	try {
		const res = await axios.delete('/doctor/speciality', { data: { specialityId } });
		if (res.data.success) {
			showNotification('Success', 'The speciality has been deleted successfully', 'success');
			dispatch({
				type: DELETE_SPECIALITY,
				payload: specialityId,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const getDoctorBySpeciality = (specialityId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/getDoctorsBySpeciality?specialityId=${specialityId}`);
		if (res.data.success) {
			dispatch({
				type: SET_SPECIALITY_DOCTORS,
				payload: { specialityId, doctors: res.data.response },
			});
		}
	} catch (err) {
		console.log(err);
	}
};
