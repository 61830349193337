import { store } from '../store/configureStore';

export const DefaultPagination = { pageNo: 1, limit: 25 };
export const currentOrg = store.getState()?.profile.selectedOrg?.organisationId;

export const paymentRatios = [
	{ label: 'Full', value: 'full' },
	{ label: 'Half', value: 'half' },
	{ label: 'One Forth', value: 'oneForth' },
];

export const drWiseBillKeys = [
	{ name: 'Total Count', key: 'totalCount' },
	{ name: 'Cash', key: 'billByCash' },
	// { name: 'UPI', key: 'billByUpi' },
	// { name: 'Card', key: 'billByCard' },
	{ name: 'Banking', key: 'billByBanking' },
	// { name: 'Wallet', key: 'billByWallet' },
	{ name: 'POS', key: 'billByUpi;billByCard;billByWallet' },
	{ name: 'Total', key: 'billByCash;billByUpi;billByCard;billByBanking;billByWallet' },
];

export const SnoHeading = { name: 'S no.', renderCell: (_, idx) => idx + 1 };
