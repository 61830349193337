import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Card, { CardTabItem } from '../../../../components/bootstrap/Card';
import IpdBillingTab from './IpdBillingTab';
import IpdPaymentsTab from './IpdPaymentsTab';
import IPDTPA from './IPDTPATab';
import { getIpdBillDetails } from '../../../../actions/ipdPatientActions';
import IpdRefund from './IpdRefund';
import IpdPaymentsEstTab from './IpdEstPaymentsTab';

const IpdBilling = () => {
	const dispatch = useDispatch();
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const hideBill = useSelector((state) => state.profile.selectedOrg?.Organisation?.isHiddenBill);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			dispatch(getIpdBillDetails(ipdId));
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Card stretch className='shadow-none' hasTab headerBorderSize={0}>
			<CardTabItem id='ipdBilling' title='Billing'>
				<IpdBillingTab />
			</CardTabItem>
			<CardTabItem id='ipdPayments' title='Payments'>
				<IpdPaymentsTab />
			</CardTabItem>
			<CardTabItem id='ipdTPA' title='TPA'>
				<IPDTPA />
			</CardTabItem>
			<CardTabItem id='ipdRefund' title='Refund'>
				<IpdRefund />
			</CardTabItem>
			{hideBill ? (
				<CardTabItem id='ipdEstPayments' title='Estimate Payments'>
					<IpdPaymentsEstTab />
				</CardTabItem>
			) : (
				<></>
			)}
		</Card>
	);
};

export default IpdBilling;
