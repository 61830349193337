import {
	SET_SPECIALITIES,
	LOADING_SPECIALITIES,
	CREATE_SPECIALITY,
	UPDATE_SPECIALITY,
	DELETE_SPECIALITY,
	SET_SPECIALITY_DOCTORS,
} from './types';

const initialState = {
	specialities: [],
	error: null,
	loading: false,
	specialityDoctors: {},
};

const specialityReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_SPECIALITIES:
			return {
				...state,
				loading: true,
			};

		case SET_SPECIALITIES:
			return {
				...state,
				specialities: action.payload || [],
				loading: false,
			};

		case CREATE_SPECIALITY:
			return {
				...state,
				specialities: [action.payload, ...state.specialities],
			};

		case UPDATE_SPECIALITY:
			state.specialities = state.specialities.filter((o) => o.id != action.payload.id);
			return {
				specialities: [action.payload, ...state.specialities],
				...state,
			};

		case DELETE_SPECIALITY:
			state.specialities = state.specialities.filter((ig) => ig.id != action.payload);
			return {
				...state,
			};
		case SET_SPECIALITY_DOCTORS:
			return {
				...state,
				specialityDoctors: {
					...state.specialityDoctors,
					[action.payload.specialityId]: action.payload.doctors,
				},
			};
		default:
			return state;
	}
};

export default specialityReducer;
