import classNames from 'classnames';
import React from 'react';
import Button from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import { Action } from '../../../../components/Table';

const Specialities = ({
	specialities,
	selectedSpeciality,
	setSelectedSpeciality,
	changeModeToDoctorListing,
}) => {
	const { darkModeStatus } = useDarkMode();
	return (
		<table className='table table-modern'>
			<thead>
				<tr>
					<th>S.No.</th>
					<th>Speciality</th>
					<th>Actions</th>
				</tr>
			</thead>
			<tbody>
				{specialities.map((speciality, index) => {
					return (
						<tr>
							<td>{index + 1}</td>
							<td>{speciality.name}</td>
							<td>
								<Action
									title='View'
									color='primary'
									icon='Eye'
									onClickFunction={() => {
										changeModeToDoctorListing(speciality);
									}}
								/>
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

export default Specialities;
