import { useFormik } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import JoditEditor from 'jodit-react';

import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import WhiteboardImg from '../../../assets/img/menu/patient/clinical-notes.png';
import { Delete, Edit } from '../../../components/icon/material-icons';
import { truncate } from '../../../helpers/helpers';
import {
	addIpdVisitNote,
	deleteIpdVisitNote,
	getIpdVisitNotes,
	updateIpdVisitNote,
} from '../../../actions/ipdPatientActions';
import ReactSelect from 'react-select';
import { getDoctors } from '../../../actions/doctorActions';
import { isPermitted } from '../../../actions/helperActions';
import { getVisitingDoc } from '../../../actions/settingActions';

const IpdVisitNotes = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);
	const visitNotes = useSelector((state) => state.ipdPatient.visitNotes);

	const [selectedVisitDoctor, setSelectedVisitDoctor] = useState(null);
	const doctors = useSelector((state) => state.doctor.doctors);
	const vistingDoctors = useSelector((state) => state.ipd.visitingDoc);

	const editor = useRef(null);
	const [text, setText] = useState('<p>');
	const [editItem, setEditItem] = useState(null);

	const handleUpcomingEdit = (item = null) => {
		if (!item) return;
		setEditItem(item);
		setText(item?.note || '');
		formik.setValues((prev) => ({
			...prev,
			date: item.date + 'T' + item.time + ':00',
		}));
	};

	const handleDelete = (visitNote) => {
		dispatch(deleteIpdVisitNote(visitNote?.id));
	};

	const formik = useFormik({
		initialValues: {
			date: moment().toISOString().substring(0, 16),
			time: moment().format('HH:mm'),
			visitDoctorId: doctors?.[0]?.id,
			note: '',
			ipdId: ipdId,
		},
		onSubmit: (values) => {
			const data = new FormData();
			data.append('ipdId', ipdId);
			data.append('visitDoctorId', values.visitDoctorId);
			data.append('date', values.date?.split('T')?.[0]);
			data.append('time', values.date?.split('T')?.[1]?.substring(0, 5));
			data.append('note', text);

			if (!editItem) {
				data.append('wardId', ipdFile?.Accommodation?.Hospital_ward?.id);
				dispatch(addIpdVisitNote(data, ipdId));
			} else {
				data.append('wardId', editItem?.wardId);
				data.append('noteId', editItem?.id);
				dispatch(updateIpdVisitNote(data, ipdId));
			}
			setText('');
			setEditItem(null);
			formik.resetForm();
		},
	});

	useEffect(() => {
		let mounted = true;

		const fetchOthers = () => {
			if (mounted) {
				dispatch(getIpdVisitNotes(ipdId));
				dispatch(getDoctors());
				dispatch(getVisitingDoc());
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>Visit Notes</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody isScrollable className='d-flex flex-column'>
					<div className='row'>
						<div className='col-2 pt-3' style={{ textAlign: 'right' }}>
							<p style={{ fontSize: '1.1rem' }}>
								<strong>DateTime:</strong>
							</p>
						</div>
						<div className='col-4'>
							<FormGroup id='date'>
								<Input
									className='form-control'
									autoComplete='off'
									type='datetime-local'
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									placeholder='Date'
									name='date'
									onChange={formik.handleChange}
									value={formik.values.date}
								/>
							</FormGroup>
						</div>

						<div className='col-2 pt-3' style={{ textAlign: 'right' }}>
							<p style={{ fontSize: '1.1rem' }}>
								<strong>Visiting Doctor:</strong>
							</p>
						</div>
						<div className='col-4'>
							<FormGroup id='visitDoctorId'>
								<ReactSelect
									id='visitDoctorId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={selectedVisitDoctor}
									options={[
										...doctors?.map((i) => ({
											value: i.id,
											label: i.Vendor_detail?.name
												? `Dr. ${i.Vendor_detail?.name}`
												: '-',
										})),
										...vistingDoctors?.map((i) => ({
											label: i?.name ? `Dr. ${i?.name}` : '',
											value: i?.vendorId,
										})),
									]}
									onChange={(e) => {
										formik.setValues((prev) => ({
											...prev,
											visitDoctorId: e.value,
										}));
										setSelectedVisitDoctor(e);
									}}
								/>
							</FormGroup>
						</div>
					</div>
					<div className='row mt-3'>
						<div className='col-1 pt-3 ps-0' style={{ textAlign: 'right' }}>
							<p style={{ fontSize: '1.1rem' }}>
								<strong>Visit Notes:</strong>
							</p>
							{/* <Button
								isOutline={!listening}
								color={listening ? 'danger' : 'primary'}
								icon='Mic'
								onClick={() => augnito.toggleListening()}>		 // Augnito Removed. Be cautious uncommenting this
								{listening ? 'Stop' : 'Speak'}
							</Button> */}
						</div>
						<div className='col-11'>
							<JoditEditor
								config={{
									defaultActionOnPaste: 'insert_as_html',
									height: '35vh',
								}}
								ref={editor}
								value={text}
								tabIndex={1}
								onBlur={(newContent) => setText(newContent)}
								onChange={(newContent) => {}}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-1'></div>
						<div className='col-3'>
							<Button
								isOutline={darkModeStatus}
								color='primary'
								className='border-0'
								onClick={() =>
									isPermitted('Ipd', 'write', formik.handleSubmit, 'Visit Notes')
								}>
								Save
							</Button>
						</div>
					</div>
					<div
						className='row mt-4'
						style={{
							overflowX: 'auto',
							height: '100%',
							width: '100%',
							flexWrap: 'nowrap',
						}}>
						{visitNotes?.map((v, index) => (
							<Card
								key={index}
								className='mx-2 shadow-none border-2'
								style={{ maxWidth: '400px' }}>
								<CardHeader className='py-2'>
									<CardLabel img={WhiteboardImg}>
										<CardTitle>
											<div className='row'>
												<div className='col-12'>Visit Notes</div>
												<div
													className='col-12'
													style={{
														fontWeight: 'normal',
														fontSize: '0.9rem',
													}}>
													{moment(v?.date).format('DD-MM-YYYY')}
												</div>
											</div>
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className=' py-2'>
									<div
										dangerouslySetInnerHTML={{
											__html: truncate(
												v?.note.replace(/(<([^>]+)>)/gi, ''),
												200,
											),
										}}></div>
								</CardBody>
								<CardFooter className='py-2'>
									<CardFooterRight>
										<Edit
											onClick={() =>
												isPermitted(
													'Ipd',
													'write',
													() => handleUpcomingEdit(v),
													'Visit Notes',
												)
											}
											style={{ cursor: 'pointer' }}
										/>
										<Delete
											onClick={() =>
												isPermitted(
													'Ipd',
													'delete',
													() => handleDelete(v),
													'Visit Notes',
												)
											}
											style={{ fill: 'var(--bs-danger)', cursor: 'pointer' }}
										/>
									</CardFooterRight>
								</CardFooter>
							</Card>
						))}
					</div>
				</CardBody>
			</Card>
		</>
	);
};

export default IpdVisitNotes;
