import { useFormik } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import RichTextEditor from 'react-rte';
import Creatable from 'react-select/creatable';

import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import ReactSelect from 'react-select';
import { getDoctors } from '../../../actions/doctorActions';
import { createNote, createTreatmentNow, getTreatments } from '../../../actions/settingActions';
import Tooltips from '../../../components/bootstrap/Tooltips';
import { createDiagnosis, getFrequencies, isPermitted } from '../../../actions/helperActions';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import axios from 'axios';
import { createDischargeSummary, getDischargeSummary } from '../../../actions/ipdPatientActions';
import { useNavigate } from 'react-router-dom';
import { dashboardMenu } from '../../../menu';
import { SET_DISCHARGE_MODAL } from '../../../reducers/types';
import showNotification from '../../../components/extras/showNotification';
axios.defaults.headers.common.Authorization = localStorage.getItem('ehrToken');

const statTimings = [
	{ name: 'Before Meal' },
	{ name: 'After Meal' },
	{ name: 'Bed Time' },
	{ name: 'SOS' },
	{ name: 'Empty Stomach' },
];

const IpdDischargeSummary = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const ipdFile = useSelector((state) => state.ipd.patientIpdFile);
	const notes = useSelector((state) => state.setting.notes);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const doctors = useSelector((state) => state.doctor.doctors);
	const diagnosis = useSelector((state) => state.ipdPatient.diagnosis);
	const procedures = useSelector((state) => state.ipdPatient.procedures);
	const diagnoses = useSelector((state) => state.setting.diagnoses).filter((i) => !i?.isAdmin);
	const treatments = useSelector((state) => state.setting.treatments);
	const dischargeData = useSelector((state) => state.ipdPatient.dischargeSummary);

	const [timingDropdown, setTimingDropdown] = useState(false);
	const [freqDropdown, setFreqDropdown] = useState(false);
	const [durationDropdown, setDurationDropdown] = useState(false);
	const [notesDropdown, setNotesDropdown] = useState(false);
	const [takeInput, setTakeInput] = useState(false);
	const [durationAll, setDurationAll] = useState('');
	const [selectedMedicine, setSelectedMedicine] = useState('');
	const [medicines, setMedicines] = useState([]);
	const [frequencies, setFrequencies] = useState([]);

	const formik = useFormik({
		initialValues: {
			doctorId: {
				label: 'Dr. ' + ipdFile?.Vendor?.Vendor_detail?.name,
				value: ipdFile?.Vendor?.id,
			},
			dateOfSurgery: '',
			dictatedBy: '',
			conditionOnDischarge: '',
			historyOfPresentIllness: RichTextEditor.createEmptyValue(),
			laboratoryData: RichTextEditor.createEmptyValue(),
			hospitalCourse: RichTextEditor.createEmptyValue(),
			dischargeInstructions: RichTextEditor.createEmptyValue(),
			followupAppointment: moment().format('YYYY-MM-DD'),
			finalDiagnosis: [],
			dischargeMedication: [],
			procedures: {
				treatmentId: [],
			},
		},
		onSubmit: (values) => {
			const data = { ...values };
			data['dischargeInstructions'] = values.dischargeInstructions.toString('html');
			data['historyOfPresentIllness'] = values.historyOfPresentIllness.toString('html');
			data['hospitalCourse'] = values.hospitalCourse.toString('html');
			data['laboratoryData'] = values.laboratoryData.toString('html');
			data['dischargeMedication'] = values.dischargeMedication?.map((x) => ({
				...x,
				frequency: x?.frequency?.label,
				medicineName: x?.medicineName?.label,
				notes: x?.notes?.label,
				timing: x?.timing?.label,
			}));
			data['doctorId'] = values.doctorId?.value;
			data['finalDiagnosis'] = values.finalDiagnosis?.map((x) => x?.value);
			data['procedures'] = {
				treatmentId: values.procedures.treatmentId?.map((x) => x?.value),
			};
			data['ipdId'] = ipdId;
			dispatch(createDischargeSummary(data));
		},
	});

	let cancelToken;
	const handleFetchMedicines = async (inputVal, firstCall = false) => {
		if (!inputVal && !firstCall) setMedicines([]);
		try {
			if (typeof cancelToken != typeof undefined) {
				cancelToken.cancel('Cancelling prev req');
			}
			cancelToken = axios.CancelToken.source();
			const res = await axios.get(
				`${process.env.REACT_APP_SERVER_URL}/doctor/medicine/suggestion?search=${
					inputVal || ''
				}`,
				{ cancelToken: cancelToken.token },
			);
			const meds = [
				...res.data?.inventories?.map((i) => ({
					isInventory: true,
					name: i?.name,
					color: 'orange',
					composition: i?.composition,
					quantity: `[${i?.Purchase_items?.[0]?.quantity || '0'}]`,
				})),
				...res.data?.medicines?.map((i) => ({
					name: i?.name,
					composition: i?.composition,
				})),
			];
			setMedicines(meds || []);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (ipdFile?.dischargeDatetime && dischargeData) {
			formik.setValues((prev) => ({
				...prev,
				doctorId: {
					label: `Dr. ${dischargeData?.Vendor_detail?.name}`,
					value: dischargeData?.Vendor_detail?.vendorId,
				},
				dateOfSurgery: dischargeData?.dateOfSurgery
					? moment(dischargeData?.dateOfSurgery).format('YYYY-MM-DD')
					: '',
				dictatedBy: dischargeData?.dictatedBy || '',
				conditionOnDischarge: dischargeData?.conditionOnDischarge || '',
				historyOfPresentIllness: RichTextEditor.createValueFromString(
					dischargeData?.historyOfPresentIllness || '',
					'html',
				),
				laboratoryData: RichTextEditor.createValueFromString(
					dischargeData?.laboratoryData || '',
					'html',
				),
				hospitalCourse: RichTextEditor.createValueFromString(
					dischargeData?.hospitalCourse || '',
					'html',
				),
				dischargeInstructions: RichTextEditor.createValueFromString(
					dischargeData?.dischargeInstructions || '',
					'html',
				),
				followupAppointment: moment(dischargeData?.followupAppointment).format(
					'YYYY-MM-DD',
				),
				finalDiagnosis: dischargeData?.Ipd_discharge_diagnoses?.map((x) => ({
					value: x?.Diagnosis?.id,
					label: x?.Diagnosis?.name,
				})),
				dischargeMedication: dischargeData?.Ipd_discharge_medicines?.map((x) => ({
					composition: x?.composition,
					duration: x?.duration,
					medicineName: {
						value: x?.Medicine?.name,
						label: x?.Medicine?.name,
					},
					frequency: {
						value: x?.frequency,
						label: x?.frequency,
					},
					timing: {
						value: x?.timing,
						label: x?.timing,
					},
					notes: {
						value: x?.notes,
						label: x?.notes,
					},
				})),
				procedures: {
					treatmentId:
						dischargeData?.Ipd_discharge_procedure_master?.Ipd_discharge_procedures?.map(
							(x) => ({
								label: x?.Treatment?.name,
								value: x?.Treatment?.id,
							}),
						),
				},
			}));
		} else {
			formik.setFieldValue(
				`finalDiagnosis`,
				diagnosis.map((x) => ({ label: x.Diagnosis?.name, value: x.Diagnosis?.id })),
			);
			formik.setFieldValue(
				`procedures.treatmentId`,
				procedures
					?.map((x) =>
						x?.Ipd_procedures?.map((y) => ({
							label: y?.Treatment?.name,
							value: y?.Treatment?.id,
						})),
					)
					.flat(),
			);
		}
	}, [diagnosis]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			const fetchOthers = async () => {
				setFrequencies(await getFrequencies());
				dispatch(getDoctors());
				dispatch(getTreatments());
				if (ipdFile?.dischargeDatetime) {
					dispatch(getDischargeSummary(ipdId));
				}
			};
			fetchOthers();
		}
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>Discharge Summary</CardTitle>
					</CardLabel>
					<CardActions>
						{dischargeData && (
							<Button
								isOutline
								color='primary'
								icon='print'
								onClick={() => {
									dispatch({
										type: SET_DISCHARGE_MODAL,
										payload: false,
									});
									navigate(
										`/${dashboardMenu.ipd.subMenu.dischargeSummaryPdf.path}`,
										{
											state: { summary: dischargeData, ipdFile },
										},
									);
								}}>
								Print
							</Button>
						)}

						<Button
							isOutline
							color='primary'
							icon='save'
							onClick={() => {
								if (
									!ipdFile?.dischargeDatetime
									// &&
									// new Date().getTime() <
									// new Date(parseInt(ipdFile?.dischargeDatetime)).getTime()
								) {
									showNotification(
										'Error',
										'Please discharge the patient first',
										'danger',
									);
									return;
								}
								isPermitted(
									'Ipd',
									'write',
									formik.handleSubmit,
									'Discharge Summary',
								);
							}}>
							Save
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody className='pb-5'>
					<div className='row'>
						<div className='col-6 mb-2'>
							<FormGroup label={`Date of Surgery`}>
								<Input
									type='date'
									className='form-control py-3'
									name='dateOfSurgery'
									value={formik.values.dateOfSurgery}
									onChange={formik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6 mb-2'>
							<FormGroup id='visitDoctorId' label={`Doctor`}>
								<ReactSelect
									id='visitDoctorId'
									className='form-control'
									styles={{
										control: () => ({
											position: 'relative',
											display: 'flex',
										}),
									}}
									value={formik.values.doctorId}
									options={doctors.map((i) => ({
										value: i.id,
										label: i.Vendor_detail?.name
											? `Dr. ${i.Vendor_detail?.name}`
											: '-',
									}))}
									onChange={(e) => {
										formik.setFieldValue(`doctorId`, e);
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-6 mb-2'>
							<FormGroup label={`Dictated By`}>
								<Input
									type='text'
									className='form-control py-3'
									name='dictatedBy'
									value={formik.values.dictatedBy}
									onChange={formik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6 mb-2'>
							<FormGroup label={`Condition on Discharge`}>
								<Input
									type='text'
									className='form-control py-3'
									name='conditionOnDischarge'
									value={formik.values.conditionOnDischarge}
									onChange={formik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6 mb-2'>
							<FormGroup label={`Final Diagnosis`}>
								<Creatable
									isMulti
									className='form-control'
									value={formik.values.finalDiagnosis}
									options={diagnoses.map((x) => ({
										label: x.name,
										value: x.id,
									}))}
									onChange={(e, { removedValue }) => {
										formik.setFieldValue(`finalDiagnosis`, e);
									}}
									onCreateOption={async (e) => {
										const dig = await createDiagnosis(e);
										formik.setFieldValue(`finalDiagnosis`, [
											...formik.values.finalDiagnosis,
											{
												value: dig?.diagnosesId,
												label: e,
											},
										]);
									}}
									styles={{
										control: () => ({
											height: '2.6rem',
											alignItems: 'center',
											position: 'relative',
											display: 'flex',
										}),
										menu: (base) => ({
											...base,
											zIndex: 9999999,
										}),
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-6 mb-2'>
							<FormGroup label={`Procedure`}>
								<Creatable
									isMulti
									className='form-control'
									value={formik.values.procedures.treatmentId}
									options={treatments?.map((i) => ({
										label: `${i.name} ${
											i?.Treatment_organisation_tag?.Treatment_tag?.name
												? `(${i?.Treatment_organisation_tag?.Treatment_tag?.name})`
												: ''
										}`,
										value: i?.id,
									}))}
									onChange={(e, { removedValue }) => {
										formik.setFieldValue(`procedures.treatmentId`, e);
									}}
									onCreateOption={async (e) => {
										const res = await createTreatmentNow({
											name: e,
											perUnitPrice: 0,
										});
										formik.setFieldValue(`procedures.treatmentId`, [
											...formik.values.procedures.treatmentId,
											{ value: res.id, label: res.name },
										]);
									}}
									styles={{
										control: () => ({
											height: '2.6rem',
											alignItems: 'center',
											position: 'relative',
											display: 'flex',
										}),
										menu: (base) => ({
											...base,
											zIndex: 9999999,
										}),
									}}
								/>
							</FormGroup>
						</div>
						<div className='col-12 mb-2'>
							<FormGroup label={`History of present Illness`}>
								<RichTextEditor
									editorStyle={{ height: '20vh' }}
									value={formik.values.historyOfPresentIllness}
									onChange={(x) =>
										formik.setFieldValue(`historyOfPresentIllness`, x)
									}
								/>
							</FormGroup>
						</div>
						<div className='col-12 mb-2'>
							<FormGroup label={`Laboratory/Data`}>
								<RichTextEditor
									editorStyle={{ height: '20vh' }}
									value={formik.values.laboratoryData}
									onChange={(x) => formik.setFieldValue(`laboratoryData`, x)}
								/>
							</FormGroup>
						</div>
						<div className='col-12 mb-2'>
							<FormGroup label={`Hospital Course`}>
								<RichTextEditor
									editorStyle={{ height: '20vh' }}
									value={formik.values.hospitalCourse}
									onChange={(x) => formik.setFieldValue(`hospitalCourse`, x)}
								/>
							</FormGroup>
						</div>
						<div className='col-12 mb-2'>
							<FormGroup label={`Discharge Instructions`}>
								<RichTextEditor
									editorStyle={{ height: '20vh' }}
									value={formik.values.dischargeInstructions}
									onChange={(x) =>
										formik.setFieldValue(`dischargeInstructions`, x)
									}
								/>
							</FormGroup>
						</div>
						<div className='col-12 mb-2'>
							<FormGroup label={`Discharge Medication`} />
							<table className='table table-bordered'>
								<tbody>
									<tr>
										<th colSpan={2}>
											<Button
												className='w-100'
												style={{
													textAlign: 'left',
												}}>
												Medicine/Composition
											</Button>
										</th>
										<th>
											<Dropdown
												className='dropdown-btn'
												isOpen={freqDropdown}
												setIsOpen={setFreqDropdown}>
												<DropdownToggle hasIcon={false}>
													<Button
														className='w-100'
														style={{
															textAlign: 'left',
														}}>
														Frequency
													</Button>
												</DropdownToggle>
												<DropdownMenu
													style={{
														maxHeight: '20vh',
														overflow: 'auto',
													}}
													size='sm'
													isAlignmentEnd>
													{frequencies?.map((k, x) => (
														<DropdownItem
															key={x}
															onClick={() => {
																formik.setValues((prev) => ({
																	...prev,
																	dischargeMedication: [
																		...prev.dischargeMedication?.map(
																			(med) => ({
																				...med,
																				frequency: {
																					label: k?.dose,
																					value: k,
																				},
																			}),
																		),
																	],
																}));
																setFreqDropdown(false);
															}}>
															{k?.dose}
														</DropdownItem>
													))}
												</DropdownMenu>
											</Dropdown>
										</th>
										<th>
											<Dropdown
												className='dropdown-btn'
												isOpen={timingDropdown}
												setIsOpen={setTimingDropdown}>
												<DropdownToggle hasIcon={false}>
													<Button
														className='w-100'
														style={{
															textAlign: 'left',
														}}>
														Timing
													</Button>
												</DropdownToggle>
												<DropdownMenu
													style={{
														maxHeight: '20vh',
														overflow: 'auto',
													}}
													size='sm'
													isAlignmentEnd>
													{statTimings?.map((k, x) => (
														<DropdownItem
															key={x}
															onClick={() => {
																formik.setValues((prev) => ({
																	...prev,
																	dischargeMedication: [
																		...prev.dischargeMedication?.map(
																			(med) => ({
																				...med,
																				timing: {
																					value: k,
																					label: k?.name,
																				},
																			}),
																		),
																	],
																}));
																setTimingDropdown(false);
															}}>
															{k?.name}
														</DropdownItem>
													))}
												</DropdownMenu>
											</Dropdown>
										</th>
										<th>
											<Dropdown
												className='dropdown-btn'
												isOpen={durationDropdown}
												setIsOpen={setDurationDropdown}>
												<DropdownToggle hasIcon={false}>
													{!takeInput ? (
														<Button
															onClick={() => setTakeInput(true)}
															className='w-100'
															style={{
																textAlign: 'left',
															}}>
															Duration
														</Button>
													) : (
														<>
															<Input
																onFocus={() =>
																	setDurationDropdown(true)
																}
																type='text'
																value={durationAll}
																onChange={(e) =>
																	setDurationAll(e.target.value)
																}
															/>
														</>
													)}
												</DropdownToggle>
												<DropdownMenu
													style={{
														maxHeight: '20vh',
														overflow: 'auto',
													}}
													size='sm'
													isAlignmentEnd>
													{[
														{ name: 'Days' },
														{ name: 'Weeks' },
														{ name: 'Months' },
														{ name: 'Lifetime' },
													]?.map((k, x) => (
														<DropdownItem
															key={x}
															onClick={() => {
																setTakeInput(false);
																formik.setValues((prev) => ({
																	...prev,
																	dischargeMedication: [
																		...prev.dischargeMedication?.map(
																			(med) => ({
																				...med,
																				duration:
																					(parseInt(
																						durationAll,
																					) || '1') +
																					' ' +
																					k?.name,
																			}),
																		),
																	],
																}));
																setDurationDropdown(false);
															}}>
															{k?.name}
														</DropdownItem>
													))}
												</DropdownMenu>
											</Dropdown>
										</th>
										<th>
											<Dropdown
												className='dropdown-btn'
												isOpen={notesDropdown}
												setIsOpen={setNotesDropdown}>
												<DropdownToggle hasIcon={false}>
													<Button
														className='w-100'
														style={{
															textAlign: 'left',
														}}>
														Notes
													</Button>
												</DropdownToggle>
												<DropdownMenu
													style={{
														maxHeight: '20vh',
														overflow: 'auto',
													}}
													// size='sm'
													isAlignmentEnd>
													{notes
														.filter(
															(i) =>
																i.type === 'Prescription Medicine',
														)
														?.map((k, x) => (
															<DropdownItem
																key={x}
																onClick={() => {
																	formik.setValues((prev) => ({
																		...prev,
																		dischargeMedication: [
																			...prev.dischargeMedication?.map(
																				(med) => ({
																					...med,
																					notes: {
																						label: k?.name,
																						value: k,
																					},
																				}),
																			),
																		],
																	}));
																	setNotesDropdown(false);
																}}>
																{k?.name}
															</DropdownItem>
														))}
												</DropdownMenu>
											</Dropdown>
										</th>
										<th></th>
									</tr>
									{formik.values.dischargeMedication.map((r, index) => (
										<>
											<tr key={index}>
												<td colSpan={2}>
													<Creatable
														components={{
															DropdownIndicator: () => null,
															IndicatorSeparator: () => null,
														}}
														className='p-0'
														value={r.medicineName}
														styles={{
															option: (e, { data }) => {
																return {
																	...e,
																	color: data?.value?.color
																		? data?.value?.color
																		: e.color,
																};
															},
															control: (base) => ({
																...base,
																border: 0,
																minHeight: '25px',
																height: '25px',
																boxShadow: 'none',
															}),
															container: (base) => ({
																...base,
																height: '25px',
															}),
														}}
														options={medicines?.map((i) => ({
															label:
																i?.name +
																(i?.quantity
																	? ` --${i?.quantity}`
																	: ''),
															value: i,
														}))}
														onInputChange={(e) => {
															handleFetchMedicines(e);
														}}
														onChange={(e) => {
															if (e.value.isInventory) {
																const ok = e?.label?.split(' ');
																ok.pop();
																formik.setFieldValue(
																	`dischargeMedication[${index}].medicineName`,
																	{
																		value: e?.value,
																		label: ok.join(' '),
																	},
																);
															} else {
																formik.setFieldValue(
																	`dischargeMedication[${index}].medicineName`,
																	{
																		value: e?.value,
																		label: e?.label,
																	},
																);
															}
															formik.setFieldValue(
																`dischargeMedication[${index}].composition`,
																e?.value?.composition || '',
															);
														}}
														onCreateOption={(e) => {
															formik.setFieldValue(
																`dischargeMedication[${index}].medicineName`,
																{
																	label: e,
																	value: e,
																},
															);
															formik.setFieldValue(
																`dischargeMedication[${index}].composition`,
																'',
															);
														}}
													/>
													{r.composition && (
														<span
															style={{
																padding: '0 10px',
																fontStyle: 'italic',
																marginTop: '-10px',
															}}>
															{r.composition}
														</span>
													)}
												</td>
												<td>
													<Creatable
														components={{
															DropdownIndicator: () => null,
															IndicatorSeparator: () => null,
														}}
														styles={{
															control: (base) => ({
																...base,
																border: 0,
																// boxShadow: 'none',
															}),
														}}
														className='p-0'
														value={r.frequency}
														options={frequencies?.map((i) => ({
															label: i?.dose,
															value: i,
														}))}
														onChange={(e) => {
															formik.setFieldValue(
																`dischargeMedication[${index}].frequency`,
																e,
															);
															formik.setFieldValue(
																`dischargeMedication[${index}].timing`,
																{},
															);
														}}
														onCreateOption={(freq) => {
															let result = '';
															if (freq != '') {
																while (freq.length > 0) {
																	result =
																		result +
																		'-' +
																		freq.substring(0, 1); // Insert space character
																	freq = freq.substring(1); // Trim String
																}
															}
															result = result.replace('-', '');
															formik.setFieldValue(
																`dischargeMedication[${index}].frequency`,
																{
																	label: result,
																	value: result,
																},
															);
															formik.setFieldValue(
																`dischargeMedication[${index}].timing`,
																{},
															);
														}}
													/>
												</td>
												<td>
													<Creatable
														components={{
															DropdownIndicator: () => null,
															IndicatorSeparator: () => null,
														}}
														styles={{
															control: (base) => ({
																...base,
																border: 0,
															}),
														}}
														className='p-0'
														value={r.timing}
														options={statTimings?.map((i) => ({
															label: i?.name,
															value: i,
														}))}
														onChange={(e) => {
															var timing = e;
															var frequency =
																typeof formik.values
																	.dischargeMedication[index]
																	?.frequency?.value === 'string'
																	? formik.values
																			.dischargeMedication[
																			index
																	  ]?.frequency?.value
																	: formik.values
																			.dischargeMedication[
																			index
																	  ]?.frequency?.value?.dose;
															var rfrequency = frequency?.replaceAll(
																'-',
																'',
															);
															switch (rfrequency) {
																case '100':
																	if (
																		e?.label === 'Before Meal'
																	) {
																		timing = {
																			label: 'Before Breakfast',
																			value: 'Before Breakfast',
																		};
																	} else if (
																		e?.label === 'After Meal'
																	) {
																		timing = {
																			label: 'After Breakfast',
																			value: 'After Breakfast',
																		};
																	}
																	break;
																case '010':
																	if (
																		e?.label === 'Before Meal'
																	) {
																		timing = {
																			label: 'Before Lunch',
																			value: 'Before Lunch',
																		};
																	} else if (
																		e?.label === 'After Meal'
																	) {
																		timing = {
																			label: 'After Lunch',
																			value: 'After Lunch',
																		};
																	}
																	break;
																case '001':
																	if (
																		e?.label === 'Before Meal'
																	) {
																		timing = {
																			label: 'Before Dinner',
																			value: 'Before Dinner',
																		};
																	} else if (
																		e?.label === 'After Meal'
																	) {
																		timing = {
																			label: 'After Dinner',
																			value: 'After Dinner',
																		};
																	}
																	break;
																default:
																	break;
															}
															formik.setFieldValue(
																`dischargeMedication[${index}].timing`,
																timing,
															);
														}}
														onCreateOption={(e) => {
															formik.setFieldValue(
																`dischargeMedication[${index}].timing`,
																{
																	label: e,
																	value: e,
																},
															);
														}}
													/>
												</td>
												<td>
													<Input
														style={{
															fontWeight: 'normal',
															padding: '0.60rem',
															borderRadius: '0.3rem',
															background: 'transparent',
															boxShadow: 'none',
														}}
														autoComplete='off'
														onChangeItem={(e) => {
															formik.setValues((prev) => ({
																...prev,
																dischargeMedication: [
																	...prev.dischargeMedication.slice(
																		0,
																		index,
																	),
																	{
																		...prev.dischargeMedication[
																			index
																		],
																		duration:
																			(isNaN(
																				parseInt(
																					prev
																						.dischargeMedication[
																						index
																					].duration,
																				),
																			)
																				? '1'
																				: parseInt(
																						prev
																							.dischargeMedication[
																							index
																						].duration,
																				  )) +
																				' ' +
																				e.name || 'Days',
																	},
																	...prev.dischargeMedication.slice(
																		index + 1,
																	),
																],
															}));
														}}
														onChange={formik.handleChange}
														name={`dischargeMedication[${index}].duration`}
														value={r.duration}
														list={[
															{ name: 'Days' },
															{ name: 'Weeks' },
															{ name: 'Months' },
															{ name: 'Lifetime' },
														]}
													/>
												</td>
												<td>
													<Creatable
														components={{
															DropdownIndicator: () => null,
															IndicatorSeparator: () => null,
														}}
														styles={{
															control: (base) => ({
																...base,
																border: 0,
																// boxShadow: 'none',
															}),
														}}
														className='p-0'
														value={r.notes}
														options={notes
															.filter(
																(i) =>
																	i.type ===
																	'Prescription Medicine',
															)
															?.map((i) => ({
																value: i,
																label: i?.name,
															}))}
														onChange={(e) => {
															formik.setFieldValue(
																`dischargeMedication[${index}].notes`,
																e,
															);
														}}
														onCreateOption={(e) => {
															dispatch(
																createNote({
																	name: e,
																	type: 'Prescription Medicine',
																	text: e,
																}),
															);
															formik.setFieldValue(
																`dischargeMedication[${index}].notes`,
																{
																	label: e,
																	value: e,
																},
															);
														}}
													/>
												</td>
												<td>
													<Tooltips title='Delete'>
														<Button
															isLink
															color='danger'
															isLight={darkModeStatus}
															icon='Delete'
															onClick={() => {
																formik.setValues((prev) => ({
																	...prev,
																	dischargeMedication: [
																		...prev.dischargeMedication.slice(
																			0,
																			index,
																		),
																		...prev.dischargeMedication.slice(
																			index + 1,
																		),
																	],
																}));
															}}></Button>
													</Tooltips>
												</td>
											</tr>
										</>
									))}
									<tr>
										<td colSpan={2}>
											<Creatable
												components={{
													DropdownIndicator: () => null,
													IndicatorSeparator: () => null,
												}}
												className='p-0'
												value={selectedMedicine}
												styles={{
													option: (e, { data }) => {
														return {
															...e,
															zIndex: 999,
															color: data?.value?.color
																? data?.value?.color
																: e.color,
														};
													},
													control: (base) => ({
														...base,
														border: 0,
														// boxShadow: 'none',
													}),
												}}
												options={medicines?.map((i) => ({
													label:
														i?.name +
														(i?.quantity ? ` --${i?.quantity}` : ''),
													value: i,
												}))}
												onInputChange={(e) => {
													handleFetchMedicines(e);
												}}
												onChange={(e) => {
													if (e.value.isInventory) {
														const ok = e?.label?.split(' ');
														ok.pop();
														formik.setValues((prev) => ({
															...prev,
															dischargeMedication: [
																...prev.dischargeMedication,
																{
																	medicineName: {
																		value: e?.value,
																		label: ok.join(' '),
																	},
																	composition:
																		e?.value?.composition,
																},
															],
														}));
													} else {
														formik.setValues((prev) => ({
															...prev,
															dischargeMedication: [
																...prev.dischargeMedication,
																{
																	medicineName: {
																		value: e?.value,
																		label: e?.label,
																	},
																	composition:
																		e?.value?.composition,
																},
															],
														}));
													}
													setSelectedMedicine(null);
												}}
												onCreateOption={(e) => {
													setSelectedMedicine(null);
													formik.setValues((prev) => ({
														...prev,
														dischargeMedication: [
															...formik.values.dischargeMedication,
															{
																medicineName: {
																	label: e,
																	value: e,
																},
																composition: '',
															},
														],
													}));
												}}
											/>
										</td>
										<td>
											<Input
												style={{
													fontWeight: 'normal',
													padding: '0.6rem',
													borderRadius: '0.3rem',
													background: 'transparent',
													boxShadow: 'none',
												}}
												// list={frequencies?.map((i) => ({
												// 	name: i.dose,
												// }))}
											/>
										</td>
										<td>
											<Input
												style={{
													fontWeight: 'normal',
													padding: '0.6rem',
													borderRadius: '0.3rem',
													background: 'transparent',
													boxShadow: 'none',
												}}
												// list={statTimings}
											/>
										</td>
										<td>
											<Input
												style={{
													fontWeight: 'normal',
													padding: '0.6rem',
													borderRadius: '0.3rem',
													background: 'transparent',
													boxShadow: 'none',
												}}
												// list={[
												// 	{ name: 'Days' },
												// 	{ name: 'Weeks' },
												// 	{ name: 'Months' },
												// 	{ name: 'Lifetime' },
												// ]}
											/>
										</td>
										<td>
											<Input
												style={{
													fontWeight: 'normal',
													padding: '0.6rem',
													borderRadius: '0.3rem',
													background: 'transparent',
													boxShadow: 'none',
												}}
												// list={notes.filter(
												// 	(i) => i.type === 'Prescription Medicine',
												// )}
											/>
										</td>
										<td></td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className='col-6 mb-2'>
							<FormGroup label={`Follow up Appointment`}>
								<Input
									type='date'
									className='form-control py-3'
									name='followupAppointment'
									value={formik.values.followupAppointment}
									onChange={formik.handleChange}
								/>
							</FormGroup>
						</div>
					</div>
				</CardBody>
			</Card>
		</>
	);
};

export default IpdDischargeSummary;
