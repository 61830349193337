import React from 'react';
import { authMenu, dashboardMenu } from '../menu';
// import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';
import PatientHeader from '../pages/common/Headers/PatientHeader';
import PatientPrescriptionHeader from '../pages/common/Headers/PatientPrescriptionHeader';

const headers = [
	{ path: dashboardMenu.consultWithDoctor.path, element: null, exact: true },
	{ path: dashboardMenu.doctorJoinZoomMeeting.path, element: null, exact: true },
	{ path: authMenu.login.path, element: null, exact: true },
	{ path: authMenu.signUp.path, element: null, exact: true },
	{ path: authMenu.page404.path, element: null, exact: true },
	// { path: dashboardMenu.patients.subMenu.patientExaminationPdf.path, element: null, exact: true },
	// {
	// 	path: dashboardMenu.patients.subMenu.patientTreatmentPlanPdf.path,
	// 	element: null,
	// 	exact: true,
	// },
	// { path: dashboardMenu.patients.subMenu.patientTreatmentPdf.path, element: null, exact: true },
	{
		path: dashboardMenu.patients.subMenu.patientPrescriptionPdf.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientDiagnosesPdf.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.patientVoicePrescriptionPdf.path,
		element: null,
		exact: true,
	},
	{ path: dashboardMenu.patients.subMenu.patientBillingPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientPaymentPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientOpdPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientHistoryPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientVisionPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientImmunPdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientChemoNotePdf.path, element: null, exact: true },
	{ path: dashboardMenu.patients.subMenu.patientAttachmentPdf.path, element: null, exact: true },
	{ path: dashboardMenu.ipd.subMenu.dischargeSummaryPdf.path, element: null, exact: true },
	{ path: dashboardMenu.ipd.subMenu.dischargeSummary2Pdf.path, element: null, exact: true },
	{ path: dashboardMenu.ipd.subMenu.ipdBillingPdf.path, element: null, exact: true },
	{ path: dashboardMenu.ipd.subMenu.ipdPaymentsPdf.path, element: null, exact: true },
	{ path: dashboardMenu.ipd.subMenu.roomStatusPdf.path, element: null, exact: true },
	{ path: dashboardMenu.ipd.subMenu.faceCard.path, element: null, exact: true },
	{ path: dashboardMenu.lab.subMenu.billingPdf.path, element: null, exact: true },
	{ path: dashboardMenu.lab.subMenu.labReportPdf.path, element: null, exact: true },
	{ path: dashboardMenu.accounts.subMenu.staffMisPDF.path, element: null, exact: true },
	{ path: dashboardMenu.accounts.subMenu.printMis.path, element: null, exact: true },
	{ path: dashboardMenu.ipd.subMenu.ipdListPdf.path, element: null, exact: true },
	{ path: dashboardMenu.pharmacy.subMenu.gstReportsPrint.path, element: null, exact: true },
	{ path: dashboardMenu.accounts.subMenu.printDrWise.path, element: null, exact: true },
	{ path: dashboardMenu.accounts.subMenu.drWiseStaffMisPDF.path, element: null, exact: true },
	{ path: dashboardMenu.pharmacy.subMenu.wholeSalePdf.path, element: null, exact: true },
	// { path: dashboardMenu.patients.subMenu.patientShortReport.path, element: null, exact: true },
	// { path: dashboardMenu.patients.subMenu.patientLongReport.path, element: null, exact: true },
	{
		path: dashboardMenu.patients.subMenu.patientDetailedLongReport.path,
		element: null,
		exact: true,
	},
	{
		path: dashboardMenu.opd.subMenu.pdfHeader.path,
		element: null,
		exact: true,
	},
	{ path: dashboardMenu.pharmacy.subMenu.saleVoucher.path, element: null, exact: true },
	{
		path: dashboardMenu.patients.subMenu.viewPatient.path,
		element: <PatientHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.patients.subMenu.prescriptionView.path,
		element: <PatientPrescriptionHeader />,
		exact: true,
	},
	// { path: dashboardMenu.dashboard.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
