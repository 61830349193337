import axios from '../helpers/axios';
import {
	SET_CURRENT_PRESCRIPTION,
	SET_PANINI_PRESCRIPTION,
	SET_MED_ALERTS_MODAL,
	SET_SAVE_PRESCRIPTION,
	SET_PANINI_ALGORITHM_CATEGORIES,
	SET_CURRENT_CAT_ALGO,
	SET_ALGO_SUBSCRIPTIONS,
	SET_ORG_ALGOS,
	SET_VENDOR_SUBSCRIPTIONS,
	SET_CURRENT_VOICE_PRESCRIPTION,
} from '../reducers/types';

export const setCurrentPrescription = (prescription) => async (dispatch) => {
	dispatch({
		type: SET_CURRENT_PRESCRIPTION,
		payload: prescription,
	});
};

export const setCurrentVoicePrescription = (prescription) => async (dispatch) => {
	dispatch({
		type: SET_CURRENT_VOICE_PRESCRIPTION,
		payload: prescription,
	});
};

export const setPaniniPrescription = (viewPanini, viewPaniniPrescription) => async (dispatch) => {
	dispatch({
		type: SET_PANINI_PRESCRIPTION,
		payload: {
			viewPanini: viewPanini,
			viewPaniniPrescription: viewPaniniPrescription,
		},
	});
};

export const setMedAlertsModal = (value) => async (dispatch) => {
	dispatch({
		type: SET_MED_ALERTS_MODAL,
		payload: value,
	});
};

export const setSavePrescription = (value) => async (dispatch) => {
	dispatch({
		type: SET_SAVE_PRESCRIPTION,
		payload: value,
	});
};

export const getAlgoCategories = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/algorithm/category`);
		if (res.data.success) {
			dispatch({
				type: SET_PANINI_ALGORITHM_CATEGORIES,
				payload: res.data.categories,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getAllAlgos = async () => {
	try {
		const res = await axios.get(`/doctor/algorithm/category`);
		let algos = [];
		if (res.data.success) {
			for (let category of res.data.categories) {
				let res2;
				if (process.env.NODE_ENV !== 'production') {
					res2 = await axios.get(
						`/doctor/algorithm/list?algorithmCategoryId=${category?.id}&type=Test`,
					);
				} else {
					res2 = await axios.get(
						`/doctor/algorithm/list?algorithmCategoryId=${category?.id}`,
					);
				}

				if (res2.data.success) {
					algos = [...algos, ...res2.data.categories];
				}
			}
		}
		return algos;
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const getAlgoByCategory = (category) => async (dispatch) => {
	try {
		const res = await axios.get(
			`/doctor/algorithm/list?algorithmCategoryId=${category}&type=Test`,
		);
		if (res.data.success) {
			dispatch({
				type: SET_CURRENT_CAT_ALGO,
				payload: res.data.categories,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const checkAccess = async (algo) => {
	try {
		const res = await axios.get(`/doctor/algorithm/access?algorithmId=${algo}`);
		if (res.data.success) {
			return res.data.isAccess;
		}
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const getOrgAlgos = () => async (dispatch) => {
	try {
		const res = await axios.get(`/algorithm-subscription/algorithm`);
		if (res.data.success) {
			dispatch({
				type: SET_ORG_ALGOS,
				payload: res.data.algorithms,
			});
		}
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const getAlgoSubscriptions = () => async (dispatch) => {
	try {
		const res = await axios.get(`/algorithm-subscription`);
		if (res.data.success) {
			dispatch({
				type: SET_ALGO_SUBSCRIPTIONS,
				payload: res.data.algorithmSubscriptions,
			});
		}
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const deleteAlgoSubscription = (algoId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/algorithm-subscription/${algoId}`);
		if (res.data.success) {
			dispatch(getAlgoSubscriptions());
		}
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const createAlgoSubscription = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/algorithm-subscription`, data);
		if (res.data.success) {
			dispatch(getAlgoSubscriptions());
		}
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const updateAlgoSubscription = (data) => async (dispatch) => {
	try {
		const res = await axios.patch(`/algorithm-subscription`, data);
		if (res.data.success) {
			dispatch(getAlgoSubscriptions());
		}
	} catch (error) {
		console.error(error);
		return false;
	}
};

export const getVendorSubscription = (patientId) => async (dispatch) => {
	try {
		const res = await axios.get(`/algorithm-subscription/vendor?userId=${patientId}`);
		if (res.data.success) {
			dispatch({
				type: SET_VENDOR_SUBSCRIPTIONS,
				payload: res.data.algorithmSubscriptions,
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const createVendorSubscription = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/algorithm-subscription/vendor`, data);
		if (res.data.success) {
			dispatch(getVendorSubscription(data.userId));
		}
	} catch (error) {
		console.log(error);
	}
};

export const updateVendorSubscription = (data) => async (dispatch) => {
	try {
		const res = await axios.patch(`/algorithm-subscription/vendor`, data);
		if (res.data.success) {
			dispatch(getVendorSubscription(data.userId));
		}
	} catch (error) {
		console.log(error);
	}
};

export const deleteVendorSubscription = (id, patientId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/algorithm-subscription/vendor/${id}`);
		if (res.data.success) {
			dispatch(getVendorSubscription(patientId));
		}
	} catch (error) {
		console.log(error);
	}
};
