import { useFormik } from 'formik';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Input from '../../../../components/bootstrap/forms/Input';

function LabTests({ labsFormik }) {
	const labList = useSelector((state) => state.ipd.gynaeLabList);

	return (
		<div className='row mb-3'>
			<div className='col-12 mb-2 text-center'>
				<strong>LABS and Other Tests</strong>
			</div>
			{labList.map((testItem, index) => (
				<div className='row mb-3' key={index}>
					<div className='col-12 mb-2'>
						<strong>{testItem?.name}</strong>
					</div>
					<table className='table table-bordered'>
						<tbody>
							<tr>
								<th>Test name</th>
								<th>Date</th>
								<th>Result</th>
							</tr>
							{testItem?.Gyno_lab_other_tests?.map((test, testIndex) => (
								<tr key={testIndex}>
									<td>{test?.title}</td>
									<td>
										<Input
											style={{
												background: 'transparent',
												height: '2.5rem',
												borderRadius: 0,
											}}
											type='date'
											className='form-control p-3 shadow-none'
											value={labsFormik.values[test?.id]?.date}
											name={`${test?.id}.date`}
											onChange={labsFormik.handleChange}
										/>
									</td>
									<td>
										<Input
											style={{
												background: 'transparent',
												height: '2.5rem',
												borderRadius: 0,
											}}
											type='text'
											className='form-control p-3 shadow-none'
											value={labsFormik.values[test?.id]?.result}
											name={`${test?.id}.result`}
											onChange={labsFormik.handleChange}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			))}
		</div>
	);
}

export default LabTests;
