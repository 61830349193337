import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JoditEditor from 'jodit-react';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { addIpdAllergy, getIpdAllergy } from '../../../actions/ipdPatientActions';
import { isPermitted } from '../../../actions/helperActions';

const IpdAllergy = () => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const editor = useRef(null);

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const allergy = useSelector((state) => state.ipdPatient.allergies);

	const [text, setText] = useState(allergy?.[0]?.allergy || '');

	const handleSubmit = () => {
		dispatch(addIpdAllergy({ ipdId, allergy: text }));
	};

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			dispatch(getIpdAllergy(ipdId));
		}
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>Allergies</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody isScrollable className='d-flex flex-column'>
					<div className='row mt-3'>
						<div className='col-12'>
							<JoditEditor
								config={{
									defaultActionOnPaste: 'insert_as_html',
									height: '60vh',
								}}
								ref={editor}
								value={text}
								tabIndex={1}
								onBlur={(newContent) => setText(newContent)}
								onChange={(newContent) => {}}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-3'>
							<Button
								isOutline={!darkModeStatus}
								color='primary'
								className=' btn'
								onClick={() =>
									isPermitted('Ipd', 'write', handleSubmit, 'Allergies')
								}>
								Save
							</Button>
						</div>
					</div>
				</CardBody>
			</Card>
		</>
	);
};

export default IpdAllergy;
