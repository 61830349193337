import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Creatable from 'react-select/creatable';

import { createNote, getBillTypes, isPermitted } from '../../../actions/helperActions';
import {
	createPatientBill,
	createPatientPayment,
	deletePatientBill,
	deletePatientBillRow,
	getPatientBills,
	getPatientPayments,
	updatePatientBill,
} from '../../../actions/patientActions';
import {
	createTreatment,
	getBillPrefix,
	getNotesByType,
	getTreatments,
} from '../../../actions/settingActions';
import PaginationButtons, {
	PER_COUNT,
	dataPagination,
} from '../../../components/PaginationButtons';
import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTabItem,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import { OffCanvasTitle } from '../../../components/bootstrap/OffCanvas';
import Tooltips from '../../../components/bootstrap/Tooltips';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import { invalidNums, noteTypes, renderRupee } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import { SET_PATIENT_BILLS, SET_PATIENT_PAYMENTS } from '../../../reducers/types';
import PatientOpdPayments from './PatientOpdPayments';
import PatientPayment from './PatientPayment';
import Select from '../../../components/bootstrap/forms/Select';
import PatientEstimatePayments from './PatientEstimatePayments';
import showNotification from '../../../components/extras/showNotification';
import { getPaymentMethods } from '../../../actions/saleVoucherActions';
import useSelectTable from '../../../hooks/useSelectTable';
import Checks from '../../../components/bootstrap/forms/Checks';
import PatientRefunds from './PatientRefunds';

const validate = (values) => {
	const errors = {};
	if (!values.date) {
		errors.date = 'Required';
	}

	return errors;
};

const PatientBilling = ({ patient, scrollId }) => {
	const userId = patient?.id;

	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const notes = useSelector((state) => state.setting.notes);
	const hideBill = useSelector((state) => state.profile.selectedOrg?.Organisation?.isHiddenBill);
	const paymentModes = useSelector((state) => state.saleVoucher?.paymentMethods);
	const treatments = useSelector((state) => state.setting.treatments);
	const [selectedTreatment, setSelectedTreatment] = useState(null);
	const data = useSelector((state) => state.patient.bills);
	const billPrefix = useSelector((state) => state.setting.billPrefix);
	const bills = useSelector((state) => state.patient.bills);
	const payments = useSelector((state) => state.patient.payments);

	const [editMode, setEditMode] = useState(false);
	const [editItem, setEditItem] = useState(null);
	const [billTypes, setBillTypes] = useState({});
	const [deleteWarn, setDeleteWarn] = useState(false);
	const [deleteItemId, setDeleteItemId] = useState(null);
	const [deleteRecordListId, setDeleteRecordListId] = useState(null);
	const [showEst, setShowEst] = useState(0);
	const [rows, setRows] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const { items, requestSort, getClassNamesFor } = useSortableData(
		data?.filter((x) => x.isEst == showEst),
	);
	const refunds = useSelector((state) => state.patient.refundPayments || []);
	const onCurrentPageItems = dataPagination(items, currentPage, perPage);
	const { selectTable, SelectAllCheck } = useSelectTable(onCurrentPageItems);

	const handleUpcomingEdit = (item) => {
		formik.resetForm();
		formikRow.resetForm();
		setSelectedTreatment(null);
		setDeleteItemId(null);
		setDeleteRecordListId(null);
		setRows([]);
		setEditItem(null);
		if (item?.id) {
			setDeleteItemId(item.id);
			formik.setValues({
				date: moment(item?.date).format('YYYY-MM-DD').toString(),
				userId,
				billTotal: item?.billTotal,
				isEst: item?.isEst,
			});
			setRows(
				item?.Bill_items?.map((bill) => ({
					billListId: bill?.id || '',
					name: bill?.name || '',
					rate: bill?.rate || '',
					unit: bill?.unit || 1,
					discountAmount: bill?.discountAmount ? bill?.discountAmount : 0,
					discountPercentage: bill?.discountPercentage ? bill?.discountPercentage : 0,
					total: bill?.total || '',
					remarks: bill?.Bill_item_remarks?.map((x) => ({
						label: x.remark,
						value: x.remark,
					})),
				})),
			);
			setEditItem(item);
		}
		setEditMode(true);
	};

	const handleRowEdit = (row, index) => {
		setDeleteRecordListId(null);
		setRows(rows.filter((r, i) => i !== index));
		formikRow.setValues({
			name: row?.name || '',
			rate: row?.rate || '',
			unit: row?.unit || '',
			discountAmount: row?.discountAmount || '',
			discountPercentage: row?.discountPercentage || '',
			total: row?.total || '',
			remarks: row?.remarks?.map((x) => ({
				label: x,
				value: x,
			})),
		});
		setSelectedTreatment({
			value: row?.name,
			label: row?.name,
		});
		setDeleteRecordListId(row?.billListId);
	};

	const handleDeleteBill = (id) => {
		setDeleteItemId(id);
		setDeleteWarn(true);
	};

	const handleDeleteConfirm = () => {
		dispatch(deletePatientBill(deleteItemId));
		setDeleteItemId(null);
		setDeleteWarn(false);
	};

	// END :: Upcoming Events
	const formikPayment = useFormik({
		initialValues: {
			userId: userId,
			paymentMode: '',
			amountPaid: '',
			date: moment().format('YYYY-MM-DD').toString(),
		},
	});

	const formik = useFormik({
		initialValues: {
			userId,
			date: moment().format('YYYY-MM-DD').toString(),
			billTotal: 0,
			isEst: 0,
		},
		validate,
		onSubmit: (values, { resetForm }) => {
			// For Payment Add
			if (formikPayment?.values?.amountPaid && !formikPayment?.values?.paymentMode) {
				showNotification('Required', 'Please select payment mode', 'danger');
				return;
			}

			const data = {};
			for (var key in values) {
				data[key] = `${values[key]}`;
			}

			data['billLists'] = rows;

			// Hitting Bill Add/Edit
			if (editItem) {
				data['billId'] = deleteItemId;
				dispatch(updatePatientBill(data, userId));
			} else {
				data['isEst'] = values.isEst == 1;
				dispatch(createPatientBill(data));
			}

			// Hitting Payment Add
			if (formikPayment?.values?.amountPaid) {
				const pData = {};
				for (var key in formikPayment.values) {
					pData[key] = `${formikPayment.values[key]}`;
				}
				pData['date'] = values['date'];

				dispatch(createPatientPayment(pData));
			}

			formikRow.resetForm();
			formikPayment.resetForm();
			formik.setValues({
				userId,
				date: moment().format('YYYY-MM-DD').toString(),
				billTotal: 0,
			});
			resetForm();
			setRows([]);
			setDeleteItemId(null);
			setEditItem(null);
			setEditMode(false);
		},
	});

	const formikRow = useFormik({
		initialValues: {
			name: '',
			rate: 0,
			unit: 0,
			discountAmount: 0,
			discountPercentage: 0,
			total: 0,
			remarks: [],
		},
	});

	const handleAddRow = () => {
		const newRow = {
			name: formikRow.values.name,
			rate: formikRow.values.rate || 0,
			unit: formikRow.values.unit || 0,
			discountAmount: formikRow.values.discountAmount || 0,
			discountPercentage: formikRow.values.discountPercentage || 0,
			total: formikRow.values.total || 0,
			remarks: formikRow.values.remarks.map((x) => x.value),
		};

		if (deleteRecordListId) newRow.billListId = deleteRecordListId;

		setRows((prev) => [newRow, ...prev]);
		setSelectedTreatment(null);
		formikRow.setValues({
			name: '',
			rate: 0,
			unit: 0,
			discountAmount: 0,
			discountPercentage: 0,
			total: 0,
			remarks: [],
		});
		setDeleteRecordListId(null);
	};

	const handleDeleteRow = (index) => {
		if (rows[index]?.billListId)
			dispatch(deletePatientBillRow(rows[index]?.billListId, userId));
		setRows((prev) => prev.filter((r, idx) => idx !== index));
	};

	const calculateDiscAmt = (rate, unit, discountPercentage) => {
		if (rate && unit && discountPercentage) {
			const tempTotal = rate * unit;
			const discountAmount = discountPercentage * 0.01 * tempTotal;
			const total = tempTotal - discountAmount;

			formikRow.setValues((prev) => ({
				...prev,
				discountAmount: parseFloat(discountAmount?.toFixed(2)),
				total,
			}));
		} else if (rate && unit) {
			const tempTotal = rate * unit;

			formikRow.setValues((prev) => ({
				...prev,
				discountAmount: 0,
				total: tempTotal,
			}));
		} else {
			formikRow.setValues((prev) => ({
				...prev,
				discountAmount: 0,
				total: 0,
			}));
		}
	};

	const calculateDiscPercent = (rate, unit, discountAmount) => {
		if (rate && unit && discountAmount) {
			const tempTotal = rate * unit;
			const discountPercentage = (discountAmount * 100) / tempTotal;
			const total = tempTotal - discountAmount;

			formikRow.setValues((prev) => ({
				...prev,
				discountPercentage: parseFloat(discountPercentage?.toFixed(2)),
				total,
			}));
		} else if (rate && unit) {
			const tempTotal = rate * unit;

			formikRow.setValues((prev) => ({
				...prev,
				discountPercentage: 0,
				total: tempTotal,
			}));
		} else {
			formikRow.setValues((prev) => ({
				...prev,
				discountPercentage: 0,
				total: 0,
			}));
		}
	};

	const billingAmt = useMemo(
		() =>
			bills
				?.filter((x) => x.isEst == showEst)
				.reduce((i, j) => i + parseFloat(j.billTotal) || 0, 0),
		[bills, showEst],
	);
	const opdAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => i?.paymentFrom === 'OPD')
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const paidAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => !i.isEst)
				.filter((i) => i?.paymentFrom !== 'OPD')
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const estAmt = useMemo(
		() =>
			payments
				.filter((i) => i.userId === userId)
				.filter((i) => i.isEst)
				.reduce((i, j) => i + parseFloat(j.amountPaid) || 0, 0),
		[payments],
	);
	const refundAmt = useMemo(
		() =>
			refunds
				.filter((i) => i.patientId === userId)
				.reduce((a, b) => a + parseFloat(b.amount || 0), 0),
		[refunds],
	);
	const pendingAmt = useMemo(
		() => billingAmt - paidAmt - refundAmt,
		[billingAmt, paidAmt, refundAmt],
	);

	useEffect(() => {
		formik.setValues((prev) => ({
			...prev,
			billTotal: rows.map((i) => i.total).reduce((i, j) => i + j, 0),
		}));
	}, [rows]);

	useEffect(() => {
		const rate = formikRow.values.rate;
		const unit = formikRow.values.unit;
		const discountPercentage = formikRow.values.discountPercentage;

		calculateDiscAmt(rate, unit, discountPercentage);
	}, [formikRow.values.rate, formikRow.values.unit]);

	useEffect(() => {
		const rate = formikRow.values.rate;
		const unit = formikRow.values.unit;
		const discountAmount = formikRow.values.discountAmount;

		calculateDiscPercent(rate, unit, discountAmount);
	}, [formikRow.values.rate, formikRow.values.unit]);

	useEffect(() => {
		let mounted = true;

		const fetchOthers = () => {
			if (mounted) {
				dispatch(getNotesByType(noteTypes.NOTE_TYPE_BILL_ITEM));
				dispatch(getBillPrefix());
				getBillTypes().then((res) => setBillTypes(res));
				isPermitted(
					'medical profile',
					'read',
					() => {
						dispatch(getTreatments());
						dispatch(getPaymentMethods());
					},
					null,
					false,
				);
				isPermitted(
					'medical profile',
					'read',
					() => {
						dispatch(getPatientBills(userId));
						dispatch(getPatientPayments(userId));
					},
					null,
					false,
				);
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
			dispatch({
				type: SET_PATIENT_BILLS,
				payload: [],
			});
			dispatch({
				type: SET_PATIENT_PAYMENTS,
				payload: [],
			});
		};
	}, []);

	return (
		<>
			<Card stretch hasTab tabBodyClassName='p-0' className='shadow-none'>
				<CardTabItem id='patient-billing' title='Billing'>
					<CardHeader>
						<CardLabel icon='Payments' iconColor='primary'>
							<CardTitle>Billing</CardTitle>
							<CardSubTitle>
								<span className='me-3'>
									Billing Amount: {renderRupee(billingAmt)}
								</span>
								<span className='me-3'>OPD payments: {renderRupee(opdAmt)}</span>
								{hideBill && (
									<span className='me-3'>
										Estmiate payments: {renderRupee(estAmt)}
									</span>
								)}
								<span className='me-3'>Other Payments: {renderRupee(paidAmt)}</span>
								<span className='me-3' style={{ color: 'red' }}>
									Balance Pending: {renderRupee(pendingAmt)}
								</span>
							</CardSubTitle>
						</CardLabel>
						<CardActions>
							{hideBill && (
								<FormGroup label={'Bill Type'}>
									<Select
										className='rounded-0 shadow-none'
										style={{ border: '1px solid #cecece' }}
										list={Object.entries(billTypes).map(([key, value]) => ({
											text: key,
											value: value,
										}))}
										value={showEst}
										onChange={(e) => setShowEst(e.target.value)}
									/>
								</FormGroup>
							)}
							{selectTable.values.selectedList.length != 0 && (
								<Button
									icon='print'
									color='primary'
									isOutline
									onClick={() =>
										navigate(`/patients/${userId}/billing-pdf`, {
											state: {
												bills: onCurrentPageItems?.filter((item) =>
													selectTable.values.selectedList.includes(
														item.id?.toString(),
													),
												),
												patient,
												range: true,
											},
										})
									}>
									Print
								</Button>
							)}
							<Button
								isOutline={!darkModeStatus}
								isLight={darkModeStatus}
								color='primary'
								icon='add'
								onClick={() => isPermitted('Billing', 'write', handleUpcomingEdit)}
								aria-label='Create Bill'>
								Add
							</Button>
						</CardActions>
					</CardHeader>
					<CardBody>
						{onCurrentPageItems.map((item) => (
							<Card key={item.id}>
								<CardBody className='table-responsive'>
									<table className='table table-modern'>
										<thead>
											<tr>
												<th>
													<Checks
														id={item?.id?.toString()}
														name={'selectedList'}
														value={item?.id}
														onChange={selectTable.handleChange}
														checked={selectTable.values.selectedList.includes(
															item?.id?.toString(),
														)}
													/>
												</th>
												<th>Date</th>
												<th>Bill No</th>
												<th>Bill Total</th>
												<th>Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td />
												<td>{moment(item.date).format('DD-MM-YYYY')}</td>
												<td>{`${billPrefix?.opd?.toUpperCase()}-${
													item?.organisationBillNo || item?.billNo || '-'
												}`}</td>
												<td>{renderRupee(item.billTotal) || '-'}</td>
												<td>
													<Tooltips title='Edit'>
														<Button
															isOutline={!darkModeStatus}
															color='primary'
															isLight={darkModeStatus}
															className={classNames('text-nowrap', {
																'border-light': !darkModeStatus,
															})}
															icon='edit'
															onClick={() =>
																isPermitted('Billing', 'edit', () =>
																	handleUpcomingEdit(item),
																)
															}></Button>
													</Tooltips>{' '}
													<Tooltips title='Print'>
														<Button
															isOutline={!darkModeStatus}
															color='primary'
															isLight={darkModeStatus}
															className={classNames('text-nowrap', {
																'border-light': !darkModeStatus,
															})}
															icon='Print'
															onClick={() => {
																navigate(
																	`/patients/${userId}/billing-pdf`,
																	{
																		state: {
																			bills: [item],
																			patient,
																		},
																	},
																);
															}}></Button>
													</Tooltips>{' '}
													<Tooltips title='Delete'>
														<Button
															isOutline={!darkModeStatus}
															color='danger'
															isLight={darkModeStatus}
															className={classNames('text-nowrap', {
																'border-light': !darkModeStatus,
															})}
															icon='Delete'
															onClick={() =>
																isPermitted(
																	'Billing',
																	'delete',
																	() => handleDeleteBill(item.id),
																)
															}></Button>
													</Tooltips>
												</td>
											</tr>
										</tbody>
									</table>
									<table className='table table-modern'>
										<thead>
											<tr>
												<th>Procedure</th>
												<th>Rate</th>
												<th>Unit</th>
												<th>Discount</th>
												<th>Remarks</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody>
											{item.Bill_items?.map((i) => (
												<tr key={i.id}>
													<td>{i.name}</td>
													<td>{i.rate}</td>
													<td>{i.unit}</td>
													<td>{i.discountAmount}</td>
													<td>
														{i?.Bill_item_remarks?.map(
															(x) => x.remark,
														)?.join(', ') || '-'}
													</td>
													<td>{i.total}</td>
												</tr>
											))}
										</tbody>
									</table>
								</CardBody>
							</Card>
						))}
					</CardBody>
					<PaginationButtons
						id={scrollId}
						data={items}
						label='items'
						setCurrentPage={setCurrentPage}
						currentPage={currentPage}
						perPage={perPage}
						setPerPage={setPerPage}
					/>
				</CardTabItem>
				<CardTabItem id='payments' title='Payments'>
					<PatientPayment patient={patient} />
				</CardTabItem>
				<CardTabItem id='opdPayments' title='OPD Payments'>
					<PatientOpdPayments patient={patient} />
				</CardTabItem>
				<CardTabItem id='patientRefunds' title='Refund Payments'>
					<PatientRefunds patient={patient} />
				</CardTabItem>
				{hideBill ? (
					<CardTabItem id='estPayments' title='Estimate Payments'>
						<PatientEstimatePayments patient={patient} />
					</CardTabItem>
				) : (
					<></>
				)}
			</Card>
			<div id='billing'></div>
			<Modal
				isOpen={editMode}
				setIsOpen={setEditMode}
				size='xl'
				titleId='upcomingEdit'
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setEditMode}>
					<OffCanvasTitle id='upcomingEdit'>Add New</OffCanvasTitle>
				</ModalHeader>
				<ModalBody>
					<Card stretch>
						<CardBody>
							<div className='row g-4'>
								<div className={hideBill ? 'col-4' : 'col-6'}>
									<FormGroup id='date' label='Date'>
										<Input
											type='date'
											style={{
												height: 'calc(3.5rem + 2px)',
											}}
											placeholder='Date'
											autoComplete='date'
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											onError={formik.errors.date}
											value={formik.values.date}
											isValid={formik.isValid}
											isTouched={formik.touched.date}
											invalidFeedback={formik.errors.date}
										/>
									</FormGroup>
								</div>
								<div className={hideBill ? 'col-4' : 'col-6'}>
									<FormGroup id='billTotal' label='Bill Total'>
										<Input
											disabled
											id='billTotal'
											style={{
												height: 'calc(3.5rem + 2px)',
											}}
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											value={formik.values.billTotal}
											onChange={formik.handleChange}
										/>
									</FormGroup>
								</div>
								{hideBill && (
									<div className='col-4'>
										<FormGroup id='isEst' label='Bill Type'>
											<Select
												disabled={!!editItem}
												style={{
													height: 'calc(3.5rem + 2px)',
												}}
												className='form-control'
												list={Object.entries(billTypes).map(
													([key, value]) => ({
														text: key,
														value: value,
													}),
												)}
												value={formik.values.isEst}
												onChange={formik.handleChange}
											/>
										</FormGroup>
									</div>
								)}
								<div className='col-3'>
									<FormGroup label='Procedure'>
										<Creatable
											id='name'
											className='form-control'
											components={{
												IndicatorSeparator: () => null,
											}}
											styles={{
												control: () => ({
													position: 'relative',
													display: 'flex',
													height: '1.6rem',
													alignItems: 'center',
												}),
											}}
											value={selectedTreatment}
											options={treatments?.map((i) => ({
												value: i,
												label: `${i.name} ${
													i?.Treatment_organisation_tag?.Treatment_tag
														?.name
														? `(${i?.Treatment_organisation_tag?.Treatment_tag?.name})`
														: ''
												}`,
											}))}
											onCreateOption={(i) => {
												dispatch(
													createTreatment({
														name: i,
														perUnitPrice: 0,
													}),
												);
												formikRow.setValues((prev) => ({
													...prev,
													name: i,
												}));
												setSelectedTreatment({ value: i, label: i });
											}}
											onChange={(i) => {
												formikRow.setValues((prev) => ({
													...prev,
													name: i.label,
													rate:
														(i.value.isAdmin
															? i.value.Treatment_price?.perUnitPrice
															: i.value.perUnitPrice) || 0,
												}));
												setSelectedTreatment(i);
											}}
										/>
									</FormGroup>
								</div>
								<div className='col-3'>
									<FormGroup id='rate' label='Rate'>
										<Input
											id='rate'
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											onChange={formikRow.handleChange}
											value={formikRow.values.rate}
										/>
									</FormGroup>
								</div>
								<div className='col-3'>
									<FormGroup id='unit' label='Unit'>
										<Input
											id='unit'
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											onChange={formikRow.handleChange}
											value={formikRow.values.unit}
										/>
									</FormGroup>
								</div>
								<div className='col-3'>
									<FormGroup id='total' label='Total'>
										<Input
											disabled
											id='total'
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											onChange={formikRow.handleChange}
											value={formikRow.values.total}
										/>
									</FormGroup>
								</div>
								<div className='col-4'>
									<FormGroup id='discountPercentage' label='Discount Percentage'>
										<Input
											id='discountPercentage'
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											onChange={(e) => {
												calculateDiscAmt(
													formikRow.values.rate,
													formikRow.values.unit,
													e.target.value,
												);
												formikRow.handleChange(e);
											}}
											value={formikRow.values.discountPercentage}
										/>
									</FormGroup>
								</div>
								<div className='col-4'>
									<FormGroup id='discountAmount' label='Discount Amount'>
										<Input
											id='discountAmount'
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											onChange={(e) => {
												calculateDiscPercent(
													formikRow.values.rate,
													formikRow.values.unit,
													e.target.value,
												);
												formikRow.handleChange(e);
											}}
											value={formikRow.values.discountAmount}
										/>
									</FormGroup>
								</div>
								<div className='col-4'>
									<FormGroup id={'remarks'} label={'Remarks'}>
										<Creatable
											isMulti
											id='name'
											className='form-control'
											components={{
												IndicatorSeparator: () => null,
											}}
											styles={{
												control: () => ({
													position: 'relative',
													display: 'flex',
													height: '1.6rem',
													alignItems: 'center',
												}),
											}}
											value={formikRow.values.remarks}
											options={notes?.map((x) => ({
												label: x.name,
												value: x.name,
											}))}
											onCreateOption={(i) => {
												createNote({
													name: i,
													type: noteTypes.NOTE_TYPE_BILL_ITEM,
													text: i,
												});
												formikRow.setFieldValue('remarks', [
													...formikRow.values.remarks,
													{
														value: i,
														label: i,
													},
												]);
											}}
											onChange={(i) => {
												formikRow.setFieldValue('remarks', i);
											}}
										/>
									</FormGroup>
								</div>
							</div>
						</CardBody>
						<CardFooterRight className='me-3 mb-3'>
							<Button icon='Add' color='primary' isOutline onClick={handleAddRow}>
								Add
							</Button>
						</CardFooterRight>
					</Card>
					<div className='table-responsive'>
						<table className='table table-modern'>
							<thead>
								<tr>
									<th>Procedure</th>
									<th>Rate</th>
									<th>Unit</th>
									<th>Discount</th>
									<th>Total</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{rows.map((r, index) => (
									<tr key={index}>
										<td>{r.name || '-'}</td>
										<td>{r.rate}</td>
										<td>{r.unit}</td>
										<td>{r.discountAmount}</td>
										<td>{r.total}</td>
										<td>
											<Tooltips title='Edit'>
												<Button
													isOutline={!darkModeStatus}
													color='primary'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='edit'
													onClick={() =>
														handleRowEdit(r, index)
													}></Button>
											</Tooltips>{' '}
											<Tooltips title='Delete'>
												<Button
													isOutline={!darkModeStatus}
													color='danger'
													isLight={darkModeStatus}
													className={classNames('text-nowrap', {
														'border-light': !darkModeStatus,
													})}
													icon='Delete'
													onClick={() => handleDeleteRow(index)}></Button>
											</Tooltips>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</ModalBody>
				<ModalFooter className='bg-transparent'>
					<div
						className={
							!editItem
								? 'd-flex align-items-center justify-content-between w-100'
								: ''
						}>
						{!editItem && (
							<div className='row w-50'>
								<div className='col-4'>
									<FormGroup id='amountPaid' label='Amount Paid' isFloating>
										<Input
											style={{
												height: 'calc(3.5rem + 2px)',
											}}
											type='number'
											onKeyDown={(e) =>
												invalidNums.includes(e.key) && e.preventDefault()
											}
											placeholder='0'
											onChange={formikPayment.handleChange}
											onBlur={formikPayment.handleBlur}
											value={formikPayment.values.amountPaid}
											isValid={formikPayment.isValid}
											isTouched={formikPayment.touched.amountPaid}
											invalidFeedback={formikPayment.errors.amountPaid}
											validFeedback='Looks good!'
										/>
									</FormGroup>
								</div>
								<span className='text-muted col-1 d-flex align-items-center'>
									via
								</span>
								<div className='col-4'>
									<FormGroup id='paymentMode'>
										<Select
											style={{
												height: 'calc(3.5rem + 2px)',
											}}
											ariaLabel='select-payment-mode'
											placeholder='Select...'
											onChange={formikPayment.handleChange}
											onBlur={formikPayment.handleBlur}
											isValid={formikPayment.isValid}
											isTouched={formikPayment.touched.paymentMode}
											invalidFeedback={formikPayment.errors.paymentMode}
											value={formikPayment.values.paymentMode}
											list={paymentModes.map((i) => ({ value: i, text: i }))}
										/>
									</FormGroup>
								</div>
							</div>
						)}
						<Button
							icon={editItem ? 'Save' : 'Add'}
							color='primary'
							isOutline
							isDisable={!formik.isValid && !!formik.submitCount}
							onClick={formik.handleSubmit}>
							{editItem ? 'Save Bill' : 'Add Bill'}
						</Button>
					</div>
				</ModalFooter>
			</Modal>
			<Modal
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteTreatmentLabel'>Warning</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to delete the bill?</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => setDeleteWarn(false)}>
						Cancel
					</Button>
					<Button color='danger' icon='delete' onClick={handleDeleteConfirm}>
						Delete
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default PatientBilling;
