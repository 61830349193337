import {
	SET_INVENTORIES,
	LOADING_INVENTORIES,
	CREATE_INVENTORY,
	UPDATE_INVENTORY,
	DELETE_INVENTORY,
} from './types';

const initialState = {
	inventories: [],
	page: 1,
	total: 0,
	error: null,
	loading: false,
};

const inventoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_INVENTORIES:
			return {
				...state,
				loading: true,
			};

		case SET_INVENTORIES:
			return {
				...state,
				inventories: action.payload.inventory || [],
				page: action.payload.page || 1,
				total: action.payload.total || 0,
				loading: false,
			};

		case CREATE_INVENTORY:
			return {
				...state,
				inventories: [action.payload, ...state.inventories],
			};

		case UPDATE_INVENTORY:
			const inventoryIdx = _.findIndex(state.inventories, (s) => s.id === action.payload.id);
			return {
				...state,
				inventories: [
					...state.inventories.slice(0, inventoryIdx),
					action.payload,
					...state.inventories.slice(inventoryIdx + 1),
				],
			};

		case DELETE_INVENTORY:
			state.inventories = state.inventories.filter((ig) => ig.id != action.payload);
			return {
				...state,
				inventories: _.reject(state.inventories, (s) => s.id === action.payload),
			};

		default:
			return state;
	}
};

export default inventoryReducer;
