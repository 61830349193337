import {
	SET_SALE_VOUCHERS,
	CREATE_SALE_VOUCHER,
	UPDATE_SALE_VOUCHER,
	DELETE_SALE_VOUCHER,
	SET_PAYMENT_METHODS,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { dashboardMenu } from '../menu';
import axiosz from 'axios';
import { toggleCreateLoading, toggleTableLoading } from './componentActions';
import { DefaultPagination, currentOrg } from '../helpers/config';

let cancelToken;
export const getSaleVouchers =
	(params = DefaultPagination, { organisationId } = { organisationId: currentOrg }) =>
	async (dispatch) => {
		var url = '/sales-voucher';
		dispatch(toggleTableLoading(true));
		try {
			if (typeof cancelToken != typeof undefined) {
				cancelToken?.cancel('Cancelling prev req');
			}
			cancelToken = axiosz.CancelToken?.source();
			axiosz.defaults.headers.common.Authorization = localStorage.getItem(`ehrToken`);
			const res = await axiosz.get(url, {
				cancelToken: cancelToken?.token,
				params: { pagination: true, ...params },
				baseURL: process.env.REACT_APP_SERVER_URL,
			});
			if (res.data.success) {
				dispatch({
					type: SET_SALE_VOUCHERS,
					payload: {
						vouchers: res.data.data,
						totalBilled: res.data.totalBilled,
						page: res.data.totalPage,
						total: res.data.totalRecord,
					},
				});
			}
		} catch (err) {
			console.log(err);
			setTimeout(() => dispatch(toggleTableLoading(false)), 2000);
			return;
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const createSaleVoucher =
	(newVoucher, navigate, state = {}) =>
	async (dispatch) => {
		try {
			const res = await axios.post('/sales-voucher', newVoucher);
			if (res.data.success) {
				showNotification('Success', 'The sales voucher is created successfully', 'success');
				navigate(`/${dashboardMenu.pharmacy.subMenu.saleVouchers.path}`, state);
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const updateSaleVoucher = (updateBody, navigate) => async (dispatch) => {
	dispatch(toggleCreateLoading(true));
	try {
		const res = await axios.patch('/sales-voucher', updateBody);
		if (res.data.success) {
			showNotification('Success', 'The sales voucher is updated successfully', 'success');
			dispatch(getSaleVouchers());
			if (navigate) navigate(`/${dashboardMenu.pharmacy.subMenu.saleVouchers.path}`);
		}
	} catch (err) {
		console.log(err);
	} finally {
		dispatch(toggleCreateLoading(false));
	}
};

export const deleteSalesVoucherItem = async (saleVoucherItemId) => {
	try {
		const res = await axios.delete(`/sales-voucher/items/${saleVoucherItemId}`);
		if (res.data.success) {
			showNotification('Success', 'The sales voucher is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteSaleVoucher = (saleVoucherId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/sales-voucher/${saleVoucherId}`);
		if (res.data.success) {
			dispatch({
				type: DELETE_SALE_VOUCHER,
				payload: saleVoucherId,
			});
			showNotification(
				'Success',
				'The sale voucher has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteSaleVoucherItem = (saleVoucherItemId) => async (dispatch) => {
	try {
		const res = await axios.delete(`/sales-voucher/items/${saleVoucherItemId}`);
		if (res.data.success) {
			dispatch(getSaleVouchers());
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPaymentMethods = () => async (dispatch) => {
	try {
		// {{url}}/api/doctor/pharmacy/stockist/payment/all
		const res = await axios.get('/sales-voucher/payment-types');
		if (res.data.success) {
			dispatch({
				type: SET_PAYMENT_METHODS,
				payload: res.data.data,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const returnSalesVoucher =
	(returnBody, setModal, queryParams, dispatchParams) => async (dispatch) => {
		dispatch(toggleCreateLoading(true));
		try {
			const res = await axios.post('/sales-voucher/add-return-items', returnBody);
			if (res.data.success) {
				showNotification(
					'Success',
					'The sales voucher is returned successfully',
					'success',
				);
				dispatch(getSaleVouchers(queryParams, dispatchParams));
				setModal(false);
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};

export const collectSalesVoucher =
	(collectBody, setModal, queryParams, dispatchParams) => async (dispatch) => {
		try {
			const res = await axios.post('/sales-voucher/collect-balance', collectBody);
			if (res.data.success) {
				showNotification(
					'Success',
					'The sales voucher is collected successfully',
					'success',
				);
				dispatch(getSaleVouchers(queryParams, dispatchParams));
				setModal(false);
			}
		} catch (err) {
			console.log(err);
		} finally {
			dispatch(toggleCreateLoading(false));
		}
	};
