import { combineReducers } from 'redux';

import authReducer from './authReducer';
import organisationReducer from './organisationReducer';
import specialityReducer from './specialityReducer';
import departmentReducer from './departmentReducer';
import doctorReducer from './doctorReducer';
import patientReducer from './patientReducer';
import pharmacyReducer from './pharmacyReducer';
import pharmacyVendorReducer from './pharmacyVendorReducer';
import purchaseVoucherReducer from './purchaseVoucherReducer';
import saleVoucherReducer from './saleVoucherReducer';
import inventoryReducer from './inventoryReducer';
import amenityReducer from './amenityReducer';
import opdReducer from './opdReducer';
import ipdReducer from './ipdReducer';
import ipdPatientReducer from './ipdPatientReducer';
import staffReducer from './staffReducer';
import settingReducer from './settingReducer';
import profileReducer from './profileReducer';
import appointmentReducer from './appointmentReducer';
import accountReducer from './accountReducer';
import labReducer from './labReducer';
import historyReducer from './historyReducer';
import visionReducer from './visionReducer';
import examsReducer from './examsReducer';
import immunReducer from './immunReducer';
import { AUTH_LOGOUT } from './types';
import pcpReducer from './pcpReducer';
import soapReducer from './soapReducer';
import buttonReducer from './buttonReducer';
import paniniReducer from './paniniReducer';
import componentReducer from './componentReducer';
import abhaReducer from './abhaReducer';
import patientMisReducer from './mis/patient-mis';
import doctorMisReducer from './mis/doctor-mis';
import telecrmReducer from './telecrmReducer';

const appReducer = combineReducers({
	auth: authReducer,
	profile: profileReducer,
	organisation: organisationReducer,
	speciality: specialityReducer,
	department: departmentReducer,
	doctor: doctorReducer,
	patient: patientReducer,
	pharmacy: pharmacyReducer,
	pharmacyVendor: pharmacyVendorReducer,
	purchaseVoucher: purchaseVoucherReducer,
	saleVoucher: saleVoucherReducer,
	inventory: inventoryReducer,
	amenity: amenityReducer,
	appointment: appointmentReducer,
	opd: opdReducer,
	ipd: ipdReducer,
	ipdPatient: ipdPatientReducer,
	staff: staffReducer,
	setting: settingReducer,
	account: accountReducer,
	lab: labReducer,
	history: historyReducer,
	vision: visionReducer,
	exams: examsReducer,
	pcp: pcpReducer,
	immun: immunReducer,
	soap: soapReducer,
	button: buttonReducer,
	panini: paniniReducer,
	component: componentReducer,
	abha: abhaReducer,
	patientMIS: patientMisReducer,
	doctorMIS: doctorMisReducer,
	telecrm: telecrmReducer,
});

const rootReducer = (state, action) => {
	if (action.type === AUTH_LOGOUT) {
		return appReducer(undefined, action);
	}

	return appReducer(state, action);
};

export default rootReducer;
