import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getNonExpiredSharedUserReportsToVendor,
	getPatientDocument,
	saveSharedDocuments,
	stopSharedDocuments,
} from '../../../../actions/patientActions';
import useDarkMode from '../../../../hooks/useDarkMode';
import classNames from 'classnames';
import Checks from '../../../../components/bootstrap/forms/Checks';
import Button from '../../../../components/bootstrap/Button';
import Popovers from '../../../../components/bootstrap/Popovers';
import { Calendar as DatePicker } from 'react-date-range';
import moment from 'moment';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import ReactSelect from 'react-select';
import { Action } from '../../../../components/Table';
import showNotification from '../../../../components/extras/showNotification';
import AUDIO from '../../../../assets/img/audio.png';
import PDF from '../../../../assets/img/pdf.png';
import DOC from '../../../../assets/img/doc.png';
import CROSS from '../../../../assets/img/cross.png';
// import Button from '../bootstrap/Button';

export default function UserFileSharing({ doctor }) {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const patient = useSelector((state) => state.patient.patient);
	const patientDocuments = useSelector((state) => state.patient.documents);
	const shareReportsNonExpired = useSelector((state) => state.patient.shareReportsNonExpired);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [expiryDate, setExpiryDate] = useState('');
	const [organisation, setOrganisation] = useState([]);
	const [sharedFilesObj, setSharedFilesObj] = useState({});
	const { soapAttachments, soapAttachRemark } = useSelector((state) => state.soap);
	const [documents, setDocuments] = useState([]);

	const { vendorId: loggedInVendorId } = useSelector((state) => state.auth.user.Vendor_detail);

	useEffect(() => {
		setDocuments((prev) => [
			...patientDocuments.map((item) => ({ ...item, fileCategory: 'report' })),
			...soapAttachments.map((item) => ({ ...item, fileCategory: 'file' })),
		]);
	}, [patientDocuments, soapAttachments]);
	useEffect(() => {
		const obj = {};
		shareReportsNonExpired?.forEach((item) => {
			obj[item.path] = item;
		});

		setSharedFilesObj(obj);
	}, [shareReportsNonExpired]);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			dispatch(getPatientDocument(patient.id, 1, 1000));
			dispatch(getNonExpiredSharedUserReportsToVendor(doctor?.Vendor?.id));
		}
		return () => {
			isMounted = false;
		};
	}, []);

	const handleSelectAllFiles = (e) => {
		if (selectedFiles.length >= 1 && patientDocuments.length === selectedFiles.length) {
			setSelectedFiles([]);
		} else {
			setSelectedFiles(patientDocuments);
		}
	};

	const handleShareFiles = async () => {
		try {
			if (!organisation.value) {
				throw new Error('Please select organisation');
			}
			const files = selectedFiles?.map((item) => {
				if (item.fileCategory === 'file') {
					return {
						id: sharedFilesObj?.[item.filename]?.id,
						organisationId: organisation.value,
						vendorId: doctor?.Vendor?.id,
						userId: patient.id,
						filename: item.type,
						path: item.image,
						fileCategory: item.fileCategory,
						expiredAt: addTimeToDate(expiryDate),
					};
				}
				return {
					id: sharedFilesObj?.[item.filename]?.id,
					organisationId: organisation.value,
					vendorId: doctor?.Vendor?.id,
					userId: patient.id,
					filename: item.type,
					path: item.name,
					fileCategory: item.fileCategory,
					expiredAt: addTimeToDate(expiryDate),
				};
			});
			dispatch(
				saveSharedDocuments(files, doctor?.Vendor?.id, () => {
					setSelectedFiles([]);
					// dispatch(getNonExpiredSharedUserReportsToVendor(patient.id))
				}),
			);
		} catch (err) {
			if (err.message == 'INVALID_DATE') {
				// alert('Please select valid date');
				showNotification('Error', 'Please select valid date');
			} else {
				showNotification('Error', err.message);
			}
		}
	};

	const handleStopSharing = async (sharedDocumentId) => {
		dispatch(stopSharedDocuments(sharedDocumentId, doctor?.Vendor?.id));
	};

	function addTimeToDate(expiry) {
		try {
			const currentDate = new Date();
			const futureDate = new Date(currentDate);
			const [amount, unit] = expiry.split(' ');
			if (isNaN(amount)) {
				throw new Error('INVALID_DATE');
			}

			switch (unit.toLowerCase()) {
				case 'days':
					futureDate.setDate(currentDate.getDate() + parseInt(amount));
					break;
				case 'weeks':
					futureDate.setDate(currentDate.getDate() + parseInt(amount) * 7);
					break;
				case 'months':
					futureDate.setMonth(currentDate.getMonth() + parseInt(amount));
					break;
				default:
					throw new Error('INVALID_DATE');
			}

			return futureDate.toISOString().split('T')[0];
		} catch (err) {
			throw new Error('INVALID_DATE');
		}
	}

	function daysUntilFutureDate(futureDateString) {
		const futureDate = new Date(futureDateString);
		const currentDate = new Date();

		// To calculate the time difference of two dates
		const timeDifference = futureDate.getTime() - currentDate.getTime();

		// To calculate the no. of days between two dates
		const daysDifference = timeDifference / (1000 * 3600 * 24);

		return Math.ceil(daysDifference); // Round up to ensure we count the current day
	}
	return (
		<table className='table table-modern'>
			<thead>
				{selectedFiles.length >= 1 ? (
					<tr>
						<th>
							{/* //check box */}
							<Checks
								id={'selectAllCheck'}
								name={'selectedList'}
								value={
									selectedFiles.length >= 1 &&
									patientDocuments.length === selectedFiles.length
								}
								onChange={handleSelectAllFiles}
								checked={
									selectedFiles.length >= 1 &&
									patientDocuments.length === selectedFiles.length
								}
							/>
						</th>

						<th colSpan={2}>
							<label>Share For</label>
							<div className='d-flex'>
								<FormGroup id='expiry' label=''>
									<Input
										id='expiryInput'
										name='expiry'
										autoComplete='off'
										style={{
											borderRadius: 0,
										}}
										onChangeItem={(e) => {
											setExpiryDate(
												(isNaN(parseInt(expiryDate))
													? '1'
													: parseInt(expiryDate)) +
													' ' +
													e.name || 'Days',
											);
										}}
										onChange={(e) => setExpiryDate(e.target.value)}
										value={expiryDate}
										list={[
											{ name: 'Days' },
											{ name: 'Weeks' },
											{ name: 'Months' },
										]}
									/>
								</FormGroup>
								<Popovers
									desc={
										<DatePicker
											minDate={moment().toDate()}
											onChange={(date) => {
												const diff = moment(date)
													.startOf('day')
													.diff(moment().startOf('day'), 'days');
												setExpiryDate(`${diff} Days`);
												document.getElementById('calendarBtn').click();
											}}
											color={process.env.REACT_APP_PRIMARY_COLOR}
										/>
									}
									placement='bottom-end'
									className='mw-100'>
									<Button
										id='calendarBtn'
										style={{
											borderRadius: 0,
										}}
										color='light'
										icon='calendar'
									/>
								</Popovers>
							</div>
						</th>

						<th style={{ width: '250px' }}>
							<ReactSelect
								id='selectedOrg'
								className='form-control'
								Placeholder='Select Orrganisation'
								components={{
									DropdownIndicator: () => null,
									IndicatorSeparator: () => null,
								}}
								value={organisation}
								options={doctor.Vendor?.Vendor_organisations?.map((i) => ({
									value: i?.Organisation?.id,
									label: `${i?.Organisation.name} `,
								}))}
								onChange={(i) => {
									setOrganisation(i);
								}}
							/>
						</th>
						<th>
							<Button
								onClick={() => {
									handleShareFiles();
								}}
								color='primary'>
								Share
							</Button>
						</th>
					</tr>
				) : (
					<tr>
						<th>
							{/* //check box */}
							<Checks
								id={'selectAllCheck'}
								name={'selectedList'}
								value={
									selectedFiles.length >= 1 &&
									patientDocuments.length === selectedFiles.length
								}
								onChange={handleSelectAllFiles}
								checked={
									selectedFiles.length >= 1 &&
									patientDocuments.length === selectedFiles.length
								}
							/>
						</th>
						<th>S.No.</th>
						<th>Name</th>
						{/* <th>Remark</th> */}
						<th>Shared Status</th>
						<th>Action</th>
					</tr>
				)}
			</thead>
			<tbody>
				{documents?.map?.((item, index) => {
					const isSelected = selectedFiles.find((file) => file.id === item.id);
					const sharedFile = sharedFilesObj?.[item.name || item.image];
					return (
						<tr>
							<td>
								<Checks
									id={'selectSingle' + index}
									name={'selectedList'}
									value={isSelected}
									onChange={() => {
										if (isSelected) {
											setSelectedFiles((prev) =>
												prev.filter((file) => file.id !== item.id),
											);
										} else {
											setSelectedFiles((prev) => [...prev, item]);
										}
									}}
									checked={isSelected}
								/>
							</td>
							<td>{index + 1}</td>
							<td>
								{item.fileCategory === 'file' ? (
									<div className='image-container'>
										<a
											href={item?.image}
											target='_blank'
											rel='noreferrer'
											className='hover-image-link'>
											{(item?.image.includes('png') ||
												item?.image.includes('jpg') ||
												item?.image.includes('jpeg') ||
												item?.image.includes('JPEG') ||
												item?.image.includes('.svg')) && (
												<img
													src={item?.image}
													alt={item?.image.substring(
														item?.image.lastIndexOf('/') + 1,
													)}
												/>
											)}
											{item?.image.includes('pdf') && (
												<img
													src={PDF}
													alt={item?.image.substring(
														item?.image.lastIndexOf('/') + 1,
													)}
												/>
											)}
											{item?.image.includes('mp3') && (
												<img
													src={AUDIO}
													alt={item?.image.substring(
														item?.image.lastIndexOf('/') + 1,
													)}
												/>
											)}
											{!(
												item?.image.includes('png') ||
												item?.image.includes('jpg') ||
												item?.image.includes('jpeg') ||
												item?.image.includes('JPEG') ||
												item?.image.includes('.svg')
											) &&
												!item?.file?.type.includes('pdf') &&
												!item?.file?.type.includes('audio') && (
													<img
														src={DOC}
														alt={item?.image.substring(
															item?.image.lastIndexOf('/') + 1,
														)}
													/>
												)}
											<br />
											{item?.image.substring(
												item?.image.lastIndexOf('/') + 1,
											)}
										</a>
									</div>
								) : (
									`${item?.type}`
								)}
							</td>
							{/* <td>{item?.remarks}</td> */}
							<td>
								{sharedFile?.expiredAt
									? moment(sharedFile?.expiredAt).format('DD-MM-YYYY') +
									  `(${daysUntilFutureDate(sharedFile?.expiredAt)} Days)`
									: 'Not Shared'}
							</td>
							<td>
								{sharedFile?.expiredAt ? (
									<Action
										icon='slash-circle'
										variant='danger'
										title={'Stop Sharing'}
										onClickFunction={() => {
											handleStopSharing(sharedFile?.id);
										}}
									/>
								) : null}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}
