import _ from 'lodash';
import {
	SET_PATIENTS,
	LOADING_PATIENTS,
	CREATE_PATIENT,
	UPDATE_PATIENT,
	DELETE_PATIENT,
	SET_PATIENT_EXAMINATIONS,
	CREATE_PATIENT_EXAMINATION,
	UPDATE_PATIENT_EXAMINATION,
	DELETE_PATIENT_EXAMINATION,
	SET_PATIENT_TREATMENT_PLANS,
	CREATE_PATIENT_TREATMENT_PLAN,
	UPDATE_PATIENT_TREATMENT_PLAN,
	DELETE_PATIENT_TREATMENT_PLAN,
	SET_PATIENT_TREATMENTS,
	CREATE_PATIENT_TREATMENT,
	UPDATE_PATIENT_TREATMENT,
	DELETE_PATIENT_TREATMENT,
	SET_PATIENT_PRESCRIPTIONS,
	CREATE_PATIENT_PRESCRIPTION,
	UPDATE_PATIENT_PRESCRIPTION,
	DELETE_PATIENT_PRESCRIPTION,
	SET_PATIENT_BILLS,
	CREATE_PATIENT_BILL,
	UPDATE_PATIENT_BILL,
	DELETE_PATIENT_BILL,
	SET_PATIENT_PAYMENTS,
	CREATE_PATIENT_PAYMENT,
	UPDATE_PATIENT_PAYMENT,
	DELETE_PATIENT_PAYMENT,
	SET_PATIENT,
	SET_PATIENT_APPOINTMENTS,
	UPDATE_PATIENT_APPOINTMENT,
	DELETE_PATIENT_APPOINTMENT,
	CREATE_PATIENT_APPOINTMENT,
	SET_PATIENT_DOCUMENTS,
	SET_TIMINGS,
	SET_PATIENT_PICKLIST,
	SET_PEDIGREE_CHARTS,
	CREATE_PEDIGREE_CHART,
	UPDATE_PEDIGREE_CHART,
	DELETE_PEDIGREE_CHART,
	SET_BONE_AGE,
	CREATE_BONE_AGE,
	UPDATE_BONE_AGE,
	DELETE_BONE_AGE,
	SET_TTKG,
	CREATE_TTKG,
	UPDATE_TTKG,
	DELETE_TTKG,
	SET_THC,
	CREATE_THC,
	UPDATE_THC,
	DELETE_THC,
	SET_GROWTH,
	CREATE_GROWTH,
	UPDATE_GROWTH,
	DELETE_GROWTH,
	SET_PATIENT_CATEGORY,
	SET_EXAMINATION_PARAMETERS,
	CREATE_EXAMINATION_PARAMETER,
	DELETE_EXAMINATION_PARAMETER,
	SET_PATIENT_OPD_DATES,
	SET_CC_SYMPTOMS,
	SET_MEDICAL_ALERTS,
	SET_EXAMINATION_TEMPLATES,
	SET_CURRENT_PATIENT,
	SET_WHITEBOARD,
	SET_CLINICAL_NOTES,
	SET_PATIENT_VOICE_PRESCRIPTIONS,
	CREATE_PATIENT_VOICE_PRESCRIPTION,
	SET_PATIENT_INVESTIGATIONS,
	CREATE_PATIENT_INVESTIGATION,
	UPDATE_PATIENT_INVESTIGATION,
	DELETE_PATIENT_INVESTIGATION,
	SET_PATIENT_SHARE_REPORTS,
	SET_GYNAE_DATA,
	DELETE_PATIENT_INVESTIGATION_TEMPLATE,
	UPDATE_PATIENT_INVESTIGATION_TEMPLATE,
	CREATE_PATIENT_INVESTIGATION_TEMPLATE,
	SET_PATIENT_INVESTIGATION_TEMPLATES,
	SET_PATIENTS_SEARCH_LIST,
	SET_PATIENT_SHARE_REPORTS_NON_EXPIRED,
	SET_PATIENT_REFUND_PAYMENTS,
	SET_PATIENT_VISITS,
} from './types';

const initialState = {
	currentPatient: {},
	pickList: null,
	patients: [],
	searchPatientList: [],
	patient: null,
	page: 1,
	total: 0,
	examinationRecords: [],
	treatmentPlans: [],
	treatments: [],
	prescriptions: [],
	voicePrescriptions: [],
	investigations: [],
	investigationTemplates: [],
	timings: [],
	bills: [],
	payments: [],
	refundPayments: [],
	opds: [],
	appointments: [],
	documents: [],
	charts: [],
	bone: [],
	ttkg: [],
	thc: [],
	growth: [],
	category: [],
	opdDates: [],
	symptoms: [],
	medicalAlerts: [],
	shareReports: [],
	examinationTemplates: [],
	whiteBoard: [],
	clinicalNotes: [],
	gynaeData: [],
	error: null,
	loading: false,
	shareReportsNonExpired: [],
	shareReportURLNonExpired: false,
	shareFileURLNonExpired: false,
};

const patientReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PATIENTS:
			return {
				...state,
				loading: true,
			};

		case SET_PATIENTS:
			return {
				...state,
				patients: action.payload.patients || [],
				page: action.payload.page || 1,
				total: action.payload.total || 0,
				loading: false,
			};

		case SET_PATIENT:
			return {
				...state,
				patient: action.payload || null,
				prescriptions: [],
				loading: false,
			};

		case CREATE_PATIENT:
			return {
				...state,
				patients: [action.payload, ...state.patients],
			};

		case UPDATE_PATIENT:
			const patientIdx = _.findIndex(state.patients, (p) => p.id === action.payload.id);
			return {
				...state,
				patients: [
					...state.patients.slice(0, patientIdx),
					action.payload,
					...state.patients.slice(patientIdx + 1),
				],
			};

		case DELETE_PATIENT:
			return {
				...state,
				patients: _.reject(state.patients, (p) => p.id === action.payload.id),
			};

		case SET_PATIENTS_SEARCH_LIST:
			return {
				...state,
				searchPatientList: action.payload || [],
			};

		case SET_PATIENT_EXAMINATIONS:
			return {
				...state,
				examinationRecords: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_EXAMINATION:
			return {
				...state,
				examinationRecords: [action.payload, ...state.examinationRecords],
			};

		case UPDATE_PATIENT_EXAMINATION:
			const patientExamIdx = _.findIndex(
				state.examinationRecords,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				examinationRecords: [
					...state.examinationRecords.slice(0, patientExamIdx),
					action.payload,
					...state.examinationRecords.slice(patientExamIdx + 1),
				],
			};

		case DELETE_PATIENT_EXAMINATION:
			return {
				...state,
				examinationRecords: _.reject(
					state.examinationRecords,
					(p) => p.id === action.payload,
				),
			};

		case SET_PATIENT_TREATMENT_PLANS:
			return {
				...state,
				treatmentPlans: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_TREATMENT_PLAN:
			return {
				...state,
				treatmentPlans: [action.payload, ...state.treatmentPlans],
			};

		case UPDATE_PATIENT_TREATMENT_PLAN:
			const patientTPlanIdx = _.findIndex(
				state.treatmentPlans,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				treatmentPlans: [
					...state.treatmentPlans.slice(0, patientTPlanIdx),
					action.payload,
					...state.treatmentPlans.slice(patientTPlanIdx + 1),
				],
			};

		case DELETE_PATIENT_TREATMENT_PLAN:
			return {
				...state,
				treatmentPlans: _.reject(state.treatmentPlans, (p) => p.id === action.payload),
			};

		case SET_PATIENT_TREATMENTS:
			return {
				...state,
				treatments: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_TREATMENT:
			return {
				...state,
				treatments: [action.payload, ...state.treatments],
			};

		case UPDATE_PATIENT_TREATMENT:
			const patientTreatIdx = _.findIndex(
				state.treatments,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				treatments: [
					...state.treatments.slice(0, patientTreatIdx),
					action.payload,
					...state.treatments.slice(patientTreatIdx + 1),
				],
			};

		case DELETE_PATIENT_TREATMENT:
			return {
				...state,
				treatments: _.reject(state.treatments, (p) => p.id === action.payload),
			};

		case SET_PATIENT_PRESCRIPTIONS:
			return {
				...state,
				prescriptions: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_PRESCRIPTION:
			return {
				...state,
				prescriptions: [action.payload, ...state.prescriptions],
			};

		case UPDATE_PATIENT_PRESCRIPTION:
			const patientPrescIdx = _.findIndex(
				state.prescriptions,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				prescriptions: [
					...state.prescriptions.slice(0, patientPrescIdx),
					action.payload,
					...state.prescriptions.slice(patientPrescIdx + 1),
				],
			};

		case DELETE_PATIENT_PRESCRIPTION:
			return {
				...state,
				prescriptions: _.reject(state.prescriptions, (p) => p.id === action.payload),
			};

		case SET_PATIENT_VOICE_PRESCRIPTIONS:
			return {
				...state,
				voicePrescriptions: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_VOICE_PRESCRIPTION:
			return {
				...state,
				voicePrescriptions: [action.payload, ...state.voicePrescriptions],
			};

		case SET_PATIENT_INVESTIGATIONS:
			return {
				...state,
				investigations: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_INVESTIGATION:
			return {
				...state,
				investigations: [action.payload, ...state.investigations],
			};

		case UPDATE_PATIENT_INVESTIGATION:
			const patientInvestigationIdx = _.findIndex(
				state.investigations,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				investigations: [
					...state.investigations.slice(0, patientInvestigationIdx),
					action.payload,
					...state.investigations.slice(patientInvestigationIdx + 1),
				],
			};

		case DELETE_PATIENT_INVESTIGATION:
			return {
				...state,
				investigations: _.reject(state.investigations, (p) => p.id === action.payload),
			};

		case SET_PATIENT_INVESTIGATION_TEMPLATES:
			return {
				...state,
				investigationTemplates: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_INVESTIGATION_TEMPLATE:
			return {
				...state,
				investigationTemplates: [action.payload, ...state.investigations],
			};

		case UPDATE_PATIENT_INVESTIGATION_TEMPLATE:
			const patientInvestigationTIdx = _.findIndex(
				state.investigationTemplates,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				investigationTemplates: [
					...state.investigationTemplates.slice(0, patientInvestigationTIdx),
					action.payload,
					...state.investigationTemplates.slice(patientInvestigationTIdx + 1),
				],
			};

		case DELETE_PATIENT_INVESTIGATION_TEMPLATE:
			return {
				...state,
				investigationTemplates: _.reject(
					state.investigationTemplates,
					(p) => p.id === action.payload,
				),
			};

		case SET_PATIENT_BILLS:
			return {
				...state,
				bills: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_BILL:
			return {
				...state,
				bills: [action.payload, ...state.bills],
			};

		case UPDATE_PATIENT_BILL:
			const patientBillIdx = _.findIndex(state.bills, (p) => p.id === action.payload.id);
			return {
				...state,
				bills: [
					...state.bills.slice(0, patientBillIdx),
					action.payload,
					...state.bills.slice(patientBillIdx + 1),
				],
			};

		case DELETE_PATIENT_BILL:
			return {
				...state,
				bills: _.reject(state.bills, (p) => p.id === action.payload),
			};

		case SET_PATIENT_PAYMENTS:
			return {
				...state,
				payments: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_PAYMENT:
			return {
				...state,
				payments: [action.payload, ...state.payments],
			};

		case UPDATE_PATIENT_PAYMENT:
			const patientPaymentIdx = _.findIndex(
				state.payments,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				payments: [
					...state.payments.slice(0, patientPaymentIdx),
					action.payload,
					...state.payments.slice(patientPaymentIdx + 1),
				],
			};

		case DELETE_PATIENT_PAYMENT:
			return {
				...state,
				payments: _.reject(state.payments, (p) => p.id === action.payload),
			};

		case SET_PATIENT_APPOINTMENTS:
			return {
				...state,
				appointments: action.payload || [],
				loading: false,
			};

		case CREATE_PATIENT_APPOINTMENT:
			return {
				...state,
				appointments: [action.payload, ...state.appointments],
			};

		case UPDATE_PATIENT_APPOINTMENT:
			const patientApptmtIdx = _.findIndex(
				state.appointments,
				(p) => p.id === action.payload.id,
			);
			return {
				...state,
				appointments: [
					...state.appointments.slice(0, patientApptmtIdx),
					action.payload,
					...state.appointments.slice(patientApptmtIdx + 1),
				],
			};

		case DELETE_PATIENT_APPOINTMENT:
			return {
				...state,
				appointments: _.reject(state.appointments, (p) => p.id === action.payload),
			};

		case SET_CC_SYMPTOMS:
			return {
				...state,
				symptoms: action.payload || [],
			};

		case SET_MEDICAL_ALERTS:
			return {
				...state,
				medicalAlerts: action.payload || [],
				loading: false,
			};

		case SET_TIMINGS:
			return {
				...state,
				timings: action.payload || [],
				loading: false,
			};

		case SET_PATIENT_DOCUMENTS:
			return {
				...state,
				documents: action.payload || [],
				loading: false,
			};
		case SET_PATIENT_PICKLIST:
			return {
				...state,
				pickList: action.payload || null,
				loading: false,
			};
		case SET_PEDIGREE_CHARTS:
			return {
				...state,
				charts: action.payload || [],
				loading: false,
			};

		case CREATE_PEDIGREE_CHART:
			return {
				...state,
				charts: [action.payload, ...state.charts],
			};

		case UPDATE_PEDIGREE_CHART:
			const patientChartsIdx = _.findIndex(state.charts, (p) => p.id === action.payload.id);
			return {
				...state,
				charts: [
					...state.charts.slice(0, patientChartsIdx),
					action.payload,
					...state.charts.slice(patientChartsIdx + 1),
				],
			};

		case DELETE_PEDIGREE_CHART:
			return {
				...state,
				charts: _.reject(state.charts, (p) => p.id === action.payload),
			};

		case SET_BONE_AGE:
			return {
				...state,
				bone: action.payload || [],
				loading: false,
			};

		case CREATE_BONE_AGE:
			return {
				...state,
				bone: [action.payload, ...state.bone],
			};

		case UPDATE_BONE_AGE:
			const patientBonesIdx = _.findIndex(state.bone, (p) => p.id === action.payload.id);
			return {
				...state,
				bone: [
					...state.bone.slice(0, patientBonesIdx),
					action.payload,
					...state.bone.slice(patientBonesIdx + 1),
				],
			};

		case DELETE_BONE_AGE:
			return {
				...state,
				bone: _.reject(state.bone, (p) => p.id === action.payload),
			};

		case SET_TTKG:
			return {
				...state,
				ttkg: action.payload || [],
				loading: false,
			};

		case CREATE_TTKG:
			return {
				...state,
				ttkg: [action.payload, ...state.ttkg],
			};

		case UPDATE_TTKG:
			const patientTTKGIdx = _.findIndex(state.ttkg, (p) => p.id === action.payload.id);
			return {
				...state,
				ttkg: [
					...state.ttkg.slice(0, patientTTKGIdx),
					action.payload,
					...state.ttkg.slice(patientTTKGIdx + 1),
				],
			};

		case DELETE_TTKG:
			return {
				...state,
				ttkg: _.reject(state.ttkg, (p) => p.id === action.payload),
			};

		case SET_THC:
			return {
				...state,
				thc: action.payload || [],
				loading: false,
			};

		case CREATE_THC:
			return {
				...state,
				thc: [action.payload, ...state.thc],
			};

		case UPDATE_THC:
			const patientTHCIdx = _.findIndex(state.thc, (p) => p.id === action.payload.id);
			return {
				...state,
				thc: [
					...state.thc.slice(0, patientTHCIdx),
					action.payload,
					...state.thc.slice(patientTHCIdx + 1),
				],
			};

		case DELETE_THC:
			return {
				...state,
				thc: _.reject(state.thc, (p) => p.id === action.payload),
			};

		case SET_GROWTH:
			return {
				...state,
				growth: action.payload || [],
				loading: false,
			};

		case CREATE_GROWTH:
			return {
				...state,
				growth: [action.payload, ...state.growth],
			};

		case UPDATE_GROWTH:
			const patientGrowthIdx = _.findIndex(state.growth, (p) => p.id === action.payload.id);
			return {
				...state,
				growth: [
					...state.growth.slice(0, patientGrowthIdx),
					action.payload,
					...state.growth.slice(patientGrowthIdx + 1),
				],
			};

		case DELETE_GROWTH:
			return {
				...state,
				growth: _.reject(state.growth, (p) => p.id === action.payload),
			};

		case SET_PATIENT_CATEGORY:
			return {
				...state,
				category: action.payload || [],
				loading: false,
			};

		case SET_PATIENT_OPD_DATES:
			return {
				...state,
				opdDates: action.payload || [],
				loading: false,
			};

		case SET_EXAMINATION_PARAMETERS:
			return {
				...state,
				examinations: action.payload || [],
				loading: false,
			};

		case CREATE_EXAMINATION_PARAMETER:
			return {
				...state,
				examinations: [action.payload, ...state.examinations],
			};

		case DELETE_EXAMINATION_PARAMETER:
			return {
				...state,
				examinations: _.reject(state.examinations, (p) => p.id === action.payload),
			};

		case SET_EXAMINATION_TEMPLATES:
			return {
				...state,
				examinationTemplates: action.payload || [],
			};
		case SET_CURRENT_PATIENT:
			return {
				...state,
				currentPatient: action.payload || {},
			};
		case SET_WHITEBOARD:
			return {
				...state,
				whiteBoard: action.payload || [],
			};
		case SET_CLINICAL_NOTES:
			return {
				...state,
				clinicalNotes: action.payload || [],
			};
		case SET_GYNAE_DATA:
			return {
				...state,
				gynaeData: action.payload || [],
			};
		case SET_PATIENT_SHARE_REPORTS:
			return {
				...state,
				shareReports: action.payload.shareReports || [],
				shareURL: action.payload.url || '',
			};
		case SET_PATIENT_SHARE_REPORTS_NON_EXPIRED:
			return {
				...state,
				shareReportsNonExpired: action.payload.shareReports || [],
				shareReportURLNonExpired: action.payload.reportUrl || '',
				shareFileURLNonExpired: action.payload.fileUrl || '',
			};
		case SET_PATIENT_VISITS:
			return {
				...state,
				opds: action.payload || [],
				loading: false,
			};
		case SET_PATIENT_REFUND_PAYMENTS:
			return {
				...state,
				refundPayments: action.payload || [],
			};
		default:
			return state;
	}
};

export default patientReducer;
