import {
	SET_INCLINIC_SLOTS,
	SET_PRESCRIPTION_PAD,
	SET_BILLING_PAD,
	SET_TELECON_SLOTS,
	SET_QUALIFICATIONS,
	LOADING_USER,
	SET_USER,
	DELETE_QUALIFICATION,
	SET_PERMISSIONS,
	SET_SERIES_ID_SETTINGS,
	SET_LABREPORT_PAD,
	SET_SMS_CREDITS,
	SET_WHATSAPP_CREDITS,
} from '../reducers/types';
import axios from '../helpers/axios';
import showNotification from '../components/extras/showNotification';
import { logout } from './authActions';

export const getVendorMessageCredits = () => async (dispatch) => {
	try {
		console.log('running getVendorMessageCredits');
		const res = await axios.get('/doctor/message-credits');
		if (res.data.success) {
			dispatch({
				type: SET_SMS_CREDITS,
				payload: res.data.smsCredits || 0,
			});
			dispatch({
				type: SET_WHATSAPP_CREDITS,
				payload: res.data.whatsappCredits || 0,
			});
		}
	} catch (err) {
		console.log(err);
		return 0;
	}
};

export const getUserData = (vendorId) => async (dispatch) => {
	dispatch({ type: LOADING_USER });
	try {
		console.log('running getUserData');
		const res = await axios.get('/doctor/profile', { params: { vendorId } });
		if (res.data.success) {
			// dispatch(setInitialOrganisation(res.data.detail?.Vendor_organisations[0]));
			if (res.data.detail?.userType === 'staff') {
				getUserPermissions(
					res.data.detail?.Vendor_detail?.vendorId,
					res.data.detail?.Vendor_organisations[0]?.organisationId,
				);
			}
			dispatch({
				type: SET_USER,
				payload: res.data.detail,
			});
		}
	} catch (err) {
		console.log(err);
		dispatch(logout());
	}
};

export const updateUserData = (updateBody, vendorId) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/updateProfile', updateBody);
		if (res.data.success) {
			dispatch(getUserData(vendorId));
			showNotification('Success', 'The profile details are updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateProfileImg = (formdata) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/image', formdata);
		if (res.data.success) {
			showNotification(
				'Success',
				'The profile image is updated successfully. Please refresh.',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateRegistrationCertificate = (formdata, vendorId) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/certificate', formdata);
		if (res.data.success) {
			dispatch(getUserData(vendorId));
			showNotification(
				'Success',
				'The registration certificate is updated successfully.',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const getUserPermissions = (orgId, vendorId) => async (dispatch) => {
	try {
		console.log('running getPermissions');
		const res = await axios.get(
			`/staff-permission?organisationId=${orgId}&vendorId=${vendorId}`,
		);
		if (res.data.success) {
			dispatch({
				type: SET_PERMISSIONS,
				payload: res.data.actions,
			});
		}
	} catch (err) {
		console.log(err);
		return [];
	}
};

export const updateUserPermissions = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.post(`/staff-permission`, updateBody);
		if (res.data.success) {
			dispatch(getUserPermissions(updateBody.organisationId, updateBody.vendorId));
			showNotification('Success', 'Permission updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const getUserQualifications = () => async (dispatch) => {
	try {
		const res = await axios.get('/qualification');
		if (res.data.success) {
			dispatch({
				type: SET_QUALIFICATIONS,
				payload: res.data.qualifications,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createUserQualification = (newQualification) => async (dispatch) => {
	try {
		const res = await axios.post('/qualification', newQualification);
		if (res.data.success) {
			dispatch(getUserQualifications());
			showNotification('Success', 'The degree has been added successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateUserQualification = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/qualification', updateBody);
		if (res.data.success) {
			dispatch(getUserQualifications());
			showNotification('Success', 'The qualification is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteUserQualification = (qualificationId) => async (dispatch) => {
	console.log(qualificationId);
	try {
		const res = await axios.delete('/qualification', { data: { qualificationId } });
		if (res.data.success) {
			dispatch({
				type: DELETE_QUALIFICATION,
				payload: qualificationId,
			});
			showNotification(
				'Success',
				'The qualification has been deleted successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const createUserAppointmentSystem =
	(newApSys, vendorId, setUser = null) =>
	async (dispatch) => {
		try {
			const res = await axios.post('/doctor/profile/inClinicType', newApSys);
			if (res.data.success) {
				if (setUser) setUser(await getVendorDetailById(vendorId));
				else dispatch(getUserData(vendorId));
				showNotification(
					'Success',
					'The appointment system has been altered successfully',
					'success',
				);
			}
		} catch (err) {
			console.log(err);
		}
	};

export const getInclinicSlots = (vendorId) => async (dispatch) => {
	console.log('running getInclinicSlots');
	try {
		const res = await axios.get('/slots/inclinic', { params: vendorId ? { vendorId } : {} });
		if (res.data.success) {
			dispatch({
				type: SET_INCLINIC_SLOTS,
				payload: res.data.slots,
			});
		}
	} catch (err) {
		console.log(err);
	}
};
export const getInclinicSlotsDoctorVise = async (vendorId) => {
	console.log('running getInclinicSlots');
	try {
		const res = await axios.get('/slots/inclinic', { params: vendorId ? { vendorId } : {} });
		if (res.data.success) {
			return res.data.slots;
		}
	} catch (err) {
		console.log(err);
	}
};

export const getUserDataDoctorVise = async (vendorId) => {
	try {
		console.log('running getUserData');
		const res = await axios.get('/doctor/profile', { params: { vendorId } });
		if (res.data.success) {
			// dispatch(setInitialOrganisation(res.data.detail?.Vendor_organisations[0]));
			if (res.data.detail?.userType === 'staff') {
				getUserPermissions(
					res.data.detail?.Vendor_detail?.vendorId,
					res.data.detail?.Vendor_organisations[0]?.organisationId,
				);
			}
			return res.data.detail;
		}
	} catch (err) {
		console.log(err);
	}
};

export const createInclinicSlot = async (newSlot, vendorId) => {
	try {
		const res = await axios.post('/slots/inclinic', newSlot);
		if (res.data.success) {
			const slots = await getInclinicSlotsDoctorVise(vendorId);
			showNotification('Success', 'The inclinicSlot is created successfully', 'success');
			return slots;
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateInclinicSlot = async (updateBody, vendorId) => {
	try {
		const res = await axios.patch('/slots/inclinic', updateBody);
		if (res.data.success) {
			const slots = await getInclinicSlotsDoctorVise(vendorId);
			showNotification('Success', 'The inclinicSlot is updated successfully', 'success');
			return slots;
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteInclinicSlot = async (slotTimeId, vendorId) => {
	try {
		const res = await axios.delete('/slots/inclinic', { data: { slotTimeId, vendorId } });
		if (res.data.success) {
			const slots = await getInclinicSlotsDoctorVise(vendorId);
			showNotification('Success', 'The slot has been deleted successfully', 'success');
			return slots;
		}
	} catch (err) {
		console.log(err);
	}
};

export const getPrescriptionPad = (vendorId) => async (dispatch) => {
	console.log('running getPrescriptionPad');
	try {
		const res = await axios.get('/doctor/profile/prescriptionPad', {
			params: vendorId ? { vendorId } : {},
		});
		if (res.data.success) {
			dispatch({
				type: SET_PRESCRIPTION_PAD,
				payload: res.data.prescriptionPad,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const updatePrescriptionPad = (prescPad) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/profile/new-prescriptionPad', prescPad);
		if (res.data.success) {
			// dispatch({
			// 	type: SET_PRESCRIPTION_PAD,
			// 	payload: prescPad,
			// });
			dispatch(getPrescriptionPad(prescPad.get('vendorId')));
			showNotification('Success', 'The prescription pad is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const uploadBillingImage = async (data) => {
	try {
		const res = await axios.post(`/billing-pad/image`, data);
		if (res.data.success) {
			// dispatch(getBillingPad());
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteBillingImage = () => async (dispatch) => {
	try {
		const res = await axios.delete(`/billing-pad`);
		if (res.data.success) {
			dispatch(getBillingPad());
		}
	} catch (error) {
		console.error(error);
	}
};

export const getBillingPad = (vendorId) => async (dispatch) => {
	console.log('running getBillingPad');
	try {
		const res = await axios.get('/billing-pad', { params: vendorId ? { vendorId } : {} });
		if (res.data.success) {
			dispatch({
				type: SET_BILLING_PAD,
				payload: res.data.billingPad,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateBillingPad = (newPad) => async (dispatch) => {
	try {
		const res = await axios.post('/billing-pad', newPad);
		if (res.data.success) {
			dispatch(getBillingPad());
			// dispatch({
			// 	type: SET_BILLING_PAD,
			// 	payload: newPad,
			// });
			showNotification('Success', 'The billing pad is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateSeriesIdSettings = (status, organisationId, vendorId) => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/profile/updateSeriesStatus`, {
			status,
			organisationId,
		});
		if (res.data.success) {
			showNotification('Success', 'Setting updated successfully', 'success');
			dispatch({
				type: SET_SERIES_ID_SETTINGS,
				payload: status,
			});
			dispatch(getUserData(vendorId));
		}
	} catch (error) {
		console.error(error);
	}
};

export const updatePatientPhoneReq = (vendorId) => async (dispatch) => {
	try {
		const res = await axios.get('/doctor/profile/patient');
		if (res.data.success) {
			dispatch(getUserData(vendorId));
			showNotification('Success', 'Setting updated successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const updatePatientAgeReq = (vendorId) => async (dispatch) => {
	try {
		const res = await axios.get('/doctor/profile/age-required');
		if (res.data.success) {
			dispatch(getUserData(vendorId));
			showNotification('Success', 'Setting updated successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateOrgPanReq = (vendorId) => async (dispatch) => {
	try {
		const res = await axios.get('/doctor/profile/pan');
		if (res.data.success) {
			dispatch(getUserData(vendorId));
			showNotification('Success', 'Setting updated successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateOrgRegNoReq = (vendorId) => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/profile/registration`);
		if (res.data.success) {
			dispatch(getUserData(vendorId));
			showNotification('Success', 'Setting updated successfully', 'success');
		}
	} catch (error) {
		console.error(error);
	}
};

export const getTeleConSlots = () => async (dispatch) => {
	console.log('running getTeleConSlots');
	try {
		const res = await axios.get('/slots');
		if (res.data.success) {
			dispatch({
				type: SET_TELECON_SLOTS,
				payload: res.data.slots,
			});
		}
	} catch (err) {
		console.log(err);
	}
};

export const createTeleConSlot = (newSlot) => async (dispatch) => {
	try {
		const res = await axios.post('/slots', newSlot);
		if (res.data.success) {
			dispatch(getTeleConSlots());
			showNotification(
				'Success',
				'The Teleconsultation slot is created successfully',
				'success',
			);
		}
	} catch (err) {
		console.log(err);
	}
};

export const updateTeleConSlot = (updateBody) => async (dispatch) => {
	try {
		const res = await axios.patch('/slots', updateBody);
		if (res.data.success) {
			dispatch(getTeleConSlots());
			// dispatch({
			// 	type: UPDATE_TELECON_SLOT,
			// 	payload: updateBody,
			// });
			showNotification('Success', 'The Teleconsultation is updated successfully', 'success');
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteTeleConSlot = (slotTimeId) => async (dispatch) => {
	try {
		const res = await axios.delete('/slots', { data: { slotTimeId } });
		if (res.data.success) {
			showNotification(
				'Success',
				'The Teleconsultation Slot has been deleted successfully',
				'success',
			);
			dispatch(getTeleConSlots());
			// dispatch({
			// 	type: DELETE_TELECON_SLOT,
			// 	payload: inclinicSlotId,
			// });
		}
	} catch (err) {
		console.log(err);
	}
};

export const deleteTeleConSlotTotal = (slotId) => async (dispatch) => {
	try {
		const res = await axios.delete('/slots/total', { data: { slotId } });
		if (res.data.success) {
			showNotification(
				'Success',
				'The Teleconsultation Slot has been deleted successfully',
				'success',
			);
			dispatch(getTeleConSlots());
			// dispatch({
			// 	type: DELETE_TELECON_SLOT,
			// 	payload: inclinicSlotId,
			// });
		}
	} catch (err) {
		console.log(err);
	}
};

export const deletePrescPadImage = () => async (dispatch) => {
	try {
		const res = await axios.patch(`/doctor/profile/prescriptionPad`);
		if (res.data.success) {
			dispatch(getPrescriptionPad());
		}
	} catch (error) {
		console.error(error);
	}
};

export const getVendorDetailById = async (vendorId) => {
	try {
		const res = await axios.get('/doctor/profile', { params: { vendorId } });
		if (res.data.success) {
			return res.data.detail;
		}
	} catch (error) {
		console.error(error);
	}
};

export const getLabReportPad = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/labtests/lab-test-pad`);
		if (res.data.success) {
			dispatch({
				type: SET_LABREPORT_PAD,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateLabReportPad = (data) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/labtests/lab-test-pad`, data);
		if (res.data.success) {
			showNotification('Success', 'updated successfully', 'success');
			dispatch(getLabReportPad());
		}
	} catch (error) {
		console.error(error);
	}
};

export const uploadLabReportImage = async (data) => {
	try {
		const res = await axios.post(`/doctor/labtests/upload-pad-image`, data);
		if (res.data.success) {
			return res.data.data;
		}
		return null;
	} catch (error) {
		console.error(error);
		return null;
	}
};

export const removeLabReportImage = () => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/labtests/remove-pad-image`);
		if (res.data.success) {
			dispatch(getLabReportPad());
		}
	} catch (error) {
		console.error(error);
	}
};
