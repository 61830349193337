import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import JoditEditor from 'jodit-react';
import { useFormik } from 'formik';

import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import {
	deleteWhiteBoard,
	getWhiteboard,
	saveWhiteBoard,
	updateWhiteBoard,
} from '../../../actions/patientActions';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import WhiteboardImg from '../../../assets/img/menu/patient/whiteboard.png';
import { Delete, Edit } from '../../../components/icon/material-icons';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import { Eye } from '../../../components/icon/bootstrap';
import { truncate } from '../../../helpers/helpers';
import { isPermitted } from '../../../actions/helperActions';
import { SET_WHITEBOARD } from '../../../reducers/types';
import useAsr from '../../../hooks/useAsr';

const WhiteBoard = forwardRef(({ userId, vendorId, voiceData }, ref) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const whiteBoard = useSelector((state) => state.patient.whiteBoard);
	const editor = useRef(null);
	const [text, setText] = useState('');
	const [editItem, setEditItem] = useState(null);
	const [deleteWarn, setDeleteWarn] = useState(false);

	const handleUpcomingEdit = (item = null) => {
		if (item) setEditItem(item);
		setText(item?.whiteboard || '');
		setDeleteWarn(false);
		formik.setValues((prev) => ({ ...prev, date: moment(item?.date).format('YYYY-MM-DD') }));
	};

	const handleDelete = (item) => {
		dispatch(deleteWhiteBoard(item?.id, userId));
	};

	const formik = useFormik({
		initialValues: {
			date: moment().format('YYYY-MM-DD'),
		},
		onSubmit: (values) => {
			const data = {};
			data['userId'] = userId;
			data['date'] = values.date;
			data['whiteboard'] = text;
			if (!editItem) {
				dispatch(saveWhiteBoard(userId, data));
			} else {
				dispatch(updateWhiteBoard(editItem?.id, data, userId));
			}
			setText('');
			setEditItem(null);
			formik.resetForm();
		},
	});

	useImperativeHandle(ref, () => ({
		save: () => formik.handleSubmit(),
	}));

	/* ----------- NEW ASR START ----------- */
	const { toggleAsr, listening, speech, speechTemp, setSpeech } = useAsr();
	useEffect(() => {
		if (speech) setText(speech?.replace('\n', '</p><p>'));
	}, [speech]);
	useEffect(() => {
		setSpeech(text);
	}, [text]);
	/* ----------- NEW ASR END ----------- */

	// On Mount
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			isPermitted(
				'medical profile',
				'read',
				() => dispatch(getWhiteboard(userId)),
				null,
				false,
			);
		}
		return () => {
			mounted = false;
			dispatch({
				type: SET_WHITEBOARD,
				payload: [],
			});
		};
	}, []);

	useEffect(() => {
		if (voiceData) {
			setText(voiceData);
		}
	}, [voiceData]);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardBody isScrollable className='d-flex flex-column h-100'>
					<div className='row'>
						<div className='col-2 pt-3' style={{ textAlign: 'right' }}>
							<p style={{ fontSize: '1.1rem' }}>
								<strong>Date:</strong>
							</p>
						</div>
						<div className='col-4'>
							<FormGroup id='date'>
								<Input
									className='form-control'
									autoComplete='off'
									type='date'
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									placeholder='Date'
									name='date'
									onChange={formik.handleChange}
									value={formik.values.date}
								/>
							</FormGroup>
						</div>
					</div>
					<div className='row mt-3'>
						<div className='col-2 pt-3 ps-0' style={{ textAlign: 'right' }}>
							<p style={{ fontSize: '1.1rem' }}>
								<strong>WhiteBoard:</strong>
							</p>
							{/* <Button
								isOutline={!listening}
								color={listening ? 'danger' : 'primary'}
								icon='Mic'
								onClick={toggleAsr}>
								{listening ? 'Stop' : 'Speak'}
							</Button> */}
						</div>
						<div className='col-10'>
							<JoditEditor
								config={{
									defaultActionOnPaste: 'insert_as_html',
									height: '60vh',
								}}
								ref={editor}
								value={speechTemp ? text + speechTemp : text}
								tabIndex={1}
								onBlur={(newContent) => setText(newContent)}
								onChange={(newContent) => {}}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-2'></div>
						<div className='col-3'>
							<Button
								isOutline={darkModeStatus}
								color='primary'
								className='border-0'
								onClick={() =>
									isPermitted('Medical Profile', 'write', formik.handleSubmit)
								}>
								Save
							</Button>
						</div>
					</div>
					{!voiceData && (
						<div
							className='row mt-4'
							style={{
								width: '100%',
								flexWrap: 'wrap',
							}}>
							<h5>Saved Boards</h5>
							{whiteBoard?.map((board, index) => (
								<Card
									key={index}
									className='mx-2 shadow-none border-2'
									style={{ maxWidth: '400px', height: '30vh' }}>
									<CardHeader className='py-2'>
										<CardLabel img={WhiteboardImg}>
											<CardTitle>
												<div className='row'>
													<div className='col-12'>Whiteboard</div>
													<div
														className='col-12'
														style={{
															fontWeight: 'normal',
															fontSize: '0.9rem',
														}}>
														{moment(board?.date).format('DD-MM-YYYY')}
													</div>
												</div>
											</CardTitle>
										</CardLabel>
									</CardHeader>
									<CardBody className='py-2'>
										<div
											dangerouslySetInnerHTML={{
												__html: truncate(
													board?.whiteboard?.replace(/(<([^>]+)>)/gi, ''),
													200,
												),
											}}></div>
									</CardBody>
									<CardFooter className='py-2'>
										<CardFooterRight>
											<Eye
												onClick={() => {
													setEditItem(board);
													setDeleteWarn(true);
												}}
												style={{ cursor: 'pointer' }}
											/>
											<Edit
												onClick={() =>
													isPermitted('Medical Profile', 'write', () =>
														handleUpcomingEdit(board),
													)
												}
												style={{ cursor: 'pointer' }}
											/>
											<Delete
												onClick={() =>
													isPermitted('Medical Profile', 'delete', () =>
														handleDelete(board),
													)
												}
												style={{
													fill: 'var(--bs-danger)',
													cursor: 'pointer',
												}}
											/>
										</CardFooterRight>
									</CardFooter>
								</Card>
							))}
						</div>
					)}
				</CardBody>
			</Card>
			<Modal
				isCentered
				isScrollable
				size={'xl'}
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deleteRec'}
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteRecLabel'>
						{moment(editItem?.date).format('DD-MM-YYYY')}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div dangerouslySetInnerHTML={{ __html: editItem?.whiteboard }} />
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setDeleteWarn(false);
							setEditItem(null);
						}}>
						Close
					</Button>
					<Button
						color='primary'
						icon='edit'
						onClick={() => handleUpcomingEdit(editItem)}>
						Edit
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
});

export default WhiteBoard;
