import React from 'react';
import Input from '../../../../components/bootstrap/forms/Input';

function EDC({ formik }) {
	return (
		<div className='row mb-3'>
			<div className='col-12 mb-2 text-center'>
				<strong>EDC Determination</strong>
			</div>
			<table className='table table-bordered'>
				<tbody>
					<tr>
						<th></th>
						<th>Date</th>
						<th>Gest. Age</th>
						<th>EDC</th>
					</tr>
					{formik.values.edcDetermination.map((item, index) => (
						<tr key={index}>
							<th>{item?.type}</th>
							<td>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='date'
									className='form-control p-3 shadow-none'
									value={item.date}
									name={`edcDetermination[${index}].date`}
									onChange={formik.handleChange}
								/>
							</td>
							<td>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='number'
									className='form-control p-3 shadow-none'
									value={item.gestationalAge}
									name={`edcDetermination[${index}].gestationalAge`}
									onChange={formik.handleChange}
								/>
							</td>
							<td>
								<Input
									style={{
										background: 'transparent',
										height: '2.5rem',
										borderRadius: 0,
									}}
									type='text'
									className='form-control p-3 shadow-none'
									value={item.EDC}
									name={`edcDetermination[${index}].EDC`}
									onChange={formik.handleChange}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
}

export default EDC;
