import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import JoditEditor from 'jodit-react';
import { useFormik } from 'formik';

import Card, {
	CardBody,
	CardFooter,
	CardFooterRight,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import {
	deleteWhiteBoard,
	getWhiteboard,
	saveWhiteBoard,
	updateWhiteBoard,
} from '../../../actions/patientActions';
import Input from '../../../components/bootstrap/forms/Input';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import WhiteboardImg from '../../../assets/img/menu/patient/whiteboard.png';
import { Delete, Edit } from '../../../components/icon/material-icons';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import { Eye } from '../../../components/icon/bootstrap';
import { truncate } from '../../../helpers/helpers';
import {
	createIpdWhiteBoard,
	deleteIpdWhiteBoard,
	getIpdWhiteboard,
	updateIpdWhiteBoard,
} from '../../../actions/ipdPatientActions';
import { isPermitted } from '../../../actions/helperActions';

const IpdWhiteBoard = ({ userId, vendorId }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const whiteBoard = useSelector((state) => state.ipdPatient.whiteboard);
	const editor = useRef(null);
	const [text, setText] = useState('<p>');
	const [editItem, setEditItem] = useState(null);
	const [deleteWarn, setDeleteWarn] = useState(false);

	const handleUpcomingEdit = (item = null) => {
		console.log(item);
		if (item) setEditItem(item);
		setText(item?.message || '');
		setDeleteWarn(false);
		formik.setValues((prev) => ({ ...prev, date: moment(item?.date).format('YYYY-MM-DD') }));
	};

	const handleDelete = (item) => {
		dispatch(deleteIpdWhiteBoard(item?.id));
	};

	const formik = useFormik({
		initialValues: {
			date: moment().format('YYYY-MM-DD'),
		},
		onSubmit: (values) => {
			const data = {};
			data['ipdId'] = ipdId;
			data['date'] = values.date;
			data['message'] = text;

			if (!editItem) {
				dispatch(createIpdWhiteBoard(data));
			} else {
				data['whiteboardId'] = editItem?.id;
				dispatch(updateIpdWhiteBoard(data));
			}
			setText('');
			setEditItem(null);
			formik.resetForm();
		},
	});

	// On Mount
	useEffect(() => {
		let mounted = true;
		if (mounted) {
			dispatch(getIpdWhiteboard(ipdId));
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>White Board</CardTitle>
					</CardLabel>
				</CardHeader>
				<CardBody isScrollable className='d-flex flex-column'>
					<div className='row'>
						<div className='col-1 pt-3' style={{ textAlign: 'right' }}>
							<p style={{ fontSize: '1.1rem' }}>
								<strong>Date:</strong>
							</p>
						</div>
						<div className='col-4'>
							<FormGroup id='date'>
								<Input
									className='form-control'
									autoComplete='off'
									type='date'
									style={{
										height: 'calc(3.5rem + 2px)',
									}}
									placeholder='Date'
									name='date'
									onChange={formik.handleChange}
									value={formik.values.date}
								/>
							</FormGroup>
						</div>
					</div>
					<div className='row mt-3'>
						<div className='col-1 pt-3 ps-0' style={{ textAlign: 'right' }}>
							<p style={{ fontSize: '1.1rem' }}>
								<strong>Ipd:</strong>
							</p>
						</div>
						<div className='col-10'>
							<JoditEditor
								config={{
									defaultActionOnPaste: 'insert_as_html',
									height: '60vh',
								}}
								ref={editor}
								value={text}
								tabIndex={1}
								onBlur={(newContent) => setText(newContent)}
								onChange={(newContent) => {}}
							/>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-1'></div>
						<div className='col-3'>
							<Button
								isOutline={darkModeStatus}
								color='primary'
								className='border-0'
								onClick={() =>
									isPermitted('Ipd', 'write', formik.handleSubmit, 'Whiteboard')
								}>
								Save
							</Button>
						</div>
					</div>
					<div
						className='row mt-4'
						style={{
							width: '100%',
							flexWrap: 'wrap',
						}}>
						<h5>Saved Boards</h5>
						{whiteBoard?.map((board, index) => (
							<Card
								key={index}
								className='mx-2 shadow-none border-2'
								style={{ maxWidth: '400px', height: '30vh' }}>
								<CardHeader className='py-2'>
									<CardLabel img={WhiteboardImg}>
										<CardTitle>
											<div className='row'>
												<div className='col-12'>Whiteboard</div>
												<div
													className='col-12'
													style={{
														fontWeight: 'normal',
														fontSize: '0.9rem',
													}}>
													{moment(board?.date).format('DD-MM-YYYY')}
												</div>
											</div>
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody className='py-2'>
									<div
										dangerouslySetInnerHTML={{
											__html: truncate(
												board?.message?.replace(/(<([^>]+)>)/gi, ''),
												200,
											),
										}}></div>
								</CardBody>
								<CardFooter className='py-2'>
									<CardFooterRight>
										<Eye
											onClick={() => {
												setEditItem(board);
												setDeleteWarn(true);
											}}
											style={{ cursor: 'pointer' }}
										/>
										<Edit
											onClick={() =>
												isPermitted(
													'Ipd',
													'write',
													() => handleUpcomingEdit(board),
													'Whiteboard',
												)
											}
											style={{ cursor: 'pointer' }}
										/>
										<Delete
											onClick={() =>
												isPermitted(
													'Ipd',
													'delete',
													() => handleDelete(board),
													'Whiteboard',
												)
											}
											style={{ fill: 'var(--bs-danger)', cursor: 'pointer' }}
										/>
									</CardFooterRight>
								</CardFooter>
							</Card>
						))}
					</div>
				</CardBody>
			</Card>
			<Modal
				isCentered
				isScrollable
				size={'xl'}
				isOpen={deleteWarn}
				setIsOpen={setDeleteWarn}
				titleId={'deleteRec'}
				isAnimation={false}>
				<ModalHeader setIsOpen={setDeleteWarn}>
					<ModalTitle id='deleteRecLabel'>
						{moment(editItem?.date).format('DD-MM-YYYY')}
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div dangerouslySetInnerHTML={{ __html: editItem?.message }} />
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setDeleteWarn(false);
							setEditItem(null);
						}}>
						Close
					</Button>
					<Button
						color='primary'
						icon='edit'
						onClick={() => handleUpcomingEdit(editItem)}>
						Edit
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default IpdWhiteBoard;
