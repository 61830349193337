import showNotification from '../components/extras/showNotification';
import axios from '../helpers/axios';
import axiosz from 'axios';
import { DefaultPagination } from '../helpers/config';
import {
	SET_PATIENT_STORE_ASSIGN,
	SET_STORE_INDENTATION,
	SET_STORE_INVENTORY,
	SET_STORE_ITEMS,
	SET_STORE_PURCHASE,
	SET_STORE_SALES,
} from '../reducers/types';
import { toggleTableLoading } from './componentActions';
import { dashboardMenu } from '../menu';

var cancelToken;
export const getStorePurchases =
	(params = DefaultPagination) =>
	async (dispatch) => {
		var url = `/doctor/purchase/store`;
		try {
			if (typeof cancelToken != typeof undefined) {
				cancelToken?.cancel('Cancelling prev req');
			}
			cancelToken = axiosz.CancelToken?.source();
			axiosz.defaults.headers.common.Authorization = localStorage.getItem(`ehrToken`);
			const res = await axiosz.get(url, {
				cancelToken: cancelToken?.token,
				params,
				baseURL: process.env.REACT_APP_SERVER_URL,
			});
			if (res.data.success) {
				dispatch({
					type: SET_STORE_PURCHASE,
					payload: {
						data: res.data.data,
						storePurchaseUrl: res.data.url,
						page: res.data.totalPage,
						total: res.data.totalRecord,
					},
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const getStoreAssignList =
	(params = DefaultPagination, isInd = false) =>
	async (dispatch) => {
		const url = '/doctor/assign/store/item';
		try {
			const res = await axios.get(url, {
				params: { isIndentation: isInd, ...params },
			});
			if (res.data.success) {
				dispatch({
					type: isInd ? SET_STORE_INDENTATION : SET_STORE_SALES,
					payload: {
						data: res.data.data,
						page: res.data.totalPage,
						total: res.data?.totalRecord,
					},
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(toggleTableLoading(false));
		}
	};

export const assignStorePurchase = (data, isInd, naviagte, setLoader) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/assign/store/item', data);
		if (res.data.success) {
			showNotification('Success', 'The Store Purchase assigned successfully', 'success');
			dispatch(getStoreAssignList(DefaultPagination, isInd));
			naviagte(`/${dashboardMenu.ipd.subMenu.salesIndentation.path}`);
			setLoader(false);
		}
	} catch (error) {
		console.log(error);
	}
};

export const deleteStoreAssign = (id, isInd, setModal) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/assign/store/item/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The Store Purchase deleted successfully', 'success');
			dispatch(getStoreAssignList(DefaultPagination, isInd));
			setModal(false);
		}
	} catch (error) {
		console.log(error);
	}
};

export const returnStoreAssignment = (data, setModal) => async (dispatch) => {
	try {
		const res = await axios.put('/doctor/assign/store/item/return', data);
		if (res.data.success) {
			showNotification('Success', 'The Store Purchase returned successfully', 'success');
			dispatch(getStoreAssignList(true));
			setModal(false);
		}
	} catch (error) {
		console.log(error);
	}
};

export const getStoreInventory = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/purchase/store/item-list?limit=1000`);
		if (res.data.success) {
			dispatch({
				type: SET_STORE_INVENTORY,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const getPatientStoreAssignList = (id) => async (dispatch) => {
	try {
		const res = await axios.get(`payments/assign-store-pay-list/${id}`);
		if (res.data.success) {
			dispatch({
				type: SET_PATIENT_STORE_ASSIGN,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const getStoreItems = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/store?limit=1000`);
		if (res.data.success) {
			dispatch({
				type: SET_STORE_ITEMS,
				payload: { data: res.data.data, storeItemsUrl: res.data.url },
			});
		}
	} catch (error) {
		console.error(error);
	}
};

export const createStoreItems = (data, setModal) => async (dispatch) => {
	try {
		const res = await axios.post(`/doctor/store`, data);
		if (res.data.success) {
			showNotification('Success', 'The Store Item created successfully', 'success');
			dispatch(getStoreItems());
			setModal(false);
		}
	} catch (error) {
		console.error(error);
	}
};

export const updateStoreItems = (data, setModal) => async (dispatch) => {
	try {
		const res = await axios.put(`/doctor/store`, data);
		if (res.data.success) {
			showNotification('Success', 'The Store Item updated successfully', 'success');
			dispatch(getStoreItems());
			setModal(false);
		}
	} catch (error) {
		console.error(error);
	}
};

export const deleteStoreItems = (id, setModal) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/store/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The Store Item deleted successfully', 'success');
			dispatch(getStoreItems());
			setModal(false);
		}
	} catch (error) {
		console.error(error);
	}
};

export const createStorePurchase = (data, navigate, setAddLoader) => async (dispatch) => {
	try {
		const res = await axios.post('/doctor/purchase/store', data);
		if (res.data.success) {
			showNotification('Success', 'The Store Purchase created successfully', 'success');
			dispatch(getStorePurchases());
			navigate(`/${dashboardMenu.ipd.subMenu.storePurchases.path}`);
		}
	} catch (error) {
		console.log(error);
	} finally {
		setAddLoader(false);
	}
};

export const updateStorePurchase = (data, setModal) => async (dispatch) => {
	try {
		const res = await axios.put('/doctor/purchase/store', data);
		if (res.data.success) {
			showNotification('Success', 'The Store Purchase updated successfully', 'success');
			dispatch(getStorePurchases());
			setModal(false);
		}
	} catch (error) {
		console.log(error);
	}
};

export const deleteStorePurchase = (id, setModal) => async (dispatch) => {
	try {
		const res = await axios.delete(`/doctor/purchase/store/${id}`);
		if (res.data.success) {
			showNotification('Success', 'The Store Purchase deleted successfully', 'success');
			dispatch(getStorePurchases());
			setModal(false);
		}
	} catch (error) {
		console.log(error);
	}
};

export const getStorePurchaseList = () => async (dispatch) => {
	try {
		const res = await axios.get(`/doctor/purchase/store/list`);
		console.log(res);
		if (res.data.success) {
			dispatch({
				type: SET_STORE_PURCHASE_LIST,
				payload: res.data.data,
			});
		}
	} catch (error) {
		console.error(error);
	}
};
