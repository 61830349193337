import {
	SET_PHARMACY_VENDORS,
	LOADING_PHARMACY_VENDORS,
	CREATE_PHARMACY_VENDOR,
	UPDATE_PHARMACY_VENDOR,
	DELETE_PHARMACY_VENDOR,
	SET_PHARMACY_VENDOR_PAYMENTS,
	SET_STOCKIST_PURCHASE_VOUCHER,
	SET_STOCKIST_PAYMENTS,
	SET_PHARMACY_ALL_PAYMENTS,
} from './types';

const initialState = {
	pharmacyVendors: [],
	payments: [],
	page: 1,
	total: 1,
	paymentPage: 1,
	paymentTotal: 1,
	purchaseVoucherPage: 1,
	purchaseVoucherTotal: 1,
	stockistPurchaseVouchers: [],
	stockistPayments: [],
	allPayments: [],
	error: null,
	loading: false,
};

const pharmacyVendorReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOADING_PHARMACY_VENDORS:
			return {
				...state,
				loading: true,
			};

		case SET_PHARMACY_VENDORS:
			return {
				...state,
				pharmacyVendors: action.payload.stockists || [],
				page: action.payload.page || 1,
				total: action.payload.total || 0,
				loading: false,
			};

		case CREATE_PHARMACY_VENDOR:
			return {
				...state,
				pharmacyVendors: [action.payload, ...state.pharmacyVendors],
			};

		case UPDATE_PHARMACY_VENDOR:
			state.pharmacyVendors = state.pharmacyVendors.filter((o) => o.id != action.payload.id);
			return {
				pharmacyVendors: [action.payload, ...state.pharmacyVendors],
				...state,
			};

		case DELETE_PHARMACY_VENDOR:
			state.pharmacyVendors = state.pharmacyVendors.filter((ig) => ig.id != action.payload);
			return {
				...state,
				total: state.total - 1,
			};

		case SET_PHARMACY_VENDOR_PAYMENTS:
			return {
				...state,
				payments: action.payload || [],
			};
		case SET_STOCKIST_PURCHASE_VOUCHER:
			return {
				...state,
				stockistPurchaseVouchers: action.payload.purchaseVoucher || [],
				purchaseVoucherPage: action.payload.page || 1,
				purchaseVoucherTotal: action.payload.total || 0,
			};
		case SET_STOCKIST_PAYMENTS:
			return {
				...state,
				stockistPayments: action.payload.payments || [],
				paymentPage: action.payload.page || 0,
				paymentTotal: action.payload.total || 0,
			};
		case SET_PHARMACY_ALL_PAYMENTS:
			return {
				...state,
				allPayments: action.payload.payments || [],
				paymentPage: action.payload.page || 1,
				paymentTotal: action.payload.total || 0,
			};
		default:
			return state;
	}
};

export default pharmacyVendorReducer;
