// Auth Reducer Types
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_OTP_KEY = 'SET_OTP_KEY';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const LOADING_USER = 'LOADING_USER';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS';

export const SET_SELECTED_ORGANISATION = 'SET_SELECTED_ORGANISATION';

export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const CREATE_PERMISSION = 'CREATE_PERMISSION';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const DELETE_PERMISSION = 'DELETE_PERMISSION';

export const LOADING_QUALIFICATIONS = 'LOADING_QUALIFICATIONS';
export const SET_QUALIFICATIONS = 'SET_QUALIFICATIONS';
export const CREATE_QUALIFICATION = 'CREATE_QUALIFICATION';
export const UPDATE_QUALIFICATION = 'UPDATE_QUALIFICATION';
export const DELETE_QUALIFICATION = 'DELETE_QUALIFICATION';

export const LOADING_INCLINIC_SLOTS = 'LOADING_INCLINIC_SLOTS';
export const SET_INCLINIC_SLOTS = 'SET_INCLINIC_SLOTS';
export const CREATE_INCLINIC_SLOT = 'CREATE_INCLINIC_SLOT';
export const UPDATE_INCLINIC_SLOT = 'UPDATE_INCLINIC_SLOT';
export const DELETE_INCLINIC_SLOT = 'XDELETE_INCLINIC_SLOT';

export const LOADING_PRESCRIPTION_PAD = 'LOADING_PRESCRIPTION_PAD';
export const SET_PRESCRIPTION_PAD = 'SET_PRESCRIPTION_PAD';
export const CREATE_PRESCRIPTION_PAD = 'CREATE_PRESCRIPTION_PAD';
export const UPDATE_PRESCRIPTION_PAD = 'UPDATE_PRESCRIPTION_PAD';
export const DELETE_PRESCRIPTION_PAD = 'DELETE_PRESCRIPTION_PAD';

export const LOADING_BILLING_PAD = 'LOADING_BILLING_PAD';
export const SET_BILLING_PAD = 'SET_BILLING_PAD';
export const CREATE_BILLING_PAD = 'CREATE_BILLING_PAD';
export const UPDATE_BILLING_PAD = 'UPDATE_BILLING_PAD';
export const DELETE_BILLING_PAD = 'DELETE_BILLING_PAD';
export const SET_LABREPORT_PAD = 'SET_LABREPORT_PAD';

export const SET_SERIES_ID_SETTINGS = 'SET_SERIES_ID_SETTINGS';

export const LOADING_TELECON_SLOTS = 'LOADING_TELECON_SLOTS';
export const SET_TELECON_SLOTS = 'SET_TELECON_SLOTS';
export const CREATE_TELECON_SLOT = 'CREATE_TELECON_SLOT';
export const UPDATE_TELECON_SLOT = 'UPDATE_TELECON_SLOT';
export const DELETE_TELECON_SLOT = 'DELETE_TELECON_SLOT';

export const LOADING_APPOINTMENTS = 'LOADING_APPOINTMENTS';
export const SET_APPOINTMENTS = 'SET_APPOINTMENTS';
export const SET_CALENDAR_APPOINTMENTS = 'SET_CALENDAR_APPOINTMENTS';
export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT';
export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const SET_APPOINTMENT_SLOTS = 'SET_APPOINTMENT_SLOTS';

export const LOADING_ORGANISATIONS = 'LOADING_ORGANISATIONS';
export const SET_ORGANISATIONS = 'SET_ORGANISATIONS';
export const CREATE_ORGANISATION = 'CREATE_ORGANISATION';
export const UPDATE_ORGANISATION = 'UPDATE_ORGANISATION';
export const DELETE_ORGANISATION = 'DELETE_ORGANISATION';

export const LOADING_SPECIALITIES = 'LOADING_SPECIALITIES';
export const SET_SPECIALITIES = 'SET_SPECIALITIES';
export const CREATE_SPECIALITY = 'CREATE_SPECIALITY';
export const UPDATE_SPECIALITY = 'UPDATE_SPECIALITY';
export const DELETE_SPECIALITY = 'DELETE_SPECIALITY';

export const LOADING_DEPARTMENTS = 'LOADING_DEPARTMENTS';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const CREATE_DEPARTMENT = 'CREATE_DEPARTMENT';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const DELETE_DEPARTMENT = 'DELETE_DEPARTMENT';

export const LOADING_DOCTORS = 'LOADING_DOCTORS';
export const SET_DOCTORS = 'SET_DOCTORS';
export const CREATE_DOCTOR = 'CREATE_DOCTOR';
export const UPDATE_DOCTOR = 'UPDATE_DOCTOR';
export const DELETE_DOCTOR = 'DELETE_DOCTOR';

export const LOADING_PATIENTS = 'LOADING_PATIENTS';
export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_PATIENT = 'SET_PATIENT';
export const CREATE_PATIENT = 'CREATE_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const DELETE_PATIENT = 'DELETE_PATIENT';

export const SET_PATIENT_DOCUMENTS = 'SET_PATIENT_DOCUMENTS';
export const SET_PATIENT_PICKLIST = 'SET_PATIENT_PICKLIST';

export const SET_PATIENT_EXAMINATIONS = 'SET_PATIENT_EXAMINATIONS';
export const CREATE_PATIENT_EXAMINATION = 'CREATE_PATIENT_EXAMINATION';
export const UPDATE_PATIENT_EXAMINATION = 'UPDATE_PATIENT_EXAMINATION';
export const DELETE_PATIENT_EXAMINATION = 'DELETE_PATIENT_EXAMINATION';

export const SET_PATIENT_TREATMENT_PLANS = 'SET_PATIENT_TREATMENT_PLANS';
export const CREATE_PATIENT_TREATMENT_PLAN = 'CREATE_PATIENT_TREATMENT_PLAN';
export const UPDATE_PATIENT_TREATMENT_PLAN = 'UPDATE_PATIENT_TREATMENT_PLAN';
export const DELETE_PATIENT_TREATMENT_PLAN = 'DELETE_PATIENT_TREATMENT_PLAN';

export const SET_PATIENT_TREATMENTS = 'SET_PATIENT_TREATMENTS';
export const CREATE_PATIENT_TREATMENT = 'CREATE_PATIENT_TREATMENT';
export const UPDATE_PATIENT_TREATMENT = 'UPDATE_PATIENT_TREATMENT';
export const DELETE_PATIENT_TREATMENT = 'DELETE_PATIENT_TREATMENT';

export const SET_PATIENT_PRESCRIPTIONS = 'SET_PATIENT_PRESCRIPTIONS';
export const CREATE_PATIENT_PRESCRIPTION = 'CREATE_PATIENT_PRESCRIPTION';
export const UPDATE_PATIENT_PRESCRIPTION = 'UPDATE_PATIENT_PRESCRIPTION';
export const DELETE_PATIENT_PRESCRIPTION = 'DELETE_PATIENT_PRESCRIPTION';

export const SET_PATIENT_VOICE_PRESCRIPTIONS = 'SET_PATIENT_VOICE_PRESCRIPTIONS';
export const CREATE_PATIENT_VOICE_PRESCRIPTION = 'CREATE_PATIENT_VOICE_PRESCRIPTION';
export const UPDATE_PATIENT_VOICE_PRESCRIPTION = 'UPDATE_PATIENT_VOICE_PRESCRIPTION';
export const DELETE_PATIENT_VOICE_PRESCRIPTION = 'DELETE_PATIENT_VOICE_PRESCRIPTION';

export const SET_PATIENT_INVESTIGATIONS = 'SET_PATIENT_INVESTIGATIONS';
export const CREATE_PATIENT_INVESTIGATION = 'CREATE_PATIENT_INVESTIGATION';
export const UPDATE_PATIENT_INVESTIGATION = 'UPDATE_PATIENT_INVESTIGATION';
export const DELETE_PATIENT_INVESTIGATION = 'DELETE_PATIENT_INVESTIGATION';

export const SET_PATIENT_INVESTIGATION_TEMPLATES = 'SET_PATIENT_INVESTIGATION_TEMPLATES';
export const CREATE_PATIENT_INVESTIGATION_TEMPLATE = 'CREATE_PATIENT_INVESTIGATION_TEMPLATE';
export const UPDATE_PATIENT_INVESTIGATION_TEMPLATE = 'UPDATE_PATIENT_INVESTIGATION_TEMPLATE';
export const DELETE_PATIENT_INVESTIGATION_TEMPLATE = 'DELETE_PATIENT_INVESTIGATION_TEMPLATE';

export const SET_PATIENT_BILLS = 'SET_PATIENT_BILLS';
export const CREATE_PATIENT_BILL = 'CREATE_PATIENT_BILL';
export const UPDATE_PATIENT_BILL = 'UPDATE_PATIENT_BILL';
export const DELETE_PATIENT_BILL = 'DELETE_PATIENT_BILL';

export const SET_PATIENT_PAYMENTS = 'SET_PATIENT_PAYMENTS';
export const CREATE_PATIENT_PAYMENT = 'CREATE_PATIENT_PAYMENT';
export const UPDATE_PATIENT_PAYMENT = 'UPDATE_PATIENT_PAYMENT';
export const DELETE_PATIENT_PAYMENT = 'DELETE_PATIENT_PAYMENT';
export const SET_PATIENT_REFUND_PAYMENTS = 'SET_PATIENT_REFUND_PAYMENTS';

export const LOADING_PATIENT_APPOINTMENTS = 'LOADING_PATIENT_APPOINTMENTS';
export const SET_PATIENT_APPOINTMENTS = 'SET_PATIENT_APPOINTMENTS';
export const CREATE_PATIENT_APPOINTMENT = 'CREATE_PATIENT_APPOINTMENT';
export const UPDATE_PATIENT_APPOINTMENT = 'UPDATE_PATIENT_APPOINTMENT';
export const DELETE_PATIENT_APPOINTMENT = 'DELETE_PATIENT_APPOINTMENT';
export const SET_PATIENT_VISITS = 'SET_PATIENT_VISITS';

export const LOADING_PHARMACIES = 'LOADING_PHARMACIES';
export const SET_PHARMACIES = 'SET_PHARMACIES';
export const CREATE_PHARMACY = 'CREATE_PHARMACY';
export const UPDATE_PHARMACY = 'UPDATE_PHARMACY';
export const DELETE_PHARMACY = 'DELETE_PHARMACY';
export const SET_PHARMACY_TAGS = 'SET_PHARMACY_TAGS';
export const ADD_PHARMACY_TAGS = 'ADD_PHARMACY_TAGS';
export const SET_SALES_SUGGESTIONS = 'SET_SALES_SUGGESTIONS';
export const SET_GST_SALESDATA = 'SET_GST_SALESDATA';
export const SET_GST_PURCHASEDATA = 'SET_GST_PURCHASEDATA';
export const SET_WHOLESALES_LIST = 'SET_WHOLESALES_LIST';
export const DELETE_WHOLESALEVOUCHER = 'DELETE_WHOLESALEVOUCHER';

export const LOADING_PHARMACY_VENDORS = 'LOADING_PHARMACY_VENDORS';
export const SET_STOCKISTS_LIST = 'SET_STOCKISTS_LIST';
export const SET_PHARMACY_VENDORS = 'SET_PHARMACY_VENDORS';
export const CREATE_PHARMACY_VENDOR = 'CREATE_PHARMACY_VENDOR';
export const UPDATE_PHARMACY_VENDOR = 'UPDATE_PHARMACY_VENDOR';
export const DELETE_PHARMACY_VENDOR = 'DELETE_PHARMACY_VENDOR';
export const SET_PHARMACY_VENDOR_PAYMENTS = 'SET_PHARMACY_VENDOR_PAYMENTS';

export const LOADING_PURCHASE_VOUCHERS = 'LOADING_PURCHASE_VOUCHERS';
export const SET_PURCHASE_VOUCHERS = 'SET_PURCHASE_VOUCHERS';
export const SET_CURRENT_PURCHASE_VOUCHER = 'SET_CURRENT_PURCHASE_VOUCHER';
export const CREATE_PURCHASE_VOUCHER = 'CREATE_PURCHASE_VOUCHER';
export const UPDATE_PURCHASE_VOUCHER = 'UPDATE_PURCHASE_VOUCHER';
export const DELETE_PURCHASE_VOUCHER = 'DELETE_PURCHASE_VOUCHER';

export const LOADING_SALE_VOUCHERS = 'LOADING_SALE_VOUCHERS';
export const SET_SALE_VOUCHERS = 'SET_SALE_VOUCHERS';
export const CREATE_SALE_VOUCHER = 'CREATE_SALE_VOUCHER';
export const UPDATE_SALE_VOUCHER = 'UPDATE_SALE_VOUCHER';
export const DELETE_SALE_VOUCHER = 'DELETE_SALE_VOUCHER';

export const LOADING_INVENTORIES = 'LOADING_INVENTORIES';
export const SET_INVENTORIES = 'SET_INVENTORIES';
export const CREATE_INVENTORY = 'CREATE_INVENTORY';
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY';
export const DELETE_INVENTORY = 'DELETE_INVENTORY';

export const LOADING_AMENITIES = 'LOADING_AMENITIES';
export const SET_AMENITIES = 'SET_AMENITIES';
export const CREATE_AMENITY = 'CREATE_AMENITY';
export const UPDATE_AMENITY = 'UPDATE_AMENITY';
export const DELETE_AMENITY = 'DELETE_AMENITY';

export const LOADING_OPD = 'LOADING_OPD';

export const SET_OPD_RECORDS = 'SET_OPD_RECORDS';
export const CREATE_OPD_RECORD = 'CREATE_OPD_RECORD';
export const UPDATE_OPD_RECORD = 'UPDATE_OPD_RECORD';
export const DELETE_OPD_RECORD = 'DELETE_OPD_RECORD';

export const SET_ACCOMMODATIONS = 'SET_ACCOMMODATIONS';
export const CREATE_ACCOMMODATION = 'CREATE_ACCOMMODATION';
export const UPDATE_ACCOMMODATION = 'UPDATE_ACCOMMODATION';
export const DELETE_ACCOMMODATION = 'DELETE_ACCOMMODATION';

export const SET_INVESTIGATION_RATELIST = 'SET_INVESTIGATION_RATELIST';
export const SET_IPD_OTHERS = 'SET_IPD_OTHERS';
export const SET_IPD_TPA = 'SET_IPD_TPA';

export const SET_STORE_ITEMS = 'SET_STORE_ITEMS';
export const SET_STORE_PURCHASE = 'SET_STORE_PURCHASE';

export const SET_UPDATES = 'SET_UPDATES';
export const SET_INVESTIGATION_TEMPLATES = 'SET_INVESTIGATION_TEMPLATES';

export const SET_SYMPTOMS = 'SET_SYMPTOMS';
export const SET_ROS_CATEGORIES = 'SET_ROS_CATEGORIES';

export const LOADING_IPD = 'LOADING_IPD';
export const SET_IPDS = 'SET_IPDS';
export const DELETE_IPD = 'DELETE_IPD';
export const SET_IPD_STATUS = 'SET_IPD_STATUS';
export const SET_PATIENT_STATUS = 'SET_PATIENT_STATUS';
export const SET_COMPANY_LIST = 'SET_COMPANY_LIST';
export const SET_PAYMENT_REMARKS = 'SET_PAYMENT_REMARKS';

export const SET_VISITING_DOCTOR_SUGGESTIONS = 'SET_VISITING_DOCTOR_SUGGESTIONS';
export const LOADING_IPD_PATIENT = 'LOADING_IPD_PATIENT';
export const SET_CURRENT_IPD = 'SET_CURRENT_IPD';
export const SET_IPD_CHIEF_COMPLAINTS = 'SET_IPD_CHIEF_COMPLAINTS';
export const DELETE_IPD_CHIEF_COMPLAINT = 'DELETE_IPD_CHIEF_COMPLAINT';
export const SET_IPD_MEDICINES = 'SET_IPD_MEDICINES';
export const DELETE_IPD_MEDICINE = 'DELETE_IPD_MEDICINE';
export const SET_IPD_INVESTIGATIONS = 'SET_IPD_INVESTIGATIONS';
export const DELETE_IPD_INVESTIGATION = 'DELETE_IPD_INVESTIGATION';
export const SET_IPD_VISIT_NOTES = 'SET_IPD_VISIT_NOTES';
export const DELETE_IPD_VISIT_NOTE = 'DELETE_IPD_VISIT_NOTE';
export const SET_IPD_CONSUMABLES = 'SET_IPD_CONSUMABLES';
export const DELETE_IPD_CONSUMABLE = 'DELETE_IPD_CONSUMABLE';
export const SET_IPD_WHITEBOARD = 'SET_IPD_WHITEBOARD';
export const DELETE_IPD_WHITEBOARD = 'DELETE_IPD_WHITEBOARD';
export const SET_IPD_PROCEDURES = 'SET_IPD_PROCEDURES';
export const DELETE_IPD_PROCEDURE = 'DELETE_IPD_PROCEDURE';
export const SET_PHYSICAL_EXAMINATIONS = 'SET_PHYSICAL_EXAMINATIONS';
export const DELETE_PHYSICAL_EXAMINATIONS = 'DELETE_PHYSICAL_EXAMINATIONS';
export const SET_IPD_HISTORY = 'SET_IPD_HISTORY';
export const SET_IPD_DIAGNOSIS = 'SET_IPD_DIAGNOSIS';
export const DELETE_IPD_DIAGNOSIS = 'DELETE_IPD_DIAGNOSIS';
export const SET_IPD_ALLERGY = 'SET_IPD_ALLERGY';
export const SET_IPD_BILL_DETAILS = 'SET_IPD_BILL_DETAILS';
export const SET_IPD_REFUND = 'SET_IPD_REFUND';
export const SET_IPD_PATIENT_BED_LIST = 'SET_IPD_PATIENT_BED_LIST';
export const SET_IPD_GYNAE_DATA = 'SET_IPD_GYNAE_DATA';
export const SET_IPD_LABOUR_RECORD = 'SET_IPD_LABOUR_RECORD';
export const SET_DISCHARGE_SUMMARY = 'SET_DISCHARGE_SUMMARY';
export const SET_IPDFILES = 'SET_IPDFILES';

export const LOADING_STAFF = 'LOADING_STAFF';
export const SET_STAFF = 'SET_STAFF_RECORDS';
export const CREATE_STAFF = 'CREATE_STAFF_RECORD';
export const UPDATE_STAFF = 'UPDATE_STAFF_RECORD';
export const DELETE_STAFF = 'DELETE_STAFF_RECORD';

export const SET_STAFF_ATTENDANCE = 'SET_STAFF_ATTENDANCE_RECORDS';
export const CREATE_STAFF_ATTENDANCE = 'CREATE_STAFF_ATTENDANCE_RECORD';
export const UPDATE_STAFF_ATTENDANCE = 'UPDATE_STAFF_ATTENDANCE_RECORD';
export const DELETE_STAFF_ATTENDANCE = 'DELETE_STAFF_ATTENDANCE_RECORD';

export const SET_STAFF_SALARY = 'SET_STAFF_ATTENDANCE_RECORDS';
export const CREATE_STAFF_SALARY = 'CREATE_STAFF_ATTENDANCE_RECORD';
export const UPDATE_STAFF_SALARY = 'UPDATE_STAFF_ATTENDANCE_RECORD';
export const DELETE_STAFF_SALARY = 'DELETE_STAFF_ATTENDANCE_RECORD';

export const LOADING_SETTINGS = 'LOADING_SETTINGS';

export const SET_OPD_STATUSES = 'SET_OPD_STATUSES';
export const CREATE_OPD_STATUS = 'CREATE_OPD_STATUS';
export const UPDATE_OPD_STATUS = 'UPDATE_OPD_STATUS';
export const DELETE_OPD_STATUS = 'DELETE_OPD_STATUS';

export const SET_CHIEF_COMPLAINTS = 'SET_CHIEF_COMPLAINTS';
export const CREATE_CHIEF_COMPLAINT = 'CREATE_CHIEF_COMPLAINT';
export const UPDATE_CHIEF_COMPLAINT = 'UPDATE_CHIEF_COMPLAINT';
export const DELETE_CHIEF_COMPLAINT = 'DELETE_CHIEF_COMPLAINT';

export const SET_EXAMINATIONS = 'SET_EXAMINATIONS';
export const CREATE_EXAMINATION = 'CREATE_EXAMINATION';
export const UPDATE_EXAMINATION = 'UPDATE_EXAMINATION';
export const DELETE_EXAMINATION = 'DELETE_EXAMINATION';
export const SET_EXAMINATION_TEMPLATES = 'SET_EXAMINATION_TEMPLATES';

export const SET_DIAGNOSES = 'SET_DIAGNOSES';
export const CREATE_DIAGNOSIS = 'CREATE_DIAGNOSIS';
export const UPDATE_DIAGNOSIS = 'UPDATE_DIAGNOSIS';
export const DELETE_DIAGNOSIS = 'DELETE_DIAGNOSIS';

export const SET_COMMUNICATION_GROUPS = 'SET_COMMUNICATION_GROUPS';
export const CREATE_COMMUNICATION_GROUP = 'CREATE_COMMUNICATION_GROUP';
export const UPDATE_COMMUNICATION_GROUP = 'UPDATE_COMMUNICATION_GROUP';
export const DELETE_COMMUNICATION_GROUP = 'DELETE_COMMUNICATION_GROUP';

export const SET_REFERRAL_TYPES = 'SET_REFERRAL_TYPES';
export const CREATE_REFERRAL_TYPE = 'CREATE_REFERRAL_TYPE';
export const UPDATE_REFERRAL_TYPE = 'UPDATE_REFERRAL_TYPE';
export const DELETE_REFERRAL_TYPE = 'DELETE_REFERRAL_TYPE';

export const SET_EXTERNAL_REFERRERS = 'SET_EXTERNAL_REFERRERS';
export const CREATE_EXTERNAL_REFERRER = 'CREATE_EXTERNAL_REFERRER';
export const UPDATE_EXTERNAL_REFERRER = 'UPDATE_EXTERNAL_REFERRER';
export const DELETE_EXTERNAL_REFERRER = 'DELETE_EXTERNAL_REFERRER';

export const SET_TREATMENTS = 'SET_TREATMENTS';
export const CREATE_TREATMENT = 'CREATE_TREATMENT';
export const UPDATE_TREATMENT = 'UPDATE_TREATMENT';
export const DELETE_TREATMENT = 'DELETE_TREATMENT';

export const SET_TREATMENTS_TAGS = 'SET_TREATMENTS_TAGS';
export const CREATE_TREATMENTS_TAGS = 'CREATE_TREATMENTS_TAGS';

export const SET_TIMINGS = 'SET_TIMINGS';

export const SET_PRESCRIPTION_TEMPLATES = 'SET_PRESCRIPTION_TEMPLATES';
export const CREATE_PRESCRIPTION_TEMPLATE = 'CREATE_PRESCRIPTION_TEMPLATE';
export const UPDATE_PRESCRIPTION_TEMPLATE = 'UPDATE_PRESCRIPTION_TEMPLATE';
export const DELETE_PRESCRIPTION_TEMPLATE = 'DELETE_PRESCRIPTION_TEMPLATE';

export const SET_SPECIAL_INSTRUCTION_TEMPLATES = 'SPECIAL_INSTRUCTION_TEMPLATES';
export const CREATE_SPECIAL_INSTRUCTION_TEMPLATE = 'CREATE_SPECIAL_INSTRUCTION_TEMPLATE';
export const UPDATE_SPECIAL_INSTRUCTION_TEMPLATE = 'UPDATE_SPECIAL_INSTRUCTION_TEMPLATE';
export const DELETE_SPECIAL_INSTRUCTION_TEMPLATE = 'DELETE_SPECIAL_INSTRUCTION_TEMPLATE';

export const SET_NOTES = 'SET_NOTES';
export const CREATE_NOTE = 'CREATE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';

export const SET_BILLPREFIX = 'SET_BILLPREFIX';

export const SET_INVESTIGATIONS = 'SET_INVESTIGATIONS';
export const CREATE_INVESTIGATION = 'CREATE_INVESTIGATION';
export const UPDATE_INVESTIGATION = 'UPDATE_INVESTIGATION';
export const DELETE_INVESTIGATION = 'DELETE_INVESTIGATION';

export const SET_CC_SYMPTOMS = 'SET_CC_SYMPTOMS';
export const SET_MEDICAL_ALERTS = 'SET_MEDICAL_ALERTS';

export const SET_ORAL_EXAMINATIONS = 'SET_ORAL_EXAMINATIONS';
export const CREATE_ORAL_EXAMINATION = 'CREATE_ORAL_EXAMINATION';
export const UPDATE_ORAL_EXAMINATION = 'UPDATE_ORAL_EXAMINATION';
export const DELETE_ORAL_EXAMINATION = 'DELETE_ORAL_EXAMINATION';

export const LOADING_ACCOUNT_VENDORS = 'LOADING_ACCOUNT_VENDORS';
export const SET_ACCOUNT_VENDORS = 'SET_ACCOUNT_VENDORS';
export const CREATE_ACCOUNT_VENDOR = 'CREATE_ACCOUNT_VENDOR';
export const UPDATE_ACCOUNT_VENDOR = 'UPDATE_ACCOUNT_VENDOR';
export const DELETE_ACCOUNT_VENDOR = 'DELETE_ACCOUNT_VENDOR';

export const LOADING_ACCOUNT_TRANSACTIONS = 'LOADING_ACCOUNT_TRANSACTIONS';
export const SET_ACCOUNT_TRANSACTIONS = 'SET_ACCOUNT_TRANSACTIONS';
export const CREATE_ACCOUNT_TRANSACTION = 'CREATE_ACCOUNT_TRANSACTIONS';
export const UPDATE_ACCOUNT_TRANSACTION = 'UPDATE_ACCOUNT_TRANSACTIONS';
export const DELETE_ACCOUNT_TRANSACTION = 'DELETE_ACCOUNT_TRANSACTIONS';
export const SET_MIS_DATA = 'SET_MIS_DATA';
export const SET_STAFF_MIS_DATA = 'SET_STAFF_MIS_DATA';
export const SET_DR_WISE_DATA_FOR_STAFF = 'SET_DR_WISE_DATA_FOR_STAFF';

export const SET_STAFF_TODAY_CURRENCY = 'SET_STAFF_TODAY_CURRENCY';

export const SET_LAB_VENDORS = 'SET_LAB_VENDORS';
export const LOADING_LAB_VENDORS = 'LOADING_LAB_VENDORS';
export const CREATE_LAB_VENDOR = 'CREATE_LAB_VENDOR';
export const UPDATE_LAB_VENDOR = 'UPDATE_LAB_VENDOR';
export const DELETE_LAB_VENDOR = 'DELETE_LAB_VENDOR';

export const SET_LAB_TRANSACTIONS = 'SET_LAB_TRANSACTIONS';
export const LOADING_LAB_TRANSACTIONS = 'LOADING_LAB_TRANSACTIONS';
export const CREATE_LAB_TRANSACTION = 'CREATE_LAB_TRANSACTION';
export const UPDATE_LAB_TRANSACTION = 'UPDATE_LAB_TRANSACTION';
export const DELETE_LAB_TRANSACTION = 'DELETE_LAB_TRANSACTION';

export const LOADING_PEDIGREE_CHARTS = 'LOADING_PEDIGREE_CHARTS';
export const SET_PEDIGREE_CHARTS = 'SET_PEDIGREE_CHARTS';
export const CREATE_PEDIGREE_CHART = 'CREATE_PEDIGREE_CHART';
export const UPDATE_PEDIGREE_CHART = 'UPDATE_PEDIGREE_CHART';
export const DELETE_PEDIGREE_CHART = 'DELETE_PEDIGREE_CHART';

export const SET_EXAMINATION_PARAMETERS = 'SET_EXAMINATION_PARAMETERS';
export const CREATE_EXAMINATION_PARAMETER = 'CREATE_EXAMINATION_PARAMETER';
export const DELETE_EXAMINATION_PARAMETER = 'DELETE_EXAMINATION_PARAMETER';

export const SET_BONE_AGE = 'SET_BONE_AGE';
export const CREATE_BONE_AGE = 'CREATE_BONE_AGE';
export const UPDATE_BONE_AGE = 'UPDATE_BONE_AGE';
export const DELETE_BONE_AGE = 'DELETE_BONE_AGE';

export const SET_TTKG = 'SET_TTKG';
export const CREATE_TTKG = 'CREATE_TTKG';
export const UPDATE_TTKG = 'UPDATE_TTKG';
export const DELETE_TTKG = 'DELETE_TTKG';

export const SET_THC = 'SET_THC';
export const CREATE_THC = 'CREATE_THC';
export const UPDATE_THC = 'UPDATE_THC';
export const DELETE_THC = 'DELETE_THC';

export const SET_GROWTH = 'SET_GROWTH';
export const CREATE_GROWTH = 'CREATE_GROWTH';
export const UPDATE_GROWTH = 'UPDATE_GROWTH';
export const DELETE_GROWTH = 'DELETE_GROWTH';

export const SET_PATIENT_CATEGORY = 'SET_PATIENT_CATEGORY';
export const SET_PATIENT_OPD_DATES = 'SET_PATIENT_OPD_DATES';

//Histroy Types

export const SET_ALL_CHIEF_COMPLAINTS = 'SET_ALL_CHIEF_COMPLAINTS';
export const SET_CHIEF_COMPLAINTS_VALUES = 'SET_CHIEF_COMPLAINTS_VALUES';
export const SET_CHIEF_COMPLAINTS_REMARKS = 'SET_CHIEF_COMPLAINTS_REMARKS';

export const SET_HOPI = 'SET_HOPI';
export const SET_HOPI_VALUES = 'SET_HOPI_VALUES';
export const SET_HOPI_REMARKS = 'SET_HOPI_REMARKS';

export const SET_SYMPTOMS_DATA = 'SET_SYMPTOMS_DATA';
export const SET_PATIENT_SYMPTOMS = 'SET_PATIENT_SYMPTOMS';
export const SET_SYMPTOMS_REMARKS = 'SET_SYMPTOMS_REMARKS';
export const SET_SUB_SYMPTOM_CHARAC = 'SET_SUB_SYMPTOM_CHARAC';

export const SET_CHRONIC_WHERE = 'SET_CHRONIC_WHERE';
export const SET_CHRONIC_DIAGNOSED = 'SET_CHRONIC_DIAGNOSED';
export const SET_CHRONIC_REMARK = 'SET_CHRONIC_REMARK';

export const SET_CHRONIC_DATA = 'SET_CHRONIC_DATA';
export const SET_SIMILAR_CONDITIONS = 'SET_SIMILAR_CONDITIONS';
export const SET_PRE_HOSPITAL = 'SET_PRE_HOSPITAL';
export const SET_PRE_HOSPITAL_REMARKS = 'SET_PRE_HOSPITAL_REMARKS';
export const SET_PRE_HOSPITAL_REASON = 'SET_PRE_HOSPITAL_REASON';
export const SET_PRE_SURGICAL = 'SET_PRE_SURGICAL';
export const SET_PRE_SURGICAL_REMARKS = 'SET_PRE_SURGICAL_REMARKS';
export const SET_PRE_SURGICAL_REASON = 'SET_PRE_SURGICAL_REASON';

export const SET_PRE_BLOOD = 'SET_PRE_BLOOD';
export const SET_BLOOD_UNITS = 'SET_BLOOD_UNITS';

export const SET_FAMILY_CHRONIC = 'SET_FAMILY_CHRONIC';
export const SET_FAMILY_SIMILAR = 'SET_FAMILY_SIMILAR';
export const SET_FH_CHRONIC = 'SET_FH_CHRONIC'; //Remarks
export const SET_FH_SIMILAR = 'SET_FH_SIMILAR'; // Remarks

export const SET_FAMILY_OTHER = 'SET_FAMILY_OTHER';
export const SET_WHOLE_FAMILY_HISTORY = 'SET_WHOLE_FAMILY_HISTORY';

export const SET_PAST_HISTORY = 'SET_PAST_HISTORY';
export const SET_SOCIAL_HISTORY = 'SET_SOCIAL_HISTORY';
export const SET_GYNECOLOGY_HISTORY = 'SET_GYNECOLOGY_HISTORY';
export const SET_FOODS = 'SET_FOODS';
export const SET_DRUG_HISTORY = 'SET_DRUG_HISTORY';
export const SET_MEDICATIONS = 'SET_MEDICATIONS';
export const SET_HISTORY_LOADING = 'SET_HISTORY_LOADING';

export const SET_WHOLE_PATIENT_HISTORY = 'SET_WHOLE_PATIENT_HISTORY';

export const SET_WHERE = 'SET_WHERE';
export const SET_HOW_DIAGNOSED = 'SET_HOW_DIAGNOSED';

export const SET_FH_SM_WHERE = 'SET_FH_SM_WHERE';
export const SET_FH_SM_HOW = 'SET_FH_SM_HOW';
export const SET_ILL_REMARKS = 'SET_ILL_REMARKS';
export const SET_SPECIAL_VALUE = 'SET_SPECIAL_VALUE';
export const SET_HABITS_VALUES = 'SET_HABITS_VALUES';
export const SET_TRAVEL_WHERE = 'SET_TRAVEL_WHERE';
export const SET_TRAVEL_REMARKS = 'SET_TRAVEL_REMARKS';

export const SET_GYNE_REMARKS = 'SET_GYNE_REMARKS';
export const SET_DELIVERY_REMARKS = 'SET_DELIVERY_REMARKS';
export const SET_DELIVERY_MODES = 'SET_DELIVERY_MODES';

export const SET_PH_CHRONIC_WHERE = 'SET_PH_CHRONIC_WHERE';
export const SET_PH_CHRONIC_HOW = 'SET_PH_CHRONIC_HOW';
export const SET_PH_CHRONIC_REMARKS = 'SET_PH_CHRONIC_REMARKS';

export const SET_PH_SIM_WHERE = 'SET_PH_SIM_WHERE';
export const SET_PH_SIM_HOW = 'SET_PH_SIM_HOW';
export const SET_PH_SIM_REMARKS = 'SET_PH_SIM_REMARKS';

export const SET_PH_PRE_HOSPITALS = 'SET_PH_PRE_HOSPITALS';
export const SET_PH_SURGICAL_HOSPITAL = 'SET_PH_SURGICAL_HOSPITAL';

export const SET_PH_SUR_SURGERY = 'SET_PH_SUR_SURGERY';

export const SET_BLOOD_REMARKS = 'SET_BLOOD_REMARKS';
export const SET_BLOOD_CENTERS = 'SET_BLOOD_CENTERS';
export const SET_MANAGE_HISTORY = 'SET_MANAGE_HISTORY';

export const SET_HOUSING = 'SET_HOUSING';
export const SET_ANIMAL = 'SET_ANIMAL';
export const SET_SOCIAL = 'SET_SOCIAL';

export const SET_DISEASES = 'SET_DISEASES';

export const SET_VISION_TEST = 'SET_VISION_TEST';
export const SET_VISION_REMARKS = 'SET_VISION_REMARKS';
export const SET_VISION_VALUES = 'SET_VISION_VALUES';
export const SET_VISION_AXIS_VALUES = 'SET_VISION_AXIS_VALUES';
export const SET_PATIENT_VISION = 'SET_PATIENT_VISION';

export const SET_AUTO_BY_DATE = 'SET_AUTO_BY_DATE';
export const SET_GLASS_BY_DATE = 'SET_GLASS_BY_DATE';
export const SET_VISION_SUGESSIONS = 'SET_VISION_SUGESSIONS';

export const SET_PCP_PROGRAMS = 'SET_PCP_PROGRAMS';
export const SET_PCP_CLASSES = 'SET_PCP_CLASSES';
export const SET_PCP_PROGRAM_DETAILS = 'SET_PCP_PROGRAM_DETAILS';
export const SET_PCP_SCHEDULES = 'SET_PCP_SCHEDULES';
export const SET_PCP_ELEMENTS = 'SET_PCP_ELEMENTS';
export const SET_CLASS_SCHEDULE = 'SET_CLASS_SCHEDULE';
export const SET_ASSESSMENT_SCHEDULE = 'SET_ASSESSMENT_SCHEDULE';
export const SET_GENERAL_TASK_SCHEDULE = 'SET_GENERAL_TASK_SCHEDULE';
export const SET_VITALS_SCHEDULE = 'SET_VITALS_SCHEDULE';
export const SET_PCP_SUBSCRIBER_DATA = 'SET_PCP_SUBSCRIBER_DATA';
export const SET_VITALS_DATA = 'SET_VITALS_DATA';
export const SET_DIETCHART = 'SET_DIETCHART';
export const SET_VITAL_FREQ = 'SET_VITAL_FREQ';
export const SET_CLASSSTATUS_LIST = 'SET_CLASSSTATUS_LIST';
export const SET_CLASS_ATTENDANCE = 'SET_CLASS_ATTENDANCE';

export const SET_EXAMS_INFO = 'SET_EXAMS_INFO';
export const SET_SUB_EXAM_CHARAC = 'SET_SUB_EXAM_CHARAC';
export const SET_EXAMS_REMARKS = 'SET_EXAMS_REMARKS';
export const SET_PATIENT_EXAMS = 'SET_PATIENT_EXAMS';

export const SET_STATUS_VALUES = 'SET_STATUS_VALUES';
export const SET_HISTORY_STATUS = 'SET_HISTORY_STATUS';

export const SET_PATIENT_IMMUN = 'SET_PATIENT_IMMUN';
export const SET_IMMUN_REMARKS = 'SET_IMMUN_REMARKS';

export const SET_PATIENT_SOAP = 'SET_PATIENT_SOAP';
export const SET_SOAP_REMARKS = 'SET_SOAP_REMARKS';

export const SET_ATTACHMENT_RECORD = 'SET_ATTACHMENT_RECORD';
export const SET_ATTACH_REMARKS = 'SET_ATTACH_REMARKS';
export const SET_PATIENT_REMARK = 'SET_PATIENT_REMARK';

// Soap
export const SET_SOAP_SUBJ = 'SET_SOAP_SUBJ';
export const SET_SOAP_OBJ = 'SET_SOAP_OBJ';
export const SET_SOAP_ASS = 'SET_SOAP_ASS';
export const SET_SOAP_PLAN = 'SET_SOAP_PLAN';
export const SET_SOAP_ATTACHMENTS = 'SET_SOAP_ATTACHMENTS';
export const SET_SOAP_ATTACH_REMARK = 'SET_SOAP_ATTACH_REMARK';
export const SET_WHITEBOARD = 'SET_WHITEBOARD';
export const SET_CLINICAL_NOTES = 'SET_CLINICAL_NOTES';
export const SET_CLINICALNOTES = 'SET_CLINICALNOTES';

export const SET_PANINI = 'SET_PANINI';

export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT';
export const SET_GYNAE_DATA = 'SET_GYNAE_DATA';
export const SET_ALGOS = 'SET_ALGOS';

export const SET_IPD_ROOMS = 'SET_IPD_ROOMS';
export const SET_VISITING_DOC = 'SET_VISITING_DOC';
export const SET_IPD_WARDS = 'SET_IPD_WARDS';
export const SET_IPD_HISTORY_TEMPLATE = 'SET_IPD_HISTORY_TEMPLATE';
export const SET_IPD_PHYSICAL_EXAMINATION_TEMPLATE = 'SET_IPD_PHYSICAL_EXAMINATION_TEMPLATE';
export const SET_GYNAE_LABLIST = 'SET_GYNAE_LABLIST';
export const SET_GYNAE_RISKLIST = 'SET_GYNAE_RISKLIST';

export const SET_IPD_BED_STATUSES = 'SET_IPD_BED_STATUSES';

export const SET_PATIENT_IPD_FILE = 'SET_PATIENT_IPD_FILE';

export const SET_FOLLOWUP_SHEETS = 'SET_FOLLOWUP_SHEETS';
export const SET_WHITE_BOARD = 'SET_WHITE_BOARD';
export const SET_PATIENT_ATTACHMENTS_MODAL = 'SET_PATIENT_ATTACHMENTS_MODAL';
export const SET_INVESTIGATION_MODAL = 'SET_INVESTIGATION_MODAL';
export const SET_DETAILED_MODAL = 'SET_DETAILED_MODAL';
export const SET_HISTORY_MODAL = 'SET_HISTORY_MODAL';
export const SET_BILLING_MODAL = 'SET_BILLING_MODAL';
export const SET_VISITS_MODAL = 'SET_VISITS_MODAL';
export const SET_APPOINTMENT_MODAL = 'SET_APPOINTMENT_MODAL';
export const SET_PRESCRIPTION_TYPE = 'SET_PRESCRIPTION_TYPE';

export const SET_PANINI_PRESCRIPTION = 'SET_PANINI_PRESCRIPTION';
export const SET_CURRENT_PRESCRIPTION = 'SET_CURRENT_PRESCRIPTION';
export const SET_CURRENT_VOICE_PRESCRIPTION = 'SET_CURRENT_VOICE_PRESCRIPTION';

export const SET_MED_ALERTS_MODAL = 'SET_MED_ALERTS_MODAL';
export const SET_IMMUNE_MODAL = 'SET_IMMUNE_MODAL';
export const SET_SAVE_PRESCRIPTION = 'SET_SAVE_PRESCRIPTION';

export const SET_ROS_TEMPLATES = 'SET_ROS_TEMPLATES';
export const SET_PATIENT_SHARE_REPORTS = 'SET_PATIENT_SHARE_REPORTS';
export const SET_PANINI_ALGORITHM_CATEGORIES = 'SET_PANINI_ALGORITHM_CATEGORIES';
export const SET_PATIENT_SHARE_REPORTS_NON_EXPIRED = 'SET_PATIENT_SHARE_REPORTS_NON_EXPIRED';
export const SET_CURRENT_CAT_ALGO = 'SET_CURRENT_CAT_ALGO';
export const SET_SHARED_REPORTS = 'SET_SHARED_REPORTS';

// IPD Modals
export const SET_IPD_CC_MODAL = 'SET_IPD_CC_MODAL';
export const SET_IPD_RX_MODAL = 'SET_IPD_RX_MODAL';
export const SET_IPD_INVESTIGATIONS_MODAL = 'SET_IPD_INVESTIGATIONS_MODAL';
export const SET_IPD_VISIT_NOTES_MODAL = 'SET_IPD_VISIT_NOTES_MODAL';
export const SET_IPD_CONSUMABLES_MODAL = 'SET_IPD_CONSUMABLES_MODAL';
export const SET_IPD_WHITEBOARD_MODAL = 'SET_IPD_WHITEBOARD_MODAL';
export const SET_IPD_PROCEDURES_MODAL = 'SET_IPD_PROCEDURES_MODAL';
export const SET_IPD_PHYSICAL_EXAMINATION_MODAL = 'SET_IPD_PHYSICAL_EXAMINATION_MODAL';
export const SET_IPD_HISTORY_MODAL = 'SET_IPD_HISTORY_MODAL';
export const SET_IPD_ALLERGY_MODAL = 'SET_IPD_ALLERGY_MODAL';
export const SET_GYNAE_MODAL = 'SET_GYNAE_MODAL';
export const SET_OPD_GYANE_MODAL = 'SET_OPD_GYANE_MODAL';
export const SET_IPD_BILLING_MODAL = 'SET_IPD_BILLING_MODAL';
export const SET_IPDFILE_MODAL = 'SET_IPDFILE_MODAL';
export const SET_LABOUR_MODAL = 'SET_LABOUR_MODAL';
export const SET_DISCHARGE_MODAL = 'SET_DISCHARGE_MODAL';
export const SET_DISCHARGE2_MODAL = 'SET_DISCHARGE2_MODAL';
export const SET_CHEMO_NOTES_MODAL = 'SET_CHEMO_NOTES_MODAL';
export const SET_APPOINTMENT_DIAGNOSES_MODAL = 'SET_APPOINTMENT_DIAGNOSES_MODAL';
export const SET_IPD_DIAGNOSES_MODAL = 'SET_IPD_DIAGNOSES_MODAL';

export const SET_ALGO_SUBSCRIPTIONS = 'SET_ALGO_SUBSCRIPTIONS';
export const SET_ORG_ALGOS = 'SET_ORG_ALGOS';
export const SET_SUBSCRIPTION_MODAL = 'SET_SUBSCRIPTION_MODAL';
export const SET_VENDOR_SUBSCRIPTIONS = 'SET_VENDOR_SUBSCRIPTIONS';

export const SET_DASHBOARDMENU_TYPE = 'SET_DASHBOARDMENU_TYPE';
export const SET_STOCKIST_PURCHASE_VOUCHER = 'SET_STOCKIST_PURCHASE_VOUCHER';
export const SET_STOCKIST_PAYMENTS = 'SET_STOCKIST_PAYMENTS';
export const SET_PHARMACY_ALL_PAYMENTS = 'SET_PHARMACY_ALL_PAYMENTS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const SET_VIEW_CONSULT2DOCTOR_MODAL = 'SET_VIEW_CONSULT2DOCTOR_MODAL';
export const SET_SPECIALITY_DOCTORS = 'SET_SPECIALITY_DOCTORS';
// Lab Actions
export const SET_LAB_TESTS = 'SET_LAB_TESTS';
export const SET_LAB_BILLS = 'SET_LAB_BILLS';
export const SET_MIS_OVERVIEW = 'SET_MIS_OVERVIEW';
export const SET_MIS_SPECIFIC_TOTALS = 'SET_MIS_SPECIFIC_TOTALS';
export const SET_STAFF_DATA = 'SET_STAFF_DATA';
export const SET_STAFF_WISE_MIS_SPECIFIC_DATA = 'SET_STAFF_WISE_MIS_SPECIFIC_DATA';
export const SET_STAFF_MIS_PRINT_DATA = 'SET_STAFF_MIS_PRINT_DATA';
export const SET_LABTEST_TAGS = 'SET_LABTEST_TAGS';
export const ADD_LABTEST_TAGS = 'ADD_LABTEST_TAGS';
export const SET_LABPARAMETERS = 'SET_LABPARAMETERS';
export const SET_LABPARAMETERS_MASTER = 'SET_LABPARAMETERS_MASTER';
export const SET_LABPARAMETER_UNIT = 'SET_LABPARAMETER_UNIT';
export const SET_LAB_TEMPLATES = 'SET_LAB_TEMPLATES';
export const SET_LAB_TEMPLATE_LIST = 'SET_LAB_TEMPLATE_LIST';
export const SET_LAB_DEPARTMENTS = 'SET_LAB_DEPARTMENTS';
export const SET_LAB_SAMPLES = 'SET_LAB_SAMPLES';
export const SET_PARAMETERLIST = 'SET_PARAMETERLIST';
export const SET_PATIENT_OCCUPATION = 'SET_PATIENT_OCCUPATION';

export const SET_STORE_ASSIGN_LIST = 'SET_STORE_ASSIGN_LIST';
export const SET_STORE_INVENTORY = 'SET_STORE_INVENTORY';
export const SET_STORE_INDENTATION = 'SET_STORE_INDENTATION';
export const SET_STORE_SALES = 'SET_STORE_SALES';
export const SET_PATIENT_STORE_ASSIGN = 'SET_PATIENT_STORE_ASSIGN';

//Component Actions
export const SET_TABLE_LOADING = 'SET_TABLE_LOADING';
export const SET_CREATE_LOADING = 'SET_CREATE_LOADING';

export const SET_NURSING_LIST = 'SET_NURSING_LIST';
export const SET_TPA_LIST = 'SET_TPA_LIST';

export const SET_PATIENTS_SEARCH_LIST = 'SET_PATIENTS_SEARCH_LIST';

// ABDM Actions
export const SET_ABHA_TXN = 'SET_ABHA_TXN';
export const SET_ABHA_CARD = 'SET_ABHA_CARD';
export const SET_ABHA_STATUS = 'SET_ABHA_STATUS';
export const SET_ABHA_PROFILE = 'SET_ABHA_PROFILE';
export const SET_MAPPED_PHR = 'SET_MAPPED_PHR';
export const SET_ABHA_SUGGESTIONS = 'SET_ABHA_SUGGESTIONS';
export const SET_HEALTH_ID_NUMBER = 'SET_HEALTH_ID_NUMBER';

export const SET_ACCOUNT_EXPENSES = 'SET_ACCOUNT_EXPENSES';
export const CREATE_ORG_EXPENSES = 'CREATE_ORG_EXPENSES';
export const UPDATE_ORG_EXPENSE = 'UPDATE_ORG_EXPENSE';
export const DELETE_ORG_EXPENSE = 'DELETE_ORG_EXPENSE';
export const SET_ORG_EXPENSES = 'SET_ORG_EXPENSES';
export const SET_DETAIL_DOCTOR_REPORT = 'SET_DETAIL_DOCTOR_REPORT';

export const SET_PATIENT_PENDING_BILLS = 'SET_PATIENT_PENDING_BILLS';

export const LOADING_TELECONSULTATION = 'LOADING_TELECONSULTATION';
export const SET_TELECONSULTATION_REMOTE = 'SET_TELECONSULTATION_REMOTE';
export const SET_TELECONSULTATION_EXPERT = 'SET_TELECONSULTATION_EXPERT';
export const SET_APPOINTMENTS_OF_REMOTE_CLINIC = 'SET_APPOINTMENTS_OF_REMOTE_CLINIC';
export const SET_APPOINTMENTS_FROM_REMOTE_CLINIC = 'SET_APPOINTMENTS_FROM_REMOTE_CLINIC';

export const SET_SMS_CREDITS = 'SET_SMS_CREDITS';
export const SET_WHATSAPP_CREDITS = 'SET_WHATSAPP_CREDITS';

export const SET_PATIENT_WISE_SALES_VOUCHER_MIS = 'SET_PATIENT_WISE_SALES_VOUCHER_MIS';

export const CREATE_CONSENT = 'CREATE_CONSENT';
export const DELETE_CONSENT = '	DELETE_CONSENT';
export const SET_CONSENTS_lIST = 'SET_CONSENTS_lIST';

export const SET_CONSENT_MODAL = 'SET_CONSENT_MODAL';

export const SET_MEDICINE_ROWS = 'SET_MEDICINE_ROWS';
export const DELETE_MEDICINE_ROW = 'DELETE_MEDICINE_ROW';

export const LOADING_TELE_CRM = 'LOADING_TELE_CRM';
export const NOT_LOADING_TELE_CRM = 'NOT_LOADING_TELE_CRM';
export const SET_CALL_LOGS = 'SET_CALL_LOGS';
export const SET_SELECTED_CALL_LOG = 'SET_SELECTED_CALL_LOG';
export const SET_SELECTED_CALL_LOG_PATIENT = 'SET_SELECTED_CALL_LOG_PATIENT';

export const SET_SPECIFIC_VITALS_DATA = 'SET_SPECIFIC_VITALS_DATA';
