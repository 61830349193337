import {
	SET_PANINI,
    SET_PANINI_PRESCRIPTION,
	SET_CURRENT_PRESCRIPTION,
	SET_MED_ALERTS_MODAL,
	SET_PANINI_ALGORITHM_CATEGORIES,
	SET_SAVE_PRESCRIPTION,
	SET_CURRENT_CAT_ALGO,
	SET_ALGO_SUBSCRIPTIONS,
	SET_ORG_ALGOS,
	SET_VENDOR_SUBSCRIPTIONS,
	SET_CURRENT_VOICE_PRESCRIPTION,
} from './types';

const initialState = {
    viewPanini : false,
    viewPaniniPrescription: false,
	currentPrescription : {},
	currentVoicePrescription : {},
	algoSubscription : [],
	vendorSubscription : [],
	orgAlgos : [],
	categories : [],
	currentCatAlgo : [],
	medAlertsModal : false,
	savePrescription : false,
	notesMsg : false,
	loading: false,
};

const paniniReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PANINI:
			return {
				...state,
				viewPanini: action.payload,
			}
        case SET_PANINI_PRESCRIPTION:
            return{
                ...state,
                viewPanini : action.payload.viewPanini,
                viewPaniniPrescription: action.payload.viewPaniniPrescription
            }
		case SET_CURRENT_PRESCRIPTION : 
			return { 
				...state,
				currentPrescription : action.payload,
			}
		case SET_CURRENT_VOICE_PRESCRIPTION : 
			return { 
				...state,
				currentVoicePrescription : action.payload,
			}
		case SET_MED_ALERTS_MODAL:
			return {
				...state,
				medAlertsModal : action.payload,
			}
		case SET_SAVE_PRESCRIPTION:
			return {
				...state,
				savePrescription : action.payload,
			}
		case SET_PANINI_ALGORITHM_CATEGORIES:
			return {
				...state,
				categories : action.payload || [],
			}
		case SET_CURRENT_CAT_ALGO:
			return {
				...state,
				currentCatAlgo : action.payload || [],
			}
		case SET_ALGO_SUBSCRIPTIONS:
			return {
				...state,
				algoSubscription : action.payload || [],
			}
		case SET_ORG_ALGOS : 
			return {
				...state,
				orgAlgos : action.payload || [],
			}
		case SET_VENDOR_SUBSCRIPTIONS:
			return {
				...state,
				vendorSubscription : action.payload || [],
			}
		default:
			return state;
	}
};

export default paniniReducer;
