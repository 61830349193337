import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import { getPatientAppointmentDiagnoses } from '../../../../actions/patientActions';
import useDarkMode from '../../../../hooks/useDarkMode';
import { useSelector } from 'react-redux';
import Button from '../../../../components/bootstrap/Button';

function Diagnoses({ userId }) {
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	const [data, setData] = useState([]);

	const patient = useSelector((state) => state.patient.patient);

	// Initial Fetch
	useEffect(() => {
		let mounted = true;

		const fetchOthers = async () => {
			if (mounted) {
				setData(await getPatientAppointmentDiagnoses(userId));
			}
		};

		fetchOthers();

		return () => {
			mounted = false;
		};
	}, []);

	return (
		<Card stretch className='shadow-none'>
			<CardHeader borderSize={1}>
				<CardLabel icon='Feed' iconColor='primary'>
					<CardTitle>Diagnoses</CardTitle>
				</CardLabel>
				<CardActions>
					<Button
						isOutline={!darkModeStatus}
						isLight={darkModeStatus}
						color='primary'
						icon='Print'
						onClick={() =>
							navigate(`/patients/${userId}/diagnoses-pdf`, {
								state: {
									diagnoses: data,
									patient,
								},
							})
						}>
						Print
					</Button>
				</CardActions>
			</CardHeader>
			<CardBody className='table-responsive'>
				{
					<table className='table table-modern'>
						<thead>
							<tr>
								<th>S.No.</th>
								<th>Name</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item, idx) => {
								return (
									<tr key={item.id}>
										<td>{idx + 1}</td>
										<td>{item.Diagnosis?.name || '-'}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				}
			</CardBody>
		</Card>
	);
}

export default Diagnoses;
