import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import Button from '../../../../components/bootstrap/Button';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../../../../components/bootstrap/Modal';
import Tooltips from '../../../../components/bootstrap/Tooltips';

function FlowSheet({ formik }) {
	const [openModal, setOpenModal] = useState(false);
	const [isEdit, setIsEdit] = useState(null);

	const handleEdit = (values, index) => {
		setIsEdit(index);
		flowFormik.setValues(values);
		setOpenModal(true);
	};

	const handleDelete = async (index) => {
		formik.setFieldValue('flowSheet', [
			...formik.values.flowSheet?.slice(0, index),
			...formik.values.flowSheet?.slice(index + 1),
		]);
	};

	const flowFormik = useFormik({
		initialValues: {
			ega: '',
			date: moment().format('YYYY-MM-DD'),
			headache: '',
			bleeding: '',
			crampsPain: '',
			nauseaVomit: '',
			swelling: '',
			fetalMvmt: '',
			weight: '',
			bp: '',
			urineProtein: '',
			urineGlucose: '',
			fundalHt: '',
			position: '',
			cervix: '',
			edema: '',
			fhr: '',
			comments: '',
			returnVisit: '',
			mdInitials: '',
		},
		onSubmit: (values, { resetForm }) => {
			if (isEdit != null) {
				formik.setFieldValue(`flowSheet`, [
					...formik.values.flowSheet.slice(0, isEdit),
					...formik.values.flowSheet.slice(isEdit + 1),
					values,
				]);
				setIsEdit(null);
			} else {
				const data = {};
				for (let key in values) {
					if (values[key] !== '') data[key] = values[key];
				}
				if (Object.keys(data).length > 1)
					formik.setFieldValue(`flowSheet`, [...formik.values.flowSheet, values]);
			}
			resetForm();
			setOpenModal(false);
		},
	});

	return (
		<>
			<div className='row mb-3'>
				<div className='col-10 text-center'>
					<strong>Flow Sheet</strong>
				</div>
				<div className='col-2 mb-1'>
					<Button
						isOutline
						color='primary'
						icon='add'
						onClick={(e) => {
							setOpenModal(true);
							flowFormik.resetForm();
						}}>
						Add
					</Button>
				</div>
				<div className='col-12'>
					<table className='table table-bordered'>
						<tbody>
							<tr>
								<th>Date</th>
								<th></th>
							</tr>
							{formik.values.flowSheet?.map((data, index) => (
								<tr key={index}>
									<td>{moment(data?.date).format('DD-MM-YYYY')}</td>
									<td>
										<Tooltips title='Edit'>
											<Button
												isLink
												isOutline
												color='primary'
												icon='edit'
												onClick={() => handleEdit(data, index)}
											/>
										</Tooltips>{' '}
										<Tooltips title='Delete'>
											<Button
												isLink
												isOutline
												color='danger'
												icon='delete'
												onClick={() => handleDelete(index)}
											/>
										</Tooltips>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			<Modal isOpen={openModal} isAnimation={false} isCentered size={`xl`}>
				<ModalHeader setIsOpen={setOpenModal}></ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-6'>
							<FormGroup label={'Date'}>
								<Input
									type='date'
									className='form-control py-3'
									name={`date`}
									value={flowFormik.values.date}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'EGA'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`ega`}
									value={flowFormik.values.ega}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Headache'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`headache`}
									value={flowFormik.values.headache}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Bleeding'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`bleeding`}
									value={flowFormik.values.bleeding}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Cramps Pain'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`crampsPain`}
									value={flowFormik.values.crampsPain}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Nausea/Vomit'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`nauseaVomit`}
									value={flowFormik.values.nauseaVomit}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Swelling'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`swelling`}
									value={flowFormik.values.swelling}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Fetal Mvmt.'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`fetalMvmt`}
									value={flowFormik.values.fetalMvmt}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Weight'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`weight`}
									value={flowFormik.values.weight}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'BP'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`bp`}
									value={flowFormik.values.bp}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Urine Protien'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`urineProtein`}
									value={flowFormik.values.urineProtein}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Urine Glucose'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`urineGlucose`}
									value={flowFormik.values.urineGlucose}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Fudal Ht.'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`fundalHt`}
									value={flowFormik.values.fundalHt}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Position'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`position`}
									value={flowFormik.values.position}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Cervix'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`cervix`}
									value={flowFormik.values.cervix}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Edema'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`edema`}
									value={flowFormik.values.edema}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'FHR'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`fhr`}
									value={flowFormik.values.fhr}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Comments'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`comments`}
									value={flowFormik.values.comments}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'Return Visit (int weeks)'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`returnVisit`}
									value={flowFormik.values.returnVisit}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
						<div className='col-6'>
							<FormGroup label={'MD Initials'}>
								<Input
									type='text'
									className='form-control py-3'
									name={`mdInitials`}
									value={flowFormik.values.mdInitials}
									onChange={flowFormik.handleChange}
								/>
							</FormGroup>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button isOutline color='primary' icon='save' onClick={flowFormik.handleSubmit}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default FlowSheet;
