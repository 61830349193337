import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Creatable from 'react-select/creatable';
import {
	addIpdInvestigation,
	deleteIpdInvestigation,
	getIpdInvestigations,
	updateIpdInvestigation,
} from '../../../actions/ipdPatientActions';
import {
	createInvestigationNow,
	createInvestigationRateList,
} from '../../../actions/settingActions';

import Button from '../../../components/bootstrap/Button';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Tooltips from '../../../components/bootstrap/Tooltips';
import useDarkMode from '../../../hooks/useDarkMode';
import showNotification from '../../../components/extras/showNotification';
import CanvasJSReact from '../../../assets/canvasjs/canvasjs.react';
import { isPermitted } from '../../../actions/helperActions';
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

function IpdInvestigations() {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();

	const patientInvestigations = useSelector((state) => state.ipdPatient.investigations);
	const ipdId = useSelector((state) => state.ipdPatient.currentIpd);
	const investigations = useSelector((state) => state.setting.investigationRateList);
	const moreInvestigations = useSelector((state) => state.setting.investigations);

	const [newDate, setNewDate] = useState(moment().format('YYYY-MM-DD'));
	const [dateModal, setDateModal] = useState(false);
	const [chartData, setChartData] = useState([]);
	const [chartModal, setChartModal] = useState(false);
	const [dates, setDates] = useState(
		[
			...new Set([
				...patientInvestigations
					?.map((i) => i.Ipd_investigations)
					?.flat(1)
					?.map((i) =>
						i.investigationDate
							? moment(i.investigationDate).format('YYYY-MM-DD')
							: moment(new Date().toISOString()).format('YYYY-MM-DD'),
					),
				moment(new Date().toISOString()).format('YYYY-MM-DD'),
			]),
		].sort(),
	);

	const formik = useFormik({
		initialValues: {
			table: Object?.assign(
				{},
				...patientInvestigations?.map((i) => ({
					[i?.Ipd_investigations?.[0]?.Investigation?.name]: Object.assign(
						{ id: i.id, investigateId: i?.investigationId },
						...i?.Ipd_investigations?.map((j) => ({ [j?.investigationDate]: j.value })),
					),
				})),
			),
		},
	});

	const handleAddInvestigation = (creatableInv) => {
		if (formik.values.table[creatableInv.label]) return;

		const newTable = {
			...formik.values.table,
			[creatableInv.label]: Object.assign(
				{},
				...dates.map((i) => ({ [i]: '', investigateId: creatableInv?.value?.id })),
			),
		};

		formik.setValues((prev) => ({ ...prev, table: newTable }));
	};

	const handleSaveInvestigations = () => {
		Object.keys(formik.values.table).forEach((i, index) => {
			const ipdInvestigateId = formik.values.table?.[i].id;
			const investigateId = formik.values.table?.[i]?.investigateId;
			delete formik.values.table[i].investigateId;
			if (ipdInvestigateId) {
				delete formik.values.table[i].id;
				const updateBody = {
					ipdId,
					investigateId,
					ipdInvestigateId,
					userInvestigateList: Object.keys(formik.values.table[i])
						.filter((x) => !!formik.values.table[i][x])
						.map((j) => ({
							date: j,
							value: formik.values.table[i][j],
						})),
				};
				dispatch(updateIpdInvestigation(updateBody));
			} else {
				const newInv = {
					ipdId,
					investigateId,
					userInvestigateList: Object.keys(formik.values.table[i]).map((j) => ({
						date: j,
						value: formik.values.table[i][j],
					})),
				};
				dispatch(addIpdInvestigation(newInv));
			}
		});
		showNotification('Success', 'Investigation saved successfully', 'success');
	};

	const handleAddDate = () => {
		const newTable = {};
		Object.keys(formik.values.table).forEach((investigation) => {
			newTable[investigation] = {
				...formik.values.table[investigation],
				[newDate]: '',
			};
		});

		formik.setValues((prev) => ({ ...prev, table: newTable }));
		let newDates = [...dates];
		newDates.push(newDate);
		newDates.sort();
		setDates(newDates);
		// setDates((prev) => [...prev, newDate]);

		setDateModal(false);
	};

	const handleDeleteInvestigation = (inv) => {
		if (!inv) return;
		if (!inv.id) {
			buildTable();
			return;
		}
		dispatch(deleteIpdInvestigation(inv?.id, ipdId));
	};

	const handleGenerateChart = (investigation, invData) => {
		const data = [];
		dates
			?.filter((x) => !!invData[moment(x).format('YYYY-MM-DD')])
			.forEach((x) =>
				data.push({
					y: parseFloat(invData[moment(x).format('YYYY-MM-DD')]),
					label: moment(x).format('DD-MM-YYYY'),
				}),
			);
		data.sort((a, b) =>
			moment(a.label, 'DD-MM-YYYY') > moment(b.label, 'DD-MM-YYYY') ? 1 : -1,
		);
		setChartData([
			{
				showInLegend: true,
				type: 'spline',
				name: investigation,
				dataPoints: data,
			},
		]);
		setChartModal(true);
	};

	const buildTable = () => {
		formik.setValues({
			table: Object?.assign(
				{},
				...patientInvestigations?.map((i) => ({
					[i?.Ipd_investigations?.[0]?.Investigation?.name]: Object.assign(
						{ id: i.id, investigateId: i?.investigationId },
						...i?.Ipd_investigations?.map((j) => ({
							[j?.investigationDate]: j.value,
						})),
					),
				})),
			),
		});
	};
	useEffect(() => {
		buildTable();
	}, [patientInvestigations]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			dispatch(getIpdInvestigations(ipdId));
		}
		return () => {
			mounted = false;
		};
	}, []);

	return (
		<>
			<Card stretch className='shadow-none'>
				<CardHeader>
					<CardLabel icon='contacts' color='primary'>
						<CardTitle>Investigations</CardTitle>
					</CardLabel>
					<CardActions>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='Add'
							onClick={() => setDateModal(true)}>
							Add Date
						</Button>
						<Button
							isOutline={!darkModeStatus}
							isLight={darkModeStatus}
							color='primary'
							icon='Save'
							onClick={() =>
								isPermitted(
									'Ipd',
									'write',
									handleSaveInvestigations,
									'Investigations',
								)
							}>
							Save
						</Button>
					</CardActions>
				</CardHeader>
				<CardBody className='table-responsive'>
					<table className='table table-bordered table-sm'>
						<tbody>
							<tr>
								<th>Test Name</th>
								{dates?.map((date) => (
									<th>{date && moment(date).format('DD-MM-YYYY')}</th>
								))}
							</tr>
							{Object.keys(formik.values.table).map((investigation, index) => (
								<tr key={index}>
									<td>
										<div className='d-flex justify-content-between align-items-center'>
											<h6 className='m-0 ms-1'>{investigation}</h6>
											<div className='d-flex'>
												<Tooltips title='View Trend'>
													<Button
														isLink
														color='primary'
														className='p-0'
														size='sm'
														icon='TrendingUp'
														iconColor='primary'
														onClick={() =>
															handleGenerateChart(
																investigation,
																formik.values.table[investigation],
															)
														}
													/>
												</Tooltips>{' '}
												<Tooltips title='delete'>
													<Button
														isLink
														color='danger'
														className='p-0'
														size='sm'
														icon='delete'
														iconColor='danger'
														onClick={() =>
															isPermitted(
																'Ipd',
																'delete',
																() =>
																	handleDeleteInvestigation(
																		formik.values.table[
																			investigation
																		],
																	),
																'Investigations',
															)
														}
													/>
												</Tooltips>
											</div>
										</div>
									</td>
									{dates?.map((date, idx) => (
										<td key={idx}>
											<Input
												style={{
													fontWeight: 'normal',
													padding: '0.60rem',
													borderRadius: '0.3rem',
													borderColor: '#dee2e6',
													boxShadow: 'none',
													border: 0,
												}}
												value={
													formik.values.table[investigation][
														moment(new Date(date).toISOString()).format(
															'YYYY-MM-DD',
														)
													] || ''
												}
												onChange={(e) =>
													formik.setValues((prev) => ({
														...prev,
														table: {
															...prev.table,
															[investigation]: {
																...prev.table[investigation],
																[moment(
																	new Date(date).toISOString(),
																).format('YYYY-MM-DD')]:
																	e.target.value,
															},
														},
													}))
												}
												disabled={moment(date) > moment()}
											/>
										</td>
									))}
								</tr>
							))}

							<tr>
								<td>
									<Creatable
										components={{
											DropdownIndicator: () => null,
											IndicatorSeparator: () => null,
										}}
										className='p-0'
										styles={{
											control: (base) => ({
												...base,
												border: 0,
												boxShadow: 'none',
											}),
										}}
										value={{}}
										options={[...investigations, ...moreInvestigations]?.map(
											(i) => ({
												label: i?.name,
												value: i,
											}),
										)}
										onChange={handleAddInvestigation}
										onCreateOption={async (e) => {
											const newInv = await createInvestigationRateList(
												{
													name: e,
													type: 'IPD',
												},
												dispatch,
											);
											if (newInv)
												handleAddInvestigation({
													label: newInv.name,
													value: newInv,
												});
										}}
									/>
								</td>
								{dates?.map((i) => (
									<td></td>
								))}
							</tr>
						</tbody>
					</table>
				</CardBody>
			</Card>
			<Modal
				isOpen={dateModal}
				setIsOpen={setDateModal}
				titleId={'deletePharm'}
				isCentered
				isAnimation={false}>
				<ModalHeader setIsOpen={setDateModal}>
					<ModalTitle id='deletePharmLabel'>Add Date</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<FormGroup id='date' label='Date'>
						<Input
							autoComplete='off'
							onChange={(e) => setNewDate(e.target.value)}
							type='date'
							style={{
								height: 'calc(3.5rem + 2px)',
							}}
							placeholder='Date'
							value={newDate}
						/>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						isOutline
						className='border-0'
						onClick={() => {
							setDateModal(false);
						}}>
						Cancel
					</Button>
					<Button color='primary' icon='save' onClick={handleAddDate}>
						Save
					</Button>
				</ModalFooter>
			</Modal>
			<Modal
				size='xl'
				isOpen={chartModal}
				setIsOpen={() => {
					setChartModal(false);
				}}
				isCentered
				isAnimation={false}>
				<ModalHeader
					setIsOpen={() => {
						setChartModal(false);
					}}>
					<ModalTitle id='deletePharmLabel'>Chart</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<CanvasJSChart
						options={{
							animationEnabled: true,
							title: {
								text: `${chartData?.map?.((i) => i.name)?.join?.(', ')} Trend`,
							},
							axisY: {
								title: 'Value',
							},
							axisX: {
								title: 'Dates',
							},
							data: chartData,
						}}
					/>
				</ModalBody>
				<ModalFooter>
					<Button
						color='primary'
						className='border-0'
						onClick={() => {
							setChartModal(false);
						}}>
						Close
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default IpdInvestigations;
